const ErrorHandler = (t, alertError) => error => {
  const errorType = error.response?.data?.type;

  console.log(error);
  console.log(errorType);

  switch (errorType) {
    case "DuplicateEntityError": {
      alertError(t("webapp:contact.error.duplicate"));

      return;
    }

    default: {
      alertError(t("webapp:common.error.general"));

      return;
    }
  }
};

export default ErrorHandler;
