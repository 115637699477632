import React, { useCallback, useContext, useMemo, useState } from "react";
import { v4 } from "uuid";
import clsx from "clsx";
import { groupBy } from "lodash";
import { Formik } from "formik";

import {
  Checkbox,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ConstructionOutlined } from "@material-ui/icons";

import { DateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    flexGrow: 1,
  },
  centerText: {
    textAlign: "center",
  },
  tableRow: {
    width: "100%",
    display: "table",

    "& td:first-child": {
      paddingLeft: "60px",
    },
  },
  tableRowWide: {
    "& td:first-child": {
      paddingLeft: "120px",
    },
  },
}));

const WaypointCargoPackagesSelect = ({
  waypoint,
  onSelectCargoItemPackages,
  selectedCargoItemPackages,

  tableRowClassName,
  tableRowWideClassName,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isWholeCargoItemSelected = (cargoItem) => {
    return cargoItem.packages.every((cargoItemPackage) =>
      selectedCargoItemPackages.includes(
        cargoItemPackage.packageId || cargoItemPackage.cargoItemPackageId
      )
    );
  };

  const isAnyCargoPackageSelected = (cargoItem) => {
    return cargoItem.packages.some((cargoItemPackage) =>
      selectedCargoItemPackages.includes(
        cargoItemPackage.packageId || cargoItemPackage.cargoItemPackageId
      )
    );
  };

  const isCheckedCargoItemPackage = (cargoItemPackageId) => {
    return selectedCargoItemPackages.includes(cargoItemPackageId);
  };

  const groupedCargo = useMemo(
    () => groupBy(waypoint.cargo, (c) => c.action),
    [waypoint.cargo]
  );

  return (
    <div className={classes.root}>
      {Object.keys(groupedCargo).map((cargoAction) => {
        return (
          <>
            <Box pt={1}>
              <Typography variant="body2">
                {cargoAction === "LOAD"
                  ? t("board.drivenThroughLoading")
                  : t("board.drivenThroughUnloading")}
              </Typography>
            </Box>
            {groupedCargo[cargoAction].map((cargoItem, index) => {
              const wholeCargoItemSelected =
                isWholeCargoItemSelected(cargoItem);
              return (
                <React.Fragment key={`${v4()}`}>
                  <TableRow className={tableRowClassName || classes.tableRow}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={wholeCargoItemSelected}
                        disabled={cargoItem.packages?.length === 0}
                        indeterminate={
                          isAnyCargoPackageSelected(cargoItem) &&
                          !wholeCargoItemSelected
                        }
                        onChange={({ target: { checked: selected } }) => {
                          onSelectCargoItemPackages(
                            selected,
                            cargoItem.packages.map(
                              (cargoItemPackage) =>
                                cargoItemPackage.packageId ||
                                cargoItemPackage.cargoItemPackageId
                            )
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>{cargoItem.incomingOrder ? `#${cargoItem.incomingOrder?.indexNumber} ${cargoItem.description || 'Zbozi'}` : cargoItem.description}</TableCell>
                  </TableRow>
                  {cargoItem.packages &&
                    cargoItem.packages.map((cargoItemPackage, index) => (
                      <TableRow
                        className={clsx(
                          tableRowClassName || classes.tableRow,
                          tableRowWideClassName || classes.tableRowWide
                        )}
                        key={`${v4()}`}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isCheckedCargoItemPackage(
                              cargoItemPackage.packageId ||
                                cargoItemPackage.cargoItemPackageId
                            )}
                            onChange={({ target: { checked: selected } }) => {
                              onSelectCargoItemPackages(selected, [
                                cargoItemPackage.packageId ||
                                  cargoItemPackage.cargoItemPackageId,
                              ]);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {cargoItem.incomingOrder ? `#${cargoItem.incomingOrder.indexNumber} ${cargoItemPackage.sequence.toString().padStart(2, "0")||
                            cargoItemPackage.cargoItemPackageId}` : cargoItemPackage.sequence.toString().padStart(2, "0")}
                        </TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              );
            })}
          </>
        );
      })}
    </div>
  );
};

export default WaypointCargoPackagesSelect;
