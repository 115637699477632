import { filterProperties } from "@cargotic/common";
import {
  CargoItemAction,
  Shipment,
  ShipmentSnapshot
} from "@cargotic/model";

import {
  ApiClient,
  httpGet,
  httpPatch,
  httpPost
} from "./client";

function serializeShipment(shipment: Shipment, update: boolean): any {
  return {
    ...shipment,
    journey: shipment.journey ? {
      ...shipment.journey,
      waypoints: shipment.journey.waypoints
        .map((waypoint) => {
          return {
            ...waypoint,
            place: {
              ...waypoint.place,
              image: undefined,
              id: update ? undefined : waypoint.place.id
            }
          };
        })
    } : undefined
  };
}

function deserializeShipment(
  {
    journey: {
      waypoints,
      ...journeyRest
    },
    isWithDph,
    issuedInvoicePaidAt,
    receivedInvoicePaidAt,
    notes,
    terms,
    carrierContact,
    customerContact,
    documents,
    customerPrice,
    carrierPrice,
    customerPaymentDueDays,
    carrierPaymentDueDays,
    commissionCurrency,
    carrierPriceCurrency,
    state,
    receivedInvoiceNumber,
    issuedInvoiceNumber,
    issuedInvoiceDueDate,
    receivedInvoiceDueDate,
    ...rest
  }: any
): ShipmentSnapshot {
  return {
    ...rest,
    journey: {
      ...journeyRest,
      waypoints: waypoints
        .map(({
          arriveAtFrom,
          arriveAtTo,
          contact,
          note,
          phoneNumber,
          ...waypointRest
        }) => {
          const result = {
            ...waypointRest,
            arriveAtFrom: new Date(arriveAtFrom),
            arriveAtTo: arriveAtTo ? new Date(arriveAtTo) : undefined,
            contact: contact ?? undefined,
            note: note ?? undefined,
            phoneNumber: phoneNumber ?? undefined
          };

          return result;
        })
    },
    carrierContact: carrierContact
    ? {
      ...carrierContact,
      isBilledOnline: Boolean(carrierContact.isBilledOnline),
      createdAt: new Date(carrierContact.createdAt),
      insuranceExpiresAt: carrierContact.insuranceExpiresAt ? new Date(carrierContact.insuranceExpiresAt) : undefined,
      type: "CARRIER"
    }
    : undefined,
    customerContact: customerContact
    ? {
      ...customerContact,
      isBilledOnline: Boolean(customerContact.isBilledOnline),
      createdAt: new Date(customerContact.createdAt),
      insuranceExpiresAt: customerContact.insuranceExpiresAt ? new Date(customerContact.insuranceExpiresAt) : undefined,
      type: "CUSTOMER"
    }
    : undefined,
    isWithDph: Boolean(isWithDph),
    documents: documents
      .map(({
        createdAt,
        ...document
      }) => ({
        ...document,
        createdAt: new Date(createdAt)
      })),
    notes: notes ?? undefined,
    terms: terms ?? undefined,
    state: state ?? undefined,
    customerPrice: customerPrice ?? undefined,
    carrierPrice: carrierPrice ?? undefined,
    customerPaymentDueDays: customerPaymentDueDays ?? undefined,
    carrierPaymentDueDays: carrierPaymentDueDays ?? undefined,
    commissionCurrency: commissionCurrency ?? undefined,
    carrierPriceCurrency: carrierPriceCurrency ?? undefined,
    issuedInvoicePaidAt: issuedInvoicePaidAt ? new Date(issuedInvoicePaidAt) : undefined,
    receivedInvoicePaidAt: receivedInvoicePaidAt ? new Date(receivedInvoicePaidAt) : undefined,
    issuedInvoiceNumber: issuedInvoiceNumber ?? undefined,
    issuedInvoiceDueDate: issuedInvoiceDueDate ? new Date(issuedInvoiceDueDate) : undefined,
    receivedInvoiceNumber: receivedInvoiceNumber ?? undefined,
    receivedInvoiceDueDate: receivedInvoiceDueDate ? new Date(receivedInvoiceDueDate) : undefined,
  };
}

async function createShipment(
  client: ApiClient,
  shipment: Shipment
): Promise<void> {
  const serialized = serializeShipment(shipment, false);

  await httpPost(
    client,
    "new-shipments",
    undefined,
    serialized
  );
}

async function fetchShipmentById(
  client: ApiClient,
  id: number
): Promise<ShipmentSnapshot> {
  const { data: { shipment } } = await httpGet(
    client,
    `new-shipments/${id}`
  );

  const deserialized = deserializeShipment(shipment);

  return deserialized;
}

async function fetchNextShipmentIndexNumber(
  client: ApiClient
): Promise<string> {
  const { data: { nextIndexNumber } } = await httpGet(
    client,
    "new-shipments/index-number"
  );

  return nextIndexNumber;
}

async function updateShipmentById(
  client: ApiClient,
  id: number,
  shipment: Shipment
): Promise<void> {
  const serialized = serializeShipment(shipment, true);

  await httpPatch(
    client,
    `new-shipments/${id}`,
    undefined,
    serialized
  );
}

export {
  createShipment,
  fetchNextShipmentIndexNumber,
  fetchShipmentById,
  updateShipmentById,
  serializeShipment
};
