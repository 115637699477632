import { Optional, ValueError } from "@cargotic/common-deprecated";

import { Validate } from "./validate";

import { isValid, parseISO } from "date-fns";

function allowEmptyString<T, R>(
  validate: Validate<T, R>
): Validate<T | "", Optional<R>> {
  return (value) => {
    if (value === "") {
      return undefined;
    }

    return validate(value);
  };
}

function validateEmail(value: string, message?: string): string {
  var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRegex.test(value.toLowerCase())) {
    return value;
  }
  throw new ValueError(message);
}

function validateIc(value: string, message?: string): string {
  if (value == undefined) {
    return undefined;
  }
  var icRegex = /^[0-9]+$/;
  if (icRegex.test(value.toLowerCase()) && value.length <= 8) {
    return value;
  }
  throw new ValueError(message);
}

function validateStringLength(value: string, maxLength: number, message?: string): string {
  if (value && value.length > maxLength) {
    throw new ValueError(message);
  }
  return value;
}

function validateDateString(value: any, message?: string): any {
  if (!value) {
    return undefined;
  }

  const validatingDate = value instanceof Date ? value : parseISO(value);
  if (!isValid(validatingDate)) {
    throw new ValueError(message);
  }
  return value;
}

export {
  // eslint-disable-next-line
  allowEmptyString,
  validateEmail,
  validateIc,
  validateStringLength,
  validateDateString
};
