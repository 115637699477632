enum UserRole {
    DISPATCHER = "DISPATCHER",
    DRIVER = "DRIVER",
    OWNER = "OWNER",
    ACCOUNTANT = "ACCOUNTANT",
    MANAGER = "MANAGER"
}

interface User {
    role: UserRole;
    name: string;
    email: string;
    phoneNumber?: string;
    avatarUrl?: string;
    parentId?: number;
}

interface UserSnapshot extends User {
    id: number;
    createdAt: Date;
    lastSeenAt: Date;
    weeklyRevenue: number;
    weeklyCommission: number;
    monthlyRevenue: number;
    monthlyCommission: number;
}

export {
    User,
    UserSnapshot,
    UserRole
};
