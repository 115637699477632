import React, {useState} from "react";

import {Box} from "@material-ui/core";
import WarehouseView from "./WarehouseView";
import UsersContainer from "../Users";
import PropTypes from "prop-types";
import WarehouseOrdersContainer from "../WarehouseOrders";
import {WarehouseOrdersModeEnum} from "../../enums/enums";
import WarehouseShipments from "../WarehouseShipments/WarehouseShipments";
import WarehouseShipmentsContainer from "../WarehouseShipments/WarehouseShipmentsContainer";

function TabPanel(props) {
	const {
		children, value, index, ...other
	} = props;

	return (
			<div
					role="tabpanel"
					hidden={value !== index}
					id={`simple-tabpanel-${index}`}
					aria-labelledby={`simple-tab-${index}`}
					{...other}
			>
				{value === index && (
						<Box p={3}>
							{children}
						</Box>
				)}
			</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

const WarehouseBodyContent = ({
																tabClasses,
																view,
																selectedWarehouse
															}) => {

	const [deleteShipmentDialogOpen, setDeleteShipmentDialogOpen] = useState(false);

	switch (view) {

		case WarehouseView.INCOMING_ORDERS: {
			return (
					<>
						<WarehouseOrdersContainer
								mode={WarehouseOrdersModeEnum.INCOMING_ORDERS}
								warehouseId={selectedWarehouse.id}
						/>
					</>
			);
		}

		case WarehouseView.ORDERS_HISTORY: {
			return (
					<>
						<WarehouseOrdersContainer
								mode={WarehouseOrdersModeEnum.ORDERS_HISTORY}
								warehouseId={selectedWarehouse.id}
						/>
					</>
			);
		}

		case WarehouseView.WAREHOUSEMEN: {
			return (
					<>
						<TabPanel>
							<div className={tabClasses.tableWrapper}>
								<UsersContainer
										mode="warehousemen"
										warehouseId={selectedWarehouse.id}
								/>
							</div>
						</TabPanel>
					</>
			);
		}

		case WarehouseView.INCOMING_SHIPMENTS: {
			return (
					<>
								<WarehouseShipmentsContainer
										setPageLoading={() => {}}
										setPageShipments={() => {}}
										setPageSelected={() => {}}
										deleteDialogOpen={deleteShipmentDialogOpen}
										setDeleteDialogOpen={setDeleteShipmentDialogOpen}
										mode={view}
										selectedWarehouse={selectedWarehouse}
								/>
					</>
			);
		}

		case WarehouseView.OUTCOMING_SHIPMENTS: {
			return (
					<>
								<WarehouseShipmentsContainer
										setPageLoading={() => {}}
										setPageShipments={() => {}}
										setPageSelected={() => {}}
										deleteDialogOpen={deleteShipmentDialogOpen}
										setDeleteDialogOpen={setDeleteShipmentDialogOpen}
										mode={view}
										selectedWarehouse={selectedWarehouse}
								/>
					</>
			);
		}

		default: {
			throw new Error(`Invalid warehouse view '${view}'!`);
		}
	}

};

export default WarehouseBodyContent;
