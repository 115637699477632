import React from "react";

const TextTable = ({ className, children }) => (
  <table className={className}>
    <tbody>
      {children}
    </tbody>
  </table>
);

export default TextTable;
