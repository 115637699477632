import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import SettingsView from "../core/Settings/SettingsView";
import SettingsContainer from "../core/Settings/SettingsContainer";

const SettingsContainerAdapter = ({ view }) => {
  const history = useHistory();

  const handleViewChange = value => {
    switch (value) {
      case SettingsView.COMPANY_AND_USER: {
        history.push(`/settings/company-and-user`);
        return;
      }

      case SettingsView.TARIFF: {
        history.push(`/settings/tariff`);
        return;
      }

      case SettingsView.ROLES: {
        history.push(`/settings/roles`);
        return;
      }

      case SettingsView.STATES: {
        history.push(`/settings/states`);
        return;
      }

      case SettingsView.EXCHANGE_RATES: {
        history.push(`/settings/exchange-rates`);
        return;
      }


      case SettingsView.BANK_ACCOUNTS: {
        history.push(`/settings/bank-accounts`);
        return;
      }

      case SettingsView.ROLE_EDITOR: {
        history.push(`/settings/role`);
        return;
      }

      case SettingsView.WAREHOUSES: {
        history.push(`/settings/warehouses`);
        return;
      }

      default: {
        throw new Error(`Invalid settings view '${view}'`);
      }
    }
  };

  return (
    <SettingsContainer
      view={view}
      onViewChange={handleViewChange}
    />
  );
};

export default SettingsContainerAdapter;
