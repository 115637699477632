import React, { useCallback } from "react";

import { ApiClient } from "@cargotic/api-client";
import { ContactType, Employee } from "@cargotic/model";
import {
  ListItemText,
  IconButton,
  InputAdornment,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import { Error } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import {
  FormValues,
  FormAutosuggestTextField,
  FormAutosuggestTextFieldProps
} from "../../../form";

import { suggestEmployee } from "../../utility";

type EmployeeFormAutosuggestTextField<T extends FormValues> =
  Omit<
    FormAutosuggestTextFieldProps<Employee, T>,
    "suggest" | "suggestionComponent" | "getSuggestionText"
  > & {
    apiClient: ApiClient;
    contactId?: number;
    contactType?: ContactType;
  }

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    color: palette.status.danger
  }
}));

function EmployeeFormAutosuggestTextField<T extends FormValues>(
  {
    apiClient,
    contactType,
    contactId,
    disabled,
    ...other
  }: EmployeeFormAutosuggestTextField<T>
): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  const suggest = useCallback((text) => {
    if (disabled) {
      return [];
    }

    return suggestEmployee(apiClient, text, contactId);
  }, [contactId]);

  function formatSuggestion({
    name,
    email,
    phoneNumber
  }: {
    name?: string;
    email?: string;
    phoneNumber?: string;
  }): string {
    const buffer = [];
    if (email) {
      buffer.push(email)
    }

    if (name) {
      buffer.push(name)
    }

    if (phoneNumber) {
      buffer.push(phoneNumber)
    }

    return buffer.join(", ");
  }

  const deletedAdornment = () => ({
    endAdornment: (
      <InputAdornment position="start">
        <Tooltip title={t("webapp:shipment.form.error.deletedEmployee")}>
          <IconButton className={classes.danger} size="small">
            <Error />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  })

  const resolveInputProps = () => {
    const { form: { values } } = other;
    const { name } = other;

    const employee = values[name];

    // if there is no contact selected yet, there is no point of showing the icon
    if (!employee) {
      return {}
    }

    const { isDeleted } = employee;
    if (isDeleted) {
      return deletedAdornment();
    }

    // if there is insurance and has legit date there is no point of showing the icon
    return {};
  }


  return (
    // @ts-ignore
    <FormAutosuggestTextField
      {...other}
      disabled={disabled}
      // @ts-ignore
      getSuggestionText={(suggestion) => {
        if (suggestion == null) {
          return '';
        }

        // @ts-ignore
        const { name, email, phoneNumber } = suggestion;
        return formatSuggestion({ name, email, phoneNumber });
      }}
      // @ts-ignore
      suggest={suggest}
      // @ts-ignore
      suggestionComponent={({ suggestion }) => {
        return (
          <ListItemText primary={formatSuggestion(suggestion)} />
        )
      }}
      // @ts-ignore
      InputProps={resolveInputProps()}
    />
  );
}

export default EmployeeFormAutosuggestTextField;
