import {Grid, makeStyles, Tooltip, Typography} from "@material-ui/core";
import {getActionFromWaypoint} from "../../../../utility/waypoint";
import {ArrowDownward, ArrowUpward, Info, SwapVert} from "@material-ui/icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {formatJourneyPointDateTime} from "../../../../utility/common";

const useStyles = makeStyles(({palette}) => ({
	journeyPointActionUpIcon: {
		fill: "#009688",
		height: 20,
		width: 20,
	},
	journeyPointActionDownIcon: {
		fill: palette.error.main,
		height: 20,
		width: 20,
	},
}));


const WarehouseOrdersDateTime = ({order}) => {
	const classes = useStyles();
	const {t} = useTranslation();

	return (
		<Grid key={order.id} container direction="column">
			{order.journey.waypoints.map((waypoint, index) => {
				const waypointAction = waypoint.cargo
						? getActionFromWaypoint(waypoint.cargo)
						: [];

				const actionIcon = (waypointAction) => {
					if (
							waypointAction.includes("LOAD") &&
							waypointAction.includes("UNLOAD")
					) {
						return (
								<Tooltip
										key={`${index}-both`}
										title={t("incomingOrders.action.both")}
								>
									<SwapVert className={classes.journeyPointActionUpIcon}/>
								</Tooltip>
						);
					}
					if (waypointAction.includes("LOAD")) {
						return (
								<Tooltip
										key={`${index}-load`}
										title={t("incomingOrders.action.loading")}
								>
									<ArrowUpward className={classes.journeyPointActionUpIcon}/>
								</Tooltip>
						);
					}
					if (waypointAction.includes("UNLOAD")) {
						return (
								<Tooltip
										key={`${index}-unload`}
										title={t("incomingOrders.action.unloading")}
								>
									<ArrowDownward className={classes.journeyPointActionDownIcon}/>
								</Tooltip>
						);
					}
					return (
							<Tooltip
									key={`${index}-none`}
									title={t("incomingOrders.action.none")}
							>
								<Info className={classes.journeyPointActionDownIcon}/>
							</Tooltip>
					);
				};

				const formattedDateTime = () => {
					let arriveAtFrom = waypoint.arriveAtFrom ? new Date(waypoint.arriveAtFrom) : undefined
					let arriveAtTo = waypoint.arriveAtTo ? new Date(waypoint.arriveAtTo) : undefined
					return formatJourneyPointDateTime(arriveAtFrom, arriveAtTo);
				}

				return (
						<Grid
								key={`${index}-grid`}
								container
								item
								spacing={1}
								alignItems="center"
						>
							<Grid item>{actionIcon(waypointAction)}</Grid>
							<Grid item xs={8}>
								<Typography variant="body2">
									{formattedDateTime()}
								</Typography>
							</Grid>
						</Grid>
				);
			})}
		</Grid>
);
}
export default WarehouseOrdersDateTime;