import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  Menu,
  MenuItem,
  Button,
  Checkbox,
  Select,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { Link } from "react-router-dom";

import Page from "../../common/Page";
import Placeholder from "../../common/Placeholder";
import StatisticsSummary from "../Statistics/StatisticsSummary";
import StatisticsLineChart from "../Statistics/StatisticsLineChart";
import StatisticsCard from "../Statistics/StatisticsCard";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import { RouterBreadcrumbs } from "../../../../cargotic-webapp-component";

import {
  readUserRevenueStatistics,
  readUserCommissionStatistics,
  readUserKpiStatistics,
  readUserShipmentsStatistics
} from "../../../resource";
import useRouter from "../../hook/useRouter";

import { Periods } from "../../enums/enums";

import {
  format
} from "date-fns";

import {
  formatDateWithoutYear,
} from "../../../utility/common";

import {
  calculatePercentageDiff
} from "../../../utility/functional";

import { ShipmentType } from "@cargotic/model-deprecated";

import {
  storeUserStatisticsFilter,
  loadUserStatisticsFilter
} from "../../../storage";

const UserStatistics = ({
  id,
  user
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [commissionStatistics, setCommissionStatistics] = useState([]);
  const [revenueStatistics, setRevenueStatistics] = useState([]);
  const [shipmentsStatistics, setShipmentsStatistics] = useState([]);

  const [loadingCommissionStatistics, setLoadingCommissionStatistics] = useState(true);
  const [loadingRevenueStatistics, setLoadingRevenueStatistics] = useState(true);
  const [loadingShipmentsStatistics, setLoadingShipmentsStatistics] = useState(true);

  const [userDataPeriod, setUserDataPeriod] = useState();
  const [shipmentsStatisticsPeriod, setShipmentsStatisticsPeriod] = useState();
  const [commissionStatisticsPeriod, setCommissionStatisticsPeriod] = useState();
  const [revenueStatisticsPeriod, setRevenueStatisticsPeriod] = useState();

  const [prevFilter, setPrevFilter] = useState(() => loadUserStatisticsFilter());
  const [filter, setFilter] = useState(() => loadUserStatisticsFilter([ShipmentType.FORWARDED, ShipmentType.CARRIED]));

  const breadcrumbs = [
    { label: t("menu.users"), href: "/users" },
    { label: user ? user.name : undefined, href: `/users/${id}` },
    { label: t("menu.statistics"), href: `/users/${id}` }
  ];

  useEffect(() => {
    storeUserStatisticsFilter(filter)
  }, [filter]);

  const handleSelectShipmentType = (types) => setFilter({ types });

  const shipmentTypeSource = [
    { title: t("statistics.forwarded"), value: ShipmentType.FORWARDED },
    { title: t("statistics.carried"), value: ShipmentType.CARRIED }
  ];

  useEffect(() => {
    try {
      if (JSON.stringify(filter.types.sort()) !== JSON.stringify(prevFilter.types.sort())) {
        fetchUserKpiStatistics(userDataPeriod);
        fetchUserRevenueStatistics(revenueStatisticsPeriod);
        fetchUserShipmentsStatistics(shipmentsStatisticsPeriod);

        if (!filter.CARRIED) {
          fetchUserCommissionStatistics(commissionStatisticsPeriod);
        }
      }
    } catch (err) {
      console.log(err);

      enqueueSnackbar(t("statistics.error.loading"), {
        variant: "error"
      });
    }

    setPrevFilter(filter);
  }, [filter]);

  const formatMyStatistics = (current, previous) => {
    const result = [];

    if (current.totalShipments) {
      result.push({
        ...current.totalShipments,
        dataDiff: previous?.totalShipments
          ? calculatePercentageDiff(current.totalShipments.data, previous.totalShipments.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalClients) {
      result.push({
        ...current.totalClients,
        dataDiff: previous?.totalClients
          ? calculatePercentageDiff(current.totalClients.data, previous.totalClients.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalDuration) {
      result.push({
        ...current.totalDuration,
        dataDiff: previous?.totalDuration
          ? calculatePercentageDiff(current.totalDuration.data, previous.totalDuration.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalDistance) {
      result.push({
        ...current.totalDistance,
        dataDiff: previous?.totalDistance
          ? calculatePercentageDiff(current.totalDistance.data, previous.totalDistance.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalRevenue) {
      result.push({
        ...current.totalRevenue,
        dataDiff: previous?.totalRevenue
          ? calculatePercentageDiff(current.totalRevenue.data, previous.totalRevenue.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalCommission) {
      result.push({
        ...current.totalCommission,
        dataDiff: previous?.totalCommission
          ? calculatePercentageDiff(current.totalCommission.data, previous.totalCommission.data)?.toFixed(2)
          : undefined
      })
    }

    return result;
  }

  const formatTooltipX = (data, period) => {
    if (period === Periods.HOUR) {
      return data.map(({ date }) => {
        return `${date.getDay()}.${date.getMonth()}. ${format(date, "HH")}h`;
      })
    }

    return data.map(({ date }) => {
      return formatDateWithoutYear(date);
    })
  }

  const formatData = (previousData, currentData) => {
    const formattedPrevious = currentData.map(({ x, y }, index) => {
      if (!previousData[index]) {
        return {
          x,
          y: null
        }
      }

      return {
        x,
        y: previousData[index].y
      }
    })

    return formattedPrevious;
  }

  const tranformInto2Curve = (current, previous, { term: period }) => {
    if (period === Periods.HOUR) {
      return [
        {
          id: "currentPeriod",
          data: current[0].data,
          tooltipX: formatTooltipX(current[0].data, period)
        },
        {
          id: "previousPeriod",
          data: previous[0].data,
          tooltipX: formatTooltipX(previous[0].data, period)
        }
      ]
    }

    const cuttedPreviousData = previous[0].data.slice(0, current[0].data.length);

    return [
      {
        id: "currentPeriod",
        data: current[0].data,
        tooltipX: formatTooltipX(current[0].data, period)
      },
      {
        id: "previousPeriod",
        data: formatData(cuttedPreviousData, current[0].data),
        tooltipX: formatTooltipX(cuttedPreviousData, period)
      }
    ]
  }

  const fetchUserKpiStatistics = async (period) => {
    setLoading(true);
    setUserDataPeriod(period);

    try {
      const kpiStatistics = period.start
        ? await readUserKpiStatistics(filter, id, period.start.toISOString(), period.end.toISOString())
        : await readUserKpiStatistics(filter, id)

      let previousKpiStatistics;
      if (period.startPrevious) {
        previousKpiStatistics = await readUserKpiStatistics(filter, id, period.startPrevious.toISOString(), period.endPrevious.toISOString());
      }

      setUserData(formatMyStatistics(kpiStatistics, previousKpiStatistics).map(item => ({ ...item, title: t(item.title) })));

      setLoading(false);
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        t("users.statistics.error"),
        { variant: "error" }
      );
      history.replace("/");
    }
  };

  const fetchUserRevenueStatistics = async (period) => {
    setLoadingRevenueStatistics(true);
    setRevenueStatisticsPeriod(period);

    try {
      const revenueStatistics = period.start
        ? await readUserRevenueStatistics(filter, id, period.start.toISOString(), period.end.toISOString(), period.term)
        : await readUserRevenueStatistics(filter, id);

      if (period.startPrevious) {
        const previousRevenueStatistics = await readUserRevenueStatistics(filter, id, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term);

        setRevenueStatistics(tranformInto2Curve(revenueStatistics, previousRevenueStatistics, period));
      }
      else {
        setRevenueStatistics(revenueStatistics);
      }

      setLoadingRevenueStatistics(false);
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        t("users.statistics.error"),
        { variant: "error" }
      );
      history.replace("/");
    }
  };

  const fetchUserCommissionStatistics = async (period) => {
    setLoadingCommissionStatistics(true);
    setCommissionStatisticsPeriod(period);

    try {
      const commissionStatistics = period.start
        ? await readUserCommissionStatistics(filter, id, period.start.toISOString(), period.end.toISOString(), period.term)
        : await readUserCommissionStatistics(filter, id);

      if (period.startPrevious) {
        const previousCommissionStatistics = await readUserCommissionStatistics(filter, id, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

        setCommissionStatistics(tranformInto2Curve(commissionStatistics, previousCommissionStatistics, period));
      }
      else {
        setCommissionStatistics(commissionStatistics);
      }

      setLoadingCommissionStatistics(false);
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        t("users.statistics.error"),
        { variant: "error" }
      );
      history.replace("/");
    }
  };

  const fetchUserShipmentsStatistics = async (period) => {
    setLoadingShipmentsStatistics(true);
    setShipmentsStatisticsPeriod(period);

    try {
      const shipmentStatistics = period.start
        ? await readUserShipmentsStatistics(filter, id, period.start.toISOString(), period.end.toISOString(), period.term)
        : await readUserShipmentsStatistics(filter, id);

      if (period.startPrevious) {
        const previousShipmentStatistics = await readUserShipmentsStatistics(filter, id, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

        setShipmentsStatistics(tranformInto2Curve(shipmentStatistics, previousShipmentStatistics, period));
      }
      else {
        setShipmentsStatistics(shipmentStatistics);
      }

      setLoadingShipmentsStatistics(false);
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        t("users.statistics.error"),
        { variant: "error" }
      );
      history.replace("/");
    }
  };

  return (
    <Page>
      <Grid container spacing={3}>
        <Grid item sm={3}><RouterBreadcrumbs value={breadcrumbs} /></Grid>
        <Grid item sm={9} style={{ display: "flex", justifyContent: "flex-end" }}>
          <FilterStaticDropdown
            id="types"
            placeholderTitle={t("statistics.shipmentType")}
            value={filter.types}
            onChange={(arr) => handleSelectShipmentType(arr.length === 0 ? [] : arr)}
            selectAll={() => handleSelectShipmentType(shipmentTypeSource.map(item => item.value))}
            onClear={() => handleSelectShipmentType([])}
            source={shipmentTypeSource}
          />
        </Grid>
      </Grid>
      <Placeholder
        loading={!(id && user)}
        render={() => (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <StatisticsCard
                  title={t("statistics.ordersTitle")}
                  fetchMethod={fetchUserShipmentsStatistics}
                  periodPicker
                >
                  <Placeholder
                    loading={loadingShipmentsStatistics}
                    render={() => (
                      <StatisticsLineChart
                        data={shipmentsStatistics}
                        axisYFormat=".2s"
                        yLabel="CZK"
                        isMoneyFormat
                        axisYFormat={(v) => `${v % 1 !== 0 ? "" : v}`}
                      />
                    )}
                  />
                </StatisticsCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StatisticsCard
                  title={t("statistics.personal")}
                  fetchMethod={fetchUserKpiStatistics}
                  periodPicker
                >
                  <Placeholder
                    loading={loading}
                    render={() => (
                      <StatisticsSummary data={userData} />
                    )}
                  />
                </StatisticsCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StatisticsCard
                  title={t("statistics.commission")}
                  fetchMethod={fetchUserCommissionStatistics}
                  periodPicker
                  hide={filter.types.includes(ShipmentType.CARRIED)}
                >
                  <Placeholder
                    loading={loadingCommissionStatistics}
                    render={() => (
                      <StatisticsLineChart data={commissionStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                    )}
                  />
                </StatisticsCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StatisticsCard
                  title={t("statistics.revenue")}
                  fetchMethod={fetchUserRevenueStatistics}
                  periodPicker
                >
                  <Placeholder
                    loading={loadingRevenueStatistics}
                    render={() => (
                      <StatisticsLineChart data={revenueStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                    )}
                  />
                </StatisticsCard>
              </Grid>
            </Grid>
          </>
        )} />
    </Page>
  );
};

export default UserStatistics;
