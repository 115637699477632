import React from "react";
import { Button, Tooltip, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  button: {
    padding: 12,
    background: "white",
    borderRadius: 3,
    color: "black",
    "&:hover": {
      backgroundColor: "white"
    }
  },
  bordered: {
    border: "2px dashed",
    borderColor: "grey"
  }
}));

const ButtonWithTooltip = ({
  onClick, children, selected, tooltipText, disabled = false, className
}) => {
  const classes = useStyles();

  const withTooltip = children => (
    <Tooltip title={tooltipText}>
      {children}
    </Tooltip>
  );

  const body = () => (
    <Button
      className={selected ? clsx(classes.button, classes.bordered, className) : clsx(classes.button, className)}
      type="button"
      variant="contained"
      disableRipple
      color="secondary"
      size="large"
      disabled={disabled}
      fullWidth
      onClick={onClick}
    >
      {children}
    </Button>
  );

  return tooltipText ? withTooltip(body()) : body();
};

export default ButtonWithTooltip;
