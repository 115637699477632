import { isEmpty } from "../collection";

const serializeQueryParameters = parameters =>
  Object
    .entries(parameters)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

export default serializeQueryParameters;
