import IBAN from "iban";
import StringMask from "string-mask";

const DEFAULT_COUNTRY_CODE = "CZ";

const BANK_CODE_LENGTH_CZ = 4;
const ACCOUNT_LENGTH_CZ = 10;
const PREFIX_LENGTH_CZ = 6;
const maskIBAN = "AA99 9999 9999 9999 9999 9999";

const convertCzFormatToBBAN = (acc) => {
    // prefix parse
    const indexOfPrefix = acc.indexOf("-");
    // eslint-disable-next-line
    let [prefix, withOutPrefix] =
        indexOfPrefix !== -1 ? acc.split("-") : ["", acc];
    prefix = prefix.padStart(PREFIX_LENGTH_CZ, "0");

    // account and bank code parse
    const indexOfBankCode = withOutPrefix.indexOf("/");
    // eslint-disable-next-line
    let [account, bankCode] =
        indexOfBankCode !== -1 ? withOutPrefix.split("/") : ["", ""];

    account = account.padStart(ACCOUNT_LENGTH_CZ, "0");

    return `${bankCode}${prefix}${account}`;
};

const convertBBANFormatToCz = (acc) => {
    if (acc) {
        const bankCode = acc.slice(0, BANK_CODE_LENGTH_CZ);
        const prefix = acc.slice(
            BANK_CODE_LENGTH_CZ,
            PREFIX_LENGTH_CZ + BANK_CODE_LENGTH_CZ
        );
        const account = acc.slice(-ACCOUNT_LENGTH_CZ);

        return `${prefix}-${account}/${bankCode}`;
    }

    return "";
};

const formatIBAN = (acc) => IBAN.printFormat(acc).replace(/ +/g, "");

const convertStringToIBANShape = (text) => {
    const formatter = new StringMask(maskIBAN);
    return formatter.apply(text);
};

export {
    convertCzFormatToBBAN,
    convertBBANFormatToCz,
    formatIBAN,
    DEFAULT_COUNTRY_CODE,
    convertStringToIBANShape
};
