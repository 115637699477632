import React, {useEffect, useMemo} from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select, Tabs, Tab, Input
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import ReactCountryFlag from "react-country-flag";
import {getAllPlaces} from "../../../resource";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from '@material-ui/core';

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  root: {
    [breakpoints.up("sm")]: {
      minWidth: 520
    }
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(4)
    }
  },
  progress: {
    marginTop: spacing(2)
  },
  formControl: {
    width: spacing(20),
    paddingTop: spacing(3)
  },
  selectTitle: {
    fontWeight: "600",
    marginBottom: spacing(1)
  },
  selectDepo: {
    fontWeight: "600",
    marginBottom: spacing(1),
    marginTop: spacing(1)
  },
  placeSuggestTop: {
    fontSize: "9px",
    color: "grey"
  },
  placeSuggestBottom: {
    fontSize: "9px",
    color: "black",
    borderTop: "solid 1px grey"
  },
  placeSuggestMain: {
    width: "300px"
  }
  }));

const ExportDialog = ({
  isOpen,
  isProcessing,
  isDone,
  title,
  handleSelectChange,
  link,
  linkLabel,
  startButtonLabel,
  downloadCSV,
  closeButtonLabel,
  infoText,
  processingText,
  doneText,
  close,
  exportCsv,
  setSelectedExportTab,
  selectedExportTab,
  exportOrdersRinkai,
  tabs,
  rinkaiDepo,
  selectValue,
  setRinkaiDepo,
  canOperateRinkai,
  handleSelectPlace
}) => {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    (async () => {
      let placesRes = await getAllPlaces();
      setPlaces(placesRes);
    })();
  }, []);

  const exportTabContent = useMemo(() => {
    if (selectedExportTab.type === "export") {
      return <>
        <DialogContentText>{infoText}</DialogContentText>
        <FormControl className={classes.formControl}>
          <InputLabel>{t("language.title")}</InputLabel>
          <Select
            autoWidth
            defaultValue={language}
            onChange={(event) => {
              setLanguage(event.target.value);
            }}
          >
            <MenuItem value="cs">
              <ReactCountryFlag
                code="cz"
                svg
                styleProps={{
                  width: "24px",
                  height: "24px"
                }}
              />
            </MenuItem>
            <MenuItem value="en">
              <ReactCountryFlag
                code="gb"
                svg
                styleProps={{
                  width: "24px",
                  height: "24px"
                }}
              />
            </MenuItem>
          </Select>
        </FormControl>
      </>;
    } else if (canOperateRinkai) {
      return <>
        <FormControl className={classes.formControl}>
          <div className={classes.selectTitle}>{t("invoices.export.selectTitle")}</div>
          <Select defaultValue={"rinkai"} onChange={handleSelectChange}>
            <MenuItem value="rinkai">Rinkai</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <div className={classes.selectDepo}>{t("orders.export.depoTitle")}</div>

          <Autocomplete
            id="autocomplete-places"
            className={classes.placeSuggestMain}
            options={places}
            onChange={(event, newValue) => {
              setRinkaiDepo(newValue);
            }}
            getOptionLabel={({ alias, formatted }) => `(${alias}) ${formatted}`}
            renderOption={({ alias, formatted}) =>
              <>
                <div className={classes.placeSuggestTop}>
                  ({alias})
                  <div className={classes.placeSuggestBottom}>
                    {formatted}
                  </div>
                </div>
              </>}
            renderInput={(params) => <TextField {...params} label={t("incomingOrders.places")} />}
          />
        </FormControl>
      </>;
    } else {
      return <></>;
    }
  }, [selectedExportTab]);

  let content = null;

  if (isProcessing) {
    content = (
      <>
        <CircularProgress classes={{root: classes.progress}}/>
        <DialogContentText>{processingText}</DialogContentText>
      </>
    );
  }
  // else if (isDone) {
  //   content = (
  //     <>
  //       <Grid container spacing={2} alignItems="center">
  //         <Grid item xs={12}>
  //           <DialogContentText>{doneText}</DialogContentText>
  //         </Grid>
  //         {/* <Grid item xs={10}>
  //           <LinkField label={linkLabel} value={link} fullWidth/>
  //         </Grid>
  //         <Grid item xs={2}>
  //           <Button fullWidth href={link} target="_blank" color="primary">
  //             {downloadCSV}
  //           </Button>
  //         </Grid> */}
  //       </Grid>
  //     </>
  //   );
  // }
  else {
    content = (
      <>
        <DialogContentText>{infoText}</DialogContentText>
        <FormControl>
          <Tabs
            value={selectedExportTab.id}
            onChange={(event, newlySelectedTabId) => {
              setSelectedExportTab(tabs.find((tab) => tab.id === newlySelectedTabId));
            }}
            indicatorColor="primary"
            textColor="secondary"
            aria-label="invoice type tabs"
          >
            {tabs.map(tab => <Tab id={tab.id} key={tab.id} label={t(tab.labelKey)}/>)}
          </Tabs>
          {exportTabContent}
        </FormControl>
      </>
    );
  }
  return (
    <Dialog classes={{ paper: classes.root }} open={isOpen} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isProcessing ||  (selectedExportTab.type === "api" && selectValue === 'rinkai' && rinkaiDepo === null)}
          onClick={() => {
            if (selectedExportTab.type === "export") {
              exportCsv(language);
            } else {
              exportOrdersRinkai();
            }
          }}
        >
          {startButtonLabel}
        </Button>
        <Button color="primary" onClick={close}>
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
