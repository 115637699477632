import React from "react";

import { Tooltip, IconButton } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const SelectColumnsButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("webapp:common.tooltip.select-columns-settings")} variant="dense">
      <IconButton aria-label="filter list" size="medium" onClick={onClick}>
        <Settings />
      </IconButton>
    </Tooltip>
  );
};

export default SelectColumnsButton;