import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { ShipmentType, ContactType } from "@cargotic/model";
import {
  format
} from "date-fns";

import theme from "../App/theme";
import {
  formatDateWithoutYear,
} from "../../../utility/common";

import {
  calculatePercentageDiff
} from "../../../utility/functional";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window";

import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

import {
  readMyStatistics,
  readMyCommissionStatistics,
  readMyRevenueStatistics,
  readMyShipmentStatistics,
  readCompanyStatistics,
  readCompanyCommissionStatistics,
  readCompanyRevenueStatistics,
  readCompanyShipmentStatistics,
  readCompanyInvoiceStatistics,
  readCompanyInvoiceSumStatistics,
  readCompanyIncomingOrdersStatistics,
  readCompanyOutcomingOrdersStatistics,
  readCompanyOutcomingOrderSumStatistics,

  suggestUsers,
  createPlacesQuery
} from "../../../resource";
import Statistics from "./Statistics";
import useAuth from "../../hook/useAuth";
import { Roles, Periods } from "../../enums/enums";

import { useApiClient } from "../../../../cargotic-webapp-component";

import useRouter from "../../hook/useRouter";

import {
  storeStatisticsFilter,
  loadStatisticsFilter,
  storeFilters,
  loadFilters
} from "../../../storage";

const useStyles = makeStyles(({ spacing }) => ({
  kpi: {
    marginBottom: spacing(4)
  },
  tercialActions: {
    "& > div:first-child > div > :last-child": {
      justifyContent: "flex-end",

      "& > div:last-child": {
        marginLeft: spacing(2)
      }
    }
  }
}));

const StatisticsContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user: { profileType, parentType } } = useAuth();
  const classes = useStyles();
  const { hasPermission } = useAuth();
  const apiClient = useApiClient();

  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingPersonal, setLoadingPersonal] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingShipments, setLoadingShipments] = useState(true);
  const [loadingIssuedInvoices, setLoadingIssuedInvoices] = useState(true);
  const [loadingIncomingOrders, setLoadingIncomingOrders] = useState(true);
  const [loadingOutcomingOrders, setLoadingOutcomingOrders] = useState(true);
  const [loadingOutcomingOrderSum, setLoadingOutcomingOrderSum] = useState(true);
  const [loadingIssuedInvoiceSum, setLoadingIssuedInvoiceSum] = useState(true);
  const [loadingReceivedInvoiceSum, setLoadingReceivedInvoiceSum] = useState(true);
  const [loadingCommissions, setLoadingCommissions] = useState(true);
  const [loadingRevenue, setLoadingRevenue] = useState(true);
  const [loadingReceivedInvoice, setLoadingReceivedInvoice] = useState(true);

  const [personalStatistics, setPersonalStatistics] = useState([]);
  const [companyStatistics, setCompanyStatistics] = useState([]);
  const [ordersStatistics, setOrdersStatistics] = useState([]);
  const [shipmentsStatistics, setShipmentsStatistics] = useState([]);
  const [commissionStatistics, setCommissionStatistics] = useState([]);
  const [revenueStatistics, setRevenueStatistics] = useState([]);
  const [receivedInvoicesStatistics, setReceivedInvoicesStatistics] = useState([]);
  const [issuedInvoicesStatistics, setIssuedInvoicesStatistics] = useState([]);
  const [incomingOrdersStatistics, setIncomingOrdersStatistics] = useState([]);
  const [outcomingOrdersStatistics, setOutcomingOrdersStatistics] = useState([]);
  const [outcomingOrderSumStatistics, setOutcomingOrderSumStatistics] = useState([]);
  const [issuedInvoiceSumStatistics, setIssuedInvoiceSumStatistics] = useState([]);
  const [receivedInvoiceSumStatistics, setReceivedInvoiceSumStatistics] = useState([]);

  const [personalStatisticsPeriod, setPersonalStatisticsPeriod] = useState();
  const [companyStatisticsPeriod, setCompanyStatisticsPeriod] = useState();
  const [ordersStatisticsPeriod, setOrdersStatisticsPeriod] = useState();
  const [shipmentsStatisticsPeriod, setShipmentsStatisticsPeriod] = useState();
  const [receivedInvoiceStatisticsPeriod, setReceivedInvoiceStatisticsPeriod] = useState();
  const [issuedInvoiceStatisticsPeriod, setIssuedInvoiceStatisticsPeriod] = useState();
  const [commissionStatisticsPeriod, setCommissionStatisticsPeriod] = useState();
  const [revenueStatisticsPeriod, setRevenueStatisticsPeriod] = useState();
  const [incomingOrdersStatisticsPeriod, setIncomingOrdersStatisticsPeriod] = useState([]);
  const [outcomingOrdersStatisticsPeriod, setOutcomingOrdersStatisticsPeriod] = useState([]);
  const [outcomingOrderSumStatisticsPeriod, setOutcomingOrderSumStatisticsPeriod] = useState([]);
  const [issuedInvoiceSumStatisticsPeriod, setIssuedInvoiceSumStatisticsPeriod] = useState([]);
  const [receivedInvoiceSumStatisticsPeriod, setReceivedInvoiceSumStatisticsPeriod] = useState([]);

  const [defaultFilters, setDefaultFilters] = useState([]);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);

  const { location: { search: routerSearch }, ...restRouter } = useRouter();

  const {
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  const [prevFilter, setPrevFilter] = useState(() => loadStatisticsFilter());
  const [filter, setFilter] = useState({ ...initFilter });

  const expandFilters = useCallback((values, fullValues) => values.map(item => fullValues.find(i => i.value === item)), []);

  useEffect(() => {
    addUrlParam("filter", filter);
  }, [filter]);

  const DEFAULT_FILTER_VALUES = ["customers", "carriers", "drivers", "vehicles", "places", "creator"];

  const handleSelectCustomer = (customer) => setFilter({ ...filter, customer });
  const handleSelectCarrier = (carrier) => setFilter({ ...filter, carrier });
  const handleSelectDrivers = (drivers) => setFilter({ ...filter, drivers });
  const handleSelectPlaces = (places) => setFilter({ ...filter, places });
  const handleSelectCreator = (creator) => setFilter({ ...filter, creator });
  const handleSelectVehicles = (vehicles) => setFilter({ ...filter, vehicles });
  const handleSelectPickupDateRange = (pickupDateRange) => setFilter({ ...filter, pickupDateRange });
  const handleSelectDeliveryDateRange = (deliveryDateRange) => setFilter({ ...filter, deliveryDateRange });

  const handleDeselectFilters = () => setFilter({});

  const handleFilterSettingsOpen = () => {
    setIsFilterSettingsOpen(true);
  };

  const availableFilters = useMemo(() => [
    {
      label: t("shipments.customer"),
      value: "customers"
    },
    {
      label: t("shipments.carrier"),
      value: "carriers"
    },
    {
      label: t("shipments.driver"),
      value: "drivers"
    },
    {
      label: t("shipments.vehicles"),
      value: "vehicles"
    },
    {
      label: t("shipments.places"),
      value: "places"
    },
    {
      label: t("shipments.creator"),
      value: "creator"
    },
  ], [t])

  useEffect(() => {
    const loadedFilters = loadFilters("statistics");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(DEFAULT_FILTER_VALUES, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, [availableFilters]);

  const onFilterSettingsSubmit = (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("statistics", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  useEffect(() => {
    storeStatisticsFilter(filter)
  }, [filter]);

  useEffect(() => {
    try {
      if (JSON.stringify(filter) !== JSON.stringify(prevFilter)) {

        fetchPersonalStatistics(personalStatisticsPeriod);
        fetchCompanyStatistics(companyStatisticsPeriod);
        fetchRevenueStatistics(revenueStatisticsPeriod);
        fetchShipmentStatistics(shipmentsStatisticsPeriod);
        fetchReceivedInvoiceStatistics(receivedInvoiceStatisticsPeriod);
        fetchIssuedInvoiceStatistics(issuedInvoiceStatisticsPeriod);
        fetchIncomingOrdersStatistics(incomingOrdersStatisticsPeriod);
        fetchOutcomingOrdersStatistics(outcomingOrdersStatisticsPeriod);
        fetchOutcomingOrderSumStatistics(outcomingOrderSumStatisticsPeriod);
        fetchIssuedInvoiceSumStatistics(issuedInvoiceSumStatisticsPeriod);
        fetchReceivedInvoiceSumStatistics(receivedInvoiceSumStatisticsPeriod);
        // if (!filter.CARRIED) {
        fetchCommissionStatistics(commissionStatisticsPeriod);
        // }
      }
    } catch (err) {
      console.log(err);

      enqueueSnackbar(t("statistics.error.loading"), {
        variant: "error"
      });
    }

    setPrevFilter(filter);
  }, [filter]);

  const formatTooltipX = (data, period) => {
    if (period === Periods.HOUR) {
      return data.map(({ date }) => {
        return `${date.getDay()}.${date.getMonth()}. ${format(date, "HH")}h`;
      })
    }

    return data.map(({ date }) => {
      return formatDateWithoutYear(date);
    })
  }

  const formatData = (previousData, currentData) => {
    const formattedPrevious = currentData.map(({ x, y }, index) => {
      if (!previousData[index]) {
        return {
          x,
          y: null
        }
      }

      return {
        x,
        y: previousData[index].y
      }
    })

    return formattedPrevious;
  }


  const transformFilter = (filter) => ({
    ...filter,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    carrier: filter.carrier ? filter.carrier.map(({ id }) => id) : undefined,
    customer: filter.customer ? filter.customer.map(({ id }) => id) : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined,
    places: filter.places ? filter.places.map(({ id }) => id) : undefined,
    creator: filter.creator ? filter.creator.map(({id}) => id) : undefined
  });

  const tranformInto2Curve = (current, previous, { term: period }) => {
    if (period === Periods.HOUR) {
      return [
        {
          id: "currentPeriod",
          data: current[0].data,
          tooltipX: formatTooltipX(current[0].data, period)
        },
        {
          id: "previousPeriod",
          data: previous[0].data,
          tooltipX: formatTooltipX(previous[0].data, period)
        }
      ]
    }

    const cuttedPreviousData = previous[0].data.slice(0, current[0].data.length);

    return [
      {
        id: "currentPeriod",
        data: current[0].data,
        tooltipX: formatTooltipX(current[0].data, period)
      },
      {
        id: "previousPeriod",
        data: formatData(cuttedPreviousData, current[0].data),
        tooltipX: formatTooltipX(cuttedPreviousData, period)
      }
    ]
  }

  const formatStatisticsCompany = (current, previous) => {
    const [
      totalShipments,
      totalClients,
      totalRevenue,
      totalCommission,
      totalIncomingOrders,
      totalOutcomingOrders,
      totalCarriers,
      totalOutcomingOrdersSum,
      totalReceivedInvoice,
      totalReceivedInvoiceSum,
      totalIssuedInvoice,
      totalIssuedInvoiceSum,
    ] = current;

    const [
      previousTotalShipments,
      previousTotalClients,
      previousTotalRevenue,
      previousTotalCommission,
      previousTotalIncomingOrders,
      previousTotalOutcomingOrders,
      previousTotalCarriers,
      previousTotalOutcomingOrdersSum,
      previousTotalReceivedInvoice,
      previousTotalReceivedInvoiceSum,
      previousTotalIssuedInvoice,
      previousTotalIssuedInvoiceSum,
  ] = previous;

    return [
      {
        ...totalShipments,
        dataDiff: calculatePercentageDiff(totalShipments.data, previousTotalShipments.data)?.toFixed(2)
      },
      {
        ...totalClients,
        dataDiff: calculatePercentageDiff(totalClients.data, previousTotalClients.data)?.toFixed(2)
      },
      {
        ...totalRevenue,
        dataDiff: calculatePercentageDiff(totalRevenue.data, previousTotalRevenue.data)?.toFixed(2)
      },
      {
        ...totalCommission,
        dataDiff: calculatePercentageDiff(totalCommission.data, previousTotalCommission.data)?.toFixed(2)
      },
      {
        ...totalIncomingOrders,
        dataDiff: calculatePercentageDiff(totalIncomingOrders.data, previousTotalIncomingOrders.data)?.toFixed(2)
      },
      {
        ...totalOutcomingOrders,
        dataDiff: calculatePercentageDiff(totalOutcomingOrders.data, previousTotalOutcomingOrders.data)?.toFixed(2)
      },
      {
        ...totalCarriers,
        dataDiff: calculatePercentageDiff(totalCarriers.data, previousTotalCarriers.data)?.toFixed(2)
      },
      {
        ...totalOutcomingOrdersSum,
        dataDiff: calculatePercentageDiff(totalOutcomingOrdersSum.data, previousTotalOutcomingOrdersSum.data)?.toFixed(2)
      },
      {
        ...totalReceivedInvoice,
        dataDiff: calculatePercentageDiff(totalReceivedInvoice.data, previousTotalReceivedInvoice.data)?.toFixed(2)
      },
      {
        ...totalReceivedInvoiceSum,
        dataDiff: calculatePercentageDiff(totalReceivedInvoiceSum.data, previousTotalReceivedInvoiceSum.data)?.toFixed(2)
      },
      {
        ...totalIssuedInvoice,
        dataDiff: calculatePercentageDiff(totalIssuedInvoice.data, previousTotalIssuedInvoice.data)?.toFixed(2)
      },
      {
        ...totalIssuedInvoiceSum,
        dataDiff: calculatePercentageDiff(totalIssuedInvoiceSum.data, previousTotalIssuedInvoiceSum.data)?.toFixed(2)
      }
    ]
  }

  const formatMyStatistics = (current, previous) => {
    const result = [];

    if (current.totalShipments) {
      result.push({
        ...current.totalShipments,
        dataDiff: previous?.totalShipments
          ? calculatePercentageDiff(current.totalShipments.data, previous.totalShipments.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalClients) {
      result.push({
        ...current.totalClients,
        dataDiff: previous?.totalClients
          ? calculatePercentageDiff(current.totalClients.data, previous.totalClients.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalDuration) {
      result.push({
        ...current.totalDuration,
        dataDiff: previous?.totalDuration
          ? calculatePercentageDiff(current.totalDuration.data, previous.totalDuration.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalDistance) {
      result.push({
        ...current.totalDistance,
        dataDiff: previous?.totalDistance
          ? calculatePercentageDiff(current.totalDistance.data, previous.totalDistance.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalRevenue) {
      result.push({
        ...current.totalRevenue,
        dataDiff: previous?.totalRevenue
          ? calculatePercentageDiff(current.totalRevenue.data, previous.totalRevenue.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalCommission) {
      result.push({
        ...current.totalCommission,
        dataDiff: previous?.totalCommission
          ? calculatePercentageDiff(current.totalCommission.data, previous.totalCommission.data)?.toFixed(2)
          : undefined
      })
    }

    return result;
  }

  const fetchPersonalStatistics = async (period) => {
    setPersonalStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.user.read")) {
      setLoadingPersonal(true);
      try {
        const myStatistics = period.start
          ? await readMyStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString())
          : await readMyStatistics(transformedFilter)

        let previousMyStatistics;
        if (period.startPrevious) {
          previousMyStatistics = await readMyStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term);

        }

        setPersonalStatistics(formatStatisticsCompany(myStatistics, previousMyStatistics).map(item => ({ ...item, title: t(item.title) })));
        setLoadingPersonal(false);
      } catch (error) {
        console.error(error);
        setLoadingPersonal(false);
        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  }

  const fetchCompanyStatistics = async (period) => {
    setCompanyStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingCompany(true);
      try {
        const companyStatistics = period.start
          ? await readCompanyStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString())
          : await readCompanyStatistics(transformedFilter);

        let fullStatistics = companyStatistics;
        if (period.startPrevious) {
          const previousCompanyStatistics = await readCompanyStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term);

          fullStatistics = formatStatisticsCompany(companyStatistics, previousCompanyStatistics);
        }

        setCompanyStatistics(fullStatistics.map(item => ({ ...item, title: t(item.title) })));
        setLoadingCompany(false);
      } catch (error) {
        console.error(error);
        setLoadingCompany(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  }

  const fetchReceivedInvoiceStatistics = async (period) => {
    setReceivedInvoiceStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read") && hasPermission("resource.invoice.read")) {
      setLoadingReceivedInvoice(true);
      try {
        const receivedInvoice = period.start ?
          await readCompanyInvoiceStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term, "received")
          : await readCompanyInvoiceStatistics(transformedFilter, undefined, undefined, undefined, "received");

        if (period.startPrevious) {
          const previousReceivedInvoiceStatistics = await readCompanyInvoiceStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term, "received")
          const transformed = tranformInto2Curve(receivedInvoice, previousReceivedInvoiceStatistics, period);
          setReceivedInvoicesStatistics(transformed);
        }
        else {
          setReceivedInvoicesStatistics(receivedInvoice);
        }

        setLoadingReceivedInvoice(false);
      } catch (error) {
        console.error(error);
        setLoadingReceivedInvoice(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      console.warn("You do not have statistics.company.read or invoice.read permission");
    }
  }

  const fetchIssuedInvoiceStatistics = async (period) => {
    setIssuedInvoiceStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read") && hasPermission("resource.invoice.read")) {
      setLoadingIssuedInvoices(true);
      try {
        const issuedInvoices = period.start
          ? await readCompanyInvoiceStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term, "issued")
          : await readCompanyInvoiceStatistics(transformedFilter, undefined, undefined, undefined, "issued");

        if (period.startPrevious) {
          const previousIssuedInvoices = await readCompanyInvoiceStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term, "issued")

          setIssuedInvoicesStatistics(tranformInto2Curve(issuedInvoices, previousIssuedInvoices, period));
        }
        else {
          setIssuedInvoicesStatistics(issuedInvoices);
        }

        setLoadingIssuedInvoices(false);
      } catch (error) {
        console.error(error);
        setLoadingIssuedInvoices(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      console.warn("You do not have statistics.company.read or invoice.read permission");
    }
  }


  const fetchIncomingOrdersStatistics = async (period) => {
    setIncomingOrdersStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingIncomingOrders(true);
      try {
        const issuedInvoices = period.start
          ? await readCompanyIncomingOrdersStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyIncomingOrdersStatistics(transformedFilter);

        if (period.startPrevious) {
          const previousIssuedInvoices = await readCompanyIncomingOrdersStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setIncomingOrdersStatistics(tranformInto2Curve(issuedInvoices, previousIssuedInvoices, period));
        }
        else {
          setIncomingOrdersStatistics(issuedInvoices);
        }

        setLoadingIncomingOrders(false);
      } catch (error) {
        console.error(error);
        setLoadingIncomingOrders(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      console.warn("You do not have statistics.company.read or invoice.read permission");
    }
  }


  const fetchIssuedInvoiceSumStatistics = async (period) => {
    setIssuedInvoiceSumStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingIssuedInvoiceSum(true);
      try {
        const issuedInvoices = period.start
          ? await readCompanyInvoiceSumStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term, "issued")
          : await readCompanyInvoiceSumStatistics(transformedFilter, undefined, undefined, undefined, "issued");

        if (period.startPrevious) {
          const previousIssuedInvoices = await readCompanyInvoiceSumStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term, "issued")

          setIssuedInvoiceSumStatistics(tranformInto2Curve(issuedInvoices, previousIssuedInvoices, period));
        }
        else {
          setIssuedInvoiceSumStatistics(issuedInvoices);
        }

        setLoadingIssuedInvoiceSum(false);
      } catch (error) {
        console.error(error);
        setLoadingIssuedInvoiceSum(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      console.warn("You do not have statistics.company.read or invoice.read permission");
    }
  }


  const fetchReceivedInvoiceSumStatistics = async (period) => {
    setReceivedInvoiceSumStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read") && hasPermission("resource.invoice.read")) {
      setLoadingReceivedInvoiceSum(true);
      try {
        const issuedInvoices = period.start
          ? await readCompanyInvoiceSumStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term, "received")
          : await readCompanyInvoiceSumStatistics(transformedFilter, undefined, undefined, undefined, "received");

        if (period.startPrevious) {
          const previousIssuedInvoices = await readCompanyInvoiceSumStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term, "received")

          setReceivedInvoiceSumStatistics(tranformInto2Curve(issuedInvoices, previousIssuedInvoices, period));
        }
        else {
          setReceivedInvoiceSumStatistics(issuedInvoices);
        }

        setLoadingReceivedInvoiceSum(false);
      } catch (error) {
        console.error(error);
        setLoadingReceivedInvoiceSum(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      console.warn("You do not have statistics.company.read or invoice.read permission");
    }
  }

  const fetchOutcomingOrderSumStatistics = async (period) => {
    setOutcomingOrderSumStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read") && hasPermission("resource.incomingOrder.user.read")) {
      setLoadingOutcomingOrderSum(true);
      try {
        const outcomingOrderSum = period.start
          ? await readCompanyOutcomingOrderSumStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyOutcomingOrderSumStatistics(transformedFilter);

        if (period.startPrevious) {
          const previousOutcomingOrderSum = await readCompanyInvoiceStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term, "issued")

          setOutcomingOrderSumStatistics(tranformInto2Curve(outcomingOrderSum, previousOutcomingOrderSum, period));
        }
        else {
          setOutcomingOrderSumStatistics(outcomingOrderSum);
        }

        setLoadingOutcomingOrderSum(false);
      } catch (error) {
        console.error(error);
        setLoadingOutcomingOrderSum(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      console.warn("You do not have statistics.company.read or invoice.read permission");
    }
  }

  const fetchOutcomingOrdersStatistics = async (period) => {
    setOutcomingOrdersStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read") && hasPermission("resource.incomingOrder.user.read")) {
      setLoadingOutcomingOrders(true);
      try {
        const outcomingOrders = period.start
          ? await readCompanyOutcomingOrdersStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyOutcomingOrdersStatistics(transformedFilter);

        if (period.startPrevious) {
          const previousIssuedInvoices = await readCompanyInvoiceStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term, "issued")

          setOutcomingOrdersStatistics(tranformInto2Curve(outcomingOrders, previousIssuedInvoices, period));
        }
        else {
          setOutcomingOrdersStatistics(outcomingOrders);
        }

        setLoadingOutcomingOrders(false);
      } catch (error) {
        console.error(error);
        setLoadingOutcomingOrders(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      console.warn("You do not have statistics.company.read or invoice.read permission");
    }
  }

  const fetchShipmentStatistics = async (period) => {
    setShipmentsStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingShipments(true);
      try {
        const shipmentStatistics = period.start
          ? await readCompanyShipmentStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyShipmentStatistics(transformedFilter);

        if (period.startPrevious) {
          const previousShipmentStatistics = await readCompanyShipmentStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setShipmentsStatistics(tranformInto2Curve(shipmentStatistics, previousShipmentStatistics, period));
        }
        else {
          setShipmentsStatistics(shipmentStatistics);
        }

        setLoadingShipments(false);
      } catch (error) {
        console.error(error);
        setLoadingShipments(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      try {
        setLoadingShipments(true);
        const shipmentStatistics = period.start
          ? await readMyShipmentStatistics(transformedFilter, period.start, period.end, period.term)
          : await readMyShipmentStatistics(transformedFilter,);

        if (period.startPrevious) {
          const previousShipmentStatistics = await readMyShipmentStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setShipmentsStatistics(tranformInto2Curve(shipmentStatistics, previousShipmentStatistics, period));
        }
        else {
          setShipmentsStatistics(shipmentStatistics);
        }

        setLoadingShipments(false);
      } catch (error) {
        console.error(error);
        setLoadingShipments(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  }

  const fetchCommissionStatistics = async (period) => {
    setCommissionStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingCommissions(true);
      try {
        const commissionStatistics = period.start
          ? await readCompanyCommissionStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyCommissionStatistics(transformedFilter,);


        if (period.startPrevious) {
          const previousCommissionStatistics = await readCompanyCommissionStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setCommissionStatistics(tranformInto2Curve(commissionStatistics, previousCommissionStatistics, period));
        }
        else {
          setCommissionStatistics(commissionStatistics);
        }

        setLoadingCommissions(false);
      } catch (error) {
        console.error(error);
        setLoadingCommissions(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      try {
        const commissionStatistics = period.start
          ? await readMyCommissionStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readMyCommissionStatistics(transformedFilter);

        if (period.startPrevious) {
          const previousCommissionStatistics = await readMyCommissionStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setCommissionStatistics(tranformInto2Curve(commissionStatistics, previousCommissionStatistics, period));
        }
        else {
          setCommissionStatistics(commissionStatistics);
        }

        setLoadingCommissions(false);
      } catch (error) {
        console.error(error);
        setLoadingCommissions(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  };

  const fetchRevenueStatistics = async (period) => {
    setRevenueStatisticsPeriod(period);
    const transformedFilter = transformFilter(filter);

    if (hasPermission("resource.statistics.company.read")) {
      try {
        setLoadingRevenue(true);
        const revenueStatistics = period.start
          ? await readCompanyRevenueStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyRevenueStatistics(transformedFilter);

        if (period.startPrevious) {
          const previousRevenueStatistics = await readCompanyRevenueStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setRevenueStatistics(tranformInto2Curve(revenueStatistics, previousRevenueStatistics, period));
        }
        else {
          setRevenueStatistics(revenueStatistics);
        }

        setLoadingRevenue(false);
      } catch (error) {
        console.error(error);
        setLoadingRevenue(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      try {
        setLoadingRevenue(true);
        const revenueStatistics = period.start
          ? await readMyRevenueStatistics(transformedFilter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readMyRevenueStatistics(transformedFilter);

        if (period.startPrevious) {
          const previousRevenueStatistics = await readMyRevenueStatistics(transformedFilter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setRevenueStatistics(tranformInto2Curve(revenueStatistics, previousRevenueStatistics, period));
        }
        else {
          setRevenueStatistics(revenueStatistics);
        }

        setLoadingRevenue(false);
      } catch (error) {
        console.error(error);
        setLoadingRevenue(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  };

  const loadAvailableContacts = async (types, search) => {
    try {
      const contacts = await apiClient.contact.postContactSuggestQuery({
        query: {
          search, types
        }
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search
        }
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableUsers = async (search, permissions) => {
    try {
      const matches = await suggestUsers({ permissions, search });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const handleLoadAvailablePlaces = async (search) => {
    try {
      const { places } = await createPlacesQuery({
        filter: { search, id: null }
      });
      return places;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  return (
    <>
      <Statistics
        classes={classes}

        hasPermissionUserRead={hasPermission("resource.statistics.user.read")}
        hasPermissionCompanyRead={hasPermission("resource.statistics.company.read")}
        hasPermissionIncomingOrderRead={hasPermission("resource.incomingOrder.user.read")}
        hasPermissionInvoiceRead={hasPermission("resource.invoice.read")}
        hasPermissionOutcomingOrderRead={hasPermission("resource.outcomingOrder.user.read")}

        loadingPersonalStatistics={loadingPersonal}
        loadingCompanyStatistics={loadingCompany}
        loadingShipmentsStatistics={loadingShipments}
        loadingRevenueStatistics={loadingRevenue}
        loadingCommissionStatistics={loadingCommissions}
        loadingReceivedInvoiceStatistics={loadingReceivedInvoice}
        loadingIssuedInvoiceStatistics={loadingIssuedInvoices}
        loadingIncomingOrdersStatistics={loadingIncomingOrders}
        loadingOutcomingOrdersStatistics={loadingOutcomingOrders}
        loadingOutcomingOrderSumStatistics={loadingOutcomingOrderSum}
        loadingIssuedInvoiceSumStatistics={loadingIssuedInvoiceSum}
        loadingReceivedInvoiceSumStatistics={loadingReceivedInvoiceSum}

        filter={filter}

        personalStatistics={personalStatistics}
        companyStatistics={companyStatistics}
        shipmentsStatistics={shipmentsStatistics}
        revenueStatistics={revenueStatistics}
        commissionStatistics={commissionStatistics}
        receivedInvoiceStatistics={receivedInvoicesStatistics}
        issuedInvoiceStatistics={issuedInvoicesStatistics}
        outcomingOrdersStatistics={outcomingOrdersStatistics}
        outcomingOrderSumStatistics={outcomingOrderSumStatistics}
        incomingOrdersStatistics={incomingOrdersStatistics}
        receivedInvoiceSumStatistics={receivedInvoiceSumStatistics}
        issuedInvoiceSumStatistics={issuedInvoiceSumStatistics}

        fetchPersonalStatistics={fetchPersonalStatistics}
        fetchCompanyStatistics={fetchCompanyStatistics}
        fetchShipmentsStatistics={fetchShipmentStatistics}
        fetchRevenueStatistics={fetchRevenueStatistics}
        fetchCommissionStatistics={fetchCommissionStatistics}
        fetchIssuedInvoiceStatistics={fetchIssuedInvoiceStatistics}
        fetchReceivedInvoiceStatistics={fetchReceivedInvoiceStatistics}
        fetchIncomingOrdersStatistics={fetchIncomingOrdersStatistics}
        fetchOutcomingOrdersStatistics={fetchOutcomingOrdersStatistics}
        fetchOutcomingOrderSumStatistics={fetchOutcomingOrderSumStatistics}
        fetchIssuedInvoiceSumStatistics={fetchIssuedInvoiceSumStatistics}
        fetchReceivedInvoiceSumStatistics={fetchReceivedInvoiceSumStatistics}
        fetchIssuedInvoiceStatistics={fetchIssuedInvoiceStatistics}

        handleLoadAvailableContacts={loadAvailableContacts}
        handleLoadAvailableVehicles={loadAvailableVehicles}
        handleLoadAvailableUsers={loadAvailableUsers}
        handleLoadAvailablePlaces={handleLoadAvailablePlaces}

        handleSelectCustomer={handleSelectCustomer}
        handleSelectCarrier={handleSelectCarrier}
        handleSelectVehicles={handleSelectVehicles}
        handleSelectDrivers={handleSelectDrivers}
        handleSelectPlaces={handleSelectPlaces}
        handleSelectCreator={handleSelectCreator}

        handleDeselectFilters={handleDeselectFilters}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={() => setIsFilterSettingsOpen(false)}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default StatisticsContainer;
