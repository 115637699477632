import React, { useState, useEffect } from "react";
import { cs, enUS } from "date-fns/locale";
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";

const ShipmentConceptDialog = ({
  t,
  isOpen,
  onClose
}) => (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        {t("shipment.continueInConcept")}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          {t("shipment.discard")}
        </Button>
        <Button onClick={() => onClose(true)} color="primary">
          {t("shipment.continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );

export default ShipmentConceptDialog;