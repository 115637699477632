import React from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
  Link,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  Delete,
  ExpandMore,
  ExpandLess,
  FileCopy,
  ExitToApp,
  Launch
} from "@material-ui/icons";

import { getCargoItemActionIcon } from "../../../cargotic-component";
import { CargoItemAction, formatCargoItem } from "../../../cargotic-core";
import { getCargoItemActionReverseIcon } from "../../utility";
import CargoEditorCargoItemListItemForm from
  "./CargoEditorCargoItemListItemForm";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: "relative",
  },
  actions: {
    position: "absolute",

    paddingTop: 0,
    paddingBottom: 12,

    bottom: 0,
    right: 0
  },
  collapsed: {
    top: 0
  },
  header: {
    display: "flex",
    padding: spacing(1),
    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    }
  }
}));

const CargoEditorCargoItemListItem = ({
  item,
  isExpanded,
  onChange,
  onCollapse,
  onCopy,
  onDelete,
  onExpand
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    action,
    template,
    quantity,
    description,
    width,
    height,
    length,
    lengthUnit,
    weight,
    volume,
    totalWeight,
    totalVolume,
    isStackable,
    cargoValue,
    cargoValueCurrency,
    incomingOrder
  } = item;

  const Icon = getCargoItemActionIcon(action);

  const getActions = () => {
    return (
      <>
        <Tooltip title={t("webapp:cargo.editor.button.copy")}>
          <IconButton size="small" onClick={onCopy}>
            <FileCopy />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("webapp:cargo.editor.button.delete")}>
          <IconButton size="small" onClick={onDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
        {
          isExpanded
            ? (
              <Tooltip title={t("webapp:cargo.editor.button.collapse")}>
                <IconButton size="small" onClick={onCollapse}>
                  <ExpandLess />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("webapp:cargo.editor.button.expand")}>
                <IconButton size="small" onClick={onExpand}>
                  <ExpandMore />
                </IconButton>
              </Tooltip>
            )
        }
      </>
    );
  };

  return (
    <Card className={classes.root} data-cy="cargo-item">
      <CardContent>
        <section className={classes.header}>
          <Icon />
          <section>
            <Typography>
              {formatCargoItem(t, item)}
            </Typography>
            <Typography>
              {item.incomingOrder ? (
                <Link
                  component={RouterLink}
                  to={`/incoming-order/${item.incomingOrder.incomingOrderId}`}
                  target="_blank"
                  rel="noopener"
                  >
                  #{item.incomingOrder.indexNumber}
                  <Tooltip title={t("webapp:cargo.editor.button.new-tab")}>
                    <Launch style={{ width: 12, height: 12 }}/>
                  </Tooltip>
                </Link>
              ) : undefined}
            </Typography>
          </section>
        </section>
        <Collapse
          in={isExpanded}
        >
          <CargoEditorCargoItemListItemForm
            className={classes.form}
            template={template}
            quantity={quantity}
            description={description}
            width={width}
            height={height}
            length={length}
            lengthUnit={lengthUnit}
            volume={volume}
            weight={weight}
            totalVolume={totalVolume}
            totalWeight={totalWeight}
            isStackable={isStackable}
            cargoValue={cargoValue}
            cargoValueCurrency={cargoValueCurrency}
            onChange={onChange}
          />
        </Collapse>
      </CardContent>
      <CardActions className={classes.actions}>
        {getActions()}
      </CardActions>
    </Card>
  );
};

export default CargoEditorCargoItemListItem;
