import React from "react";

import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox
} from "@material-ui/core";

import FilterDynamicSuggestionDropdown from "../FilterDynamicSuggestionDropdown";

const PlaceFilterDropdown = ({
  placeholderTitle,
  onChange,
  value,
  selectAll,
  search,
  getTitle,
  onClear,
  disabled = false
}) => (
  <FilterDynamicSuggestionDropdown
    placeholderTitle={placeholderTitle}
    onChange={onChange}
    value={value}
    disabled={disabled}
    selectAll={selectAll}
    renderItem={({ id, alias, name }, checked, handleChange) => (
      <ListItem button key={id} onClick={() => handleChange({ target: { checked: !checked } })}>
        <ListItemText primary={alias} secondary={name} />
        <ListItemSecondaryAction>
          <Checkbox
            color="primary"
            checked={checked}
            edge="end"
            onChange={handleChange}
          />
        </ListItemSecondaryAction>
      </ListItem>)}
    search={search}
    getTitle={getTitle}
    onClear={onClear}
  />
);

export default PlaceFilterDropdown;
