import React from "react";

import { Chip } from "@material-ui/core";
import {
  DirectionsCar as DirectionsCarIcon,
  Face as FaceIcon,
  Place as PlaceIcon,
  ShoppingBasket as ShoppingBasketIcon
} from "@material-ui/icons";

const ChipTag = ({ label, type }) => {
  const Icon = (() => {
    switch (type) {
      case "CARGO_TYPE": {
        return ShoppingBasketIcon;
      }

      case "CONTACT_TYPE": {
        return FaceIcon;
      }

      case "GEOGRAPHICAL": {
        return PlaceIcon;
      }

      case "VEHICLE_TYPE": {
        return DirectionsCarIcon;
      }

      default: {
        throw new Error(`Invalid tag type '${type}'!`);
      }
    }
  })();

  return (
    <Chip
      icon={(
        <Icon />
      )}
      label={label}
    />
  );
};

export default ChipTag;
