import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  CardActions,
  CardHeader,
  CardContent,
  TextField,
  RadioGroup,
  Card,
  Radio,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormControl,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import ExchangeRateMode from "./ExchangeRateMode";

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      padding: spacing(1)
    }
  }
}));

const ExchangeRateCard = ({
  type,
  setType,
  hasUpdateExchangeRateModePermission
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader title={t("webapp:settings.title.exchange-rates")} />
      <CardContent className={classes.content}>
        <div>
          <Typography><b>{t("settings.nativeCurrency")}</b></Typography>
          <TextField
            name="currencySelect"
            value="CZK"
            disabled
          />
        </div>
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("webapp:settings.title.exchange-rate-mode")}</FormLabel>
            <RadioGroup
              aria-label="fetch-exchange-rates-type"
              name="fetch-exchange-rates-type"
              value={type}
              disabled={!hasUpdateExchangeRateModePermission}
              onChange={({ target: { value } }) => setType(value)}
            >
              <FormControlLabel
                value={ExchangeRateMode.AUTOMATIC}
                control={<Radio />}
                disabled={!hasUpdateExchangeRateModePermission}
                label={t("webapp:settings.title.exchange-rate-mode-automatic")}
              />
              <FormControlLabel
                value={ExchangeRateMode.MANUAL}
                disabled={!hasUpdateExchangeRateModePermission}
                control={<Radio />}
                label={t("webapp:settings.title.exchange-rate-mode-manual")}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </CardContent>
    </Card>
  );
};

export default ExchangeRateCard;
