import clsx from "clsx";
import React from "react";

import {
  IconButton,
  LinearProgress,
  Link,
  Typography,
  makeStyles
} from "@material-ui/core";

import { red } from "@material-ui/core/colors";

import { Clear, Description } from "@material-ui/icons";

import { hexToRgba } from "../../../cargotic-common";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",

    padding: spacing(1)
  },
  icon: {
    fontSize: 42
  },
  content: {
    flex: 1,

    marginLeft: spacing(1),
    marginRight: spacing(1),

    "& > :first-child h6": {
      lineHeight: 1.2
    },

    "& > :last-child .MuiLinearProgress-root": {
      marginBottom: spacing(2),
      marginTop: spacing(2)
    }
  },
  error: {
    "& h6.MuiTypography-subtitle2": {
      color: red[500]
    },

    "& svg.MuiSvgIcon-fontSizeLarge": {
      color: red[500]
    }
  }
}));

const FileUpload = ({
  className,
  title,
  url,
  description,
  error,
  isUploading,
  onDelete
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        className,
        classes.root,
        {
          [classes.error]: error !== undefined
        }
      )}
    >
      <section>
        <IconButton
          size="small"
          href={url}
          disabled={!url}
          target="_blank"
          rel="noopener"
        >
          <Description className={classes.icon} fontSize="large" />
        </IconButton>
      </section>
      <section className={classes.content}>
        <div>
          <Typography className={classes.title} variant="subtitle1">
            {
              url
                ? (
                  <Link href={url} target="_blank" rel="noopener">
                    {title}
                  </Link>
                ) : (
                  title
                )
            }
          </Typography>
        </div>
        <div>
          {isUploading ? (
            <LinearProgress />
          ) : (
            <Typography variant="subtitle2" color="textSecondary">
              {
                error !== undefined
                  ? error
                  : description
              }
            </Typography>
          )}
        </div>
      </section>
      <section>
        <IconButton disabled={isUploading} onClick={onDelete}>
          <Clear />
        </IconButton>
      </section>
    </div>
  );
};

export default FileUpload;
