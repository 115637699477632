import React, { useEffect, useReducer, useState } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  addMilliseconds,
  differenceInMilliseconds,
  getDate,
  getMonth,
  getYear,
  setDate,
  setMonth,
  setYear
} from "date-fns";
import { pipe } from "@cargotic/common-deprecated";

import {
  IncomingOrder as IncomingOrderT,
  IncomingOrderForm as IncomingOrderFormT,
  IncomingOrderState
} from "@cargotic/model";
import { useExchangeRates, Currency } from "@cargotic/currency";

import { CircularProgress, makeStyles, Slide } from "@material-ui/core";
import queryString from "query-string";

import { Contact } from "@cargotic/model";
import { useApiClient } from "../../../../cargotic-webapp-component";

import { loadDefaultUnitLength, storeDefaultUniLength } from "../../../storage";
import useRouter from "../../../component/hook/useRouter";
import useAuth from "../../../component/hook/useAuth";
import JourneyPlanner from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner";
import CargoticMapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import { Waypoint } from "../../../../../multiload/cargotic-core";
import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import IncomingOrderForm from "../IncomingOrderForm";
import { dehydrateWaypoints, hydrateWaypoints } from "../../../../../multiload/cargotic-webapp/utility";
import useIncomingOrderConcept from "../../../component/hook/useIncomingOrderConcept";
import IncomingOrderConceptDialog from "../IncomingOrderConceptDialog";

import JourneyPlannerReducer
  from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerReducer";
import JourneyPlannerState from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerState";
import JourneyPlannerAction
  from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerAction";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";
import useOutcomingOrderConcept from "../../../component/hook/useOutcomingOrderConcept";
import {
  getNextIncomingOrderNumber,
  putIncomingOrderById
} from "../../../resource";
import { loadActiveFilters } from "../../../storage";

const INCOMING_ORDER_INDEX_NUMBER_MIN_LENGTH = 4;

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: "relative",
    height: "100%",
    overflow: "hidden"
  },
  form: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 200,
    overflow: "auto",
    backgroundColor: palette.background.default
  },
  loader: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  planner: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  incomingOrderForm: {
    paddingTop: spacing(2),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingBottom: spacing(2)
  }
}));

const getInitialCargoItemSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialCargoItemLinkSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ itemId }) => itemId)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialWaypointSeed = waypoints => {
  const seed = waypoints
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

function IncomingOrder(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    match: {
      params: { id: incomingOrderId }
    },
    history,
    location: { pathname, search }
  } = useRouter();

  const path = pathname.split("/");
  const copy = path.slice(-1)[0] === "copy";
  const edit = !isNaN(parseInt(path.slice(-1)[0]));
  const { customerId } = queryString.parse(search);

  const { incomingOrder: incomingOrderConcept, setIncomingOrder: setIncomingOrderConcept } = useIncomingOrderConcept();
  const [incomingOrder, setIncomingOrder] = useState<IncomingOrderFormT | undefined>();
  const [initialJourney, setInitialJourney] = useState({
    cargoItemSeed: 0,
    cargoItemLinkSeed: 0,
    waypointSeed: 2,
    isPlaceSearchFailAlertOpen: false,
    selectedWaypointIndex: 0,
    waypoints: [Waypoint({ id: 0 }), Waypoint({ id: 1 })],
    distance: undefined,
    duration: undefined,
    errors: [{}, {}]
  });

  const [journeyState, dispatchJourney] = useReducer(
    JourneyPlannerReducer,
    JourneyPlannerState(initialJourney)
  );

  const [isPlannerOpen, setIsPlannerOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { hasPermission } = useAuth();
  const [defaultUnitLength] = useState(() => loadDefaultUnitLength());
  const [conceptDialogOpen, setConceptDialogOpen] = useState(incomingOrderConcept && (!incomingOrderId || copy));
  const [isJourneyChanged, setIsJourneyChanged] = useState(true);

  const apiClient = useApiClient();

  const {
    isLoading: isExchangeRatesLoading,
    exchangeRates
  } = useExchangeRates();

  const {
    isLoading: isGoogleMapsApiLoading,
    api: googleMapsApi
  } = useGoogleMapsApi();

  const checkIndexNumber = (indexNumber) => /^[0-9,a-z,A-Z,-]*$/.test(indexNumber) && indexNumber.length >= INCOMING_ORDER_INDEX_NUMBER_MIN_LENGTH;

  const handleBack = (): void => {
    setIsPlannerOpen(true);
  };

  const handleJourneyComplete = (): void => {
    setIsPlannerOpen(false);
  };

  const storeDefaultLenghUnitUndefinedTemplate = (unit) => storeDefaultUniLength(unit);

  const { setShipment: setShipmentConcept } = useShipmentConcept();
  const { setOutcomingOrder: setOutcomingOrderConcept } = useOutcomingOrderConcept();

  const handleSave = async (
    newIncomingOrder: Omit<IncomingOrderT, "journey">,
    setIsSubmitting,
    redirectTo
  ): Promise<void> => {
    // create new incomingOrder
    if (!newIncomingOrder.isDraft && !checkIndexNumber(newIncomingOrder.indexNumber)) {
      enqueueSnackbar(t("webapp:incomingOrder.form.error.indexNumber"), {
        variant: "error"
      });
      return;
    }

    if (incomingOrderId === undefined || copy) {
      const state = newIncomingOrder.isDraft ? IncomingOrderState.QUEUE : newIncomingOrder.state;
      const incomingOrder = {
        ...newIncomingOrder,
        state,
        journey: copy && journeyState === initialJourney
          ? {
            waypoints: dehydrateWaypoints(journeyState.waypoints),
            duration: journeyState.duration,
            distance: journeyState.distance
          }
          : journeyState,
      };

      try {
        setIncomingOrderConcept(null);
        const { incomingOrderId } = await apiClient.incomingOrder.postIncomingOrder({ incomingOrder });

        enqueueSnackbar(t("incomingOrder.create.success"), { variant: "success" });

        const concept = {
          incomingOrderIds: [incomingOrderId],
          fresh: true
        };
        let params;
        switch (redirectTo) {
          case "shipment":
            params = loadActiveFilters("shipments");
            setShipmentConcept(concept);
            history.push(`/shipment?${params}`);
            break;

          case "outcoming-order":
            params = loadActiveFilters("outcoming-orders");
            setOutcomingOrderConcept(concept);
            history.push(`/outcoming-order?${params}`);
            break;

          default:
            params = loadActiveFilters("incoming-orders");
            history.push(`/incoming-orders?${params}`);
            break;
        }
      } catch (error) {
        console.log(error);

        const { response } = error;
        const data = response?.data || {};

        let errorMessage;
        setIsSubmitting(false);

        switch (data.code) {
          case "incoming-order/invalid-index-number-length":
            errorMessage = t("incomingOrder.create.error.invalidIndexNumberLength");
            break;
          case "incoming-order/index-number-already-exists":
            errorMessage = t("incomingOrder.create.error.indexNumberAlreadyExists");
            break;
          case "journey/no-point":
            errorMessage = t("incomingOrder.create.error.noPoint");
            break;
          case "journey/unsufficient-point-count":
            errorMessage = t("incomingOrder.create.error.unsufficientPointCount");
            break;
          case "cargo/loaded-more-than-once":
            errorMessage = t("incomingOrder.create.error.loadedMoreThanOnce");
            break;
          case "cargo/unloaded-before-loaded":
            errorMessage = t("incomingOrder.create.error.cargoUnloadedBeforeLoaded");
            break;
          case "cargo/unloaded-more-than-once":
            errorMessage = t("incomingOrder.create.error.cargoUnloadedMoreThanOnce");
            break;
          case "cargo/action-error":
            errorMessage = t("incomingOrder.create.error.cargoActionUnknown");
            break;
          case "cargo/not-unloaded":
            errorMessage = t("incomingOrder.create.error.cargoNotUnloaded");
            break;
          case "journey/no-journey":
            errorMessage = t("incomingOrder.create.error.noJourney");
            break;
          case "incoming-order/invalid-index-number":
            errorMessage = t("webapp:incomingOrder.form.error.indexNumber");
            break;
          default:
            errorMessage = t("incomingOrder.create.error.general");
            break;
        }
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    } else {
      const incomingOrder = {
        ...newIncomingOrder,
        journey: isJourneyChanged
          ? journeyState
          : undefined,
        paidAt: undefined
      };

      try {
        await putIncomingOrderById(
          incomingOrderId,
          incomingOrder
        );
        enqueueSnackbar(t("incomingOrder.update.success"), { variant: "success" });
        const params = loadActiveFilters("incoming-orders");
        history.push(`/incoming-orders?${params}`);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(t("incomingOrder.update.error.general"), { variant: "error" });
      }
    }
  };

  const fetchIncomingOrderData = async (): Promise<void> => {
    // new incomingOrder
    if (incomingOrderId === undefined) {
      let customerContact: Contact | undefined;

      if (customerId) {
        customerContact = await apiClient.contact.getContact({ contactId: customerId });
      }

      setIncomingOrder({
        indexNumber: "",
        externalReference: "",
        customerContact,
        customerEmployee: undefined,
        customerPosition: "",
        customerPaymentDueDays: "60",
        carrierPaymentDueDays: "60",
        customerPrice: "",
        customerPriceCurrency: Currency.CZK,
        isCustomerPriceWithDph: false,
        notes: "",
        documents: [],
        services: [
          {
            name: "Doprava",
            price: 0,
            priceCurrency: "CZK",
            isPriceWithDph: false,
          },
        ],
        isDraft: true,
        journey: undefined,
        state: undefined,
      });
    } else if (copy) {
      const {
        journey,
        ...newIncomingOrder
      }: IncomingOrderT = await apiClient.incomingOrder.getIncomingOrderById({ incomingOrderId });

      newIncomingOrder.indexNumber = "";
      newIncomingOrder.documents = [];
      newIncomingOrder.isDraft = true;
      newIncomingOrder.outcomingOrder = [];
      newIncomingOrder.outcomingOrderId = undefined;

      // adjust datetime of waypoints relatively
      const referenceDate = journey.waypoints[0].arriveAtFrom;
      const newReferenceDate = pipe(
        (date: Date) => setDate(date, getDate(new Date())),
        (date) => setMonth(date, getMonth(new Date())),
        (date) => setYear(date, getYear(new Date())),
      )(referenceDate);

      journey.waypoints = journey.waypoints.map((waypoint) => {
        const { arriveAtFrom, arriveAtTo } = waypoint;
        const differenceInMsFrom = differenceInMilliseconds(arriveAtFrom, referenceDate);
        const differenceInMsTo = arriveAtTo ? differenceInMilliseconds(arriveAtTo, arriveAtFrom) : -1;
        const todayFrom = addMilliseconds(newReferenceDate, differenceInMsFrom);
        const todayTo = differenceInMsTo === -1 ? undefined : addMilliseconds(todayFrom, differenceInMsTo);
        waypoint.arriveAtFrom = todayFrom;
        waypoint.arriveAtTo = todayTo;
        return waypoint;
      });

      const waypoints = hydrateWaypoints(journey.waypoints);

      const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };
      const journeyData = {
        cargoItemSeed: getInitialCargoItemSeed(waypoints),
        cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
        waypointSeed: getInitialWaypointSeed(waypoints),
        waypoints: fetchedJourney.waypoints,
        errors: Array(fetchedJourney.waypoints.length).fill({}),
        distance: fetchedJourney.distance,
        duration: fetchedJourney.duration
      };

      setIsJourneyChanged(false);
      setIncomingOrder(newIncomingOrder);
      setInitialJourney(fetchedJourney);
      dispatchJourney({
        type: JourneyPlannerAction.CHANGE_JOURNEY,
        ...journeyData
      });
      setIncomingOrderConcept({
        ...journeyData,
        ...newIncomingOrder
      });
    } else if (edit) {
      const { journey, ...newIncomingOrder } = await apiClient.incomingOrder.getIncomingOrderById({
        incomingOrderId
      });
      newIncomingOrder.indexNumber = newIncomingOrder.indexNumber
        ? newIncomingOrder.indexNumber
        : "";
      newIncomingOrder.documents = newIncomingOrder.documents ? newIncomingOrder.documents.map(({ createdAt, ...doc }) => ({ createdAt: new Date(createdAt), ...doc })) : [];

      const waypoints = hydrateWaypoints(journey.waypoints);
      const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };

      setIsJourneyChanged(false);
      setIncomingOrder({
        ...newIncomingOrder
      });
      setInitialJourney(fetchedJourney);
      dispatchJourney({
        cargoItemSeed: getInitialCargoItemSeed(waypoints),
        cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
        waypointSeed: getInitialWaypointSeed(waypoints),
        type: JourneyPlannerAction.CHANGE_JOURNEY,
        waypoints: fetchedJourney.waypoints,
        errors: Array(fetchedJourney.waypoints.length).fill({}),
        distance: fetchedJourney.distance,
        duration: fetchedJourney.duration
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchIncomingOrderData();
        setIsLoading(false);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(t("incomingOrder.load.error"), { variant: "error" });
      }
    })();
  }, []);

  const onFormChange = (values) => {

    // if ((incomingOrderId === undefined || copy) && !conceptDialogOpen) {
    if (!conceptDialogOpen) {
      const newIncomingOrderConcept = { ...incomingOrderConcept, ...values };
      setIncomingOrderConcept(newIncomingOrderConcept);
      setIncomingOrder({ ...incomingOrder, ...values });
    }
  };

  useEffect(() => {
    if (JSON.stringify(journeyState.waypoints) !== JSON.stringify(initialJourney.waypoints)) {
      setIsJourneyChanged(true);
      onFormChange(journeyState);
    }
  }, [journeyState]);

  useEffect(() => {
    const setIndexNum = async () => {
      if (incomingOrder?.customerContact?.contactNumber) {
        const indexNum = await getNextIncomingOrderNumber(
          incomingOrder.customerContact.contactNumber
        );
        setIncomingOrder({ ...incomingOrder, indexNumber: indexNum });
      }
    }
    // if (incomingOrderId === undefined || copy) {
    setIndexNum();
    // }
  }, [incomingOrder?.customerContact?.contactNumber]);

  const content = (() => {
    if (isLoading || isExchangeRatesLoading || isGoogleMapsApiLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <>
        <JourneyPlanner
          className={classes.planner}
          googleMapsApi={googleMapsApi!}
          state={journeyState}
          dispatch={dispatchJourney}
          onComplete={handleJourneyComplete}
          mapTheme={CargoticMapTheme}
          defaultLengthUnit={defaultUnitLength}
          storeDefaultLengthUnit={storeDefaultLenghUnitUndefinedTemplate}
        />
        {!conceptDialogOpen
          ? (
            <Slide in={!isPlannerOpen} direction="left" unmountOnExit>
              <div className={classes.form}>
                <IncomingOrderForm
                  className={classes.incomingOrderForm}
                  apiClient={apiClient}
                  exchangeRates={exchangeRates!}
                  /* @ts-ignore */
                  incomingOrder={incomingOrder}
                  journey={journeyState}
                  onBack={handleBack}
                  onSave={handleSave}
                  isUpdating={edit}
                  onFormChange={onFormChange}
                  isDraft={incomingOrder.isDraft}
                />
              </div>
            </Slide>
          )
          : null}
        <IncomingOrderConceptDialog
          t={t}
          isOpen={conceptDialogOpen}
          onClose={async (saveConcept) => {
            if (saveConcept) {
              dispatchJourney({
                cargoItemSeed: incomingOrderConcept.cargoItemSeed,
                cargoItemLinkSeed: incomingOrderConcept.cargoItemLinkSeed,
                waypointSeed: incomingOrderConcept.waypointSeed,
                type: JourneyPlannerAction.CHANGE_JOURNEY,
                waypoints: incomingOrderConcept.waypoints,
                errors: incomingOrderConcept.errors,
                distance: incomingOrderConcept.distance,
                duration: incomingOrderConcept.duration
              });

              setIncomingOrder({
                ...incomingOrder,
                ...incomingOrderConcept
              });
            } else {
              setIncomingOrderConcept(null);
            }
            setConceptDialogOpen(false);
          }}
        />
      </>
    );
  })();

  return (
    <>
      <div className={classes.root}>
        {content}
      </div>
    </>
  );
}

export default IncomingOrder;
