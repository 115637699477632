import React from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from "@material-ui/core";

import VehicleIncomeTableGroup from "./VehicleIncomeTableGroup";
import CoolerTablePagination from "../../../cargotic-webapp/component/common/CoolerTablePagination";

const VehicleIncomeTable = ({
  onEditClick,
  page,
  rowsPerPage,
  incomes,
  totalIncomes,
  onChangePage,
  onChangeRowsPerPage,
  onDeleteClick,
  isIncomeEditationDisabled,
  isIncomeDeletionDisabled
}) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow component={Paper}>
            <TableCell align="center" colSpan={2} />
            <TableCell align="center">
              {t("webapp:vehicle.title.description")}
            </TableCell>
            <TableCell align="center">
              {t("webapp:vehicle.title.value")}
            </TableCell>
            <TableCell align="center">
              {t("webapp:vehicle.title.accountedAt")}
            </TableCell>
            <TableCell align="center">
              {t("webapp:vehicle.title.createdAt")}
            </TableCell>
            <TableCell padding="none" />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(incomes).map((group) => (
            <VehicleIncomeTableGroup
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              isIncomeEditationDisabled={isIncomeEditationDisabled}
              isIncomeDeletionDisabled={isIncomeDeletionDisabled}
              month={group}
              entries={incomes[group]}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 15, 25]}
        component="div"
        count={totalIncomes}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={t("rowsPerPage")}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page"
        }}
        nextIconButtonProps={{
          "aria-label": "next page"
        }}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("of")} ${count}`}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        ActionsComponent={CoolerTablePagination}
      />
    </TableContainer>
  )
}

export default VehicleIncomeTable;
