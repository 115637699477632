import clsx from "clsx";
import React from "react";

import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    display: "flex"
  },
  avatar: {
    border: `2px solid ${palette.background.default}`,
    borderRadius: "50%",
    marginLeft: -8,

    "&:first-child": {
      marginLeft: 0
    }
  },
  large: {
    width: 50,
    height: 50
  },
  medium: {
    width: 32,
    height: 32,

    fontSize: typography.body1.fontSize
  },
  small: {
    width: 24,
    height: 24,

    fontSize: typography.caption.fontSize
  }
}));

const UserAvatarGroup = ({ max = 5, size, children }) => {
  const classes = useStyles();
  const extra = children.length > max ? children.length - max + 1 : 0;

  return (
    <div className={classes.root}>
      {children.slice(0, children.length - extra).map((child, index) => (
        React.cloneElement(child, {
          size,
          className: classes.avatar,
          isLinked: false
        })
      ))}
      {
        extra
          ? (
            <Avatar
              style={{
                boxSizing: "content-box",
                zIndex: 0
              }}
              className={clsx(classes.avatar, classes[size])}
            >
              {`+${extra}`}
            </Avatar>
          ) : (
            null
          )
      }
    </div>
  );
};

export default UserAvatarGroup;
