import Axios from "axios";

import config from "./config";

const HttpMethod = {
  DELETE: "DELETE",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
};

const httpRequest = (method, url, data, params) =>
  Axios.request({
    method,
    url,
    params,
    data,
    withCredentials: true,
  });

const httpDelete = (url, data) =>
  httpRequest(HttpMethod.DELETE, url, data, undefined);

const httpGet = (url, params) =>
  httpRequest(HttpMethod.GET, url, undefined, params);

const httpPost = (url, data) =>
  httpRequest(HttpMethod.POST, url, data, undefined);

const httpPut = (url, data) =>
  httpRequest(HttpMethod.PUT, url, data, undefined);

const httpPatch = (url, data) =>
  httpRequest(HttpMethod.PATCH, url, data, undefined);

const resource = (path, url = config.api.urlV1) => `${url}/${path}`;

const createResource = (path, data) => httpPost(resource(path), data);

const readResource = (path, params) => httpGet(resource(path), params);

const updateResource = (path, data) => httpPut(resource(path), data);

const deleteResource = (path, data) => httpDelete(resource(path), data);

const patchResource = (path, data) => httpPatch(resource(path), data);

export {
  createResource,
  readResource,
  updateResource,
  deleteResource,
  patchResource,
};
