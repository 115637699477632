import clsx from "clsx";
import React from "react";

import { Currency } from "@cargotic/common"
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { Trend } from "../../../cargotic-webapp-component";
import { localizeMonth } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > div > section:last-child": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& > div:last-child > :not(:first-child)": {
        marginLeft: spacing(1)
      }
    }
  },
  content: {
    "&:last-child": {
      padding: spacing(2)
    }
  }
}));

const MonthlyVehicleExpenseCard = ({
  className,
  month,
  value,
  rateOfChange,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  onEditButtonClick,
  onDeleteButtonClick
}) => {
  const { t, i18n: { language } } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <section>
          <Typography variant="h6">
            <b>
              {localizeMonth(month, language)}
            </b>
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="p">
            <b>
              {`${value.toLocaleString("cs")} ${Currency.CZK}`}
            </b>
          </Typography>
        </section>
        <section>
          <Trend
            rateOfChange={rateOfChange}
            description={t("webapp:common.trend.previous-month")}
            isInverted
          />
          <div>
            <Tooltip
              title={t("webapp:vehicle.title.editExpenses")}
              disableFocusListener={isEditButtonDisabled}
              disableHoverListener={isEditButtonDisabled}
              disableTouchListener={isEditButtonDisabled}
            >
              <span>
                <IconButton
                  size="small"
                  disabled={isEditButtonDisabled}
                  onClick={onEditButtonClick}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={t("webapp:vehicle.title.deleteExpenses")}
              disableFocusListener={isDeleteButtonDisabled}
              disableHoverListener={isDeleteButtonDisabled}
              disableTouchListener={isDeleteButtonDisabled}
            >
              <span>
                <IconButton
                  size="small"
                  disabled={isDeleteButtonDisabled}
                  onClick={onDeleteButtonClick}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </section>
      </CardContent>
    </Card>
  );
};

export default MonthlyVehicleExpenseCard;
