const convertUnit = (value, sourceUnit, targetUnit, { base, factors }) => {
  if (sourceUnit === targetUnit) {
    return value;
  }

  if (sourceUnit === base) {
    return value * factors[targetUnit];
  }

  if (targetUnit === base) {
    return value / factors[sourceUnit];
  }

  return (value / factors[sourceUnit]) * factors[targetUnit];
};

export default convertUnit;
