import React from "react";

import { TextField } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";

const NumberField = ({
  value: initialValue,
  isRequired = false,
  onChange,
  ...other
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleCange = event => {
    const { target: { value: newValue } } = event;

    setValue(newValue);

    if (
      (!isRequired && newValue === "")
      || !Number.isNaN(parseFloat(newValue))
    ) {
      onChange(event);
    }
  };

  return (
    <TextField
      type="number"
      value={value}
      onChange={handleCange}
      {...other}
    />
  );
};

export default NumberField;
