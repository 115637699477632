import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles(({ spacing, palette }) => ({
    abort: {
        background: palette.status.danger,
        marginRight: spacing(1),
        color: "white",
        "&:hover": {
            background: palette.status.danger,
            color: "white"
        }
    },
    actionButtonGroup: {
        display: "flex",
        marginTop: "auto",
        "& > button:not(:last-child)": {
            marginRight: spacing(2)
        }
    }
}));

export function FormButtons({
    form,
    onClose,
    onSubmit,
    submitButtonLabel,
    cancelButtonLabel,
    submitButtonProps
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const { isSubmitting, isValid, dirty } = form;
    const {
        enableOnDirty = true,
        disableOnInvalid = true,
        ...restSubmitButtonProps
    } = submitButtonProps || {};
    const blah =
        isSubmitting ||
        (disableOnInvalid && !isValid) ||
        (enableOnDirty && !dirty);

    return (
        <Box className={classes.actionButtonGroup}>
            {typeof onSubmit === "function" && (
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={blah}
                    onClick={onSubmit}
                    {...restSubmitButtonProps}
                >
                    {typeof submitButtonLabel === "string"
                        ? t(submitButtonLabel)
                        : submitButtonLabel}
                </Button>
            )}

            {typeof onClose === "function" && (
                <Button
                    onClick={onClose}
                    variant="contained"
                    size="large"
                    className={classes.abort}
                >
                    {typeof cancelButtonLabel === "string"
                        ? t(cancelButtonLabel)
                        : cancelButtonLabel}
                </Button>
            )}
        </Box>
    );
}

FormButtons.propTypes = {
    onClose: PropTypes.func,
    submitButtonLabel: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.string
    ]),
    cancelButtonLabel: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.string
    ]),
    submitButtonProps: PropTypes.shape({
        enableOnDirty: PropTypes.bool,
        disableOnInvalid: PropTypes.bool
    })
};

FormButtons.defaultProps = {
    onClose: undefined,
    submitButtonProps: {
        disableOnInvalid: true,
        enableOnDirty: true
    },
    submitButtonLabel: "save",
    cancelButtonLabel: "cancel"
};

export default FormButtons;
