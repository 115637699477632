import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { Card, makeStyles } from "@material-ui/core";

import config from "../../../config";
import client from "../../../../cargotic-webapp/client";

import TrackingMap from "./TrackingMap";
import TrackingSideMenu from "./TrackingSideMenu";

import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import { formatDateTime } from "../../../utility/common";

const TrackingContainer = () => {
  const { t } = useTranslation();
  const { isLoading: isGoogleMapsApiLoading, api: googleMapsApi } = useGoogleMapsApi();

  const [geolocationLoading, setGeolocationLoading] = useState(true);
  const [tracking, setTracking] = useState([]);
  const [plannedPath, setPlannedPath] = useState([]);
  const [driverPath, setDriverPath] = useState([]);
  const [hoveredTrackId, setHoveredTrackId] = useState(-1);
  const hoveredTrackIdRef = useRef(-1);
  const [expandingCard, setExpandingCard] = useState(-1);

  const fetchActiveTracking = async () => {
    const tracking = await client.tracking.getActiveTracking();
    setTracking(tracking);

    if (hoveredTrackIdRef.current !== -1) {
      const hoveredTracking = tracking.find(({ id: trackingId }) => trackingId === hoveredTrackIdRef.current)
      const newPlannedPath = hoveredTracking.journey.waypoints.map(({ placeGoogleId }) => placeGoogleId);
      setPlannedPath(newPlannedPath);
    }

    setGeolocationLoading(false);
  }

  const fetchData = async () => {
    if (hoveredTrackIdRef.current !== -1) {
      onTrackingPathChange(hoveredTrackIdRef.current);
    }
    fetchActiveTracking();
  }

  const onTrackingPathChange = async (id) => {
    if (id === -1) {
      setDriverPath([]);
    }
    else {
      const data = await client.tracking.getTrackingGeopositions({ trackingId: id });
      const geolocations = data.map(({ latitude, longitude }) => ({
        lat: latitude,
        lng: longitude
      }));
      setDriverPath(geolocations);
    }
  }

  useEffect(() => {
    hoveredTrackIdRef.current = hoveredTrackId;
  }, [hoveredTrackId]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <TrackingSideMenu
        geolocationLoading={geolocationLoading}
        expandingCard={expandingCard}
        setExpandingCard={setExpandingCard}
        tracking={tracking}
        hoveredTrackId={hoveredTrackId}
        setHoveredTrackId={setHoveredTrackId}
        onTrackingPathChange={onTrackingPathChange}
      />
      <div style={{
        width: "100%",
        height: "100%"
      }} >
        {!isGoogleMapsApiLoading
          ? <TrackingMap
            googleMapsApi={googleMapsApi}
            driverPath={driverPath}
            plannedPath={plannedPath}
            selectedDriverId={hoveredTrackId}
            tracking={tracking} />
          : null}
      </div>
    </>
  );
}

export default TrackingContainer;