import React from "react";
import { useTranslation } from "react-i18next";

import Pane from "../Pane";
import PaneHeader from "../PaneHeader";
import CargoEditorActions from "./CargoEditorActions";
import CargoEditorContent from "./CargoEditorContent";

const CargoEditor = ({
  className,
  cargo,
  selectedCargoItemId,
  unloadableCargoItems,
  isCargoItemLoadingDisabled,
  isCargoItemUnloadingDisabled,
  isVisible,
  onCargoItemChange,
  onCargoItemCopy,
  onCargoItemDelete,
  onCargoItemLoad,
  onCargoItemReload,
  onCargoItemSelect,
  onCargoItemUnload
}) => {
  const { t } = useTranslation();

  return (
    <Pane className={className} isVisible={isVisible}>
      <PaneHeader title={t("webapp:cargo.editor.title")} />
      <CargoEditorContent
        cargo={cargo}
        selectedCargoItemId={selectedCargoItemId}
        isCargoItemLoadingDisabled={isCargoItemLoadingDisabled}
        onCargoItemChange={onCargoItemChange}
        onCargoItemCopy={onCargoItemCopy}
        onCargoItemDelete={onCargoItemDelete}
        onCargoItemLoad={onCargoItemLoad}
        onCargoItemReload={onCargoItemReload}
        onCargoItemSelect={onCargoItemSelect}
        onCargoItemUnload={onCargoItemUnload}
      />
      <CargoEditorActions
        unloadableCargoItems={unloadableCargoItems}
        isCargoItemLoadingDisabled={isCargoItemLoadingDisabled}
        isCargoItemUnloadingDisabled={isCargoItemUnloadingDisabled}
        onCargoItemLoad={onCargoItemLoad}
        onCargoItemUnload={onCargoItemUnload}
      />
    </Pane>
  );
};

export default CargoEditor;
