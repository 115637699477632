import { useFormik } from "formik2";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { UserRole } from "@cargotic/model";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Radio,
  Stepper,
  Step,
  StepButton,
  StepContent,
  makeStyles
} from "@material-ui/core";

import { ToggleButton } from "@material-ui/lab";

import {
  DrawerDialog,
  FormikDatePicker,
  FormikTextField,
  FormikToggleButtonGroup,
  FormikUserAutosuggestTextField,
  FormikVehicleAutosuggestTextField,
  ApiClientContext
} from "../../../cargotic-webapp-component";

import { useApiClient } from "../../../cargotic-webapp-component";

import { VehicleType } from "@cargotic/model";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
  step: {
    marginTop: spacing(1),

    "& > :not(:first-child)": {
      marginTop: spacing(0.5)
    }
  },
  prefill: {
    marginBottom: spacing(6)
  },
  radioGroupLabel: {
    marginTop: spacing(1),

    "&:first-child": {
      marginTop: spacing(2)
    },

    "& > span:last-child > div": {
      display: "flex",
      alignItems: "center",

      "& > :last-child": {
        marginLeft: spacing(1)
      }
    }
  }
}));

const VehicleEditor = ({
  initialValue = {},
  type: inputType,
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const client = useApiClient();

  const VehicleEditorSchema = Yup.object().shape({
    manufacturer: Yup.string()
      .max(32, t("webapp:vehicle.validate.manufacturer"))
      .required(t("webapp:vehicle.validate.manufacturer")),
    model: Yup.string()
      .max(32, t("webapp:vehicle.validate.model"))
      .required(t("webapp:vehicle.validate.model")),
    licensePlate: Yup.string()
      .max(8, t("webapp:vehicle.validate.licensePlate"))
      .required(t("webapp:vehicle.validate.licensePlate")),
    vin: Yup.string()
      .length(17, t("webapp:vehicle.validate.vin"))
      .test("duplicityValidation", t("vehicles.error.duplicate"), function (value) {
        if (value?.length === 17 && value !== previousVin && value !== initialVin) {
          const promise = client.vehicle.getVehicleDuplicate({ vin: value });

          return promise.then((result) => result === null ? true : false);
        }

        return true;
      }),
    emissionClass: Yup.string().max(16, t("webapp:vehicle.validate.emissionClass")),
    cargoSpaceWidth: Yup.number()
      .min(0, t("webapp:vehicle.validate.cargoSpaceWidth"))
      .typeError(t("webapp:vehicle.validate.cargoSpaceWidth")),
    cargoSpaceHeight: Yup.number()
      .min(0, t("webapp:vehicle.validate.cargoSpaceHeight"))
      .typeError(t("webapp:vehicle.validate.cargoSpaceHeight")),
    cargoSpaceLength: Yup.number()
      .min(0, t("webapp:vehicle.validate.cargoSpaceLength"))
      .typeError(t("webapp:vehicle.validate.cargoSpaceLength")),
    cargoSpaceLoad: Yup.number()
      .min(0, t("webapp:vehicle.validate.cargoSpaceLoad"))
      .typeError(t("webapp:vehicle.validate.cargoSpaceLoad")),
    defaultDriver: Yup.object()
      .typeError(t("webapp:vehicle.validate.defaultDriver")),
    defaultTrailer: Yup.object()
      .typeError(t("webapp:vehicle.validate.default-trailer")),
    manufacturedAt: Yup.date().nullable()
      .typeError(t("webapp:common.validate.date"))
  });

  const { id } = initialValue;
  const initialDefaultTrailer = initialValue.defaultTrailer || "";
  const initialType = initialValue.type || inputType || VehicleType.CAR;
  const initialManufacturer = initialValue.manufacturer || "";
  const initialModel = initialValue.model || "";
  const initialLicensePlate = initialValue.licensePlate || "";
  const initialVin = initialValue.vin || "";
  const initialEmissionClass = initialValue.emissionClass || "";
  const initialDefaultDriver = initialValue.defaultDriver || "";
  const initialCargoSpaceWidth = initialValue.cargoSpaceDimensions?.width
    ? initialValue.cargoSpaceDimensions.width.toString()
    : "";

  const initialCargoSpaceHeight = initialValue.cargoSpaceDimensions?.height
    ? initialValue.cargoSpaceDimensions.height.toString()
    : "";

  const initialCargoSpaceLength = initialValue.cargoSpaceDimensions?.length
    ? initialValue.cargoSpaceDimensions.length.toString()
    : "";

  const initialCargoSpaceLoad = initialValue.cargoSpaceLoad
    ? initialValue.cargoSpaceLoad.toString()
    : "";

  const initialManufacturedAt = initialValue.manufacturedAt || null;

  const initialActiveStep = id === undefined ? 0 : 1;
  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const [previousVin, setPreviousVin] = useState(initialVin);

  const typeForm = useFormik({
    initialValues: {
      type: initialType
    },
    onSubmit: () => {
      setActiveStep(1);
    }
  })

  const form = useFormik({
    initialValues: {
      manufacturer: initialManufacturer,
      model: initialModel,
      licensePlate: initialLicensePlate,
      vin: initialVin,
      emissionClass: initialEmissionClass,
      cargoSpaceWidth: initialCargoSpaceWidth,
      cargoSpaceHeight: initialCargoSpaceHeight,
      cargoSpaceLength: initialCargoSpaceLength,
      cargoSpaceLoad: initialCargoSpaceLoad,
      defaultDriver: initialDefaultDriver,
      manufacturedAt: initialManufacturedAt,
      defaultTrailer: initialDefaultTrailer
    },
    validationSchema: VehicleEditorSchema,
    onSubmit: ({
      manufacturer,
      model,
      licensePlate,
      vin,
      emissionClass,
      cargoSpaceWidth,
      cargoSpaceHeight,
      cargoSpaceLength,
      cargoSpaceLoad,
      defaultDriver,
      defaultTrailer,
      manufacturedAt
    }) => {
      const parsedCargoSpaceWidth = parseFloat(cargoSpaceWidth);
      const parsedCargoSpaceHeight = parseFloat(cargoSpaceHeight);
      const parsedCargoSpaceLength = parseFloat(cargoSpaceLength);
      const parsedCargoSpaceLoad = parseFloat(cargoSpaceLoad);

      const vehicle = {
        type: typeForm.values.type,
        manufacturer,
        model,
        licensePlate,
        vin: vin !== "" ? vin : undefined,
        emissionClass: emissionClass !== "" ? emissionClass : undefined,
        defaultTrailer: defaultTrailer === "" || typeForm.values.type === VehicleType.TRAILER
          ? undefined
          : defaultTrailer,
        defaultDriver: defaultDriver === "" || typeForm.values.type === VehicleType.TRAILER
          ? undefined
          : defaultDriver,
        cargoSpaceDimensions: (
          parsedCargoSpaceWidth
          || parsedCargoSpaceHeight
          || parsedCargoSpaceLength
        ) ? ({
          width: Number.isNaN(parsedCargoSpaceWidth)
            ? undefined
            : parsedCargoSpaceWidth,
          height: Number.isNaN(parsedCargoSpaceHeight)
            ? undefined
            : parsedCargoSpaceHeight,
          length: Number.isNaN(parsedCargoSpaceLength)
            ? undefined
            : parsedCargoSpaceLength
        }) : undefined,
        cargoSpaceLoad: Number.isNaN(parsedCargoSpaceLoad)
          ? undefined
          : parsedCargoSpaceLoad,
        manufacturedAt: manufacturedAt ?? undefined
      };

      if (onSubmit) {
        onSubmit(vehicle);
      }
    }
  });

  const handleBackButtonClick = () => {
    if (activeStep === 0) {
      if (onClose) {
        onClose();
      }

      return;
    }

    setActiveStep(0);
  };

  const handleNextButtonClick = () => {
    if (activeStep === 0) {
      try {
        typeForm.submitForm();
      } catch (error) {
        console.log(error);
      }

      return;
    }

    if (activeStep === 1) {
      try {
        form.submitForm();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmitButtonClick = () => (
    form.submitForm()
  );

  const handleVinChange = (e) => {
    setPreviousVin(form.values.vin);

    form.handleChange(e);
  };

  useEffect(() => {
    typeForm.resetForm({
      errors: {},
      values: {
        type: initialType
      }
    });

    form.resetForm({
      errors: {},
      values: {
        manufacturer: initialManufacturer,
        model: initialModel,
        licensePlate: initialLicensePlate,
        vin: initialVin,
        emissionClass: initialEmissionClass,
        cargoSpaceWidth: initialCargoSpaceWidth,
        cargoSpaceHeight: initialCargoSpaceHeight,
        cargoSpaceLength: initialCargoSpaceLength,
        cargoSpaceLoad: initialCargoSpaceLoad,
        defaultDriver: initialDefaultDriver,
        defaultTrailer: initialDefaultTrailer,
        manufacturedAt: initialManufacturedAt
      }
    });

    setActiveStep(initialActiveStep);
  }, [isOpen]);

  const handleStepChange = step => {
    if (activeStep === 0 && step === 1) {
      form.submitForm();
    } else {
      setActiveStep(step);
    }
  };

  const steps = [
    [
      t("webapp:common.title.general-information"),
      <div key={0} className={classes.step}>
        <FormikToggleButtonGroup
          form={typeForm}
          name="type"
          exclusive
        >
          {[
            VehicleType.CAR,
            VehicleType.TRAILER
          ].map(type => (
            <ToggleButton
              key={type}
              value={type}
              disabled={(inputType === VehicleType.CAR && type === VehicleType.TRAILER) || (inputType === VehicleType.TRAILER && type === VehicleType.CAR)}
            >
              {t(`webapp:vehicle.type.${type.toLowerCase()}`)}
            </ToggleButton>
          ))}
        </FormikToggleButtonGroup>
      </div>
    ],
    [
      t("webapp:common.title.additional-information"),
      <div className={classes.content}>
        <div>
          <FormikTextField
            form={form}
            name="manufacturer"
            label={t("webapp:vehicle.title.manufacturer")}
            fullWidth
            required
          />
        </div>
        <div>
          <FormikTextField
            form={form}
            name="model"
            label={t("webapp:vehicle.title.model")}
            fullWidth
            required
          />
        </div>
        <div>
          <FormikTextField
            form={form}
            name="licensePlate"
            label={t("webapp:vehicle.title.licensePlate")}
            fullWidth
            required
          />
        </div>
        <div>
          <FormikTextField
            form={form}
            name="vin"
            label={t("webapp:vehicle.title.vin")}
            fullWidth
            handleChange={handleVinChange}
          />
        </div>
        {
          typeForm.values.type === VehicleType.CAR
            ? (
              <div>
                <FormikTextField
                  form={form}
                  name="emissionClass"
                  label={t("webapp:vehicle.title.emissionClass")}
                  fullWidth
                />
              </div>) : null}
        <div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormikTextField
                form={form}
                name="cargoSpaceLength"
                label={t("webapp:vehicle.title.cargo-space-length")}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormikTextField
                form={form}
                name="cargoSpaceWidth"
                label={t("webapp:vehicle.title.cargo-space-width")}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormikTextField
                form={form}
                name="cargoSpaceHeight"
                label={t("webapp:vehicle.title.cargo-space-height")}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl disabled fullWidth>
                <InputLabel />
                <Select value="m">
                  <MenuItem value="m">m</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <FormikTextField
                form={form}
                name="cargoSpaceLoad"
                label={t("webapp:vehicle.title.cargo-space-load")}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl disabled fullWidth>
                <InputLabel />
                <Select value="kg">
                  <MenuItem value="kg">kg</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        {
          typeForm.values.type === VehicleType.CAR
            ? (
              <div>
                <FormikUserAutosuggestTextField
                  form={form}
                  permissions={["resource.user.role.permission.driver"]}
                  name="defaultDriver"
                  label={t("webapp:vehicle.title.defaultDriver")}
                  fullWidth
                />
                <FormikVehicleAutosuggestTextField
                  form={form}
                  name="defaultTrailer"
                  type={VehicleType.TRAILER}
                  label={t("webapp:vehicle.title.default-trailer")}
                  fullWidth
                />
              </div>
            )
            : null
        }
        <div>
          <FormikDatePicker
            form={form}
            name="manufacturedAt"
            label={t("webapp:vehicle.title.manufacturedAt")}
            format="MM/yyyy"
            views={["year", "month"]}
            fullWidth
            required={false}
            placeholder="01/2020"
          />
        </div>
      </div>
    ]
  ]

  return (
    <>
      <DrawerDialog
        title={t("webapp:vehicle:title.vehicle")}
        actions={(
          <div className={classes.actions}>
            <Button variant="contained" onClick={handleBackButtonClick}>
              {
                activeStep === 0
                  ? t("webapp:common.title.cancel")
                  : t("webapp:common.title.back")
              }
            </Button>
            <Button variant="contained" color="primary" onClick={handleNextButtonClick}>
              {
                activeStep === 0
                  ? t("webapp:common.title.continue")
                  : t("webapp:common.title.complete")
              }
            </Button>
          </div>
        )}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map(([label, content], index) => (
            <Step key={label}>
              <StepButton onClick={() => handleStepChange(index)}>
                {label}
              </StepButton>
              <StepContent>
                {content}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DrawerDialog>
    </>
  )
};

export default VehicleEditor;
