import React from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from "@material-ui/core";

import { Form, FormValues } from "../../form";

interface FormSelectProps<T extends FormValues> {
  form: Form<T>;
  name: keyof T;
  label?: string;
  color?: "primary" | "secondary";
  renderValue?: (value: unknown) => React.ReactElement;
  isDisabled?: boolean;
  isShrunk?: boolean;
  hasFullWidth?: boolean;

  children: React.ReactNode;
}

function FormSelect<T extends FormValues>(
  {
    form: {
      errors,
      values,
      touched,
      handleBlur,
      handleChange
    },
    name,
    color,
    label,
    renderValue,
    isDisabled,
    isShrunk,
    hasFullWidth,
    children
  }: FormSelectProps<T>
): React.ReactElement {
  const isTouched = touched[name];
  const error = isTouched ? errors[name] : undefined;
  const errorMessage = error?.message || undefined;
  const hasError = error !== undefined;
  const value = values[name];

  const header = label ? (
    <InputLabel shrink={isShrunk}>
      {label}
    </InputLabel>
  ) : null;

  return (
    <FormControl fullWidth={hasFullWidth}>
      {header}
      <Select
        name={name as unknown as string}
        value={value}
        error={hasError}
        disabled={isDisabled}
        renderValue={renderValue}
        onBlur={handleBlur}
        onChange={handleChange}
        color={color}
      >
        {children}
      </Select>
      <FormHelperText>{errorMessage ?? error?.name ?? " "}</FormHelperText>
    </FormControl>
  );
}

export default FormSelect;
