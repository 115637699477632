import React from "react";
import { getNameInitials } from "../../../../utility/common";
import { Avatar, Tooltip } from "@material-ui/core";

const BoardCardAvatar = ({ tooltip, name, avatar, className, style }) =>
  <Tooltip title={`${tooltip}: ${name}`} style={style} className={className} >
    <Avatar
      style={{ width: 28, height: 28 }}
      alt={name}
      src={avatar}>
      <span style={{ fontFamily: "Barlow", color: "white", fontSize: 14 }} >
        {getNameInitials(name).substr(0, 2)}
      </span>
    </Avatar>
  </Tooltip>

export default BoardCardAvatar;