import clsx from "clsx";
import React from "react";

import { Paper, Slide, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column"
  }
}));

const Pane = ({ className, children, isVisible }) => {
  const classes = useStyles();

  return (
    <Slide in={isVisible} direction="up" timeout={360}>
      <Paper className={clsx(classes.root, className)} elevation={4}>
        {children}
      </Paper>
    </Slide>
  );
};

export default Pane;
