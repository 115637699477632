import React, { useState, useEffect } from "react";

import useRouter from "../../../hook/useRouter";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Card,
  Divider,
  Paper,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";

import { ShipmentState } from "@cargotic/model";

import {
  getActionTypeOfLoading,
  getWaypointsWithCargo,
  getShortCargoWaypointsTitle,
  getShortCargoWaypointsTitleLimited,
  mapAndFormatWaypoints
} from "../../../../utility/waypoint";

import BoardCardHeader from "../component/BoardCardHeader";
import BoardCardLabels from "../component/BoardCardLabels";
import { red } from "@material-ui/core/colors";
import { Error } from "@material-ui/icons";
import BoardCardWaypointRow from "../component/BoardCardWaypointRow";

const useStyles = makeStyles(({ spacing, transitions, palette }) => ({
  root: {
    padding: 8
  },
  place: {
    marginLeft: 20,
    marginBottom: 5,
    fontFamily: "Barlow",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "1rem"
  }
}));

const BoardCardM = ({
  id,
  isDraft,
  state,
  type,
  indexNumber,
  customer,
  carrier,
  customerPrice,
  createdBy,
  loadings,
  currency,
  vehicle,
  driver,
  avatar,
  actions,
  paidAt,
  hasWarning,
  hasError,
  onClick,
  onChangeState,
  hasCreateShipmentPermission,
  hasUpdateShipmentPermission,
  hasDeleteShipmentPermission,
  updateShipmentDriveThrough,
  openDeleteDialog
}) => {
  const [currentState, setCurrentState] = useState(state);
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const waypoints = getWaypointsWithCargo(loadings);
  const shortCargoTitle = getShortCargoWaypointsTitle(waypoints, t);
  const shortCargoTitleLimited = getShortCargoWaypointsTitleLimited(shortCargoTitle);
  const data = mapAndFormatWaypoints(loadings, true, true);

  const [loading, setLoading] = useState(data[0]);
  const [hovered, setHovered] = useState(false);

  const [driveThroughLoading, setDriveThroughLoading] = useState(-1);

  const [elevation, setElevation] = useState(2);

  useEffect(() => {
    setElevation(hovered ? 7 : 2);
  }, [hovered]);

  const states = [ShipmentState.QUEUE, ShipmentState.READY, ShipmentState.IN_PROGRESS, ShipmentState.DONE];

  return (
    <Card
      key={id}
      elevation={elevation}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={classes.root} >
        <div
          onClick={(e) => onClick(e, id, state)}
          style={{ cursor: "pointer" }}
        >
          <BoardCardLabels
            isDraft={isDraft}
            type={type}
            paidAt={paidAt}
          />
          {!isDraft
            ? <BoardCardHeader
              t={t}
              id={id}
              hasWarning={hasWarning}
              hasError={hasError}
              isLastState={currentState === states[states.length - 1]}
              state={state}
              indexNumber={indexNumber}
              onStateChange={(event) => onChangeState(event.target.value, id, currentState)}
              hasUpdateShipmentPermission={hasUpdateShipmentPermission}
              moveToNextState={() => onChangeState(states[states.indexOf(currentState) + 1], id, currentState)}
              currentState={currentState}
            />
            : null}
          {data.map(({
            action,
            id: waypointId,
            index,
            isDrivenThrough,
            inProgress,
            haveError,
            drivenThroughAt,
            formattedPlace,
            formattedDateTime
          }, i) => (
              <BoardCardWaypointRow
                key={`waypoint-row-${i}`}
                t={t}
                id={waypointId}
                action={action}
                isDrivenThrough={isDrivenThrough}
                inProgress={inProgress}
                haveError={haveError}
                drivenThroughAt={drivenThroughAt}
                driveThroughLoading={driveThroughLoading}
                updateShipmentDriveThrough={async () => {
                  setDriveThroughLoading(waypointId);
                  await updateShipmentDriveThrough(id, waypointId);
                }}
                formattedPlace={formattedPlace}
                formattedDateTime={formattedDateTime}
              />
            ))}
        </div>
      </div>
    </Card>
  );
}

export default BoardCardM;