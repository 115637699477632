import React, { useCallback } from "react";

import { makeStyles } from "@material-ui/core";

import Placeholder from "../../common/Placeholder";
import BankAccountsCard from "./BankAccountCard";
import TariffCard from "../Subscription/SubscriptionCard";
import CompanyCard from "../CompanyProfile/CompanyCard";
import BillingCard from "../Billing/BillingCard";
import UserProfileCard from "../UserProfile/UserProfileCard";
import General from "./General/SettingsGeneral";
import StampCard from "./StampCard";
import SettingsView from "./SettingsView";
import SettingsCompanyUserOverview from "./SettingsCompanyUserOverview";
import SettingsTariffAndBillingOverview from "./SettingsTariffAndBillingOverview";
import SettingsRoleOverview from "./SettingsRoleOverview";
import SettingsStateOverview from "./SettingsStateOverview";

import SettingsBankAccountOverview from "./SettingsBankAccountOverview";
import SettingsCurrencyOverview from "./SettingsCurrencyOverview";

import { Container } from "../../../../cargotic-webapp-component";
import SettingsWarehousesOverview from "./SettingsWarehousesOverview";

const SettingsBodyContent = ({
  classes,
  view,

  isBankAccountLoading,
  isCompanyProfileLoading,
  isUserProfileLoading,
  isInvoiceLoading,
  isCompanyTermsLoading,
  isUserTermsLoading,
  isUserRoleLoading,
  isExchangeRateModeLoading,
  isWarehousesLoading,

  handleStampSignatureFileSelect,
  stampSignatureFile,
  handleFileDelete,

  bankAccounts,
  handleUpdateBankAccountSubmit,
  handleCreateBankAccountSubmit,
  handleRemoveBankAccountSubmit,
  handleReplaceBankAccount,
  handleAppendUnfilledBankAccount,

  hasUpdateBankAccountPermission,
  hasDeleteBankAccountPermission,
  hasCreateBankAccountPermission,
  
  hasCreateRolePermission,
  hasUpdateRolePermission,
  hasReadRolePermission,
  hasDeleteRolePermission,

  updateCompanyProfile,
  updateCompanyProfilePartial,

  customerProfile,
  hasUpdateUserProfilePermission,
  hasReadUserProfilePermission,
  
  hasDeleteUserProfilePermission,
  handleUpdateCustomerProfileClick,
  handleChangePassword,
  handleChangeUserAvatarImage,

  hasUpdateCompanyProfilePermission,
  hasReadCompanyTermsPermission,
  hasDeleteCompanyProfilePermission,

  hasReadSequencingPermission,
  hasUpdateSequencingPermission,

  companyProfile,
  
  handleUpdateCompanyProfileClick,
  handleCreateCompanyProfileClick,
  handleChangeCompanyAvatarImage,
  handleCompanyConditionsClick,
  handleUserConditionsClick,
  handleDeleteCompany,
  handleUpdateCompanyProfile,
  handleUpdateCompanyProfilePartial,

  user,

  invoices,
  bankAccountsTableProps,
  userRoleTableProps,
  isBankAccountEditorOpen,
  setIsBankAccountEditorOpen,
  exchangeRatesTableProps,
  wareHousesTableProps,

  handleDeleteBankAccountClick,
  handleOpenDeleteBankAccountDialog,
  handleOpenBankAccountEditor,
  handleCreateBankAccountClick,
  handleUpdateBankAcountClick,

  handleOpenDeleteRolesDialog,

  subscriptionInfo,
  exchangeRatesMode,
  handleSetExchangeRatesMode,

  handleCreateExchangeRateClick,
  handleUpdateExchangeRateClick,
  handleOpenDeleteExchangeRatesDialog,
  
  hasUpdateExchangeRatePermission,
  hasDeleteExchangeRatePermission,
  hasCreateExchangeRatePermission,
  hasUpdateExchangeRateModePermission,
  hasReadExchangeRatePermission,

  hasCreateWarehousePermission,
  hasUpdateWarehousePermission,
  hasDeleteWarehousePermission,
  hasReadWarehousePermission,

  handleDeleteWarehouseClick,
  handleCreateWarehouseClick,
  handleUpdateWarehouseClick,

  userStateTableProps,
  statesData,
  handleOpenDeleteStatesDialog,
  hasInviteUserPermission,

  handleChangeTokenClick,
  token,
  tokens
}) => {

  switch (view) {
    case SettingsView.COMPANY_AND_USER: {
      return (
        <Container size="large">
          <SettingsCompanyUserOverview
            className={classes}
            companyProfile={companyProfile}
            userProfile={customerProfile}
            isLoading={isCompanyProfileLoading || isUserProfileLoading}
            hasUpdateUserProfilePermission={hasUpdateUserProfilePermission}
            hasDeleteUserProfilePermission={hasDeleteUserProfilePermission}
            handleUpdateCustomerProfileClick={handleUpdateCustomerProfileClick}
            handleChangePassword={handleChangePassword}
            handleChangeUserAvatarImage={handleChangeUserAvatarImage}

            hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
            hasReadCompanyTermsPermission={hasReadCompanyTermsPermission}
            hasDeleteCompanyProfilePermission={hasDeleteCompanyProfilePermission}
            hasReadSequencingPermission={hasReadSequencingPermission}
            hasUpdateSequencingPermission={hasUpdateSequencingPermission}

            handleUpdateCompanyProfileClick={handleUpdateCompanyProfileClick}
            handleCreateCompanyProfileClick={handleCreateCompanyProfileClick}
            handleChangeCompanyAvatarImage={handleChangeCompanyAvatarImage}
            handleCompanyConditionsClick={handleCompanyConditionsClick}
            handleDeleteCompany={handleDeleteCompany}
            handleUserConditionsClick={handleUserConditionsClick}
            handleUpdateCompanyProfile={handleUpdateCompanyProfile}
            handleUpdateCompanyProfilePartial={handleUpdateCompanyProfilePartial}

            handleStampSignatureFileSelect={handleStampSignatureFileSelect}
            stampSignatureFile={stampSignatureFile}
            handleFileDelete={handleFileDelete}
            hasInviteUserPermission={hasInviteUserPermission}

            handleChangeTokenClick={handleChangeTokenClick}
            token={token}
            tokens={tokens}
          />
        </Container>
      );
    }

    case SettingsView.TARIFF: {
      return (
        <Container>
          <SettingsTariffAndBillingOverview
            className={classes}
            invoices={invoices}
            subscriptionInfo={subscriptionInfo}
            hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
          />
        </ Container>
      );
    }

    case SettingsView.ROLES: {
      return (
        <SettingsRoleOverview
          className={classes}
          invoices={invoices}
          isLoading={isUserRoleLoading}
          userRoleTableProps={userRoleTableProps}
          hasCreateRolePermission={hasCreateRolePermission}
          hasUpdateRolePermission={hasUpdateRolePermission}
          hasReadRolePermission={hasReadRolePermission}
          hasDeleteRolePermission={hasDeleteRolePermission}
          handleDeleteRolesClick={handleOpenDeleteRolesDialog}
        />
      );
    }

    case SettingsView.WAREHOUSES: {
      return (
         <SettingsWarehousesOverview
             className={classes}
             isLoading={isWarehousesLoading}
             warehousesTableProps={wareHousesTableProps}
             hasCreateWarehousePermission={hasCreateWarehousePermission}
             hasUpdateWarehousePermission={hasUpdateWarehousePermission}
             hasDeleteWarehousePermission={hasDeleteWarehousePermission}
             hasReadWarehousePermission={hasReadWarehousePermission}
             handleCreateWarehouseClick={handleCreateWarehouseClick}
             handleUpdateWarehouseClick={handleUpdateWarehouseClick}
             handleDeleteWarehouseClick={handleDeleteWarehouseClick}
         />
      );
    }

    case SettingsView.STATES: {
      return (
        <SettingsStateOverview
          view={view}
          className={classes}
          invoices={invoices}
          isLoading={isUserRoleLoading}
          userStateTableProps={userStateTableProps}
          statesData={statesData}

          // TODO
          hasCreateRolePermission={hasCreateRolePermission}
          hasUpdateRolePermission={hasUpdateRolePermission}
          hasReadRolePermission={hasReadRolePermission}
          hasDeleteRolePermission={hasDeleteRolePermission}
          handleOpenDeleteStatesDialog={handleOpenDeleteStatesDialog}
        />
      );
    }
    
    case SettingsView.EXCHANGE_RATES: {
      return (
        <SettingsCurrencyOverview
          className={classes}
          isLoading={isExchangeRateModeLoading}

          exchangeRatesTableProps={exchangeRatesTableProps}
          exchangeRatesMode={exchangeRatesMode}
          handleSetExchangeRatesMode={handleSetExchangeRatesMode}
          handleCreateExchangeRateClick={handleCreateExchangeRateClick}
          handleUpdateExchangeRateClick={handleUpdateExchangeRateClick}
          handleOpenDeleteExchangeRatesDialog={handleOpenDeleteExchangeRatesDialog}
          
          hasUpdateExchangeRatePermission={hasUpdateExchangeRatePermission}
          hasDeleteExchangeRatePermission={hasDeleteExchangeRatePermission}
          hasCreateExchangeRatePermission={hasCreateExchangeRatePermission}
          hasUpdateExchangeRateModePermission={hasUpdateExchangeRateModePermission}
          hasReadExchangeRatePermission={hasReadExchangeRatePermission}
        />
      );
    }
    case SettingsView.BANK_ACCOUNTS: {
      return (
        <SettingsBankAccountOverview
          className={classes}

          bankAccounts={bankAccounts}
          isLoading={isBankAccountLoading}

          handleUpdateBankAccountSubmit={handleUpdateBankAccountSubmit}
          handleRemoveBankAccountSubmit={handleRemoveBankAccountSubmit}
          handleCreateBankAccountSubmit={handleCreateBankAccountSubmit}
          handleReplaceBankAccount={handleReplaceBankAccount}
          handleAppendUnfilledBankAccount={handleAppendUnfilledBankAccount}

          bankAccountsTableProps={bankAccountsTableProps}

          hasUpdateBankAccountPermission={hasUpdateBankAccountPermission}
          hasDeleteBankAccountPermission={hasDeleteBankAccountPermission}
          hasCreateBankAccountPermission={hasCreateBankAccountPermission}

          isBankAccountEditorOpen={isBankAccountEditorOpen}
          setIsBankAccountEditorOpen={setIsBankAccountEditorOpen}
          handleDeleteBankAccountClick={handleDeleteBankAccountClick}
          handleOpenDeleteBankAccountDialog={handleOpenDeleteBankAccountDialog}
          handleOpenBankAccountEditor={handleOpenBankAccountEditor}
          handleCreateBankAccountClick={handleCreateBankAccountClick}
          handleUpdateBankAcountClick={handleUpdateBankAcountClick}
        />
      );
    }

    default: {
      throw new Error(`Invalid settings profile view '${view}'!`);
    }
  }
};

export default SettingsBodyContent;
