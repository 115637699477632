import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import Trend from "../Trend";
import StatisticCardSelect from "./StatisticCardSelect";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

const StatisticCardFooter = ({
  unit,
  units,
  rateOfChange,
  trendDescription,
  isTrendInverted,
  onUnitChange
}) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div>
        <StatisticCardSelect
          value={unit}
          items={units}
          onChange={onUnitChange}
        />
      </div>
      <div>
        <Trend
          description={trendDescription}
          rateOfChange={rateOfChange}
          isInverted={isTrendInverted}
        />
      </div>
    </section>
  );
};

export default StatisticCardFooter;
