import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { loadGoogleMapsApi } from "../../utility";
import GoogleMapsApiContext from "../GoogleMapsApiContext";

const GoogleMapsApiProvider = ({ apiKey, libraries, children }) => {
  const { i18n } = useTranslation();
  const [api, setApi] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const load = async () => {
    const [language] = i18n.languages;
    setApi(await loadGoogleMapsApi(apiKey, libraries, language));
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <GoogleMapsApiContext.Provider value={{ api, apiKey, isLoading }}>
      {children}
    </GoogleMapsApiContext.Provider>
  );
};

export default GoogleMapsApiProvider;
