import React, { useState } from "react";

import {
  Fab,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  Add,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  FileCopyOutlined,
  DeleteOutline,
  EmojiObjectsOutlined,
  Edit,
  PictureAsPdf,
  Label
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { ContactType } from "@cargotic/model";
import { useExchangeRates } from "@cargotic/currency";

import DataTable from "../../../datatable";
import ExportDialog from "./ExportDialog";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import SearchTextfield from "../../common/SearchTextfield";

import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import PlaceFilterDropdown from "../../../../cargotic-webapp-filter/component/PlaceFilterDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import useAuth from "../../hook/useAuth";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";
import BoxFilterDropdown from "../../../../cargotic-webapp-filter/component/BoxFilterDropdown";


const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger,
    },
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)",
  }
}));

const IncomingOrders = ({
  data,
  dataCount,
  selectedColumns,
  incomingOrders,
  setIncomingOrders,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  isIncomingOrdersExportDialogOpen,
  isIncomingOrdersExportProcessing,
  isIncomingOrdersExportDone,
  incomingOrdersExportLink,
  isIncomingOrdersDeleteProcessing,
  isLabelsDownloadingLoading,
  closeIncomingOrdersExportDialog,
  exportIncomingOrders,
  canCreateIncomingOrder,
  canUpdateIncomingOrder,
  canDeleteIncomingOrder,
  canCreateInvoice,
  canReadCompanyIncomingOrder,
  hasImportPermission,
  additionalFilter: {
    type: additionalFilterType,
    lastWaypointDateFrom: additionalFilterLastDateFrom,
  },
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleIncomingOrdersExportRequest,
  handlePrintLabels,
  handlePayIncomingOrder,
  handleGenerateIncomingOrderInvoice,
  handleSelectAll,
  onSelectAllOnPage,
  handleSelect,
  handleDeleteRequest,
  handleSelectPlace,
  filter,
  handleSelectIncomingOrderState,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectCreators,
  loadAvailableUsers,
  handleSelectCustomer,
  handleSelectCustomerPrice,
  loadAvailableContacts,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleLoadAvailablePlaces,
  handleLoadAvailableBoxes,
  handleSelectPlaces,
  handleSelectBoxes,
  handleSelectTags,
  exportOrdersRinkai,
  handleChangeSelectedColumns,
  setRinkaiDepo,
  rinkaiDepo,
  handleRinkaiDepoInputChange,
  loadAvailableStates,
  removeItemsByToken,
  handleOpenSetStatuses
}) => {
  const { history } = useRouter();
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, hasPermission } = useAuth();
  const { token } = user;
  const canOperateRinkai = hasPermission("resource.rinkai.all");

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);

  const {
    isLoading: isExchangeRatesLoading,
    exchangeRates
  } = useExchangeRates();
  const [selectValue, setSelectValue] = useState('rinkai');
  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
  };

  const EXPORT_TABS = [
    { id: 0, labelKey: "invoices.export.main", type: "export" },
    { id: 1, labelKey: "invoices.export.api", type: "api" }
  ];

  const [selectedExportTab, handleSetSelectedExportTab] = useState(EXPORT_TABS.find((tab) => tab.type === "export") || EXPORT_TABS[0]);

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  let incomingOrdersHeader = removeItemsByToken([
    {
      name: "indexNumber",
      label: "incomingOrders.number",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "customerContact",
      label: "incomingOrders.customer",
      isOrderable: false,
    },
    {
      name: "pickupAtStart",
      label: "incomingOrders.date",
      isOrderable: false,
      width: "10%",
    },
    {
      name: "route",
      label: "incomingOrders.route",
      isOrderable: false,
    },
    {
      name: "waypointContact",
      label: "incomingOrders.waypointContact",
      isOrderable: false,
    },
    {
      name: "state",
      label: "incomingOrders.state",
      isOrderable: false,
      width: "6%",
    },
    {
      name: "box",
      label: "shipments.box.title",
      isOrderable: false
    },
    {
      name: "outcomingOrder",
      label: "incomingOrders.outcomingOrderNumber",
      isOrderable: false,
    },
    {
      name: "externalReference",
      label: "incomingOrders.externalReference",
      isOrderable: false
    },
    {
      name: "customerPrice",
      label: "incomingOrders.customerPrice",
      isOrderable: false,
    },
    {
      name: "sumCargoValue",
      label: "incomingOrders.sumCargoValue",
      width: "15%",
      isOrderable: false,
    },
    {
      name: "createdBy",
      label: "incomingOrders.createdBy",
      width: "50px",
      isOrderable: false
    },
    {
      name: "tags",
      label: "incomingOrders.tags",
      isOrderable: false
    },
    {
      name: "cashOnDelivery",
      label: "incomingOrders.cashOnDelivery",
      width: "15%",
      isOrderable: false,
    },
  ]);

  const payDisable = [];
  if (canCreateInvoice) {
    for (let index = 0; index < incomingOrders.length; index++) {
      if (incomingOrders[index].isDraft) {
        payDisable.push(incomingOrders[index].id);
      }
    }
  } else {
    payDisable.push(-1);
  }

  const createInvoiceDisable = [];
  if (canCreateInvoice) {
    incomingOrders.forEach(({ isDraft, bankAccountId }, index) => {
      if (isDraft || !bankAccountId) {
        createInvoiceDisable.push(incomingOrders[index].id);
      }
    });
  } else {
    createInvoiceDisable.push(-1);
  }

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => history.push(`/incoming-order/${id}/copy`),
          icon: <FileCopyOutlined />,
          title: "copy",
          disableFor: !canCreateIncomingOrder ? [-1] : undefined,
        },
      ],
    },
    {
      column: [
        {
          handleClick: (id) => history.push(`/incoming-order/${id}`),
          icon: <Edit />,
          title: "incomingOrders.edit",
          disableFor: !canUpdateIncomingOrder ? [-1] : undefined,
        },
      ],
    },
  ];

  const draftSource = [
    { title: t("incomingOrders.draft"), value: "DRAFT" },
    { title: t("incomingOrders.completed"), value: "COMPLETED" },
  ];
  const setDraft = (value) => {
    if (value.includes("DRAFT") && value.includes("COMPLETED")) {
      handleSelectIsDraft(null);
    } else if (value.includes("COMPLETED")) {
      handleSelectIsDraft(false);
    } else if (value.includes("DRAFT")) {
      handleSelectIsDraft(true);
    } else {
      handleSelectIsDraft(undefined);
    }
  };

  const isAnyFilterActive = () => {
    const {
      customerPrice,
      customers,
      creators,
      cargo,
      state,
      places,
      unloadingDate,
      loadingDate,
      createdAt,
      isDraft,
    } = filter;

    return [
      customerPrice,
      customers,
      creators,
      cargo,
      state,
      places,
      unloadingDate,
      loadingDate,
      createdAt,
      isDraft,
    ].some((item) => item !== undefined);
  };

  const sum = incomingOrders.reduce((acc, val) => {
    const rate = val.customerPriceCurrency ? exchangeRates.find((exchangeRate) => exchangeRate.sourceCurrency === val.customerPriceCurrency).rate : 1;
    return acc + (val.customerPrice * rate || 0);
  }, 0);

  return (
    <Page
      title={t("menu.incomingOrderOverview")}
      actions={
        <>
          <Tooltip title="Set state">
            <span>
              <Fab color="primary" disabled={loading || !selected} onClick={handleOpenSetStatuses}>
                <Label />
              </Fab>
            </span>
          </Tooltip>
          {!token ? (
            <Tooltip title={t("incomingOrders.newIncomingOrder")}>
              <span>
                <Fab
                  color="primary"
                  disabled={loading || !canCreateIncomingOrder}
                  onClick={() => history.push("/incoming-order")}
                >
                  <Add />
                </Fab>
              </span>
            </Tooltip>) : undefined}
          {!token ? (<Tooltip title={t("incomingOrders.import.action")}>
            <span>
              <Fab
                color="primary"
                disabled={loading || !hasImportPermission}
                onClick={() => history.push("/incoming-order/csv-import")}
              >
                <CloudUploadOutlined />
              </Fab>
            </span>
          </Tooltip>) : undefined}
          <Tooltip title={t("incomingOrders.export.action")}>
            <span>
              <Fab
                className={classes.danger}
                disabled={loading || !selected}
                onClick={handleIncomingOrdersExportRequest}
              >
                <CloudDownloadOutlined />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title="Print labels">
            <span>
              <Fab color="primary" disabled={loading || !selected} onClick={handlePrintLabels}>
                <PictureAsPdf />
              </Fab>
            </span>
          </Tooltip>
          {!token ? (<Tooltip title={t("incomingOrders.removeIncomingOrder")}>
            <span>
              <Fab
                className={classes.danger}
                disabled={loading || selected === 0 || !canDeleteIncomingOrder}
                onClick={handleDeleteRequest}
                data-cy="remove-shipment"
              >
                <DeleteOutline />
              </Fab>
            </span>
          </Tooltip>) : undefined}
        </>
      }
      tertiaryActions={
        <>
          <Tooltip
            title={
              <>
                <Typography color="inherit" style={{ fontWeight: 800 }}>
                  {t("didYouKnowThat")}
                </Typography>
                <Typography color="inherit">{t("proTableTip")}</Typography>
              </>
            }
          >
            <IconButton>
              <EmojiObjectsOutlined />
            </IconButton>
          </Tooltip>
        </>
      }
    >
      <FilterContainer
        searchField={
          <SearchTextfield
            handleSearch={handleSearch}
            value={search}
            fullWidth
            placeholder={t("incomingOrders.searchTooltip")}
          />
        }
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={removeItemsByToken([
          <ContactFilterDropdown
            id="customers"
            key="customers"
            onChange={(value) =>
              handleSelectCustomer(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectCustomer(all)}
            value={filter.customers ?? []}
            search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
            placeholderTitle={t("contacts.customer")}
            onClear={() => handleSelectCustomer(undefined)}
            getTitle={(item) => item.name}
          />,
          <FilterDateRangeDropdown
            id="loadingDate"
            key="loadingDate"
            onChange={(value) => handleSelectLoadingsDateRange(value)}
            value={
              filter.loadingDate
                ? [filter.loadingDate.from, filter.loadingDate.to]
                : []
            }
            onClear={() => handleSelectLoadingsDateRange(undefined)}
            placeholderTitle={t("incomingOrders.loadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="unloadingDate"
            key="unloadingDate"
            onChange={(value) => handleSelectUnloadingsDateRange(value)}
            value={
              filter.unloadingDate
                ? [filter.unloadingDate.from, filter.unloadingDate.to]
                : []
            }
            onClear={() => handleSelectUnloadingsDateRange(undefined)}
            placeholderTitle={t("incomingOrders.unloadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="createdAt"
            key="createdAt"
            onChange={(value) => handleSelectCreatedAtDateRange(value)}
            value={
              filter.createdAt
                ? [filter.createdAt.from, filter.createdAt.to]
                : []
            }
            onClear={() => handleSelectCreatedAtDateRange(undefined)}
            placeholderTitle={t("incomingOrders.creationDate")}
          />,
          <StateFilterDropdown
            id="state"
            key="state"
            placeholderTitle={t("board.state.title")}
            value={filter.state ?? []}
            onChange={(arr) =>
              handleSelectIncomingOrderState(arr.length === 0 ? undefined : arr)
            }
            search={(text) => loadAvailableStates(text)}
            selectAll={(all) =>
              handleSelectIncomingOrderState(all)
            }
            onClear={() => handleSelectIncomingOrderState(undefined)}
            getTitle={(item) => item.name}
          />,
          <FilterNumberRange
            id="customerPrice"
            key="customerPrice"
            onChange={(value) => handleSelectCustomerPrice(value)}
            value={filter.customerPrice}
            placeholderTitle={t("incomingOrders.customerPrice")}
            onClear={() => handleSelectCustomerPrice(undefined)}
          />,
          <UserFilterDropdown
            id="creators"
            key="creators"
            onChange={(value) =>
              handleSelectCreators(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectCreators(all)}
            value={filter.creators ?? []}
            placeholderTitle={t("incomingOrders.creator")}
            search={(text) => loadAvailableUsers(text)}
            onClear={() => handleSelectCreators(undefined)}
            getTitle={(item) => item.name}
            disabled={!canReadCompanyIncomingOrder}
          />,
          <FilterStaticDropdown
            id="cargo"
            key="cargo"
            placeholderTitle={t("incomingOrders.cargo")}
            value={filter.cargo ?? []}
            onChange={(arr) =>
              handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
            }
            selectAll={() =>
              handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
            }
            onClear={() => handleSelectCargoTemplate(undefined)}
            source={AvailableCargoItemTemplates.map((item) => ({ title: t(item.label), value: item.value }))}
          />,
          <FilterStaticDropdown
            id="isDraft"
            key="isDraft"
            placeholderTitle={t("incomingOrders.draft")}
            value={
              filter.isDraft === undefined
                ? []
                : filter.isDraft === null
                  ? ["DRAFT", "COMPLETED"]
                  : filter.isDraft === true
                    ? ["DRAFT"]
                    : ["COMPLETED"]
            }
            onChange={(value) => setDraft(value)}
            selectAll={() => setDraft(draftSource.map((item) => item.value))}
            onClear={() => handleSelectIsDraft(undefined)}
            source={draftSource}
          />,
          <PlaceFilterDropdown
            id="places"
            key="places"
            placeholderTitle={t("shipments.places")}
            selectAll={(all) => handleSelectPlaces(all)}
            search={(text) => handleLoadAvailablePlaces(text)}
            value={filter.places ?? []}
            onChange={(value) => handleSelectPlaces(value.length === 0 ? undefined : value)}
            onClear={() => handleSelectPlaces(undefined)}
          />,
          <BoxFilterDropdown
            id="boxes"
            key="boxes"
            placeholderTitle={t("shipments.box.title")}
            selectAll={(all) => handleSelectBoxes(all)}
            search={(text) => handleLoadAvailableBoxes(text)}
            value={filter.boxes ?? []}
            onChange={(value) => handleSelectBoxes(value.length === 0 ? undefined : value)}
            onClear={() => handleSelectBoxes(undefined)}
          />
        ])}
        onClear={handleDeselect}
      />
      <DataTable
        headers={incomingOrdersHeader}
        actions={!token ? tableActions : []}
        selectedColumns={selectedColumns}
        data={data}
        dataCount={dataCount}
        loading={loading || isIncomingOrdersDeleteProcessing || isLabelsDownloadingLoading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        enableDetail
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSelectAllOnPage={onSelectAllOnPage}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        onChangeSelectedColumns={handleChangeSelectedColumns}
        sum={sum}
      />
      <ExportDialog
        infoText={t("orders.export.info.text")}
        title={t("orders.export.title")}
        closeButtonLabel={t("orders.export.close")}
        downloadCSV={t("orders.export.downloadCSV")}
        startButtonLabel={t("orders.export.start")}
        processingText={t("orders.export.processing.text")}
        doneText={t("orders.export.done.text")}
        linkLabel={t("orders.export.done.link")}
        isDone={isIncomingOrdersExportDone}
        isOpen={isIncomingOrdersExportDialogOpen}
        isProcessing={isIncomingOrdersExportProcessing}
        link={incomingOrdersExportLink}
        handleSelectPlace={handleSelectPlace}
        close={closeIncomingOrdersExportDialog}
        exportCsv={exportIncomingOrders}
        tabs={EXPORT_TABS}
        selectValue={selectValue}
        handleSelectChange={handleSelectChange}
        canOperateRinkai={canOperateRinkai}
        setSelectedExportTab={handleSetSelectedExportTab}
        rinkaiDepo={rinkaiDepo}
        setRinkaiDepo={setRinkaiDepo}
        selectedExportTab={selectedExportTab}
        exportOrdersRinkai={exportOrdersRinkai}
        handleRinkaiDepoInputChange={handleRinkaiDepoInputChange}
      />
    </Page>
  );
};

export default IncomingOrders;
