import PlaceAddress from "./PlaceAddress";

const Place = ({
  googleId,
  alias,
  name,
  address = PlaceAddress({}),
  latitude,
  longitude,
  thumbnail
}) => ({
  googleId,
  alias,
  name,
  address,
  latitude,
  longitude,
  thumbnail
});

export default Place;
