import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { TermsSchema } from "../../../utility/validationSchema";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  error: {
    color: "red"
  },
  paper: {
    [breakpoints.down("xs")]: {
      width: "90%"
    },
    [breakpoints.up("sm")]: {
      minWidth: 820
    }
  }
}));

const SetConditionsDialog = ({
  isCompanyConditions,
  hasUpdateCompanyProfilePermission,
  open,
  handleClose,
  handleSubmit,
  handleDelete,
  conditions,
  isReadOnly
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [initialConditions, setInitialConditions] = useState({});

  const areTermsEmpty = !conditions || Object.keys(conditions).length === 0;

  useEffect(() => {
    setInitialConditions({ ...conditions });
  }, [open]);

  const areAllTermsEmpty = (terms) => {
    const entries = Object.entries(terms);
    if (entries.length === 0) {
      return true;
    }
    const areEmptyList = entries.map(([k, v]) => v?.content === "" || !v?.content);
    return areEmptyList.every(x => x === true);
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={TermsSchema}
      initialValues={{ selectedLanguage: "cs", values: initialConditions }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        const { values: termsData } = values;
        handleSubmit(termsData);
      }}
    >
      {({
        resetForm,
        initialValues,
        isSubmitting,
        submitForm,
        errors,
        touched,
        values,
        setValues
      }) => (
        <Dialog
          classes={{ paper: classes.paper }}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{isCompanyConditions ? t("settings.setConditions") : t("settings.setUserConditions")}</DialogTitle>
          <>
            <DialogContent>
              <form className={classes.container}>
                <FormControl>
                  <InputLabel id="language-label">{t("language.title")}</InputLabel>
                  <Select
                    id="language"
                    name="language"
                    value={values.selectedLanguage}
                    onChange={({ target: { value } }) => setValues({ ...values, selectedLanguage: value })}
                    disableUnderline
                  >
                    <MenuItem value="cs">{t("default")} - {t("language.cs")}</MenuItem>
                    <MenuItem value="en">{t("language.en")}</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  multiline
                  name="terms"
                  disabled={(isCompanyConditions && !hasUpdateCompanyProfilePermission) || isReadOnly}
                  label={t("settings.conditions")}
                  value={values.values[values.selectedLanguage] ? values.values[values.selectedLanguage].content : ""}
                  error={errors.terms && touched.terms}
                  helperText={touched.email && t("errors.conditions")}
                  onChange={({ target: { value } }) => {
                    let currentValues = values.values;
                    currentValues[values.selectedLanguage] = { ...currentValues[values.selectedLanguage], content: value };
                    setValues({ ...values, values: currentValues });
                  }}
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleDelete(values.values.termsId)}
                color="primary"
                disabled={isReadOnly || (isCompanyConditions && !hasUpdateCompanyProfilePermission) || areTermsEmpty}
              >
                {t("delete")}
              </Button>
              <Button
                color="primary"
                onClick={handleClose}
              >
                {t("abort")}
              </Button>
              <Button onClick={submitForm} color="primary"
                disabled={isReadOnly || isSubmitting || (isCompanyConditions && !hasUpdateCompanyProfilePermission) || areAllTermsEmpty(values.values)}
              >
                {t("save")}
              </Button>
            </DialogActions>
          </>
        </Dialog>
      )}
    </Formik>
  );
};

export default SetConditionsDialog;
