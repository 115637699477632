import clsx from "clsx";
import React, { useRef, useState } from "react";

import { makeStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

import { hexToRgba } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: spacing(4),

    border: "2px dashed",
    borderColor: palette.primary.light,

    transition: "background-color .5s ease",

    "&:hover": {
      cursor: "pointer"
    }
  },
  dragged: {
    backgroundColor: hexToRgba(palette.primary.light, 0.2),
    borderColor: palette.primary.main
  },
  error: {
    borderColor: red[500],
    backgroundColor: hexToRgba(red[200], 0.2)
  },
  input: {
    display: "none"
  }
}));

const FileDropzone = ({
  className,
  children,
  accept,
  multiple,
  hasError,
  onChange
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const handleInputChange = ({ target: { files } }) => {
    const elements = Array.from(files);

    if (onChange) {
      onChange(elements);
    }

    inputRef.current.value = null;
  };

  const handleDropzoneClick = event => {
    event.preventDefault();

    inputRef.current.click();
  };

  const handleDropzoneDragEnter = () => setIsDraggedOver(true);
  const handleDropzoneDragLeave = () => setIsDraggedOver(false);

  const handleDropzoneDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDropzoneDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggedOver(false);

    const files = Array.from(event.dataTransfer.items)
      .map(item => item.getAsFile())
      .filter(item => item);

    if (files.length === 0) {
      return;
    }

    if (onChange) {
      onChange(files);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className={classes.input}
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleInputChange}
      />
      <div
        className={
          clsx(
            classes.root,
            className,
            {
              [classes.dragged]: isDraggedOver,
              [classes.error]: hasError
            }
          )
        }
        onClick={handleDropzoneClick}
        onDragEnter={handleDropzoneDragEnter}
        onDragLeave={handleDropzoneDragLeave}
        onDragOver={handleDropzoneDragOver}
        onDrop={handleDropzoneDrop}
      >
        {children}
      </div>
    </>
  );
};

export default FileDropzone;
