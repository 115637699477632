import React, { useRef } from "react";

import { ButtonBase, Avatar, makeStyles } from "@material-ui/core";

import { getNameInitials } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ spacing }) => ({
  avatar: {
    width: spacing(20),
    height: spacing(20),

    fontSize: spacing(10)
  },
  button: {
    borderRadius: "50%"
  },
  input: {
    display: "none"
  }
}));

const ContactProfileDetailContentHeaderAvatar = ({
  name,
  avatarUrl,
  onChange
}) => {
  const classes = useStyles();
  const inputRef = useRef();

  const handleAvatarInputChange = ({ target: { files } }) => {
    if (onChange) {
      onChange(files[0]);
    }

    inputRef.current.value = null;
  };

  const handleButtonClick = () => (
    inputRef.current.click()
  );

  return (
    <div>
      <ButtonBase className={classes.button} onClick={handleButtonClick}>
        <Avatar className={classes.avatar} alt={name} src={avatarUrl}>
          {getNameInitials(name)}
        </Avatar>
      </ButtonBase>
      <input
        ref={inputRef}
        className={classes.input}
        type="file"
        accept="image/jpeg,image/png"
        onChange={handleAvatarInputChange}
      />
    </div>
  );
};

export default ContactProfileDetailContentHeaderAvatar;
