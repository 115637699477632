import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationAlert } from "../../../cargotic-webapp-component";

const VehicleDeleteConfirmationAlert = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ConfirmationAlert
      title={t("webapp:vehicle.title.deleteVehicle")}
      content={t("webapp:vehicle.prompt.deleteVehicle")}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default VehicleDeleteConfirmationAlert;
