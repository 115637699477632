import React, { useState } from "react";
import {
  Button, makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    borderRadius: 0,
    background: "#FAFAFA",
    padding: spacing(2),
    margin: spacing(3, 0, 4, 0),
    color: "#000",
    fontFamily: "Roboto",
    fontWeight: 500,
    marginRight: 15
  }
}));

const SelectOrderType = ({ handleShipmentSelected, handleOutcomingOrderSelected }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleShipmentSelected}
      >
        {t("outcomingOrders.carriedTo")}
      </Button>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleOutcomingOrderSelected}
      >
        {t("outcomingOrders.forwardedTo")}
      </Button>
    </>
  );
};

export default SelectOrderType;
