import React, { useRef, useState, useEffect } from "react";
import { AutoSizer, CellMeasurerCache, createMasonryCellPositioner, Masonry, CellMeasurer } from "react-virtualized";
import { makeStyles } from "@material-ui/core";
import {
  BoardCardS,
  BoardCardM,
  BoardCardL,
  BoardCardXL
} from "./card";

const useStyles = makeStyles(({ spacing, palette }) => ({
  boardList: {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent"
    },
    outline: 0
  }
}));

const COLUMN_SPACING = 5;

const BoardList = ({
  shipments,
  totalShipments,
  state,
  cardSize,
  loadAdditionalShipments,
  columnFlexBasis,
  totalColumns,
  onClick,
  handleChangeState,
  handleSelect,
  hasCreateShipmentPermission,
  hasUpdateShipmentPermission,
  hasDeleteShipmentPermission,
  updateShipmentDriveThrough,
  totalShipmentWarnings,
  hasShipmentError,
  openDeleteDialog
}) => {
  const cache = useRef(new CellMeasurerCache({
    fixedWidth: true,
    defaultWidth: 500,
    defaultHeight: 300
  }));
  const classes = useStyles();

  const loadingShipments = useRef([]);
  const cellPositioner = useRef(null);
  const masonryRef = useRef(null);
  const previousTotalColumnsRef = useRef(totalColumns);
  const [currentWidth, setWidth] = useState(0);
  const previousWidthRef = useRef(-1);
  const previousCardSize = useRef(null);

  const cellRenderer = ({ index, key, parent, style, isScrolling }, width) => {
    if (index >= shipments.length) {
      return (
        <div style={style}>PLACEHOLDER</div>
      );
    }

    const {
      id,
      indexNumber,
      customerContact,
      carrierContact,
      customerPrice,
      currency,
      createdBy,
      journey,
      customerPriceCurrency,
      isDraft,
      vehicle,
      driver,
      paidAt,
      type
    } = shipments[index];

    const BoardCard = ({ size }) => {
      const props = {
        id,
        onClick,
        isDraft: Boolean(isDraft),
        state,
        indexNumber,
        customer: customerContact,
        carrier: carrierContact,
        customerPrice,
        createdBy,
        loadings: journey.waypoints,
        currency: customerPriceCurrency,
        paidAt,
        hasWarning: totalShipmentWarnings(shipments[index]),
        hasError: hasShipmentError(shipments[index], state),
        onChangeState: handleChangeState,
        hasCreateShipmentPermission,
        hasUpdateShipmentPermission,
        hasDeleteShipmentPermission,
        updateShipmentDriveThrough,
        openDeleteDialog,
        handleSelect,
        vehicle,
        driver,
        type
      };

      switch (size) {
        case 0:
          return <BoardCardS {...props} />
        case 1:
          return <BoardCardM {...props} />
        case 2:
          return <BoardCardL {...props} />
        case 3:
          return <BoardCardXL {...props} />
      }
      return <div />
    }


    return (
      <CellMeasurer index={index} cache={cache.current} key={key} parent={parent} >
        <div style={{ ...style, width: width / totalColumns }} >
          <BoardCard size={cardSize} />
          {shipments.length - index <= totalColumns
            ? <div style={{ height: "30px" }} />
            : null}
        </div>
      </CellMeasurer>
    )
  };

  const loadMoreRows = async (startIndex, stopIndex) => {
    let notLoaded = false;
    for (let i = startIndex; i < stopIndex; i++) {
      if (!loadingShipments.current.includes(i)) {
        notLoaded = true;
        loadingShipments.current = [...loadingShipments.current, i];
      }
    }

    if (notLoaded) {
      loadAdditionalShipments(startIndex, stopIndex, state);
    }
  }

  const resetPositions = () => {
    cache.current.clearAll();
    cellPositioner.current?.reset({
      cellMeasurerCache: cache.current,
      columnWidth: currentWidth / totalColumns,
      columnCount: totalColumns,
      spacer: COLUMN_SPACING
    });
    masonryRef.current?.clearCellPositions();
  }

  const onCellsRendered = ({ startIndex, stopIndex }) => {
    if (startIndex === 0 && stopIndex === shipments.length - 1) {
      return;
    }

    if (stopIndex > shipments.length - 5 && shipments.length !== totalShipments) {
      loadMoreRows(shipments.length, shipments.length + 30);
    }
  }

  useEffect(() => {
    previousTotalColumnsRef.current = totalColumns;
    previousWidthRef.current = currentWidth;

    resetPositions();
  }, [totalColumns, currentWidth]);

  useEffect(() => {
    resetPositions();
  }, [shipments]);

  useEffect(() => {
    if (previousCardSize.current !== cardSize) {
      previousCardSize.current = cardSize;
      resetPositions();
    }
  }, [cardSize]);

  return (
    <AutoSizer>
      {({ width, height }) => {
        if (width !== currentWidth) {
          if (previousWidthRef.current === -1) {
            previousWidthRef.current = width;
          }
          setWidth(width);
        }
        if (cellPositioner.current === null) {
          cellPositioner.current = createMasonryCellPositioner({
            cellMeasurerCache: cache.current,
            columnWidth: width / totalColumns,
            columnCount: totalColumns,
            spacer: COLUMN_SPACING
          });
        }
        return (
          <Masonry
            ref={masonryRef}
            cellCount={totalShipments}
            cellMeasurerCache={cache.current}
            cellPositioner={cellPositioner.current}
            cellRenderer={(cell) => cellRenderer(cell, width)}
            onCellsRendered={onCellsRendered}
            className={classes.boardList}
            height={height}
            width={width + (COLUMN_SPACING * totalColumns)}
            overscanByPixels={250}
          />
        );
      }
      }
    </AutoSizer>
  );
}

export default BoardList;