import React, {useCallback, useContext} from "react";

import {Checkbox, makeStyles, TableCell, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {CargoPackagesContext} from "@cargotic/webapp/component/core/WarehouseOrders/ReceiveIncomingOrdersDialog";
import {CargooPackagesContext} from "@cargotic/webapp/component/core/WarehouseOrders/DispatchIncomingOrdersDialog";
import {v4} from 'uuid';
import clsx from "clsx";
import {AssociatedWarehouseOrdersContext} from "./WarehouseOrders";

const useStyles = makeStyles(({spacing, breakpoints}) => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	centerText: {
		textAlign: "center"
	},
	tableRow: {
		width: '100%',
		display: 'table',

		'& td:first-child': {
			paddingLeft: '60px',
		}
	},
	tableRowWide: {
		'& td:first-child': {
			paddingLeft: '120px'
		}
	}
}));

const WarehouseOrderTableRow = ({
																	incomingOrder,
																	warehouseOrder,
																	onSelectCargoItemPackages
																}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	const [associatedWarehouseOrders, setAssociatedWarehouseOrders] = useContext(AssociatedWarehouseOrdersContext);

	const checkSelectedCargoItem = (cargoItem) => {
		return cargoItem.packages.every(cargoItemPackage => selectedCargoItemPackages.includes(cargoItemPackage.packageId));
	}

	const checkSomeSelectedCargoItem = (cargoItem) => {
		return cargoItem.packages.some(cargoItemPackage => selectedCargoItemPackages.includes(cargoItemPackage.packageId));
	}

	const checkSelectedCargoItemPackage = (cargoItemPackageId) => {
		return selectedCargoItemPackages.includes(cargoItemPackageId);
	}

	const selectedCargoItemPackages = useContext(incomingOrder ? CargoPackagesContext : CargooPackagesContext)

	const checkIfCargoItemPackageIsDispatchable = useCallback((cargoItemPackage) => {
		const selectedCargoItemPackage = warehouseOrder.warehouseOrder.cargoItemPackages.find(p => p.id === cargoItemPackage.packageId)
		return selectedCargoItemPackage && selectedCargoItemPackage?.receivedDate && !selectedCargoItemPackage.dispatchedDate
	}, [warehouseOrder])

	const checkIfCargoItemHasDispatchablePackages = useCallback((cargoItem) => {
		return cargoItem.packages.some(cip => checkIfCargoItemPackageIsDispatchable(cip))
	}, [warehouseOrder])


	const checkIfCargoItemPackageIsReceivable = useCallback((cargoItemPackage) => {
		const selectedCargoItemPackage = associatedWarehouseOrders.find(associatedWarehouseOrder => {
			 return associatedWarehouseOrder.warehouseOrder.cargoItemPackages.find(p => p.id === cargoItemPackage.packageId)
		})?.warehouseOrder?.cargoItemPackages?.find(p => p.id === cargoItemPackage.packageId)
		return !selectedCargoItemPackage || (selectedCargoItemPackage && selectedCargoItemPackage.receivedDate === null)
	}, [associatedWarehouseOrders])

	const checkIfCargoItemHasReceivablePackages = useCallback((cargoItem) => {
		return cargoItem.packages.some(cip => checkIfCargoItemPackageIsReceivable(cip))
	}, [incomingOrder])

	return (
			<>
				{incomingOrder && (
						<div className={classes.root}>
							{incomingOrder.journey.waypoints[0].cargo.map((cargoItem, index) => (
									<>
										{checkIfCargoItemHasReceivablePackages(cargoItem) && (
												<React.Fragment key={`${v4()}`}>
													<TableRow className={classes.tableRow}>
														<TableCell padding="checkbox">
															<Checkbox
																	color="primary"
																	checked={checkSelectedCargoItem(cargoItem)}
																	indeterminate={checkSomeSelectedCargoItem(cargoItem) && !checkSelectedCargoItem(cargoItem)}
																	onChange={({target: {checked: selected}}) => {
																		onSelectCargoItemPackages(selected, cargoItem.packages.map(p => p.packageId))
																	}}
															/>
														</TableCell>
														<TableCell>
															#{cargoItem.itemId} {t('cargoItem')}
														</TableCell>
													</TableRow>
													{cargoItem.packages && cargoItem.packages.map((cargoItemPackage, index) => (
															<>
																{checkIfCargoItemPackageIsReceivable(cargoItemPackage) && (
																		<TableRow className={clsx(classes.tableRow, classes.tableRowWide)} key={`${v4()}`}>
																			<TableCell padding="checkbox">
																				<Checkbox
																						color="primary"
																						checked={checkSelectedCargoItemPackage(cargoItemPackage.packageId)}
																						onChange={({target: {checked: selected}}) => {
																							onSelectCargoItemPackages(selected, [cargoItemPackage.packageId])
																						}}
																				/>
																			</TableCell>
																			<TableCell>
																				#{cargoItemPackage.packageId} {t('package')}
																			</TableCell>
																		</TableRow>
																)}
															</>
													))}
												</React.Fragment>
										)}
									</>
							))}
						</div>
				)}

				{warehouseOrder && (
						<div className={classes.root}>
							{warehouseOrder.journey.waypoints[0].cargo.map((cargoItem, index) => (
									<>
										{checkIfCargoItemHasDispatchablePackages(cargoItem) && (
												<React.Fragment key={`${v4()}`}>
													<TableRow className={classes.tableRow}>
														<TableCell padding="checkbox">
															<Checkbox
																	color="primary"
																	checked={checkSelectedCargoItem(cargoItem)}
																	indeterminate={checkSomeSelectedCargoItem(cargoItem) && !checkSelectedCargoItem(cargoItem)}
																	onChange={({target: {checked: selected}}) => {
																		onSelectCargoItemPackages(selected, cargoItem.packages.map(p => p.packageId))
																	}}
															/>
														</TableCell>
														<TableCell>
															#{cargoItem.itemId} {t('cargoItem')}
														</TableCell>
													</TableRow>
													{cargoItem.packages && cargoItem.packages.map((cargoItemPackage, index) => (
															<>
																{checkIfCargoItemPackageIsDispatchable(cargoItemPackage) && (
																		<TableRow className={clsx(classes.tableRow, classes.tableRowWide)}
																							key={`${v4()}`}>
																			<TableCell padding="checkbox">
																				<Checkbox
																						color="primary"
																						checked={checkSelectedCargoItemPackage(cargoItemPackage.packageId)}
																						onChange={({target: {checked: selected}}) => {
																							onSelectCargoItemPackages(selected, [cargoItemPackage.packageId])
																						}}
																				/>
																			</TableCell>
																			<TableCell>
																				#{cargoItemPackage.packageId} {t('package')}
																			</TableCell>
																		</TableRow>
																)}
															</>
													))}
												</React.Fragment>
										)}
									</>
							))}
						</div>
				)}

			</>

	);
};

export default WarehouseOrderTableRow;
