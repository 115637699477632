import React from "react";

import UserAutosuggestTextField from "../UserAutosuggestTextField";


const FormUserAutosuggestTextField = ({ form, name, ...rest }) => {
  const value = form.values[name];
  const error = form.errors[name];
  const isTouched = form.touched[name];
  const hasError = isTouched && error;

  return (
    <UserAutosuggestTextField
      {...rest}
      name={name}
      value={value}
      error={hasError}
      helperText={hasError ? error.message : " "}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
    />
  );
};

export default FormUserAutosuggestTextField;
