import React, { useEffect, useState } from "react";

import { Fab, makeStyles, Tooltip, } from "@material-ui/core";

import { ArrowDownward, ArrowUpward, CloudDownloadOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { ContactType } from "@cargotic/model";
import BoxFilterDropdown from "../../../../cargotic-webapp-filter/component/BoxFilterDropdown";

import DataTable from "../../../datatable";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import SearchTextfield from "../../common/SearchTextfield";
import SendOrderDialog from "../../../incomingOrder/component/SendOrderDialog";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import { WarehouseOrdersModeEnum } from "../../enums/enums";
import PlaceFilterDropdown from "../../../../cargotic-webapp-filter/component/PlaceFilterDropdown";
import ReceiveIncomingOrdersDialog from "./ReceiveIncomingOrdersDialog";
import DispatchIncomingOrdersDialog from "./DispatchIncomingOrdersDialog";
import { loadAvailableWarehouseStates } from "../WarehouseOrders/utils/utils";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import ExportDialog from "../IncomingOrders/ExportDialog";
import useAuth from "../../hook/useAuth";
import { addUrlParam, getTableUrlParams } from "../../../utility/window";
import { reloadResources } from "i18next";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger,
    },
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)",
  },
}));

const LinehallWarehouseOrders = ({
  data,
  mode,
  dataCount,
  selectedColumns,
  incomingOrders,
  setIncomingOrders,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  canCreateIncomingOrder,
  canUpdateIncomingOrder,
  canDeleteIncomingOrder,
  canReadCompanyIncomingOrder,
  additionalFilter: {
    type: additionalFilterType,
    lastWaypointDateFrom: additionalFilterLastDateFrom,
  },
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleWarehouseOrdersExportRequest,
  handleSelectAll,
  handleSelect,
  handleDeleteRequest,
  filter,
  handleSelectIncomingOrderState,
  handleSelectWarehouseOrderState,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectReceivedDateDateRange,
  handleSelectDispatchedDateDateRange,
  handleSelectDeliveryDateDateRange,
  handleLoadAvailablePlaces,
  handleLoadAvailableBoxes,
  // handleSelectIncomingOrderOrderState,
  handleSelectCreators,
  handleSelectReceivedBy,
  handleSelectDispatchedBy,
  loadAvailableUsers,
  loadAvailableIncomingOrderState,
  handleSelectCustomer,
  handleSelectCustomerPrice,
  loadAvailableContacts,
  handleSelectPlaces,
  handleSelectBoxes,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleSendOrder,
  handleChangeSelectedColumns,
  handleDialogClosed,
  warehouseId,
  isWarehouseOrdersExportDone,
  isWarehouseOrdersExportDialogOpen,
  isWarehouseOrdersExportProcessing,
  warehouseOrdersExportLink,
  closeWarehouseOrdersExportDialog,
  exportWarehouseOrders,
  rinkaiDepoId,
  setRinkaiDepoId,
  exportOrdersRinkai,
  handleRinkaiDepoInputChange
}) => {
  const router = useRouter();
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, hasPermission } = useAuth();

  const {
    location: { search: routerSearch },
  } = useRouter();

  const { filter: initFilter } =
    getTableUrlParams(routerSearch);

  const [additionalFilter] = useState(initFilter);

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);

  const [selectValue, setSelectValue] = useState('rinkai');
  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
  };
  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));


  const handleSendOrderSubmit = async ({
    email,
    message,
    language,
    isSendCopyToMeChecked,
  }) => {
    setSendOrderDialogOpen(false);
    await handleSendOrder(sendOrderDialogData.id, {
      email,
      message,
      language,
      isSendCopyToMeChecked,
    });
  };

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const warehouseOrdersHeader = [
    {
      name: "indexNumber",
      label: "incomingOrders.number",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "customerContact",
      label: "incomingOrders.customer",
      isOrderable: false,
    },
    {
      name: "pickupAtStart",
      label: "incomingOrders.date",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "route",
      label: "incomingOrders.route",
      isOrderable: false,
    },
    {
      name: "customerPrice",
      label: "incomingOrders.customerPrice",
      isOrderable: false,
    },
    {
      name: "packages",
      label: "incomingOrders.warehousePackages",
      isOrderable: false,
    },
    {
      name: "receivedDate",
      label: "incomingOrders.warehouseReceivedDate",
      isOrderable: false,
    },
    {
      name: "receivedBy",
      label: "incomingOrders.warehouseReceivedBy",
      isOrderable: false,
    },
    {
      name: "externalReference",
      label: "incomingOrders.externalReference",
      isOrderable: false,
    },
    {
      name: "box",
      label: "shipments.box.title",
      isOrderable: false
    },
  ];

  if (mode === WarehouseOrdersModeEnum.INCOMING_ORDERS) {
    warehouseOrdersHeader.splice(
      4,
      0,
      {
        name: "warehouseState",
        label: "incomingOrders.warehouseState",
        isOrderable: false,
        width: "6%",
      }
    )
  }

  if (mode === WarehouseOrdersModeEnum.ORDERS_HISTORY) {
    warehouseOrdersHeader.push(
      {
        name: "dispatchedBy",
        label: "incomingOrders.warehouseDispatchedBy",
        isOrderable: false,
        width: "6%",
      },
      {
        name: "dispatchedDate",
        label: "incomingOrders.warehouseDispatchedDate",
        isOrderable: false,
        width: "6%",
      }
    )
  }

  const isAnyFilterActive = () => {
    const {
      customerPrice,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
    } = filter;

    return [
      customerPrice,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
    ].some((item) => item !== undefined);
  };

  const warehouseOrdersFilters = [
    <ContactFilterDropdown
      id="customers"
      key="customers"
      onChange={(value) =>
        handleSelectCustomer(value.length === 0 ? undefined : value)
      }
      selectAll={(all) => handleSelectCustomer(all)}
      value={filter.customers ?? []}
      search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
      placeholderTitle={t("contacts.customer")}
      onClear={() => handleSelectCustomer(undefined)}
      getTitle={(item) => item.name}
    />,
    <FilterDateRangeDropdown
      id="loadingDate"
      key="loadingDate"
      onChange={(value) => handleSelectLoadingsDateRange(value)}
      value={
        filter.loadingDate
          ? [filter.loadingDate.from, filter.loadingDate.to]
          : []
      }
      onClear={() => handleSelectLoadingsDateRange(undefined)}
      placeholderTitle={t("incomingOrders.loadingsDateRange")}
    />,
    <FilterDateRangeDropdown
      id="unloadingDate"
      key="unloadingDate"
      onChange={(value) => handleSelectUnloadingsDateRange(value)}
      value={
        filter.unloadingDate
          ? [filter.unloadingDate.from, filter.unloadingDate.to]
          : []
      }
      onClear={() => handleSelectUnloadingsDateRange(undefined)}
      placeholderTitle={t("incomingOrders.unloadingsDateRange")}
    />,
    <FilterDateRangeDropdown
      id="createdAt"
      key="createdAt"
      onChange={(value) => handleSelectCreatedAtDateRange(value)}
      value={
        filter.createdAt
          ? [filter.createdAt.from, filter.createdAt.to]
          : []
      }
      onClear={() => handleSelectCreatedAtDateRange(undefined)}
      placeholderTitle={t("incomingOrders.creationDate")}
    />,
    // <FilterDateRangeDropdown
    //   id="receivedDate"
    //   key="receivedDate"
    //   onChange={(value) => handleSelectReceivedDateDateRange(value)}
    //   value={
    //     filter.receivedDate
    //       ? [filter.receivedDate.from, filter.receivedDate.to]
    //       : []
    //   }
    //   onClear={() => handleSelectReceivedDateDateRange(undefined)}
    //   placeholderTitle={t("incomingOrders.warehouseReceivedDate")}
    // />,
    // <FilterDateRangeDropdown
    //   id="deliveryDate"
    //   key="deliveryDate"
    //   onChange={(value) => handleSelectDeliveryDateDateRange(value)}
    //   value={
    //     filter.deliveryDate
    //       ? [filter.deliveryDate.from, filter.deliveryDate.to]
    //       : []
    //   }
    //   onClear={() => handleSelectDeliveryDateDateRange(undefined)}
    //   placeholderTitle={t("incomingOrders.warehouseDeliveryDate")}
    // />,
    <FilterNumberRange
      id="customerPrice"
      key="customerPrice"
      onChange={(value) => handleSelectCustomerPrice(value)}
      value={filter.customerPrice}
      placeholderTitle={t("incomingOrders.customerPrice")}
      onClear={() => handleSelectCustomerPrice(undefined)}
    />,
    <UserFilterDropdown
      id="creators"
      key="creators"
      onChange={(value) =>
        handleSelectCreators(value.length === 0 ? undefined : value)
      }
      selectAll={(all) => handleSelectCreators(all)}
      value={filter.creators ?? []}
      placeholderTitle={t("incomingOrders.creator")}
      search={(text) => loadAvailableUsers(text)}
      onClear={() => handleSelectCreators(undefined)}
      getTitle={(item) => item.name}
    // disabled={!canReadCompanyIncomingOrder}
    />,
    // <UserFilterDropdown
    //   id="receivedBy"
    //   key="receivedBy"
    //   onChange={(value) =>
    //     handleSelectReceivedBy(value.length === 0 ? undefined : value)
    //   }
    //   selectAll={(all) => handleSelectReceivedBy(all)}
    //   value={filter.receivedBy ?? []}
    //   placeholderTitle={t("incomingOrders.warehouseReceivedBy")}
    //   search={(text) => loadAvailableUsers(text)}
    //   onClear={() => handleSelectReceivedBy(undefined)}
    //   getTitle={(item) => item.name}
    // // disabled={!canReadCompanyIncomingOrder}
    // />,
    <FilterStaticDropdown
      id="cargo"
      key="cargo"
      placeholderTitle={t("incomingOrders.cargo")}
      value={filter.cargo ?? []}
      onChange={(arr) =>
        handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
      }
      selectAll={() =>
        handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
      }
      onClear={() => handleSelectCargoTemplate(undefined)}
      source={AvailableCargoItemTemplates.map(({ value, label }) => ({ title: t(label), value }))}
    />,
    <StateFilterDropdown
      id="incomingOrderState"
      key="incomingOrderState"
      placeholderTitle={t("board.state.title")}
      value={filter.state ?? []}
      onChange={(arr) =>
        handleSelectIncomingOrderState(arr.length === 0 ? undefined : arr)
      }
      search={(text) => loadAvailableIncomingOrderState(text)}
      selectAll={(all) =>
        handleSelectIncomingOrderState(all)
      }
      onClear={() => handleSelectIncomingOrderState(undefined)}
      getTitle={(item) => item.name}
    />,
    <PlaceFilterDropdown
      id="places"
      key="places"
      placeholderTitle={t("shipments.places")}
      selectAll={(all) => handleSelectPlaces(all)}
      search={(text) => handleLoadAvailablePlaces(text)}
      value={filter.places ?? []}
      onChange={(value) => handleSelectPlaces(value.length === 0 ? undefined : value)}
      onClear={() => handleSelectPlaces(undefined)}
    />,
    <BoxFilterDropdown
      id="boxes"
      key="boxes"
      placeholderTitle={t("shipments.box.title")}
      selectAll={(all) => handleSelectBoxes(all)}
      search={(text) => handleLoadAvailableBoxes(text)}
      value={filter.boxes ?? []}
      onChange={(value) => handleSelectBoxes(value.length === 0 ? undefined : value)}
      onClear={() => handleSelectBoxes(undefined)}
    />,
  ]

  return (
    <>
      <FilterContainer
        searchField={
          <SearchTextfield
            handleSearch={handleSearch}
            value={search}
            fullWidth
            placeholder={t("incomingOrders.searchTooltip")}
          />
        }
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={warehouseOrdersFilters}
        onClear={handleDeselect}
      />
      <DataTable
        headers={warehouseOrdersHeader}
        actions={[]}
        selectedColumns={selectedColumns}
        data={data}
        dataCount={dataCount}
        loading={loading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        onChangeSelectedColumns={handleChangeSelectedColumns}
      />
    </>
  );
};

export default LinehallWarehouseOrders;
