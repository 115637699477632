import React, { useState } from "react";
import TagColors from "../../../utility/TagColors";
import { useTranslation } from "react-i18next";
import {Button, Menu, MenuItem} from "@material-ui/core";

const OutcomingOrderState = ({ order, state, updateOrderState, reload }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateState = async (state) => {
    try {
      const res = await updateOrderState(order.id, state);
      await reload();
    } catch (err) {
      console.log(err);
    }
  };

  const statusSource = state.map(e => ({ title: e.name, value: e.id, tag: e.tag }));
  const selectedStatus = statusSource.find((status) => status.value === order.stateId);

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: TagColors[selectedStatus.tag] }}
      >
        {selectedStatus.title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statusSource.map((state) => (
          <MenuItem
            key={state.value}
            style={{
              backgroundColor: TagColors[state.tag]
            }}
            onClick={() => {
              updateState(state.value);
              handleClose();
            }}
          >
            {state.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OutcomingOrderState;