import React from "react";

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { CustomerProfileSchema } from "../../../utility/validationSchema";

import { parsePhoneNumberFromString } from "libphonenumber-js";

const DEFAULT_COUNTRY_CODE = "CZ";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  error: {
    color: "red"
  }
}));

const UpdateUserProfileDialog = ({
  open,
  customerProfile: { firstName, lastName, phoneNumber },
  handleClose,
  handleSubmit,
  errors,
  touched
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("customers.update")}</DialogTitle>
      <Formik
        validationSchema={CustomerProfileSchema}
        initialValues={{ firstName, lastName, phoneNumber }}
        onSubmit={(values, { setSubmitting }) => {
          const { firstName, lastName, phoneNumber } = values;

          setSubmitting(false);
          handleSubmit(firstName, lastName, parsePhoneNumberFromString(phoneNumber, DEFAULT_COUNTRY_CODE)?.number);
        }}
      >
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          submitForm,
          errors,
          touched,
          values
        }) => (
          <>
            <DialogContent>
              <form className={classes.container}>
                <div>
                  <TextField
                    required
                    name="firstName"
                    label={t("firstName")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={errors.firstName && touched.firstName}
                  />
                  {errors.firstName && touched.firstName && (
                    <Typography variant="subtitle2" className={classes.error}>
                      {t(errors.firstName)}
                    </Typography>
                  )}
                </div>
                <div>
                  <TextField
                    required
                    name="lastName"
                    label={t("lastName")}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName}
                  />
                  {errors.lastName && touched.lastName && (
                    <Typography variant="subtitle2" className={classes.error}>
                      {t(errors.lastName)}
                    </Typography>
                  )}
                </div>
                <div>
                  <TextField
                    name="phoneNumber"
                    label={t("phone")}
                    error={errors.phoneNumber && touched.phoneNumber}
                    onChange={handleChange}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <Typography variant="subtitle2" className={classes.error}>
                      {t(errors.phoneNumber)}
                    </Typography>
                  )}
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("abort")}
              </Button>
              <Button onClick={submitForm} color="primary">
                {t("update")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateUserProfileDialog;
