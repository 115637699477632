import React, { useCallback } from "react";
import clsx from "clsx";

import {
  makeStyles,
} from "@material-ui/core";
import { deepPurple, red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

import Page from "../../common/Page";

import SettingsBodyNavigation from "./SettingsBodyNavigation";
import SettingsBodyContent from "./SettingsBodyContent";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",

    "& > div:last-child": {
      flex: 1,
      marginTop: spacing(2),
      marginBottom: spacing(2)
    }
  },
  card: {
    padding: 4
  },
  cardBottomMargin: {
    marginBottom: spacing(4)
  },
  buttonBottom: {
    margin: spacing(1),
    justifyContent: "right"
  },
  purpleAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  imgAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  cardActions: {
    [breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center",
      "& > :not(:last-child)": {
        marginBottom: spacing(1)
      }
    },
    [breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  center: {
    display: "flex",
    justifyContent: "center"
  },
  flexbox: {
    [breakpoints.down("xs")]: {
      display: "grid",
    },
    [breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  leftFlex: {
    [breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center"
    },
    [breakpoints.up("sm")]: {
      flex: 1
    }
  },
  rightFlex: {
    flex: 3
  },
  warning: {
    color: red[500]
  },
  bottomMargin: {
    marginBottom: 10
  },
  topMargin: {
    marginTop: 10
  },
  bankInput: {
    width: spacing(50),
    textAlign: "center",
    fontSize: spacing(3),
    marginTop: 2
  },
  bban: {
    width: "30%",
    [breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [breakpoints.up("sm")]: {
      textAlign: "right"
    }
  },
  iban: {
    minWidth: "30%"
  }
}));

const Settings = ({
  isBankAccountLoading,
  isCompanyProfileLoading,
  isUserProfileLoading,
  isInvoiceLoading,
  isCompanyTermsLoading,
  isUserTermsLoading,
  isUserRoleLoading,
  isExchangeRateModeLoading,
  isWarehousesLoading,

  companyProfile,
  invoices,
  customerProfile,
  user,
  bankAccounts,

  hasReadInvoicesPermission,
  hasUpdateCompanyProfilePermission,
  hasUpdateUserProfilePermission,
  hasDeleteUserProfilePermission,
  hasReadUserProfilePermission,
  hasReadCompanyProfilePermission,
  hasDeleteCompanyProfilePermission,

  handleUpdateCompanyProfileClick,
  handleUpdateCustomerProfileClick,
  handleCreateCompanyProfileClick,
  handleChangePassword,
  handleDeleteCompany,
  handleChangeUserAvatarImage,
  handleChangeCompanyAvatarImage,
  handleUserConditionsClick,
  handleCompanyConditionsClick,

  handleUpdateBankAccountSubmit,
  handleCreateBankAccountSubmit,
  handleRemoveBankAccountSubmit,
  handleReplaceBankAccount,
  handleAppendUnfilledBankAccount,

  updateCompanyProfile,
  updateCompanyProfilePartial,

  stampSignatureFile,
  handleStampSignatureFileSelect,
  handleFileDelete,

  view,
  onViewChange,
  bankAccountsTableProps,
  userRoleTableProps,
  exchangeRatesTableProps,
  warehousesTableProps,

  hasReadBankAccountPermission,
  hasUpdateBankAccountPermission,
  hasDeleteBankAccountPermission,
  hasCreateBankAccountPermission,

  hasCreateRolePermission,
  hasUpdateRolePermission,
  hasReadRolePermission,
  hasDeleteRolePermission,

  isBankAccountEditorOpen,
  setIsBankAccountEditorOpen,

  handleDeleteBankAccountClick,
  handleOpenDeleteBankAccountDialog,
  handleOpenBankAccountEditor,
  handleCreateBankAccountClick,
  handleUpdateBankAcountClick,

  handleOpenDeleteRolesDialog,
  subscriptionInfo,

  exchangeRatesMode,
  handleSetExchangeRatesMode,
  handleCreateExchangeRateClick,
  handleUpdateExchangeRateClick,
  handleOpenDeleteExchangeRatesDialog,

  hasUpdateExchangeRatePermission,
  hasDeleteExchangeRatePermission,
  hasCreateExchangeRatePermission,
  hasUpdateExchangeRateModePermission,
  hasReadExchangeRatePermission,

  hasReadSequencingPermission,
  hasUpdateSequencingPermission,

  hasCreateWarehousePermission,
  hasUpdateWarehousePermission,
  hasDeleteWarehousePermission,
  hasReadWarehousePermission,

  handleDeleteWarehouseClick,
  handleCreateWarehouseClick,
  handleUpdateWarehouseClick,

  userStateTableProps,
  statesData,
  handleOpenDeleteStatesDialog,
  hasInviteUserPermission,

  handleChangeTokenClick,
  token,
  tokens
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Page>
      <div className={classes.root}>
        <SettingsBodyNavigation
          view={view}
          onViewChange={onViewChange}
          hasReadBankAccountPermission={hasReadBankAccountPermission}
          hasReadRolePermission={hasReadRolePermission}
          hasReadWarehousePermission={hasReadWarehousePermission}
          hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
          hasReadExchangeRatePermission={hasReadExchangeRatePermission}
        />
        <SettingsBodyContent
          view={view}
          isBankAccountLoading={isBankAccountLoading}
          isCompanyProfileLoading={isCompanyProfileLoading}
          isUserProfileLoading={isUserProfileLoading}
          isInvoiceLoading={isInvoiceLoading}
          isCompanyTermsLoading={isCompanyTermsLoading}
          isUserTermsLoading={isUserTermsLoading}
          isUserRoleLoading={isUserRoleLoading}
          isExchangeRateModeLoading={isExchangeRateModeLoading}
          isWarehousesLoading={isWarehousesLoading}

          hasUpdateBankAccountPermission={hasUpdateBankAccountPermission}
          hasDeleteBankAccountPermission={hasDeleteBankAccountPermission}
          hasCreateBankAccountPermission={hasCreateBankAccountPermission}

          hasCreateRolePermission={hasCreateRolePermission}
          hasUpdateRolePermission={hasUpdateRolePermission}
          hasReadRolePermission={hasReadRolePermission}
          hasDeleteRolePermission={hasDeleteRolePermission}

          hasCreateWarehousePermission={hasCreateWarehousePermission}
          hasUpdateWarehousePermission={hasUpdateWarehousePermission}
          hasDeleteWarehousePermission={hasDeleteWarehousePermission}
          hasReadWarehousePermission={hasReadWarehousePermission}

          hasReadSequencingPermission={hasReadSequencingPermission}
          hasUpdateSequencingPermission={hasUpdateSequencingPermission}

          classes={classes}
          user={user}

          handleUpdateBankAccountSubmit={handleUpdateBankAccountSubmit}
          handleCreateBankAccountSubmit={handleCreateBankAccountSubmit}
          handleRemoveBankAccountSubmit={handleRemoveBankAccountSubmit}
          handleReplaceBankAccount={handleReplaceBankAccount}
          handleAppendUnfilledBankAccount={handleAppendUnfilledBankAccount}

          updateCompanyProfile={updateCompanyProfile}
          updateCompanyProfilePartial={updateCompanyProfilePartial}

          stampSignatureFile={stampSignatureFile}
          handleStampSignatureFileSelect={handleStampSignatureFileSelect}
          handleFileDelete={handleFileDelete}

          customerProfile={customerProfile}

          hasUpdateUserProfilePermission={hasUpdateUserProfilePermission}
          handleUpdateCustomerProfileClick={handleUpdateCustomerProfileClick}
          handleChangeUserAvatarImage={handleChangeUserAvatarImage}
          
          hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
          hasReadCompanyProfilePermission={hasReadCompanyProfilePermission}
          hasDeleteCompanyProfilePermission={hasDeleteCompanyProfilePermission}

          companyProfile={companyProfile}

          handleUpdateCompanyProfileClick={handleUpdateCompanyProfileClick}
          handleCreateCompanyProfileClick={handleCreateCompanyProfileClick}
          handleChangeCompanyAvatarImage={handleChangeCompanyAvatarImage}
          handleCompanyConditionsClick={handleCompanyConditionsClick}
          handleDeleteCompany={handleDeleteCompany}
          handleUserConditionsClick={handleUserConditionsClick}
          handleUpdateCompanyProfile={updateCompanyProfile}
          handleUpdateCompanyProfilePartial={updateCompanyProfilePartial}

          invoices={invoices}
          bankAccounts={bankAccounts}
          bankAccountsTableProps={bankAccountsTableProps}
          userRoleTableProps={userRoleTableProps}
          exchangeRatesTableProps={exchangeRatesTableProps}
          isBankAccountEditorOpen={isBankAccountEditorOpen}
          setIsBankAccountEditorOpen={setIsBankAccountEditorOpen}

          wareHousesTableProps={warehousesTableProps}

          handleDeleteBankAccountClick={handleDeleteBankAccountClick}
          handleOpenDeleteBankAccountDialog={handleOpenDeleteBankAccountDialog}
          handleOpenBankAccountEditor={handleOpenBankAccountEditor}
          handleCreateBankAccountClick={handleCreateBankAccountClick}
          handleUpdateBankAcountClick={handleUpdateBankAcountClick}

          handleOpenDeleteRolesDialog={handleOpenDeleteRolesDialog}
          subscriptionInfo={subscriptionInfo}

          exchangeRatesMode={exchangeRatesMode}
          handleSetExchangeRatesMode={handleSetExchangeRatesMode}

          handleCreateExchangeRateClick={handleCreateExchangeRateClick}
          handleUpdateExchangeRateClick={handleUpdateExchangeRateClick}
          handleOpenDeleteExchangeRatesDialog={handleOpenDeleteExchangeRatesDialog}
          
          hasUpdateExchangeRatePermission={hasUpdateExchangeRatePermission}
          hasDeleteExchangeRatePermission={hasDeleteExchangeRatePermission}
          hasCreateExchangeRatePermission={hasCreateExchangeRatePermission}
          hasUpdateExchangeRateModePermission={hasUpdateExchangeRateModePermission}
          hasReadExchangeRatePermission={hasReadExchangeRatePermission}

          handleCreateWarehouseClick={handleCreateWarehouseClick}
          handleUpdateWarehouseClick={handleUpdateWarehouseClick}
          handleDeleteWarehouseClick={handleDeleteWarehouseClick}

          userStateTableProps={userStateTableProps}
          statesData={statesData}
          handleOpenDeleteStatesDialog={handleOpenDeleteStatesDialog}
          hasInviteUserPermission={hasInviteUserPermission}

          handleChangeTokenClick={handleChangeTokenClick}
          token={token}
          tokens={tokens}
        />
      </div>
    </Page>
  );
};

export default Settings;
