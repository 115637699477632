import React from "react";

import { OutlinedInputProps, Tooltip, InputAdornment, IconButton, makeStyles, TextField } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@material-ui/pickers";
import { isValid, isBefore, parseISO } from "date-fns";

import { Form, FormValues } from "../../form";
import { getCleanDate } from "../../../utility/common";

interface FormDatePickerProps<T extends FormValues> {
  form: Form<T>;
  name: string;
  label?: string;
  InputProps?: Partial<OutlinedInputProps>;
  isDisabled?: boolean;
  hasFullWidth?: boolean;
  format?: any;
}


const useStyles = makeStyles(() => ({
  error: {
    color: "red"
  }
}));

function FormDatePickerWithDueAdornment<T extends FormValues>(
  {
    form: {
      errors,
      touched,
      values,
      handleBlur,
      handleChange
    },
    name,
    label,
    InputProps,
    isDisabled,
    hasFullWidth,
    format
  }: FormDatePickerProps<T>
): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  const isTouched = touched[name];
  const error = isTouched ? errors[name] : undefined;
  const errorMessage = error?.message || undefined;
  const hasError = error !== undefined;
  const value = values[name] as string;

  return (
    <DatePicker
      value={value}
      disabled={isDisabled}
      allowKeyboardControl
      disableMaskedInput
      allowSameDateSelection
      renderInput={(props) => (
        <TextField
          {...props}
          InputProps={{
            ...props.InputProps
          }}
          name={name}
          label={label}
          error={hasError}
          value={props.inputProps.value}
          onBlur={handleBlur}
          helperText={errorMessage ?? error?.name ?? " "}
          inputProps={{
            ...props.inputProps,
            placeholder: "1.1.2020"
          }}
          fullWidth={hasFullWidth}
        />)
      }
      inputFormat={format}
      InputProps={{
        ...InputProps,
        startAdornment: isValid(Date.parse(value)) && isBefore(Date.parse(value), new Date()) ? (
          <InputAdornment position="start">
            <Tooltip title={t("webapp:shipment.form.warning.due")}>
              <IconButton className={classes.error} size="small">
                <Warning />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ) : undefined
      }}
      onChange={
        // @ts-ignore
        (newValue) => handleChange({ target: { name, value: getCleanDate(newValue) } })
      }
    />
  );
}

export default FormDatePickerWithDueAdornment;
