import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const NotDeliveredKind = {
  UNCATCHED: "UNCATCHED",
  CANCELED: "CANCELED",
  COMPLAINT: "COMPLAINT",
  UNPAID: "UNPAID",
  OTHER: "OTHER"
};

const NotDeliveredDialog = ({
  open,
  handleClose,
  handleSubmit
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(NotDeliveredKind.UNCATCHED);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("webapp:incomingOrder.undeliveredTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("webapp:incomingOrder.undeliveredReasonSubtitle")}
        </DialogContentText>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t("webapp:incomingOrder.undeliveredReasonTitle")}</FormLabel>
          <RadioGroup aria-label="not-delivered-reason" name="not-delivered-reason" value={value} onChange={(e, val) => setValue(val)}>
            <FormControlLabel value={NotDeliveredKind.UNCATCHED} control={<Radio />} label={t(`webapp:incomingOrder.undeliveredReason.${NotDeliveredKind.UNCATCHED}`)} />
            <FormControlLabel value={NotDeliveredKind.CANCELED} control={<Radio />} label={t(`webapp:incomingOrder.undeliveredReason.${NotDeliveredKind.CANCELED}`)} />
            <FormControlLabel value={NotDeliveredKind.COMPLAINT} control={<Radio />} label={t(`webapp:incomingOrder.undeliveredReason.${NotDeliveredKind.COMPLAINT}`)} />
            <FormControlLabel value={NotDeliveredKind.UNPAID} control={<Radio />} label={t(`webapp:incomingOrder.undeliveredReason.${NotDeliveredKind.UNPAID}`)} />
            <FormControlLabel value={NotDeliveredKind.OTHER} control={<Radio />} label={t(`webapp:incomingOrder.undeliveredReason.${NotDeliveredKind.OTHER}`)} />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("abort")}
        </Button>
        <Button onClick={() => handleSubmit(value)} color="primary" data-cy="set-undelivered-reason-confirm">
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotDeliveredDialog;
