import { fromEntries } from "./object";

function filterProperties<T, K extends keyof T>(
  object: T, predicate: (key: K, value: T[K]) => boolean
): T {
  return fromEntries(
    Object
      .entries(object)
      .filter(([key, value]) => predicate(key as K, value))
  );
}

function identity<T>(value: T): T {
  return value;
}

function pipe<A1, R1, R2>(
  f1: (a1: A1) => R1,
  f2: (a: R1) => R2
): (a1: A1) => R2;

function pipe<A1, R1, R2, R3>(
  f1: (a1: A1) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3
): (a1: A1) => R3;

function pipe<A1, R1, R2, R3, R4>(
  f1: (a1: A1) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4
): (a1: A1) => R4;

function pipe<A1, R1, R2, R3, R4, R5>(
  f1: (a1: A1) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5
): (a1: A1) => R5;

function pipe<A1, R1, R2, R3, R4, R5, R6>(
  f1: (a1: A1) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6
): (a1: A1) => R6;

function pipe<A1, R1, R2, R3, R4, R5, R6, R7>(
  f1: (a1: A1) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6,
  f7: (a: R6) => R7
): (a1: A1) => R7;

// @ts-ignore
// eslint-disable-next-line
function pipe(...fns) {
  return fns
    .reduce((f1, f2) => (...args: unknown[]) => f2(f1(...args)));
}

export {
  filterProperties,
  identity,
  pipe
};
