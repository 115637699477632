import React, { useState } from "react";

import { Fade, makeStyles, Grid } from "@material-ui/core";
import queryString from "query-string";

import useRouter from "../../hook/useRouter";
import logo from "../../../asset/image/logo.svg";
import logoASEN from "../../../asset/image/high-res.webp";
import TrackForm from "./TrackForm";
import { useTranslation } from "react-i18next";
import TrackDetail from "./TrackDetail";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  mainSign: {
    backgroundColor: "#eeeee",
    minHeight: "100%"
  },
  logo: {
    width: 250
  },
  side: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(10),
    paddingBottom: spacing(1),
    [breakpoints.up(420 + spacing(4))]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto"
    },
    "& > div": {
      marginTop: spacing(4),
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      "& button": {
        width: 60
      },
      "& > h1": {
        marginBottom: spacing(2)
      },
      "& > span": {
        textAlign: "center"
      },
      "& > form": {
        width: "100%",
        "& > section": {
          marginBottom: spacing(2),
          "& > div": {
            width: "100%",
            "&:not(last-child)": {
              marginBottom: spacing(1)
            }
          }
        }
      }
    }
  },
  main: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(10),
    paddingBottom: spacing(1),
    [breakpoints.up(420 + spacing(4))]: {
      width: 420,
      marginLeft: "auto",
      marginRight: "auto"
    },
    "& > div": {
      marginTop: spacing(4),
      display: "flex",
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      "& button": {
        width: "100%"
      },
      "& > h1": {
        marginBottom: spacing(2)
      },
      "& > span": {
        textAlign: "center"
      },
      "& > form": {
        width: "100%",
        "& > section": {
          marginBottom: spacing(2),
          "& > div": {
            width: "100%",
            "&:not(last-child)": {
              marginBottom: spacing(1)
            }
          }
        }
      }
    }
  }
}));

const Track = () => {
  const {location: {search}} = useRouter();
  const {token} = queryString.parse(search);
  const classes = useStyles();
  const {t} = useTranslation();
  const [orderTracking, setOrderTracking] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={classes.mainSign}>
      <main className={orderTracking ? classes.side : classes.main}>
        <Fade in timeout={1000}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} align="center">
              <a href={`/?token=${token || ''}`}><img className={classes.logo} src={logoASEN} alt="logo"/></a>
            </Grid>
            {orderTracking ?
              <TrackDetail isLoading={isLoading} orderTracking={orderTracking} token={token} setOrderTracking={setOrderTracking}/>
              :
              <TrackForm setIsLoading={setIsLoading} setOrderTracking={setOrderTracking} token={token}/>
            }
          </Grid>
        </Fade>
      </main>
    </div>
  );
};

export default Track;
