import React, { useEffect, useState } from "react";

import { deserializeContact } from "@cargotic/api-client";

import ShipmentConceptContext from "../../context/ShipmentConceptContext";

const key = "shipmentConcept";

const ShipmentConceptProvider = ({ children }) => {
  const [shipment, setShipment] = useState(() => {
    const value = localStorage.getItem(key);
    if (value) {
      const parsedValue = JSON.parse(value);

      if (!parsedValue) {
        return null;
      }

      parsedValue.waypoints = parsedValue.waypoints?.map(({ arriveAtFrom, arriveAtTo, ...rest }) => ({
        arriveAtFrom: arriveAtFrom ? new Date(arriveAtFrom) : undefined,
        arriveAtTo: arriveAtTo ? new Date(arriveAtTo) : undefined,
        ...rest
      }));

      if (!parsedValue.waypoints) {
        return null;
      }

      if (parsedValue.customerContact) {
        parsedValue.customerContact = deserializeContact(parsedValue.customerContact);
      }

      if (parsedValue.carrierContact) {
        parsedValue.carrierContact = deserializeContact(parsedValue.carrierContact);
      }

      return parsedValue;
    }
    return null;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(shipment));
  }, [shipment]);

  return (
    <ShipmentConceptContext.Provider value={{
      shipment,
      setShipment
    }}
    >
      {children}
    </ShipmentConceptContext.Provider>
  );
};

export default ShipmentConceptProvider;
