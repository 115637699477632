import React from "react";
import {useHistory} from "react-router-dom";
import WarehouseView from "../core/WarehouseDetail/WarehouseView";
import WarehouseContainer from "../core/WarehouseDetail/WarehouseContainer";

const WarehousesContainerAdapter = ({ view }) => {
  const history = useHistory();

  const handleViewChange = (value, selectedWarehouseId) => {
    switch (value) {
      case WarehouseView.INCOMING_ORDERS: {
        history.push(`/warehouses/${selectedWarehouseId}/incoming-orders/`);
        return;
      }

      case WarehouseView.ORDERS_HISTORY: {
        history.push(`/warehouses/${selectedWarehouseId}/order-history`);
        return;
      }

      case WarehouseView.WAREHOUSEMEN: {
        history.push(`/warehouses/${selectedWarehouseId}/warehousemen/`);
        return;
      }

      case WarehouseView.INCOMING_SHIPMENTS: {
        history.push(`/warehouses/${selectedWarehouseId}/shipments/incoming`);
        return;
      }

      case WarehouseView.OUTCOMING_SHIPMENTS: {
        history.push(`/warehouses/${selectedWarehouseId}/shipments/outcoming`);
        return;
      }

      default: {
        throw new Error(`Invalid warehouse view '${view}'`);
      }
    }
  };

  return (
    <WarehouseContainer
      view={view}
      onViewChange={handleViewChange}
    />
  );
};

export default WarehousesContainerAdapter;
