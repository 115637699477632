
import React from "react";

import { Grid, Tooltip, Typography } from "@material-ui/core";

import {
  getFormattedPlaceName,
} from "../../../utility/waypoint";

const IncomingOrdersRoute = ({ incomingOrder }) => (
  <Grid key={incomingOrder.id} container>
    {incomingOrder.journey.waypoints.map((waypoint, index) => {
      const { components, formatted } = waypoint.place.address;
      const result = Array.isArray(components)
        ? getFormattedPlaceName(components)
        : formatted;
      return (
        <Grid key={index} item xs={12}>
          <Typography variant="body2">{result}</Typography>
        </Grid>
      );
    })}
  </Grid>
);

export default IncomingOrdersRoute;