import React from "react";

import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox
} from "@material-ui/core";

import FilterDynamicSuggestionDropdown from "../FilterDynamicSuggestionDropdown";
import { getNameInitials } from "../../../cargotic-webapp/utility/common";

const UserFilterDropdown = ({
  placeholderTitle,
  onChange,
  value,
  selectAll,
  search,
  getTitle,
  onClear,
  disabled = false
}) => (
    <FilterDynamicSuggestionDropdown
      placeholderTitle={placeholderTitle}
      onChange={onChange}
      value={value}
      disabled={disabled}
      selectAll={selectAll}
      renderItem={({ id, name, avatarUrl, email }, checked, handleChange) => (
        <ListItem button key={id} onClick={() => handleChange({ target: { checked: !checked } })}>
          <ListItemAvatar>
            <Avatar
              alt={name}
              src={avatarUrl}
            >
              {getNameInitials(name)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={name} secondary={email} />
          <ListItemSecondaryAction>
            <Checkbox
              color="primary"
              checked={checked}
              edge="end"
              onChange={handleChange}
            />
          </ListItemSecondaryAction>
        </ListItem>)}
      search={search}
      getTitle={getTitle}
      onClear={onClear}
    />
  );

export default UserFilterDropdown;
