import React, { useCallback, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { cs, enUS } from "date-fns/locale";

import {
  Grid,
  Typography
} from "@material-ui/core";

import useRouter from "../../hook/useRouter";

import {
  createPlace,
  updatePlace,
  deletePlace,
  createPlacesQuery
} from "../../../resource";

import PlaceCreateDialog from "./PlaceCreateDialog";
import PlaceDeleteDialog from "./PlaceDeleteDialog";
import PlaceUpdateDialog from "./PlaceUpdateDialog";

import useAuth from "../../hook/useAuth";
import useTable from "../../../datatable/useTable";

import Places from "./Places";
import SwipeableDialog from "../../../swipeableDialog";
import PlaceSearchFailDialog from "../../common/PlaceSearchFailDialog";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window"

const PlacesContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user: { id: userId, role: myRole }, hasPermission } = useAuth();

  const locale = t('locale') === "cs" ? cs : enUS;

  const pathname = location.pathname.slice(1);

  const { location: { search: routerSearch } } = useRouter();

  const {
    searchText: initSearchText
  } = getTableUrlParams(routerSearch);

  const [places, setPlaces] = useState([]);
  const [search, setSearch] = useState(initSearchText);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [searchFailDialogOpen, setSearchFailDialogOpen] = useState(false);
  const [selectedForUpdate, setSelectedForUpdate] = useState({});

  let reloadDelay;
  let storeSearchDelay;


  const reloadPlaces = useCallback(async (offset, limit, ordering) => {
    let placesData = [];
    let promise = createPlacesQuery({
      filter: { search: search, id: null },
      orderBy: ordering,
      offset: offset,
      limit: limit
    });

    let places = await promise;

    if (places.count === 0 && offset !== 0) {
      handleChangePage(
        undefined,
        0
      )
    }

    places.places.map((place) => {
      place.selected = false;

      let tableCells = [];
      tableCells.push({
        render:
          <Typography variant="body2"> {place.alias} </Typography>
      });
      tableCells.push({
        render: <Typography variant="body2"> {place.address.formatted} </Typography>
      });

      placesData.push({ id: place.id, row: tableCells, selected: false });
      return place;
    });
    setPlaces(places.places);
    return { data: placesData, totalCnt: places.count };
  }, [search]);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = useTable(reloadPlaces, "places");

  const selectedPlaces = [];
  for (let index = 0; index < data.length; index++) {
    if (data[index].selected) {
      selectedPlaces.push(places[index]);
    }
  }

  const selected = loading
    ? null
    : places.filter(({ selected }) => selected).length;

  const handleCreateSubmit = (alias, address) => {
    setCreateDialogOpen(false);
    createPlace(alias, address).then(() => {
      reloadData();
    });
  };

  const handleUpdateSubmit = (alias, address, placeId) => {
    setUpdateDialogOpen(false);
    updatePlace(alias, address, placeId).then(() => {
      reloadData();
    });
  };

  const handleDeleteSubmit = () => {
    setDeleteDialogOpen(false);

    const ids = selectedPlaces.map(({ id }) => id);
    const requests = ids.map(deletePlace);

    return Promise.all(requests)
      .then(() => {
        reloadDataFromScratch();
      })
      .catch((error) => {
        console.log(error);

        enqueueSnackbar(t("places.error.delete"), {
          variant: "error"
        });
      });
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250)
  }, [search])

  const canCreatePlace = hasPermission("resource.place.create");
  const canUpdatePlace = hasPermission("resource.place.update");
  const canDeletePlace = hasPermission("resource.place.delete");

  return (
    <>
      <Places
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        loading={loading}
        search={search}
        ordering={ordering}
        direction={direction}
        rowsPerPage={rowsPerPage}
        page={page}
        checkedAll={checkedAll}
        canCreatePlace={canCreatePlace}
        canUpdatePlace={canUpdatePlace}
        canDeletePlace={canDeletePlace}
        handleSort={handleSort}
        handleSearch={handleSearch}
        handleSelectAll={handleSelectAll}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreateRequest={() => setCreateDialogOpen(true)}
        handleDeleteRequest={handleOpenDeleteDialog}
        handleSelect={handleSelect}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        handleUpdateRequest={(id) => {
          let place = places.filter(_place => _place.id == id)[0];

          setSelectedForUpdate(place);
          setUpdateDialogOpen(true);
        }}
      />
      <SwipeableDialog
        isOpen={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        form={(
          <PlaceCreateDialog
            open={createDialogOpen}
            handleSearchFail={() => setSearchFailDialogOpen(true)}
            handleClose={() => setCreateDialogOpen(false)}
            handleSubmit={handleCreateSubmit}
          />
        )}
      />
      <SwipeableDialog
        isOpen={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        form={(
          <PlaceUpdateDialog
            open={updateDialogOpen}
            place={selectedForUpdate}
            handleSearchFail={() => setSearchFailDialogOpen(true)}
            handleClose={() => setUpdateDialogOpen(false)}
            handleSubmit={handleUpdateSubmit} />
        )}
      />
      <PlaceDeleteDialog
        open={deleteDialogOpen}
        selected={selectedPlaces.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
      <PlaceSearchFailDialog
        isOpen={searchFailDialogOpen}
        onClose={() => setSearchFailDialogOpen(false)}
      />
    </>
  );
}

export default PlacesContainer;
