import React from "react";

import {
  Button, Card, CardContent, Grid, makeStyles,
  TextField
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { TrackOrderSchema } from "../../../utility/validationSchema";
import { httpGet, useApiClient } from "@cargotic/api-client-deprecated";

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    padding: spacing(2),
    "& > section": {
      display: "flex",
      justifyContent: "space-between"
    }
  }
}));


const TrackForm = ({
  token,
  setOrderTracking,
  setIsLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const apiClient = useApiClient();
  const handleSubmit = async ({ incomingOrderReference }) => {
    const { data } = await httpGet(
      apiClient,
      `track-order/${encodeURIComponent(incomingOrderReference)}?token=${token}`
    );
    setOrderTracking(data);
    setIsLoading(false);
  };

  return (
    <Grid item xs={12} md={12}>
      <Card style={{ width: "100%" }}>
        <CardContent className={classes.content}>
          <Formik
            initialValues={{
              incomingOrderReference: ""
            }}
            onSubmit={handleSubmit}
            validationSchema={TrackOrderSchema}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              errors,
              touched
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <div>
                    <TextField
                      name="incomingOrderReference"
                      label={t("incomingOrderReference")}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      required
                      error={touched.incomingOrderReference && typeof errors.incomingOrderReference !== 'undefined'}
                      helperText={touched.incomingOrderReference && t(errors.incomingOrderReference) || " "}
                      fullWidth
                    />
                  </div>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {t("checkIncomingOrderTrace")}
                  </Button>
                </form>
              </>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TrackForm;
