import React from "react";

const BoardCardDetailRow = ({
  classes,
  noCargoLabel,
  shortCargoTitle,
  shortCargoTitleLimited,
  customerPrice,
  currency,
  vehicle,
  language
}) => (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginRight: 10, marginLeft: 10, marginTop: 5 }}>
        <div>
        {shortCargoTitleLimited.length <= 0
            ? noCargoLabel : shortCargoTitle.map((title, index) => (
          <div>{title}</div>
        ))}
        </div>
        <span className={classes.cardPlace}>{
          customerPrice != undefined
            ? `${customerPrice.toLocaleString(language)} ${currency}`
            : ""}
        </span>
      </div>
      <div style={{ marginRight: 10, marginLeft: 10, marginTop: 5 }}>
        {vehicle
          ? <span >{`${vehicle.manufacturer} ${vehicle.model}, ${vehicle.licensePlate}`}</span>
          : null}
      </div>
    </div>
  );

export default BoardCardDetailRow;