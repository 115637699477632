import React from "react";
import {
  Button, Dialog, DialogActions, DialogContent
} from "@material-ui/core";

const IncomingOrderConceptDialog = ({
  t,
  isOpen,
  onClose
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
  >
    <DialogContent>
      {t("incomingOrder.continueInConcept")}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)} color="primary">
        {t("incomingOrder.discard")}
      </Button>
      <Button onClick={() => onClose(true)} color="primary">
        {t("incomingOrder.continue")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default IncomingOrderConceptDialog;
