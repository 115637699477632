import React, { useState } from "react";

import { 
  makeStyles, 
  MenuItem, 
  Select,
  Typography
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import {
    addDays,
    addHours,
    addWeeks,
    addMonths,
    endOfMonth,
    startOfDay,
    startOfMonth,
    startOfWeek,
    endOfWeek,
    endOfDay,
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday
  } from "date-fns";
  import { cs, enUS } from "date-fns/locale";
  import { Periods } from "../../enums/enums";
  import Placeholder from "../../common/Placeholder";

  import { ArrowUpward, ArrowDownward } from "@material-ui/icons";

  import theme from "../App/theme";

const useStyles = makeStyles(({ spacing}) => ({
  bottomPart: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: spacing(1)
  },
  iconMargin: {
    marginRight: spacing(2)
  },
  caption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

const OverviewCard = ({
  loading,
  CardIcon,
  title,
  subtitle,
  value,
  difference,
  bottomCaption,
  enableTimeSelect,
  fetchValue
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;

  const [periodValue, setPeriodValue] = useState("today");

  const convertPeriodToDate = (period) => {
    switch (period) {
      case "currentMonth":
        return {
          start: startOfMonth(new Date(), { locale }),
          end: endOfMonth(new Date(), { locale }),
          startPrevious: startOfMonth(addMonths(new Date(), -1), { locale }),
          endPrevious: endOfMonth(addMonths(new Date(), -1), { locale }),

          term: Periods.DAY
        };
      case "currentWeek":
        return {
          start: startOfWeek(new Date(), { locale }),
          end: endOfWeek(new Date(), { locale }),
          startPrevious: startOfWeek(addWeeks(new Date(), -1), { locale }),
          endPrevious: endOfWeek(addWeeks(new Date(), -1), { locale }),
          term: Periods.DAY
        };
      case "today":
        return {
          start: startOfToday(),
          end: endOfToday(),
          startPrevious: startOfDay(addDays(new Date(), -1), { locale }),
          endPrevious: endOfDay(addDays(new Date(), -1), { locale }),
          term: Periods.HOUR
        };
      case "yesterday":
        return {
          start: startOfYesterday(),
          end: endOfYesterday(),
          startPrevious: startOfDay(addDays(new Date(), -2), { locale }),
          endPrevious: endOfDay(addDays(new Date(), -2), { locale }),
          term: Periods.HOUR
        };
      case "lastWeek":
        return {
          start: startOfWeek(addWeeks(new Date(), -1), { locale }),
          end: endOfWeek(addWeeks(new Date(), -1), { locale }),
          startPrevious: startOfWeek(addWeeks(new Date(), -2), { locale }),
          endPrevious: endOfWeek(addWeeks(new Date(), -2), { locale }),
          term: Periods.DAY
        };
      case "lastMonth":
        return {
          start: startOfMonth(addMonths(new Date(), -1), { locale }),
          end: endOfMonth(addMonths(new Date(), -1), { locale }),
          startPrevious: startOfMonth(addMonths(new Date(), -2), { locale }),
          endPrevious: endOfMonth(addMonths(new Date(), -2), { locale }),
          term: Periods.DAY
        };
      case "allTime":
        return {
          start: undefined,
          end: undefined,
          term: Periods.WEEK
        };
      default:
        return null;
    }
  };

  return (
    <Placeholder
      loading={loading}
      render={() => (
        <>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          {enableTimeSelect ? (
            <Select
              name="period"
              value={periodValue}
              variant="standard"
              onChange={(val) => {
                val.stopPropagation();
                setPeriodValue(val.target.value);
                const period = convertPeriodToDate(val.target.value);
                fetchValue(period);
              }}
              onClose={(val) => {
                val.stopPropagation();
              }}
              disableUnderline
            >
              <MenuItem value="currentMonth">{t("period.currentMonth")}</MenuItem>
              <MenuItem value="lastMonth">{t("period.lastMonth")}</MenuItem>
              <MenuItem value="currentWeek">{t("period.currentWeek")}</MenuItem>
              <MenuItem value="lastWeek">{t("period.lastWeek")}</MenuItem>
              <MenuItem value="today">{t("period.today")}</MenuItem>
              <MenuItem value="yesterday">{t("period.yesterday")}</MenuItem>
              <MenuItem value="allTime">{t("period.allTime")}</MenuItem>
            </Select>
          ) : (
            <Typography variant="body1">{subtitle}</Typography>
          )}
          <div className={classes.bottomPart}>
            <Typography variant="h3" align="right">
              <CardIcon
                fontSize="large"
                color="disabled"
                className={classes.iconMargin}
              />
              {value}
            </Typography>
            <div className={classes.caption}>
              <Typography variant="subtitle1" align="left">
                {bottomCaption}
              </Typography>
              {difference !== undefined
                ? (
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: difference < 0 ? theme.palette.status.danger : theme.palette.primary.light,
                      marginRight: "5px",
                      display: "flex",
                      alignItems: "center"
                    }}
                    >
                    {difference < 0
                      ? (
                        <ArrowDownward style={{height: 14}}/>
                      )
                      : <ArrowUpward style={{height: 14}}/>}
                    {Math.abs(difference) + "%"}
                  </Typography>
                ): null
              }
            </div>
          </div>
        </>
      )}
    />
  );
};

export default OverviewCard;
