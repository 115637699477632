import React from "react";

import { Typography, makeStyles } from "@material-ui/core";
import {
  GoogleMap,
  withGoogleMap,
  DirectionsRenderer,
} from "react-google-maps";
import { formatDistance } from "date-fns";
import { cs, enUS } from "date-fns/locale";

import { useTranslation } from "react-i18next";

import MapTheme from "../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";

const useStyles = makeStyles(({ spacing, palette }) => ({
  containerMarginBottom: {
    marginBottom: spacing(2),
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
}));

const RouteAndCargo = ({ shipment, directions }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const CZECH_REPUBLIC_POSITION = { lat: 49.743979, lng: 15.338667 };

  const DirectionsMap = withGoogleMap(({ directions }) => (
    <GoogleMap
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        styles: MapTheme,
      }}
      defaultZoom={8}
      defaultCenter={CZECH_REPUBLIC_POSITION}
    >
      <DirectionsRenderer directions={directions} />
    </GoogleMap>
  ));

  return (
    <div>
      <div className={classes.containerMarginBottom}>
        <Typography className={classes.subtitle} gutterBottom>
          {t("shipments.route")}{" "}
          {shipment.journey.distance && shipment.journey.duration
            ? `/ ${`${(
                shipment.journey.distance / 1000
              ).toFixed()} km`} / ${formatDistance(
                0,
                shipment.journey.duration * 1000,
                {
                  includeSeconds: true,
                  locale: t("locale") === "cs" ? cs : enUS,
                }
              )}`
            : null}
        </Typography>
        <DirectionsMap
          directions={directions}
          containerElement={<div style={{ height: "75vh", width: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
        />
      </div>
    </div>
  );
};

export default RouteAndCargo;
