import React from "react";

import JourneyPlannerControl from "./JourneyPlannerControl";
import JourneyPlannerMap from "./JourneyPlannerMap";
import JourneyPlannerPlaceSearchFailAlert
  from "./JourneyPlannerSearchFailAlert";

const JourneyPlanner = ({
  className,
  waypoints,
  errors,
  cargo,
  lastLatLng,
  setLastLatLng,
  selectedCargoItemId,
  selectedWaypointIndex,
  mapRef,
  isCargoEditorVisible,
  isCargoItemLoadingDisabled,
  isCargoItemUnloadingDisabled,
  isMapLoaded,
  isJourneyComplete,
  isPlaceSearchFailAlertOpen,
  searchPlace,
  searchPlaceByCoordinates,
  handlePickFromMap,
  suggestPlace,
  unloadableCargoItems,
  onCargoItemChange,
  onCargoItemCopy,
  onCargoItemDelete,
  onCargoItemLoad,
  onCargoItemReload,
  onCargoItemSelect,
  onCargoItemUnload,
  onCargoEditorToggle,
  onComplete,
  onPlaceSearchFail,
  onPlaceSearchFailAlertClose,
  onWaypointAdd,
  onWaypointChange,
  onWaypointError,
  onWaypointDelete,
  onWaypointSelect,
  onWaypointsReorder,
  isDraft
}) => (
    <div className={className}>
      <JourneyPlannerMap ref={mapRef} />
      {
        !isMapLoaded
          ? null
          : (
            <JourneyPlannerControl
              waypoints={waypoints}
              errors={errors}
              cargo={cargo}
              lastLatLng={lastLatLng}
              setLastLatLng={setLastLatLng}
              selectedCargoItemId={selectedCargoItemId}
              selectedWaypointIndex={selectedWaypointIndex}
              isCargoEditorVisible={isCargoEditorVisible}
              isCargoItemLoadingDisabled={isCargoItemLoadingDisabled}
              isCargoItemUnloadingDisabled={isCargoItemUnloadingDisabled}
              isMapLoaded={isMapLoaded}
              isJourneyComplete={isJourneyComplete}
              searchPlace={searchPlace}
              searchPlaceByCoordinates={searchPlaceByCoordinates}
              handlePickFromMap={handlePickFromMap}
              suggestPlace={suggestPlace}
              unloadableCargoItems={unloadableCargoItems}
              onComplete={onComplete}
              onCargoItemChange={onCargoItemChange}
              onCargoItemCopy={onCargoItemCopy}
              onCargoItemDelete={onCargoItemDelete}
              onCargoItemLoad={onCargoItemLoad}
              onCargoItemReload={onCargoItemReload}
              onCargoItemSelect={onCargoItemSelect}
              onCargoItemUnload={onCargoItemUnload}
              onCargoEditorToggle={onCargoEditorToggle}
              onPlaceSearchFail={onPlaceSearchFail}
              onWaypointAdd={onWaypointAdd}
              onWaypointChange={onWaypointChange}
              onWaypointError={onWaypointError}
              onWaypointDelete={onWaypointDelete}
              onWaypointSelect={onWaypointSelect}
              onWaypointsReorder={onWaypointsReorder}
              isDraft={isDraft}
            />
          )
      }
      <JourneyPlannerPlaceSearchFailAlert
        isOpen={isPlaceSearchFailAlertOpen}
        onClose={onPlaceSearchFailAlertClose}
      />
    </div>
  );

export default JourneyPlanner;
