import React from "react";

import {
  Grid, Button, TextField, makeStyles, Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

import Page from "../../common/Page";
import { createMail } from "../../../resource";
import useRouter from "../../hook/useRouter";
import { FeedbackSchema } from "../../../utility/validationSchema";

const useStyles = makeStyles(({ spacing }) => ({
  page: {
    marginTop: spacing(20)
  },
  title: {
    fontWeight: 1000
  },
  notFound: {
    fontWeight: 700,
    marginTop: 5
  },
  button: {
    marginTop: 10
  }
}));

const Feedback = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();

  const createFeedback = content => createMail(content).then(() => {
    enqueueSnackbar(t("feedback.sent"), {
      variant: "success"
    });
    history.push("/");
  })
    .catch((error) => {
      console.log(error);

      enqueueSnackbar(t("feedback.error"), {
        variant: "error"
      });
    });

  return (
    <>
      <Page title={t("menu.feedback")} >
        <Formik
          validationSchema={FeedbackSchema}
          initialValues={{ feedback: "" }}
          onSubmit={({ feedback }) => {
            createFeedback(feedback);
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            touched
          }) => (
              <form onSubmit={handleSubmit}>
                <Grid container direction="row">
                  <Grid item xs={12} style={{ marginTop: 15 }}>
                    <TextField
                      name="feedback"
                      type="text"
                      label={t("feedback.hint")}
                      variant="outlined"
                      value={values.feedback}
                      error={errors.feedback && touched.feedback}
                      helperText={touched.feedback && t(errors.feedback)}
                      multiline
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 15,
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      size="large"
                    >
                      {t("save")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
        </Formik>
      </Page>
    </>
  );
};

export default Feedback;
