import React from "react";

import { makeStyles } from "@material-ui/core";

import ContactProfileView from "../../ContactProfileView";
import ContactEmployeeOverview from "../ContactEmployeeOverview";
import ContactOverview from "../ContactOverview";
import ContactVehiclesOverview from '../ContactVehiclesOverview'

const ContactProfileBodyContent = ({
  className,
  activity,
  contact,
  employeeQuery,
  employees,
  orderBalanceHighlight,
  selectedEmployee,
  shipmentBalanceHighlight,
  shipmentCommissionHighlight,
  shipmentsHighlight,
  user,
  view,
  isActivityLoading,
  isEmployeeDeleteConfirmationAlertOpen,
  isEmployeeEditorOpen,
  isSubcontractorEmployeeEditorOpen,
  isEmployeesLoading,
  isHighlightsLoading,
  onActivityLoad,
  onBreadcrumbsChange,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onEmployeeAdd,
  onSubcontractorEmployeeSubmit,
  onSubcontractorEmployeeAdd,
  onSubcontractorEmployeeClose,
  onEmployeeDelete,
  onEmployeeDeleteConfirmationAlertClose,
  onEmployeeDeleteConfirmationAlertSubmit,
  onEmployeeEdit,
  onEmployeeEditorClose,
  onEmployeeEditorSubmit,
  onEmployeeQueryChange,
  onOrderBalanceHighlightChange,
  onShipmentBalanceHighlightChange,
  onShipmentsHighlightChange,
  onShipmentCommissionHighlightChange,
  onShipmentsHeaderIconClick
}) => {
  switch (view) {
    case ContactProfileView.CONTACT_EMPLOYEE_OVERVIEW: {
      return (
        <ContactEmployeeOverview
          className={className}
          contact={contact}
          employees={employees}
          query={employeeQuery}
          selectedEmployee={selectedEmployee}
          isDeleteConfirmationAlertOpen={isEmployeeDeleteConfirmationAlertOpen}
          isEmployeeEditorOpen={isEmployeeEditorOpen}
          isSubcontractorEmployeeEditorOpen={isSubcontractorEmployeeEditorOpen}
          isLoading={isEmployeesLoading}
          onBreadcrumbsChange={onBreadcrumbsChange}
          onDeleteConfirmationAlertClose={
            onEmployeeDeleteConfirmationAlertClose
          }
          onDeleteConfirmationAlertSubmit={
            onEmployeeDeleteConfirmationAlertSubmit
          }
          onEmployeeAdd={onEmployeeAdd}
          onSubcontractorEmployeeSubmit={onSubcontractorEmployeeSubmit}
          onSubcontractorEmployeeAdd={onSubcontractorEmployeeAdd}
          onSubcontractorEmployeeClose={onSubcontractorEmployeeClose}
          onEmployeeDelete={onEmployeeDelete}
          onEmployeeEdit={onEmployeeEdit}
          onEditorClose={onEmployeeEditorClose}
          onEditorSubmit={onEmployeeEditorSubmit}
          onQueryChange={onEmployeeQueryChange}
        />
      );
    }

    case ContactProfileView.CONTACT_OVERVIEW: {
      return (
        <ContactOverview
          className={className}
          activity={activity}
          contact={contact}
          orderBalanceHighlight={orderBalanceHighlight}
          shipmentBalanceHighlight={shipmentBalanceHighlight}
          shipmentCommissionHighlight={shipmentCommissionHighlight}
          shipmentsHighlight={shipmentsHighlight}
          user={user}
          isActivityLoading={isActivityLoading}
          isHighlightsLoading={isHighlightsLoading}
          onActivityLoad={onActivityLoad}
          onBreadcrumbsChange={onBreadcrumbsChange}
          onCommentaryChange={onCommentaryChange}
          onCommentaryDelete={onCommentaryDelete}
          onCommentarySubmit={onCommentarySubmit}
          onOrderBalanceHighlightChange={onOrderBalanceHighlightChange}
          onShipmentBalanceHighlightChange={onShipmentBalanceHighlightChange}
          onShipmentsHighlightChange={onShipmentsHighlightChange}
          onShipmentCommissionHighlightChange={onShipmentCommissionHighlightChange}
          onShipmentsHeaderIconClick={onShipmentsHeaderIconClick}
        />
      );
    }

    case ContactProfileView.CONTACT_VEHICLES_OVERVIEW: {
      return (
        <ContactVehiclesOverview 
          onBreadcrumbsChange={onBreadcrumbsChange}
          contact={contact}
        />
      )
    }

    default: {
      throw new Error(`Invalid contact profile view '${view}'!`);
    }
  }
};

export default ContactProfileBodyContent;
