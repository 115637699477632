import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import InvoiceOutIcon from "@material-ui/icons/ArrowUpward";
import InvoiceInIcon from "@material-ui/icons/ArrowDownward";

import { ShipmentInvoiceType } from "@cargotic/webapp/component/enums/enums";

import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-flex",
        flexDirection: "column-reverse",
        width: "fit-content"
    }
}));

export function InvoicesSpeedDial({ disabled }) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const redirectIncoming = useCallback(() => {
        history.push(`/invoice?type=${ShipmentInvoiceType.RECEIVED}`);
    }, [history]);

    const redirectOutgoing = useCallback(() => {
        history.push(`/invoice?type=${ShipmentInvoiceType.ISSUED}`);
    }, [history]);

    return (
        <SpeedDial
            FabProps={{ disabled }}
            classes={{ root: classes.root }}
            ariaLabel="create invoice"
            icon={<SpeedDialIcon openIcon={<Add />} />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="up"
        >
            <SpeedDialAction
                key={"create-outgoing-invoice"}
                icon={<InvoiceOutIcon />}
                tooltipTitle={t("invoices.createOutgoing")}
                onClick={redirectOutgoing}
            />
            <SpeedDialAction
                key={"create-incoming-invoice"}
                icon={<InvoiceInIcon />}
                tooltipTitle={t("invoices.createIncoming")}
                onClick={redirectIncoming}
            />
        </SpeedDial>
    );
}

InvoicesSpeedDial.propTypes = {
    disabled: PropTypes.bool
};

InvoicesSpeedDial.defaultProps = {
    disabled: false
};

export default InvoicesSpeedDial;
