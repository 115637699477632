import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import {
  deserializeFloat,
  deserializeInteger,
  identity,
  pipe,
  EditableTypography,
  ValueError
} from "@cargotic/common-deprecated";

import { ShipmentType, ShipmentState } from "@cargotic/model-deprecated";

import { Currency, convertCurrency } from "@cargotic/currency";
import { useApiClient } from "@cargotic/api-client-deprecated";
import {
  allowEmptyString, required, isObject, validateStringLength, validateDateString
} from "@cargotic/validate";
import {
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  Box
} from "@material-ui/core";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Assignment, LocalShipping } from "@material-ui/icons";

import RelatedInfo from "../RelatedInfo";
import ShipmentSummary from "../ShipmentSummary";
import ShipmentForwarderForm from "../ShipmentForwarderForm";

import ShipmentFormType from "../../../utility/ShipmentFormType";
import { createFormValidator, FormValidationError, useForm } from "../../../form";

import { generateUuid } from "../../../../../multiload/cargotic-common";

const SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH = 15;

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    padding: spacing(2)
  },
  separator: {
    flexGrow: 1
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "& > div > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > div > button": {
      minWidth: spacing(14)
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    display: "flex"
  },
  selectState: {
    marginRight: spacing(1),
    minWidth: 100
  },
  button: {
    background: "#29AEA0",
    color: "#FFF",
    borderRadius: 200,
    width: "100%",
    marginBottom: 31,
    fontSize: 16,
    textTransform: "uppercase",
    justifyContent: "flex-start",
    padding: spacing(2),
    paddingLeft: 30
  },
  btnText: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    paddingRight: 20
  },
  createOrder: {
    marginTop: 73
  },
  back: {
    width: 162,
    height: 36,
    marginTop: 13
  },
  backSpaced: {
    marginRight: 13,
  }
}));

const OutcomingOrderForm = ({
  className,
  apiClient,
  exchangeRates,
  journey,
  shipment: {
    indexNumber,
    orderSerialNumber: initialOrderSerialNumber,
    type,
    isDraft,
    state: initialState,
    commissionCurrency = Currency.CZK,
    carrierContact: initialCarrierContact = "",
    driverContact: initialDriverContact,
    vehicleInfo: initialVehicleInfo,
    carrierPaymentDueDays: initialCarrierPaymentDueDays = "",
    carrierPrice: initialCarrierPrice = "",
    carrierPriceCurrency: initialCarrierPriceCurrency = Currency.CZK,
    carrierEmployee: initialCarrierEmployee = "",
    isCarrierPriceWithDph: initialIsCarrierPriceWithDph,
    driver: initialDriver = "",
    vehicle: initialVehicle = "",
    trailer: initialTrailer = "",
    notes: initialNotes,
    terms: initialTerms,
    services: initialServices,
    documents: initialDocuments,
    ...shipment
  },
  isExtended,
  isUpdating,
  availableTerms,
  onBack,
  onFormChange,
  onSave
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const [formType, setFormType] = useState(
    type === ShipmentType.CARRIED ? ShipmentFormType.CARRIER : ShipmentFormType.FORWARDER
  );
  const [state, setState] = useState(initialState || ShipmentState.QUEUE);
  const [stateChange, setStateChange] = useState(false);
  const [
    isOrderSerialNumberDuplicate,
    setIsOrderSerialNumberDuplicate
  ] = useState(false);

  const [shipmentNumber, setShipmentNumber] = useState(indexNumber);
  const validateDeleted = (x, message = "") => {
    if (typeof x === "object" && x?.isDeleted) {
      throw new ValueError(message);
    }

    return x;
  };

  const validateContact = pipe(
    (contact) => required(contact, t("webapp:outcomingOrder.form.error.contact")),
    (contact) => isObject(contact, t("webapp:outcomingOrder.form.error.contact")),
    (contact) => validateDeleted(contact, t("webapp:outcomingOrder.form.error.deletedContact"))
  );

  const validateTerms = pipe(
    (terms) => required(terms, t("webapp:outcomingOrder.form.error.contact"))
  );

  const validateOrderSerialNumber = pipe(
    allowEmptyString(
      (value) => validateStringLength(value, 16, t("webapp:outcomingOrder.form.error.orderSerialNumberLength"))
    )
  );

  const validateDraftContact = pipe(
    allowEmptyString((contact) => isObject(contact, t("webapp:outcomingOrder.form.error.contact"))),
    (contact) => validateDeleted(contact, t("webapp:outcomingOrder.form.error.deletedContact"))
  );

  const validateEmployee = pipe(
    allowEmptyString((employee) => isObject(employee, t("webapp:outcomingOrder.form.error.employee"))),
    (employee) => validateDeleted(employee, t("webapp:outcomingOrder.form.error.deletedEmployee"))
  );

  const isString = x => typeof x === "string";
  const validateDraftPaymentDueDays = allowEmptyString(
    (x) => deserializeInteger(x, 10, t("webapp:outcomingOrder.form.error.paymentDueDays"))
  );

  const validateParsedDate = allowEmptyString(
    (x) => validateDateString(x, t("webapp:outcomingOrder.form.error.date"))
  );

  const validateDraftPrice = allowEmptyString(
    (x) => deserializeFloat(x, t("webapp:outcomingOrder.form.error.price"))
  );

  const validateDriver = pipe(
    (driver) => required(driver, t("webapp:outcomingOrder.form.error.driver")),
    (driver) => isObject(driver, t("webapp:outcomingOrder.form.error.driver")),
    (driver) => validateDeleted(driver, t("webapp:outcomingOrder.form.error.deletedDriver"))
  );

  const validateDraftDriver = pipe(
    allowEmptyString((driver) => isObject(driver, t("webapp:outcomingOrder.form.error.driver"))),
    (driver) => validateDeleted(driver, t("webapp:outcomingOrder.form.error.deletedDriver"))
  );

  const validateVehicle = pipe(
    (vehicle) => required(vehicle, t("webapp:outcomingOrder.form.error.vehicle")),
    (vehicle) => isObject(vehicle, t("webapp:outcomingOrder.form.error.vehicle")),
    (vehicle) => validateDeleted(vehicle, t("webapp:outcomingOrder.form.error.deletedVehicle"))
  );

  const validateDraftVehicle = pipe(
    allowEmptyString((vehicle) => isObject(vehicle, t("webapp:outcomingOrder.form.error.vehicle"))),
    (vehicle) => validateDeleted(vehicle, t("webapp:outcomingOrder.form.error.deletedVehicle"))
  );

  const validateTrailer = pipe(
    allowEmptyString((trailer) => isObject(trailer, t("webapp:outcomingOrder.form.error.trailer"))),
    (trailer) => validateDeleted(trailer, t("webapp:outcomingOrder.form.error.deletedTrailer"))
  );

  const validateCarrierPaymentDueDays = allowEmptyString(
    (x) => deserializeInteger(x, 10, t("webapp:outcomingOrder.form.error.paymentDueDays"))
  );

  const validatePosition = allowEmptyString(
    (x) => validateStringLength(x, 32, t("webapp:outcomingOrder.form.error.customerPositionTooLong"))
  );

  const validatePrice = pipe(
    (x) => required(x, t("webapp:outcomingOrder.form.error.price")),
    (x) => deserializeFloat(x, t("webapp:outcomingOrder.form.error.price"))
  );

  const validateOptionalString = allowEmptyString(identity);

  const calculateCommission = (
    customerPrice,
    customerPriceCurrency,
    carrierPrice,
    carrierPriceCurrency,
    commissionCurrency
  ) => {
    const parsedCustomerPrice = parseFloat(customerPrice);
    const parsedCarrierPrice = parseFloat(carrierPrice);

    if (
      Number.isNaN(parsedCustomerPrice)
      || Number.isNaN(parsedCarrierPrice)
    ) {
      return undefined;
    }

    const convertedCustomerPrice = convertCurrency(
      parsedCustomerPrice,
      customerPriceCurrency,
      commissionCurrency,
      exchangeRates
    );

    const convertedCarrierPrice = convertCurrency(
      parsedCarrierPrice,
      carrierPriceCurrency,
      commissionCurrency,
      exchangeRates
    );

    return convertedCustomerPrice - convertedCarrierPrice;
  };

  const validateSubmitDraft = ({ values }) => {
    let errors = {};

    // validate shared values for both forms
    try {
      validateDraftDriver(values.driver);
    } catch (err) {
      errors = { ...errors, driver: err };
    }
    try {
      validateDraftVehicle(values.vehicle);
    } catch (err) {
      errors = { ...errors, vehicle: err };
    }
    try {
      validateTrailer(values.trailer);
    } catch (err) {
      errors = { ...errors, trailer: err };
    }
    try {
      validateDraftPrice(values.carrierPrice);
    } catch (err) {
      errors = { ...errors, carrierPrice: err };
    }


    scrollOnError(errors);
    return errors;
  };

  const onSubmitDraft = (form) => {
    const { values } = form;
    const errs = validateSubmitDraft(form);

    if (Object.keys(errs).length !== 0) {
      form.setErrors(errs);
      form.touchAll();
      return;
    }
    form.setIsSubmitting(true);

    onSave({
      ...shipment,
      ...values,
      isDraft: true,
      state: ShipmentState.QUEUE,
      indexNumber: undefined,
      commission: undefined,
      commissionCurrency: undefined,
      documents: values.documents
        .filter(({ id }) => id)
        .map(({ id }) => ({ id }))
    }, form.setIsSubmitting);
  };

  const forwarderForm = useForm({
    initialValues: {
      carrierContact: initialCarrierContact,
      carrierEmployee: initialCarrierEmployee,
      orderSerialNumber: initialOrderSerialNumber,
      carrierPaymentDueDays: initialCarrierPaymentDueDays,
      carrierPrice: initialCarrierPrice,
      carrierPriceCurrency: initialCarrierPriceCurrency,
      isCarrierPriceWithDph: initialIsCarrierPriceWithDph,
      driverContact: initialDriverContact,
      vehicleInfo: initialVehicleInfo,
      driver: initialDriver,
      vehicle: initialVehicle,
      trailer: initialTrailer,
      notes: initialNotes,
      services: initialServices,
      terms: initialTerms,
      documents: initialDocuments
        .map((document) => ({ ...document, uuid: generateUuid() }))
    },
    validate: createFormValidator({
      orderSerialNumber: validateOrderSerialNumber,
      driverContact: validateOptionalString,
      vehicleInfo: validateOptionalString,
      carrierContact: validateContact,
      carrierPaymentDueDays: validateCarrierPaymentDueDays,
      carrierPrice: validatePrice,
      carrierEmployee: validateEmployee,
      notes: validateOptionalString,
      terms: validateTerms,
    }),
    onChange: (shipment, oldShipment) => {
      const {
        carrierPrice,
        carrierContact: newCarrierContact,
        carrierPriceCurrency: newCarrierPriceCurrency
      } = shipment;
      const {
        carrierContact: oldCarrierContact,
        carrierPriceCurrency: oldCarrierPriceCurrency
      } = oldShipment;

      if (newCarrierContact !== oldCarrierContact) {
        forwarderForm.setValue("carrierContact", newCarrierContact);
      }
    },
    onSubmit: (values) => {
      onSave({
        ...shipment,
        ...values,
        carrierPrice: values.carrierPrice === "" ? undefined : values.carrierPrice,
        vehicleInfo: values.vehicleInfo === "" ? undefined : values.vehicleInfo,
        carrierPaymentDueDays: values.carrierPaymentDueDays === "" ? undefined : values.carrierPaymentDueDays,
        state,
        indexNumber: shipmentNumber,
        commission: calculateCommission(
          forwarderForm.values.customerPrice,
          forwarderForm.values.customerPriceCurrency,
          forwarderForm.values.carrierPrice,
          forwarderForm.values.carrierPriceCurrency,
          commissionCurrency
        ),
        commissionCurrency,
        isDraft: false,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id }))
      }, forwarderForm.setIsSubmitting);
    }
  });

  const calulatedCommission = calculateCommission(
    forwarderForm.values.customerPrice,
    forwarderForm.values.customerPriceCurrency,
    forwarderForm.values.carrierPrice,
    forwarderForm.values.carrierPriceCurrency,
    commissionCurrency
  );

  const commission = formType === ShipmentFormType.FORWARDER
    && calulatedCommission !== undefined
    ? `${calulatedCommission.toFixed(2)} ${commissionCurrency}`
    : t("webapp:outcomingOrder.summary.metric.unknown");

  const handleShipmentNumberChange = ({ target: { value } }) => setShipmentNumber(value);

  const scrollOnError = (errors) => {
    if (errors && Object.keys(errors).length > 0) {
      const elements = document.getElementsByName(Object.keys(errors)[0]);
      if (elements.length > 0) {
        elements[0].scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onSaveDraft = (event) => {
    event.preventDefault();
    forwarderForm.touchAll();

    try {
      forwarderForm.setIsSubmitting(true);

      forwarderForm.setErrors({});

      onSubmitDraft(forwarderForm);
    } catch (error) {
      if (!(error instanceof FormValidationError)) {
        throw error;
      }
      forwarderForm.setErrors(error.errors);
      forwarderForm.setIsSubmitting(false);
    }
  };

  const handleSubmit = (event) => {
    const { errors } = forwarderForm;
    forwarderForm.handleSubmit(event);
    scrollOnError(errors);
  };

  const handleState = ({ target: { value } }) => {
    setState(value);
    setStateChange(true);
  };

  useEffect(() => {
    const carrierPrice = forwarderForm.values.services.reduce((a, i) => a + (
      !isNaN(parseInt(i.price, 10)) ? parseInt(i.price, 10) : 0
    ), 0);

    forwarderForm.setValue("carrierPrice", carrierPrice)
  }, [forwarderForm.values.services]);

  useEffect(() => {
    if (stateChange) {
      return;
    }
    const { errors } = forwarderForm;
    if (Object.keys(errors).length === 0) {
      if (!isUpdating) {
        setState(ShipmentState.READY);
      } else {
        setState(initialState || ShipmentState.READY);
      }
    } else {
      setState(ShipmentState.QUEUE);
    }
  }, [forwarderForm.errors]);

  useEffect(() => {
    onFormChange(forwarderForm.values);
  }, [forwarderForm.values]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, [shipmentNumber]);

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid className={classes.header} item xs={8}>
          <section className={classes.title}>
            <Typography variant="h4">
              {`${t("settings.customSequencing.sectionLabels.OUTCOMING_ORDER")} #`}
            </Typography>
            <EditableTypography
              variant="h4"
              maxLength={SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH}
              value={shipmentNumber}
              isDisabled={isUpdating}
              onChange={handleShipmentNumberChange}
              dataCy="shipment-id"
            />
          </section>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <Paper className={classes.content}>
            <ShipmentForwarderForm
              form={forwarderForm}
              onDocumentsChange={() => onFormChange(forwarderForm.values)}
              apiClient={apiClient}
              newApiClient={apiClient}
              availableTerms={availableTerms}
            />
          </Paper>
          <Grid container>
            <Button variant="contained" color="primary" onClick={onBack} className={classes.back}>
              {t("webapp:outcomingOrder.form.button.back")}
            </Button>

            <div className={classes.separator}/>

            {isDraft && (
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveDraft}
                className={`${classes.back} ${classes.backSpaced}`}
                disabled={forwarderForm.isSubmitting}
              >
                {t("webapp:outcomingOrder.form.button.draft")}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.back}
              disabled={forwarderForm.isSubmitting}
            >
              {t("webapp:outcomingOrder.form.button.complete")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ShipmentSummary
            journey={journey}
            values={forwarderForm.values}
            outcomingOrder={shipment}
            apiClient={apiClient}
          />

          <RelatedInfo outcomingOrder={shipment} />
        </Grid>
      </Grid>
    </form>
  );
};

export default OutcomingOrderForm;
