import React from "react";

import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Page from "../../common/Page";

const TariffExpirationDispatcher = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("settings.tariff.isExpired")}>
      <Typography variant="subtitle1" gutterBottom>{t("settings.tariff.waitForNewTariff")}</Typography>
    </Page>
  );
};

export default TariffExpirationDispatcher;
