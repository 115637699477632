const suggestGooglePlace = (placesAutocompleteService, text) =>
  new Promise((resolve, reject) => (
    placesAutocompleteService.getPlacePredictions(
      { input: text },
      (predictions, status) => {
        if (status !== "OK") {
          reject(status);
          return;
        }

        resolve(predictions);
      }
    )
  ));

export default suggestGooglePlace;
