import React from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";

const FormikCheckbox = ({ form, name, ...props }) => {
  const value = form.values[name];

  return (
    <FormControlLabel
      {...props}
      name={name}
      checked={value}
      onChange={form.handleChange}
      control={(
        <Checkbox color="primary" />
      )}
    />
  );
};

export default FormikCheckbox;
