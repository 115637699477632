import clsx from "clsx";
import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, makeStyles, Grid } from "@material-ui/core";
import Placeholder from "../../common/Placeholder";
import UserProfileCard from "../UserProfile/UserProfileCard";
import UserTokenCard from "../UserProfile/UserTokenCard";
import CompanyCard from "../CompanyProfile/CompanyCard";
import General from "./General/SettingsGeneral";
import StampCard from "./StampCard";
import CustomSequencingSettingsCard from './CustomSequencingSettingsCard'

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    flex: 1
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const SettingsCompanyUserOverview = ({
  className,
  isLoading,

  companyProfile,
  userProfile,

  hasUpdateUserProfilePermission,
  hasDeleteUserProfilePermission,

  handleUpdateCustomerProfileClick,
  handleChangePassword,
  handleChangeUserAvatarImage,

  hasUpdateCompanyProfilePermission,
  hasReadCompanyTermsPermission,
  hasDeleteCompanyProfilePermission,

  hasReadSequencingPermission,
  hasUpdateSequencingPermission,

  handleUpdateCompanyProfileClick,
  handleCreateCompanyProfileClick,
  handleChangeCompanyAvatarImage,
  handleCompanyConditionsClick,
  handleDeleteCompany,
  handleUpdateCompanyProfile,
  handleUpdateCompanyProfilePartial,

  handleUserConditionsClick,
  handleFileDelete,
  stampSignatureFile,
  handleStampSignatureFileSelect,
  hasReadExchangeRatePermission,
  hasInviteUserPermission,
  
  handleChangeTokenClick,
  token,
  tokens
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={clsx(className, classes.progress)}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <>
      <Grid container spacing={2} className={className}>
        <Grid item xs={12} sm={6}>
          <CompanyCard
            classes={classes}

            hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
            hasReadCompanyTermsPermission={hasReadCompanyTermsPermission}
            hasDeleteCompanyProfilePermission={hasDeleteCompanyProfilePermission}

            companyProfile={companyProfile}

            handleUpdateClick={handleUpdateCompanyProfileClick}
            handleCreateClick={handleCreateCompanyProfileClick}
            handleChangeAvatarImage={handleChangeCompanyAvatarImage}
            handleConditionsClick={handleCompanyConditionsClick}
            handleDeleteCompany={handleDeleteCompany}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UserProfileCard
            classes={classes}

            hasUpdateUserProfilePermission={hasUpdateUserProfilePermission}
            hasDeleteUserProfilePermission={hasDeleteUserProfilePermission}

            customerProfile={userProfile}

            handleUpdateClick={handleUpdateCustomerProfileClick}
            handleChangePassword={handleChangePassword}
            handleChangeAvatarImage={handleChangeUserAvatarImage}
          />
        </Grid>
        {hasInviteUserPermission && <Grid item xs={12} sm={6}>
          <UserTokenCard
            classes={classes}
            hasUpdateUserProfilePermission={hasUpdateUserProfilePermission}
            handleChangeTokenClick={handleChangeTokenClick}
            token={token}
            tokens={tokens}
          />
        </Grid>}
        <Grid item xs={12} sm={6}>
          <General
            hasUpdateUserProfilePermission={hasUpdateUserProfilePermission}
            hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
            handleUserConditionsClick={handleUserConditionsClick}

            companyProfile={companyProfile}

            updateCompanyProfile={handleUpdateCompanyProfile}
            updateCompanyProfilePartial={handleUpdateCompanyProfilePartial}
            
            classes={classes}
          />
        </Grid>
        {hasReadSequencingPermission && <Grid item xs={12} sm={6}>
          <StampCard
            classes={classes}
            handleStampSignatureFileSelect={handleStampSignatureFileSelect}
            file={stampSignatureFile}
            handleFileDelete={handleFileDelete}
          />
        </Grid>}
        {hasReadSequencingPermission ?
          (<Grid item xs={12} sm={6}>
            <CustomSequencingSettingsCard
              hasUpdateSequencingPermission={hasUpdateSequencingPermission}
            />
          </Grid>) : null
        }
      </Grid>
    </>
  );
};

export default SettingsCompanyUserOverview;
