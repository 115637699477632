const config = {
  api: {
    url: process.env.CW_API_URL,
    urlV1: `${process.env.CW_API_URL}/v1`,
    urlV2: `${process.env.CW_API_URL}/v2`,
  },
  bolt: {
    url: process.env.CW_BOLT_URL,
  },
  firebase: {
    apiKey: process.env.CW_FIREBASE_API_KEY,
    authDomain: process.env.CW_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.CW_FIREBASE_DATABASE_URL,
    projectId: process.env.CW_FIREBASE_PROJECT_ID,
    storageBucket: process.env.CW_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.CW_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.CW_FIREBASE_APP_ID,
  },
  googleAnalytics: {
    trackingId: process.env.CW_GA_TRACKING_ID,
    url: process.env.CW_GA_URL,
  },
  googleMaps: {
    apiKey: process.env.CW_GM_API_KEY,
    url: `https://maps.googleapis.com/maps/api/js?key=${process.env.CW_GM_API_KEY}&libraries=places&language=cs`,
  },
  hotjar: {
    trackingId: process.env.CW_HOTJAR_TRACKING_ID,
  },
  tagManager: {
    trackingId: process.env.CW_TAG_MANAGER_TRACKING_ID,
  },
  landingPage: {
    url: process.env.CW_LANDING_PAGE_URL,
  },
  documents: {
    url: process.env.CW_DOCUMENTS_URL,
  }
};

export default config;
