import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogContent,
  Typography
} from "@material-ui/core";
import DragAndDrop from "../DragAndDrop";

const ChangeOrder = ({ columns, setColumns }) => {
  const { t } = useTranslation();

  return (
    <DialogContent
      style={{
        maxWidth: "50%",
        borderLeft: "2px solid #d9d9d9",
        padding: 0,
        paddingLeft: "25px"
      }}
    >
      <DialogContent style={{ padding: 0 }}>
        <Typography
          variant="h5"
          style={{ paddingBottom: 12 }}
        >
          {t("selectedColumns.selectedColumns")}
        </Typography>

        <DragAndDrop
          items={columns}
          setItems={setColumns}
          paddingLeft={30}
        />
      </DialogContent>
    </DialogContent>
  );
};

export default ChangeOrder;
