import React, { useState, useMemo} from "react";

import {
  Fab,
  Grid,
  Chip,
  makeStyles,
  Button,
  InputBase,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { UserRole } from "@cargotic/model";

import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  PersonAdd,
  DeleteOutline,
  Search,
  EmojiObjectsOutlined,
  Close,
  ControlPoint,
  Edit as EditIcon
} from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { useApiClient } from "../../../../cargotic-webapp-component";

import Page from "../../common/Page";
import DataTable from "../../../datatable";
import SearchTextfield from "../../common/SearchTextfield";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import FilterDynamicDropdown from "../../../../cargotic-webapp-filter/component/FilterDynamicDropdown";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterTextDropdown from "../../../../cargotic-webapp-filter/component/FilterTextDropdown";

const useStyles = makeStyles(({ palette, spacing }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  marginBottom: {
    marginBottom: spacing(2)
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)"
  }
}));

const Contacts = ({
  data,
  dataCount,
  selectedColumns,
  ordering,
  search,
  direction,
  checkedAll,
  page,
  rowsPerPage,
  loading,
  handleSort,
  canCreateContact,
  canDeleteContact,
  canUpdateContact,
  canCreateShipment,
  filter,
  clearFilter,
  handleSelect,
  handleSelectAll,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleCreateRequest,
  handleDeleteRequest,
  handleUpdateRequest,
  handleViewMoreRequest,
  handleNewShipment,
  handleSelectContactType,
  handleSelectIsSubcontractor,
  handleSelectVisibility,
  handleSelectContactSharedBy,
  handleSelectContactSharedWith,
  handleSelectTags,
  handleSelectCreators,
  handleSelectCreatedAtRange,
  handleSelectHeadquarters,
  handleDeselectFilters,
  handleFilterSettingsOpen,
  defaultFilters,
  handleChangeSelectedColumns
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const client = useApiClient();
  const { enqueueSnackbar } = useSnackbar();

  const [variantCustomer, setVariantCustomer] = useState("outlined");
  const [variantCarrier, setVariantCarrier] = useState("outlined");
  const [variantAll, setVariantAll] = useState("outlined");
  const [searchValue, setSearchValue] = useState("");

  const [selectedContactVisibility, setSelectedContactVisibility] = useState([]);
  const [isAvailableTagsLoading, setIsAvailableTagsLoading] = useState(true);
  const [availableTags, setAvailableTags] = useState([]);
  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const contactsHeader = [
    {
      name: "name",
      label: "contacts.companyNameTitle",
      isOrderable: true
    },
    {
      name: "billingAddress",
      label: "contacts.address",
      isOrderable: true
    },
    {
      name: "creatorName",
      label: "contacts.createdBy",
      isOrderable: true
    },
    {
      name: "type",
      label: "contacts.type",
      isOrderable: true
    },
    {
      name: "tags",
      label: "contacts.tags",
      isOrderable: false
    }
  ];

  const tableActions = [
    {
      column: [
        {
          handleClick: handleNewShipment,
          icon: <ControlPoint />,
          title: "newShipment",
          disableFor: !canCreateShipment ? [-1] : undefined
        }
      ]
    },
    {
      column: [
        {
          handleClick: handleUpdateRequest,
          icon: <EditIcon />,
          title: "contacts.updateTitle",
          disableFor: !canUpdateContact ? [-1] : undefined
        }
      ]
    },
    {
      column: [
        {
          handleClick: handleViewMoreRequest,
          icon: <KeyboardArrowRightIcon />,
          title: "more"
        }
      ]
    }
  ];

  const loadAvailableTags = async () => {
    setIsAvailableTagsLoading(true);

    try {
      const loadedTags = await client.contact.getContactTags();
      setAvailableTags(loadedTags);
      setIsAvailableTagsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.loadTags"), {
        variant: "error"
      });
    }
  };

  const contactSubcontractorSource = useMemo(() => [
    { value: true, title: t("yes") },
    { value: false, title: t("no") },
  ], [t])

  const contactTypeSource = [{ title: t("contacts.customer"), value: "CUSTOMER" }, { title: t("contacts.carrier"), value: "CARRIER" }, { title: t("contacts.both"), value: "BOTH" }];
  const visibilitySource = [{ title: t("contacts.private"), value: "PRIVATE" }, { title: t("contacts.public"), value: "PUBLIC" }];

  const isAnyFilterActive = () => {
    const { types, sharedWith, sharedBy, creators, createdAt, tags, headquarters, isPrivate, isSubcontractor } = filter;
    return types || sharedWith || sharedBy || creators || createdAt || tags || headquarters || isPrivate || isSubcontractor;
  };

  const loadAvailableUsers = async (search) => {
    try {
      const { matches } = await client.user.postUserMatchQuery({
        query: {
          match: { search, limit: 100 }
        }
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const setVisibility = (value) => {
    if (value.includes("PUBLIC") && value.includes("PRIVATE")) {
      handleSelectVisibility(null);
    } else if (value.includes("PUBLIC")) {
      handleSelectVisibility(false);
    } else if (value.includes("PRIVATE")) {
      handleSelectVisibility(true);
    } else {
      handleSelectVisibility(undefined);
    }
  };

  return (
    <Page
      title={t("menu.contacts")}
      actions={(
        <>
          <Fab
            color="primary"
            disabled={loading || !canCreateContact}
            onClick={handleCreateRequest}
          >
            <PersonAdd />
          </Fab>
          <Fab
            className={classes.danger}
            disabled={loading || selected === 0 || !canDeleteContact}
            onClick={handleDeleteRequest}
          >
            <DeleteOutline />
          </Fab>
        </>
      )}
      tertiaryActions={(
        <>
          <Tooltip title={(
            <>
              <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
              <Typography color="inherit">{t("proTableTip")}</Typography>
            </>
          )}
          >
            <IconButton>
              <EmojiObjectsOutlined />
            </IconButton>
          </Tooltip>
        </>
      )}
    >
      <FilterContainer
        searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("contacts.searchTooltip")}/>}
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        loading={loading}
        filters={[
          <FilterStaticDropdown
            id="types"
            key="types"
            placeholderTitle={t("contacts.contactType")}
            value={filter.types ?? []}
            onChange={(arr) => handleSelectContactType(arr.length === 0 ? undefined : arr)}
            selectAll={() => handleSelectContactType(contactTypeSource.map(item => item.value))}
            onClear={() => handleSelectContactType(undefined)}
            source={contactTypeSource}
          />,
          <FilterStaticDropdown
            id="isPrivate"
            key="isPrivate"
            onChange={(value) => setVisibility(value)}
            selectAll={() => setVisibility(visibilitySource.map(item => item.value))}
            value={filter.isPrivate === undefined ? [] : (filter.isPrivate === null ? ["PRIVATE", "PUBLIC"] : (filter.isPrivate === true ? ["PRIVATE"] : ["PUBLIC"]))}
            placeholderTitle={t("contacts.visibility")}
            onClear={() => handleSelectVisibility(undefined)}
            source={visibilitySource}
          />,
          <UserFilterDropdown
            id="sharedWith"
            key="sharedWith"
            onChange={(value) => handleSelectContactSharedWith(value.length === 0 ? undefined : value)}
            search={(text) => loadAvailableUsers(text)}
            selectAll={(all) => handleSelectContactSharedWith(all)}
            getTitle={(item) => item.name}
            value={filter.sharedWith ?? []}
            placeholderTitle={t("contacts.sharedWith")}
            onClear={() => handleSelectContactSharedWith(undefined)}
          />,
          <UserFilterDropdown
            id="sharedBy"
            key="sharedBy"
            onChange={(value) => handleSelectContactSharedBy(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectContactSharedBy(all)}
            value={filter.sharedBy ?? []}
            search={(text) => loadAvailableUsers(text)}
            getTitle={(item) => item.name}
            placeholderTitle={t("contacts.sharedBy")}
            onClear={() => handleSelectContactSharedBy(undefined)}
          />,
          <FilterDateRangeDropdown
            id="createdAt"
            key="createdAt"
            onChange={(value) => handleSelectCreatedAtRange(value)}
            value={filter.createdAt ? [filter.createdAt.from, filter.createdAt.to] : []}
            onClear={() => handleSelectCreatedAtRange(undefined)}
            placeholderTitle={t("contacts.createdAtRange")}
          />,
          <UserFilterDropdown
            id="creators"
            key="creators"
            onChange={(value) => handleSelectCreators(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectCreators(all)}
            value={filter.creators ?? []}
            placeholderTitle={t("contacts.createdBy")}
            search={(text) => loadAvailableUsers(text)}
            onClear={() => handleSelectCreators(undefined)}
            getTitle={(item) => item.name}
          />,
          <FilterDynamicDropdown
            id="tags"
            key="tags"
            onChange={(value) => handleSelectTags(value.length === 0 ? undefined : value)}
            value={filter.tags ?? []}
            selectAll={(arr) => handleSelectTags(arr.map(item => item.id))}
            placeholderTitle={t("contacts.tags")}
            suggest={loadAvailableTags}
            source={availableTags}
            onClear={() => handleSelectTags(undefined)}
            getTitle={(item) => item.label}
            getValue={(item) => item.id}
          />,
          <FilterStaticDropdown
              id="isSubcontractor"
              key="isSubcontractor"
              placeholderTitle={t("webapp:contact.title.subContractor")}
              value={filter.isSubcontractor ?? []}
              onChange={(arr) => handleSelectIsSubcontractor(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectIsSubcontractor(contactSubcontractorSource.map(item => item.value))}
              onClear={() => handleSelectIsSubcontractor(undefined)}
              source={contactSubcontractorSource}
            />,
          // <FilterTextDropdown
          //   id="headquarters"
          //   key="headquarters"
          //   onChange={(value) => handleSelectHeadquarters(value === "" ? undefined : value)}
          //   value={filter.headquarters}
          //   placeholderTitle={t("contacts.headquarters")}
          //   onClear={() => handleSelectHeadquarters(undefined)}
          // />
        ]}
        onClear={handleDeselectFilters}
        defaultFilters={defaultFilters}
        expandLimit={5}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
      />
      <DataTable
        headers={contactsHeader}
        actions={tableActions}
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        loading={loading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeSelectedColumns={handleChangeSelectedColumns}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
      />
    </Page>
  );
};

export default Contacts;
