import React from "react";
import {
  DialogContent,
  Typography,
  Button
} from "@material-ui/core";
import ColumnState from "./ColumnState";
import { useTranslation } from "react-i18next";

const ColumnsSelection = ({
  columns,
  setColumns,
  setActive
}) => {
  const { t } = useTranslation();

  return (
    <DialogContent style={{ padding: 0 }}>
      <DialogContent style={{ padding: 0 }}>
        <Typography
          variant="h5"
        >
          {t("selectedColumns.title")}
        </Typography>
        <Button
          onClick={() => setColumns(null)}
        >
          {t("selectedColumns.default")}
        </Button>
      </DialogContent>

      {columns.map(({
        name,
        label,
        isActive
      }) => (
        <ColumnState
          key={name}
          label={label}
          name={name}
          isActive={isActive}
          setActive={setActive}
        />
      ))}

    </DialogContent>
  );
};

export default ColumnsSelection;
