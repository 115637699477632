import clsx from "clsx";
import React from "react";

import {
  Drawer,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles(({ palette, spacing }) => ({
  paper: {
    minWidth: 560
  },
  wrapper: {
    height: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  content: {
    flex: 1,

    padding: spacing(2),

    overflowY: "auto",

    "& > section:first-child": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    "& > section:last-child": {
      marginTop: spacing(2)
    }
  },
  actions: {
    padding: spacing(2),

    borderTop: `1px solid ${palette.action.disabled}`
  }
}));

const DrawerDialog = ({
  className,
  title,
  children,
  actions,
  isOpen,
  onClose
}) => {
  const classes = useStyles();

  return (
    <Drawer
      classes={{
        paper: clsx(className, classes.paper)
      }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus
    >
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <section>
            <Typography variant="h5">
              {title}
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </section>
          <section>
            {children}
          </section>
        </div>
        {
          actions
            ? (
              <div className={classes.actions}>
                {actions}
              </div>
            ) : null
        }
      </div>
    </Drawer>
  );
};

export default DrawerDialog;
