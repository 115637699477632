import { endOfMonth, startOfMonth, } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import queryString from "query-string";

import { Button, Grid, Link, makeStyles, Menu, MenuItem, Tooltip, Typography, } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { PayIconButton } from "@cargotic/webapp-component";

import {
  ArrowDownward,
  ArrowUpward,
  CallMade,
  CallReceived,
  FastForward,
  Info,
  LocalShipping,
  SwapVert,
  Warning
} from "@material-ui/icons";

import { ContactType, ShipmentType } from "@cargotic/model";

import ShipmentOverview from "@cargotic/webapp-shipment-overview";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useRouter from "../../hook/useRouter";
import useTable from "../../../datatable/useTable";
import { formatJourneyPointDateTime, isDue } from "../../../utility/common";
import { getActionFromWaypoint, getAdvancedFormattedPlaceName, } from "../../../utility/waypoint";

import useAuth from "../../hook/useAuth";
import UserAvatar from "../../../../cargotic-webapp-component/component/UserAvatar";

import { addUrlParam, getTableUrlParams } from "../../../utility/window";

import { loadFilters, storeFilters } from "../../../storage";

import {
  createCompanyBankAccountMatchQuery,
  createShipmentInvoice,
  createShipmentsExport,
  createStateSuggestQuery,
  deleteShipment,
  suggestUsers,
  updateShipmentState
} from "../../../resource";

import { HrefInNewTabIcon, useApiClient } from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";
import WarehouseShipments from "./WarehouseShipments";
import ShipmentsDeleteDialog from "../Shipments/ShipmentsDeleteDialog";
import { WarehouseOrdersModeEnum } from "../../enums/enums";
import WarehouseView from "../WarehouseDetail/WarehouseView";

const useStyles = makeStyles(({ palette }) => ({
  journeyPointActionUpIcon: {
    fill: "#009688",
    height: 20,
    width: 20,
  },
  journeyPointActionDownIcon: {
    fill: palette.error.main,
    height: 20,
    width: 20,
  },
  carriedForwarderIcon: {
    fill: "rgba(0,0,0,0.56)",
  },
  inlineContent: {
    display: "inline-flex",
  },
  warning: {
    fill: "silver",
  },
  error: {
    fill: palette.error.main,
    color: palette.error.main,
  },
  success: {
    fill: "#009688",
  },
}));

const WarehouseShipmentsContainer = ({
  setPageLoading,
  setPageShipments,
  setPageSelected,
  deleteDialogOpen,
  setDeleteDialogOpen,
  mode,
  selectedWarehouse
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const pathname = location.pathname.slice(1);

  const [shipments, setShipments] = useState([]);

  const {
    location: { search: routerSearch },
  } = useRouter();

  const { searchText: initSearchText, filter: initFilter } =
    getTableUrlParams(routerSearch);

  if (initFilter.lastWaypointDateFrom) {
    initFilter.lastWaypointDateFrom = new Date(initFilter.lastWaypointDateFrom);
    initFilter.lastWaypointDateTo = new Date(initFilter.lastWaypointDateTo);
  }

  const [additionalFilter, setAdditionalFilter] = useState(initFilter);
  const [isShipmentsExportDialogOpen, setIsShipmentsExportDialogOpen] =
    useState(false);
  const [isShipmentsExportProcessing, setIsShipmentsExportProcessing] =
    useState(false);
  const [isShipmentsExportDone, setIsShipmentsExportDone] = useState(false);
  const [shipmentsExportLink, setShipmentsExportLink] = useState(null);
  const [search, setSearch] = useState(initSearchText);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  const paymentAvailable = useRef(true);

  const handleDeselect = () => setAdditionalFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const onFilterSettingsSubmit = async (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("shipments", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const defaultFilterValues = [
    "drivers",
    "vehicles",
    "loadingDate",
    "unloadingDate",
    "createdAt",
    "state",
  ];
  const availableFilters = [
    {
      label: t("shipments.driver"),
      value: "drivers",
    },
    {
      label: t("shipments.vehicles"),
      value: "vehicles",
    },
    {
      label: t("shipments.loadingsDateRange"),
      value: "loadingDate",
    },
    {
      label: t("shipments.unloadingsDateRange"),
      value: "unloadingDate",
    },
    {
      label: t("incomingOrders.state"),
      value: "state",
    },
    {
      label: t("shipments.creator"),
      value: "creators",
    },
    {
      label: t("shipments.creationDate"),
      value: "createdAt",
    },
    {
      label: t("shipments.cargo"),
      value: "cargo",
    },
    {
      label: t("shipments.draft"),
      value: "isDraft",
    },
  ];

  const classes = useStyles();

  const apiClient = useApiClient();

  let reloadDelay;
  let storeSearchDelay;

  const getShipmentTypeIcon = ({ type, isDraft }) => {
    if (isDraft) {
      return (
        <Tooltip title={t("shipments.draft")}>
          <Warning className={classes.carriedForwarderIcon} />
        </Tooltip>
      );
    }

    if (type === ShipmentType.CARRIED) {
      return (
        <Tooltip title={t("shipments.carried")}>
          <LocalShipping className={classes.carriedForwarderIcon} />
        </Tooltip>
      );
    }
    if (type === ShipmentType.FORWARDED) {
      return (
        <Tooltip title={t("shipments.forwarded")}>
          <FastForward className={classes.carriedForwarderIcon} />
        </Tooltip>
      );
    }

    return null;
  };

  const ShipmentsDateTime = ({ shipment }) => (
    <Grid key={shipment.id} container direction="column">
      {shipment.journey.waypoints.map((waypoint, index) => {
        const waypointAction = waypoint.cargo
          ? getActionFromWaypoint(waypoint.cargo)
          : [];

        const actionIcon = (waypointAction) => {
          if (
            waypointAction.includes("LOAD") &&
            waypointAction.includes("UNLOAD")
          ) {
            return (
              <Tooltip key={`${index}-both`} title={t("shipments.action.both")}>
                <SwapVert className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("LOAD")) {
            return (
              <Tooltip
                key={`${index}-load`}
                title={t("shipments.action.loading")}
              >
                <ArrowUpward className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("UNLOAD")) {
            return (
              <Tooltip
                key={`${index}-unload`}
                title={t("shipments.action.unloading")}
              >
                <ArrowDownward className={classes.journeyPointActionDownIcon} />
              </Tooltip>
            );
          }
          return (
            <Tooltip key={`${index}-none`} title={t("shipments.action.none")}>
              <Info className={classes.journeyPointActionDownIcon} />
            </Tooltip>
          );
        };

        return (
          <Grid
            key={`${index}-grid`}
            container
            item
            spacing={1}
            alignItems="center"
          >
            <Grid item>{actionIcon(waypointAction)}</Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {formatJourneyPointDateTime(
                  waypoint.arriveAtFrom,
                  waypoint.arriveAtTo
                )}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  const ShipmentIncomingOrders = ({ shipment }) => (
    <Typography variant="body2">
      {shipment.incomingOrders
        ? shipment.incomingOrders.map(({ id, indexNumber }) => (
          <Link
            component={RouterLink}
            to={`/incoming-order/${id}`}
            target="_blank"
            rel="noopener"
          >
            {` #${indexNumber}`}
            <HrefInNewTabIcon />
          </Link>
        ))
        : "-"}
    </Typography>
  );

  const ShipmentsRoute = ({ shipment }) => {
    return (
      <Grid key={shipment.id} container>
        {shipment.journey.waypoints.map((waypoint, index) => {
          const { components, formatted } = waypoint.place.address;
          const result = Array.isArray(components)
            ? getAdvancedFormattedPlaceName(components)
            : formatted;
          return (
            <Grid key={index} item xs={12}>
              <Typography variant="body2">{result}</Typography>
            </Grid>
          );
        })}
      </Grid>
    )
  };

  const ShipmentState = ({ shipment, state }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const updateState = async (state) => {
      try {
        const res = await updateShipmentState(shipment.id, state);
        await reload();
      } catch (err) {
        console.log(err);
      }
    };

    const statusSource = state.map(e => ({ title: e.name, value: e.id }))
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {statusSource.map((state) => {
            if (state.value === shipment.stateId) {
              return state.title;
            }
            return "";
          })}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {statusSource.map((state) => (
            <MenuItem
              key={state.value}
              onClick={() => {
                updateState(state.value);
                handleClose();
              }}
            >
              {state.title}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    carriers: filter.carriers ? filter.carriers.map(({ id }) => id) : undefined,
    customers: filter.customers
      ? filter.customers.map(({ id }) => id)
      : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined,
    state: filter.state ? filter.state.map(({ id }) => id) : undefined
  });

  const ShipmentsInvoices = ({ shipment }) => (
    <Grid key={shipment.id} container>
      <Grid
        key="issuedInvoiceNumber"
        item
        xs={10}
        className={classes.inlineContent}
      >
        <Tooltip title={t("shipments.issuedInvoice")}>
          <CallMade className={classes.success} style={{ marginRight: 5 }} />
        </Tooltip>
        <Typography
          variant="body2"
          className={
            isDue(shipment.issuedInvoiceDueDate) &&
              !shipment.issuedInvoicePaidAt
              ? classes.error
              : null
          }
        >
          {shipment.issuedInvoiceNumber
            ? `#${shipment.issuedInvoiceNumber}`
            : "-"}
        </Typography>
      </Grid>
      <Grid key="issuedInvoicePaidAt" item xs={2}>
        <PayIconButton
          name="issuedInvoicePayButton"
          isPaid={shipment.issuedInvoicePaidAt !== undefined}
          paidAt={shipment.issuedInvoicePaidAt}
          dueDate={shipment.issuedInvoiceDueDate}
          onPay={() =>
            paymentAvailable.current &&
            handlePayShipment(shipment.id, {
              ...shipment,
              issuedInvoicePaidAt: null,
            })
          }
        />
      </Grid>
      {shipment.type === ShipmentType.FORWARDED ? (
        <>
          <Grid
            key="receivedInvoiceNumber"
            item
            xs={10}
            className={classes.inlineContent}
          >
            <Tooltip title={t("shipments.receivedInvoice")}>
              <CallReceived
                className={classes.error}
                style={{ marginRight: 5 }}
              />
            </Tooltip>
            <Typography
              variant="body2"
              className={
                isDue(shipment.receivedInvoiceDueDate) &&
                  !shipment.receivedInvoicePaidAt
                  ? classes.error
                  : null
              }
            >
              {shipment.receivedInvoiceNumber
                ? `#${shipment.receivedInvoiceNumber}`
                : "-"}
            </Typography>
          </Grid>
          <Grid key="receivedInvoicePaidAt" item xs={2}>
            <PayIconButton
              name="receivedInvoicePayButton"
              isPaid={shipment.receivedInvoicePaidAt !== undefined}
              paidAt={shipment.receivedInvoicePaidAt}
              dueDate={shipment.receivedInvoiceDueDate}
              onPay={() =>
                paymentAvailable.current &&
                handlePayShipment(shipment.id, {
                  ...shipment,
                  receivedInvoicePaidAt: null,
                })
              }
            />
          </Grid>
        </>
      ) : undefined}
    </Grid>
  );

  const filterShipments = (shipments) => {
    let outcomingShipments = [];
    let incomingShipments = [];
    let wpIndex;
    let warehouseWaypoint;

    shipments.map(shipment => {
      warehouseWaypoint = shipment.journey.waypoints.find((wp, index) => {
        wpIndex = index;
        return wp.place.id === selectedWarehouse.place.id;
      })
      if (wpIndex > 0 && warehouseWaypoint !== undefined) {
        incomingShipments.push(shipment)
      }
      if (wpIndex === 0 && warehouseWaypoint !== undefined) {
        outcomingShipments.push(shipment)
      }
    })

    return { outcomingShipments, incomingShipments };
  }

  const reloadShipments = useCallback(
    async (offset, limit, ordering) => {
      const filter = transformFilter(additionalFilter);
      try {
        const state = await createStateSuggestQuery({ resources: ["shipment"] });
        let shipmentsResponse;
        switch (mode) {
          case WarehouseView.INCOMING_SHIPMENTS: {
            shipmentsResponse =
              await apiClient.shipment.postShipmentMatchQuery({
                query: {
                  match: {
                    search,
                    places: [selectedWarehouse.place.id],
                    placeAction: 'UNLOAD',
                    ...filter,
                  },
                  orderBy: ordering,
                  offset,
                  limit,
                },
              });
          }
            break;
          case WarehouseView.OUTCOMING_SHIPMENTS: {
            shipmentsResponse =
              await apiClient.shipment.postShipmentMatchQuery({
                query: {
                  match: {
                    search,
                    places: [selectedWarehouse.place.id],
                    placeAction: 'LOAD',
                    ...filter,
                  },
                  orderBy: ordering,
                  offset,
                  limit,
                },
              });
          }
        }

        if (shipmentsResponse.total === 0 && offset !== 0) {
          handleChangePage(undefined, 0);
        }

        const updatedShipments = shipmentsResponse.matches.map((shipment) => {
          shipment.isDisabled = shipment.isDisabled === 1;
          shipment.selected = false;

          shipment.tableCells = [
            {
              render: !shipment.isDraft ? (
                <Typography variant="body2">
                  {" "}
                  #{shipment.indexNumber}
                </Typography>
              ) : (
                <Typography variant="body2">
                  <i>{t("shipments.draft")}</i>
                </Typography>
              ),
            },
            {
              render: (
                <Typography variant="body2">
                  {shipment.vehicle ? (
                    <Link
                      component={RouterLink}
                      to={`/vehicles/${shipment.vehicle.id}`}
                      target="_blank"
                      rel="noopener"
                    >
                      {shipment.vehicle.manufacturer} {shipment.vehicle.model},
                      SPZ: {shipment.vehicle.licensePlate}
                      <HrefInNewTabIcon />
                    </Link>
                  ) : null}
                </Typography>
              ),
            },
            {
              render: shipment.driver ? (
                <Link
                  component={RouterLink}
                  to={`/users/${shipment.driver.id}`}
                  target="_blank"
                  rel="noopener"
                >
                  {shipment.driver.name}
                  <HrefInNewTabIcon />
                </Link>
              ) : (
                <></>
              ),
            },
            {
              render: <ShipmentsDateTime shipment={shipment} />,
            },
            {
              render: <ShipmentsRoute shipment={shipment} />,
            },
            {
              render: <ShipmentIncomingOrders shipment={shipment} />,
            },
            {
              render: !!shipment.stateId && <ShipmentState shipment={shipment} state={state} />,
            },
            {
              render: <UserAvatar size="large" user={{
                ...shipment.createdBy,
                name: `${shipment.createdBy.firstName} ${shipment.createdBy.lastName}`
              }} />
            }
          ];

          return shipment;
        });
        const shipmentData = updatedShipments.reduce(
          (acc, curr) => [
            ...acc,
            {
              type: curr.type,
              id: curr.id,
              row: curr.tableCells,
              selected: false,
              detail: ({ setDetailed }) => (
                <ShipmentOverview
                  id={curr.id}
                  type="SHIPMENT"
                  shipment={curr}
                  shipments={updatedShipments}
                  setShipments={() => {
                  }}
                  onSave={() => {
                  }}
                  open
                  setOpen={() => setDetailed(null)}
                />
              ),
            },
          ],
          []
        );

        setShipments(updatedShipments);
        return { data: shipmentData, totalCnt: shipmentsResponse.total };
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("shipments.error.get"), {
          variant: "error",
        });
      }
    },
    [search, additionalFilter, selectedWarehouse]
  )


  const handleSelectLoadingsDateRange = (loadingDate) =>
    setAdditionalFilter({ ...additionalFilter, loadingDate });
  const handleSelectUnloadingsDateRange = (unloadingDate) =>
    setAdditionalFilter({ ...additionalFilter, unloadingDate });
  const handleSelectCreatedAtDateRange = (createdAt) =>
    setAdditionalFilter({ ...additionalFilter, createdAt });
  const handleSelectShipmentState = (state) =>
    setAdditionalFilter({ ...additionalFilter, state });
  const handleSelectCustomer = (customers) =>
    setAdditionalFilter({ ...additionalFilter, customers });
  const handleSelectCarrier = (carriers) =>
    setAdditionalFilter({ ...additionalFilter, carriers });
  const handleSelectVehicles = (vehicles) =>
    setAdditionalFilter({ ...additionalFilter, vehicles });
  const handleSelectCustomerPrice = (customerPrice) =>
    setAdditionalFilter({ ...additionalFilter, customerPrice });
  const handleSelectCarrierPrice = (carrierPrice) =>
    setAdditionalFilter({ ...additionalFilter, carrierPrice });
  const handleSelectCommission = (commission) =>
    setAdditionalFilter({ ...additionalFilter, commission });
  const handleSelectCreators = (creators) =>
    setAdditionalFilter({ ...additionalFilter, creators });
  const handleSelectDrivers = (drivers) =>
    setAdditionalFilter({ ...additionalFilter, drivers });
  const handleSelectCargoTemplate = (cargo) =>
    setAdditionalFilter({ ...additionalFilter, cargo });
  const handleSelectIsDraft = (isDraft) =>
    setAdditionalFilter({ ...additionalFilter, isDraft });
  const handleSelectIssuedPaymentState = (issuedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, issuedPaymentState });
  const handleSelectReceivedPaymentState = (receivedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, receivedPaymentState });

  const handleSelectLastMonth = () => {
    const now = new Date();

    setAdditionalFilter({
      lastWaypointDateFrom: startOfMonth(now),
      lastWaypointDateTo: endOfMonth(now),
    });
  };

  const loadAvailableUsers = async (search, permissions) => {
    try {
      const matches = await suggestUsers({ permissions, search });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableContacts = async (type, search) => {
    try {
      const contacts = await apiClient.contact.postContactSuggestQuery({
        query: {
          search,
          types: ["BOTH", type],
        },
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search,
        },
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableStates = async (search) => {
    try {
      const states = await createStateSuggestQuery({ resources: ["shipment"] });
      return states;
    } catch (error) {
      enqueueSnackbar(t("settings.error.suggest-state"), {
        variant: "error",
      });
      return undefined;
    }
  };

  const loadInitVehicle = async (vehicleId) => {
    try {
      const vehicle = await apiClient.vehicle.getVehicle({
        vehicleId,
      });

      setAdditionalFilter({
        ...additionalFilter,
        vehicles: [vehicle],
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadInitContact = async (contactId) => {
    try {
      const contact = await apiClient.contact.getContact({
        contactId,
      });

      if (contact.type === ContactType.CUSTOMER) {
        setAdditionalFilter({
          ...additionalFilter,
          customers: [contact],
        });
      } else {
        setAdditionalFilter({
          ...additionalFilter,
          carriers: [contact],
        });
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const handleSendOrder = async (
    shipmentId,
    { email, message, language, isSendCopyToMeChecked }
  ) => {
    try {
      await apiClient.email.postSendOrderByEmail({
        shipmentId,
        data: {
          email,
          message,
          language,
          hasSendCopyToMe: isSendCopyToMeChecked,
        },
      });
      enqueueSnackbar(t("webapp:shipment.send-order.success.title"), {
        variant: "success",
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:shipment.send-order.error.submit"), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const { filterVehicleId, filterContactId } =
      queryString.parse(routerSearch);

    if (filterVehicleId) {
      loadInitVehicle(filterVehicleId);
    }
    if (filterContactId) {
      loadInitContact(filterContactId);
    }
  }, []);

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const banking = await createCompanyBankAccountMatchQuery({
          limit: 10,
          offset: 0,
        });
        setBankAccounts(banking);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBankAccounts();
  }, []);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reload,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData,
  } = useTable(reloadShipments, "shipments");

  const generateShipmentInvoice = async (idShipment) => {
    await createShipmentInvoice(idShipment);
    reload();
  };

  useEffect(() => {
    setPageLoading(loading);
  }, [loading]);

  useEffect(() => {
    setPageShipments(shipments);
  }, [shipments]);

  useEffect(() => {
    const selected = loading
      ? null
      : data.filter(({ selected }) => selected).length;

    setPageSelected(selected);
  }, [loading, data]);

  const handlePayShipment = async (
    id,
    {
      issuedInvoiceNumber,
      issuedInvoiceDueDate,
      issuedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      receivedInvoicePaidAt,
      bankAccountId,
      taxableSupplyDate,
      invoiceNote,
      issueDate,
    }
  ) => {
    try {
      paymentAvailable.current = false;
      await apiClient.shipment.postShipmentPayment({
        shipmentId: id,
        payment: {
          issuedInvoiceNumber,
          issuedInvoiceDueDate,
          issuedInvoicePaidAt,
          receivedInvoiceDueDate,
          receivedInvoiceNumber,
          receivedInvoicePaidAt,
          bankAccountId,
          taxableSupplyDate,
          invoiceNote,
          issueDate,
        },
      });

      setShipments((cur) =>
        cur.map((shipment) =>
          shipment.id === id
            ? {
              ...shipment,
              issuedInvoiceNumber,
              issuedInvoiceDueDate,
              issuedInvoicePaidAt,
              receivedInvoiceDueDate,
              receivedInvoiceNumber,
              receivedInvoicePaidAt,
              bankAccountId,
              taxableSupplyDate,
              invoiceNote,
              issueDate,
            }
            : shipment
        )
      );

      setData((current) =>
        current.map((shipment) => {
          if (shipment.id === id) {
            const row = [...shipment.row];

            row[5] = {
              render: (
                <ShipmentsInvoices
                  shipment={{
                    id,
                    type: shipment.type,
                    issuedInvoiceNumber,
                    issuedInvoiceDueDate:
                      typeof issuedInvoiceDueDate === "object"
                        ? issuedInvoiceDueDate.toISOString()
                        : issuedInvoiceDueDate,
                    issuedInvoicePaidAt:
                      typeof issuedInvoicePaidAt === "object"
                        ? issuedInvoicePaidAt?.toISOString()
                        : issuedInvoicePaidAt,
                    receivedInvoiceDueDate:
                      typeof receivedInvoiceDueDate === "object"
                        ? receivedInvoiceDueDate.toISOString()
                        : receivedInvoiceDueDate,
                    receivedInvoiceNumber,
                    receivedInvoicePaidAt:
                      typeof receivedInvoicePaidAt === "object"
                        ? receivedInvoicePaidAt?.toISOString()
                        : receivedInvoicePaidAt,
                    bankAccountId,
                    taxableSupplyDate:
                      typeof taxableSupplyDate === "object"
                        ? taxableSupplyDate?.toISOString()
                        : taxableSupplyDate,
                    invoiceNote,
                    issueDate,
                  }}
                />
              ),
            };

            return {
              ...shipment,
              row,
            };
          }

          return shipment;
        })
      );
      // await reload();
    } catch (err) {
      console.log(err);

      enqueueSnackbar(t("shipments.error.paidAt"), {
        variant: "error",
      });
    } finally {
      paymentAvailable.current = true;
    }
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  useEffect(() => {
    const loadedFilters = loadFilters("shipments");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const selectedShipments = data.filter(({ selected }) => selected);

  const handleDeleteSubmit = () => {
    setDeleteDialogOpen(false);

    const ids = selectedShipments.map(({ id }) => id);
    const requests = ids.map(deleteShipment);

    return Promise.all(requests)
      .then(() => {
        reloadDataFromScratch();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("orders.error.delete"), {
          variant: "error",
        });
      });
  };

  const handleShipmentsExportRequest = () => {
    setIsShipmentsExportDialogOpen(true);
  };

  const closeShipmentsExportDialog = () => {
    setIsShipmentsExportDialogOpen(false);

    if (isShipmentsExportDone) {
      setIsShipmentsExportDone(false);
      setShipmentsExportLink(null);
    }
  };

  const exportShipments = async (language) => {
    setIsShipmentsExportProcessing(true);

    try {
      const link = await createShipmentsExport(
        selectedShipments.map(({ id }) => id),
        language
      );

      setShipmentsExportLink(link);
      setIsShipmentsExportDone(true);
    } catch (error) {
      console.log(error);

      const type = error.response.data.error;

      if (type === "ShipmentExportLimitExceededError") {
        enqueueSnackbar(t("orders.export.errorLimit"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("orders.export.error"), {
          variant: "error",
        });
      }
    } finally {
      setIsShipmentsExportProcessing(false);
    }
  };

  useEffect(() => {
    addUrlParam("filter", additionalFilter);
  }, [additionalFilter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250);
  }, [search]);

  const { hasPermission, user } = useAuth();
  const canUpdateShipment =
    hasPermission("resource.shipment.company.update") ||
    hasPermission("resource.shipment.user.update");
  const canCreateInvoice = hasPermission("resource.invoice.create");
  const canCreateShipment = hasPermission("resource.shipment.user.create");
  const canDeleteShipment =
    hasPermission("resource.shipment.user.delete") ||
    hasPermission("resource.shipment.company.delete");
  const canReadInvoice = hasPermission("resource.invoice.read");
  const canReadCompanyShipment = hasPermission(
    "resource.shipment.company.read"
  );

  return (
    <>
      <WarehouseShipments
        selectedWarehouse={selectedWarehouse}
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        shipments={shipments}
        setShipments={setShipments}
        loading={loading}
        search={search}
        ordering={ordering}
        direction={direction}
        rowsPerPage={rowsPerPage}
        page={page}
        checkedAll={checkedAll}
        exportShipments={exportShipments}
        canUpdateShipment={canUpdateShipment}
        canCreateInvoice={canCreateInvoice}
        canCreateShipment={canCreateShipment}
        canDeleteShipment={canDeleteShipment}
        canReadInvoice={canReadInvoice}
        canReadCompanyShipment={canReadCompanyShipment}
        isShipmentsExportDialogOpen={isShipmentsExportDialogOpen}
        isShipmentsExportProcessing={isShipmentsExportProcessing}
        isShipmentsExportDone={isShipmentsExportDone}
        shipmentsExportLink={shipmentsExportLink}
        additionalFilter={additionalFilter}
        closeShipmentsExportDialog={closeShipmentsExportDialog}
        handleSort={handleSort}
        handleChangePage={handleChangePage}
        handleShipmentsExportRequest={handleShipmentsExportRequest}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleSelectAll={handleSelectAll}
        handleSelect={handleSelect}
        handleDeleteRequest={() => setDeleteDialogOpen(true)}
        handleGenerateShipmentInvoice={generateShipmentInvoice}
        handlePayShipment={handlePayShipment}
        handleSelectLastMonth={handleSelectLastMonth}
        filter={additionalFilter}
        availableBankAccounts={bankAccounts}
        handleSelectShipmentState={handleSelectShipmentState}
        handleDeselect={handleDeselect}
        loadAvailableUsers={loadAvailableUsers}
        defaultFilters={defaultFilters}
        handleSelectCustomer={handleSelectCustomer}
        handleSelectCarrier={handleSelectCarrier}
        handleSelectCustomerPrice={handleSelectCustomerPrice}
        handleSelectCarrierPrice={handleSelectCarrierPrice}
        handleSelectCommission={handleSelectCommission}
        loadAvailableContacts={loadAvailableContacts}
        handleSelectLoadingsDateRange={handleSelectLoadingsDateRange}
        handleSelectUnloadingsDateRange={handleSelectUnloadingsDateRange}
        handleSelectCreatedAtDateRange={handleSelectCreatedAtDateRange}
        handleSelectCreators={handleSelectCreators}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        handleSelectDrivers={handleSelectDrivers}
        handleSelectCargoTemplate={handleSelectCargoTemplate}
        handleSelectIsDraft={handleSelectIsDraft}
        handleSelectVehicles={handleSelectVehicles}
        loadAvailableVehicles={loadAvailableVehicles}
        handleSelectIssuedPaymentState={handleSelectIssuedPaymentState}
        handleSelectReceivedPaymentState={handleSelectReceivedPaymentState}
        handleSendOrder={handleSendOrder}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        loadAvailableStates={loadAvailableStates}
      />
      <ShipmentsDeleteDialog
        open={deleteDialogOpen}
        selected={selectedShipments.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default WarehouseShipmentsContainer;
