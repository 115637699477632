import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > td:last-child": {
      paddingLeft: spacing(2)
    }
  }
}));

const TextTableRow = ({ label, value }) => {
  const classes = useStyles();
  const valueNode = value === "string"
    ? <Typography>{value}</Typography>
    : value;

  return (
    <tr className={classes.root}>
      <td>
        <Typography>
          {label}
        </Typography>
      </td>
      <td>
        {valueNode}
      </td>
    </tr>
  );
};

export default TextTableRow;
