import { Currency } from "@cargotic/currency";

import { ContactSnapshot, Employee } from "./contact";
import { Journey } from "./journey";

enum ShipmentType {
  FORWARDED = "FORWARDED",
  CARRIED = "CARRIED"
}

enum ShipmentState {
  QUEUE = "QUEUE",
  READY = "READY",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE"
}

interface TermsTranslation {
  snapshotId: number;
  content: string;
}

interface Terms {
  termsId: number;
  isCompany: boolean;
  cs?: TermsTranslation,
  en?: TermsTranslation
}

interface Shipment {
  indexNumber?: string;
  journey: Journey;
  carrierContact: ContactSnapshot;
  customerContact: ContactSnapshot;
  customerEmployee?: Employee;
  carrierEmployee?: Employee;
  customerPrice: number;
  customerPriceCurrency: Currency;
  carrierPrice: number;
  carrierPriceCurrency: Currency;
  commission: number;
  commissionCurrency: Currency;
  isDraft: boolean;
  type: ShipmentType;
  paymentDueDays: number;
  notes?: string;
  terms?: Terms;
  state: ShipmentState;
}

interface ShipmentSnapshot extends Shipment {
  id: number;
}

export {
  Shipment,
  ShipmentSnapshot,
  ShipmentType,
  ShipmentState
};
