import React, { useState } from "react";

import { Paper, makeStyles } from "@material-ui/core";

import ContactProfileDetailActions from "./ContactProfileDetailActions";
import ContactProfileDetailContent from "./ContactProfileDetailContent";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    position: "sticky",
    top: 64,

    width: 340,
    height: "calc(100vh - 124px)",

    overflow: "auto"
  }
}));

const ContactProfileDetail = ({
  contact,
  onAvatarChange,
  onDeleteButtonClick,
  onEditButtonClick,
  onShipmentButtonClick,
  onTagEditClick
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandButtonClick = () => setIsExpanded(!isExpanded);

  return (
    <Paper className={classes.root}>
      <ContactProfileDetailContent
        contact={contact}
        isExpanded={isExpanded}
        onAvatarChange={onAvatarChange}
        onDeleteButtonClick={onDeleteButtonClick}
        onEditButtonClick={onEditButtonClick}
        onShipmentButtonClick={onShipmentButtonClick}
        onTagEditClick={onTagEditClick}
      />
      <ContactProfileDetailActions
        isExpanded={isExpanded}
        onExpandButtonClick={handleExpandButtonClick}
      />
    </Paper>
  );
};

export default ContactProfileDetail;
