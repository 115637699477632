import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {DialogContent, InputAdornment, makeStyles, TextField} from "@material-ui/core";

import {Search as SearchIcon} from "@material-ui/icons";

import {useApiClient} from "../../index";
import UserSelectEditorSelectList from "./UserSelectEditorSelectList";
import {suggestUsers} from "../../../cargotic-webapp/resource";

const useStyles = makeStyles(({ spacing }) => ({
  input: {
    flex: "none",
    marginBottom: spacing(1)
  },
  list: {
    padding: 0
  }
}));

const UserSelectEditorSelect = ({ value, onChange, permissions }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const client = useApiClient();

  const [search, setSearch] = useState("");
  const [availableUsers, setAvailableUsers] = useState();
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  const handleDeselect = () => {
    if (onChange) {
      onChange(undefined);
    }
  };

  const handleSearchChange = ({ target: { value: newSearch } }) => (
    setSearch(newSearch)
  );

  const handleSelect = id => {
    if (onChange) {
      const driver = availableUsers.find(({ id: other }) => other === id);
      onChange(driver);
    }
  };

  const loadUsers = async () => {
    setIsUsersLoading(true);

    try {
      const matches = await suggestUsers({  search });
      const selected = matches.map(({ id, ...rest }) => ({
        ...rest,
        id,
        isSelected: value?.id === id
      }));

      setAvailableUsers(selected);
      setIsUsersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [search]);

  useEffect(() => {
    if (!availableUsers) {
      return;
    }

    const selected = availableUsers.map(({ id, ...rest }) => ({
      ...rest,
      id,
      isSelected: value?.id === id
    }));

    setAvailableUsers(selected);
  }, [value]);

  return (
    <>
      <DialogContent className={classes.input}>
        <TextField
          label={permissions.includes("resource.user.role.permission.driver") ? t("webapp:common.title.search-driver") : t("webapp:common.title.search-warehouseman")}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          fullWidth
          onChange={handleSearchChange}
        />
      </DialogContent>
      <DialogContent className={classes.list} dividers>
        <UserSelectEditorSelectList
          users={availableUsers}
          isLoading={isUsersLoading}
          onDeselect={handleDeselect}
          onSelect={handleSelect}
        />
      </DialogContent>
    </>
  );
};

export default UserSelectEditorSelect;
