const loadScript = source => new Promise((resolve) => {
  const element = document.createElement("script");

  element.setAttribute("src", source);
  element.addEventListener("load", () => resolve());

  document.body.appendChild(element);
});

export {
  loadScript
};
