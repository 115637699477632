
const TagColors = {
  RED: "#E76F51",
  ORANGE: "#ffbe0b",
  BLUE: "#4361ee",
  GREEN: "#2A9D8F",
  BROWN: "#E9C46A",
  AQUA: "#4cc9f0",
  PURPLE: "#8A2BE2",
  YELLOW: "#FFFF00",
  GREY: "#808080",
  PINK: "#FF69B4"
};

export default TagColors;