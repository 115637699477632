import React from "react";

import { TextField } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";

const FormikDateTimePicker = ({
  form,
  name,
  format = "d.MM.yyyy HH:mm",
  fullWidth,
  InputProps,
  placeholder,
  required,
  value: v,
  onChange,
  ...props
}) => {
  const value = v || form.values[name];
  const error = form.errors[name];
  const isTouched = form.touched[name];

  const handleChange = newValue => (
    form.setValues({ ...form.values, [name]: newValue })
  );

  return (
    <DateTimePicker
      {...props}
      inputFormat={format}
      value={value}
      clearable
      autoOk
      disableMaskedInput
      allowSameDateSelection
      onError={console.error}
      allowKeyboardControl
      renderInput={(props) => (
        <TextField
          required={required}
          name={name}
          {...props}
          InputProps={{
            ...props.InputProps,
            ...InputProps
          }}
          value={props.inputProps.value}
          error={isTouched && error !== undefined}
          onBlur={form.handleBlur}
          helperText={isTouched && error !== undefined ? error : " "}
          inputProps={{
            ...props.inputProps,
            placeholder: placeholder ?? "1.1.2020"
          }}
          fullWidth={fullWidth}
          variant="standard"
        />)
      }
      onChange={onChange || handleChange}
      onAccept={onChange || handleChange}
    />
  );
};

export default FormikDateTimePicker;
