import React from "react";
import { Slide, Card, CardHeader, Collapse, CardContent, Tooltip, Divider, Typography, makeStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { teal } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

import BoardCardWaypointIcon from "../ShipmentBoard/component/BoardCardWaypointIcon";
import TrackingCardInfo from "./TrackingCardInfo";

import { UserAvatar } from "../../../../cargotic-webapp-component/component";
import { getDrivenThroughText, getActionTypeOfLoading, getFormattedPlaceName } from "../../../utility/waypoint";
import { formatDateTime } from "../../../utility/common";

const useStyles = makeStyles(() => ({
  trackingCard: {
    position: "relative",
    transition: "width 300ms",
    alignItems: "center",
    marginLeft: 10,
    marginTop: 10,
    cursor: "pointer"
  },
  cardPlace: {
    fontFamily: "Barlow",
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "1rem"
  },
  cardTime: {
    fontFamily: "Barlow",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "1rem"
  },
  waypointRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "space-between",
    alignItems: "center",
    alignContent: "center"
  },
  placeRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    margin: 8
  },
  placeRowInfo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 5
  },
  placesSection: {
    display: "flex",
    marginRight: 10,
    flexDirection: "column"
  },
  trackingInfoSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  driveThroughCheck: {
    marginRight: 5,
    color: teal[700],
    width: 20,
    height: 20
  }
}));


const TrackingCard = ({
  hoveredTrackId,
  setHoveredTrackId,
  onTrackingPathChange,
  setExpandingCard,
  expandingCard,
  tracking: { id, driver, vehicle, journey, tracking }
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card
      className={classes.trackingCard}
      onClick={() => {
        onTrackingPathChange(hoveredTrackId === id ? -1 : id);
        setHoveredTrackId(hoveredTrackId === id ? -1 : id);
        setExpandingCard(hoveredTrackId === id ? -1 : id);
      }}
      style={{ width: id === expandingCard ? "100%" : "85%" }}
      elevation={hoveredTrackId === id ? 8 : 1} >
      <CardHeader
        avatar={<UserAvatar user={driver} size="small" />}
        title={driver.name}
        subheader={`${vehicle.manufacturer} ${vehicle.manufacturer}, ${vehicle.licensePlate}`}
      />
      <Collapse
        in={hoveredTrackId === id}
        timeout="auto">
        <CardContent>
          <span>
            <span className={classes.placesSection}>
              {journey.waypoints.map((waypoint, index) => {
                const action = getActionTypeOfLoading(waypoint);
                const { components, formatted } = waypoint.address || {};
                const formattedAddress = getFormattedPlaceName(components);
                return (
                  <span key={`tracking-${id}-w-${index}`} className={classes.waypointRow} >
                    <span className={classes.placeRow} >
                      <BoardCardWaypointIcon t={t} action={action} />
                      <span className={classes.placeRowInfo} >
                        <span className={classes.cardPlace}>
                          {formattedAddress || formatted}
                        </span>
                        <span className={classes.cardTime}>
                          {formatDateTime(new Date(waypoint.arriveAtFrom))}
                        </span>
                      </span>
                    </span >
                    {waypoint.isDrivenThrough
                      ? (
                        <Tooltip title={getDrivenThroughText(t, action)}>
                          <Check className={classes.driveThroughCheck} />
                        </Tooltip>
                      )
                      : null}
                  </span>
                )
              }
              )}
              <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              <div className={classes.trackingInfoSection} >
                <TrackingCardInfo title={t("tracking.currentPosition")} value={`${tracking.latitude}N ${tracking.longitude}E`} />
                <TrackingCardInfo title={t("tracking.speed")} value={Math.round(tracking.speed)} />
                <TrackingCardInfo title={t("tracking.lastTrack")} value={formatDateTime(new Date(tracking.trackedAt))} />
              </div>
            </span>
          </span>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default TrackingCard;