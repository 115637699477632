import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  Typography,
  MenuList,
  Grow,
  ListItemText,
  Popper,
  ClickAwayListener,
  ListItemSecondaryAction,
  ListItem,
  FormControl,
  Divider,
  Select,
  IconButton,
  InputLabel,
  Button,
  Input,
  MenuItem,
  Checkbox,
  Paper
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";

import { areArraysEqual } from "../../../cargotic-webapp/utility/functional";

const useStyles = makeStyles(({ spacing }) => ({
  text: {
    padding: "4px 6px"
  },
  icon: {
    width: "0.8em",
    height: "0.8em"
  },
  endIcon: {
    marginLeft: 0
  },
  buttonRoot: {
    textTransform: "none",
    fontWeight: 600
  },
  buttonRootSelected: {
    background: "#D6D6D6",
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#D6D6D6"
    }
  },
  title: {
    paddingRight: spacing(3)
  }
}));

const FilterStaticDropdown = ({
  source,
  placeholderTitle,
  onChange,
  value,
  selectAll,
  onClear,
  disabled
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectAllChosen, setSelectAllChosen] = useState(undefined);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = (item, checked) => {
    if (checked) {
      if (!value.includes(item)) {
        onChange([...value, item]);
      }
    } else {
      onChange(value.filter(c => c !== item));
    }
  };

  const handleClearAndClose = () => {
    onClear();
    handleToggle();
  };

  const sourceValues = source.map(item => item.value);
  const isSelectedAll = areArraysEqual(value, sourceValues);

  return (
    <div>
      <Button
        ref={anchorRef}
        classes={{ root: value.length > 0 ? classes.buttonRootSelected : classes.buttonRoot, endIcon: classes.endIcon, text: classes.text }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple
        disabled={disabled}
        endIcon={
          <>
            <IconButton onClick={() => null} size="small"><ArrowDropDownIcon className={classes.icon} /></IconButton>
            {value.length > 0 ?
              (<IconButton onClick={handleClearAndClose} size="small">
                <CloseIcon className={classes.icon} />
              </IconButton>) : null}
          </>
        }
      >
        {placeholderTitle} {value.length > 0 ? `(${value.length})` : null}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex: 99999}}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <ListItem button key="selectAll" onClick={selectAll}>
                    <ListItemText primary={t("selectAll")} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        color="primary"
                        checked={selectAllChosen || isSelectedAll}
                        edge="end"
                        onChange={({ target: { checked } }) => {
                          if (checked) {
                            selectAll();
                            setSelectAllChosen(true);
                          } else if (checked === false) {
                            onClear();
                            setSelectAllChosen(false);
                          }
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  {source.map(({ value: itemValue, title }) =>
                    (
                      <ListItem button key={itemValue} onClick={() => handleClick(itemValue, !value.includes(itemValue))}>
                        <ListItemText primary={title} className={classes.title} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            color="primary"
                            checked={value.includes(itemValue)}
                            edge="end"
                            onChange={({ target: { checked } }) =>
                              handleClick(itemValue, checked)
                            }
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default FilterStaticDropdown;
