import clsx from "clsx";
import React from "react";

import { ButtonBase, Fab, makeStyles } from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";

import FileDropzone from "../FileDropzone";

const useStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    position: "absolute",
    top: -10,
    right: -10,
    zIndex: 10,

    width: 36,
    height: 36,

    boxShadow: "none"
  },
  dropzone: {
    borderRadius: 20
  },
  image: {
    position: "relative"
  },
  preview: {
    width: "100%",
    height: "100%",

    borderRadius: 20
  }
}));

const ImageFileDropzone = ({
  className,
  children,
  value,
  isPreviewClickable,
  onPreviewClick,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  const handleClearButtonClick = () => {
    if (onChange) {
      onChange(undefined);
    }
  };

  const handlePreviewClick = event => {
    if (onPreviewClick) {
      onPreviewClick(event);
    }
  };

  if (value) {
    return (
      <div className={clsx(classes.image, className)}>
        <Fab
          className={classes.button}
          color="primary"
          size="small"
          onClick={handleClearButtonClick}
        >
          <ClearIcon color="inherit" fontSize="small" />
        </Fab>
        <ButtonBase
          style={{
            background: `url("${value}") center center / cover no-repeat`
          }}
          className={classes.preview}
          disabled={!isPreviewClickable}
          onClick={handlePreviewClick}
        />
      </div>
    );
  }

  return (
    <FileDropzone
      className={clsx(classes.dropzone, className)}
      onChange={onChange}
      {...props}
    >
      {children}
    </FileDropzone>
  );
};

export default ImageFileDropzone;
