import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, makeStyles } from "@material-ui/core";

import { CargoItemAction } from "../../../cargotic-core/model";
import PaneContent from "../PaneContent";
import CargoEditorCargoItemList from "./CargoEditorCargoItemList";

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    textAlign: "center",

    height: "100%"
  },
  section: {
    paddingBottom: spacing(1)
  }
}));

const CargoEditorContent = ({
  cargo,
  selectedCargoItemId,
  isCargoItemLoadingDisabled,
  onCargoItemChange,
  onCargoItemCopy,
  onCargoItemDelete,
  onCargoItemReload,
  onCargoItemSelect,
  onCargoItemUnload
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getContent = () => {
    if (!cargo || cargo.length === 0) {
      return (
        <div className={classes.content}>
          <Typography color="textSecondary">
            {t("webapp:cargo.editor.empty")}
          </Typography>
        </div>
      );
    }

    return Object
      .keys(CargoItemAction)
      .map(
        action => [
          action,
          cargo.filter(({ action: other }) => other === action)
        ]
      )
      .filter(([_, cargo]) => cargo.length !== 0)
      .map(([action, cargo]) => (
        <section key={action} className={classes.section} data-cy={"cargo-" + action}>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {t(`webapp:cargo.item.action.${action}`)}
          </Typography>
          <CargoEditorCargoItemList
            cargo={cargo}
            selectedCargoItemId={selectedCargoItemId}
            isCargoItemLoadingDisabled={isCargoItemLoadingDisabled}
            onCargoItemChange={onCargoItemChange}
            onCargoItemCopy={onCargoItemCopy}
            onCargoItemDelete={onCargoItemDelete}
            onCargoItemReload={onCargoItemReload}
            onCargoItemSelect={onCargoItemSelect}
            onCargoItemUnload={onCargoItemUnload}
          />
        </section>
      ));
  };

  return (
    <PaneContent>
      {getContent()}
    </PaneContent>
  );
};

export default CargoEditorContent;
