import React from "react";
import { useSnackbar } from "notistack";
import {
  classes,Button, Grid, CardActions, CardHeader, CardContent, Card, Typography, makeStyles, TextField, List, ListItemText, Link
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContactType } from "@cargotic/model";

import { red } from "@material-ui/core/colors";
import { ContactFormAutosuggestTextField } from "../../../../cargotic-webapp/contact";
import {
  useApiClient as useNewApiClient
} from "../../../../cargotic-webapp-component";
import { createFormValidator, useForm } from "../../../form";
import { pipe, ValueError, formatDate } from "@cargotic/common-deprecated";
import { isObject, required, validateDateString } from "@cargotic/validate";
import useRouter from "../../hook/useRouter";
import { parseISO } from "date-fns";
import { cs, enUS } from "date-fns/locale";


const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  content: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      padding: spacing(1)
    }
  },
  cardActions: {
    [breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center",
      "& > :not(:last-child)": {
        marginBottom: spacing(1)
      }
    },
    [breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  warning: {
    color: red[500]
  },
}));
const UserTokenCard = ({
  hasUpdateUserProfilePermission,
  handleChangeTokenClick,
  token, 
  tokens, 
}) => {
  const newApiClient = useNewApiClient();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { origin } = location;
  const signUpLink = (token) => `${origin}/sign-up?token=${token}`
  const locale = t("locale") === "cs" ? cs : enUS;

  const validateDeleted = (x, message = "") => {
    if (typeof x === "object" && x?.isDeleted) {
      throw new ValueError(message);
    }

    return x;
  };

  const validateContact = pipe(
    (contact) => required(contact, t("webapp:incomingOrder.form.error.contact")),
    (contact) => isObject(contact, t("webapp:incomingOrder.form.error.contact")),
    (contact) => validateDeleted(contact, t("webapp:incomingOrder.form.error.deletedContact"))
  );


  const form = useForm({
    initialValues: {
      customerContact: '',

    },
    validate: createFormValidator({
      customerContact: validateContact,
    }),
    onChange: (incomingOrder, oldShipment) => {

      const { customerContact: newCustomerContact } = incomingOrder;
      const { customerContact: oldCustomerContact} = oldShipment;
      
      if (newCustomerContact !== oldCustomerContact) {
        form.setValue("customerContact", newCustomerContact);
      }
    },
  });

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={t("settings.senderEnvironment")} />
        <CardContent className={classes.content}>
  
        <Grid container>
          <Grid item xs={12} sm={6}>      
            <ContactFormAutosuggestTextField
              form={form}
              contactType={ContactType.CUSTOMER}
              apiClient={newApiClient}
              name="customerContact"
              label={t("settings.emailSender")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="inherit">Token: <b><Link href={signUpLink(token)}>{token}</Link></b></Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" ><b>{t("settings.senderList")}</b></Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }} >
                {t("firstName")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }} >
                {t("lastName")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }} >
                {t("companyName")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }} >
                {t("email")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }} >
                {t("settings.lastActivity")}
              </Typography>
            </Grid>
          </Grid>
          {tokens.map((token,index) =>(<Grid container>
            <Grid item xs>
              <Typography variant={token.isActive ? "overline" : "caption"} >{token.firstName}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant={token.isActive ? "overline" : "caption"}>{token.lastName}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant={token.isActive ? "overline" : "caption"} >{token.companyName}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant={token.isActive ? "overline" : "caption"} >{token.email}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant={token.isActive ? "overline" : "caption"} >{token.lastSeen ? formatDate(parseISO(token.lastSeen), "dd.MM.yyyy", locale) : ''}</Typography>
            </Grid>
          </Grid>))}
        </Grid>
          
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            disabled={!hasUpdateUserProfilePermission}
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={() => handleChangeTokenClick(form.values.customerContact.email)}
          >
            {t("settings.generateToken")}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default UserTokenCard;
