const SettingsView = {
  COMPANY_AND_USER: "COMPANY_AND_USER",
  TARIFF: "TARIFF",
  EXCHANGE_RATES: "EXCHANGE_RATES",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  ROLES: "ROLES",
  ROLE_EDITOR: "ROLE_EDITOR",
  WAREHOUSES: "WAREHOUSES",
  STATES: "STATES"
};

export default SettingsView;