import { LegacyPlace } from "./place";

enum ContactType {
  CARRIER = "CARRIER",
  CUSTOMER = "CUSTOMER",
  BOTH = "BOTH"
}

interface Employee {
  id?: number;
  name?: string;
  email?: string;
  phoneNumber?: string;
  isDeleted?: boolean
}

interface Contact {
  type: ContactType;
  companyName: string;
  creator: String;
  creatorId: number;
  dic: string;
  employees?: Employee[];
  email?: string;
  phoneNumber?: string;
  insurance?: Date;
  dueDays?: number;
  website?: string;
  ic?: string;
  notes?: string;
  billingAddress?: LegacyPlace;
  deliveryAddress?: LegacyPlace;
  tags: ContactTag[];
  assignedCount?: number;
  billingContact?: string;
  electronicalBilling: boolean;
  isPrivate: boolean;
  isBilledOnline: boolean;
}

interface ContactTag {
  id: number;
  type: string;
  geopoliticalType?: string;
  value: string;
  localizedType?: string;
  localizedGeopoliticalType?: string;
}

interface ContactSnapshot extends Contact {
  id: number;
  createdAt: Date;
  isAssigned: boolean;
  isDeleted?: boolean;
}

export {
  Employee,
  Contact,
  ContactSnapshot,
  ContactType,
  ContactTag
};
