import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, makeStyles } from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon
} from "@material-ui/icons";

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.common.white,
    backgroundColor: palette.primary.light
  },
  expired: {
    color: palette.common.white,
    backgroundColor: palette.warning.light
  },
  icon: {
    color: palette.common.white
  }
}));

const VehicleExpirationTableItemStatusChip = ({ status }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (status === "active") {
    return (
      <Chip
        className={classes.active}
        label={t("webapp:vehicle.title.expiration-active")}
        icon={(
          <CheckCircleIcon className={classes.icon} />
        )}
      />
    )
  }

  return (
    <Chip
      className={classes.expired}
      label={t("webapp:vehicle.title.expiration-expired")}
      icon={(
        <ErrorIcon className={classes.icon} />
      )}
    />
  )
};

export default VehicleExpirationTableItemStatusChip;
