import React, {useMemo, useState} from "react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Popover,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {ChevronRight, KeyboardArrowDown, KeyboardArrowUp, MoreVert,} from "@material-ui/icons";

import {useTranslation} from "react-i18next";

const DEFAULT_COLSPAN = 12;

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    borderRadius: 15,
  },
  cardCell: {
    margin: spacing(2),
  },
  opacityUp: {
    opacity: 1,
    transition: "opacity .25s ease-in-out",
  },
  opacityDown: {
    opacity: 0,
    transition: "opacity .25s ease-in-out",
  },
}));

const DrawCheckbox = ({
  tableMode,
  selected,
  index,
  selectOnlyOne,
  onSelect,
  disabled,
}) => {
  if (!tableMode) {
    return null;
  }
  return (
    <TableCell padding="checkbox">
      <Checkbox
        color="primary"
        checked={selected}
        disabled={disabled}
        onChange={({ target: { checked: selected } }) =>
          onSelect(index, selected, selectOnlyOne)
        }
      />
    </TableCell>
  );
};

const DrawAction = ({
  id,
  handleClick,
  icon,
  title,
  usePopover,
  PopoverContent,
  disabled,
  showActions,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorElTable, setAnchorElTable] = useState(null);
  const handleClosePopover = () => setAnchorElTable(null);
  const handleClickPopover = (event) => setAnchorElTable(event.currentTarget);

  const IconButtonProps = usePopover
    ? {
        onClick: handleClickPopover,
        disabled,
      }
    : {
        onClick: () => handleClick(id),
        disabled,
      };

  return (
    <Grid
      item
      className={showActions ? classes.opacityUp : classes.opacityDown}
    >
      <Tooltip title={t(title)}>
        <span>
          <IconButton {...IconButtonProps}>{icon}</IconButton>
        </span>
      </Tooltip>
      {usePopover ? (
        <Popover
          open={Boolean(anchorElTable)}
          anchorEl={anchorElTable}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <PopoverContent id={id} />
        </Popover>
      ) : null}
    </Grid>
  );
};

const DrawActions = ({
  actions,
  id,
  enableDetails,
  enableDetail,
  enableMultipleDetails,
  detailed,
  setDetailed,
  enableMenu,
  menuItems,
  showActions,
    rowCollapse,
    setRowCollapse
}) => {
  const { t } = useTranslation();

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleCloseMenu = () => setAnchorElMenu(null);
  const handleClickMenu = (event) => setAnchorElMenu(event.currentTarget);

  return (
    <Grid
      key={`${id}-grid`}
      container
      direction="row"
      alignItems="center"
      wrap="nowrap"
      justify="center"
    >
      {actions.map(({ column }, index) => (
        <Grid key={`${index}-grid-row`} container item direction="column">
          {column.map(
            (
              {
                handleClick,
                icon,
                title,
                usePopover,
                PopoverContent,
                disableFor,
              },
              index
            ) => (
              <DrawAction
                key={`${index}-draw-action`}
                id={id}
                handleClick={handleClick}
                icon={icon}
                title={title}
                usePopover={usePopover}
                PopoverContent={PopoverContent}
                disabled={
                  (disableFor != undefined && disableFor.includes(-1)) ||
                  (disableFor != undefined && disableFor.includes(id))
                }
                showActions={showActions}
              />
            )
          )}
        </Grid>
      ))}
      {enableDetails ? (
        <Grid item>
          <Typography variant="body2">
            <IconButton
              onClick={() => {
                if (enableMultipleDetails) {
                  setRowCollapse(!rowCollapse)
                } else {
                  setDetailed(detailed === id ? -1 : id)
                }
              }}
              data-cy="show-details"
            >
              {enableMultipleDetails ?
                  (
                      <>
                        {rowCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </>
                  ) :
                  (
                      <>
                        {detailed === id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </>
                  )
              }
            </IconButton>
          </Typography>
        </Grid>
      ) : null}
      {enableMenu ? (
        <Grid item>
          <Typography variant="body2">
            <IconButton
              onClick={(e) =>
                menuItems
                  ? handleClickMenu(e)
                  : setDetailed(id === detailed ? -1 : id)
              }
            >
              <MoreVert />
            </IconButton>
            {menuItems && (
              <Menu
                id="table-row-menu"
                anchorEl={anchorElMenu}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={handleCloseMenu}
              >
                {menuItems.map(({ handleClick, title, disableFor }, index) => (
                  <MenuItem
                    key={`${index}-menu-item`}
                    onClick={() => handleClick(id)}
                    disabled={
                      (disableFor != undefined && disableFor.includes(-1)) ||
                      (disableFor != undefined && disableFor.includes(id))
                    }
                  >
                    {t(title)}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Typography>
        </Grid>
      ) : null}
      {enableDetail ? (
        <Grid item>
          <Typography variant="body2">
            <IconButton
              onClick={(e) =>
                menuItems
                  ? handleClickMenu(e)
                  : setDetailed(id === detailed ? -1 : id)
              }
            >
              <ChevronRight />
            </IconButton>
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

const DataTableRow = ({
  disabled,
  tableMode,
  index,
  id,
  selected,
  rowActionsDisable,
  details,
  detail,
  enableDetails,
  enableDetail,
  enableMultipleDetails,
  detailed,
  setDetailed,
  enableMenu,
  menuItems,
  cells,
  actions,
  selectOnlyOne,
  onSelect,
}) => {
  const classes = useStyles();

  let actionsDefault = false;
  if (!tableMode) {
    actionsDefault = true;
  }
  const [showActions, setShowActions] = useState(actionsDefault);


  const TrArrea = tableMode ? TableRow : Card;
  const TrArreaProps = tableMode
    ? {
        onFocus: () => setShowActions(true),
        onMouseOver: () => setShowActions(true),
        onMouseLeave: () => setShowActions(false),
      }
    : {
        className: classes.card,
        style: { backgroundColor: selected ? "#45CFBB" : "white" },
      };
  const TrContent = tableMode ? React.Fragment : CardContent;
  const TrContentProps = tableMode
    ? {}
    : {
        onClick: () => onSelect(index, !selected, selectOnlyOne),
      };
  const TrCell = tableMode ? TableCell : "div";
  const TrCellProps = tableMode
    ? {}
    : {
        className: classes.cardCell,
      };
  const TrActions = tableMode ? TableCell : CardActions;

  const colSpan = useMemo(() => {
    return (
      cells?.length +
      [actions?.length > 0, typeof onSelect === "function"].filter(Boolean)
        .length
    );
  }, [cells, actions, onSelect]);

  const [rowCollapse, setRowCollapse] = useState(false);

  return (
    <React.Fragment>
      <TrArrea {...TrArreaProps}>
        <TrContent {...TrContentProps}>
          <DrawCheckbox
            tableMode={tableMode}
            selected={selected}
            row
            index={index}
            disabled={disabled}
            selectOnlyOne={selectOnlyOne}
            onSelect={onSelect}
          />
          {cells.map(({ render }, index) => (
            <TrCell key={index} {...TrCellProps}>
              {render}
            </TrCell>
          ))}
        </TrContent>
        {actions !== undefined ? (
          <TrActions>
            <DrawActions
              actions={actions}
              rowActionsDisable={rowActionsDisable}
              id={id}
              enableDetails={enableDetails}
              enableDetail={enableDetail}
              enableMultipleDetails={enableMultipleDetails}
              detailed={detailed}
              setDetailed={setDetailed}
              enableMenu={enableMenu}
              menuItems={menuItems}
              showActions={showActions}
              rowCollapse={rowCollapse}
              setRowCollapse={setRowCollapse}
            />
          </TrActions>
        ) : null}


        {enableMultipleDetails ?
            (
                <>
                  {rowCollapse && !tableMode ? (
                      <Collapse in={rowCollapse && !tableMode}>
                        {details || null}
                      </Collapse>
                  ) : null}
                </>
            ) :
            (
                <>
                  {detailed === id && !tableMode ? (
                      <Collapse in={detailed === id && !tableMode}>
                        {details || null}
                      </Collapse>
                  ) : null}
                </>
            )
        }

      </TrArrea>
      <TableRow>

        <TableCell colSpan={colSpan + 1} padding="none">
          {enableMultipleDetails ?
              (
                  <>
                    {rowCollapse && tableMode && (
                        <Collapse in>{details || detail({ setDetailed })}</Collapse>
                    )}
                  </>
              ) :
              (
                  <>
                    {detailed === id && tableMode && (
                        <Collapse in>{details || detail({ setDetailed })}</Collapse>
                    )}
                  </>
              )
          }
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default DataTableRow;
