import React from "react";

import {
  Button,
  Typography,
  makeStyles
} from "@material-ui/core";

import StatisticCardSelect from "./StatisticCardSelect";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const StatisticCardHeader = ({
  title,
  period,
  periods,
  range,
  onRangeChange,
  onPeriodChange,
  adornment
}) => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <section>
        <Typography variant="h6">
          <b>
            {title}
          </b>
        </Typography>
        <StatisticCardSelect
          value={period}
          items={periods}
          range={range}
          onRangeChange={onRangeChange}
          onChange={onPeriodChange}
        />
      </section>
      {
        adornment
          ? (
            <section>
              {adornment}
            </section>
          ) : null
      }
    </div>
  );
};

export default StatisticCardHeader;
