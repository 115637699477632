import React from "react";

import {
  ListItem,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Tooltip,
  IconButton,
  makeStyles
} from "@material-ui/core";

import { Info } from "@material-ui/icons";

import FilterDynamicSuggestionDropdown from "../FilterDynamicSuggestionDropdown";
import { getNameInitials } from "../../../cargotic-webapp/utility/common";


const useStyles = makeStyles(({ spacing }) => ({
  secondaryAction: {
    paddingRight: spacing(10)
  }
}));

const ContactFilterDropdown = ({
  placeholderTitle,
  onChange,
  value,
  selectAll,
  search,
  suggest,
  getTitle,
  onClear,
  disabled
}) => {
  const classes = useStyles();
  return (
    <FilterDynamicSuggestionDropdown
      placeholderTitle={placeholderTitle}
      onChange={onChange}
      value={value}
      selectAll={selectAll}
      disabled={disabled}
      renderItem={({ id, name, ico, dic, email, phoneNumber }, checked, handleChange) => (
        <ListItem button key={id} onClick={() => handleChange({ target: { checked: !checked } })} classes={{ secondaryAction: classes.secondaryAction}}>
          <ListItemText primary={name} />
          <ListItemSecondaryAction>
            <Tooltip title={`IC: ${ico ?? '-'}, DIC: ${dic ?? '-'}, Email: ${email ?? '-'}, Mobil: ${phoneNumber ?? '-'}`}>
              <IconButton size="small">
                <Info style={{ width: 15, height: 15 }} />
              </IconButton>
            </Tooltip>
            <Checkbox
              color="primary"
              checked={checked}
              edge="end"
              onChange={handleChange}
            />
          </ListItemSecondaryAction>
        </ListItem>)}
      search={search}
      getTitle={getTitle}
      onClear={onClear}
    />
  );
      }

export default ContactFilterDropdown;
