import React from "react";

import { ApiClient } from "../../client";
import ApiClientContext from "../ApiClientContext";

interface ApiClientProviderProps {
  client: ApiClient;
  children: React.ReactNode;
}

function ApiClientProvider(
  { client, children }: ApiClientProviderProps
): React.ReactElement {
  return (
    <ApiClientContext.Provider value={{ client }}>
      {children}
    </ApiClientContext.Provider>
  );
}

export default ApiClientProvider;
