import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ContactsDeleteDialog = ({
  open,
  selected,
  handleClose,
  handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("contacts.deleteTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("contacts.confirmDeletion", { selected })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("contact.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactsDeleteDialog;
