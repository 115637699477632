import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";

import { LengthUnit } from "../../../cargotic-core/model";

const LengthUnitSelect = ({ label, labelId, variant, fullWidth, ...other }) => {
  return (
    <FormControl variant={variant} fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select label={label} {...other}>
        {
          Object
            .values(LengthUnit)
            .map((value) => (
              <MenuItem key={value} value={value}>
                {value.toLowerCase()}
              </MenuItem>
            ))
        }
      </Select>
    </FormControl>
  );
};

export default LengthUnitSelect;
