import React from "react";

import { amber, green } from "@material-ui/core/colors";
import { IconButton, SnackbarContent, makeStyles } from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon, Close as CloseIcon, Error as ErrorIcon, Info as InfoIcon, Warning as WarningIcon
} from "@material-ui/icons";
import clsx from "clsx";

const icons = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    marginBottom: spacing(1)
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: palette.error.dark
  },
  info: {
    backgroundColor: palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  messageIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: spacing(1)
  }
}));

const PersistentSnackbar = ({
  link, message, variant, handleClose
}) => {
  const classes = useStyles();
  const Icon = icons[variant];

  return (
    <SnackbarContent
      className={clsx(classes.container, classes[variant])}
      message={(
        <span className={classes.message}>
          <Icon className={classes.messageIcon} />
          {message}
        </span>
      )}
      action={[
        link,
        <IconButton key="closePersistentSnackbar" color="inherit" onClick={handleClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
    />
  );
};

export default PersistentSnackbar;
