import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

const useStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    marginLeft: 18,
    marginTop: -8,
    color: palette.primary.main,

    "& svg": {
      width: 56,
      height: 56
    }
  },
  hidden: {
    visibility: "hidden"
  }
}));

const WaypointBrowserButton = ({ onClick, isHidden }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip title={t("webapp:waypoint.browser.button.add")}>
      <IconButton
        className={clsx(classes.button, { [classes.hidden]: isHidden })}
        size="small"
        disableFocusRipple
        disableTouchRipple
        onClick={onClick}
      >
        <AddCircle />
      </IconButton>
    </Tooltip>
  );
};

export default WaypointBrowserButton;
