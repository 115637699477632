import React, { useEffect, useState } from "react";

import { deserializeContact } from "@cargotic/api-client";

import IncomingOrderMultiselectSelectionContext from "../../context/IncomingOrderMultiselectSelectionContext";

const key = "incomingOrderMultiselectSelection";

const IncomingOrderMultiselectSelectionProvider = ({ children }) => {
  const [incomingOrderSelection, setIncomingOrderSelection] = useState(() => {
    const value = localStorage.getItem(key);
    if (value) {
      const parsedValue = JSON.parse(value);

      if (!parsedValue) {
        return [];
      }

      return parsedValue;
    }
    return [];
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(incomingOrderSelection));
  }, [incomingOrderSelection]);

  return (
    <IncomingOrderMultiselectSelectionContext.Provider value={{
      incomingOrderSelection,
      setIncomingOrderSelection
    }}
    >
      {children}
    </IncomingOrderMultiselectSelectionContext.Provider>
  );
};

export default IncomingOrderMultiselectSelectionProvider;
