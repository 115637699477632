import React from "react";
import { useTranslation } from "react-i18next";

import { ContactActivityType } from "@cargotic/model";
import { Typography } from "@material-ui/core";

import {
  ActivityTimeline,
  ActivityType
} from "../../../cargotic-webapp-component";

import { localizeMonth } from "../../../cargotic-webapp-utility";

const ContactActivityTimeline = ({ activity, ...props }) => {
  const { t, i18n: { language } } = useTranslation();

  return (
    <ActivityTimeline
      activity={activity.map((item) => {
        const { type } = item;

        if (type === ContactActivityType.CONTACT_COMMENTARY) {
          const { commentary } = item;

          return {
            type: ActivityType.COMMENTARY,
            commentary
          };
        }

        if (type === ContactActivityType.CONTACT_CREATE) {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:contact.activity.create")}
              </Typography>
            ),
            createdAt
          };
        }

        if (
          type === ContactActivityType.CONTACT_EMPLOYEE_CREATE
          || type === ContactActivityType.CONTACT_EMPLOYEE_DELETE
          || type === ContactActivityType.CONTACT_EMPLOYEE_UPDATE
        ) {
          const {
            user,
            createdAt
          } = item;

          const key = type === ContactActivityType.CONTACT_EMPLOYEE_CREATE
            ? "webapp:contact.activity.employee-create"
            : type === ContactActivityType.CONTACT_EMPLOYEE_DELETE
              ? "webapp:contact.activity.employee-delete"
              : "webapp:contact.activity.employee-update";

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {
                  t(key)
                }
              </Typography>
            ),
            createdAt
          };
        }

        if (type === ContactActivityType.CONTACT_UPDATE) {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:contact.activity.update")}
              </Typography>
            ),
            createdAt
          };
        }

        throw new Error(`Unknown contact activity type '${type}'!`);
      })}
      {...props}
    />
  );
};

export default ContactActivityTimeline;
