import React, { useEffect } from "react";

import {
  Button,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { useFormik } from "formik2";

import * as Yup from "yup";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import useAuth from "../../hook/useAuth";
import { getIn } from 'formik'

import {
  DrawerDialog,
  FormikCheckbox,
  FormikSelect,
  FormikTextField,
  useApiClient
} from "../../../../cargotic-webapp-component";

import SettingsStateView from "./SettingsStateView";
import TagColors from "../../../utility/TagColors";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
  root: {
    "p + p": {
      marginTop: "15px"
    }
  }
}));


const SettingsStateEditor = ({
  initialValue = {},
  user,
  availableRoles,
  isOpen,
  onSubmit,
  onClose
}) => {


  const { t } = useTranslation();
  const classes = useStyles();
  const { user: { role, id: userId }, hasPermission } = useAuth();

  const hasUpdateStatePermission = hasPermission("resource.state.crud");

  const { stateId: id } = initialValue;
  const initialStatusName = initialValue.name || "";
  const initialResources = initialValue.resources || "";
  const initialIsDefault = initialValue.isDefault;
  const initialAutomationRule = initialValue.automationRule;
  const initialTag = initialValue.tag;

  const StateFormSchema = Yup.object({
    statusName: Yup.string()
      .max(128, t("webapp:common.validate.status-name"))
      .required(t("webapp:common.validate.status-name")),
    resources: Yup.string()
      .max(128, t("webapp:common.validate.resources")),
    automationRule: Yup.string().nullable(),
    isDefault: Yup.boolean(t("webapp:common.validate.status-isDefault")),
    tag: Yup.string().nullable()
  });


  const stateForm = useFormik({
    initialValues: {
      statusName: initialStatusName,
      resources: initialResources,
      isDefault: initialIsDefault,
      automationRule: initialAutomationRule,
      tag: initialTag
    },
    validationSchema: StateFormSchema,
    onSubmit: values => {
      const statusName = values.statusName || undefined;
      const resources = values.resources || undefined;
      const isDefault = values.isDefault || false;
      const automationRule = values.automationRule || undefined;
      const tag = values.tag || undefined;

      const state = {
        id,
        statusName,
        resources,
        isDefault,
        automationRule,
        tag
      };

      if (onSubmit) {
        onSubmit(state);
      }
    }
  });

  useEffect(() => {
    stateForm.resetForm({
      values: {
        statusName: initialStatusName,
        resources: initialResources,
        isDefault: initialIsDefault,
        automationRule: initialAutomationRule,
        tag: initialTag
      },
      errors: {}
    });
  }, [isOpen]);

  const createStatesMenuItems = () => Object.keys(SettingsStateView).map(name => (
    <MenuItem value={name.toLowerCase()} key={name}>
      {t(`settings.customSequencing.sectionLabels.${name}`)}
    </MenuItem>
  ));

  const createAutomationMenuItems = () => {
    const resource = getIn(stateForm.values, "resources");
    if (resource === "incoming_order") {
      return [
        <MenuItem value="AFTER_ASSIGNING_TO_SHIPMENT" key="AFTER_ASSIGNING_TO_SHIPMENT">
          {t("webapp:settings.label.assigned-to-shipment")}
        </MenuItem>,
        <MenuItem value="AFTER_INCOMING_ORDER_DELIVERY" key="AFTER_INCOMING_ORDER_DELIVERY">
          {t("webapp:settings.label.incoming-order-delivery")}
        </MenuItem>,
        <MenuItem value="AFTER_INCOMING_ORDER_ATTEMPTED_DELIVERY" key="AFTER_INCOMING_ORDER_ATTEMPTED_DELIVERY">
          {t("webapp:settings.label.incoming-order-attempted-delivery")}
        </MenuItem>,
        <MenuItem value="AFTER_FIRST_DRIVEN_THROUGH" key="AFTER_FIRST_DRIVEN_THROUGH">
          {t("webapp:settings.label.incoming-order-first-waypoint-driven-through")}
        </MenuItem>,
        <MenuItem value="AFTER_ATTACHMENT_ASSIGNED" key="AFTER_ATTACHMENT_ASSIGNED">
          {t("webapp:settings.label.incoming-order-attachment-assigned")}
        </MenuItem>,
        <MenuItem value={undefined} key={undefined}>-</MenuItem>
      ];
    }
    if (resource === "shipment") {
      return [
        <MenuItem value="AFTER_ALL_DRIVEN_THROUGH" key="AFTER_ALL_DRIVEN_THROUGH">
          {t("webapp:settings.label.all-driven-through")}
        </MenuItem>,
        <MenuItem value="AFTER_ANY_DRIVEN_THROUGH" key="AFTER_ANY_DRIVEN_THROUGH">
          {t("webapp:settings.label.any-driven-through")}
        </MenuItem>,
        <MenuItem value="AFTER_FIRST_DRIVEN_THROUGH" key="AFTER_FIRST_DRIVEN_THROUGH">
          {t("webapp:settings.label.first-driven-through")}
        </MenuItem>,
        <MenuItem value={undefined} key={undefined}>-</MenuItem>
      ];
    }
    return [
      <MenuItem value={undefined} key={undefined}>-</MenuItem>
    ];
  };

  const createTagMenuItems = () => {
    return [
      <MenuItem value={undefined} key={undefined}>-</MenuItem>,
      ...Object.entries(TagColors).map(([k, v]) => (
        <MenuItem value={k} key={k} style={{ backgroundColor: v, height: "3em" }}>{t(`webapp:settings.label.colors.${k.toLowerCase()}`)}</MenuItem>
      ))
    ];
  }

  const handleSave = () => {
    try {
      stateForm.submitForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DrawerDialog
      title={t("webapp:settings.title.states")}
      actions={(
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose}>
            {t("abort")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t("save")}
          </Button>
        </div>
      )}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        <div>
          <FormikTextField
            form={stateForm}
            required
            name="statusName"
            label={t("webapp:common.title.statusName")}
            fullWidth
          />
        </div>
        <div>
          <FormikSelect
            required
            disabled={userId === id || !hasUpdateStatePermission}
            name="resources"
            fullWidth
            form={stateForm}
            label={t("webapp:common.title.resources")}
          >
            {createStatesMenuItems()}
          </FormikSelect>
        </div>
        <div>
          <FormikSelect
            disabled={userId === id || !hasUpdateStatePermission}
            name="automationRule"
            fullWidth
            form={stateForm}
            label={t("webapp:common.title.automation")}
          >
            {createAutomationMenuItems()}
          </FormikSelect>
        </div>
        <div>
          <FormikSelect
            disabled={userId === id || !hasUpdateStatePermission}
            name="tag"
            fullWidth
            form={stateForm}
            label={t("webapp:common.title.tag")}
          >
            {createTagMenuItems()}
          </FormikSelect>
        </div>
        <div>
          <FormikCheckbox
            name="isDefault"
            fullWidth
            form={stateForm}
            label={t("webapp:common.title.primary")}
          />
        </div>
      </div>
    </DrawerDialog >
  );
};

export default SettingsStateEditor;
