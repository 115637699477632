
import React, { useState, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Button,
  Checkbox,
  Select,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import { EmojiObjectsOutlined } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Page from "../../common/Page";
import Placeholder from "../../common/Placeholder";
import StatisticsCard from "./StatisticsCard";
import StatisticsLineChart from "./StatisticsLineChart";
import StatisticsSummary from "./StatisticsSummary";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import VehiclesFilterDropdown from "../../../../cargotic-webapp-filter/component/VehiclesFilterDropdown";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import PlaceFilterDropdown from "../../../../cargotic-webapp-filter/component/PlaceFilterDropdown";

import { ShipmentType, ContactType } from "@cargotic/model"

const Statistics = ({
  filter,

  loadingPersonalStatistics,
  loadingCompanyStatistics,
  loadingShipmentsStatistics,
  loadingRevenueStatistics,
  loadingCommissionStatistics,
  loadingReceivedInvoiceStatistics,
  loadingIssuedInvoiceStatistics,
  loadingIncomingOrdersStatistics,
  loadingOutcomingOrdersStatistics,
  loadingOutcomingOrderSumStatistics,
  loadingIssuedInvoiceSumStatistics,
  loadingReceivedInvoiceSumStatistics,

  personalStatistics,
  companyStatistics,
  shipmentsStatistics,
  revenueStatistics,
  commissionStatistics,
  issuedInvoiceStatistics,
  receivedInvoiceStatistics,
  incomingOrdersStatistics,
  outcomingOrdersStatistics,
  outcomingOrderSumStatistics,
  receivedInvoiceSumStatistics,
  issuedInvoiceSumStatistics,

  fetchPersonalStatistics,
  fetchCompanyStatistics,
  fetchShipmentsStatistics,
  fetchRevenueStatistics,
  fetchCommissionStatistics,
  fetchReceivedInvoiceStatistics,
  fetchIssuedInvoiceStatistics,
  fetchIncomingOrdersStatistics,
  fetchOutcomingOrdersStatistics,
  fetchOutcomingOrderSumStatistics,
  fetchIssuedInvoiceSumStatistics,
  fetchReceivedInvoiceSumStatistics,

  classes,

  hasPermissionUserRead,
  hasPermissionCompanyRead,
  hasPermissionIncomingOrderRead,
  hasPermissionInvoiceRead,
  hasPermissionOutcomingOrderRead,

  handleLoadAvailableContacts,
  handleLoadAvailableVehicles,
  handleLoadAvailableUsers,
  handleLoadAvailablePlaces,

  handleSelectCustomer,
  handleSelectCarrier,
  handleSelectVehicles,
  handleSelectDrivers,
  handleSelectPlaces,
  handleSelectCreator,

  handleDeselectFilters,
  handleFilterSettingsOpen,

  defaultFilters
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("xs"));

  const isAnyFilterActive = useCallback(() => Object.values(filter).some((filterValue) => !!filterValue), [filter]);

  return (
    <Page
      title={t("menu.statistics")}
    >
      <FilterContainer
        loading={false}
        searchField={<></>}
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        onClear={handleDeselectFilters}
        defaultFilters={defaultFilters}
        filters={[
          <ContactFilterDropdown
            id="customers"
            key="customers"
            onChange={(value) => handleSelectCustomer(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectCustomer(all)}
            value={filter.customer ?? []}
            search={(text) => handleLoadAvailableContacts([ContactType.CARRIER, ContactType.BOTH, ContactType.CUSTOMER], text)}
            placeholderTitle={t("shipments.customer")}
            onClear={() => handleSelectCustomer(undefined)}
            getTitle={(item) => item.name}
          />,
          <ContactFilterDropdown
            id="carriers"
            key="carriers"
            onChange={(value) => handleSelectCarrier(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectCarrier(all)}
            value={filter.carrier ?? []}
            search={(text) => handleLoadAvailableContacts([ContactType.CARRIER, ContactType.BOTH, ContactType.CUSTOMER], text)}
            placeholderTitle={t("shipments.carrier")}
            onClear={() => handleSelectCarrier(undefined)}
            getTitle={(item) => item.name}
          />,
          <UserFilterDropdown
            id="drivers"
            key="drivers"
            onChange={(value) => handleSelectDrivers(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectDrivers(all)}
            value={filter.drivers ?? []}
            placeholderTitle={t("shipments.driver")}
            search={(text) => handleLoadAvailableUsers(text, ["resource.user.role.permission.driver"])}
            onClear={() => handleSelectDrivers(undefined)}
            getTitle={(item) => item.name}
          />,
          <VehiclesFilterDropdown
            id="vehicles"
            key="vehicles"
            onChange={(value) => handleSelectVehicles(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectVehicles(all)}
            value={filter.vehicles ?? []}
            placeholderTitle={t("shipments.vehicles")}
            search={(text) => handleLoadAvailableVehicles(text)}
            onClear={() => handleSelectVehicles(undefined)}
          />,
          <PlaceFilterDropdown
            id="places"
            key="places"
            placeholderTitle={t("shipments.places")}
            selectAll={(all) => handleSelectPlaces(all)}
            search={(text) => handleLoadAvailablePlaces(text)}
            value={filter.places ?? []}
            onChange={(value) => handleSelectPlaces(value.length === 0 ? undefined : value)}
            onClear={() => handleSelectPlaces(undefined)}
          />,
          <UserFilterDropdown
            id="creator"
            key="creator"
            onChange={(value) => handleSelectCreator(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectCreator(all)}
            value={filter.creator ?? []}
            placeholderTitle={t("shipments.creator")}
            search={(text) => handleLoadAvailableUsers(text)}
            onClear={() => handleSelectCreator(undefined)}
            getTitle={(item) => item.name}
          />
        ]}
      />
      <Grid container spacing={2}>
        {hasPermissionUserRead || hasPermissionCompanyRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.shipmentsTitle")}
              fetchMethod={fetchShipmentsStatistics}
              tooltip={t("statistics.tooltip.filter-driver-vehicle-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingShipmentsStatistics}
                render={() => (
                  <StatisticsLineChart name="orders" data={shipmentsStatistics} axisYFormat={(v) => `${v % 1 !== 0 ? "" : v}`} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {hasPermissionCompanyRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.kpi")}
              fetchMethod={fetchCompanyStatistics}
              tooltip={t("statistics.tooltip.filter-customer-carrier-vehicle-driver-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingCompanyStatistics}
                render={() => (
                  <StatisticsSummary data={companyStatistics} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {hasPermissionUserRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.personal")}
              fetchMethod={fetchPersonalStatistics}
              tooltip={t("statistics.tooltip.filter-customer-carrier-vehicle-driver-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingPersonalStatistics}
                render={() => (
                  <StatisticsSummary data={personalStatistics} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.commission")}
              fetchMethod={fetchCommissionStatistics}
              tooltip={t("statistics.tootlip.filter-customer-carrier-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingCommissionStatistics}
                render={() => (
                  <StatisticsLineChart name="commission" data={commissionStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionIncomingOrderRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.revenue")}
              fetchMethod={fetchRevenueStatistics}
              tooltip={t("statistics.tooltip.filter-customer-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingRevenueStatistics}
                render={() => (
                  <StatisticsLineChart name="revenue" data={revenueStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionIncomingOrderRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.incomingOrders")}
              fetchMethod={fetchIncomingOrdersStatistics}
              tooltip={t("statistics.tooltip.filter-customer-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingIncomingOrdersStatistics}
                render={() => (
                  <StatisticsLineChart name="incomingOrders" data={incomingOrdersStatistics} axisYFormat={(v) => `${v % 1 !== 0 ? "" : v}`} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionOutcomingOrderRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.outcomingOrders")}
              fetchMethod={fetchOutcomingOrdersStatistics}
              tooltip={t("statistics.tooltip.filter-carrier-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingOutcomingOrdersStatistics}
                render={() => (
                  <StatisticsLineChart name="outcomingOrders" data={outcomingOrdersStatistics} axisYFormat={(v) => `${v % 1 !== 0 ? "" : v}`} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionOutcomingOrderRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.outcomingOrdersSum")}
              fetchMethod={fetchOutcomingOrderSumStatistics}
              tooltip={t("statistics.tooltip.filter-carrier-place")}
              periodPicker
            >
              <Placeholder
                loading={loadingOutcomingOrderSumStatistics}
                render={() => (
                  <StatisticsLineChart name="outcomingOrdersSum" data={outcomingOrderSumStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionInvoiceRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.incomingInvoices")}
              fetchMethod={fetchReceivedInvoiceStatistics}
              tooltip={t("statistics.tooltip.filter-carrier")}
              periodPicker
            >
              <Placeholder
                loading={loadingReceivedInvoiceStatistics}
                render={() => (
                  <StatisticsLineChart name="incomingInvoices" data={receivedInvoiceStatistics} axisYFormat={(v) => `${v % 1 !== 0 ? "" : v}`} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionInvoiceRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.outcomingInvoices")}
              fetchMethod={fetchIssuedInvoiceStatistics}
              tooltip={t("statistics.tooltip.filter-customer")}
              periodPicker
            >
              <Placeholder
                loading={loadingIssuedInvoiceStatistics}
                render={() => (
                  <StatisticsLineChart name="issuedInvoices" data={issuedInvoiceStatistics} axisYFormat={(v) => `${v % 1 !== 0 ? "" : v}`} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionInvoiceRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.incomingInvoicesSum")}
              fetchMethod={fetchReceivedInvoiceSumStatistics}
              tooltip={t("statistics.tooltip.filter-customer")}
              periodPicker
            >
              <Placeholder
                loading={loadingReceivedInvoiceSumStatistics}
                render={() => (
                  <StatisticsLineChart name="incomingInvoicesSum" data={receivedInvoiceSumStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {(hasPermissionUserRead || hasPermissionCompanyRead) && hasPermissionInvoiceRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.outcomingInvoicesSum")}
              fetchMethod={fetchIssuedInvoiceSumStatistics}
              tooltip={t("statistics.tooltip.filter-customer")}
              periodPicker
            >
              <Placeholder
                loading={loadingIssuedInvoiceSumStatistics}
                render={() => (
                  <StatisticsLineChart name="outcomingInvoicesSum" data={issuedInvoiceSumStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
      </Grid>
    </Page>
  );
};

export default Statistics;
