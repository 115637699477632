import React from "react";

import { makeStyles, Grid, Card, CardContent, Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TrackBasicInfo from "./TrackBasicInfo";
import TrackStatuses from "./TrackStatuses";
import clsx from "clsx";

const useStyles = makeStyles(({spacing}) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  notInSystem: {
    marginTop: spacing(3)
  },
  content: {
    padding: spacing(2),
    "& > section": {
      display: "flex",
      justifyContent: "space-between"
    }
  }
}));

const TrackDetail = ({
  orderTracking,
  setOrderTracking,
  isLoading
}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {basicInfo, statuses} = orderTracking;

  const UpperTab = ({
  content
}) => {

    if (isLoading) {
      return (
        <div className={clsx(classes.center, classes.content)}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <Grid item xs={12} md={12}>
        <Card style={{width: "100%"}}>
          <CardContent className={classes.content}>
            <Button
              color="primary"
              variant="contained"
              size={'small'}
              style={{ marginBottom: 10 }}
              onClick={() => setOrderTracking(null)}>
              {t('back')}
            </Button>
            {content}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <UpperTab content={
        !basicInfo ?
          <div className={classes.notInSystem}>{t('trackingOrder.states.notInSystem')}</div>
          :
          <TrackBasicInfo basicInfo={basicInfo}/>
      }/>
      {basicInfo.type === "IN_SYSTEM" ? <TrackStatuses basicInfo={basicInfo} statuses={statuses}/> : undefined}
    </React.Fragment>
  );
};

export default TrackDetail;
