import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { flatten, intersection, remove, union } from "lodash";
import { useSnackbar } from "notistack";
import ReceiveIncomingOrdersContainer from "./ReceiveIncomingOrdersContainer";
import { receiveCargoItemPackages } from "../../../resource";

const useStyles = makeStyles(({ palette }) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  dialogPaper: {
    height: "80vh",
    minWidth: "80vw",
  },
  abort: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger,
      color: "white",
    },
  },
}));

export const CargoPackagesContext = React.createContext({});
const ReceiveIncomingOrdersDialog = ({
  open,
  handleClose,
  handleSubmit,
  warehouseId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [cargoItemPackagesToUpdate, setCargoItemPackagesToUpdate] = useState(
    []
  );
  const [incomingOrders, setIncomingOrders] = useState([]);
  const [packageIds, setPackageIds] = useState([]);
  const [areSelectedAll, setAreSelectedAll] = useState(false);

  const handleCargoItemPackagesRequest = async () => {
    let data;
    if (areSelectedAll) {
      const requestData = packageIds.reduce((acc, value, index, array) => {
        const idx = acc.findIndex((item) => item.id === value.incomingOrderId);
        if (idx !== -1) {
          acc[idx] = { id: value.incomingOrderId, cargoItemPackages: [...acc[idx].cargoItemPackages, value.packageId] };
          return acc;
        } else {
          return [...acc, { id: value.incomingOrderId, cargoItemPackages: [value.packageId] }];
        }
      }, []);
      data = requestData;
    } else {
      data = incomingOrders
        .map((incomingOrder) => {
          const incomingOrderCargoItemPackages = flatten(
            incomingOrder.journey.waypoints[0].cargo.map((cargoItem) =>
              cargoItem.packages.map((p) => p.packageId)
            )
          );
          return {
            id: incomingOrder.id,
            cargoItemPackages: intersection(
              incomingOrderCargoItemPackages,
              cargoItemPackagesToUpdate
            ),
          };
        })
        .filter((d) => d.cargoItemPackages.length > 0);
    }

    try {
      await receiveCargoItemPackages({
        incomingOrders: data,
        warehouseId,
      });
      enqueueSnackbar(t("incomingOrders.receiveIncomingOrders.success.post"), {
        variant: "success",
      });
      handleSubmit();
      handleClose();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(t("incomingOrders.receiveIncomingOrders.error.post"), {
        variant: "error",
      });
    }
  };

  const handleSelectCargoItemPackages = (selected, cargoItemPackages) => {
    setCargoItemPackagesToUpdate((state) => {
      if (selected) {
        state = union(state, cargoItemPackages);
      } else {
        remove(state, (n) => cargoItemPackages.includes(n));
      }
      return [...state];
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <>{t("incomingOrders.receiveInWarehouse")}</>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CargoPackagesContext.Provider value={cargoItemPackagesToUpdate}>
          <ReceiveIncomingOrdersContainer
            onIncomingOrdersReload={setIncomingOrders}
            onSelectCargoItemPackages={handleSelectCargoItemPackages}
            handleReloadIncomingOrders={(i) => setIncomingOrders(i)}
            handleSetPackageIds={(i) => setPackageIds(i)}
            handleSetAreSelectedAll={(value) => setAreSelectedAll(value)}
            warehouseId={warehouseId}
          />
        </CargoPackagesContext.Provider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          className={classes.abort}
        >
          {t("incomingOrders.cancel")}
        </Button>
        <Button
          onClick={handleCargoItemPackagesRequest}
          variant="contained"
          color="primary"
        >
          <>{t("incomingOrders.receiveInWarehouse")}</>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReceiveIncomingOrdersDialog;
