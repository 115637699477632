import React, { useEffect } from "react";

import {
  Button,
  MenuItem,
  makeStyles,
  TextField
} from "@material-ui/core";
import { Currency } from "../../../../cargotic-currency/currency";
import { useFormik } from "formik2";

import * as Yup from "yup";
import IBAN from "iban";
import { convertCzFormatToBBAN, formatIBAN, DEFAULT_COUNTRY_CODE, convertBBANFormatToCz } from "../../../utility/banking";

import { useTranslation } from "react-i18next";

import useAuth from "../../hook/useAuth";

import {
  DrawerDialog,
  FormikCheckbox,
  FormikSelect,
  FormikTextField
} from "../../../../cargotic-webapp-component";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
  root: {
    "p + p": {
      marginTop: "15px"
    }
  }
}));


const BankAccountEditor = ({
  initialValue = {},
  user,
  isOpen,
  onSubmit,
  onClose
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user: { role, id: userId } } = useAuth();

  const { id } = initialValue;

  const initialCurrency = initialValue.currency || Currency.CZK;
  const initialIban = initialValue.iban || "";
  const initialBban = initialValue.bban || "";
  const initialIsPrimary = initialValue.isPrimary || "";


  const BankAccountSchema = Yup.object({
    currency: Yup.string()
      .max(10, t("webapp:common.validate.currency"))
      .required(t("webapp:common.validate.currency")),
    iban: Yup.string()
      .test("iban-test", t("webapp:common.validate.iban"),
        (value) => {
          return IBAN.isValid(value)
        })
      .max(128, t("webapp:common.validate.iban"))
      .required(t("webapp:common.validate.iban")),
    bban: Yup.string()
      .test("bban-test", t("webapp:common.validate.bban"),
        (value) => {
         return IBAN.isValidBBAN(DEFAULT_COUNTRY_CODE ,value)
        })
      .max(128, t("webapp:common.validate.bban"))
      .required(t("webapp:common.validate.bban")),
    isPrimary: Yup.boolean()
  });

  const bankAccountForm = useFormik({
    initialValues: {
      currency: initialCurrency,
      iban: initialIban,
      bban: initialBban,
      isPrimary: initialIsPrimary
    },
    validationSchema: BankAccountSchema,
    onSubmit: values => {
      const currency = values.currency || undefined;
      const iban = values.iban || undefined;
      const bban = values.bban || undefined;
      const isPrimary = values.isPrimary || undefined;

      const bankAccount = {
        id,
        currency,
        bban,
        iban,
        isPrimary
      };

      if (onSubmit) {
        onSubmit(bankAccount);
      }
    }
  });

  useEffect(() => {
    bankAccountForm.resetForm({
      values: {
        currency: initialCurrency,
        iban: initialIban,
        bban: initialBban,
        isPrimary: initialIsPrimary
      },
      errors: {}
    });
  }, [isOpen]);

  const handleSave = () => {
    try {
      bankAccountForm.submitForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DrawerDialog
      title={t("webapp:common.title.bank-account")}
      actions={(
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose}>
            {t("abort")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t("save")}
          </Button>
        </div>
      )}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        <div>
          <FormikSelect
            required
            name="currency"
            fullWidth
            form={bankAccountForm}
            label={t("webapp:common.title.currency")}
          >
            <MenuItem value={Currency.CZK}>CZK</MenuItem>
            <MenuItem value={Currency.EUR}>EUR</MenuItem>
          </FormikSelect>
        </div>
        <div>
          <FormikTextField
            required
            form={bankAccountForm}
            name="bban"
            label={t("webapp:common.title.bban")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            required
            name="iban"
            form={bankAccountForm}
            label={t("webapp:common.title.iban")}
            fullWidth
          />
        </div>
        <div>
          <FormikCheckbox
            form={bankAccountForm}
            name="isPrimary"
            label={t("webapp:common.title.primary")}
            color="primary"
            fullWidth
          />
        </div>
      </div>
    </DrawerDialog >
  );
};

export default BankAccountEditor;
