import React from "react";

import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    [breakpoints.down("xs")]: {
      paddingTop: spacing(3),
      paddingRight: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2)
    },
    [breakpoints.up("sm")]: {
      paddingTop: spacing(2),
      paddingRight: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2)
    }
  },
  rootMargin: {
    marginBottom: spacing(10)
  },
  header: {
    [breakpoints.down("xs")]: {
      marginBottom: spacing(1)
    },
    [breakpoints.up("sm")]: {
      marginBottom: 0
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    position: "fixed",
    bottom: spacing(3),
    right: spacing(3),
    "& > :not(:last-child)": {
      marginRight: spacing(1)
    }
  }
}));

const Page = ({
  className,
  actions,
  title,
  children,
  secondaryActions,
  tertiaryActions,
  rootStyle
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <div className={clsx(className, classes.root, { [classes.rootMargin]: actions })} style={rootStyle} >
        {title != undefined || secondaryActions != undefined || tertiaryActions != undefined ? (
          <div className={classes.header}>
            <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5" style={{ fontSize: 20, fontWeight: "bold" }} component="span">{title}</Typography>
              </Grid>
              {secondaryActions != undefined || !isResponsive ? (
                <Grid item xs={12} sm={7} style={{ display: "flex", alignItems: "center" }}>
                  {secondaryActions}
                </Grid>
              ) : null}
              {tertiaryActions != undefined || !isResponsive ? (
                <Grid item xs={12} sm={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                  {tertiaryActions}
                </Grid>
              ) : null}
            </Grid>
          </div>
        ) : null}
        <>
          {children}
        </>
      </div>
      <div className={classes.actions}>
        {actions}
      </div>
    </>
  );
};

export default Page;
