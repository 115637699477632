import React, { useEffect, useState, useCallback } from "react";
import { Check, HighlightOff } from "@material-ui/icons";

import IBAN from "iban";

import { Typography } from "@material-ui/core";
import { ValueError } from "@cargotic/common-deprecated";
import { Permission } from "@cargotic/model";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Currency } from "@cargotic/currency";
import { formatDate } from "@cargotic/common";

import useAuth from "../../hook/useAuth";
import { updatePassword } from "../../../auth";
import { useApiClient } from "../../../../cargotic-webapp-component";
import useTable from "../../../datatable/useTable";
import { convertCzFormatToBBAN, formatIBAN, DEFAULT_COUNTRY_CODE, convertStringToIBANShape, convertBBANFormatToCz } from "../../../utility/banking";

import Settings from "./Settings";
import BankAccountEditor from "./BankAccountEditor";
import ExchangeRateEditor from "./ExchangeRateEditor";

import BankAccountDeleteConfirmationAlert from "./BankAccountDeleteConfirmationAlert";
import RoleDeleteConfirmationAlert from "./RoleDeleteConfirmationAlert";
import ExchangeRateDeleteConfirmationAlert from "./ExchangeRateDeleteConfirmationAlert";
import StateDeleteConfirmationAlert from "./StateDeleteConfirmationAlert";

import SettingsView from "./SettingsView";
import SettingsRoleEditor from "./SettingsRoleEditor";

import {
  readCompanyProfile,
  updateCompanyProfile,
  createCompanyProfile,
  patchCompanyProfile,
  updateCompanyProfileAvatar,
  deleteCompany,

  updateCustomerProfile,
  updateCustomerProfileAvatar,

  readInvoices,
  readAvailableTags,

  readUserTerms,
  updateTerms,
  createTerms,
  readCompanyTerms,
  deleteTerms,

  createStampSignature,
  deleteStampSignature,

  createCompanyBankAccountMatchQuery,
  updateCompanyBankAccount,
  createCompanyBankAccount,
  deleteCompanyBankAccount,

  readCompanyPermissions,
  createUserRole,
  readPermissionsByRoleId,
  updateCompanyRole,
  createCompanyRoleMatchQuery,
  deleteCompanyRole,

  createMatchQueryExchangeRates,
  createExchangeRate,
  putExchangeRate,
  deleteExchangeRate,

  createMatchQueryWarehouses,
  deleteWarehouse,
  createWarehouse,
  putWarehouse,

  createStateMatchQuery,
  deleteState,

  generateToken,
  matchUserToken
} from "../../../resource";

import UpdateUserProfileDialog from "../UserProfile/UpdateUserProfileDialog";
import CreateCompanyProfileDialog from "../CompanyProfile/CreateCompanyProfileDialog";
import UpdateCompanyProfileDialog from "../CompanyProfile/UpdateCompanyProfileDialog";
import ChangePasswordDialog from "../UserProfile/ChangePasswordDialog";
import DeleteCompanyDialog from "../CompanyProfile/DeleteCompanyDialog";
import SetConditionsDialog from "../CompanyProfile/SetConditionsDialog";
import SetPricePerKmDialog from "../CompanyProfile/SetPricePerKmDialog";

import useUserProfile from "../../hook/useUserProfile";
import useTariff from "../../hook/useTariff";

import { convertCzFormatToBBAN, formatIBAN, DEFAULT_COUNTRY_CODE, convertBBANFormatToCz } from "../../../utility/banking";
import WarehouseEditor from "./WarehouseEditor";
import WarehouseDeleteConfirmationAlert from "./WarehouseDeleteConfirmationAlert";


import SettingsStateView from "./SettingsStateView";
import { useHistory } from "react-router-dom";

const SettingsContainer = ({ view, onViewChange }) => {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const { user, signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const language = i18n.language === "cs" ? "CZ" : "EN";
  const {
    loading: userProfileLoading,
    setLoading: setUserProfileLoading,
    setUserProfile,
    userProfile
  } = useUserProfile();

  const apiClient = useApiClient();

  const [companyProfile, setCompanyProfile] = useState([]);
  const [userConditions, setUserConditions] = useState({});
  const [companyConditions, setCompanyConditions] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [deleteCompanyDialogOpen, setDeleteCompanyDialogOpen] = useState(false);
  const [companyConditionsOpen, setCompanyConditionsOpen] = useState(false);
  const [userConditionsOpen, setUserConditionsOpen] = useState(false);
  const [priceOpen, setPriceOpen] = useState(false);
  const [optionTags, setOptionTags] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [states, setStates] = useState([]);
  const [stampSignatureFile, setStampSignatureFile] = useState();
  const [exchangeRatesMode, setExchangeRatesMode] = useState();

  const [
    updateCustomerProfileDialogOpen,
    setUpdateCustomerProfileDialogOpen
  ] = useState(false);
  const [
    updateCompanyProfileDialogOpen,
    setUpdateCompanyProfileDialogOpen
  ] = useState(false);
  const [
    createCompanyProfileDialogOpen,
    setCreateCompanyProfileDialogOpen
  ] = useState(false);

  const [loading, setLoading] = useState(true);

  const [isBankAccountLoading, setIsBankAccountLoading] = useState(true);
  const [isCompanyProfileLoading, setIsCompanyProfileLoading] = useState(true);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
  const [isUserTermsLoading, setIsUserTermsLoading] = useState(true);
  const [isCompanyTermsLoading, setIsCompanyTermsLoading] = useState(true);
  const [isUserRoleLoading, setIsUserRoleLoading] = useState(true);
  const [isStateLoading, setIsStateLoading] = useState(true);
  const [isRoleLoading, setIsRoleLoading] = useState(true);
  const [isExchangeRateModeLoading, setIsExchangeRateModeLoading] = useState(true);

  const [isBankAccountEditorOpen, setIsBankAccountEditorOpen] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState({});
  const [isBankAccountDeleteDialogOpen, setIsBankAccountDeleteDialogOpen] = useState(false);

  const [isWarehouseEditorOpen, setIsWarehouseEditorOpen] = useState(false);

  const [isExchangeRateEditorOpen, setIsExchangeRateEditorOpen] = useState(false);
  const [selectedExchangeRate, setSelectedExchangeRate] = useState({});

  const [selectedWarehouse, setSelectedWarehouse] = useState({});

  const [userConditionsLoading, setUserConditionsLoading] = useState(true);
  const [companyConditionsLoading, setCompanyConditionsLoading] = useState(true);
  const [hasDocumentStampSignatureFileDropzoneError, setHasDocumentStampSignatureFileDropzoneError] = useState(false);

  const [isRolesDeleteDialogOpen, setIsRolesDeleteDialogOpen] = useState(false);

  const [isExchangeRateDeleteDialogOpen, setIsExchangeRateDeleteDialogOpen] = useState(false);

  const [isWarehouseDeleteDialogOpen, setIsWarehouseDeleteDialogOpen] = useState(false);

  const [isExchangeRateLoading, setIsExchangeRateLoading] = useState(true);
  const [exchangeRates, setExchangeRates] = useState([]);

  const [isWarehousesLoading, setIsWarehousesLoading] = useState(true);
  const [warehouses, setWarehouses] = useState([]);

  const [isStatesDeleteDialogOpen, setIsStatesDeleteDialogOpen] = useState(false);

  const [token, setToken] = useState("");
  const [tokens, setTokens] = useState([]);

  const handleOpenDeleteBankAccountDialog = () => setIsBankAccountDeleteDialogOpen(true);
  const handleOpenDeleteRolesDialog = () => setIsRolesDeleteDialogOpen(true);
  const handleOpenBankAccountEditor = () => setIsBankAccountEditorOpen(true);
  const handleOpenDeleteExchangeRatesDialog = () => setIsExchangeRateDeleteDialogOpen(true);
  const handleOpenDeleteWarehouseDialog = () => setIsWarehouseDeleteDialogOpen(true);
  const handleOpenDeleteStatesDialog = () => setIsStatesDeleteDialogOpen(true);


  const updateCompanyProfilePartial = (update) =>
    updateCompanyProfile({ ...companyProfile, ...update });

  const fetchTags = async () => {
    const tgs = await readAvailableTags(t, language);
    setOptionTags(tgs);
  };

  const handleDeleteUserConditionsSubmit = async (termsId) => {
    setUserConditionsOpen(false);

    try {
      await deleteTerms(termsId);
      setUserConditions({});
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    }
  }

  const handleDeleteCompanyConditionsSubmit = async (termsId) => {
    setCompanyConditionsOpen(false);

    try {
      await deleteTerms(termsId);
      setCompanyConditions({});
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    }
  }

  const handleExchangeRateEditorSubmit = async ({ id, sourceCurrency, targetCurrency, date, rate }) => {
    setIsExchangeRateEditorOpen(false);

    try {
      if (id) {
        await putExchangeRate(id, { sourceCurrency, targetCurrency, createdAt: date, rate });
      }
      else {
        await createExchangeRate({ sourceCurrency, targetCurrency, createdAt: date, rate });
      }
      await exchangeRatesTableProps.reloadData();
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t(`webapp:settings.error.${id ? `update-exchange-rate` : `create-exchange-rate`}`), {
        variant: "error"
      });
    }
  }

  const handleUserConditionsSubmit = async (terms) => {
    setLoading(true);
    setUserConditionsOpen(false);

    try {
      const { termsId, ...termsData } = terms;
      if (termsId) {
        await updateTerms(termsId, termsData);
        setUserConditions({ termsId, ...termsData });
      } else {
        const createdTerms = await createTerms(terms, false);
        setUserConditions({ termsId: createdTerms.termsId, isCompany: false, ...terms });
      }
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyConditionsSubmit = async (terms) => {
    setLoading(true);
    setCompanyConditionsOpen(false);

    try {
      const { termsId, ...termsData } = terms;
      if (termsId) {
        await updateTerms(termsId, termsData);
        setCompanyConditions({ termsId, ...termsData });
      } else {
        await createTerms(terms, true);
        setCompanyConditions({ termsId, ...termsData });
      }

    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCustomerProfileSubmit = (firstName, lastName, phoneNumber) => {
    setUserProfileLoading(true);
    setUpdateCustomerProfileDialogOpen(false);

    return updateCustomerProfile(firstName, lastName, phoneNumber, null)
      .then(() => {
        setUserProfile({
          ...userProfile, firstName, lastName, phoneNumber
        });
        setUserProfileLoading(false);
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(t("users.error.update"), {
          variant: "error"
        });

        setUserProfileLoading(false);
      });
  };
  const handleCreateCompanyProfileSubmit = (
    ic,
    dic,
    companyName,
    url,
    email,
    address,
    placeId,
    tags
  ) => {
    setLoading(true);
    setCreateCompanyProfileDialogOpen(false);

    return createCompanyProfile(ic, dic, url, companyName, email, address, placeId, tags)
      .then(() => {
        setCompanyProfile({
          ic, dic, url, companyName, email, address, placeId, tags
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(t("company.error.create"), {
          variant: "error"
        });
      });
  };

  const handleUpdateCompanyProfileSubmit = async ({
    ic,
    dic,
    url,
    companyName,
    email,
    address,
    placeId,
    responsiblePerson,
    tags,
    phoneNumber
  }) => {
    setLoading(true);
    setUpdateCompanyProfileDialogOpen(false);
    return updateCompanyProfile({
      ic, dic, url, companyName, email, address, phoneNumber, placeId, responsiblePerson, tags
    })
      .then(() => {
        setCompanyProfile({
          ...companyProfile, ic, dic, url, companyName, email, phoneNumber, address, placeId, responsiblePerson, tags
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(t("company.error.update"), {
          variant: "error"
        });
      });
  };

  const handleChangePasswordSubmit = (password) => {
    setLoading(true);
    setChangePasswordDialogOpen(false);
    return updatePassword(password)
      .then(() => {
        setLoading(false);
        enqueueSnackbar(t("auth.success.changePassword"), { variant: "success" });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        enqueueSnackbar(t("auth.error.changePassword"), {
          variant: "error"
        });
      });
  };

  const handleDeleteCompanySubmit = () => {
    setLoading(true);
    setDeleteCompanyDialogOpen(false);
    return deleteCompany().then(() => signOut())
      .catch((err) => {
        console.error(err);
        setLoading(false);
        enqueueSnackbar(t("auth.error.deleteAccount"), {
          variant: "error"
        });
      });
  };

  const handleChangeExchangeRatesMode = async (mode) => {
    try {
      await patchCompanyProfile({ exchangeRateMode: mode });
      await exchangeRatesTableProps.reloadDataFromScratch();
    } catch (error) {
      console.error(error);

      const { response } = error;
      enqueueSnackbar(t("settings.error.update"), {
        variant: "error"
      });
    }
  }

  const handleChangeUserAvatarImage = async (file) => {
    setUserProfileLoading(true);

    try {
      const avatarUrl = await updateCustomerProfileAvatar(file);

      setUserProfile({
        ...userProfile,
        avatarUrl
      });
    } catch (error) {
      console.error(error);

      const { response } = error;

      if (response && response.status === 413) {
        enqueueSnackbar(t("settings.error.updatePayloadTooLarge"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("settings.error.update"), {
          variant: "error"
        });
      }
    } finally {
      setUserProfileLoading(false);
    }
  };

  const handleChangeCompanyAvatarImage = async (file) => {
    setLoading(true);

    try {
      const avatarUrl = await updateCompanyProfileAvatar(file);

      setCompanyProfile({
        ...companyProfile,
        avatarUrl
      });
    } catch (error) {
      console.error(error);

      const { response } = error;

      if (response && response.status === 413) {
        enqueueSnackbar(t("settings.error.updatePayloadTooLarge"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("settings.error.update"), {
          variant: "error"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRoleEditorSubmit = (data) => {
    const { id, ...rest } = data;
    if (id) {
      handleUpdateUserRole(data);
    }
    else {
      handleCreateUserRole(data);
    }
  }

  const handleUpdateUserRole = async ({ id, name, description, permissionsIds }) => {
    try {
      const updated = await updateCompanyRole(id, { name, description, permissionsIds });
      console.log(updated);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error"
      });
    }
  }

  const handleCreateUserRole = async ({ name, description, permissionsIds }) => {
    try {
      const result = await createUserRole({ name, description, permissionsId: permissionsIds });

      console.log(result);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error"
      });
    }
  }

  const handleSetExchangeRatesMode = (value) => {
    setExchangeRatesMode(value);
    handleChangeExchangeRatesMode(value);
  }

  const reloadUserRoles = useCallback(async (offset, limit, ordering) => {
    // not permission not send
    if (!hasReadRolePermission) {
      return { data: [], totalCnt: 0 };
    }

    let userRoleData = [];
    setIsUserRoleLoading(true);

    const userRoles = await createCompanyRoleMatchQuery({ offset, limit, orderBy: ordering });

    userRoles.matches.map((role) => {
      role.selected = false;

      let tableCells = [];
      tableCells.push({
        render: <Typography variant="body2">{role.name}</Typography>
      });
      tableCells.push({
        render: <Typography variant="body2">{role.description}</Typography>
      });

      userRoleData.push({ id: role.id, row: tableCells, selected: false });
      return role;
    });

    setUserRoles(userRoleData);
    setIsUserRoleLoading(false);
    return { data: userRoleData, totalCnt: userRoles.total };
  }, []);




  const reloadState = useCallback(async (offset, limit, ordering) => {
    // not permission not send
    // TODO
    if (!hasReadRolePermission) {
      return { data: [], totalCnt: 0 };
    }

    // refactor duplicate in SettingsStateNavigation 
    const resources = Object.keys(SettingsStateView).find(key => SettingsStateView[key].url === history.location.pathname) || SettingsStateView.WAREHOUSE_ORDER.key;
    let stateData = [];
    setIsStateLoading(true);

    const states = await createStateMatchQuery({ resources, offset, limit, orderBy: ordering });

    states.matches.map((state) => {
      state.selected = false;

      let tableCells = [];
      tableCells.push({
        render: <Typography variant="body2">{state.name}</Typography>
      });

      stateData.push({ id: state.stateId, row: tableCells, selected: false });
      return state;
    });

    setStates(states);
    setIsStateLoading(false);

    return { data: stateData, totalCnt: states.total };
  }, []);

  const reloadBankAccounts = useCallback(async (offset, limit, ordering) => {
    // not permission not send
    if (!hasReadBankAccountPermission) {
      return { data: [], totalCnt: 0 };
    }

    setIsBankAccountLoading(true);
    try {
      let bankAccountData = [];
      let promise = await createCompanyBankAccountMatchQuery({
        orderBy: ordering,
        offset,
        limit
      });

      // let promise = await apiClient.bankAccount.getBankAccounts();

      let _bankAccounts = promise;

      _bankAccounts.matches.map((account) => {
        account.selected = false;

        let tableCells = [];
        tableCells.push({
          render: <Typography variant="body2">{account.currency}</Typography>
        });
        tableCells.push({
          render: <Typography variant="body2">{account.bban}</Typography>
        });
        tableCells.push({
          render: <Typography variant="body2"> {convertStringToIBANShape(account.iban)} </Typography>
        });
        tableCells.push({
          render: <Typography variant="body2"> {account.isPrimary ? (
            <Check style={{ color: "green" }} />
          ) : (
            <HighlightOff style={{ color: "red" }} />
          )} </Typography>
        });

        bankAccountData.push({ id: account.id, row: tableCells, selected: false });
        return account;
      });

      setBankAccounts(_bankAccounts.matches);
      setIsBankAccountLoading(false);

      return { data: bankAccountData, totalCnt: _bankAccounts.total };
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("settings.bankAccount.error.read"), {
        variant: "error"
      });
    }
  }, []);

  const OUTPUT_DATE_FORMAT = "yyyy-MM-dd";

  const reloadAutomaticExchangeRates = useCallback(async (offset, limit, ordering) => {

    // not permission not send request
    if (!hasReadExchangeRatePermission) {
      return { data: [], totalCnt: 0 };
    }


    setIsExchangeRateLoading(true);
    try {
      let exchangeRatesData = [];
      let promise = createMatchQueryExchangeRates({
        orderBy: ordering,
        offset,
        limit
      });

      let _exchangeRates = await promise;

      _exchangeRates.matches.map((rate) => {
        let tableCells = [];

        tableCells.push({
          render: <Typography variant="body2">{formatDate(new Date(rate.createdAt), OUTPUT_DATE_FORMAT)}</Typography>
        });
        tableCells.push({
          render: <Typography variant="body2">{rate.sourceCurrency}</Typography>
        });
        tableCells.push({
          render: <Typography variant="body2"> {rate.rate} </Typography>
        });
        tableCells.push({
          render: <Typography variant="body2">{rate.targetCurrency}</Typography>
        });


        exchangeRatesData.push({ id: rate.id, row: tableCells, selected: false });
        return rate;
      });
      setExchangeRates(_exchangeRates.matches);
      setIsExchangeRateLoading(false);

      return { data: exchangeRatesData, totalCnt: _exchangeRates.total };
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("settings.exchangeRates.error.read"), {
        variant: "error"
      });
    }
  }, []);

  const reloadWarehouses = useCallback(async (offset, limit, ordering) => {
    // not permission not send
    if (!hasReadWarehousePermission) {
      return { data: [], totalCnt: 0 };
    }

    let warehousesData = [];
    setIsWarehousesLoading(true);
    let promise = createMatchQueryWarehouses({
      orderBy: ordering,
      offset,
      limit
    });

    let warehouses = await promise;
    setWarehouses(warehouses.matches);
    warehouses.matches.map((warehouse) => {
      warehouse.selected = false;

      let tableCells = [];
      tableCells.push({
        render: <Typography variant="body2">{warehouse.name}</Typography>
      });
      tableCells.push({
        render: <Typography variant="body2">{warehouse.place.address.formatted}</Typography>
      });

      warehousesData.push({ id: warehouse.id, row: tableCells, selected: false });
      return warehousesData;
    });

    setIsWarehousesLoading(false);
    return { data: warehousesData, totalCnt: warehouses.total };
  }, []);


  const bankAccountsTableProps = useTable(reloadBankAccounts, "bankAccounts");
  const userRoleTableProps = useTable(reloadUserRoles, "userRoles");
  const exchangeRatesTableProps = useTable(reloadAutomaticExchangeRates, "exchangeRates");
  const warehousesTableProps = useTable(reloadWarehouses, "warehouses");
  const userStateTableProps = useTable(reloadState, "userState");


  const selectedBankAccounts = [];
  for (let index = 0; index < bankAccountsTableProps.data.length; index++) {
    if (bankAccountsTableProps.data[index].selected) {
      selectedBankAccounts.push(bankAccounts[index]);
    }
  }

  const selectedUserRoles = [];
  for (let index = 0; index < userRoleTableProps.data.length; index++) {
    if (userRoleTableProps.data[index].selected) {
      selectedUserRoles.push(userRoles[index]);
    }
  }

  const selectedExchangeRates = [];
  for (let index = 0; index < exchangeRatesTableProps.data.length; index++) {
    if (exchangeRatesTableProps.data[index].selected) {
      selectedExchangeRates.push(exchangeRates[index]);
    }
  }


  const selectedUserState = userStateTableProps.data.map(e => e.selected && states.matches.find(i => i.stateId === e.id)).filter(Boolean);

  const handleDeleteExchangeRatesSubmit = async () => {
    setIsExchangeRateDeleteDialogOpen(false);

    const ids = selectedExchangeRates.map(({ id }) => id);
    const promise = ids.map(deleteExchangeRate);

    try {
      await Promise.all(promise);
      exchangeRatesTableProps.reloadDataFromScratch();
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("webapp:settings.error.delete-exchange-rate"), {
        variant: "error"
      });
    }
  }

  const selectedWarehouses = [];
  for (let index = 0; index < warehousesTableProps.data.length; index++) {
    if (warehousesTableProps.data[index].selected) {
      selectedWarehouses.push(warehouses[index]);
    }
  }

  const handleDeleteWarehouseSubmit = async () => {
    setIsWarehouseDeleteDialogOpen(false);
    const ids = selectedWarehouses.map(({ id }) => id);
    const promise = ids.map(deleteWarehouse);

    try {
      await Promise.all(promise);
      await warehousesTableProps.reloadData();
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("webapp:settings.error.delete-warehouse"), {
        variant: "error"
      });
    }
  }

  const handleDeleteRolesSubmit = async () => {
    setIsRolesDeleteDialogOpen(false);

    const ids = selectedUserRoles.map(({ id }) => id);
    const promise = ids.map(deleteCompanyRole);

    try {
      await Promise.all(promise);
      userRoleTableProps.reloadDataFromScratch();
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("webapp:settings.error.delete-role"), {
        variant: "error"
      });
    }
  }

  const handleDeleteBankAccountsSubmit = async () => {
    setIsBankAccountDeleteDialogOpen(false);

    const ids = selectedBankAccounts.map(({ id }) => id);
    const promise = ids.map(deleteCompanyBankAccount);

    try {
      await Promise.all(promise);
      bankAccountsTableProps.reloadDataFromScratch();
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("settings.bankAccount.error.delete"), {
        variant: "error"
      });
    }
  };


  const handleBankAccountEditorSubmit = async ({ id: bankAccountId, currency, bban, iban, isPrimary }) => {
    setIsBankAccountEditorOpen(false);
    const processedBban = bban?.trim().replace("/", "").replace("-", "")
    const processedIban = iban?.trim()

    try {
      if (bankAccountId) {
        await updateCompanyBankAccount(bankAccountId, { currency, bban: processedBban, iban: processedIban, isPrimary });
      } else {
        // if (isIbanFormatSelected) {
        //   const formattedIban = formatIBAN(iban);

        //   if (!IBAN.isValid(formattedIban)) {
        //     throw new ValueError("settings.bankAccount.updateErrorIBAN");
        //   }
        //   updatedBankAccount = await apiClient.bankAccount.putBankAccount({
        //     id,
        //     bankAccount: { currency, iban: formattedIban }
        //   });
        // } else {
        //   const formattedBban = convertCzFormatToBBAN(bban);

        //   if (!IBAN.isValidBBAN(DEFAULT_COUNTRY_CODE, formattedBban)) {
        //     throw new ValueError("settings.bankAccount.updateErrorBBAN");
        //   }
        const { id } = await createCompanyBankAccount({ currency, bban: processedBban, iban: processedIban, isPrimary });
      }
      await bankAccountsTableProps.reloadData();
    } catch (error) {
      console.error(error);
      let errorMessage = t("settings.bankAccount.error.update");

      if (error instanceof ValueError) {
        errorMessage = t(error.message);
      }

      enqueueSnackbar(errorMessage, {
        variant: "error"
      });
    }
  };

  const handleWarehouseEditorSubmit = async (isCreate, _warehouse, address) => {
    setIsWarehouseEditorOpen(false);

    let warehouse = {
      ..._warehouse,
      description: "",
      creator: user.firstName + " " + user.lastName,
      creatorId: user.id,
      place: address,
    }
    delete warehouse.address;

    try {
      if (isCreate) {
        await createWarehouse(warehouse);
      } else {
        await putWarehouse(selectedWarehouse.id, warehouse);
      }
      await warehousesTableProps.reloadData();
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error"
      });
    }
  };



  const handleDeleteStatesSubmit = async () => {
    setIsStatesDeleteDialogOpen(false);

    const ids = selectedUserState.map(({ stateId }) => stateId);
    const promise = ids.map(deleteState);

    try {
      await Promise.all(promise);
      userStateTableProps.reloadDataFromScratch();
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("webapp:settings.error.delete-state"), {
        variant: "error"
      });
    }
  }

  const handleStampSignatureFileSelect = async (file) => {
    setHasDocumentStampSignatureFileDropzoneError(false);

    try {
      const { url, id, createdAt, name } = await createStampSignature(file);

      setStampSignatureFile({ id, url, createdAt, isUploading: false, name });
    } catch (error) {
      console.error(error);

      let message = t("settings.stamp.error.upload.general");

      if (error?.response?.data?.error === "FileTooLargeError") {
        message = t("settings.stamp.error.upload.size");
      }

      setStampSignatureFile({
        isUploading: false,
        error: message
      });
    }
  }

  const handleStampSignatureFileDelete = async () => {
    try {
      await deleteStampSignature();

      setStampSignatureFile(undefined);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("settings.stamp.error.delete"), {
        variant: "error"
      });
    }
  };

  const handleChangeTokenClick = async (email) => {
    if (email) {
      const { tokens } = await generateToken(email)

      if (tokens.length !== 0) {
        setToken(tokens[0].token);
      }

      setTokens(tokens);

      enqueueSnackbar(t("settings.tokenCreate"), {
        variant: "success"
      });
    } else {
      enqueueSnackbar(t("settings.error.tokenMail"), {
        variant: "error"
      });
    }
  }

  const { hasPermission } = useAuth();

  const hasDeleteUserPermission = hasPermission("resource.user.delete");
  const hasReadUserProfilePermission = hasPermission("resource.user.read");
  const hasUpdateUserProfilePermission = hasPermission("resource.user.update");

  const hasReadCompanyProfilePermission = hasPermission("resource.company.read");
  const hasDeleteCompanyPermission = hasPermission("resource.company.delete");
  const hasUpdateCompanyProfilePermission = hasPermission("resource.company.update");

  const hasReadCompanyTermsPermission = hasPermission("resource.terms.company.read");
  const hasUpdateCompanyTermsPermission = hasPermission("resource.terms.company.update");
  const hasDeleteCompanyTermsPermission = hasPermission("resource.terms.company.delete");

  const hasReadInvoicesPermission = hasPermission("resource.subscription.invoice.read");

  const hasUpdateBankAccountPermission = hasPermission(Permission.resource.bankAccount.update);
  const hasDeleteBankAccountPermission = hasPermission(Permission.resource.bankAccount.delete);
  const hasCreateBankAccountPermission = hasPermission(Permission.resource.bankAccount.create);
  const hasReadBankAccountPermission = hasPermission(Permission.resource.bankAccount.read);

  const hasCreateRolePermission = hasPermission(Permission.resource.role.create);
  const hasDeleteRolePermission = hasPermission(Permission.resource.role.delete);
  const hasUpdateRolePermission = hasPermission(Permission.resource.role.update);
  const hasReadRolePermission = hasPermission(Permission.resource.role.read);

  const hasReadWarehousePermission = hasPermission("resource.warehouse.read");
  const hasCreateWarehousePermission = hasPermission("resource.warehouse.create");
  const hasUpdateWarehousePermission = hasPermission("resource.warehouse.update");
  const hasDeleteWarehousePermission = hasPermission("resource.warehouse.delete");

  const hasUpdateExchangeRatePermission = hasPermission("resource.exchangeRate.custom.update");
  const hasCreateExchangeRatePermission = hasPermission("resource.exchangeRate.custom.create");
  const hasDeleteExchangeRatePermission = hasPermission("resource.exchangeRate.custom.delete");
  const hasReadExchangeRatePermission = hasPermission("resource.exchangeRate.custom.read");
  const hasUpdateExchangeRateModePermission = hasPermission("resource.exchangeRate.mode.update");

  const hasUpdateSequencingPermission = hasPermission("resource.documentSequence.update");
  const hasReadSequencingPermission = hasPermission("resource.documentSequence.read");
  const hasInviteUserPermission = hasPermission("resource.user.create");


  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      setIsCompanyProfileLoading(true);
      try {
        if (hasReadCompanyProfilePermission) {
          const companyProfile = await readCompanyProfile(language);
          setCompanyProfile(companyProfile);
          setExchangeRatesMode(companyProfile.exchangeRateMode);
          if (companyProfile.stampSignature) {
            const { stampSignature } = companyProfile;
            setStampSignatureFile(stampSignature);
          }
        }
      }
      catch (err) {
        console.error(err);

        enqueueSnackbar(t("company.error.loading"), {
          variant: "error"
        });
      }
      finally {
        setIsCompanyProfileLoading(false);
        setIsExchangeRateModeLoading(false);
      }
    };
    fetchCompanyProfile();
  }, []);


  useEffect(() => {
    const fetchInvoices = async () => {
      setIsInvoiceLoading(true);
      try {
        if (hasReadInvoicesPermission) {
          const invoices = await readInvoices();
          setInvoices(invoices);
        }
      }
      catch (err) {
        console.error(err);

        enqueueSnackbar(t("settings.error.loadingInvoices"), {
          variant: "error"
        });
      }
      finally {
        setIsInvoiceLoading(false);
      }
    }
    fetchInvoices();
  }, []);

  useEffect(() => {
    const fetchTerms = async () => {
      setIsUserTermsLoading(true);
      if (hasReadUserProfilePermission) {
        try {
          const userTerms = await readUserTerms();
          setUserConditions(userTerms);
          setUserConditionsLoading(false);
        } catch (error) {
          console.error(error);
          setUserConditionsLoading(false);

          enqueueSnackbar(t("company.error.loading"), {
            variant: "error"
          });
        }
      }
      setIsUserTermsLoading(false);
    }

    fetchTerms();
  }, []);

  useEffect(() => {
    const fetchTerms = async () => {
      setIsCompanyTermsLoading(true);
      try {
        const companyTerms = await readCompanyTerms();
        setCompanyConditions(companyTerms);
        setCompanyConditionsLoading(false);
      } catch (error) {
        console.error(error);
        setCompanyConditionsLoading(false);

        enqueueSnackbar(t("company.error.loading"), {
          variant: "error"
        });
      }

      setIsUserTermsLoading(false);
    }

    fetchTerms();
  }, []);

  useEffect(() => {
    const fetchUserTokens = async () => {
      const { tokens } = await matchUserToken();

      if (tokens.length !== 0) {
        setToken(tokens[0].token);
      }

      setTokens(tokens);
    }

    fetchUserTokens();
  }, []);

  return (
    <>
      {view === SettingsView.ROLE_EDITOR ? (
        <SettingsRoleEditor
          isLoading={isRoleLoading}
          handleSubmit={handleRoleEditorSubmit}
        />
      ) : (
        <Settings
          isBankAccountLoading={isBankAccountLoading}
          isCompanyProfileLoading={isCompanyProfileLoading}
          isUserProfileLoading={userProfileLoading}
          isInvoiceLoading={isInvoiceLoading}
          isUserTermsLoading={isUserTermsLoading}
          isCompanyTermsLoading={isCompanyTermsLoading}
          isUserRoleLoading={isUserRoleLoading}
          isExchangeRateModeLoading={isExchangeRateModeLoading}
          isWarehousesLoading={isWarehousesLoading}

          companyProfile={companyProfile}
          customerProfile={userProfile}
          user={user}
          bankAccounts={bankAccounts}
          updateCompanyProfile={updateCompanyProfile}
          updateCompanyProfilePartial={updateCompanyProfilePartial}

          hasReadBankAccountPermission={hasReadBankAccountPermission}
          hasUpdateBankAccountPermission={hasUpdateBankAccountPermission}
          hasDeleteBankAccountPermission={hasDeleteBankAccountPermission}
          hasCreateBankAccountPermission={hasCreateBankAccountPermission}

          hasCreateRolePermission={hasCreateRolePermission}
          hasUpdateRolePermission={hasUpdateRolePermission}
          hasReadRolePermission={hasReadRolePermission}
          hasDeleteRolePermission={hasDeleteRolePermission}

          hasCreateWarehousePermission={hasCreateWarehousePermission}
          hasUpdateWarehousePermission={hasUpdateWarehousePermission}
          hasDeleteWarehousePermission={hasDeleteWarehousePermission}
          hasReadWarehousePermission={hasReadWarehousePermission}

          hasUpdateSequencingPermission={hasUpdateSequencingPermission}
          hasReadSequencingPermission={hasReadSequencingPermission}

          invoices={invoices}
          hasUpdateUserProfilePermission={hasUpdateUserProfilePermission}
          hasDeleteUserPermission={hasDeleteUserPermission}
          hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
          hasReadUserProfilePermission={hasReadUserProfilePermission}
          hasReadCompanyProfilePermission={hasReadCompanyProfilePermission}
          hasReadCompanyTermsPermission={hasReadCompanyTermsPermission}
          hasDeleteCompanyPermission={hasDeleteCompanyPermission}
          hasReadInvoicesPermission={hasReadInvoicesPermission}
          handleUpdateCompanyProfileClick={() => setUpdateCompanyProfileDialogOpen(true)}
          handleUpdateCustomerProfileClick={() => setUpdateCustomerProfileDialogOpen(true)}
          handleCreateCompanyProfileClick={() => setCreateCompanyProfileDialogOpen(true)}
          handleChangePassword={() => setChangePasswordDialogOpen(true)}
          handleDeleteCompany={() => setDeleteCompanyDialogOpen(true)}
          handleUserConditionsClick={() => setUserConditionsOpen(true)}
          handleCompanyConditionsClick={() => setCompanyConditionsOpen(true)}
          handleSetPriceClick={() => setPriceOpen(true)}
          handleChangeUserAvatarImage={handleChangeUserAvatarImage}
          handleChangeCompanyAvatarImage={handleChangeCompanyAvatarImage}

          stampSignatureFile={stampSignatureFile}
          handleStampSignatureFileSelect={handleStampSignatureFileSelect}
          handleFileDelete={handleStampSignatureFileDelete}

          view={view}
          onViewChange={onViewChange}

          bankAccountsTableProps={bankAccountsTableProps}
          userRoleTableProps={userRoleTableProps}
          exchangeRatesTableProps={exchangeRatesTableProps}
          warehousesTableProps={warehousesTableProps}

          handleOpenDeleteBankAccountDialog={handleOpenDeleteBankAccountDialog}
          handleOpenBankAccountEditor={handleOpenBankAccountEditor}
          handleCreateBankAccountClick={() => {
            setSelectedBankAccount({});
            setIsBankAccountEditorOpen(true);
          }}
          handleUpdateBankAcountClick={index => {
            const [bankAccount] = bankAccounts
              .filter(item => index === item.id);

            setSelectedBankAccount(bankAccount);
            setIsBankAccountEditorOpen(true);
          }}
          handleOpenDeleteRolesDialog={handleOpenDeleteRolesDialog}
          handleOpenDeleteExchangeRatesDialog={handleOpenDeleteExchangeRatesDialog}
          subscriptionInfo={user?.subscription}

          exchangeRatesMode={exchangeRatesMode}
          handleSetExchangeRatesMode={handleSetExchangeRatesMode}
          handleCreateExchangeRateClick={() => {
            setSelectedExchangeRate({});
            setIsExchangeRateEditorOpen(true);
          }}
          handleUpdateExchangeRateClick={index => {
            const [exchangeRate] = exchangeRates
              .filter(item => index === item.id);
            setSelectedExchangeRate(exchangeRate);
            setIsExchangeRateEditorOpen(true);
          }}

          handleCreateWarehouseClick={() => {
            setSelectedWarehouse({})
            setIsWarehouseEditorOpen(true);
          }}
          handleUpdateWarehouseClick={index => {
            const [warehouse] = warehouses
              .filter(item => index === item.id);
            setSelectedWarehouse(warehouse);
            setIsWarehouseEditorOpen(true);
          }}
          handleDeleteWarehouseClick={handleOpenDeleteWarehouseDialog}
          handleChangeTokenClick={handleChangeTokenClick}
          token={token}
          tokens={tokens}

          hasUpdateExchangeRatePermission={hasUpdateExchangeRatePermission}
          hasDeleteExchangeRatePermission={hasDeleteExchangeRatePermission}
          hasCreateExchangeRatePermission={hasCreateExchangeRatePermission}
          hasUpdateExchangeRateModePermission={hasUpdateExchangeRateModePermission}
          hasReadExchangeRatePermission={hasReadExchangeRatePermission}


          userStateTableProps={userStateTableProps}
          statesData={states}
          handleOpenDeleteStatesDialog={handleOpenDeleteStatesDialog}

          hasInviteUserPermission={hasInviteUserPermission}

        />)}
      <UpdateUserProfileDialog
        open={updateCustomerProfileDialogOpen}
        customerProfile={userProfile}
        handleClose={() => setUpdateCustomerProfileDialogOpen(false)}
        handleSubmit={handleUpdateCustomerProfileSubmit}
      />
      <CreateCompanyProfileDialog
        open={createCompanyProfileDialogOpen}
        handleClose={() => setCreateCompanyProfileDialogOpen(false)}
        handleSubmit={handleCreateCompanyProfileSubmit}
        optionTags={optionTags}
      />
      <UpdateCompanyProfileDialog
        open={updateCompanyProfileDialogOpen}
        companyProfile={companyProfile}
        handleClose={() => setUpdateCompanyProfileDialogOpen(false)}
        handleSubmit={handleUpdateCompanyProfileSubmit}
        optionTags={optionTags}
      />
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        handleClose={() => setChangePasswordDialogOpen(false)}
        handleSubmit={handleChangePasswordSubmit}
      />
      <DeleteCompanyDialog
        open={deleteCompanyDialogOpen}
        handleClose={() => setDeleteCompanyDialogOpen(false)}
        handleSubmit={handleDeleteCompanySubmit}
      />
      <SetConditionsDialog
        isCompanyConditions
        open={companyConditionsOpen}
        conditions={companyConditions}
        isReadOnly={
          !hasUpdateCompanyTermsPermission && !hasDeleteCompanyTermsPermission
        }
        hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
        handleClose={() => setCompanyConditionsOpen(false)}
        handleSubmit={handleCompanyConditionsSubmit}
        handleDelete={handleDeleteCompanyConditionsSubmit}
      />
      <SetConditionsDialog
        isCompanyConditions={false}
        open={userConditionsOpen}
        handleClose={() => setUserConditionsOpen(false)}
        conditions={userConditions}
        handleSubmit={handleUserConditionsSubmit}
        handleDelete={handleDeleteUserConditionsSubmit}
      />
      <ExchangeRateEditor
        initialValue={selectedExchangeRate}
        isOpen={isExchangeRateEditorOpen}
        onSubmit={handleExchangeRateEditorSubmit}
        onClose={() => setIsExchangeRateEditorOpen(false)}
      />
      <BankAccountEditor
        initialValue={selectedBankAccount}
        isOpen={isBankAccountEditorOpen}
        onSubmit={handleBankAccountEditorSubmit}
        onClose={() => setIsBankAccountEditorOpen(false)}
      />
      <WarehouseEditor
        initialValue={selectedWarehouse}
        isOpen={isWarehouseEditorOpen}
        handleSubmit={handleWarehouseEditorSubmit}
        onClose={() => setIsWarehouseEditorOpen(false)}
      />
      <BankAccountDeleteConfirmationAlert
        isOpen={isBankAccountDeleteDialogOpen}
        onClose={() => setIsBankAccountDeleteDialogOpen(false)}
        onSubmit={handleDeleteBankAccountsSubmit}
      />
      <RoleDeleteConfirmationAlert
        isOpen={isRolesDeleteDialogOpen}
        onClose={() => setIsRolesDeleteDialogOpen(false)}
        onSubmit={handleDeleteRolesSubmit}
      />

      <StateDeleteConfirmationAlert
        isOpen={isStatesDeleteDialogOpen}
        onClose={() => setIsStatesDeleteDialogOpen(false)}
        onSubmit={handleDeleteStatesSubmit}
      />

      <ExchangeRateDeleteConfirmationAlert
        isOpen={isExchangeRateDeleteDialogOpen}
        onClose={() => setIsExchangeRateDeleteDialogOpen(false)}
        onSubmit={handleDeleteExchangeRatesSubmit}
      />
      <WarehouseDeleteConfirmationAlert
        isOpen={isWarehouseDeleteDialogOpen}
        onClose={() => setIsWarehouseDeleteDialogOpen(false)}
        onSubmit={handleDeleteWarehouseSubmit}
      />
    </>
  );
};

export default SettingsContainer;
