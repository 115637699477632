import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import SubmitTransportationDocumentsAppRouter from "./SubmitTransportationDocumentsAppRouter";

const useStyles = makeStyles(() => ({
  "@global": {
    "html, body, #app": {
      height: "100%",
      backgroundColor: "#EEEEEE",
    },
  },
}));

const SubmitTransportationDocumentsApp = () => {
  useStyles();

  return <SubmitTransportationDocumentsAppRouter />;
};

export default SubmitTransportationDocumentsApp;
