import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { cs, enUS } from "date-fns/locale";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { Link as RouterLink } from "react-router-dom";

import { Typography, Link } from "@material-ui/core";

import {
  storeFilters,
  loadFilters
} from "../../../storage";
import Invoices from "./Invoices";
import useRouter from "../../hook/useRouter";
import useTable from "../../../datatable/useTable";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";
import { setInvoicePaidAt } from "@cargotic/webapp/resource";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window";

import {
  ShipmentInvoiceType,
} from "@cargotic/webapp/component/enums/enums";
import { formatDate } from '@cargotic/common-deprecated/date'
import { parseISO } from "date-fns";

import { getInvoices } from "@cargotic/webapp/resource"
import { createInvoicesExport, createShipmentsExport } from "../../../resource";
import { PayIconButton } from "@cargotic/webapp-component";

const TABS = [
  { id: 0, labelKey: "invoices.received", type: ShipmentInvoiceType.RECEIVED },
  { id: 1, labelKey: "invoices.issued", type: ShipmentInvoiceType.ISSUED }
];

const OUTPUT_DATE_FORMAT = "yyyy-MM-dd";

const DEFAULT_FILTER_VALUES = ["author", "customer", "issueDate", "duzp", "createdAt", "dueDate", "paid", "paidAt"];

const InvoicesContainer = () => {
  const { t } = useTranslation();

  const locale = t("locale") === "cs" ? cs : enUS;
  const history = useHistory();
  const {
    type: invoiceType = ShipmentInvoiceType.RECEIVED,
  } = useMemo(
    () =>
      qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      }),
    [history]
  );
  const { location: { search: routerSearch }, ...restRouter } = useRouter();
  const [selectedTab, handleSetSelectedTab] = useState(TABS.find((tab) => tab.type === invoiceType) || TABS[0])

  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  const agendaIdentifier = useMemo(() => {
    const type = selectedTab?.type
    return `invoices-${type}`
  }, [selectedTab])

  const availableFilters = useMemo(() => [
    {
      label: t("invoices.invoice.author"),
      value: "author"
    },
    {
      label: t(selectedTab?.type === ShipmentInvoiceType.ISSUED ? "invoices.invoice.customer" : "invoices.invoice.supplier"),
      value: "customer"
    },
    {
      label: t("invoices.invoice.issueDate"),
      value: "issueDate"
    },
    {
      label: t("invoices.invoice.duzp"),
      value: "duzp"
    },
    {
      label: t("invoices.invoice.createdAt"),
      value: "createdAt"
    },
    {
      label: t("invoices.invoice.dueDate"),
      value: "dueDate"
    },
    {
      label: t("invoices.invoice.status"),
      value: "paid"
    },
    {
      label: t("invoices.invoice.paidAt"),
      value: "paidAt"
    }
  ], [t, selectedTab])

  const [filter, setFilter] = useState(initFilter);
  const [search, setSearch] = useState(initSearchText);

  const [invoices, setInvoices] = useState([]);
  const [isInvoicesExportDialogOpen, setIsInvoicesExportDialogOpen] = useState(false);
  const [isInvoicesExportDone, setIsInvoicesExportDone] = useState(false);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [availableCustomers, setAvailableCustomers] = useState([]);
  const [isInvoicesExportProcessing, setIsInvoicesExportProcessing] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  let reloadDelay;
  let storeSearchDelay;

  const handleSelectAuthor = (author) => setFilter({ ...filter, author });
  const loadAvailableCustomers = () => setAvailableCustomers([]);
  const handleSelectCustomer = (customer) => setFilter({ ...filter, customer });
  const handleSelectStatus = (paid) => setFilter({ ...filter, paid });
  const handleSelectCreatedAtRange = (createdAt) => {
    setFilter({ ...filter, createdAt });
  };
  const handleSelectDuzpRange = (duzp) => {
    setFilter({ ...filter, duzp });
  };
  const handleSelectIssueDateRange = (issueDate) => {
    setFilter({ ...filter, issueDate });
  };
  const handleSelectDueDateRange = (dueDate) => {
    setFilter({ ...filter, dueDate });
  };
  const handleSelectPaidAtDateRange = (paidAt) => {
    setFilter({ ...filter, paidAt });
  };

  const transformFilter = (filter, search, invoiceType) => {
    const { author, customer, issueDate, ...restFilterValues } = filter;
    return {
      ...restFilterValues,
      search,
      editorId: author?.map((a) => a?.id),
      supplierId: invoiceType === ShipmentInvoiceType.RECEIVED ? customer?.map((c) => c?.id) : undefined,
      customerId: invoiceType === ShipmentInvoiceType.ISSUED ? customer?.map((c) => c?.id) : undefined,
      releaseDate: issueDate,
      type: invoiceType,
    }
  };

  const exportInvoicesPohoda = async () => {
    setIsInvoicesExportProcessing(true);

    try {
      const data = await createInvoicesExport(selectedInvoices.map(({ id }) => id));
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      element.setAttribute('download', 'export_pohoda.xml');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.log(error);
    } finally {
      setIsInvoicesExportProcessing(false);
    }
  };

  const openInvoicesExportDialog = () => {
    setIsInvoicesExportDialogOpen(true);
  };

  const closeInvoicesExportDialog = () => {
    setIsInvoicesExportDialogOpen(false);

    if (isInvoicesExportDone) {
      setIsInvoicesExportDone(false);
    }
  };

  const handleInvoicePay = async (invoiceId) => {
    const paidAt = formatDate(new Date(), OUTPUT_DATE_FORMAT);
    await setInvoicePaidAt(invoiceId, {
      paidAt
    });

    setInvoices((cur) => cur.map((invoice) =>
      invoice.id === invoiceId
        ? {
          ...invoice,
          paidAt
        }
        : invoice)
    );

    setData(current => current.map((invoice) => {
      if (invoice.id === invoiceId) {
        const row = [...invoice.row];
        const paidColumnIndex = row.findIndex(({ render: { type: { name }} }) => name === "PayIconButton");
        row[paidColumnIndex] = {
          render: <PayIconButton
            name="payButton"
            isPaid={true}
            paidAt={new Date().toISOString()}
            dueDate={invoice.dueDate}
            onPay={() => handleInvoicePay(invoice.id)}
          />
        };

        return {
          ...invoice,
          row
        };
      }

      return invoice;
    }));
  }

  const reloadInvoices = useCallback(async (offset = 0, limit = 50, ordering) => {
    const invoiceType = selectedTab.type
    try {
      let promise = await getInvoices({
        match: {
          ...transformFilter(filter, search, invoiceType),
        },
        orderBy: ordering,
        offset,
        limit
      });

      let invoices = await promise;
      setInvoices(invoices.matches);
      const invoiceData = invoices?.matches?.map((invoice) => {
        const invoiceType = invoice.type;
        const rowCells = [
          {
            render: <Typography variant="body2"> {invoice.indexNumber} </Typography>
          },
          {
            render:
              <Link component={RouterLink} to={`/contacts/${invoiceType === ShipmentInvoiceType.ISSUED ? invoice.customer.id : invoice.supplier.id}`}>
                {invoiceType === ShipmentInvoiceType.ISSUED ? invoice.customer.name : invoice.supplier.name}
              </Link>
          },
          {
            render: <Typography variant="body2"> {invoice.vs} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoiceType === ShipmentInvoiceType.ISSUED ? invoice.customer.ic : invoice.supplier.ic} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoiceType === ShipmentInvoiceType.ISSUED ? invoice.customer.dic : invoice.supplier.dic} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoice.releaseDate ? formatDate(parseISO(invoice.releaseDate), "dd.MM.yyyy", locale) : "-"} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoice.duzp ? formatDate(parseISO(invoice.duzp), "dd.MM.yyyy", locale) : "-"} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoice.dueDate ? formatDate(parseISO(invoice.dueDate), "dd.MM.yyyy", locale) : "-"} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoice.price ?? "-"} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoice.totalWithoutVAT ?? "-"} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoice.totalVAT ?? "-"} </Typography>
          },
          {
            render: <Typography variant="body2"> {invoice.currency} </Typography>
          },
          {
            render: <PayIconButton
              name="payButton"
              isPaid={invoice.paid}
              paidAt={invoice.paidAt}
              dueDate={invoice.dueDate}
              onPay={() => handleInvoicePay(invoice.id)}
            />
          },
          {
            render: <Typography variant="body2"> {invoice.paidAt ? formatDate(parseISO(invoice.paidAt), "dd.MM.yyyy") : "-"} </Typography>
          }
        ];
        return { id: invoice.id, row: rowCells, selected: false };
      }) ?? [];

      return { data: invoiceData, totalCnt: invoices.total };
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('invoices.loadError'), {
        variant: "error"
      });
    }
  }, [search, filter, selectedTab]);

  const {
    data,
    dataCount,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    selectedColumns,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData
  } = useTable(reloadInvoices, agendaIdentifier);

  const handleDeselectFilters = () => setFilter({});

  const handleFilterSettingsClose = () => {
    setIsFilterSettingsOpen(false);
  };

  const handleFilterSettingsOpen = () => {
    setIsFilterSettingsOpen(true);
  };

  const onFilterSettingsSubmit = (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters(agendaIdentifier, value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const expandFilters = useCallback((values, fullValues) => values.map(item => fullValues.find(i => i.value === item)), []);

  const selectedInvoices = data.filter(({ selected }) => selected);

  useEffect(() => {
    addUrlParam("filter", filter);
  }, [filter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250);
  }, [search]);

  useEffect(() => {
    const loadedFilters = loadFilters(agendaIdentifier);
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(DEFAULT_FILTER_VALUES, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, [availableFilters]);

  useEffect(() => {
    reloadData()
    addUrlParam("type", selectedTab?.type);
  }, [selectedTab])

  return (
    <>
      <Invoices
        tableRows={data}
        selectedTab={selectedTab}
        setSelectedTab={handleSetSelectedTab}
        availableTabs={TABS}
        dataCount={dataCount}
        ordering={ordering}
        search={search}
        direction={direction}
        checkedAll={checkedAll}
        selectedColumns={selectedColumns}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        filter={filter}
        handleReloadInvoices={(scratch) => scratch ? reloadDataFromScratch() : reloadData()}
        handleDeselectFilters={handleDeselectFilters}
        handleSort={handleSort}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        loadAvailableCustomers={loadAvailableCustomers}
        availableCustomers={availableCustomers}
        setAvailableCustomers={setAvailableCustomers}
        isInvoicesExportProcessing={isInvoicesExportProcessing}
        handleSelectAuthor={handleSelectAuthor}
        handleSelectCustomer={handleSelectCustomer}
        handleSelectCreatedAtRange={handleSelectCreatedAtRange}
        handleSelectDuzpRange={handleSelectDuzpRange}
        handleSelectIssueDateRange={handleSelectIssueDateRange}
        handleSelectDueDateRange={handleSelectDueDateRange}
        handleSelectStatus={handleSelectStatus}
        handleSelectPaidAtDateRange={handleSelectPaidAtDateRange}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        isInvoicesExportDialogOpen={isInvoicesExportDialogOpen}
        exportInvoicesPohoda={exportInvoicesPohoda}
        openInvoicesExportDialog={openInvoicesExportDialog}
        closeInvoicesExportDialog={closeInvoicesExportDialog}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default InvoicesContainer;
