import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, makeStyles } from "@material-ui/core";

import BillingCard from "../Billing/BillingCard";
import SubscriptionCard from "../Subscription/SubscriptionCard";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& > :not(:first-child)": {
      marginTop: spacing(4)
    }
  },
  content: {
    flex: 1
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const SettingsTariffAndBillingOverview = ({
  className,
  invoices,
  isLoading,
  subscriptionInfo,
  hasUpdateCompanyProfilePermission,
  user
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={clsx(className, classes.progress)}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <>
      <div className={clsx(className, classes.root)}>
        <SubscriptionCard
          classes={classes}
          hasUpdateCompanyProfilePermission={hasUpdateCompanyProfilePermission}
          user={user}
          subscriptionInfo={subscriptionInfo}
        />
        <BillingCard
          classes={classes}
          invoices={invoices}
        />
      </div>
    </>
  );
};

export default SettingsTariffAndBillingOverview;
