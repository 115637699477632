import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core";
import { LinkField } from "../../common";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles(({breakpoints, spacing }) => ({
  root: {
    [breakpoints.up("sm")]: {
      minWidth: 520
    }
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(4)
    }
  },
  progress: {
    marginTop: spacing(2)
  }
}));

const ExportDialog = ({
  isOpen,
  isProcessing,
  isDone,
  title,
  link,
  linkLabel,
  startButtonLabel,
  downloadCSV,
  closeButtonLabel,
  infoText,
  processingText,
  doneText,
  close,
  exportCsv
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  let content = null;
  const [language, setLanguage] = useState(i18n.language);

  if (isProcessing) {
    content = (
      <>
        <CircularProgress classes={{ root: classes.progress }} />
        <DialogContentText>{processingText}</DialogContentText>
      </>
    );
  } else if (isDone) {
    content = (
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <DialogContentText>{doneText}</DialogContentText>
          </Grid>
          <Grid item xs={10}>
            <LinkField label={linkLabel} value={link} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth href={link} target="_blank" color="primary">
              {downloadCSV}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    content = (
      <>
        <DialogContentText>{infoText}</DialogContentText>
        <FormControl className={classes.formControl}>
          <InputLabel>{t("language.title")}</InputLabel>
          <Select
            autoWidth
            defaultValue={language}
            onChange={(event) => {
              setLanguage(event.target.value);
            }}
          >
            <MenuItem value="cs">
              <ReactCountryFlag
                code="cz"
                svg
                styleProps={{
                  width: "24px",
                  height: "24px"
                }}
              />
            </MenuItem>
            <MenuItem value="en">
              <ReactCountryFlag
                code="gb"
                svg
                styleProps={{
                  width: "24px",
                  height: "24px"
                }}
              />
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }

  return (
    <Dialog classes={{ paper: classes.root }} open={isOpen} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isProcessing || isDone}
          onClick={() => {
            exportCsv(language);
          }}
        >
          {startButtonLabel}
        </Button>
        <Button color="primary" onClick={close}>
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
