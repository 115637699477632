import React from "react";

import { Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { HrefInNewTabIcon } from "../../../../cargotic-webapp-component";

const IncomingOrdersOutcomingOrderReference = ({ incomingOrder }) => {

  const renderReference = ({ id, externalReference, indexNumber, isDeleted }, type) =>
    isDeleted ? (
      <Typography variant="body2" key={id}>
        {externalReference || `#${indexNumber}`}
      </Typography>
    ) : (
      <Typography variant="body2" key={id}>
        <Link
          component={RouterLink}
          to={`${type === "SHIPMENT" ? `/shipment` : `/outcoming-order`}/${id}`}
          rel="noopener"
          target="_blank"
        >
          {`#${indexNumber}`}
          <HrefInNewTabIcon />
        </Link>
      </Typography>
    );

  const shipments = incomingOrder.shipments ? incomingOrder.shipments.map((item) => renderReference(item, "SHIPMENT")) : [];
  const outcomingOrders = incomingOrder.outcomingOrders ? incomingOrder.outcomingOrders.map((item) => renderReference(item, "OUTCOMING_ORDER")) : [];

  return [...shipments, ...outcomingOrders];
};

export default IncomingOrdersOutcomingOrderReference;