import {
  TrendingDown as TrendingDownIcon,
  TrendingFlat as TrendingFlatIcon,
  TrendingUp as TrendingUpIcon
} from "@material-ui/icons";

const getTrendIcon = (rateOfChange) => {
  if (rateOfChange > 0) {
    return TrendingUpIcon;
  }

  if (rateOfChange < 0) {
    return TrendingDownIcon;
  }

  return TrendingFlatIcon;
};

export default getTrendIcon;
