import React, { useState, useEffect } from "react";
import { getPlacesSuggestions } from "../../../places";
import AutocompleteTextField from "./AutocompleteTextField";

const PlacesAutocompleteTextField = ({
  name,
  placeholder,
  label,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  suggestions,
  onSuggestionsChange,
  onSuggestionSelect,
  required
}) => (
  <AutocompleteTextField
    onBlur={onBlur}
    error={Boolean(error)}
    required={required}
    name={name}
    helperText={helperText}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    label={label}
    sourceMethod={getPlacesSuggestions}
    suggestions={suggestions}
    onSuggestionsChange={onSuggestionsChange}
    onSuggestionSelect={onSuggestionSelect}
    shownAttribute="title"
  />
);

export default PlacesAutocompleteTextField;
