import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Tab, Tabs } from "@material-ui/core";
import RoleEditorView from "./RoleEditorView";
import SettingsStateView from "./SettingsStateView";

const SettingsStateNavigation = ({ }) => {
  
  const history = useHistory();

  const handleViewChange = value => {
    if(SettingsStateView[value]){
      history.push(SettingsStateView[value].url);
      return;
    }

    throw new Error(`Invalid settings/states view '${value}'`);
  };

  const { t } = useTranslation();

  const handleTabChange = (_, value) => handleViewChange(value);
  return (
    <Tabs
      indicatorColor="primary"
      value={Object.keys(SettingsStateView).find(key => SettingsStateView[key].url === history.location.pathname) || SettingsStateView.WAREHOUSE_ORDER.key}
      onChange={handleTabChange}
    >     
      {Object.keys(SettingsStateView).map(state => (<Tab
        label={t(SettingsStateView[state].t)}
        value={SettingsStateView[state].key}
      />))}
    </Tabs>
  );
};

export default SettingsStateNavigation;
