import React from "react";

import { Grid, Typography, makeStyles } from "@material-ui/core";
import { formatNumericOrders } from "../../../utility/functional";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import theme from "../App/theme";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";


const StatisticsSummary = ({ data }) => (
  <Grid container spacing={2}>
    {data.map(({ title, data: d, unit, alternative, dataDiff }, index) => (
      <Grid item xs={6} key={index}>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <div style={{display: "flex", alignItems: "center"}}>
          <Typography color="primary" variant="h5" component="p">
            {`${typeof d === "number" ? formatNumericOrders(d.toFixed(0)) : d} ${unit || ""} ${alternative ? `/ ${alternative}` : ""}`}
          </Typography>
          {
            dataDiff !== undefined
              ? (
                dataDiff < 0
                  ? (
                    <ArrowDownward
                      style={{
                        marginLeft: "10px",
                        color: theme.palette.status.danger,
                        height: 14
                      }}
                    />
                  )
                  : (
                    <ArrowUpward
                      style={{
                        marginLeft: "10px",
                        color: theme.palette.primary.light,
                        height: 14
                      }}
                    />
                  )
              )
              : null
          }
          <Typography
            color="primary"
            variant="h5"
            component="p"
            style={{
              fontSize: 14,
              color: dataDiff < 0 ? theme.palette.status.danger : theme.palette.primary.light
            }}
          >
            {`${dataDiff !== undefined ? Math.abs(dataDiff) + "%" : ''}`}
          </Typography>
        </div>
      </Grid>
    ))}
  </Grid>
);

export default StatisticsSummary;
