import React, {useState, useEffect, useRef} from "react";
import { useSnackbar } from "notistack";

import {
  IconButton,
  InputAdornment,
  TextField,
  makeStyles
} from "@material-ui/core";

import { GpsFixed, Search, Room } from "@material-ui/icons";

import SearchAutosuggestTextFieldSuggestArea
  from "./SearchAutosuggestTextFieldSuggestArea";
import { suggestPlacesByAlias } from "../../../../packages/cargotic-webapp/resource";

import GpsCoordinatesDialog from "../GpsCoordinatesDialog";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  },
  adornment: {
    marginRight: -10
  }
}));

const SearchAutosuggestTextField = ({
  value,
  text,
  suggest,
  search,
  searchByCoordinates,
  lastLatLng,
  isCargoEditorVisible,
  setLastLatLng,
  renderSuggestion,
  handlePickFromMap,
  required,
  onValueChange,
  onTextChange,
  onSearchFail,
  error,
  hideCargoPlaces,
  onBlur,
  ...other
}) => {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const [isGpsDialogOpen, setIsGpsDialogOpen] = useState(false);
  const [isPickingFromMap, setIsPickingFromMap] = useState(false);
  const inputRef = useRef();
  const isSuggestionAreaActive = useRef(true);
  const isSuggestionAreaClicked = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleBlur = (event) => {
    if (isSuggestionAreaClicked.current) {
      isSuggestionAreaClicked.current = false;

      if (inputRef.current) {
        inputRef.current.focus();
      }

      return;
    }

    setSuggestions([]);

    if (!text) {
      onTextChange(text);
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleChange = ({ target: { value: newText } }) =>
    onTextChange(newText);

  const handleSearch = async () => {
    if (!text) {
      return;
    }

    const result = await search(text);

    if (!result) {
      onSearchFail();
      return;
    }

    onValueChange(result);
  };

  const handleSearchClick = () =>
    handleSearch();

  const handleGpsClick = () =>
    setIsGpsDialogOpen(true);

  const handleGpsDialogClose = () =>
    setIsGpsDialogOpen(false);

  const handleKeyPress = ({ key }) =>
    key === "Enter"
      ? handleSearch()
      : undefined;

  const handleSuggestionAreaMouseDown = () => {
    isSuggestionAreaClicked.current = true;
  };

  const handleSuggestionSelect = suggestion =>
    onValueChange(suggestion);

  const handlePickingFromMap = () => {
    if (isCargoEditorVisible) {
      setIsPickingFromMap(true);
      handlePickFromMap(false);
    } else {
      setIsPickingFromMap(false);
      handlePickFromMap(true);
    }
  };

  useEffect(() => {
    (async () => {
      if (isPickingFromMap && lastLatLng !== null && typeof lastLatLng.lat !== 'undefined' && typeof lastLatLng.lng !== 'undefined') {
        const result = await searchByCoordinates(lastLatLng.lat, lastLatLng.lng);
        if (!result) {
          if (onSearchFail !== undefined) {
            onSearchFail();
          } else {
            enqueueSnackbar(t("webapp:journey.gps.error.search"), {
              variant: "error"
            });
          }
          return;
        }
        setIsPickingFromMap(false);
        handlePickFromMap(true);
        onValueChange(result);
      }

    })()
  }, [lastLatLng]);

  useEffect(() => {
    isSuggestionAreaActive.current = false;
    setSuggestions([]);
  }, [value]);

  useEffect(() => {
    if (!text || !isSuggestionAreaActive.current) {
      isSuggestionAreaActive.current = true;
      setSuggestions([]);
      return;
    }

    (async () => {
      if (hideCargoPlaces) {
        const googlePlaces = await suggest(text);
        setSuggestions(googlePlaces);
      } else {
        const [googlePlaces, cargoPlaces] = await Promise.all([suggest(text), suggestPlacesByAlias(text)]);
        setSuggestions(cargoPlaces.concat(googlePlaces.slice(0, 5 - cargoPlaces.length)));
      }
    })();
  }, [text]);

  return (
    <div className={classes.root}>
      <TextField
        ref={inputRef}
        autoComplete="off"
        value={text}
        required={required}
        error={error}
        InputProps={{
          endAdornment:
            <InputAdornment className={classes.adornment} position="end">
              <IconButton onClick={handleSearchClick} size="small">
                <Search />
              </IconButton>
              <IconButton onClick={handleGpsClick} size="small">
                <GpsFixed />
              </IconButton>
              <IconButton onClick={handlePickingFromMap} size="small">
                <Room />
              </IconButton>
            </InputAdornment>
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        {...other}
      />
      <GpsCoordinatesDialog
        isOpen={isGpsDialogOpen}
        search={searchByCoordinates}
        onClose={handleGpsDialogClose}
        onSearchFail={onSearchFail}
        onValueChange={onValueChange}
      />
      {
        suggestions.length === 0
          ? null
          : (
            <SearchAutosuggestTextFieldSuggestArea
              suggestions={suggestions}
              renderSuggestion={renderSuggestion}
              onMouseDown={handleSuggestionAreaMouseDown}
              onSuggestionSelect={handleSuggestionSelect}
            />
          )
      }
    </div>
  );
};

export default SearchAutosuggestTextField;
