import React from "react";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: 85,
    height: 85,

    backgroundColor: palette.primary.main,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    color: palette.common.white,

    borderRadius: 12
  }
}));

const IconBox = ({ className, thumbnail, children }) => {
  const classes = useStyles();
  const style = thumbnail
    ? { backgroundImage: `url(${thumbnail})`}
    : undefined;

  return (
    <Paper style={style} className={clsx(classes.root, className)} elevation={2}>
      {children}
    </Paper>
  );
};

export default IconBox;
