import React from "react";

import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox
} from "@material-ui/core";

import FilterDynamicSuggestionDropdown from "../FilterDynamicSuggestionDropdown";
import { getNameInitials } from "../../../cargotic-webapp/utility/common";

const getVehicleNme = (manufacturer, model, licensePlate) => {
  const name = [];
  if (manufacturer) {
    name.push(manufacturer)
  }
  if (model) {
    name.push(model)
  } 
  if (licensePlate) {
    name.push(licensePlate)
  }

  return name.join(', ');
}

const VehiclesFilterDropdown = ({
  placeholderTitle,
  onChange,
  value,
  selectAll,
  search,
  getTitle,
  onClear,
  disabled = false
}) => (
    <FilterDynamicSuggestionDropdown
      placeholderTitle={placeholderTitle}
      onChange={onChange}
      value={value}
      disabled={disabled}
      selectAll={selectAll}
      renderItem={({ id, manufacturer, model, licensePlate }, checked, handleChange) => (
        <ListItem button key={id} onClick={() => handleChange({ target: { checked: !checked } })}>
          <ListItemText primary={getVehicleNme(manufacturer, model, licensePlate)} />
          <ListItemSecondaryAction>
            <Checkbox
              color="primary"
              checked={checked}
              edge="end"
              onChange={handleChange}
            />
          </ListItemSecondaryAction>
        </ListItem>)}
      search={search}
      getTitle={getTitle}
      onClear={onClear}
    />
  );

export default VehiclesFilterDropdown;
