import React from "react";

import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import { Cancel, Error, CheckCircle } from "@material-ui/icons";
import { isDue, getOverdueDays } from "../../../cargotic-webapp/utility/common";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import { formatDate } from '@cargotic/common-deprecated/date'
import { cs, enUS } from "date-fns/locale";

const useStyles = makeStyles(({ palette }) => ({
  warning: {
    fill: "silver"
  },
  error: {
    fill: palette.error.main,
    color: palette.error.main
  },
  success: {
    fill: "#009688"
  }
}));

const PayIconButton = ({ name, isPaid, paidAt, dueDate, onPay }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;

  return (
    isPaid ? (
      <Tooltip title={t("invoices.invoice.beenPaid", { paidAt: formatDate(parseISO(paidAt), "dd.MM.yyyy", locale) })}>
        <IconButton
          size="small"
          onClick={onPay}>
          <CheckCircle className={classes.success} />
        </IconButton>
      </Tooltip>)
      : isDue(paidAt) ? (
        <Tooltip title={t("invoices.invoice.overdue", { days: getOverdueDays(dueDate) })}>
          <IconButton
            size="small"
            onClick={onPay}>
            <Error className={classes.error} />
          </IconButton>
        </Tooltip>) : (
        <Tooltip title={t("invoices.invoice.unpaid")}>
          <IconButton
            size="small"
            onClick={onPay}>
            <Cancel className={classes.warning} />
          </IconButton>
        </Tooltip>
      ));
}

export default PayIconButton;