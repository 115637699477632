import {Check, PriorityHighOutlined} from "@material-ui/icons";
import React from "react";

const checkIfAllPackagesReceived = (order) => {
	return order.warehouseOrder.cargoItemPackages.every(cargoItemPackage => !!cargoItemPackage.receivedDate)
}

const WarehouseOrdersPackagesFulfilled = ({order}) => (
		<>
			{checkIfAllPackagesReceived(order) && <Check color="action"/>}
			{!checkIfAllPackagesReceived(order) && <PriorityHighOutlined color="secondary"/>}
		</>
);

export default WarehouseOrdersPackagesFulfilled;