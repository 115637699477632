import React, { useEffect, useState, useMemo } from "react";
import {
  AppBar, Box, Fab, IconButton, makeStyles, Tab, Tabs, Tooltip, Typography
} from "@material-ui/core";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import qs from 'qs'

import { ShipmentType } from "@cargotic/model-deprecated";

import { addUrlParam } from "../../../utility/window";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import useAuth from "../../hook/useAuth";
import ShipmentsContainer from "../Shipments";
import OutcomingOrdersContainer from "./OutcomingOrdersContainer";
import ImportDialog from "./ImportDialog";
import {updateContact, updateOrdersByImportingFromRinkai} from "../../../resource";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(({ palette, spacing }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)"
  },
  content: {
    padding: spacing(1)
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function TabPanel(props, classes) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.content}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const TABS = [
  { id: 0, labelKey: "outcomingOrders.carried", type: ShipmentType.CARRIED },
  { id: 1, labelKey: "outcomingOrders.forwarded", type: ShipmentType.FORWARDED }
];

const IMPORT_TABS = [
  { id: 0, labelKey: "orders.import.main", type: "import" },
  { id: 1, labelKey: "orders.import.api", type: "api" }
];

const useTabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    color: "#333",
    boxShadow: "none"
  },
  tab: {
    borderColor: "#333"
  }
}));

const OutcomingOrdersView = () => {
  const { location: { pathname }, history } = useRouter();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    type: requestedShipmentType = ShipmentType.CARRIED
  } = useMemo(
    () =>
      qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      }),
    [history]
  );

  const [selectedTab, setSelectedTab] = useState(TABS.find((tab) => tab.type === requestedShipmentType) || TABS[0]);
  const tabClassess = useTabStyles();
  const [selectedImportTab, handleSetSelectedImportTab] = useState(IMPORT_TABS.find((tab) => tab.type === "import") || IMPORT_TABS[0]);
  const { enqueueSnackbar } = useSnackbar();
  const [isRinkaiImportDone, setIsRinkaiImportDone] = useState(false);
  const [isRinkaiImportProcessing, setIsRinkaiImportProcessing] = useState(false);
  const [selectValue, setSelectValue] = useState('rinkai');

  const handleTabChange = (event, newTabId) => {
    setSelectedTab(TABS.find((tab) => tab.id === newTabId));
  };

  const { user, hasPermission } = useAuth();
  const canUpdateShipment = hasPermission("resource.shipment.company.update")
    || hasPermission("resource.shipment.user.update");
  const canCreateSInvoice = hasPermission("resource.shipment.invoice.create");
  const canCreateShipment = hasPermission("resource.shipment.user.create");
  const canDeleteShipment = hasPermission("resource.shipment.user.delete")
    || hasPermission("resource.shipment.company.delete");
  const canReadSInvoice = hasPermission("resource.shipment.invoice.read");
  const canReadCompanyShipment = hasPermission("resource.shipment.company.read");

  const canUpdateOutcomingOrder = hasPermission("resource.outcomingOrder.company.update")
    || hasPermission("resource.outcomingOrder.user.update");
  const canCreateOOInvoice = hasPermission("resource.outcomingOrder.invoice.create");
  const canCreateOutcomingOrder = hasPermission("resource.outcomingOrder.user.create");
  const canCreateOOOrderPdf = hasPermission("resource.outcomingOrder.summary.read");
  const canDeleteOutcomingOrder = hasPermission("resource.outcomingOrder.user.delete")
    || hasPermission("resource.outcomingOrder.company.delete");
  const canReadOOInvoice = hasPermission("resource.outcomingOrder.invoice.read");
  const canReadCompanyOutcomingOrder = hasPermission("resource.outcomingOrder.company.read");
  const canOperateRinkai = hasPermission("resource.rinkai.all");

  const [loading, setLoading] = useState(true);
  const [shipments, setShipments] = useState([]);
  const [outcomingOrders, setOutcomingOrders] = useState([]);
  const [selected, setSelected] = useState(0);
  const [rinkaiDepo, setRinkaiDepo] = useState(null);
  const [
    isImportDialogOpen,
    setIsImportDialogOpen
  ] = useState(false);

  const closeImportDialog = () => setIsImportDialogOpen(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleShipmentsExportRequest = () => {};

  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleRinkaiDepoInputChange = (e) => {
    setRinkaiDepo(e.target.value);
  };

  const importFromRinkai = () => {
    setIsRinkaiImportProcessing(true);
    const { id: planId, alias} = rinkaiDepo;
    return updateOrdersByImportingFromRinkai(planId, alias)
      .then((r) => {
        if (r.data.errors) {
          r.data.errors.map(({ translation, values }) => enqueueSnackbar(t(translation, values), {
            variant: "error",
            persist: true
          }));
        } else {
          enqueueSnackbar(t('orders.import.success'), {
            variant: "success"
          });
          closeImportDialog();
          window.location.reload();
        }
        setIsRinkaiImportProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('orders.import.error.unknown'), {
          variant: "error",
          persist: true
        });
        setIsRinkaiImportProcessing(false);
      });
  };

  const handleShipmentsImportRequest = () => setIsImportDialogOpen(true);

  const handleOutcomingOrdersExportRequest = () => {};
  const handleDeleteShipmentRequest = () => setDeleteDialogOpen(true);
  const handleDeleteOutcomingOrderRequest = () => setDeleteDialogOpen(true);

  useEffect(() => setDeleteDialogOpen(false), [loading]);
  useEffect(() => {
    addUrlParam("type", selectedTab?.type);
  }, [selectedTab])

  return (
    <Page
      title={t("shipments.overview")}
    >
      <AppBar position="static" className={tabClassess.root}>
        <Tabs value={selectedTab?.id} onChange={handleTabChange} aria-label="tabs">
          {TABS.map((tab) => <Tab id={tab.id} label={t(tab.labelKey)} className={tabClassess.tab} {...a11yProps(tab.id)}/>)}
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab?.id} index={0} classes={classes}>
        <ShipmentsContainer
          setPageLoading={setLoading}
          setPageShipments={setShipments}
          setPageSelected={setSelected}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          handleShipmentsImportRequest={handleShipmentsImportRequest}
          handleDeleteShipmentRequest={handleDeleteShipmentRequest}
        />
      </TabPanel>
      <TabPanel value={selectedTab?.id} index={1}>
        <OutcomingOrdersContainer
          setPageLoading={setLoading}
          setPageOutcomingOrders={setOutcomingOrders}
          setPageSelected={setSelected}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          handleOutcomingOrdersExportRequest={handleOutcomingOrdersExportRequest}
          handleDeleteOutcomingOrderRequest={handleDeleteOutcomingOrderRequest}
        />
      </TabPanel>
      <ImportDialog
        infoText={t("shipments.import.info.text")}
        title={t("shipments.import.title")}
        closeButtonLabel={t("orders.import.close")}
        processingText={t("orders.import.processing")}
        doneText={t("orders.import.done.text")}
        isDone={isRinkaiImportDone}
        startButtonLabel={t("orders.import.start")}
        isOpen={isImportDialogOpen}
        isProcessing={isRinkaiImportProcessing}
        close={closeImportDialog}
        tabs={IMPORT_TABS}
        canOperateRinkai={canOperateRinkai}
        selectValue={selectValue}
        rinkaiDepo={rinkaiDepo}
        setRinkaiDepo={setRinkaiDepo}
        setSelectedValue={setSelectValue}
        setSelectedImportTab={handleSetSelectedImportTab}
        selectedImportTab={selectedImportTab}
        importFromRinkai={importFromRinkai}
        handleSelectChange={handleSelectChange}
        handleRinkaiDepoInputChange={handleRinkaiDepoInputChange}
      />
    </Page>
  );
};

export default OutcomingOrdersView;
