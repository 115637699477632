import React, { useEffect, useState } from "react";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";
import Shipment from "../Shipment";
import IncomingOrderMultiloading from "../IncomingOrderMultiloading";
import useRouter from "../../../component/hook/useRouter";

enum View {
    NONE,
    SHIPMENT,
    INCOMING_ORDER_MULTILOADING
}

const ShipmentView = () => {
  const { shipment } = useShipmentConcept();
  const { match: { params: { id } } } = useRouter();
  const [view, setView] = useState(View.NONE);

  useEffect(() => {
    if (!view) {
      setView(
        id || shipment
          ? View.SHIPMENT
          : View.INCOMING_ORDER_MULTILOADING
      );
    }
  }, [view, shipment]);

  switch (view) {
    case View.SHIPMENT:
      return <Shipment />;

    case View.INCOMING_ORDER_MULTILOADING:
      return <IncomingOrderMultiloading />;

    default:
      return null;
    }
};

export default ShipmentView;
