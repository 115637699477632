import { ValueError, fromEntries, isEmpty } from "@cargotic/common-deprecated";

import { Validate } from "./validate";

class ObjectValidationError<
  T,
  E extends {
    [key in keyof T]?: ValueError
  } = { [key in keyof T]?: ValueError }
> extends ValueError {
  errors: E

  constructor(errors: E) {
    super();

    this.errors = errors;
  }
}

function createObjectValidator<
  T,
  K extends keyof T = keyof T,
  R extends { [key in keyof T]: unknown } = { [key in keyof T]: unknown },
  V extends {
    [key in keyof T]: Validate<T[K], R[K]>
  } = { [key in keyof T]: Validate<T[K], R[K]> }
>(validators: V): Validate<T, R> {
  return (object) => {
    const result = Object
      .entries(object)
      .map((entry) => {
        const [key, value] = entry as unknown as [K, T[K]];

        const validate = validators[key];

        if (!validate) {
          return entry;
        }

        try {
          const validated = validate(value);

          return [key, validated];
        } catch (error) {
          if (!(error instanceof ValueError)) {
            throw error;
          }

          return [key, error];
        }
      });

    const errors = result
      .filter((entry) => {
        const [, value] = entry;

        return value instanceof ValueError;
      }) as [K, ValueError][];

    if (!isEmpty(errors)) {
      throw new ObjectValidationError(fromEntries(errors));
    }

    return fromEntries(result as [K, R[K]][]);
  };
}

function isObject(value: any, message?: string): Object {
  if (typeof value !== "object") {
    throw new ValueError(message);
  }

  return value;
}


export {
  ObjectValidationError,

  createObjectValidator,
  isObject
};
