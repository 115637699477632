import { ArrowDownward, ArrowForward, ArrowUpward } from "@material-ui/icons";

import { CargoItemAction } from "../../cargotic-core";

const getCargoItemActionIcon = (action) => {
  switch (action) {
  case CargoItemAction.IDLE: {
    return ArrowForward;
  }

  case CargoItemAction.LOAD: {
    return ArrowUpward;
  }

  case CargoItemAction.UNLOAD: {
    return ArrowDownward;
  }
  }
};

export default getCargoItemActionIcon;
