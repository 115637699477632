import React from "react";

import { makeStyles } from "@material-ui/core";
import { ShipmentActivityType } from "@cargotic/model";
import { replaceIndex } from "@cargotic/common";

import { useSnackbar } from "notistack";

import useAuth from "../cargotic-webapp/component/hook/useAuth";
import ShipmentActivityTimeline from "../cargotic-webapp/component/core/ShipmentActivityTimeline";

import client from "../cargotic-webapp/client";

import {
  postIncomingOrderCommentar,
  putIncomingOrderCommentar,
  deleteIncomingOrderCommentar,
  postOutcomingOrderCommentar,
  putOutcomingOrderCommentar,
  deleteOutcomingOrderCommentar,
} from "../cargotic-webapp/resource";
import { parseISO } from "date-fns";

const useStyles = makeStyles(({ spacing }) => ({
  tabContentContainer: {
    paddingTop: spacing(1),
  },
}));

const ActivityTab = ({
  entityType,
  data,
  shipment: propsShipment,
  incomingOrder,
  outcomingOrder,
  activity,
  setActivity,
}) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  // Temporary shipment -> order solution
  const shipment = propsShipment || incomingOrder || outcomingOrder;

  const handleCommentaryChange = async (commentaryId, content) => {
    try {
      let res;
      if (entityType === "SHIPMENT") {
        res = await client.shipment.putShipmentCommentary({
          shipmentId: data.id,
          commentaryId,
          commentary: {
            content,
          },
        });
      } else if (entityType === "INCOMING_ORDER") {
        res = await putIncomingOrderCommentar({
          incomingOrderId: data.id,
          commentaryId,
          content,
        });
        res.createdAt = Date.parse(res.createdAt);
      } else if (entityType === "OUTCOMING_ORDER") {
        res = await putOutcomingOrderCommentar({
          outcomingOrderId: data.id,
          commentaryId,
          content,
        });
        res.createdAt = Date.parse(res.createdAt);
      }

      setActivity((current) => {
        const index = current.findIndex(
          (other) => other.commentary?.id === commentaryId
        );

        const updated = replaceIndex(current, index, {
          type: `${entityType}_COMMENTARY`,
          commentary: {
            author: { ...user, name: `${user.firstName} ${user.lastName}` },
            id: commentaryId,
            createdAt: res.createdAt,
            content,
          },
        });

        return updated;
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar("An error has occurred while ", {
        variant: "error",
      });
    }
  };

  const handleCommentaryDelete = async (commentaryId) => {
    try {
      if (entityType === "SHIPMENT") {
        await client.shipment.deleteShipmentCommentary({
          shipmentId: data.id,
          commentaryId,
        });
      } else if (entityType === "INCOMING_ORDER") {
        await deleteIncomingOrderCommentar({
          incomingOrderId: data.id,
          commentaryId,
        });
      } else if (entityType === "OUTCOMING_ORDER") {
        await deleteOutcomingOrderCommentar({
          outcomingOrderId: data.id,
          commentaryId,
        });
      }

      setActivity((current) =>
        current.filter((other) => other.commentary?.id !== commentaryId)
      );
    } catch (error) {
      console.log(error);

      enqueueSnackbar("An error has occurred while deleting your commentary!", {
        variant: "error",
      });
    }
  };

  const handleCommentarySubmit = async (content) => {
    try {
      let res;
      if (entityType === "SHIPMENT") {
        res = await client.shipment.postShipmentCommentary({
          shipmentId: data.id,
          commentary: { content },
        });
      } else if (entityType === "INCOMING_ORDER") {
        res = await postIncomingOrderCommentar({
          incomingOrderId: data.id,
          content,
        });
        res.createdAt = parseISO(res.createdAt);
      } else if (entityType === "OUTCOMING_ORDER") {
        res = await postOutcomingOrderCommentar({
          outcomingOrderId: data.id,
          content,
        });
        res.createdAt = parseISO(res.createdAt);
      }
      setActivity((current) => [
        ...current,
        {
          type: ShipmentActivityType.SHIPMENT_COMMENTARY,
          commentary: {
            id: res.id,
            author: { ...user, name: `${user.firstName} ${user.lastName}` },
            createdAt: res.createdAt,
            content,
          },
        },
      ]);
    } catch (error) {
      console.log(error);

      enqueueSnackbar("An error has occurred while posting your commentary!", {
        variant: "error",
      });
    }
  };

  return (
    <div className={classes.tabContentContainer}>
      {activity.length !== 0 ? (
        <ShipmentActivityTimeline
          activity={activity}
          user={{
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            href: "@",
            avatarUrl: user.avatarUrl,
          }}
          onCommentaryChange={handleCommentaryChange}
          onCommentaryDelete={handleCommentaryDelete}
          onCommentarySubmit={handleCommentarySubmit}
        />
      ) : null}
    </div>
  );
};

export default ActivityTab;
