import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

import {
  Typography,
  makeStyles,
  Grid,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import {
  Edit,
  Check,
  Close,
  Error,
  AccessTime,
  Warning,
  AttachMoney,
} from "@material-ui/icons";
import { teal, red } from "@material-ui/core/colors";

import {
  mapAndFormatWaypoints,
  getDrivenThroughAtText,
  getDrivenThroughText,
} from "../../../cargotic-webapp/utility/waypoint";

import client from "../../../cargotic-webapp/client";
import CargoItemList from "../../../../multiload/cargotic-component/component/CargoItemList";
import {
  postIncomingOrderWaypointDriveThrough,
  deleteIncomingOrderWaypointDriveThrough,
} from "@cargotic/webapp/resource";
import { CargoActionType } from "@cargotic/model";
import WaypointType from "../../../cargotic-webapp/utility/WaypointType";
import {
  formatDateTime,
  parseDate,
} from "../../../../packages/cargotic-webapp/utility/common";

const useStyles = makeStyles(({ spacing }) => ({
  isDrivenThrough: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  isDrivenThroughIcon: {
    marginLeft: 5,
    marginRight: 15,
    width: 18,
    height: 18,
  },
  error: {
    color: "red",
  },
  waypointAddress: {
    marginLeft: "4px",
  },
}));

export function Waypoint({
  key,
  waypoint,
  entityType,
  data,
  errorAction,

  isDriveThroughLoading,
  setUpdatingWaypoint,

  setUpdateDriveThroughDialogOpen,

  onChangeDriveThrough,
  handleOpenSetDrivenThroughDialog,
  handleOpenSetNotDeliveredThroughDialog,

  // handleOpenUpdateDrivenThroughTimeDialog,

  handleOpenUpdateDrivenThroughDialog,

  deleteWaypointDriveThrough,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let formattedWaypoint = useMemo(() => {
    return mapAndFormatWaypoints([waypoint], true, false)[0];
  }, [waypoint]);

  if (entityType === "WAREHOUSE_ORDER") {
    formattedWaypoint = {
      ...formattedWaypoint,
      cargo: formattedWaypoint.cargo.map((c) => ({
        ...c,
        packages: c.packages.map((p) => {
          const pck = data.warehouseOrder.cargoItemPackages.find((item) => item.id === p.packageId);
          return {
            ...p,
            receivedBy: pck.receivedBy,
            receivedDate: pck.receivedDate,
            dispatchedBy: pck.dispatchedBy,
            dispatchedDate: pck.dispatchedDate,
          };
      })}))
    }
  };

  const getPackageLabel = useCallback(
    (pckg, indexNumber) => {
      if (entityType === "SHIPMENT") {
        return `${indexNumber}${pckg?.sequence.toString().padStart(2, "0")}`;
      }

      if (entityType === "OUTCOMING_ORDER") {
        return `${indexNumber}${pckg?.sequence.toString().padStart(2, "0")}`;
      }

      if (entityType === "INCOMING_ORDER") {
        return `${data.indexNumber}${pckg?.sequence
          .toString()
          .padStart(2, "0")}`;
      }

      if (entityType === "WAREHOUSE_ORDER") 
      {
        return `${data.indexNumber}${pckg?.sequence
          .toString()
          .padStart(2, "0")}`;
      }

      return pckg?.id;
    },
    [data]
  );

  const {
    placeAlias,
    placeName,
    action,
    formattedPlace,
    formattedDateTime,
    isDrivenThrough, // shipment context only
    drivenThroughAt, // shipment context only
    cargo,
    contact,
    phoneNumber,
    note,
  } = formattedWaypoint || {};
  // because of (incomingOrder vs shipment) context
  const [isWaypointDrivenThrough, waypointDrivenThroughAt] =
    cargo.reduce(
      (acc, value) => {
        const [cargoDrivenThrough, cargoDrivenTheoughAt] =
          value.packages.reduce(
            (acc2, value2) => {
              const { isDrivenThrough, drivenThroughAt } = value2;
              const [acc2DrivenThrough, acc2DrivenThroughAt] = acc2;
              return [
                acc2DrivenThrough || isDrivenThrough,
                new Date(drivenThroughAt) || acc2DrivenThrough,
              ];
            },
            [false, undefined]
          );
        const [accDrivenThrough, accDrivenThroughAt] = acc;
        return [
          accDrivenThrough || cargoDrivenThrough,
          accDrivenThroughAt || cargoDrivenTheoughAt,
        ];
      },
      [false, undefined]
    ) || isDrivenThrough;

  const isNotDelivered =
    cargo.reduce((acc, value) => {
      return acc || value.incomingOrder?.reasonNotDelivered != undefined;
    }, false) || data?.reasonNotDelivered != undefined;

  return (
    <div
      key={key}
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container item alignContent="center" alignItems="center">
        <Grid item xs={5}>
          <Typography className={classes.waypointAddress} variant="body2">
            {placeAlias}
          </Typography>
          <Typography className={classes.waypointAddress} variant="body2">
            <i>{placeName}</i>
          </Typography>
          <Typography className={classes.waypointAddress} variant="body2">
            <b>{formattedPlace}</b>
          </Typography>
          <Typography variant="body1">
            <i>{contact ? `${t("shipment.contact")}: ${contact}` : null}</i>
          </Typography>
          <Typography variant="body1">
            <i>
              {phoneNumber
                ? `${t("webapp:common.title.phone-number")}: ${phoneNumber}`
                : null}
            </i>
          </Typography>
          <Typography variant="body1">
            <i>{note ? `${t("webapp:common.title.note")}: ${note}` : null}</i>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">{formattedDateTime}</Typography>
        </Grid>
        <Grid item xs={5}>
          {entityType === "INCOMING_ORDER" && data?.reasonNotDelivered &&
            action === WaypointType.UNLOADING ? (
            <>
              <Box ml={1}>
                <Error className={classes.error} fontSize="small" />
              </Box>
              <Box>
                <Typography>
                  {t(
                    `webapp:incomingOrder.undeliveredReason.${data?.reasonNotDelivered}`
                  )}
                </Typography>
              </Box>
            </>
          ) : undefined}
          {entityType === "INCOMING_ORDER" && data?.paidCOD && action === WaypointType.UNLOADING ? (
            <>
              <Box ml={1}>
                <Typography variant="body2">
                  {t("webapp:incomingOrder.form.label.paidAt")} {data?.paidCOD.price}{" "}
                  {data?.paidCOD.currency}
                </Typography>
                <Typography variant="body2">
                  {t("webapp:incomingOrder.form.label.method")}{" "}
                  {t(
                    `webapp:incomingOrder.codPaymentMethod.${data?.paidCOD.method}`
                  )}
                  , {formatDateTime(parseDate(data?.paidCOD.paidAt))}
                </Typography>
              </Box>
            </>
          ) : undefined}
          {isDrivenThrough ||
            isWaypointDrivenThrough ||
            (isNotDelivered && data && entityType === "SHIPMENT" && action === WaypointType.UNLOADING) ? (
            <div className={classes.isDrivenThrough}>
              <Check
                className={classes.isDrivenThroughIcon}
                style={{ color: teal[700] }}
              />
              <Typography style={{ marginRight: 10 }} variant="body2">
                {getDrivenThroughAtText(
                  t,
                  action,
                  drivenThroughAt || waypointDrivenThroughAt,
                  isNotDelivered
                )}
              </Typography>

              <IconButton
                size="small"
                disabled={isDriveThroughLoading}
                onClick={
                  isNotDelivered
                    ? () => handleOpenSetNotDeliveredThroughDialog(true)
                    : () => handleOpenUpdateDrivenThroughDialog(waypoint)
                }
              >
                <Edit color="action" />
              </IconButton>

              <IconButton
                size="small"
                disabled={isDriveThroughLoading}
                onClick={() => deleteWaypointDriveThrough(waypoint)}
              >
                <Close color="action" />
              </IconButton>
            </div>
          ) : !data?.isDraft ? (
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={isDriveThroughLoading}
                onClick={
                  cargo?.length > 0
                    ? () => handleOpenSetDrivenThroughDialog(waypoint)
                    : () => onChangeDriveThrough(waypoint)
                }
              >
                {getDrivenThroughText(t, action)}
              </Button>
              {action === WaypointType.UNLOADING ? (
                <Button
                  variant="contained"
                  color="default"
                  disabled={isDriveThroughLoading}
                  onClick={() =>
                    handleOpenSetNotDeliveredThroughDialog(waypoint)
                  }
                >
                  {t("webapp:incomingOrder.undeliveredTitle")}
                </Button>
              ) : null}
            </>
          ) : null}
        </Grid>
        <CargoItemList
          cargo={cargo}
          expandableDetail={true}
          getPackageLabel={getPackageLabel}
        />
      </Grid>
    </div>
  );
}

Waypoint.propTypes = {
  key: PropTypes.string.isRequired,
  waypoint: PropTypes.object.isRequired,
  errorAction: PropTypes.number.isRequired,
  isDriveThroughLoading: PropTypes.bool.isRequired,
  setUpdatingWaypoint: PropTypes.func.isRequired,
  setUpdateDriveThroughDialogOpen: PropTypes.func.isRequired,
  onChangeDriveThrough: PropTypes.func.isRequired,
  handleOpenSetDrivenThroughDialog: PropTypes.func.isRequired,
  handleOpenUpdateDrivenThroughDialog: PropTypes.func.isRequired,
  deleteWaypointDriveThrough: PropTypes.func.isRequired,
};

export default Waypoint;
