
import React from "react";

import WaypointType from "../../../../utility/WaypointType";
import {
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
  SwapVert,
} from "@material-ui/icons";
import { Tooltip, useTheme } from "@material-ui/core";

const BoardCardWaypointIcon = ({ t, action }) => {
  const theme = useTheme();
  switch (action) {
    case WaypointType.LOADING:
      return (
        <Tooltip title={t("shipments.action.loading")}>
          <KeyboardArrowUp style={{ color: theme.palette.primary.main }} />
        </Tooltip>
      );
    case WaypointType.UNLOADING:
      return (
        <Tooltip title={t("shipments.action.unloading")}>
          <KeyboardArrowDown style={{ color: theme.palette.warning.light }} />
        </Tooltip>
      );
    case WaypointType.NONE:
      return (
        <Tooltip title={t("shipments.action.none")}>
          <KeyboardArrowRight color="action" />
        </Tooltip>
      );
    case WaypointType.BOTH:
      return (
        <Tooltip title={t("shipments.action.both")}>
          <SwapVert color="action" />
        </Tooltip>
      );
    default:
      throw new Error("Wrong cargo action");
  }
}

export default BoardCardWaypointIcon;