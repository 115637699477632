import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationAlert } from "../../../../../cargotic-webapp-component";

const StateDeleteConfirmationAlert = ({
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationAlert
      title={t("webapp:settings.title.delete-state")}
      content={t("webapp:settings.prompt.delete-state")}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default StateDeleteConfirmationAlert;
