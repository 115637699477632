import clsx from "clsx";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { PhotoCamera as PhotoCameraIcon } from "@material-ui/icons";

import { RouterBreadcrumbs } from "../../../cargotic-webapp-component";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  invrtedColor: {
    "& button": {
      color: palette.common.white
    }
  },
  input: {
    display: "none"
  }
}));

const VehicleProfileHeaderToolbar = ({
  breadcrumbs,
  hasInvertedColor,
  onAvatarChange
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef();

  const handleAvatarInputChange = ({ target: { files } }) => {
    if (onAvatarChange) {
      onAvatarChange(files[0]);
    }

    inputRef.current.value = null;
  };

  const handleAvatarUploadButtonClick = event => (
    inputRef.current.click()
  );

  return (
    <div className={
      clsx(
        classes.root,
        {
          [classes.invrtedColor]: hasInvertedColor
        }
      )}
    >
      <section>
        <RouterBreadcrumbs
          value={breadcrumbs}
          hasInvertedColor={hasInvertedColor}
        />
      </section>
      <section>
        <Tooltip title={t("webapp:vehicle.title.uploadPhoto")}>
          <IconButton onClick={handleAvatarUploadButtonClick}>
            <PhotoCameraIcon />
          </IconButton>
        </Tooltip>
        <input
          ref={inputRef}
          className={classes.input}
          type="file"
          accept="image/jpeg,image/png"
          onChange={handleAvatarInputChange}
        />
      </section>
    </div>
  );
};

export default VehicleProfileHeaderToolbar;
