import React, {useState} from "react";

import {Button, Menu, MenuItem} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {updateWarehouseOrderState} from "../../../../resource";

const WarehouseOrderState = ({ warehouseOrder, reload, loadAvailableStates, state }) => {

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateState = async (state) => {
    try {
      await updateWarehouseOrderState(warehouseOrder.id, state);
      reload();
    } catch (err) {
      console.log(err);
    }
  };

  const statusSource = state.map(e => ({title: e.name, value: e.stateId }))

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {statusSource.map((state) => {
          if (state.value === warehouseOrder.warehouseOrder.state.id) {
            return state.title;
          }
          return "";
        })}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statusSource.map((state) => (
          <MenuItem
            key={state.value}
            onClick={() => {
              updateState(state.value);
              handleClose();
            }}
          >
            {state.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default WarehouseOrderState;



// THIS CODE WILL BE REMOVED IN NEAR FUTURE

// const WarehouseOrderState = ({order}) => {
// 	const [anchorEl, setAnchorEl] = React.useState(null);
// 	const handleClick = (event) => {
// 		setAnchorEl(event.currentTarget);
// 	};
//
// 	const handleClose = () => {
// 		setAnchorEl(null);
// 	};
//
// 	const updateState = async (state) => {
// 		try {
// 			await updateWarehouseOrderState(order.id, state);
// 			await reload();
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	};
//
// 	const statusSource = [
// 		{title: t("board.warehouseState.accepted"), value: WarehouseOrderStateEnum.ACCEPTED},
// 		{title: t("board.warehouseState.planned"), value: WarehouseOrderStateEnum.PLANNED},
// 		{title: t("board.warehouseState.ready"), value: WarehouseOrderStateEnum.READY},
// 	];
// 	return (
// 			<>
// 				<Button
// 						aria-controls="simple-menu"
// 						aria-haspopup="true"
// 						onClick={handleClick}
// 				>
// 					{statusSource.map((state) => {
// 						if (state.value === order.warehouseOrder.state) {
// 							return state.title;
// 						}
// 						return "";
// 					})}
// 				</Button>
// 				<Menu
// 						id="simple-menu"
// 						anchorEl={anchorEl}
// 						keepMounted
// 						open={Boolean(anchorEl)}
// 						onClose={handleClose}
// 				>
// 					{statusSource.map((state) => (
// 							<MenuItem
// 									key={state.value}
// 									onClick={() => {
// 										updateState(state.value);
// 										handleClose();
// 									}}
// 							>
// 								{state.title}
// 							</MenuItem>
// 					))}
// 				</Menu>
// 			</>
// 	);
// };