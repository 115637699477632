import { Locale, format } from "date-fns";

const DEFAULT_DATE_TEMPLATE = "dd. MMMM HH:mm";

function formatDate(
  date: Date,
  template = DEFAULT_DATE_TEMPLATE,
  locale?: Locale
): string {
  return format(date, template, { locale });
}

export {
  // eslint-disable-next-line
  formatDate
};
