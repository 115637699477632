import React, { useState, useRef } from "react";
import { Slide, makeStyles } from "@material-ui/core";

import client from "../../../client";
import Axios from "axios"
import config from "../../../config";
import TrackingCard from "./TrackingCard";

const useStyles = makeStyles(() => ({
  slideRoot: {
    zIndex: 2,
    minWidth: "23%",
    position: "fixed",
    maxHeight: "100%",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
}));

const TrackingSideMenu = ({
  geolocationLoading,
  tracking,
  hoveredTrackId,
  setHoveredTrackId,
  expandingCard,
  setExpandingCard,
  onTrackingPathChange
}) => {
  const classes = useStyles();
  return (
    <Slide in={!geolocationLoading} direction="right" >
      <div className={classes.slideRoot}>
        {tracking.map((tracking, index) =>
          <TrackingCard
            key={`tracking-${tracking.id}`}
            classes={classes}
            tracking={tracking}
            hoveredTrackId={hoveredTrackId}
            setHoveredTrackId={setHoveredTrackId}
            onTrackingPathChange={onTrackingPathChange}
            expandingCard={expandingCard}
            setExpandingCard={setExpandingCard}
          />
        )}
        <div style={{ height: 55 }} />
      </div>
    </Slide>
  );
}
export default TrackingSideMenu;