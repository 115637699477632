import clsx from "clsx";
import React, { forwardRef } from "react";

import { IconButton, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    border: `1px solid ${palette.primary.main}`,
    borderRadius: 80
  },
  disabled: {
    borderColor: palette.action.disabled
  }
}));

const OutlinedIconButton = forwardRef(({
  children,
  color = "primary",
  disabled,
  ...props
}, ref) => {
  const { palette } = useTheme();
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={clsx(classes.root, { [classes.disabled]: disabled })}
    >
      <IconButton
        color={color}
        disabled={disabled}
        {...props}
      >
        {children}
      </IconButton>
    </div>
  );
});

export default OutlinedIconButton;
