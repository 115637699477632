import React, { useCallback, useMemo } from "react";

import { makeStyles, Button, Menu, MenuItem } from "@material-ui/core";

import {
  updateShipmentState
} from "../../../resource";
import TagColors from "../../../utility/TagColors";

export function ShipmentState({ shipment, state, reload }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const updateState = useCallback(
    async (state) => {
      try {
        const res = await updateShipmentState(shipment.id, state);
        await reload();
      } catch (err) {
        console.error(err);
      }
    },
    [updateShipmentState, reload, shipment]
  );

  const statusSource = useMemo(
    () => state.map((e) => ({ title: e.name, value: e.id, tag: e.tag })),
    [state]
  );

  const selectedStatus = statusSource.find((status) => status.value === shipment.stateId);
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: TagColors[selectedStatus.tag]}}
      >
        {selectedStatus.title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statusSource.map((state) => (
          <MenuItem
            key={state.value}
            onClick={() => {
              updateState(state.value);
              handleClose();
            }}
            style={{
              backgroundColor: TagColors[state.tag]
            }}
          >
            {state.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ShipmentState;
