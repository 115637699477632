class IndexOutOfBoundsError extends Error {
  constructor() {
    super();

    this.name = this.constructor.name;
  }
}

class InvalidStateError extends Error {
  constructor() {
    super();

    this.name = this.constructor.name;
  }
}

class NoSuchElementError extends Error {
  constructor() {
    super();

    this.name = this.constructor.name;
  }
}

class NotImplementedError extends Error {
  constructor() {
    super();

    this.name = this.constructor.name;
  }
}

class ValueError extends Error {
  message: string;

  constructor(message?: string) {
    super();

    this.name = this.constructor.name;
    this.message = message ?? "";
  }
}

export {
  IndexOutOfBoundsError,
  InvalidStateError,
  NoSuchElementError,
  NotImplementedError,
  ValueError
};
