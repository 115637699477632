import * as Yup from "yup";

import { ShipmentInvoiceType } from "@cargotic/webapp/component/enums/enums";

export const getUserFormValidationSchema = (t) =>
    Yup.object({
        dic: Yup.string().nullable(),
        ic: Yup.string().nullable(),
        id: Yup.number().required(t("invoices.invoice.validation.required")),
        name: Yup.string().required(t("invoices.invoice.validation.required")),
        assignedCount: Yup.number(),
        email: Yup.string().email().nullable()
    });

export const getInvoiceFormValidationSchema = (t, invoiceType) =>
  Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        dph: Yup.string().required(t("invoices.invoice.validation.required")),
        dphType: Yup.string().required(
          t("invoices.invoice.validation.required")
        ),
        count: Yup.number()
          .required(t("invoices.invoice.validation.required"))
          .typeError(t("invoices.invoice.validation.number")),
        unit: Yup.string().nullable(),
        unitPrice: Yup.number()
          .typeError(t("invoices.invoice.validation.number"))
          .required(t("invoices.invoice.validation.required")),
        dphInclusion: Yup.string().required(
          t("invoices.invoice.validation.required")
        ),
        description: Yup.string().nullable(),
      })
    ),
    externalReference:
      invoiceType === ShipmentInvoiceType.RECEIVED
        ? Yup.string().required(t("invoices.invoice.validation.required"))
        : Yup.string().nullable(),
    contact: getUserFormValidationSchema(t).required(
      t("invoices.invoice.validation.required")
    ),
    releaseDate: Yup.string().required(
      t("invoices.invoice.validation.required")
    ),
    duzp: Yup.string().required(t("invoices.invoice.validation.required")),
    dueDate: Yup.string().required(t("invoices.invoice.validation.required")),
    bankAccountId: Yup.string().required(
      t("invoices.invoice.validation.required")
    ),
    paymentMethod: Yup.string().required(
      t("invoices.invoice.validation.required")
    ),
    vs: Yup.string(),
    note: Yup.string().nullable(),
    currency: Yup.string().required(t("invoices.invoice.validation.required")),
  });
