import React, { useEffect, useState } from "react";

import config from "../../../config";
import ReconnectingWebSocket from "../../../reconnecting-websocket";
import BoltClientContext from "../../context/BoltClientContext";
import useAuth from "../../hook/useAuth";

const BoltClientProvider = ({ children }) => {
  const { authenticated } = useAuth();
  const [client, setClient] = useState();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (authenticated) {
      const ws = new ReconnectingWebSocket(config.bolt.url);

      ws.onopen = () => setIsReady(true);
      ws.onclose = () => setIsReady(false);

      setClient(ws);
    } else if (client) {
      client.close();
    }
  }, [authenticated]);

  return (
    <BoltClientContext.Provider value={{ isReady, client }}>
      {children}
    </BoltClientContext.Provider>
  );
};

export default BoltClientProvider;
