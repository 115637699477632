import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { useTranslation } from "react-i18next";


const DirectVehicleIncomeDeleteDialog = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("webapp:vehicle.title.delete-income")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("webapp:vehicle.prompt.delete-income")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={onSubmit} color="primary">
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DirectVehicleIncomeDeleteDialog;
