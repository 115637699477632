import { cs, enUS, sk } from "date-fns/locale";

const DATE_FNS_I18N = {
  cs,
  en: enUS,
  sk,

  "en-US": enUS,
  "cs-CZ": cs,
  "sk-SK": sk
};

const getDateFnsLocale = (language: string) => {
  if (!(language in DATE_FNS_I18N)) {
    //throw new Error(`Unsupported language '${language}'.`);
    language = 'en-US';
  }

  return DATE_FNS_I18N[language] as Locale;
};

export default getDateFnsLocale;
