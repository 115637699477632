import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { getTemplates } from "../../../../resource";

const ChooseTemplateDialog = ({
  isOpen,
  onClose,
  handleChooseTemplate,
  handleDeleteTemplate,
  compatibilityError,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const getTmp = async () => {
      const res = await getTemplates();
      if (res) setTemplates(res.templates);
    };
    getTmp();
  }, []);

  const handleDelete = async (e, templateID) => {
    e.stopPropagation();
    await handleDeleteTemplate(templateID);
    const res = await getTemplates();
    if (res) setTemplates(res.templates);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("importCSV.choose.label")}</DialogTitle>
      <DialogContent>
        <Box p="1" minWidth="250px;">
          {templates.length < 1 && (
            <Box width="100%" textAlign="center">
              {t("importCSV.choose.createFirst")}
            </Box>
          )}
          {compatibilityError && (
            <Box width="100%" textAlign="center">
              {t("importCSV.choose.incompatible")}
            </Box>
          )}
          <MenuList>
            {templates.map((t) => (
              <MenuItem key={t.id} onClick={() => handleChooseTemplate(t)}>
                <Box display="inline-flex" width="100%">
                  <Box width="100%">{t.name}</Box>
                  <IconButton
                    onClick={(e) => handleDelete(e, t.id)}
                    aria-label="delete"
                    size="small"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("abort")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseTemplateDialog;
