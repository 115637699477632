import React, {useEffect} from "react";

import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox
} from "@material-ui/core";

import FilterDynamicSuggestionDropdown from "../FilterDynamicSuggestionDropdown";
import FilterDynamicSuggestionDropdownBox from "../FilterDynamicSuggestionDropdownBox";
const BoxFilterDropdown = ({
  placeholderTitle,
  onChange,
  value,
  selectAll,
  search,
  getTitle,
  onClear,
  disabled = false
}) => {

  useEffect(() => {
    console.log('valuevalue', value);
  }, []);
  return (
    <FilterDynamicSuggestionDropdownBox
      placeholderTitle={placeholderTitle}
      onChange={onChange}
      value={value}
      disabled={disabled}
      selectAll={selectAll}
      renderItem={({ box }, checked, handleChange) => {
        return (
          <ListItem button key={box} onClick={() => handleChange({ target: { checked: !checked } })}>
            <ListItemText primary={box} />
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                checked={checked}
                edge="end"
                onChange={handleChange}
              />
            </ListItemSecondaryAction>
          </ListItem>);
      }}
      search={search}
      getTitle={getTitle}
      onClear={onClear}
    />
  )
};

export default BoxFilterDropdown;
