import React, { useEffect, useMemo, useState } from "react";
import { cs, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

import {
  Card, CardContent, CardHeader, Typography, makeStyles
} from "@material-ui/core";
import { formatDistance } from "date-fns";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: "#29AEA0",
    color: "#FFF",
    padding: spacing(1)
  },
  content: {
    "& > section": {
      display: "flex",
      justifyContent: "space-between"
    },
    marginBottom: 47,
    minHeight: 251
  }
}));

const useStyles2 = makeStyles(({ palette, spacing }) => ({
  root: {
    margin: 0,
    padding: spacing(1)
  }
}));

const ShipmentSummary = ({
  journey, shipment, apiClient
}) => {
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;

  const classes = useStyles();
  const classes2 = useStyles2();

  // const [incomingOrders, setIncomingOrders] = useState(null);

  // useEffect(() => {

  //   if (shipment && shipment.incomingOrders) {
  //     Promise.all(
  //       shipment.incomingOrders.map(
  //         ({ id: incomingOrderId }) => apiClient.incomingOrder.getIncomingOrderById({ incomingOrderId })
  //       )
  //     ).then(setIncomingOrders)
  //       .catch(() => setIncomingOrders(null));
  //   } else setIncomingOrders(null);
  // }, [shipment.incomingOrders]);

  // const commissionValue = (incomingOrders || []).reduce((a, { customerPrice }) => a + customerPrice, 0);

  const distance = (journey && journey.distance)
    ? `${(journey.distance / 1000).toFixed(2)} km`
    : t("webapp:outcomingOrder.summary.metric.unknown");

  const duration = (journey && journey.duration)
    ? formatDistance(new Date(journey.duration * 1000), new Date(0), { locale })
    : t("webapp:outcomingOrder.summary.metric.unknown");

  // const commission = commissionValue && incomingOrders[0].customerPriceCurrency
  //   ? `${commissionValue.toFixed(2)} ${incomingOrders[0].customerPriceCurrency}`
  //   : t("webapp:outcomingOrder.summary.metric.unknown");

  const metrics = [
    ["webapp:shipment.summary.label.distance", distance],
    ["webapp:shipment.summary.label.duration", duration],
    // ["webapp:shipment.summary.label.commission", commission]
  ];

  return (
    <Card className={classes.root}>
      <CardHeader title={t("webapp:shipment.summary.title")} classes={classes2} />
      <CardContent className={classes.content}>
        {
          metrics.map(([label, value]) => (
            <section key={label}>
              <Typography>
                {t(label)}
              </Typography>
              <Typography>
                <b>
                  {value}
                </b>
              </Typography>
            </section>
          ))
        }
      </CardContent>
    </Card>
  );
};

export default ShipmentSummary;
