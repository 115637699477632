import React from "react";

import { ApiClientContext } from "../../context";

const ApiClientProvider = ({ client, children }) => (
  <ApiClientContext.Provider value={client}>
    {children}
  </ApiClientContext.Provider>
);

export default ApiClientProvider;
