import React from "react";

import { Fab, makeStyles, Tooltip } from "@material-ui/core";
import { ArrowForward, Explore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  fabEditorToggle: {
    position: "absolute",
    right: 20,
    bottom: 100
  },
  fabComplete: {
    position: "absolute",
    right: 20,
    bottom: 40
  }
}));

const JourneySummaryResponsive = ({
  isVisible,
  isCargoEditorVisible,
  isJourneyComplete,
  onCargoEditorToggle,
  onComplete
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    isVisible ? (
      <React.Fragment>
        <Tooltip 
          title={isCargoEditorVisible
            ? t("webapp:journey.summary.showJourneyMap")
            : t("webapp:journey.summary.hideJourneyMap")}
        >
          <Fab
            className={classes.fabEditorToggle}
            color="primary"
            size="large"
            onClick={onCargoEditorToggle}
          >
            <Explore />
          </Fab>
        </Tooltip>
        <Tooltip title={t("webapp:journey.summary.complete")}>
          <Fab
            className={classes.fabComplete}
            color="primary"
            size="large"
            disabled={!isJourneyComplete}
            onClick={onComplete}
            data-cy="complete-journey-responsive"
          >
            <ArrowForward />
          </Fab>
        </Tooltip>
      </React.Fragment>
    ) : null
  );
};

export default JourneySummaryResponsive;
