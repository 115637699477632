import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

const PlaceSearchFailDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {t("webapp:journey.alert.place.search.fail.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("webapp:journey.alert.place.search.fail.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("webapp:journey.alert.place.search.fail.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlaceSearchFailDialog;
