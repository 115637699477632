import {
    FormGroup,
    FormControlLabel,
    Checkbox
 } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ColumnState = ({label, isActive, name, setActive}) => {
  const { t } = useTranslation();

  return (
      <FormGroup row>
        <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name={name}
                checked={isActive}
                onChange={event => setActive(name, event.target.checked)}
              />
            }
            label={t(label)}
        />
      </FormGroup>
  );
}

export default ColumnState;
