import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useFormik, getIn } from "formik";

import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import FormikAutocomplete from "@cargotic/webapp-component/component/FormikAutocomplete";

import { getExample } from "./example";

export function SequencingSettingsAutocomplete({
    form,
    name,
    availableItems,
    defaultItems
}) {
    const { t } = useTranslation();
    const inputLabel = t(`settings.customSequencing.sectionLabels.${name}`);
    const getOptionLabel = useCallback(
        (option) => t(`settings.customSequencing.sectionItems.${option}`),
        [t]
    );
    return (
        <>
            <FormikAutocomplete
                form={form}
                id={name}
                name={name}
                label={inputLabel}
                multiple
                handleHomeEndKeys
                size="small"
                required
                options={availableItems.map((v) => v.code)}
                getOptionLabel={getOptionLabel}
                defaultValue={defaultItems.map((v) => v.code)}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={getOptionLabel(option)}
                            size="small"
                            {...getTagProps({ index })}
                        />
                    ))
                }
            />
            <Typography variant="caption">
                {`${t("settings.customSequencing.example")}: ${getExample(
                    getIn(form.values, name)
                )}`}
            </Typography>
        </>
    );
}

SequencingSettingsAutocomplete.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    availableItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SequencingSettingsAutocomplete;
