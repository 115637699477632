import { green, grey, red } from "@material-ui/core/colors";

const TREND_COLOR_DOWN = red[600];
const TREND_COLOR_FLAT = grey[500];
const TREND_COLOR_UP = green[400];

const getTrendColor = (rateOfChange, isInverted) => {
  if (rateOfChange > 0) {
    return isInverted ? TREND_COLOR_DOWN : TREND_COLOR_UP;
  }

  if (rateOfChange < 0) {
    return isInverted ? TREND_COLOR_UP : TREND_COLOR_DOWN;
  }

  return TREND_COLOR_FLAT;
};

export default getTrendColor;
