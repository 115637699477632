const DashboardView = {
  CHAT: "CHAT",
  CONTACTS: "CONTACTS",
  SHIPMENT: "SHIPMENT",
  SHIPMENTS: "SHIPMENTS",
  USERS: "USERS",
  OVERVIEW: "OVERVIEW",
  SETTINGS: "SETTINGS",
  STATISTICS: "STATISTICS",
  VEHICLES: "VEHICLES",
  VEHICLE_OVERVIEW: "VEHICLE_OVERVIEW",
  TRACKING: "TRACKING",
  DRIVERS: "DRIVERS",
  PLACES: "PLACES",
  NOTFOUND: "NOTFOUND",
  FEEDBACK: "FEEDBACK",
  TARIFF_EXPIRATION: "TARIFF_EXPIRATION",
  SHIPMENT_BOARD: "SHIPMENT_BOARD",
  INCOMING_ORDERS: "INCOMING_ORDERS",
  OUTCOMING_ORDERS: "OUTCOMING_ORDERS",
  INCOMING_ORDER: "INCOMING_ORDER",
  OUTCOMING_ORDER: "OUTCOMING_ORDER",
  INVOICES: "INVOICES",
  INVOICE: "INVOICE",
  WAREHOUSES: "WAREHOUSES",
};

export default DashboardView;
