import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import {createMatchQueryWarehouses} from "../../../resource";
import WarehouseBodyNavigation from "./WarehouseBodyNavigation";
import WarehouseBodyContent from "./WarehouseBodyContent";
import WarehouseView from "./WarehouseView";
import {useParams} from "react-router-dom";

const useStyles = makeStyles(({palette, spacing}) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100%",

		"& > div:last-child": {
			flex: 1,
			marginTop: spacing(2),
			marginBottom: spacing(2)
		}
	},
	danger: {
		background: palette.status.danger,
		color: "white",
		"&:hover": {
			background: palette.status.danger
		}
	},
	pressedChip: {
		fontWeight: "bold",
		filter: "brightness(20%)"
	},
	select: {
		width: "200px",
		maxWidth: "100%"
	},
}));

const useTabStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: "transparent",
		color: "#333",
		boxShadow: "none"
	},
	subTabRoot: {
		flexGrow: 1,
		backgroundColor: "transparent",
		color: "#333",
		boxShadow: "none",
		marginTop: 15,
		marginBottom: 30
	},
	tab: {
		borderColor: "#333"
	},
	tableWrapper: {
		marginTop: theme.spacing(-4),
		marginLeft: theme.spacing(-5),
		marginRight: theme.spacing(-5)
	}
}));

const WarehouseContainer = ({view, onViewChange}) => {
	const {location: {pathname}, history} = useRouter();
	const classes = useStyles();
	const {t} = useTranslation();
	let {warehouseId} = useParams();

	const tabClasses = useTabStyles();

	const [warehouses, setWarehouses] = useState(undefined);
	const [selectedWarehouse, setSelectedWarehouse] = useState(undefined);

	const handleSelectWarehouse = (warehouseId) => {
		switch (view) {
			case WarehouseView.INCOMING_ORDERS:
				history.push(`/warehouses/${warehouseId}/incoming-orders`)
				break;
			case WarehouseView.ORDERS_HISTORY:
				history.push(`/warehouses/${warehouseId}/order-history`)
				break;
			case WarehouseView.WAREHOUSEMEN:
				history.push(`/warehouses/${warehouseId}/warehousemen`)
				break;
			case WarehouseView.INCOMING_SHIPMENTS:
				history.push(`/warehouses/${warehouseId}/shipments/incoming`)
				break;
			case WarehouseView.OUTCOMING_SHIPMENTS:
				history.push(`/warehouses/${warehouseId}/shipments/outcoming`)
				break;
		}
	}

	useEffect(() => {
		(async () => {
			let warehouses = await createMatchQueryWarehouses({
				orderBy: [],
				offset: 0,
				limit: 50
			})
			setWarehouses(warehouses.matches)

			if (!warehouseId) {
				handleSelectWarehouse(warehouses.matches[0].id)
				setSelectedWarehouse(warehouses.matches[0])
			} else {
				let selectedWarehouse = warehouses.matches.find(w => w.id.toString() === warehouseId.toString());
				setSelectedWarehouse(selectedWarehouse)
			}
		})()
	}, [])

	return (
			<>
				<Page>
					<div className={classes.root}>
						{selectedWarehouse && (
								<>
									<WarehouseBodyNavigation
											view={view}
											onViewChange={onViewChange}
											warehouses={warehouses}
											selectedWarehouseId={warehouseId}
											handleChangeSelectedWarehouseId={handleSelectWarehouse}
											tabClasses={tabClasses}
											classes={classes}
									/>
									<WarehouseBodyContent
											view={view}
											tabClasses={tabClasses}
											selectedWarehouse={selectedWarehouse}
									/>
								</>
						)}
					</div>
				</Page>
			</>
	);
};

export default WarehouseContainer;
