import React from "react";

import { makeStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
  mainTitle: {
    color: "black",
    fontSize: 20,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold"
  },
  title: {
    color: "black",
    fontSize: 18,
    width: "100%",
    textAlign: "left",
    fontWeight: "bold",
    marginTop: spacing(4)
  },
  basicInfo: {
    marginTop: spacing(3),
    width: "100%"
  },
  basicInfoFlex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // "& > div:nth-child(n+2)": {
    //   marginLeft: 80
    // }
  },
  basicInfoFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  basicInfoBlock: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "column",
    "& .basicInfoBlockTitle": {
      width: "100%",
      textAlign: "left",
      color: "grey",
      fontWeight: "bold",
      fontSize: 14
    },
    "& .basicInfoBlockInformation": {
      marginTop: spacing(1),
      width: "100%",
      textAlign: "left",
      color: "black",
      fontWeight: "500",
      fontSize: 13
    }
  }
}));

const TrackBasicInfo = ({
  basicInfo: {
    codCurrency,
    codValue,
    companyContactNumber,
    companyName,
    customerName,
    deliveryAddress,
    externalReference,
    totalCargoWeight
  }
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.mainTitle}>{companyName || ''}&nbsp;{t('trackingOrder.base.trackAndTrace')}</div>
      <div className={classes.basicInfo}>
        <div className={classes.basicInfoBlock}>
          <div className={'basicInfoBlockTitle'}>
            {companyName || ''}&nbsp;{t('trackingOrder.base.support')}
          </div>
          <div className={'basicInfoBlockInformation'}>
            {companyContactNumber || ''}
          </div>
        </div>
        <div className={classes.title}>
          {t('trackingOrder.base.orderDetail')}
        </div>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={classes.basicInfoBlock}>
              <div className={'basicInfoBlockTitle'}>
                {t('trackingOrder.base.sender')}
              </div>
              <div className={'basicInfoBlockInformation'}>
                {customerName || ''}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.basicInfoBlock}>
              <div className={'basicInfoBlockTitle'}>
                {t('trackingOrder.base.deliveryAddress')}
              </div>
              <div className={'basicInfoBlockInformation'}>
                {deliveryAddress || ''}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.basicInfoBlock}>
              <div className={'basicInfoBlockTitle'}>
                {t('trackingOrder.base.weight')}
              </div>
              <div className={'basicInfoBlockInformation'}>
                {totalCargoWeight ? totalCargoWeight.toFixed(1) : ''}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.basicInfoBlock}>
              <div className={'basicInfoBlockTitle'}>
                {t('trackingOrder.base.referenceNumber')}
              </div>
              <div className={'basicInfoBlockInformation'}>
                {externalReference || ''}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.basicInfoBlock}>
              <div className={'basicInfoBlockTitle'}>
                {t('trackingOrder.base.cashOnDelivery')}
              </div>
              <div className={'basicInfoBlockInformation'}>
                {codValue || ''}&nbsp;{codCurrency || ''}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TrackBasicInfo;
