import React, { forwardRef } from "react";

import {
  CircularProgress,
  List,
  ListItem,
  Paper,
  Typography,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    padding: spacing(2)
  }
}));

const AutosuggestTextFieldSuggestionArea = forwardRef(({
  className,
  suggestions,
  getSuggestionText,
  suggestionsComponent: SuggestionsComponent,
  emptySuggestionsLabel,
  isLoading,
  isOpen,
  onSuggestionClick
}, ref) => {
  const classes = useStyles();

  if (!isOpen) {
    return null;
  }

  const content = (() => {
    if (isLoading) {
      return (
        <div className={classes.content}>
          <CircularProgress size={30} />
        </div>
      );
    }

    if (suggestions.length === 0) {
      return (
        <div className={classes.content}>
          <Typography color="textSecondary">
            {emptySuggestionsLabel}
          </Typography>
        </div>
      );
    }

    return (
      <SuggestionsComponent
        suggestions={suggestions}
        onSuggestionClick={onSuggestionClick}
      />
    );
  })();

  return (
    <Paper ref={ref} className={className}>
      {content}
    </Paper>
  );
});

export default AutosuggestTextFieldSuggestionArea;
