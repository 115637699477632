const SEPARATORS = ["&", "+"];

const getNameInitials = (name: string) => (
  name.split(" ")
    .map(part => part[0])
    .filter(part => part !== undefined && !SEPARATORS.includes(part))
    .filter((_, index) => index < 2)
    .join("")
    .toUpperCase()
);

export default getNameInitials;
