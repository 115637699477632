import React, {useCallback, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {Button, Chip, Grid, makeStyles, MenuItem, Select, Tooltip, Typography} from "@material-ui/core";

import useRouter from "../../hook/useRouter";
import UserEditor from "./UserEditor";

import {useApiClient} from "../../../../cargotic-webapp-component";
import useAuth from "../../hook/useAuth";

import Users from "./Users";
import UserInviteDialog from "./UserInviteDialog";
import UserDeleteDialog from "./UserDeleteDialog";
import UserStatistics from "./UserStatistics";
import useTable from "../../../datatable/useTable";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

import {loadFilters, storeFilters} from "../../../storage";

import {
  createWarehouseman,
  deleteUser,
  inviteUser,
  readCompanyRoles,
  reinviteUser,
  updateRole,
  createMatchQueryUsers
} from "../../../resource";
import {ImageAvatar} from "../../common";
import {getNameInitials} from "../../../utility/common";
import {formatDistanceToNow} from "date-fns";
import {cs, enUS} from "date-fns/locale";

import {addUrlParam, getTableUrlParams} from "../../../utility/window"
import UserSelectEditor from "../../../../cargotic-webapp-component/component/UserSelectEditor";

const formatMoney = value => (
  (Math.ceil(value * 100) / 100).toFixed(0).toLocaleString("cs")
);

const useStyles = makeStyles(({ palette }) => ({
  select: {
    width: "75%",
    maxWidth: "75%"
  },
  reinviteButton: {
    display: "flex",
    alignItems: "center"
  }
}));

const UsersContainer = ({
  mode = 'users',
  warehouseId = undefined
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { location, match: { params: { id } } } = useRouter();
  const { user: { id: userId, role: myRole }, hasPermission } = useAuth();
  const locale = t("locale") === "cs" ? cs : enUS;
  const client = useApiClient();

  const pathname = location.pathname.slice(1);

  const { history, location: { search: routerSearch } } = useRouter();

  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  const [users, setUsers] = useState([]);
  const [userInviteDialogOpen, setUserInviteDialogOpen] = useState(false);
  const [warehousemanCreateDialogOpen, setWarehousemanCreateDialogOpen] = useState(false);
  const [userDeleteDialogOpen, setUserDeleteDialogOpen] = useState(false);
  const [userEditorDialogOpen, setUserEditorDialogOpen] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);

  const [search, setSearch] = useState(initSearchText);
  const [filter, setFilter] = useState(initFilter);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [clickedUser, setClickedUser] = useState({});

  const clearFilter = () => setFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);

  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const handleUserEditorOpen = () => setUserEditorDialogOpen(true);

  const handleUserEditorClose = () => setUserEditorDialogOpen(false);

  const onFilterSettingsSubmit = async value => {
    setIsFilterSettingsOpen(true);
    storeFilters("users", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const defaultFilterValues = ["roles"];
  const availableFilters = [
    {
      label: t("users.role"),
      value: "roles"
    }];

  const expandFilters = (values, fullValues) => values.map(item => fullValues.find(i => i.value === item));

  const resendInvitation = async (user) => {
    try {
      await reinviteUser(user);
      enqueueSnackbar(t("auth.reinvitation.success"), { variant: "success" });
    }
    catch (error) {
      const response = error.response.data;
      if (response.message === "soft-bounce") {
        enqueueSnackbar(t("auth.reinvitation.errorSoftBounce"), { variant: "error" });
      } else if (response.message === "hard-bounce") {
        enqueueSnackbar(t("auth.reinvitation.errorHardBounce"), { variant: "error" });
      } else {
        enqueueSnackbar(t("auth.reinvitation.error"), { variant: "error" });
      }
    }
  }

  const createRoleMenuItems = (roles) => roles
    .map(({ id, name }) => (
      <MenuItem value={id} key={id}>
        {name}
      </MenuItem>
    ));

  const UserRoles = ({
    user,
    allRoles,
    hasUpdateRoleForUserPermission
  }) => {
    return (
      userId !== user.id
        ? (
          <>
            <Select
              id={"select" + user.id}
              variant="outlined"
              value={user.roleId}
              className={classes.select}
              onChange={({ target: { value } }) => handleUpdateRole(user.id, value)}
              disabled={!hasUpdateRoleForUserPermission}
            >
              {createRoleMenuItems(allRoles)}
            </Select>
          </>
        ) : (
          <>
            <Chip label={user.roleName} color="default" variant="default" style={{ margin: 1 }} />
            <Chip label={t("users.you")} color="primary" variant="default" style={{ margin: 1 }} />
          </>
        )
    );
  }

  let reloadDelay;
  let storeSearchDelay;

  const reloadUsers = useCallback(async (offset, limit, ordering) => {
    let usersData = [];

    let promise = createMatchQueryUsers({
      query: {
        match: { search, ...filter, warehouseIds: warehouseId ? [warehouseId] : [] },
        offset: offset,
        limit: limit,
        orderBy: ordering
      }
    });

    const roles = await readCompanyRoles();

    let _users = await promise;

    if (_users.total === 0 && offset !== 0) {
      handleChangePage(
        undefined,
        0
      )
    }

    _users.matches.map((user) => {
      user.selected = false;

      let tableCells = [];
      tableCells.push({
        render:
          <Grid container alignItems="center" spacing={4}>
            <Grid item>
              <ImageAvatar
                source={user.avatarUrl}
                text={getNameInitials(user.name).toUpperCase()}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2"> {user.name} </Typography>
            </Grid>
          </Grid>
      });
      tableCells.push({
        render: <Typography variant="body2"> {user.email} </Typography>
      });
      tableCells.push({
        render: <UserRoles
          user={user}
          allRoles={roles}
          hasUpdateRoleForUserPermission={hasUpdateRoleForUserPermission}
        />
      });
      tableCells.push({
        render: (
          <div className={classes.reinviteButton} >
            {user.lastSeenAt
              ? <Typography variant="body2">
                {formatDistanceToNow(user.lastSeenAt, { locale })}
              </Typography>
              :
              <>
                <Typography variant="body2">
                  {t("users.pending")}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => resendInvitation({ email: user.email })}
                >
                  {t("users.resend")}
                </Button>
              </>
            }
          </div>
        )
      });

      if (mode == 'users') {
        tableCells.push({
          render:
              <Tooltip title={t("period.currentMonth") + " / " + t("period.currentWeek")}>
                <Typography variant="body2">
                  {
                    user.monthlyRevenue !== undefined && user.weeklyRevenue !== undefined
                        ? `${formatMoney(user.monthlyRevenue)} / ${formatMoney(user.weeklyRevenue)} CZK`
                        : "-"
                  }
                </Typography>
              </Tooltip>
        });
        tableCells.push({
          render:
              <Tooltip title={t("period.currentMonth") + " / " + t("period.currentWeek")}>
                <Typography variant="body2">
                  {
                    user.monthlyCommission !== undefined && user.weeklyCommission !== undefined
                        ? `${formatMoney(user.monthlyCommission)} / ${formatMoney(user.weeklyCommission)} CZK`
                        : "-"
                  }
                </Typography>
              </Tooltip>
        });
      }

      usersData.push({ id: user.id, row: tableCells, selected: false });
      return user;
    });

    setUsers(_users.matches);
    return { data: usersData, totalCnt: _users.total };
  }, [search, filter, warehouseId]);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = useTable(reloadUsers, "users");

  const selectedUsers = [];
  for (let index = 0; index < data.length; index++) {
    if (data[index].selected) {
      selectedUsers.push(users[index]);
    }
  }

  const handleDeleteSubmit = () => {
    setUserDeleteDialogOpen(false);

    const ids = selectedUsers.map(({ id }) => id);
    const requests = ids.map(deleteUser);

    return Promise.all(requests)
      .then(() => {
        reloadDataFromScratch();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("auth.error.deleteUser"), {
          variant: "error"
        });
      });
  };

  const handleUserInviteDialogSubmit = async ({ firstName, lastName, email, roleId, phoneNumber }) => {
    setUserInviteDialogOpen(false);
    try {
      await inviteUser({ firstName, lastName, email, roleId, phoneNumber });
      reloadData();
      enqueueSnackbar(t("users.invite.success"), {
        variant: "success"
      });
    } catch (err) {
      console.log(err.response);
      const errorCode = err.response.data.error.code;
      if (errorCode === "auth/email-already-exists") {
        enqueueSnackbar(t("users.invite.emailAlreadyExists"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("users.invite.error"), {
          variant: "error"
        });
      }
    }
  };

  const handleWarehousemanCreateDialogSubmit = async user => {
    setWarehousemanCreateDialogOpen(false);
    try {
      await createWarehouseman({ userId: user.id, warehouseId });
      reloadData();
      enqueueSnackbar(t("warehouses.warehouseman.create.success"), {
        variant: "success"
      });
    } catch (err) {
      console.log(err.response);
      enqueueSnackbar(t("warehouses.warehouseman.create.error"), {
        variant: "error"
      });
    }
  };

  const onUserEditorSubmit = async ({ id: userId, ...user }) => {
    handleUserEditorClose();

    try {
      const updatedUser = await client.user.putUser({ userId, user });
      await reloadData();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t("users.error.update"), { variant: "error" });
    }
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const handleUpdateRole = async (id, roleId) => {
    await updateRole({ userId: id, roleId });
    reloadData();
  }

  const handleSelectUserRole = (roles) => setFilter({ ...filter, roles });

  useEffect(() => {
    addUrlParam("filter", filter);
  }, [filter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250)
  }, [search]);

  useEffect(() => {
    const loadedFilters = loadFilters("users");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const loadAvailableRoles = async () => {
    const roles = await readCompanyRoles();
    setAvailableRoles(roles);
  }

  useEffect(() => {
    loadAvailableRoles();
  }, []);

  const onEdit = (id) => {
    setClickedUser(users.find(item => item.id === id));
    handleUserEditorOpen();
  };

  const handleUserInviteDialogOpen = () => setUserInviteDialogOpen(true);
  const handleUserInviteDialogClose = () => setUserInviteDialogOpen(false);

  const handleWarehousemanCreateDialogOpen = () => setWarehousemanCreateDialogOpen(true);
  const handleWarehousemanCreateDialogClose = () => setWarehousemanCreateDialogOpen(false);

  const handleUserDeleteDialogOpen = () => setUserDeleteDialogOpen(true);
  const handleUserDeleteDialogClose = () => setUserDeleteDialogOpen(false);

  const canInviteUser = hasPermission("resource.user.create");
  const canDeleteUser = hasPermission("resource.user.delete");
  const hasUpdateRoleForUserPermission = hasPermission("resource.user.role.update");

  useEffect(() => {
    const fetchSelectedUser = async () => {
      try {
        const selectedUser = await client.user.getUser({ userId: id });
        if (!selectedUser) {
          throw Error("No selected user");
        }
        setSelectedUser(selectedUser);
      } catch (e) {
        console.error(e);

        enqueueSnackbar(
          t("users.statistics.error"),
          { variant: "error" }
        );
        history.replace("/");
      }
    }
    if (id) {
      fetchSelectedUser();
    }
  }, [id]);


  return (
    <>
      {id ? (
        <UserStatistics id={id} user={selectedUser} />
      ) : (
        <Users
          data={data}
          dataCount={dataCount}
          selectedColumns={selectedColumns}
          users={users}
          loading={loading}
          search={search}
          ordering={ordering}
          direction={direction}
          checkedAll={checkedAll}
          rowsPerPage={rowsPerPage}
          page={page}
          filter={filter}
          handleSearch={handleSearch}
          canInviteUser={canInviteUser}
          canDeleteUser={canDeleteUser}
          handleSort={handleSort}
          handleUserDeleteDialogOpen={handleUserDeleteDialogOpen}
          handleUserInviteDialogOpen={mode === 'users' ? handleUserInviteDialogOpen : handleWarehousemanCreateDialogOpen}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          handleSelectUserRole={handleSelectUserRole}
          handleChangeSelectedColumns={handleChangeSelectedColumns}
          clearFilter={clearFilter}
          handleFilterSettingsOpen={handleFilterSettingsOpen}
          defaultFilters={defaultFilters}
          onEdit={onEdit}
          availableRoles={availableRoles}
          loadAvailableRoles={loadAvailableRoles}
          mode={mode}
        />
      )}
      {mode === 'users' && <UserInviteDialog
          open={userInviteDialogOpen}
          availableRoles={availableRoles}
          handleClose={handleUserInviteDialogClose}
          handleSubmit={handleUserInviteDialogSubmit}
      />}
      {mode === 'warehousemen' && <UserSelectEditor
          user={users[0]}
          isOpen={warehousemanCreateDialogOpen}
          onClose={handleWarehousemanCreateDialogClose}
          onSubmit={handleWarehousemanCreateDialogSubmit}
          permissions={["resource.user.role.permission.warehouseman"]}
      />}
      <UserDeleteDialog
        open={userDeleteDialogOpen}
        selected={selectedUsers.length}
        handleSubmit={handleDeleteSubmit}
        handleClose={handleUserDeleteDialogClose}
      />
      <UserEditor
        initialValue={clickedUser}
        availableRoles={availableRoles}
        isOpen={userEditorDialogOpen}
        onSubmit={onUserEditorSubmit}
        onClose={() => handleUserEditorClose()}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default UsersContainer;
