import React, { useState } from "react";
import { receiveCargoItemPackages } from "../../../resource";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LinehallWarehouseOrdersContainer from "./LinehallWarehouseOrdersContainer";
import { useSnackbar } from "notistack";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";

const useStyles = makeStyles(({ palette }) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  dialogPaper: {
    height: "80vh",
    minWidth: "80vw",
  },
  abort: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger,
      color: "white"
    }
  },
}));


const LinehallDialog = ({
  open,
  handleClose,
  handleSubmit,
  warehouse
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  
  const [warehouseOrders, setWarehouseOrders] = useState([]);
  const [selectedWarehouseOrders, setSelectedWarehouseOrders] = useState([]);

  const { setShipment } = useShipmentConcept();

  const handleSubmitWarehouseOrders = () => {
    if (!selectedWarehouseOrders.length) {
      enqueueSnackbar(t("incomingOrders.noSelection", { variant: "error" }));
    } else {
      setShipment({
        incomingOrderIds: selectedWarehouseOrders.map(({ id }) => id),
        incomingOrders: selectedWarehouseOrders,
        fresh: true
      });
      history.push("shipment");
    }
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <>Naplánování přepravy na sklad</>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <LinehallWarehouseOrdersContainer
          warehouse={warehouse}
          setWarehouseOrders={setWarehouseOrders}
          warehouseOrders={warehouseOrders}
          setSelectedWarehouseOrders={setSelectedWarehouseOrders}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LinehallDialog;