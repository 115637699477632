import React, {useEffect, useState} from "react";

import {Button, InputAdornment, ListItemIcon, ListItemText, makeStyles, TextField, Typography} from "@material-ui/core";

import {useTranslation} from "react-i18next";

import {DrawerDialog} from "../../../../cargotic-webapp-component";
import {Place} from "@material-ui/icons";
import {searchPlace, searchPlaceByCoordinates, suggestPlace} from "../../../places";
import SearchAutosuggestTextField from "../../../../../multiload/cargotic-webapp/component/SearchAutosuggestTextField";
import {useGoogleMapsApi} from "../../../../../multiload/cargotic-map";
import {normalizeGooglePlace} from "../../../../../multiload/cargotic-webapp/utility";
import {Formik} from "formik";
import {WarehouseSchema} from "../../../utility/validationSchema";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
  root: {
    "p + p": {
      marginTop: "15px"
    }
  },
  actionButtonGroup: {
    justifyContent: "flex-end",
    flexDirection: "row",
    display: "flex",
    padding: spacing(2),
    marginTop: "auto",
    borderTop: "1px solid silver"
  },
  container: {
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  abort: {
    background: palette.status.danger,
    marginRight: spacing(1),
    color: "white",
    "&:hover": {
      background: palette.status.danger,
      color: "white"
    }
  },
}));


const WarehouseEditor = ({
  initialValue,
  user,
  isOpen,
  onClose,

  handleClose,
  handleSearchFail,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isCreate, setIsCreate] = useState();

  useEffect(() => {
    setIsCreate(!Boolean(Object.keys(initialValue).length > 0))
  }, [isOpen])

  const {
    api: googleMapsApi
  } = useGoogleMapsApi();

  const googlePlaceService = new google.maps.places.PlacesService(document.createElement('div'));
  const googleGeocoderService = new googleMapsApi.Geocoder();
  const googleAutocompleteService = new googleMapsApi.places.AutocompleteService();

  const [placesService, setPlacesService] = useState(googlePlaceService);
  const [geocoderService, setGeocoderService] = useState(googleGeocoderService);
  const [addressSuggested, setAddressSuggested] = useState(true);
  const [address, setAddress] = useState();

  const onPlaceChange = async (setValues, values, place) => {
    const normalizedPlace = await normalizeGooglePlace(placesService, place.place_id);

    setAddress(normalizedPlace);

    setAddressSuggested(true);
    setValues({ ...values, address: place.description });
  }

  const haveAddressError = (errors, touched) => errors.address && touched.address || !addressSuggested;

  return (
    <DrawerDialog
      title={t("webapp:common.title.warehouse")}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div style={{height: "calc(100vh - 78px)"}}>
        <Formik
            validationSchema={WarehouseSchema}
            initialValues={{
              name: initialValue?.name ?? "",
              address: initialValue?.place?.address?.formatted ?? "",
              capacity: initialValue?.capacity ?? 0,
              rampCount: initialValue?.rampCount ?? 0,
            }}
            values={initialValue}
            onSubmit={(values) => {
              handleSubmit(isCreate, values, address);
            }}
        >
          {({
              handleBlur,
              handleChange,
              isSubmitting,
              submitForm,
              errors,
              values,
              touched,
              setValues
            }) => (
              <div className={classes.form}>
                <form className={classes.container}>
                  <TextField
                      required
                      name="name"
                      value={values.name}
                      error={errors.name && touched.name}
                      helperText={touched.name && t(errors.name)}
                      label={t("webapp:warehouses.name")}
                      onChange={handleChange}
                      fullWidth
                      onBlur={handleBlur}
                  />
                  <SearchAutosuggestTextField
                      required
                      fullWidth
                      hideCargoPlaces
                      disabled={!isCreate}
                      name="address"
                      text={values.address ?? ""}
                      value={address}
                      helperText={haveAddressError(errors, touched) && t(errors.address)}
                      error={haveAddressError(errors, touched)}
                      label={t("places.address")}
                      renderSuggestion={({ description }) => (
                          <>
                            <ListItemIcon>
                              <Place />
                            </ListItemIcon>
                            <ListItemText primary={description} />
                          </>
                      )}
                      search={(text) => searchPlace(placesService, text)}
                      searchByCoordinates={(latitude, longitude) =>
                          searchPlaceByCoordinates(geocoderService, placesService, latitude, longitude)}
                      // onSearchFail={handleSearchFail}
                      suggest={(text) => suggestPlace(googleAutocompleteService, text)}
                      onTextChange={(text) => {
                        setValues({ ...values, address: text });
                        setAddressSuggested(false);
                      }}
                      onValueChange={(place) => onPlaceChange(setValues, values, place)}
                  />
                  <TextField
                      required
                      name="capacity"
                      type="number"
                      value={values.capacity}
                      error={errors.capacity && touched.capacity}
                      helperText={touched.capacity && t(errors.capacity)}
                      label={t("webapp:warehouses.capacity")}
                      InputProps={{
                        endAdornment: <InputAdornment position="start"><p>m<sup>3</sup></p></InputAdornment>,
                      }}
                      onChange={handleChange}
                      fullWidth
                      onBlur={handleBlur}
                  />
                  <TextField
                      required
                      name="rampCount"
                      type="number"
                      value={values.rampCount}
                      error={errors.rampCount && touched.rampCount}
                      helperText={touched.rampCount && t(errors.rampCount)}
                      label={t("webapp:warehouses.rampCount")}
                      onChange={handleChange}
                      fullWidth
                      onBlur={handleBlur}
                  />
                </form>
                <div className={classes.actionButtonGroup}>
                  <Button onClick={handleClose} variant="contained" className={classes.abort}>
                    {t("abort")}
                  </Button>
                  <Button onClick={submitForm} variant="contained" color="primary" disabled={isSubmitting}>
                    {t("save")}
                  </Button>
                </div>
              </div>
          )}
        </Formik>
      </div>
    </DrawerDialog>
  );
};

export default WarehouseEditor;
