import { updateIndex } from "../../../cargotic-common";
import {
  CargoItem,
  CargoItemAction,
  CargoItemTemplate,
  CargoItemTemplateSpecification,
  Waypoint
} from "../../../cargotic-core";

import JourneyPlannerAction from "./JourneyPlannerAction";

const deleteCargoItemByIdAndItemId = (waypoints, id, itemId, action) => (
  waypoints.map(waypoint => ({
    ...waypoint,
    cargo: waypoint.cargo
      .filter(({ id: otherId, itemId: otherItemId }) => {
        if (otherId === id) {
          return false;
        }

        if (action === CargoItemAction.LOAD && otherItemId === itemId) {
          return false;
        }

        return true;
      })
  }))
);

const deleteWaypointByIndex = (waypoints, index) => (
  waypoints
    .filter((_, other) => other !== index)
);

const loadCargoItem = (
  waypoints,
  selectedWaypointIndex,
  loadedItem,
  unloadedItem
) => waypoints.map((waypoint, index) => {
  const isLast = index === waypoints.length - 1;
  const isSelected = index === selectedWaypointIndex;

  if (isSelected) {
    return {
      ...waypoint,
      cargo: [
        ...waypoint.cargo,
        loadedItem
      ]
    };
  }

  if (isLast) {
    return {
      ...waypoint,
      cargo: [
        ...waypoint.cargo,
        unloadedItem
      ]
    };
  }

  return waypoint;
});

const compareDateTimes = (arriveAtFrom, arriveAtTo) =>
  arriveAtFrom.getYear() === arriveAtTo.getYear() &&
  arriveAtFrom.getMonth() === arriveAtTo.getMonth() &&
  arriveAtFrom.getDate() === arriveAtTo.getDate() &&
  arriveAtFrom.getHours() === arriveAtTo.getHours() &&
  arriveAtFrom.getMinutes() === arriveAtTo.getMinutes();

const validateWaypointDatesFrom = (waypoints, errors, changedIndex) => {
  const changedWaypoint = waypoints[changedIndex];
  const previousWaypoint = waypoints[changedIndex - 1];
  let latestDate = previousWaypoint !== undefined
    ? previousWaypoint.arriveAtTo ?? previousWaypoint.arriveAtFrom
    : changedWaypoint.arriveAtFrom;

  const updatedWaypoints = waypoints.map((currentWaypoint, currentIndex) => {
    if (currentIndex < changedIndex) {
      return currentWaypoint;
    }
    let { arriveAtFrom, arriveAtTo } = currentWaypoint;
    /* will uncomment later
    errors = (arriveAtFrom < latestDate)
      ? addErrorByIndex(errors, currentIndex, "arriveAtFrom", "shipments.validation.dateValid")
      : removeErrorByIndex(errors, currentIndex, "arriveAtFrom");
    */

    latestDate = arriveAtFrom;

    if (arriveAtTo) {
      if (compareDateTimes(arriveAtFrom, arriveAtTo)) {
        arriveAtTo = undefined;
      } else {
        /* will uncomment later
        errors = (arriveAtTo < arriveAtFrom)
          ? addErrorByIndex(errors, currentIndex, "arriveAtTo", "shipments.validation.dateValid")
          : removeErrorByIndex(errors, currentIndex, "arriveAtTo");
        */
      }
      latestDate = arriveAtTo || arriveAtFrom;
    } else {
      errors = removeErrorByIndex(errors, currentIndex, "arriveAtTo");
    }

    return {
      ...currentWaypoint,
      arriveAtFrom,
      arriveAtTo
    }
  });

  return {
    errors,
    waypoints: updatedWaypoints
  }
}

const validateWaypointPlace = (errors, index, place) => 
  (place && place.googleId)
    ? errors
    : addErrorByIndex(errors, index, "place", "shipments.validation.placeValid");

const validateWaypointPhoneNumber = (errors, index, phoneNumber) => (phoneNumber.length > 15) ? addErrorByIndex(errors, index, "phoneNumber", "shipments.validation.phoneNumberValid") : errors;

const validateWaypointNote = (errors, index, note) => (note.length > 255) ? addErrorByIndex(errors, index, "note", "shipments.validation.noteValid") : errors;

const validateWaypointContact = (errors, index, contact) => (contact.length > 255) ? addErrorByIndex(errors, index, "contact", "shipments.validation.contactValid") : errors;

const validateWaypointPlaces = (waypoints) =>
  waypoints
    .reduce(
      (isComplete, { place: { googleId } }) => isComplete
        && googleId !== undefined,
      true
    );

const recalculateSelectedWaypointIndex = (selectedWaypointIndex, index) => {
  if (index > selectedWaypointIndex) {
    return selectedWaypointIndex;
  }

  if (index < selectedWaypointIndex) {
    return selectedWaypointIndex - 1;
  }

  if (selectedWaypointIndex === 0) {
    return selectedWaypointIndex;
  }

  return selectedWaypointIndex - 1;
};

const unloadCargoItem = (waypoints, selectedWaypointIndex, item, itemId) => (
  waypoints
    .map((waypoint, index) => {
      const isSelected = selectedWaypointIndex === index;
      const updatedCargo = Array.from(waypoint.cargo)
        .filter(({ action, itemId: otherItemId }) => (
          action !== CargoItemAction.UNLOAD || otherItemId !== itemId
        ));

      if (isSelected) {
        updatedCargo.push(item);
      }

      return {
        ...waypoint,
        cargo: updatedCargo
      };
    })
);

const updateCargoItemById = (
  waypoints,
  selectedWaypointIndex,
  id,
  itemId,
  item,
  cargoItemLinkSeed
) => {
  const isLinkBroken = waypoints
    .slice(0, selectedWaypointIndex)
    .flatMap(({ cargo }) => cargo)
    .some(({ itemId: otherItemId }) => otherItemId === itemId);

  let updatedCargoItemLinkSeed = cargoItemLinkSeed;
  const updatedWaypoints = waypoints
    .map(waypoint => ({
      ...waypoint,
      cargo: waypoint.cargo.map(current => {
        const { itemId: currentItemId } = current;

        if (current.id === id) {
          const updatedItemId = isLinkBroken
            ? updatedCargoItemLinkSeed++
            : current.itemId;

          return { ...current, ...item, itemId: updatedItemId };
        }

        if (current.itemId === itemId) {
          if (isLinkBroken) {
            return current;
          }

          return { ...current, ...item };
        }

        return current;
      })
    }));

  return [updatedCargoItemLinkSeed, updatedWaypoints];
};

const updateWaypointByIndex = (waypoints, index, waypoint) =>
  waypoints
    .map((otherWaypoint, otherIndex) => {
      if (otherIndex !== index) {
        return otherWaypoint;
      }

      return {
        ...otherWaypoint,
        ...waypoint
      };
    });

const addErrorByIndex = (errors, index, name, value) =>
  errors
    .map((otherError, otherIndex) => {
      if (otherIndex !== index) {
        return otherError;
      }

      return {
        ...otherError,
        [name]: value
      };
    });

const removeErrorsByIndex = (errors, index) =>
  errors.reduce((acc, otherError, otherIndex) => {
    if (otherIndex !== index) {
      return [...acc, otherError];
    }
    return acc;
  }, []);

const removeErrorByIndex = (errors, index, name) =>
  errors.map((otherError, otherIndex) => {
    if (otherIndex !== index) {
      return otherError;
    }

    return Object.keys(otherError).reduce((acc, otherName) => {
      if (otherName !== name) {
        acc[otherName] = otherError[otherName]
      }
      return acc
    }, {});
  });

const reorderWaypoints = (
  waypoints,
  sourceIndex,
  destinationIndex,
  cargoItemLinkSeed
) => {
  const copy = Array.from(waypoints);
  const [removed] = copy.splice(sourceIndex, 1);
  copy.splice(destinationIndex, 0, removed);

  let updatedCargoItemLinkSeed = cargoItemLinkSeed;

  const [newWaypoints] = copy.reduce(
    (
      [updatedWaypoints, unloadedCargoItemIds],
      waypoint,
      index
    ) => {
      const updatedUnloadedCargoItemIds = Array.from(unloadedCargoItemIds);
      const isFirstWaypoint = index === 0;
      const isLastWaypoint = index === waypoints.length - 1;

      const updatedCargoItems = waypoint.cargo.reduce(
        (result, item) => {
          if (item.action === CargoItemAction.LOAD) {
            if (isLastWaypoint) {
              return result;
            }

            if (updatedUnloadedCargoItemIds.includes(item.itemId)) {
              return [
                ...result,
                { ...item, itemId: updatedCargoItemLinkSeed++ }
              ];
            }

            return [...result, item];
          }

          if (isFirstWaypoint) {
            return result;
          }

          updatedUnloadedCargoItemIds.push(item.itemId);

          return [...result, item];
        },
        []
      );

      const updatedWaypoint = { ...waypoint, cargo: updatedCargoItems };

      return [
        [...updatedWaypoints, updatedWaypoint],
        updatedUnloadedCargoItemIds
      ];
    },
    [[], []]
  );

  return [updatedCargoItemLinkSeed, newWaypoints];
};

const JourneyPlannerReducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case JourneyPlannerAction.ADD_WAYPOINT: {
      const { waypoints, waypointSeed, errors } = state;

      return {
        ...state,
        waypoints: [...waypoints, Waypoint({ id: waypointSeed })],
        errors: [...errors, {}],
        waypointSeed: waypointSeed + 1
      };
    }

    case JourneyPlannerAction.COPY_CARGO_ITEM: {
      const { id: originalId } = action;
      const { waypoints, selectedWaypointIndex } = state;
      let { cargoItemSeed, cargoItemLinkSeed } = state;
      const { itemId } = waypoints[selectedWaypointIndex].cargo
        .find(({ id: otherId }) => otherId === originalId);

      const copyId = cargoItemSeed++;
      const copyItemId = cargoItemLinkSeed++;

      const updatedWaypoints = waypoints.map((waypoint, index) => {
        const isSelected = index === selectedWaypointIndex;
        const template = waypoint.cargo.find(({ itemId: otherItemId }) => (
          otherItemId === itemId
        ));

        if (!template) {
          return waypoint;
        }

        return {
          ...waypoint,
          cargo: [
            ...waypoint.cargo,
            {
              ...template,
              id: isSelected ? copyId : cargoItemSeed++,
              itemId: copyItemId
            }
          ]
        };
      });

      return {
        ...state,
        cargoItemSeed,
        cargoItemLinkSeed,
        selectedCargoItemId: copyId,
        waypoints: updatedWaypoints
      };
    }

    case JourneyPlannerAction.DELETE_CARGO_ITEM: {
      const { id, itemId, action: cargoItemAction } = action;
      const {
        selectedCargoItemId,
        waypoints
      } = state;

      return {
        ...state,
        selectedCargoItemId: id === selectedCargoItemId
          ? undefined
          : selectedCargoItemId,
        waypoints: deleteCargoItemByIdAndItemId(
          waypoints,
          id,
          itemId,
          cargoItemAction
        )
      };
    }

    case JourneyPlannerAction.DELETE_WAYPOINT: {
      const { index } = action;
      const {
        selectedCargoItemId,
        selectedWaypointIndex,
        waypoints,
        errors
      } = state;

      return {
        ...state,
        selectedCargoItemId: selectedWaypointIndex === index
          ? undefined
          : selectedCargoItemId,
        selectedWaypointIndex: recalculateSelectedWaypointIndex(
          selectedWaypointIndex,
          index
        ),
        waypoints: deleteWaypointByIndex(waypoints, index),
        errors: removeErrorsByIndex(errors, index)
      };
    }

    case JourneyPlannerAction.LOAD_CARGO_ITEM: {
      const { waypoints, selectedWaypointIndex } = state;
      let { cargoItemSeed, cargoItemLinkSeed } = state;

      const loadedId = cargoItemSeed++;
      const unloadedId = cargoItemSeed++;
      const itemId = cargoItemLinkSeed++;

      const {
        width,
        height,
        length,
        lengthUnit
      } = CargoItemTemplateSpecification[CargoItemTemplate.EUR_PALLET];

      const loadedItem = CargoItem({
        id: loadedId,
        action: CargoItemAction.LOAD,
        itemId,
        width,
        height,
        length,
        lengthUnit,
        quantity: 1,
        template: CargoItemTemplate.EUR_PALLET
      });

      const unloadedItem = {
        ...loadedItem,
        id: unloadedId,
        action: CargoItemAction.UNLOAD
      };

      return {
        ...state,
        cargoItemSeed,
        cargoItemLinkSeed,
        selectedCargoItemId: loadedId,
        waypoints: loadCargoItem(
          waypoints,
          selectedWaypointIndex,
          loadedItem,
          unloadedItem
        )
      };
    }

    case JourneyPlannerAction.VALIDATE_FORM_DATES: {
      const { index } = action;
      const { waypoints, errors } = state;

      const updatedState = validateWaypointDatesFrom(waypoints, errors, index);

      return {
        ...state,
        errors: updatedState.errors,
        waypoints: updatedState.waypoints,
      };
    }

    case JourneyPlannerAction.VALIDATE_WAYPOINT_PLACE: {
      const { index } = action;
      const { waypoints, errors } = state;

      return {
        ...state,
        errors: validateWaypointPlace(errors, index, waypoints[index].place)
      };
    }

    case JourneyPlannerAction.VALIDATE_WAYPOINT_PHONE_NUMBER: {
      const { index } = action;
      const { waypoints, errors } = state;

      return {
        ...state,
        errors: validateWaypointPhoneNumber(errors, index, waypoints[index].phoneNumber)
      };
    }


    case JourneyPlannerAction.VALIDATE_WAYPOINT_NOTE: {
      const { index } = action;
      const { waypoints, errors } = state;

      return {
        ...state,
        errors: validateWaypointNote(errors, index, waypoints[index].note)
      };
    }


    case JourneyPlannerAction.VALIDATE_WAYPOINT_CONTACT: {
      const { index } = action;
      const { waypoints, errors } = state;

      return {
        ...state,
        errors: validateWaypointContact(errors, index, waypoints[index].contact)
      };
    }

    case JourneyPlannerAction.REORDER_WAYPOINTS: {
      const { sourceIndex, destinationIndex } = action;
      const { waypoints, cargoItemLinkSeed } = state;

      const [updatedCargoItemLinkSeed, updatedWaypoints] = reorderWaypoints(
        waypoints,
        sourceIndex,
        destinationIndex,
        cargoItemLinkSeed
      );

      return {
        ...state,
        selectedWaypointIndex: destinationIndex,
        waypoints: updatedWaypoints,
        cargoItemLinkSeed: updatedCargoItemLinkSeed
      };
    }

    case JourneyPlannerAction.SELECT_CARGO_ITEM: {
      const { id: selectedCargoItemId } = action;

      return {
        ...state,
        selectedCargoItemId
      };
    }

    case JourneyPlannerAction.SELECT_WAYPOINT: {
      const { index: selectedWaypointIndex } = action;

      return {
        ...state,
        selectedWaypointIndex,
        selectedCargoItemId: undefined
      };
    }

    case JourneyPlannerAction.SET_DISTANCE_AND_DURATION: {
      const { distance, duration } = action;

      return {
        ...state,
        distance,
        duration
      };
    }

    case JourneyPlannerAction.SET_IS_PLACE_SEARCH_FAIL_ALERT_OPEN: {
      const { isPlaceSearchFailAlertOpen } = action;

      return {
        ...state,
        isPlaceSearchFailAlertOpen
      }
    }

    case JourneyPlannerAction.UNLOAD_CARGO_ITEM: {
      let { cargoItemSeed, cargoItemLinkSeed } = state;
      const { selectedCargoItemId, selectedWaypointIndex, waypoints } = state;
      const {
        width,
        height,
        length,
        lengthUnit
      } = CargoItemTemplateSpecification[CargoItemTemplate.EUR_PALLET];

      let item = action.item || CargoItem({
        width,
        height,
        length,
        lengthUnit,
        quantity: 1,
        template: CargoItemTemplate.EUR_PALLET
      });

      const id = cargoItemSeed++;
      const itemId = item.itemId ?? cargoItemLinkSeed++;

      item = {
        ...item,
        id,
        itemId,
        action: CargoItemAction.UNLOAD
      };

      return {
        ...state,
        cargoItemSeed,
        cargoItemLinkSeed,
        selectedCargoItemId: action.item === undefined
          ? id
          : selectedCargoItemId,
        waypoints: unloadCargoItem(
          waypoints,
          selectedWaypointIndex,
          item,
          itemId
        )
      };
    }

    case JourneyPlannerAction.UPDATE_CARGO_ITEM: {
      const {
        id,
        itemId,
        action: cargoItemAction,
        item
      } = action;

      const { waypoints, selectedWaypointIndex, cargoItemLinkSeed } = state;

      const [updatedCargoItemLinkSeed, updatedWaypoints] = updateCargoItemById(
        waypoints,
        selectedWaypointIndex,
        id,
        itemId,
        item,
        cargoItemLinkSeed
      );

      return {
        ...state,
        cargoItemLinkSeed: updatedCargoItemLinkSeed,
        waypoints: updatedWaypoints
      };
    }

    case JourneyPlannerAction.UPDATE_WAYPOINT: {
      const { index, waypoint, name } = action;
      const { waypoints, errors } = state;

      return {
        ...state,
        waypoints: updateWaypointByIndex(waypoints, index, waypoint),
        errors: removeErrorByIndex(errors, index, name)
      };
    }

    case JourneyPlannerAction.ADD_ERROR: {
      const { index, name, value } = action;
      const { errors } = state;
      return {
        ...state,
        errors: addErrorByIndex(errors, index, name, value)
      };
    }

    case JourneyPlannerAction.CHANGE_JOURNEY: {
      const { waypoints, errors, distance, duration, waypointSeed, cargoItemSeed } = action;
      return {
        ...state,
        waypointSeed,
        cargoItemSeed,
        waypoints,
        errors,
        distance,
        duration
      };
    }

    default: {
      throw new Error(`Unknown action type '${type}'!`);
    }
  }
};

export default JourneyPlannerReducer;
