import { useFormik } from "formik2";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, makeStyles, MenuItem } from "@material-ui/core";

import {
  DrawerDialog,
  FormikSelect,
  FormikTextField
} from "../../../cargotic-webapp-component";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  }
}));

const ContactSubcontractorEmployeeEditor = ({
  initialValue = {},
  isOpen,
  onClose,
  onSubmit,
  availableCompanyRoles
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const roleSelectItems = useMemo(() => availableCompanyRoles?.map((companyRole) => {
    const roleId = companyRole?.id
    return <MenuItem key={roleId} value={roleId}>{companyRole?.name}</MenuItem>
  }), [availableCompanyRoles])

  const schema = Yup.object({
    roleId: Yup.string().required(t("webapp:common.validate.value")),
    firstName: Yup.string()
      .max(64, t("webapp:common.validate.name")),
      lastName: Yup.string()
      .max(64, t("webapp:common.validate.name")),
    email: Yup.string()
      .email(t("webapp:common.validate.email"))
      .max(128, t("webapp:common.validate.email"))
      .required(t("webapp:employee.validate.email")),
    phoneNumber: Yup.string()
      .max(32, t("webapp:common.validate.phone-number"))
  });
 
  const initialFirstName = initialValue.firstName || "";
  const initialLastName = initialValue.lastName || "";
  const initialEmail = initialValue.email || "";
  const initialPhoneNumber = initialValue.phoneNumber || "";
  const initialRole = initialValue.roleId || availableCompanyRoles?.[0]?.id

  const form = useFormik({
    initialValues: {
      firstName: initialFirstName,
      lastName: initialLastName,
      email: initialEmail,
      phoneNumber: initialPhoneNumber,
      roleId: initialRole,
    },
    onSubmit: (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  });

  const handleSubmitButtonClick = () => (
    form.submitForm()
  );

  useEffect(() => {
    form.resetForm({
      values: {
        firstName: initialFirstName,
        lastName: initialLastName,
        email: initialEmail,
        phoneNumber: initialPhoneNumber,
        roleId: initialRole
      },
      errors: {}
    });
  }, [isOpen]);

  return (
    <DrawerDialog
      title={t("webapp:common.title.user")}
      isOpen={isOpen}
      onClose={onClose}
      actions={(
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose}>
            {t("webapp:common.title.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitButtonClick}
          >
            {t("webapp:common.title.continue")}
          </Button>
        </div>
      )}
    >
      <div>
        <FormikTextField
          form={form}
          name="firstName"
          label={t("webapp:common.title.name")}
          fullWidth
        />
        <FormikTextField
          form={form}
          name="lastName"
          label={t("webapp:common.title.last-name")}
          fullWidth
        />
        <FormikTextField
          form={form}
          required
          name="email"
          label={t("webapp:common.title.email")}
          fullWidth
        />
        <FormikTextField
          form={form}
          name="phoneNumber"
          label={t("webapp:common.title.phone-number")}
          fullWidth
        />
        <FormikSelect
          form={form}
          required
          name="roleId"
          label={t("webapp:common.title.role")}
          fullWidth
        >
          {roleSelectItems}
        </FormikSelect>

      </div>
    </DrawerDialog>
  );
};

export default ContactSubcontractorEmployeeEditor;
