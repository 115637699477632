import React, { useState, useEffect, useRef } from "react";
import { cs, enUS } from "date-fns/locale";
import { formatDistance } from "date-fns";

import { useTranslation } from "react-i18next";

import {
  deserializeFloat,
  deserializeInteger,
  identity,
  pipe,
  EditableTypography,
  ValueError
} from "@cargotic/common-deprecated";

import { ShipmentType, ShipmentState } from "@cargotic/model-deprecated";

import { Currency, convertCurrency } from "@cargotic/currency";
import { useApiClient } from "@cargotic/api-client-deprecated";
import {
  allowEmptyString, required, isObject, validateStringLength, validateDateString
} from "@cargotic/validate";
import {
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  Box
} from "@material-ui/core";

import ShipmentSummary from "../ShipmentSummary";
import RelatedInfo from "../RelatedInfo";
import ShipmentCarrierForm from "../ShipmentCarrierForm";

import { createFormValidator, FormValidationError, useForm } from "../../../form";

import { generateUuid } from "../../../../../multiload/cargotic-common";

const SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH = 15;

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    padding: spacing(2)
  },
  separator: {
    flexGrow: 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "& > div > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > div > button": {
      minWidth: spacing(14)
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    display: "flex"
  },
  selectState: {
    marginRight: spacing(1),
    minWidth: 100
  },
  button: {
    background: "#29AEA0",
    color: "#FFF",
    borderRadius: 200,
    width: "100%",
    marginBottom: 31,
    fontSize: 16,
    textTransform: "uppercase",
    justifyContent: "flex-start",
    padding: spacing(2),
    paddingLeft: 30
  },
  btnText: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    paddingRight: 20
  },
  createOrder: {
    marginTop: 73
  },
  back: {
    width: 162,
    height: 36,
    marginTop: 13
  },
  backSpaced: {
    marginRight: 13,
  },
}));

const ShipmentForm = ({
  className,
  apiClient,
  newApiClient,
  exchangeRates,
  journey,
  shipment: {
    indexNumber,
    externalReference: initialExternalReference,
    orderSerialNumber: initialOrderSerialNumber,
    type,
    isDraft,
    state: initialState,
    driverContact: initialDriverContact,
    driver: initialDriver = "",
    vehicle: initialVehicle = "",
    trailer: initialTrailer = "",
    notes: initialNotes,
    documents: initialDocuments,
    ...shipment
  },
  isExtended,
  isUpdating,
  onBack,
  onFormChange,
  onSave
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;
  const isMounted = useRef(false);
  const [state, setState] = useState(initialState || ShipmentState.QUEUE);
  const [stateChange, setStateChange] = useState(false);
  const [
    isOrderSerialNumberDuplicate,
    setIsOrderSerialNumberDuplicate
  ] = useState(false);

  const [shipmentNumber, setShipmentNumber] = useState(indexNumber);

  const validateDeleted = (x, message = "") => {
    if (typeof x === "object" && x?.isDeleted) {
      throw new ValueError(message);
    }

    return x;
  };

  const validateOrderSerialNumber = pipe(
    (value) => required(value || undefined, t("webapp:shipment.form.error.orderSerialNumber")),
    (value) => validateStringLength(value, 16, t("webapp:shipment.form.error.orderSerialNumberLength"))
  );

  const isString = x => typeof x === "string";

  const validateDriver = pipe(
    (driver) => required(driver, t("webapp:shipment.form.error.driver")),
    (driver) => isObject(driver, t("webapp:shipment.form.error.driver")),
    (driver) => validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  );

  const validateDraftDriver = pipe(
    allowEmptyString((driver) => isObject(driver, t("webapp:shipment.form.error.driver"))),
    (driver) => validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  );

  const validateVehicle = pipe(
    (vehicle) => required(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) => isObject(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) => validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  );

  const validateDraftVehicle = pipe(
    allowEmptyString((vehicle) => isObject(vehicle, t("webapp:shipment.form.error.vehicle"))),
    (vehicle) => validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  );

  const validateTrailer = pipe(
    allowEmptyString((trailer) => isObject(trailer, t("webapp:shipment.form.error.trailer"))),
    (trailer) => validateDeleted(trailer, t("webapp:shipment.form.error.deletedTrailer"))
  );


  const validateOptionalString = allowEmptyString(identity);

  const validateSubmitDraft = ({ values }) => {
    let errors = {};

    // validate shared values for both forms
    try {
      validateDraftDriver(values.driver);
    } catch (err) {
      errors = { ...errors, driver: err };
    }
    try {
      validateDraftVehicle(values.vehicle);
    } catch (err) {
      errors = { ...errors, vehicle: err };
    }
    try {
      validateTrailer(values.trailer);
    } catch (err) {
      errors = { ...errors, trailer: err };
    }

    scrollOnError(errors);
    return errors;
  };

  const onSubmitDraft = (form) => {
    const { values } = form;
    const errs = validateSubmitDraft(form);

    if (Object.keys(errs).length !== 0) {
      form.setErrors(errs);
      form.touchAll();
      return;
    }
    form.setIsSubmitting(true);

    onSave({
      ...shipment,
      ...values,
      isDraft: true,
      state: ShipmentState.QUEUE,
      indexNumber: undefined,
      documents: values.documents
        .filter(({ id }) => id)
        .map(({ id }) => ({ id }))
    }, form.setIsSubmitting);
  };

  const carrierForm = useForm({
    initialValues: {
      externalReference: initialExternalReference,
      driver: initialDriver,
      vehicle: initialVehicle,
      trailer: initialTrailer,
      type: ShipmentType.CARRIED,
      notes: initialNotes,
      documents: initialDocuments ? initialDocuments
        .map((document) => ({ ...document, uuid: generateUuid() })) : []
    },
    validate: createFormValidator({
      driver: validateDriver,
      vehicle: validateVehicle,
      trailer: validateTrailer,
      notes: validateOptionalString
    }),
    onChange: (shipment, oldShipment) => {},
    onSubmit: (values) => {
      onSave({
        ...shipment,
        ...values,
        orderSerialNumber: undefined,
        indexNumber: shipmentNumber,
        isDraft: false,
        state,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id }))
      }, carrierForm.setIsSubmitting);
    }
  });

  const handleShipmentNumberChange = ({ target: { value } }) => setShipmentNumber(value);

  const scrollOnError = (errors) => {
    if (errors && Object.keys(errors).length > 0) {
      const elements = document.getElementsByName(Object.keys(errors)[0]);
      if (elements.length > 0) {
        elements[0].scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleSubmit = (event) => {
    const errors = carrierForm.handleSubmit(event);
    scrollOnError(errors);
  };

  const handleState = ({ target: { value } }) => {
    setState(value);
    setStateChange(true);
  };

  const onSaveDraft = (event) => {
    event.preventDefault();
    carrierForm.touchAll();

    try {
      carrierForm.setIsSubmitting(true);

      carrierForm.setErrors({});

      onSubmitDraft(carrierForm);
    } catch (error) {
      if (!(error instanceof FormValidationError)) {
        throw error;
      }
      carrierForm.setErrors(error.errors);
      carrierForm.setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (stateChange) {
      return;
    }
    const { errors } = carrierForm;
    if (Object.keys(errors).length === 0) {
      if (!isUpdating) {
        setState(ShipmentState.READY);
      } else {
        setState(initialState || ShipmentState.READY);
      }
    } else {
      setState(ShipmentState.QUEUE);
    }
  }, [carrierForm.errors]);

  useEffect(() => {
    onFormChange(carrierForm.values);
  }, [carrierForm.values]);

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid className={classes.header} item xs={8}>
          <section className={classes.title}>
            <Typography variant="h4">
              {`${t("webapp:shipment.form.title")} #`}
            </Typography>
            <EditableTypography
              variant="h4"
              maxLength={SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH}
              value={shipmentNumber}
              isDisabled={isUpdating}
              onChange={handleShipmentNumberChange}
              dataCy="shipment-id"
            />
          </section>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <Paper className={classes.content}>
            <ShipmentCarrierForm
              form={carrierForm}
              onDocumentsChange={() => onFormChange(carrierForm.values)}
              apiClient={apiClient}
              newApiClient={newApiClient}
            />
          </Paper>
          <Grid container>
            <Button variant="contained" color="primary" onClick={onBack} className={classes.back}>
              {t("webapp:shipment.form.button.back")}
            </Button>

            <div className={classes.separator}/>

            {isDraft && (
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveDraft}
                className={`${classes.back} ${classes.backSpaced}`}
                disabled={carrierForm.isSubmitting}
              >
                {t("webapp:outcomingOrder.form.button.draft")}
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.back}
              disabled={carrierForm.isSubmitting}
            >
              {t("webapp:shipment.form.button.complete")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ShipmentSummary
            journey={journey}
            shipment={shipment}
            apiClient={newApiClient}
          />

          <RelatedInfo shipment={shipment} />
        </Grid>
      </Grid>
    </form>
  );
};

export default ShipmentForm;
