import React, { useEffect, useState } from "react";

import { deserializeContact } from "@cargotic/api-client";

import IncomingOrderConceptContext from "../../context/IncomingOrderConceptContext";

const key = "incomingOrderConcept";

const IncomingOrderConceptProvider = ({ children }) => {
  const [incomingOrder, setIncomingOrder] = useState(() => {
    const value = localStorage.getItem(key);
    if (value) {
      const parsedValue = JSON.parse(value);

      if (!parsedValue) {
        return null;
      }

      parsedValue.waypoints = parsedValue.waypoints?.map(({ arriveAtFrom, arriveAtTo, ...rest }) => ({
        arriveAtFrom: arriveAtFrom ? new Date(arriveAtFrom) : undefined,
        arriveAtTo: arriveAtTo ? new Date(arriveAtTo) : undefined,
        ...rest
      }));

      if (!parsedValue.waypoints) {
        return null;
      }

      if (parsedValue.customerContact) {
        parsedValue.customerContact = deserializeContact(parsedValue.customerContact);
      }

      return parsedValue;
    }
    return null;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(incomingOrder));
  }, [incomingOrder]);

  return (
    <IncomingOrderConceptContext.Provider value={{
      incomingOrder,
      setIncomingOrder
    }}
    >
      {children}
    </IncomingOrderConceptContext.Provider>
  );
};

export default IncomingOrderConceptProvider;
