import React from "react";
import { useTranslation } from "react-i18next";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  AllInbox as AllInboxIcon,
  LocalShipping as LocalShippingIcon
} from "@material-ui/icons";

import {
  CargoItemTemplateSpecification,
  formatCargoItem
} from "../../../cargotic-core";

const CargoItemListItem = ({ item, ...rest }) => {
  const { t } = useTranslation();
  const Icon = CargoItemTemplateSpecification[item.template]?.fullTruck
    ? LocalShippingIcon
    : AllInboxIcon;

  return (
    <ListItem {...rest}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={formatCargoItem(t, item)} />
    </ListItem>
  );
};

export default CargoItemListItem;
