import React from "react";

import { FormControl, FormHelperText, makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { ToggleButtonGroup } from "@material-ui/lab";

import { hexToRgba } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ palette }) => ({
  group: {
    display: "flex",
    width: "100%",

    "& > *": {
      flex: 1
    },

    "& > .Mui-selected": {
      color: palette.primary.main,
      backgroundColor: "transparent !important",
      borderColor: palette.primary.main,

      "&:hover": {
        backgroundColor: hexToRgba(palette.primary.main, 0.04)
      }
    },

    "& > .MuiToggleButton-root": {
      "&:hover": {
        backgroundColor: alpha(palette.action.active, 0.02)
      }
    }
  }
}));

const FormikToggleButtonGroup = ({
  className,
  form,
  name,
  ...props
}) => {
  const classes = useStyles();

  const error = form.errors[name];
  const value = form.values[name];
  const hasError = error !== undefined;

  const handleChange = (_, value) => {
    if (!value) {
      return;
    }

    form.setValues({
      ...form.values,
      [name]: value
    });
  };

  return (
    <FormControl className={className} fullWidth>
      <ToggleButtonGroup
        {...props}
        className={classes.group}
        value={value}
        onChange={handleChange}
      />
      <FormHelperText error={hasError}>
        {error ?? " "}
      </FormHelperText>
    </FormControl>
  );
};

export default FormikToggleButtonGroup;
