import WaypointType from "./WaypointType";
import {
  parseDate,
  formatDateTime,
  formatPlaceFromComponents,
  formatJourneyPointDateTime,
} from "./common";
import { formatDate } from "@cargotic/common";
import { ShipmentState } from "@cargotic/model";

import {
  CargoItemTemplate,
  CargoItemTemplateSpecification,
  LengthUnit,
  LengthUnitSpecification,
  convertUnit,
} from "../../../multiload/cargotic-core";

const getDrivenThroughAtText = (t, activity, createdAt, isNotDelivered) => {
  const formattedDateTime = formatDateTime(createdAt);
  if (activity === WaypointType.LOADING && !isNotDelivered) {
    return `${t("board.drivenThroughLoadingAt")} ${formattedDateTime}`;
  } else if (activity === WaypointType.UNLOADING && !isNotDelivered) {
    return `${t("board.drivenThroughUnloadingAt")} ${formattedDateTime}`;
  }
  return `${t("board.drivenThroughAt")} ${formattedDateTime}`;
};
const getDrivenThroughText = (t, activity) => {
  if (activity === WaypointType.LOADING) {
    return t("board.drivenThroughLoading");
  } else if (activity === WaypointType.UNLOADING) {
    return t("board.drivenThroughUnloading");
  }
  return t("board.drivenThrough");
};

const getActionFromWaypoint = (cargo) => {
  const cargoEntries = Object.entries(cargo);
  const actions = [];
  if (!cargoEntries) {
    return actions;
  }
  cargoEntries.forEach((entry) => {
    const { action } = entry[1];
    if (actions.filter((val) => val === action).length === 0) {
      actions.push(action);
    }
  });
  return actions;
};

const getAdvancedFormattedPlaceName = (components) => {
  const addressValues = {
    locality: null,
    postal_code: null,
    country: null,
  };
  const preObject = {};
  components.map(({ longName, long_name, shortName, short_name, types }) => {
    preObject[types[0]] = {
      longName: longName ? longName : long_name,
      shortName: shortName ? shortName : shortName,
    };
  });
  if ("sublocality" in preObject) {
    addressValues.locality = preObject.sublocality.longName;
  } else if ("locality" in preObject) {
    addressValues.locality = preObject.locality.longName;
  } else if ("sublocality_level_1" in preObject) {
    addressValues.locality = preObject.sublocality_level_1.longName;
  } else if ("neighborhood" in preObject) {
    addressValues.locality = preObject.neighborhood.longName;
  } else if ("administrative_area_level_2" in preObject) {
    addressValues.locality = preObject.administrative_area_level_2.longName;
  }
  if ("postal_code" in preObject) {
    addressValues.postal_code = preObject["postal_code"].longName;
  }
  if ("country" in preObject) {
    addressValues.country = preObject["country"].shortName
      ? preObject["country"].shortName
      : preObject["country"].longName;
  }

  return `${addressValues.locality ? addressValues.locality : ""}${
    addressValues.postal_code ? `, ${addressValues.postal_code}` : ""
  }${addressValues.country ? `, ${addressValues.country}` : ""}`;
};

const getFormattedPlaceName = (components) =>
  components
    ?.filter(
      ({ types }) =>
        types.includes("sublocality") ||
        types.includes("locality") ||
        types.includes("postal_code") ||
        types.includes("country")
    )
    .sort(({ types: typesA }, { types: typesB }) => {
      if (typesA.includes("country")) {
        return 1;
      }
      if (typesB.includes("country")) {
        return -1;
      }
      return 0;
    })
    .map(({ shortName, longName, types }) =>
      types.includes("country") ? shortName : longName
    )
    .join(", ");

const getActionTypeOfLoading = (loading) => {
  if (!loading.cargo) {
    return WaypointType.NONE;
  }

  const isLoading = loading.cargo.every((c) => c.action === "LOAD");
  const isUnloading = loading.cargo.every((c) => c.action === "UNLOAD");
  if (isLoading) {
    return WaypointType.LOADING;
  }
  if (isUnloading) {
    return WaypointType.UNLOADING;
  }
  return WaypointType.BOTH;
};

const getWaypointsWithCargo = (loadings) =>
  loadings
    .map(({ cargo }) =>
      Object.values(cargo || {}).filter(({ action }) => action === "LOAD")
    )
    .flat();

const getShortCargoWaypointsTitle = (waypoints, translate) =>
  waypoints.map(
    ({ quantity, lengthUnit, length, template, totalWeight, totalVolume }) => {
      const builder = [];

      builder.push(`${quantity}x`);

      if (template === CargoItemTemplate.LDM) {
        if (length) {
          const baseLength = convertUnit(
            length,
            lengthUnit,
            LengthUnit.M,
            LengthUnitSpecification
          );

          builder.push(` ${baseLength} LDM`);
        } else {
          builder.push(` LDM`);
        }
      } else {
        builder.push(` ${translate(`core:cargo.item.template.${template}`)}`);
      }

      if (totalWeight !== undefined) {
        builder.push(`, ${totalWeight} kg`);
      }

      if (totalVolume !== undefined) {
        builder.push(`, ${totalVolume} m³`);
      }

      return builder.join("");
    }
  );

const getShortCargoWaypointsTitleLimited = (waypoints) => waypoints.slice(0, 2);

const mapAndFormatWaypoints = (
  waypoints,
  hasFullDateRange = true,
  hasShorterPlaceTitle = true
) =>
  waypoints.map((loading, index) => {
    const action = getActionTypeOfLoading(loading);
    const formattedComponents = formatPlaceFromComponents(
      loading.place.address.components ?? []
    );
    const currentDate = new Date();
    const arriveAtFrom = loading.arriveAtFrom
      ? new Date(loading.arriveAtFrom)
      : undefined;
    const arriveAtTo = loading.arriveAtTo
      ? new Date(loading.arriveAtTo)
      : undefined;
    const placeAlias = loading.place.alias;
    const placeName = loading.place.name;

    return {
      index,
      id: loading.id,
      isDrivenThrough: loading.isDrivenThrough, // waypoint does not have
      drivenThroughAt:
        loading.drivenThroughAt && loading.isDrivenThrough
          ? new Date(loading.drivenThroughAt)
          : undefined, // waypoint does not have
      formattedDateTime: hasFullDateRange
        ? formatJourneyPointDateTime(arriveAtFrom, arriveAtTo, true)
        : formatDateTime(arriveAtFrom),
      formattedPlace:
        hasShorterPlaceTitle && formattedComponents
          ? formattedComponents
          : loading.place.address.formatted,
      inProgress:
        (arriveAtTo
          ? arriveAtFrom < currentDate && arriveAtTo > currentDate
          : false) && !loading.isDrivenThrough,
      placeAlias: placeAlias,
      placeName: placeName,
      haveError: loading.hasError,
      hasWarning: loading.hasWarning,
      reasonNotDelivered: loading.reasonNotDelivered,
      action,
      contact: loading.contact,
      phoneNumber: loading.phoneNumber,
      note: loading.note,
      cargo: loading.cargo ?? [],
    };
  });

export {
  getActionTypeOfLoading,
  getDrivenThroughAtText,
  getDrivenThroughText,
  getWaypointsWithCargo,
  getFormattedPlaceName,
  getShortCargoWaypointsTitle,
  getShortCargoWaypointsTitleLimited,
  getAdvancedFormattedPlaceName,
  getActionFromWaypoint,
  mapAndFormatWaypoints,
};
