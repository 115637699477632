import React from "react";
import { useTranslation } from "react-i18next";

import { VehicleExpirationType } from "@cargotic/model";
import { formatDate } from "@cargotic/common";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";

import VehicleExpirationIcon from "../VehicleExpirationIcon";
import VehicleExpirationTableItemStatusChip
  from "./VehicleExpirationTableItemStatusChip";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(2)
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    }
  },
  icon: {
    margin: spacing(1),
    fontSize: 40
  },
  chip: {
    display: "flex",
    justifyContent: "center"
  }
}));

const VehicleExpirationTableItem = ({
  expiration: {
    type,
    name,
    createdAt,
    expiresAt,
    note
  },
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  onDeleteButtonClick,
  onEditButtonClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const now = new Date();
  const isExpired = expiresAt <= now;
  const shortenedNote = (() => {
    if (!note) {
      return null;
    }

    if (note.length < 35) {
      return note;
    }

    return note.slice(0, 30).concat("...");
  })();

  return (
    <Paper className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <VehicleExpirationIcon
            className={classes.icon}
            type={type}
            fontSize="large"
            color="action"
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" color="textSecondary">
            {t("webapp:vehicle.title.expiration-type")}
          </Typography>
          <Typography>
            <b>
              {
                type === VehicleExpirationType.OTHER
                  ? name
                  : t(`webapp:vehicle.expiration.${type}`)
              }
            </b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" color="textSecondary">
            {t("webapp:vehicle.title.expiration-expires-at")}
          </Typography>
          <Typography>
            {formatDate(expiresAt, true)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2" color="textSecondary">
            {t("webapp:vehicle.title.expiration-created-at")}
          </Typography>
          <Typography>
            {formatDate(createdAt, true)}
          </Typography>
        </Grid>
        <Grid item className={classes.chip} xs={2}>
          <VehicleExpirationTableItemStatusChip
            status={isExpired ? "expired" : "active"}
          />
        </Grid>
        <Grid item xs={2}>
          {shortenedNote ? (
            <React.Fragment>
              <Typography variant="subtitle2" color="textSecondary">
                {t("webapp:vehicle.title.expiration-note")}
              </Typography>
              <Typography>
                {shortenedNote}
              </Typography>
            </React.Fragment>
          ) : null}
        </Grid>
        <Grid className={classes.actions} item xs={2}>
          <Tooltip
            title={t("webapp:vehicle.title.edit-expiration")}
            disableFocusListener={isEditButtonDisabled}
            disableHoverListener={isEditButtonDisabled}
            disableTouchListener={isEditButtonDisabled}
          >
            <span>
              <IconButton
                disabled={isEditButtonDisabled}
                onClick={onEditButtonClick}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t("webapp:vehicle.title.delete-expiration")}
            disableFocusListener={isDeleteButtonDisabled}
            disableHoverListener={isDeleteButtonDisabled}
            disableTouchListener={isDeleteButtonDisabled}
          >
            <span>
              <IconButton
                disabled={isDeleteButtonDisabled}
                onClick={onDeleteButtonClick}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VehicleExpirationTableItem;
