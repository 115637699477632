enum VehicleFuel {
    PETROL = "petrol",
    DIESEL = "diesel"
}

interface Vehicle {
    model: string;
    vendor: string;
    fuel: VehicleFuel;
    manufacturedAt: Date;
    licensePlate: string;
    vin: string;
    cargoSpaceHeight: number;
    cargoSpaceWidth: number;
    cargoSpaceLength: number;
    maximumLoad: number;
    avatar?: string;
    type?: string;
}

interface VehicleSnapshot extends Vehicle {
    id: number;
    createdAt: Date;
}

export {
    Vehicle,
    VehicleSnapshot,
    VehicleFuel
};
