import React from "react";
import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@material-ui/core";

import VehicleProfileView from "../../VehicleProfileView";

const VehicleProfileBodyNavigation = ({
  view,
  isIncomeTabDisabled,
  isExpenseTabDisabled,
  isExpirationTabDisabled,
  onViewChange
}) => {
  const { t } = useTranslation();

  const handleTabChange = (_, value) => {
    if (onViewChange) {
      onViewChange(value);
    }
  };

  return (
    <div>
      <Tabs
        indicatorColor="primary"
        value={view}
        onChange={handleTabChange}
      >
        <Tab
          label={t("webapp:vehicle.title.overview")}
          value={VehicleProfileView.VEHICLE_OVERVIEW}
        />
        <Tab
          disabled={isIncomeTabDisabled}
          label={t("webapp:vehicle.title.incomes")}
          value={VehicleProfileView.VEHICLE_INCOME_OVERVIEW}
        />
        <Tab
          disabled={isExpenseTabDisabled}
          label={t("webapp:vehicle.title.expenses")}
          value={VehicleProfileView.VEHICLE_EXPENSE_OVERVIEW}
        />
        <Tab
          disabled={isExpirationTabDisabled}
          label={t("webapp:vehicle.title.expirations")}
          value={VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW}
        />
      </Tabs>
    </div>
  );
};

export default VehicleProfileBodyNavigation;
