import clsx from "clsx";
import React, { useRef, useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import { isEmpty } from "../../collection";
import { Optional } from "../../type";

import SuggestionArea from "../SuggestionArea";
import { AutosuggestProps } from "../Autosuggest";

interface AutosuggestSearchProps<T> extends Omit<AutosuggestProps<T>, "getSuggestionText"> {
  search: (text: string) => Promise<T | undefined>;
  getResultText: (value?: T) => string;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: "relative"
  },
  suggestions: {
    position: "absolute",
    width: "100%",
    marginTop: spacing(-2),
    zIndex: 50
  }
}));

function AutosuggestSearch<T>(
  {
    className,
    name,
    value,
    search,
    suggest,
    getResultText,
    suggestionComponent,
    inputComponent: InputComponent,
    onBlur,
    onChange,
    ...other
  }: AutosuggestSearchProps<T>
): React.ReactElement {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();
  const suggestionAreaRef = useRef<HTMLDivElement>();

  const isSuggestionAreaClicked = useRef<boolean>(false);

  const [inputValue, setInputValue] = useState(() => getResultText(value));
  const [suggestions, setSuggestions] = useState<T[]>([]);

  const handleChange = (newValue: Optional<T>): void => {
    if (onChange) {
      onChange({ target: { name: name ?? "", value: newValue } });
    }
  };

  const handleInputBlur = (event: React.FocusEvent): void => {
    if (isSuggestionAreaClicked.current) {
      isSuggestionAreaClicked.current = false;

      if (inputRef.current) {
        inputRef.current.focus();
      }

      return;
    }

    setSuggestions([]);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleInputChange = async (
    {
      target: {
        value: newInputValue
      }
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): Promise<void> => {
    setInputValue(newInputValue);

    if (isEmpty(newInputValue)) {
      setSuggestions([]);
    } else {
      setSuggestions(await suggest(newInputValue));
    }

    handleChange(undefined);
  };

  const handleSuggestionClick = (suggestion: T): void => {
    const newInputValue = getResultText(suggestion);

    setInputValue(newInputValue);
    setSuggestions([]);

    handleChange(suggestion);
  };

  const handleSearch = async (): Promise<void> => {
    if (isEmpty(inputValue)) {
      return;
    }

    setSuggestions([]);

    const result = await search(inputValue);

    if (!result) {
      return;
    }

    const newInputValue = getResultText(result);

    setInputValue(newInputValue);
    handleChange(result);
  };

  const handleKeyPress = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    handleSearch();
  };

  useEffect(() => {
    const { current: element } = suggestionAreaRef;

    if (!element) {
      return () => {};
    }

    const listener = (): void => {
      isSuggestionAreaClicked.current = true;
    };

    element.addEventListener("mousedown", listener);

    return () => {
      element.removeEventListener("mousedown", listener);
    };
  });

  useEffect(() => {
    const newInputValue = getResultText(value);

    if (inputValue !== newInputValue && newInputValue != undefined) {
      setInputValue(newInputValue);
    }
  }, [value]);

  return (
    <div className={clsx(className, classes.root)}>
      {InputComponent({
        ...other,
        name,
        ref: inputRef,
        value: inputValue,
        onBlur: handleInputBlur,
        onChange: handleInputChange,
        onSearch: handleSearch,
        onKeyPress: handleKeyPress
      })}
      <SuggestionArea
        ref={suggestionAreaRef}
        className={classes.suggestions}
        // @ts-ignore
        getSuggestionText={getResultText}
        // @ts-ignore
        suggestionComponent={suggestionComponent}
        suggestions={suggestions}
        // @ts-ignore
        onSuggestionClick={handleSuggestionClick}
      />
    </div>
  );
}

export default AutosuggestSearch;
export {
  AutosuggestSearchProps
};
