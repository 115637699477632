import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { Avatar, Tooltip, makeStyles } from "@material-ui/core";

import {
  buildUserUrl,
  getNameInitials
} from "../../../cargotic-webapp-utility";

import useAuth from "../../../cargotic-webapp/component/hook/useAuth";

const useStyles = makeStyles(({ typography }) => ({
  root: {
    display: "inline-block",
    textDecoration: "none"
  },
  large: {
    width: 50,
    height: 50
  },
  medium: {
    width: 32,
    height: 32,

    fontSize: typography.body1.fontSize
  },
  small: {
    width: 24,
    height: 24,

    fontSize: typography.caption.fontSize
  }
}));

const UserAvatar = ({
  className,
  user: {
    id,
    avatarUrl,
    name
  },
  size = "large",
  isLinked = true
}) => {
  const { user, hasPermission } = useAuth();
  const classes = useStyles();

  const Root = isLinked
    && (user.id === id || hasPermission("resource.statistics.company.read"))
    ? Link
    : "div";

  return (
    <Root className={clsx(classes.root, className)} to={buildUserUrl(id)}>
      <Tooltip title={name}>
        <Avatar
          className={classes[size]}
          alt={name}
          src={avatarUrl}
        >
          {getNameInitials(name)}
        </Avatar>
      </Tooltip>
    </Root>
  );
};

export default UserAvatar;
