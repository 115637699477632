import React, { useState } from "react";

import {
  Chip,
  Fab,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  AddOutlined as OutlinedAddIcon,
  DeleteOutlined as OutlinedDeleteIcon,
  Search as SearchIcon,
  EmojiObjectsOutlined,
  ChevronRight as ChevronRightIcon
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { VehicleType } from "@cargotic/model";

import DataTable from "../../../datatable";
import Page from "../../common/Page";
import SearchTextfield from "../../common/SearchTextfield";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";


const useStyles = makeStyles(({ palette, spacing }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  page: {
    "& tbody > tr > td:last-child > div": {
      justifyContent: "flex-end",
      paddingRight: spacing(1),

      "& > div": {
        width: "initial",

        "&:not(:first-child)": {
          marginLeft: spacing(1)
        }
      }
    }
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)"
  },
  searchText: {
    padding: "5px",
    border: "1px solid gray",
    borderRadius: "2px",
    background: "#FCFCFC"
  }
}));

const Vehicles = ({
  data,
  dataCount,
  selectedColumns,
  ordering,
  search,
  direction,
  loading,
  page,
  rowsPerPage,
  checkedAll,
  canCreateVehicle,
  canDeleteVehicle,
  canUpdateVehicle,
  filter,
  handleSelectVehicleType,
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectAll,
  handleCreateRequest,
  handleDeleteRequest,
  handleUpdateRequest,
  handleMoreRequest,
  handleSelect,
  handleDeselect,
  defaultFilters,
  handleFilterSettingsOpen,
  handleSelectCreatedAtRange,
  handleSelectCreators,
  loadAvailableUsers,
  handleChangeSelectedColumns,
  pageTitle,
  showTips,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const isAnyFilterActive = () => {
    const { types, createdAt, creators } = filter;
    return types !== undefined || createdAt !== undefined || creators !== undefined;
  };

  let vehiclesHeader = [
    {
      name: "manufacturer",
      label: "vehicles.manufacturer",
      isOrderable: true
    },
    {
      name: "model",
      label: "vehicles.model",
      isOrderable: true
    },
    {
      name: "licensePlate",
      label: "vehicles.licensePlate",
      isOrderable: true
    },
    {
      name: "vin",
      label: "vehicles.vin",
      isOrderable: true
    },
    {
      name: "type",
      label: "vehicles.type",
      isOrderable: true
    }
  ];

  let vehiclesActions = [
    {
      column: [
        {
          handleClick: handleUpdateRequest,
          icon: <EditIcon />,
          title: "vehicles.more"
        }
      ]
    },
    {
      column: [
        {
          handleClick: handleMoreRequest,
          icon: <ChevronRightIcon />,
          title: "vehicles.more"
        }
      ]
    }
  ];

  const vehicleTypeSource = [{ title: t("vehicles.chips.cars"), value: VehicleType.CAR }, { title: t("vehicles.chips.trailers"), value: VehicleType.TRAILER }];

  return (
    <>
      <Page
        className={classes.page}
        title={pageTitle}
        actions={(
          <>
            <Fab
              color="primary"
              disabled={loading || !canCreateVehicle}
              onClick={handleCreateRequest}
            >
              <OutlinedAddIcon />
            </Fab>
            <Fab
              className={classes.danger}
              disabled={loading || selected === 0 || !canDeleteVehicle}
              onClick={handleDeleteRequest}
            >
              <OutlinedDeleteIcon />
            </Fab>
          </>
        )}
        tertiaryActions={(
          showTips && <div style={{ background: "ADD" }}>
            <Tooltip title={(
              <>
                <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
                <Typography color="inherit">{t("proTableTip")}</Typography>
              </>
            )
            }
            >
              <IconButton>
                <EmojiObjectsOutlined />
              </IconButton>
            </Tooltip>
          </div>
        )}
      >
        <FilterContainer
          searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("vehicles.searchTooltip")} />}
          showClearButton={isAnyFilterActive}
          handleFilterSettingsOpen={handleFilterSettingsOpen}
          defaultFilters={defaultFilters}
          loading={loading}
          filters={[
            <FilterStaticDropdown
              id="types"
              key="types"
              placeholderTitle={t("vehicles.vehicleType")}
              value={filter.types ?? []}
              onChange={(arr) => handleSelectVehicleType(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectVehicleType(vehicleTypeSource.map(item => item.value))}
              onClear={() => handleSelectVehicleType(undefined)}
              source={vehicleTypeSource}
            />,
            <FilterDateRangeDropdown
              id="createdAt"
              key="createdAt"
              onChange={(value) => handleSelectCreatedAtRange(value)}
              value={filter.createdAt ? [filter.createdAt.from, filter.createdAt.to] : []}
              onClear={() => handleSelectCreatedAtRange(undefined)}
              placeholderTitle={t("contacts.createdAtRange")}
            />,
            <UserFilterDropdown
              id="creators"
              key="creators"
              onChange={(value) => handleSelectCreators(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectCreators(all)}
              value={filter.creators ?? []}
              placeholderTitle={t("vehicles.creator")}
              search={(text) => loadAvailableUsers(text)}
              onClear={() => handleSelectCreators(undefined)}
              getTitle={(item) => item.name}
            />
          ]}
          onClear={handleDeselect}
          onOpenSelectColumnsDialog={() => setIsOpenSelectColumnsDialog(true)}
        />
        <DataTable
          headers={vehiclesHeader}
          actions={vehiclesActions}
          data={data}
          dataCount={dataCount}
          selectedColumns={selectedColumns}
          loading={loading}
          ordering={ordering}
          direction={direction}
          checkedAll={checkedAll}
          page={page}
          rowsPerPage={rowsPerPage}
          onSort={handleSort}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSelectedColumns={handleChangeSelectedColumns}
          isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
          setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        />
      </Page>
    </>
  );
};

export default Vehicles;
