import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationAlert } from "@cargotic/webapp-component";

const DeleteInvoiceConfirmationAlert = ({
    selectedInvoices,
    isOpen,
    onClose,
    onSubmit
}) => {
    const { t } = useTranslation();
    const title = !selectedInvoices?.length
        ? t("invoices.deleteInvoice.title")
        : t("invoices.deleteInvoice.titlem");
    const content = !selectedInvoices?.length
        ? t("invoices.deleteInvoice.prompt")
        : t("invoices.deleteInvoice.promptm");

    return (
        <ConfirmationAlert
            title={title}
            content={content}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};

export default DeleteInvoiceConfirmationAlert;
