import React from "react"

import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"

export function ActionField({ title, icon: Icon, children, onClick }) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Tooltip title={title}>
          <Fab color="primary" size="small" onClick={onClick}>
            <Icon />
          </Fab>
        </Tooltip>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  )
}

export default ActionField
