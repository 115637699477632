import React from "react";
import {useTranslation} from "react-i18next";

import {AppBar, MenuItem, Select, Tab, Tabs} from "@material-ui/core";
import WarehouseView from "./WarehouseView";


const WarehouseBodyNavigation = ({
																	 view,
																	 onViewChange,
																	 handleChangeSelectedWarehouseId,
																	 selectedWarehouseId,
																	 warehouses,
																	 classes,
																	 tabClasses
																 }) => {
	const {t} = useTranslation();

	const handleTabChange = (_, value) => onViewChange(value, selectedWarehouseId);

	const createWarehousesMenuItems = (warehouses) => warehouses
			.map(({id, name}) => (
					<MenuItem value={id} key={id}>
						{name}
					</MenuItem>
			));

	const WarehouseSelector = ({warehouses}) => {
		return (
				<div style={{marginTop: "15px", marginBottom: "15px"}}>
					{warehouses && warehouses.length > 0 && <Select
							variant="standard"
							value={selectedWarehouseId}
							className={classes.select}
							onChange={({target: {value}}) => handleChangeSelectedWarehouseId(value)}
					>
						{createWarehousesMenuItems(warehouses)}
					</Select>}
					{!warehouses && <Select
							variant="standard"
							value={0}
							className={classes.select}
					>
						<MenuItem value={0} key={0}>
							...
						</MenuItem>
					</Select>}
				</div>
		);
	}

	return (
			<>
				<WarehouseSelector
						warehouses={warehouses}
				/>
				<AppBar position="static" className={tabClasses.root}>
					<Tabs value={view}
								onChange={handleTabChange}
								aria-label="tabs"
					>
						<Tab label={t("warehouses.incomingOrders")}
								 className={tabClasses.tab}
								 value={WarehouseView.INCOMING_ORDERS}
						/>
						<Tab label={t("warehouses.orderHistory")}
								 className={tabClasses.tab}
								 value={WarehouseView.ORDERS_HISTORY}
						/>
						<Tab label={t("warehouses.warehouseman.title")}
								 className={tabClasses.tab}
								 value={WarehouseView.WAREHOUSEMEN}
						/>
						<Tab label={t("warehouses.shipments.title")}
								 className={tabClasses.tab}
								 value={WarehouseView.INCOMING_SHIPMENTS}
						/>
					</Tabs>
				</AppBar>

				{(view === WarehouseView.INCOMING_SHIPMENTS || view === WarehouseView.OUTCOMING_SHIPMENTS) && (
						<AppBar position="static" className={tabClasses.subTabRoot}>
							<Tabs value={view}
										onChange={handleTabChange}
										aria-label="tabs"
							>
								<Tab label={t("warehouses.incomingShipments")}
										 className={tabClasses.tab}
										 value={WarehouseView.INCOMING_SHIPMENTS}
								/>
								<Tab label={t("warehouses.outcomingShipments")}
										 className={tabClasses.tab}
										 value={WarehouseView.OUTCOMING_SHIPMENTS}
								/>
							</Tabs>
						</AppBar>
				)}
			</>
	);
};

export default WarehouseBodyNavigation;
