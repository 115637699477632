import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Divider } from "@material-ui/core";

const DashboardDrawerItem = ({
  icon, title, path, selected, showText, showAction, action, classes
}) => {
  const { t } = useTranslation();

  return (
    <ListItem button className={classes.zeroPadding} selected={selected} component={Link} to={path}>
      <ListItemIcon className={classes.avatar}>
        {icon}
      </ListItemIcon>
      {showText ? (
        <ListItemText
          primary={
            <Typography
              variant="body2"
              noWrap>{
                t(title)}
            </Typography>
          } />
      ) : null}
      {showAction ? action : null}
    </ListItem>
  );
};

export default DashboardDrawerItem;
