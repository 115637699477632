import React from "react";

import { makeStyles } from "@material-ui/core";

import CargoEditorCargoItemListItem from "./CargoEditorCargoItemListItem";

const useStyles = makeStyles(() => ({
  root: {
    "& > div:not(:first-child)": {
      marginTop: 10
    }
  }
}));

const CargoEditorCargoItemList = ({
  cargo,
  selectedCargoItemId,
  isCargoItemLoadingDisabled,
  onCargoItemChange,
  onCargoItemCopy,
  onCargoItemDelete,
  onCargoItemReload,
  onCargoItemSelect,
  onCargoItemUnload
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {cargo.map(item => {
        const { id, itemId, action } = item;

        const isExpaded = id === selectedCargoItemId;

        return (
          <CargoEditorCargoItemListItem
            key={id}
            item={item}
            isExpanded={isExpaded}
            isLoadingDisabled={isCargoItemLoadingDisabled}
            onChange={
              (name, type, value) => onCargoItemChange(id, name, type, value)
            }
            onCollapse={() => onCargoItemSelect(undefined)}
            onCopy={() => onCargoItemCopy(id)}
            onDelete={() => onCargoItemDelete(id, itemId, action)}
            onExpand={() => onCargoItemSelect(id)}
            onReload={() => onCargoItemReload(id)}
            onUnload={() => onCargoItemUnload(id)}
          />
        );
      })}
    </div>
  );
};

export default CargoEditorCargoItemList;
