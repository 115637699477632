import React from "react";
import PropTypes from 'prop-types'

import { getIn } from 'formik'

import { TextField } from "@material-ui/core";

function FormikTextField({ value, form, name, handleChange, bypassIsTouched, ...props }) {
  const val = value || getIn(form.values, name);
  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);

  return (
    <TextField
      {...props}
      name={name}
      value={val}
      error={(bypassIsTouched || isTouched) && error !== undefined}
      helperText={(bypassIsTouched || isTouched) && error !== undefined ? error : undefined}
      onBlur={form.handleBlur}
      onChange={handleChange || form.handleChange}
    />
  );
};

FormikTextField.propTypes = {
  bypassIsTouched: PropTypes.bool
}

FormikTextField.defaultProps = {
  bypassIsTouched: false
}

export default FormikTextField;
