import React from "react";

import { FormControl, FormLabel, RadioGroup } from "@material-ui/core";

const FormikRadioGroup = ({
  form,
  name,
  label,
  ...props
}) => {
  const value = form.values[name];

  return (
    <FormControl>
      <FormLabel>
        {label}
      </FormLabel>
      <RadioGroup
        {...props}
        name={name}
        value={value}
        onChange={form.handleChange}
      />
    </FormControl>
  );
};

export default FormikRadioGroup;
