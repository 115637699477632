import normalizeGoogleAddressComponents
  from "./normalizeGoogleAddressComponents";

import { Place, PlaceAddress } from "../../cargotic-core";
import { fetchGooglePlaceDetail } from "../../cargotic-map";

const REQUIRED_FIELDS = [
  "name",
  "address_component",
  "formatted_address",
  "geometry.location",
  "photos"
];

const normalizeGooglePlace = async (placeService, googleId) => {
  const {
    name,
    address_components: googleComponents,
    formatted_address: formatted,
    geometry: { location },
    photos
  } = await fetchGooglePlaceDetail(placeService, googleId, REQUIRED_FIELDS);

  const components = normalizeGoogleAddressComponents(googleComponents);
  const latitude = location.lat();
  const longitude = location.lng();
  const address = PlaceAddress({ formatted, components });
  const thumbnail = photos ? photos[0].getUrl({}) : undefined;

  return Place({
    googleId,
    name,
    address,
    latitude,
    longitude,
    thumbnail
  });
};

export default normalizeGooglePlace;
