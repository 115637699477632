import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { MenuItem, Paper, Typography, Box, Fab } from "@material-ui/core";
import {
  Add,
  CleaningServicesSharp,
  ConstructionOutlined,
  Remove,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";

import { ContactType } from "@cargotic/model-deprecated";
import { EditableTypography } from "@cargotic/common-deprecated";

import { Currency } from "@cargotic/webapp/currency";
import { convertBBANFormatToCz } from "@cargotic/webapp/utility/banking";
import Page from "@cargotic/webapp/component/common/Page";
import {
  Subject,
  Currency,
  PaymentMethod,
  InvoiceVatType,
  ShipmentInvoiceType,
} from "@cargotic/webapp/component/enums/enums";
import ContactFormAutosuggestTextField from "@cargotic/webapp/contact/component/ContactFormAutosuggestTextField";

import { useApiClient } from "@cargotic/webapp-component";
import FormikTextField from "@cargotic/webapp-component/component/FormikTextField";
import FormikSelect from "@cargotic/webapp-component/component/FormikSelect";
import FormikDatePicker from "@cargotic/webapp-component/component/FormikDatePicker";
import ContactEditor from "@cargotic/webapp-contact/component/ContactEditor";

import { formatDate } from "@cargotic/common-deprecated/date";

import {
  createShipmentInvoice,
  updateShipmentInvoice,
} from "@cargotic/webapp/resource";
import FormButtons from "@cargotic/webapp-component/component/FormButtons";

import InvoiceItemForm from "../InvoiceItems/InvoiceItemForm";

import InvoiceItems from "../InvoiceItems";
import { renderTextField, renderSelect } from "../formHelpers";

import ActionField from "./ActionField";
import { useDialog } from "@cargotic/webapp-component/hook";
import {
  getInvoiceFormValidationSchema,
  getUserFormValidationSchema,
} from "./validationSchemas";

const OUTPUT_DATE_FORMAT = "yyyy-MM-dd";

const getPageTitle = (type, t) => {
  if (type === ShipmentInvoiceType.RECEIVED) {
    return (
      <Typography variant="h4" component="span">
        {t("invoices.invoice.invoiceIn")}
      </Typography>
    );
  }

  if (type === ShipmentInvoiceType.ISSUED) {
    return (
      <Typography variant="h4" component="span">
        {t("invoices.invoice.invoiceOut")}
      </Typography>
    );
  }
};

const getPageHeader = (
  type,
  t,
  invoiceNumber,
  handleSetInvoiceNumber,
  isUpdating
) => (
  <Box display="flex" pb={2}>
    <Box display="inline" pr={2}>
      {getPageTitle(type, t)}
    </Box>
    <EditableTypography
      variant="h4"
      component="span"
      maxLength={12}
      value={invoiceNumber}
      isDisabled={isUpdating}
      onChange={({ target: { value } }) => {
        handleSetInvoiceNumber(value);
      }}
      dataCy="invoice-number"
    />
  </Box>
);

const getContactFromInvoice = (invoice, invoiceType) => {
  if (invoiceType === ShipmentInvoiceType.RECEIVED) {
    return invoice?.supplier;
  }

  if (invoiceType === ShipmentInvoiceType.ISSUED) {
    return invoice?.customer;
  }
};

const getPrimaryBankAccountId = (bankAccounts) =>
  bankAccounts?.find((account) => account.isPrimary)?.id;

const getDefaultBankAccountId = (bankAccounts) =>
  getPrimaryBankAccountId(bankAccounts) || bankAccounts?.[0]?.id;

const getInitialBankAccountId = (invoice, bankAccounts) =>
  invoice?.bankAccountId || getDefaultBankAccountId(bankAccounts);

export function InvoiceForm({
  invoice,
  bankAccounts,
  invoiceType,
  currentUserId,
  invoiceNumber,
  handleSetInvoiceNumber,
  handleSetCurrency,
  currency,
  submitButtonLabel,
  submitButtonProps,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApiClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, handleSetIsUpdating] = useState(false);

  const [
    isContactEditorOpen,
    handleOpenContactEditor,
    handleCloseContactEditor,
    selectedContact,
  ] = useDialog();

  const invoiceForm = useFormik({
    initialValues: {
      contact: getContactFromInvoice(invoice, invoiceType),
      externalReference: invoice?.externalReference,
      releaseDate: invoice?.releaseDate || new Date(),
      dueDate: invoice?.dueDate || new Date(),
      duzp: invoice?.duzp || new Date(),
      currency: invoice?.currency || currency,
      bankAccountId: getInitialBankAccountId(invoice, bankAccounts),
      paymentMethod:
        invoice?.paymentMethod || Object.values(PaymentMethod)?.[0],
      items: [
        ...(Array.isArray(invoice?.items) && invoice?.items.length > 0
          ? invoice?.items
          : [
              {
                id: Math.random(),
                ...InvoiceItems.DEFAULT_INVOICE_ITEM,
              },
            ]),
      ],
      note: invoice?.note,
      vs: invoice?.vs || invoiceNumber,
    },
    validationSchema: getInvoiceFormValidationSchema(t),
    onSubmit: async (values, actions) => {
      const { items, contact, dueDate, releaseDate, duzp, ...restValues } =
        values;

      const resolvedValues = {
        indexNumber: invoiceNumber,
        type: invoiceType,
        customer:
          invoiceType === ShipmentInvoiceType.RECEIVED
            ? currentUserId
            : contact?.id,
        supplier:
          invoiceType === ShipmentInvoiceType.ISSUED
            ? currentUserId
            : contact?.id,
        items: items.map(
          ({ id, ...restInoviceItemValues }) => restInoviceItemValues
        ),

        dueDate: formatDate(new Date(dueDate), OUTPUT_DATE_FORMAT),
        releaseDate: formatDate(new Date(releaseDate), OUTPUT_DATE_FORMAT),
        duzp: formatDate(new Date(duzp), OUTPUT_DATE_FORMAT),
        ...restValues,
      };
      try {
        if (invoice?.id) {
          await updateShipmentInvoice(invoice?.id, resolvedValues);
        } else {
          await createShipmentInvoice(resolvedValues);
        }

        enqueueSnackbar(t("invoices.invoice.changesSaved"), {
          variant: "success",
        });
        history.push(`/invoices?type=${invoiceType}`);
      } catch (error) {
        console.error(error);

        const { response } = error;
        const data = response?.data || {};
        let errorMessage;

        switch (data.code) {
          case "invoice/index-number-already-exists":
            errorMessage = t("invoices.indexNumberAlreadyExists");
            break;
          default:
            errorMessage = t("invoices.submitError");
            break;
        }

        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      }
    },
  });

  const invoiceFormValidationSchema = useMemo(
    () => getInvoiceFormValidationSchema(t),
    [getInvoiceFormValidationSchema]
  );

  const handleClose = useCallback(() => {
    history.push("/invoices");
  }, [history]);

  const handleContactEditorSubmit = async ({ id: contactId, ...contact }) => {
    handleCloseContactEditor();

    try {
      let result;

      if (contactId) {
        result = await client.contact.putContact({
          contactId,
          contact,
        });
      } else {
        result = await client.contact.postContact({ contact });
      }
      invoiceForm.setFieldValue("contact", result);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("invoices.invoice.contact.error.submit"), {
        variant: "error",
      });
    }
  };

  const bankAccountOptions = useMemo(() => {
    return bankAccounts?.map((bankAccount) => (
      <MenuItem key={bankAccount.id} value={bankAccount.id}>
        {convertBBANFormatToCz(bankAccount.bban)}
      </MenuItem>
    ));
  }, [bankAccounts]);

  const currenciesOptions = useMemo(
    () =>
      Object.keys(Currency).map((currency) => (
        <MenuItem key={currency} value={currency}>
          {currency}
        </MenuItem>
      )),
    []
  );

  const paymentMethodOptions = useMemo(
    () =>
      Object.values(PaymentMethod).map((paymentMethodValue) => (
        <MenuItem key={paymentMethodValue} value={paymentMethodValue}>
          {t(`enum.PaymentMethod.${paymentMethodValue}`)}
        </MenuItem>
      )),
    []
  );

  useEffect(() => {
    if (invoiceForm.dirty) {
      invoiceForm.setFieldValue(
        "bankAccountId",
        getDefaultBankAccountId(bankAccounts)
      );
    }
  }, [bankAccounts]);

  return (
    <Page>
      <>
        {getPageHeader(
          invoiceType,
          t,
          invoiceNumber,
          handleSetInvoiceNumber,
          false
        )}
        <Paper>
          <Box pt={3} pb={3}>
            <Box pl={1} pr={7}>
              <ActionField
                title={t("invoices.invoice.addContact")}
                icon={EditIcon}
                onClick={() => {
                  handleOpenContactEditor(
                    invoiceForm.values.contact || {
                      type: ContactType.CUSTOMER,
                    }
                  );
                }}
              >
                <ContactFormAutosuggestTextField
                  form={invoiceForm}
                  contactType={ContactType.CUSTOMER}
                  apiClient={client}
                  name="contact"
                  label={t("webapp:shipment.form.label.contact")}
                  fullWidth
                  required
                  data-cy="customer-contact"
                />
              </ActionField>
            </Box>

            <Box pr={7} pl={8}>
              {invoiceType === ShipmentInvoiceType.RECEIVED && (
                <Box pb={1}>
                  {renderTextField(
                    invoiceForm,
                    "externalReference",
                    t("invoices.invoice.externalReference"),
                    false
                  )}
                </Box>
              )}

              <Box pb={1}>
                <FormikDatePicker
                  form={invoiceForm}
                  name="releaseDate"
                  label={t("invoices.invoice.releaseDate")}
                  format="dd.MM.yyyy"
                  fullWidth
                  required
                />
              </Box>

              <Box pb={1}>
                <FormikDatePicker
                  form={invoiceForm}
                  name="duzp"
                  label={t("invoices.invoice.dateOfTaxableSupply")}
                  format="dd.MM.yyyy"
                  fullWidth
                  required
                />
              </Box>

              <Box pb={1}>
                <FormikDatePicker
                  form={invoiceForm}
                  name="dueDate"
                  label={t("invoices.invoice.dueDate")}
                  format="dd.MM.yyyy"
                  fullWidth
                  required
                />
              </Box>

              <Box pb={1}>
                {renderSelect(
                  invoiceForm,
                  "bankAccountId",
                  invoiceType === ShipmentInvoiceType.ISSUED
                    ? t("invoices.invoice.number")
                    : t("invoices.invoice.paymentNumber"),
                  bankAccountOptions
                )}
              </Box>

              <Box pb={1}>
                {renderSelect(
                  invoiceForm,
                  "paymentMethod",
                  t("invoices.invoice.paymentMethod"),
                  paymentMethodOptions
                )}
              </Box>

              <Box pb={1}>
                {renderTextField(
                  invoiceForm,
                  "vs",
                  t("invoices.invoice.vs"),
                  false
                )}
              </Box>

              <Box pb={1}>
                {renderTextField(
                  invoiceForm,
                  "note",
                  t("invoices.invoice.note"),
                  false
                )}
              </Box>

              <Box pb={1}>
                {renderSelect(
                  invoiceForm,
                  "currency",
                  t("invoices.invoice.currency"),
                  currenciesOptions,
                  true,
                  handleSetCurrency
                )}
              </Box>
            </Box>

            <Box pl={1} pr={1}>
              <FormikProvider value={invoiceForm}>
                <FieldArray name="items">
                  {(helpers) => (
                    <InvoiceItems form={invoiceForm} helpers={helpers} />
                  )}
                </FieldArray>
              </FormikProvider>
            </Box>

            <Box pl={7}>
              <FormButtons
                form={invoiceForm}
                submitButtonLabel={submitButtonLabel}
                submitButtonProps={submitButtonProps}
                onSubmit={invoiceForm.submitForm}
                onClose={handleClose}
              />
            </Box>

            <ContactEditor
              initialValue={selectedContact}
              isOpen={isContactEditorOpen}
              type={ContactType.CUSTOMER}
              onClose={handleCloseContactEditor}
              onSubmit={handleContactEditorSubmit}
            />
          </Box>
        </Paper>
      </>
    </Page>
  );
}

InvoiceForm.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  invoiceNumber: PropTypes.string,
  handleSetInvoiceNumber: PropTypes.func.isRequired,
  handleSetCurrency: PropTypes.func.isRequired,
  currency: PropTypes.oneOf(Object.values(Currency)).isRequired,
  invoice: PropTypes.object,
  invoiceType: PropTypes.string,
  bankAccounts: PropTypes.arrayOf(PropTypes.object),
  submitButtonLabel: PropTypes.string,
  submitButtonProps: PropTypes.object,
};

InvoiceForm.defaultProps = {
  invoice: undefined,
  invoiceNumber: undefined,
  bankAccounts: undefined,
  invoiceType: undefined,
  currency: Currency.CZK,
  submitButtonLabel: undefined,
  submitButtonProps: undefined,
};

export default InvoiceForm;
