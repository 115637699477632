import React from "react";
import { Avatar, Tooltip } from "@material-ui/core";
import { getNameInitials } from "../../../../utility/common";
import { AvatarGroup } from "@material-ui/lab";
import BoardCardAvatar from "./BoardCardAvatar";

const BoardCardAvatarGroup = ({
  t,
  createdBy,
  carrier,
  customer,
  driver
}) => {
  const creatorName = `${createdBy.firstName} ${createdBy.lastName}`;
  return (
    <AvatarGroup>
      <BoardCardAvatar
        tooltip={t("shipments.creator")}
        name={creatorName}
        avatar={createdBy.avatarUrl}
      />
      {carrier ?
        <BoardCardAvatar
          tooltip={t('shipments.carrier')}
          name={carrier.name}
          avatar={carrier.avatarUrl}
        />
        : null}
      {customer ?
        <BoardCardAvatar
          tooltip={t('shipments.customer')}
          name={customer.name}
          avatar={customer.avatarUrl}
        />
        : null}
      {driver ?
        <BoardCardAvatar
          tooltip={t('shipments.driver')}
          name={driver.name}
          avatar={driver.avatarUrl}
        />
        : null}
    </AvatarGroup>
  );
}

export default BoardCardAvatarGroup;
