import React from "react";

import {Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  preview: {
    backgroundSize: "contain",
    width: "100%",
    height: "auto"
  }
}));

const AttachmentPreview = ({ url, name}) => {
  const classes = useStyles();

  return (
    <Grid item xs={11}>
      {name.includes('png') || name.includes('jpg') || name.includes('jpeg') ?
        <img src={url} className={classes.preview}  alt={name}/>
        :
        <iframe
          src={url}
          height={"100%"}
          width={"100%"}
        ></iframe>
      }
    </Grid>
  );
};

export default AttachmentPreview;
