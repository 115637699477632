import {
	differenceInDays,
	endOfMonth,
	isBefore,
	parseISO,
	startOfMonth,
} from "date-fns";
import React, { useCallback, useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { Link, makeStyles, Typography } from "@material-ui/core";

import { ContactType } from "@cargotic/model";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { flatten } from "lodash";
import { CargoPackagesContext } from "@cargotic/webapp/component/core/WarehouseOrders/ReceiveIncomingOrdersDialog";
import useRouter from "../../hook/useRouter";
import useTable from "../../../datatable/useTable";
import IncomingOrdersDateTime from "../IncomingOrders/IncomingOrdersDateTime";
import IncomingOrdersRoute from "../IncomingOrders/IncomingOrdersRoute";
import IncomingOrdersRouteContact from "../IncomingOrders/IncomingOrdersRouteContact";

import useAuth from "../../hook/useAuth";
import { addUrlParam, getTableUrlParams } from "../../../utility/window";

import { loadFilters, storeFilters } from "../../../storage";

import {
	createMatchQueryWarehouseOrders,
	createPlacesQuery,
	createShipmentInvoice,
	exportOrdersRinkai,
	createGetBoxes,
	createStateSuggestQuery,
	createMatchQueryIncomingOrders,
} from "../../../resource";
import IncomingOrdersDeleteDialog from "../IncomingOrders/IncomingOrdersDeleteDialog";

import { useApiClient } from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";
import IncomingOrdersOutcomingOrderReference from "../IncomingOrders/IncomingOrdersOutcomingOrderReference";
import ReceiveIncomingOrders from "./ReceiveIncomingOrders";
import WarehouseOrderTableRow from "./WarehouseOrderTableRow";
import { AssociatedWarehouseOrdersContext } from "./WarehouseOrders";

const useStyles = makeStyles(({ palette }) => ({
	journeyPointActionUpIcon: {
		fill: "#009688",
		height: 20,
		width: 20,
	},
	journeyPointActionDownIcon: {
		fill: palette.error.main,
		height: 20,
		width: 20,
	},
	carriedForwarderIcon: {
		fill: "rgba(0,0,0,0.56)",
	},
	inlineContent: {
		display: "inline-flex",
	},
	warning: {
		fill: "silver",
	},
	error: {
		fill: palette.error.main,
		color: palette.error.main,
	},
	success: {
		fill: "#009688",
	},
}));

const ReceiveIncomingOrdersContainer = ({
	onSelectCargoItemPackages,
	handleReloadIncomingOrders,
	handleSetPackageIds,
	onIncomingOrdersReload,
	handleSetAreSelectedAll,
	warehouseId,
}) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [incomingOrders, setIncomingOrders] = useState([]);
	const [associatedWarehouseOrders, setAssociatedWarehouseOrders] = useContext(
		AssociatedWarehouseOrdersContext
	);
	const [allPagesSelected, setAllPagesSelected] = useState(false);
	const [allIds, setAllIds] = useState([]);

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const {
		location: { search: routerSearch },
	} = useRouter();

	const { searchText: initSearchText, filter: initFilter } =
		getTableUrlParams(routerSearch);

	if (initFilter.lastWaypointDateFrom) {
		initFilter.lastWaypointDateFrom = new Date(initFilter.lastWaypointDateFrom);
		initFilter.lastWaypointDateTo = new Date(initFilter.lastWaypointDateTo);
	}

	const [additionalFilter, setAdditionalFilter] = useState(initFilter);
	const [
		isIncomingOrdersExportDialogOpen,
		setIsIncomingOrdersExportDialogOpen,
	] = useState(false);
	const [
		isIncomingOrdersExportProcessing,
		setIsIncomingOrdersExportProcessing,
	] = useState(false);
	const [isIncomingOrdersExportDone, setIsIncomingOrdersExportDone] =
		useState(false);
	const [incomingOrdersExportLink, setIncomingOrdersExportLink] =
		useState(null);
	const [search, setSearch] = useState(initSearchText);
	const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
	const [defaultFilters, setDefaultFilters] = useState([]);
	const [bankAccounts, setBankAccounts] = useState([]);

	const handleDeselect = () => setAdditionalFilter({});

	const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
	const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

	const onFilterSettingsSubmit = async (value) => {
		setIsFilterSettingsOpen(true);
		storeFilters("receive-incoming-orders", value);
		setDefaultFilters(expandFilters(value, availableFilters));
		setIsFilterSettingsOpen(false);
	};

	const defaultFilterValues = [
		"customers",
		"loadingDate",
		"unloadingDate",
		"creators",
		"customerPrice",
		"places",
		"cargo",
		"createdAt",
	];
	const availableFilters = [
		{
			label: t("contacts.customer"),
			value: "customers",
		},
		{
			label: t("incomingOrders.loadingsDateRange"),
			value: "loadingDate",
		},
		{
			label: t("incomingOrders.unloadingsDateRange"),
			value: "unloadingDate",
		},
		{
			label: t("incomingOrders.creationDate"),
			value: "createdAt",
		},
		{
			label: t("incomingOrders.customerPrice"),
			value: "customerPrice",
		},
		{
			label: t("incomingOrders.creator"),
			value: "creators",
		},
		{
			label: t("incomingOrders.cargo"),
			value: "cargo",
		},
		{
			label: t("shipments.places"),
			value: "places",
		},
		{
			label: t("incomingOrders.boxes"),
			value: "boxes",
		},
		{
			label: t("incomingOrders.state"),
			value: "state",
		},
	];

	const classes = useStyles();
	const apiClient = useApiClient();

	let reloadDelay;
	let storeSearchDelay;

	const transformFilter = (filter) => ({
		...filter,
		isDraft: false,
		creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
		drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
		carriers: filter.carriers ? filter.carriers.map(({ id }) => id) : undefined,
		customers: filter.customers
			? filter.customers.map(({ id }) => id)
			: undefined,
		state: filter.state ? filter.state.map(({ id }) => id) : undefined,
		vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined,
		places: filter.places ? filter.places.map(({ id }) => id) : undefined,
		boxes: filter.boxes ? filter.boxes.map(({ box }) => box) : undefined,
	});

	const isDue = (dateString) =>
		dateString && isBefore(parseISO(dateString), new Date());
	const getOverdueDays = (dateString) =>
		dateString ? differenceInDays(new Date(), parseISO(dateString)) : undefined;

	const handleSelectAllOnPage = (bool) => {
		setAllPagesSelected(bool);
	};

	const getIdsArray = (objects) => {
		const objectsIds = [];
		objects.map((object) => {
			objectsIds.push(object.id);
		});
		return objectsIds;
	};

	const reduceIncomingOrdersPackages = (
		incomingOrders,
		associatedWarehouseOrders
	) => {
		let receivedCargoItems = [];
		associatedWarehouseOrders.map((awo) => {
			receivedCargoItems.push(
				awo.warehouseOrder.cargoItemPackages.filter(
					(cip) => cip.receivedDate !== null
				)
			);
		});
		receivedCargoItems = flatten(receivedCargoItems).map((x) => x.id);

		const reducedIncomingOrders = incomingOrders
			.map((incomingOrder) => {
				const cargos = incomingOrder.journey.waypoints[0].cargo.map((cargo) => {
					const packages = cargo.packages.filter(
						(p) => !receivedCargoItems.includes(p.packageId)
					);
					return { ...cargo, packages };
				});

				return {
					...incomingOrder,
					journey: {
						...incomingOrder.journey,
						waypoints: [
							{
								...incomingOrder.journey.waypoints[0],
								cargo: cargos,
							},
							...incomingOrder.journey.waypoints.slice(
								1,
								incomingOrder.journey.waypoints.length
							),
						],
					},
				};
			})
			.filter((io) =>
				io.journey.waypoints[0].cargo.some((cargo) => cargo.packages.length > 0)
			);

		return reducedIncomingOrders;
	};

	const reloadIncomingOrders = useCallback(
		async (offset, limit, ordering) => {
			const filter = transformFilter(additionalFilter);

			try {
				const incomingOrdersResponse = await createMatchQueryIncomingOrders({
					query: {
						match: {
							search,
							...filter,
							getPackageIds: true,
							notInWarehouseId: warehouseId,
						},
						orderBy: ordering,
						offset,
						limit,
					},
				});
				setAllIds(incomingOrdersResponse.allIds || []);

				const warehouseOrdersResponse = await createMatchQueryWarehouseOrders({
					match: {
						search,
						warehouseId,
						incomingOrderIds: getIdsArray(incomingOrdersResponse.matches),
					},
					orderBy: ordering,
					offset,
					limit,
				});

				console.log(incomingOrdersResponse.matches, warehouseOrdersResponse.matches);
				// const reducedPackages = reduceIncomingOrdersPackages(
				// 	incomingOrdersResponse.matches,
				// 	warehouseOrdersResponse.matches
				// );
				const reducedPackages = incomingOrdersResponse.matches;
				setAssociatedWarehouseOrders(warehouseOrdersResponse.matches);

				if (incomingOrdersResponse.total === 0 && offset !== 0) {
					handleChangePage(undefined, 0);
				}

				handleReloadIncomingOrders(reducedPackages);
				handleSetPackageIds(incomingOrdersResponse.packageIds ? incomingOrdersResponse.packageIds.filter(({ incomingOrderId }) => !warehouseOrdersResponse.matches.find((val) => val.incomingOrderId === incomingOrderId)) : []);

				const updatedIncomingOrders = reducedPackages.map((item) => {
					item.isDisabled = item.isDisabled === 1;
					item.selected = false;

					item.tableCells = [
						{
							render: (
								<Typography variant="body2">
									{" "}
									#
									{item.indexNumber}

								</Typography>
							),
						},
						{
							render: item.customerContact ? (
								<Typography variant="body2">
									<Link
										component={RouterLink}
										to={`/contacts/${item.customerContact.id}`}
									>
										{item.customerContact.name ||
											item.customerContact.email ||
											item.customerContact.phoneNumber}
									</Link>
								</Typography>
							) : undefined,
						},
						{
							render: <IncomingOrdersDateTime incomingOrder={item} />,
						},
						{
							render: <IncomingOrdersRoute incomingOrder={item} />,
						},
						{
							render: <IncomingOrdersRouteContact incomingOrder={item} />,
						},
						{
							render: (
								<IncomingOrdersOutcomingOrderReference incomingOrder={item} />
							),
						},
						{
							render: (
								<Typography variant="body2">
									{item.externalReference}
								</Typography>
							),
						},
						{
							render: (
								<Typography variant="body2">{item.customerPrice}</Typography>
							),
						},
						{
							render: (
								<Typography variant="body2">{item.sumCargoValue}</Typography>
							),
						},
					];

					return item;
				});

				const incomingOrderData = updatedIncomingOrders.reduce(
					(acc, curr) => [
						...acc,
						{
							type: curr.type,
							id: curr.id,
							row: curr.tableCells,
							selected: false,
							detail: ({ setDetailed }) => (
								<WarehouseOrderTableRow
									incomingOrder={curr}
									onSelectCargoItemPackages={onSelectCargoItemPackages}
								/>
							),
						},
					],
					[]
				);

				setIncomingOrders([...updatedIncomingOrders]);
				onIncomingOrdersReload([...updatedIncomingOrders]);
				return {
					data: incomingOrderData,
					totalCnt: incomingOrdersResponse.total,
				};
			} catch (err) {
				console.error(err);
				enqueueSnackbar(t("incomingOrders.error.get"), {
					variant: "error",
				});
			}
		},
		[search, additionalFilter]
	);

	const handleSelectLoadingsDateRange = (loadingDate) =>
		setAdditionalFilter({ ...additionalFilter, loadingDate });
	const handleSelectUnloadingsDateRange = (unloadingDate) =>
		setAdditionalFilter({ ...additionalFilter, unloadingDate });
	const handleSelectCreatedAtDateRange = (createdAt) =>
		setAdditionalFilter({ ...additionalFilter, createdAt });
	const handleSelectIncomingOrderState = (state) =>
		setAdditionalFilter({ ...additionalFilter, state });
	const handleSelectCustomer = (customers) =>
		setAdditionalFilter({ ...additionalFilter, customers });
	const handleSelectCarrier = (carriers) =>
		setAdditionalFilter({ ...additionalFilter, carriers });
	const handleSelectVehicles = (vehicles) =>
		setAdditionalFilter({ ...additionalFilter, vehicles });
	const handleSelectCustomerPrice = (customerPrice) =>
		setAdditionalFilter({ ...additionalFilter, customerPrice });
	const handleSelectCarrierPrice = (carrierPrice) =>
		setAdditionalFilter({ ...additionalFilter, carrierPrice });
	const handleSelectCommission = (commission) =>
		setAdditionalFilter({ ...additionalFilter, commission });
	const handleSelectCreators = (creators) =>
		setAdditionalFilter({ ...additionalFilter, creators });
	const handleSelectDrivers = (drivers) =>
		setAdditionalFilter({ ...additionalFilter, drivers });
	const handleSelectCargoTemplate = (cargo) =>
		setAdditionalFilter({ ...additionalFilter, cargo });
	const handleSelectIsDraft = (isDraft) =>
		setAdditionalFilter({ ...additionalFilter, isDraft });
	const handleSelectIssuedPaymentState = (issuedPaymentState) =>
		setAdditionalFilter({ ...additionalFilter, issuedPaymentState });
	const handleSelectReceivedPaymentState = (receivedPaymentState) =>
		setAdditionalFilter({ ...additionalFilter, receivedPaymentState });
	const handleSelectPlaces = (places) =>
		setAdditionalFilter({ ...additionalFilter, places });
	const handleSelectBoxes = (boxes) =>
		setAdditionalFilter({ ...additionalFilter, boxes });

	const handleSelectLastMonth = () => {
		const now = new Date();

		setAdditionalFilter({
			lastWaypointDateFrom: startOfMonth(now),
			lastWaypointDateTo: endOfMonth(now),
		});
	};

	const loadAvailableUsers = async (search, roles = undefined) => {
		try {
			const { matches } = await apiClient.user.postUserMatchQuery({
				query: {
					match: { search, roles },
					limit: 15,
				},
			});
			return matches;
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};

	const loadAvailableContacts = async (type, search) => {
		try {
			const contacts = await apiClient.contact.postContactSuggestQuery({
				query: {
					search,
					types: ["BOTH", type],
				},
			});
			return contacts;
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};

	const loadInitVehicle = async (vehicleId) => {
		try {
			const vehicle = await apiClient.vehicle.getVehicle({
				vehicleId,
			});

			setAdditionalFilter({
				...additionalFilter,
				vehicles: [vehicle],
			});
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};

	const loadInitContact = async (contactId) => {
		try {
			const contact = await apiClient.contact.getContact({
				contactId,
			});

			if (contact.type === ContactType.CUSTOMER) {
				setAdditionalFilter({
					...additionalFilter,
					customers: [contact],
				});
			} else {
				setAdditionalFilter({
					...additionalFilter,
					carriers: [contact],
				});
			}
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};
	const loadAvailableStates = async (search) => {
		try {
			const states = await createStateSuggestQuery({
				resources: ["incoming_order"],
			});
			return states;
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};

	useEffect(() => {
		const { filterVehicleId, filterContactId } =
			queryString.parse(routerSearch);

		if (filterVehicleId) {
			loadInitVehicle(filterVehicleId);
		}
		if (filterContactId) {
			loadInitContact(filterContactId);
		}
	}, []);

	useEffect(() => {
		const fetchBankAccounts = async () => {
			try {
				const banking = null; // await readCompanyBanking();
				setBankAccounts(banking);
			} catch (error) {
				console.error(error);
			}
		};

		fetchBankAccounts();
	}, []);

	const onSelect = useCallback(
		(dataRow) => {
			const incomingOrder = incomingOrders.find(
				(incomingOrder) => incomingOrder.id === dataRow.id
			);
			const packages = flatten(
				incomingOrder.journey.waypoints[0].cargo.map((cargo) => {
					return cargo.packages.map((p) => p.packageId);
				})
			);

			onSelectCargoItemPackages(dataRow.selected, packages);
			handleSetAreSelectedAll(false);
		},
		[incomingOrders]
	);

	const onSelectAll = useCallback(
		(selectedRows) => {
			console.log(selectedRows);
			selectedRows.map((dataRow) => {
				const incomingOrder = incomingOrders.find(
					(incomingOrder) => incomingOrder.id === dataRow.id
				);
				const packages = flatten(
					incomingOrder.journey.waypoints[0].cargo.map((cargo) => {
						return cargo.packages.map((p) => p.packageId);
					})
				);
				onSelectCargoItemPackages(dataRow.selected, packages);
			});
			handleSetAreSelectedAll(true);
		},
		[incomingOrders]
	);

	const {
		data,
		dataCount,
		selectedColumns,
		loading,
		ordering,
		direction,
		checkedAll,
		page,
		rowsPerPage,
		reload,
		reloadData,
		reloadDataFromScratch,
		handleSort,
		handleSelect,
		handleSelectAll,
		handleChangePage,
		handleChangeRowsPerPage,
		handleChangeSelectedColumns,
		setData,
	} = useTable(
		reloadIncomingOrders,
		"receive-incoming-orders",
		onSelect,
		onSelectAll
	);

	const selectedCargoItemPackages = useContext(CargoPackagesContext);

	const generateIncomingOrderInvoice = async (idIncomingOrder) => {
		await createShipmentInvoice(idIncomingOrder);
		reload();
	};
	const handleSearch = (_search) => {
		clearTimeout(reloadDelay);
		reloadDelay = setTimeout(() => {
			setSearch(_search);
		}, 250);
	};

	const expandFilters = (values, fullValues) =>
		values.map((item) => fullValues.find((i) => i.value === item));

	useEffect(() => {
		const loadedFilters = loadFilters("receive-incoming-orders");
		if (loadedFilters.length === 0) {
			setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
		} else {
			setDefaultFilters(expandFilters(loadedFilters, availableFilters));
		}
	}, []);

	const selectedIncomingOrders = data.filter(({ selected }) => selected);

	const handleDeleteSubmit = () => {
		setDeleteDialogOpen(false);

		const ids = selectedIncomingOrders.map(({ id }) => id);
		const requests = ids.map((incomingOrderId) =>
			apiClient.incomingOrder.deleteIncomingOrderById({ incomingOrderId })
		);

		return Promise.all(requests)
			.then(() => {
				reloadDataFromScratch();
			})
			.catch((error) => {
				console.log(error);
				enqueueSnackbar(t("orders.error.delete"), {
					variant: "error",
				});
			});
	};

	const handleIncomingOrdersExportRequest = () => {
		setIsIncomingOrdersExportDialogOpen(true);
	};

	const closeIncomingOrdersExportDialog = () => {
		setIsIncomingOrdersExportDialogOpen(false);

		if (isIncomingOrdersExportDone) {
			setIsIncomingOrdersExportDone(false);
			setIncomingOrdersExportLink(null);
		}
	};

	const handleLoadAvailablePlaces = async (search) => {
		try {
			const { places } = await createPlacesQuery({
				filter: { search, id: null },
			});
			return places;
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};

	const handleLoadAvailableBoxes = async (search) => {
		try {
			const boxes = await createGetBoxes({
				filter: { search },
			});
			return boxes;
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};
	const exportIncomingOrdersToRinkai = async (ids) => {
		setIsIncomingOrdersExportProcessing(true);
		try {
			const response = await exportOrdersRinkai(
				selectedIncomingOrders.map(({ id }) => id),
				rinkaiDepoId
			);
			if (response.status === "error") {
				response.errors.map(({ t: translate, indexNumber }) => {
					enqueueSnackbar(t(translate, { indexNumber }), {
						variant: "error",
					});
				});
			} else {
				enqueueSnackbar(t(response.result), {
					variant: "success",
				});
				setIsIncomingOrdersExportDialogOpen(false);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsIncomingOrdersExportProcessing(false);
		}
	};

	const exportIncomingOrders = async (language) => {
		setIsIncomingOrdersExportProcessing(true);

		try {
			const link = await createIncomingOrdersExport(
				selectedIncomingOrders.map(({ id }) => id),
				language
			);

			setIncomingOrdersExportLink(link);
			setIsIncomingOrdersExportDone(true);
		} catch (error) {
			console.log(error);

			const type = error.response.data.error;

			if (type === "ShipmentExportLimitExceededError") {
				enqueueSnackbar(t("orders.export.errorLimit"), {
					variant: "error",
				});
			} else {
				enqueueSnackbar(t("orders.export.error"), {
					variant: "error",
				});
			}
		} finally {
			setIsIncomingOrdersExportProcessing(false);
		}
	};

	useEffect(() => {
		addUrlParam("filter", additionalFilter);
	}, [additionalFilter]);

	useEffect(() => {
		clearTimeout(storeSearchDelay);
		storeSearchDelay = setTimeout(() => {
			addUrlParam("searchText", search);
		}, 250);
	}, [search]);

	const { hasPermission, user } = useAuth();
	const canUpdateIncomingOrder =
		hasPermission("resource.incomingOrder.company.update") ||
		hasPermission("resource.incomingOrder.user.update");
	const canCreateInvoice = hasPermission(
		"resource.incomingOrder.invoice.create"
	);
	const canCreateIncomingOrder = hasPermission(
		"resource.incomingOrder.user.create"
	);
	const canCreateOrderPdf = hasPermission(
		"resource.incomingOrder.summary.read"
	);
	const canDeleteIncomingOrder =
		hasPermission("resource.incomingOrder.user.delete") ||
		hasPermission("resource.incomingOrder.company.delete");
	const canReadInvoice = hasPermission("resource.incomingOrder.invoice.read");
	const canReadCompanyIncomingOrder = hasPermission(
		"resource.incomingOrder.company.read"
	);

	const [rinkaiDepoId, setRinkaiDepoId] = useState("");
	const handleRinkaiDepoInputChange = (e) => {
		setRinkaiDepoId(e.target.value);
	};

	return (
		<>
			<ReceiveIncomingOrders
				data={data}
				setRinkaiDepoId={setRinkaiDepoId}
				rinkaiDepoId={rinkaiDepoId}
				handleRinkaiDepoInputChange={handleRinkaiDepoInputChange}
				dataCount={dataCount}
				selectedColumns={selectedColumns}
				incomingOrders={incomingOrders}
				setIncomingOrders={setIncomingOrders}
				loading={loading}
				search={search}
				ordering={ordering}
				direction={direction}
				rowsPerPage={rowsPerPage}
				page={page}
				checkedAll={checkedAll}
				exportIncomingOrders={exportIncomingOrders}
				canCreateOrderPdf={canCreateOrderPdf}
				canUpdateIncomingOrder={canUpdateIncomingOrder}
				canCreateInvoice={canCreateInvoice}
				canCreateIncomingOrder={canCreateIncomingOrder}
				canDeleteIncomingOrder={canDeleteIncomingOrder}
				canReadInvoice={canReadInvoice}
				canReadCompanyIncomingOrder={canReadCompanyIncomingOrder}
				isIncomingOrdersExportDialogOpen={isIncomingOrdersExportDialogOpen}
				isIncomingOrdersExportProcessing={isIncomingOrdersExportProcessing}
				isIncomingOrdersExportDone={isIncomingOrdersExportDone}
				incomingOrdersExportLink={incomingOrdersExportLink}
				additionalFilter={additionalFilter}
				closeIncomingOrdersExportDialog={closeIncomingOrdersExportDialog}
				handleSort={handleSort}
				handleChangePage={handleChangePage}
				handleIncomingOrdersExportRequest={handleIncomingOrdersExportRequest}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				handleSearch={handleSearch}
				handleSelectAll={handleSelectAll}
				handleSelect={handleSelect}
				handleDeleteRequest={() => setDeleteDialogOpen(true)}
				handleGenerateIncomingOrderInvoice={generateIncomingOrderInvoice}
				handleSelectLastMonth={handleSelectLastMonth}
				filter={additionalFilter}
				availableBankAccounts={bankAccounts}
				handleDeselect={handleDeselect}
				loadAvailableUsers={loadAvailableUsers}
				defaultFilters={defaultFilters}
				handleSelectIncomingOrderState={handleSelectIncomingOrderState}
				handleSelectCustomer={handleSelectCustomer}
				handleSelectCustomerPrice={handleSelectCustomerPrice}
				loadAvailableContacts={loadAvailableContacts}
				handleLoadAvailablePlaces={handleLoadAvailablePlaces}
				handleFilterSettingsOpen={handleFilterSettingsOpen}
				handleSelectLoadingsDateRange={handleSelectLoadingsDateRange}
				handleSelectUnloadingsDateRange={handleSelectUnloadingsDateRange}
				handleSelectCreatedAtDateRange={handleSelectCreatedAtDateRange}
				handleSelectCreators={handleSelectCreators}
				handleSelectCargoTemplate={handleSelectCargoTemplate}
				handleSelectIsDraft={handleSelectIsDraft}
				handleSelectIssuedPaymentState={handleSelectIssuedPaymentState}
				handleSelectReceivedPaymentState={handleSelectReceivedPaymentState}
				handleSelectPlaces={handleSelectPlaces}
				handleLoadAvailableBoxes={handleLoadAvailableBoxes}
				handleSelectBoxes={handleSelectBoxes}
				loadAvailableStates={loadAvailableStates}
				handleSelectAllOnPage={handleSelectAllOnPage}
				exportOrdersRinkai={exportIncomingOrdersToRinkai}
				handleChangeSelectedColumns={handleChangeSelectedColumns}
			/>
			<IncomingOrdersDeleteDialog
				open={deleteDialogOpen}
				selected={selectedIncomingOrders.length}
				handleClose={() => setDeleteDialogOpen(false)}
				handleSubmit={handleDeleteSubmit}
			/>
			<FilterSettings
				availableFilters={availableFilters}
				initialFilters={defaultFilters}
				isOpen={isFilterSettingsOpen}
				onClose={handleFilterSettingsClose}
				onSubmit={onFilterSettingsSubmit}
			/>
		</>
	);
};

export default ReceiveIncomingOrdersContainer;
