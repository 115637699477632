import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { deserializeQuery } from "../../utility/common";
import { useApiClient } from "../../../cargotic-webapp-component";
import Loading from "@cargotic/webapp-component/component/Loading";

import VehicleProfileView
  from "../../../cargotic-webapp-vehicle/VehicleProfileView";

import VehicleProfileContainer
  from "../../../cargotic-webapp-vehicle/component/VehicleProfileContainer";

const ContactVehicleProfileContainerAdapter = ({ view }) => {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const params = useParams();
  const contactId = parseInt(params?.contactId, 10);
  const vehicleId = parseInt(params.id, 10);
  const actions = deserializeQuery(search.substring(1));

  const [contact, setContact] = useState();
  const [isContactLoading, setIsContactLoading] = useState(true);

  const [selectedExpiration, setSelectedExpiration] = useState();
  const [isExpirationEditorOpen, setIsExpirationEditorOpen] = useState(false);

  const handleExpirationAdd = type => {
    if (view !== VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW) {
      history.push(`/contacts/${contactId}/vehicles/${vehicleId}/expirations?add=${type}`);
      return;
    }

    setSelectedExpiration({ type });
    setIsExpirationEditorOpen(true);
  }

  const handleExpirationSelect = value => setSelectedExpiration(value);
  const handleExpirationEditorOpen = () => setIsExpirationEditorOpen(true);
  const handleExpirationEditorClose = () => setIsExpirationEditorOpen(false);

  const handleViewChange = value => {
    switch (value) {
      case VehicleProfileView.VEHICLE_INCOME_OVERVIEW: {
        history.push(`/contacts/${contactId}/vehicles/${vehicleId}/incomes`);
        return;
      }

      case VehicleProfileView.VEHICLE_EXPENSE_OVERVIEW: {
        history.push(`/contacts/${contactId}/vehicles/${vehicleId}/expenses`);
        return;
      }

      case VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW: {
        history.push(`/contacts/${contactId}/vehicles/${vehicleId}/expirations`);
        return;
      }

      case VehicleProfileView.VEHICLE_OVERVIEW: {
        history.push(`/contacts/${contactId}/vehicles/${vehicleId}`);
        return;
      }

      default: {
        throw new Error(`Invalid contact vehicle profile view '${view}'!`);
      }
    }
  };

  const handleShipmentRedirect = () => (
    history.push(`/shipment?vehicleId=${vehicleId}`)
  );

  const handleVehiclesRedirect = () => history.push(`/contacts/${contactId}/vehicles`);

  useEffect(() => {
    const loadContact = async () => {
      setIsContactLoading(true);
  
      try {
        const loadedContact = await client?.contact?.getContact({ contactId });
        setContact(loadedContact);
        setIsContactLoading(false);
      } catch (error) {
        console.log(error);
  
        enqueueSnackbar(t("webapp:contact.error.load"), {
          variant: "error"
        });
      }
    };

    if (typeof contact === "undefined" && contactId) {
      loadContact(contactId)
    }
  }, [
    enqueueSnackbar, 
    setContact, 
    contactId, 
    setIsContactLoading, 
    client?.contact?.getContact
  ])

  useEffect(() => {
    const { add: type } = actions;

    if (!type) {
      return;
    }

    setSelectedExpiration({ type });
    setIsExpirationEditorOpen(true);
  }, [actions.add]);

  return contact ?
    <VehicleProfileContainer
      selectedExpiration={selectedExpiration}
      vehicleId={vehicleId}
      view={view}
      isExpirationEditorOpen={isExpirationEditorOpen}
      onExpirationAdd={handleExpirationAdd}
      onExpirationEditorClose={handleExpirationEditorClose}
      onExpirationEditorOpen={handleExpirationEditorOpen}
      onExpirationSelect={handleExpirationSelect}
      onVehiclesRedirect={handleVehiclesRedirect}
      onViewChange={handleViewChange}
      onShipmentRedirect={handleShipmentRedirect}
      initialBreadcrumbs={[
        { label: t("webapp:common.title.contacts"), href: "/contacts" },
        { label: contact?.name , href: `/contacts/${contactId}` }
      ]}
      breadcrumbsRootPath={`/contacts/${contactId}/vehicles`}
    />
    : <Loading />
  
}

export default ContactVehicleProfileContainerAdapter;
