import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {getFormattedPlaceName} from "../../../../utility/waypoint";

const WarehouseOrdersRoute = ({order}) => {
	return (
			<Grid key={order.id} container>
				{order.journey.waypoints.map((waypoint, index) => {
					const {components, formatted} = waypoint.place.address;
					const result = Array.isArray(components)
							? getFormattedPlaceName(components)
							: formatted;
					return (
							<Grid key={index} item xs={12}>
								<Typography variant="body2">{result}</Typography>
							</Grid>
					);
				})}
			</Grid>
	);
}

export default WarehouseOrdersRoute;