import { useContext } from "react";

import ExchangeRatesContext, {
  ExchangeRatesContextProps
} from "../component/ExchangeRatesContext";

function useExchangeRates(): ExchangeRatesContextProps {
  return useContext(ExchangeRatesContext);
}

export default useExchangeRates;
