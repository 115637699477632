import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { CircularProgress, makeStyles, Fab, Tooltip } from "@material-ui/core";
import { Delete, Edit, Build, Add, DeleteOutline } from "@material-ui/icons";

import DataTable from "../../../datatable";
import useRouter from "../../hook/useRouter";
import Page from "../../common/Page";
import { SelectColumnsButton,useApiClient } from "@cargotic/webapp-component";


import SettingsStateNavigation from "./SettingsStateNavigation";
import SettingsStateView from "./SettingsStateView";
import SettingsStateEditor from "./SettingsStateEditor";
import { createState,updateState } from "../../../resource";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& > :not(:first-child)": {
      marginTop: spacing(4)
    }
  },
  content: {
    flex: 1
  },
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  }
}));

const SettingsStateOverview = ({

  className,
  tariff,
  isLoading,
  onBreadcrumbsChange,
  userStateTableProps,
  statesData,

  hasCreateRolePermission,
  hasUpdateRolePermission,
  hasReadRolePermission,
  hasDeleteRolePermission,

  handleOpenDeleteStatesDialog
}) => {

  
  const classes = useStyles();
  const { t } = useTranslation();
  const { history } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const [states, setStates] = useState([]);

  const [availableRoles, setAvailableRoles] = useState([]);
  const [stateEditorDialogOpen, setStateEditorDialogOpen] = useState(false);
  const [clickedState, setClickedState] = useState({});


  const handleStateEditorClose = () => setStateEditorDialogOpen(false);
  const handleStateEditorOpen = () => setStateEditorDialogOpen(true);



  const onCreate = () => {
    setClickedState({ resources: selectedTab.toLowerCase() });
    handleStateEditorOpen();
  };


  const onEdit = (id) => {
    setClickedState(statesData.matches.find(item => item.stateId === id));
    handleStateEditorOpen();
  };

  const onCreateSubmit = async ({ ...options }) => {
    try {
      const update = await createState({...options});
      await reloadData();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("users.error.update"), { variant: "error" });
    }
  };


  const onUpdateSubmit = async ({ ...options }) => {
    try {
      await updateState({...options});
      await reloadData();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("users.error.update"), { variant: "error" });
    }
  };


  const onStateEditorSubmit = async ({ ...options }) => {
    handleStateEditorClose();
    const { id } = options;

    if (id){
      onUpdateSubmit({...options})
    } else{
      onCreateSubmit({...options})
    }
  };

  const selectedTab = Object.keys(SettingsStateView).find(key => SettingsStateView[key].url === history.location.pathname) || SettingsStateView.WAREHOUSE_ORDER.key;

  const userRoleHeader = [
    {
      name: "title",
      label: "webapp:common.title.statusName",
      isOrderable: true
    }
  ];

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => onEdit(id),
          icon: <Edit />,
          title: "webapp:settings.title.editState",
          disableFor: !hasUpdateRolePermission ||  selectedTab === SettingsStateView.WAREHOUSE_ORDER.key ? [-1] : undefined
        }
      ]
    },
  ];

  const {
    data,
    dataCount,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    selectedColumns,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = userStateTableProps;
  const selectedItems = data.filter(({ selected }) => selected);
  
  return (
    <Page
      actions={(
        <>
          <Tooltip title={t("webapp:settings.title.create-state")}>
            <span>
              <Fab
                color="primary"
                disabled={isLoading || !hasCreateRolePermission || selectedTab === SettingsStateView.WAREHOUSE_ORDER.key}
                onClick={() => onCreate()}
              >
                <Add />
              </Fab>
            </span>
          </Tooltip>
          <Fab
            className={classes.danger}
            disabled={isLoading || selectedItems.length === 0 || !hasDeleteRolePermission || selectedTab === SettingsStateView.WAREHOUSE_ORDER.key}
            onClick={handleOpenDeleteStatesDialog}
          >
            <DeleteOutline />
          </Fab>
        </>
      )}
      tertiaryActions={(
        <SelectColumnsButton onClick={setIsOpenSelectColumnsDialog} />
      )}>

      <SettingsStateNavigation />



      <div className={clsx(className, classes.root)}>
        <DataTable
          headers={userRoleHeader}
          actions={tableActions}
          data={data}
          dataCount={dataCount}
          loading={loading}
          ordering={ordering}
          selectedColumns={selectedColumns}
          direction={direction}
          checkedAll={checkedAll}
          page={page}
          rowsPerPage={rowsPerPage}
          onSort={handleSort}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSelectedColumns={handleChangeSelectedColumns}
          isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
          setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        />
      </div>

      <SettingsStateEditor
        initialValue={clickedState}
        availableRoles={availableRoles}
        isOpen={stateEditorDialogOpen}
        onSubmit={onStateEditorSubmit}
        onClose={() => handleStateEditorClose()}
      />
    </Page>
  );
};

export default SettingsStateOverview;
