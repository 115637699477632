import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Vehicles from "@cargotic/webapp/component/core/Vehicles";
import useRouter from "@cargotic/webapp/component/hook/useRouter";
import zhCN from "date-fns/locale/zh-CN";

export function ContactVehiclesOverview({ contact, onBreadcrumbsChange }) {
    const { t } = useTranslation();
    const { history } = useRouter();

    const handleVehicleDetailRedirect = useCallback((idVehicle) => {
        return history.push(`/contacts/${contact?.id}/vehicles/${idVehicle}`);
    });

    useEffect(() => {
        onBreadcrumbsChange([
            { label: t("webapp:common.title.contacts"), href: "/contacts" },
            { label: contact?.name, href: `/contacts/${contact?.id}` },
            { label: t("webapp:common.title.vehicles") }
        ]);
    }, []);

    return (
        <Vehicles
            standalone
            contact={contact}
            onVehicleDetailRedirect={handleVehicleDetailRedirect}
        />
    );
}

export default ContactVehiclesOverview;
