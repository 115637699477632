import { createTheme } from "@material-ui/core";
import { deepPurple, teal, red, grey } from "@material-ui/core/colors";

/*
 Colors from cargotic_color.pdf

 MAIN - teal
 light     #77E6D1  #60d8c6
 mid       #45CFBB
 dark      #29AEA0  #24bcaa
 darker    #117F72  #009488

 beige
 light     #C6AE93
 lighter   #DDD2C7

 greys
 blueish   #77E6D1
 darker    #606060

 b4e2dd

 MATERIAL
 prim  vvvvvvv                               material teal
 50  - #e1f7f4  #e1f3f2  #e1f3f2  #dbf6f2  #
 100 - #b4eae2  #b4e2dd  #b4e1de  #a5e7dc  #
 200 - #81ddcf  #83d0c8  #84cfc9  #60d8c6  #
 300 - @45CFBB  #50bdb1  #53bbb2  #00c6ae  #
 400 - #00c3ab  @29AEA0  #2faca1  #00b79c  #
 500 - #00b59b  #049f8f  #169d90  #00a88a  #009688
 600 - #00a78d  #039281  #148f82  #009a7d  #
 700 - #00967c  #028171  @117F72  #008a6c  #
 800 - #00856d  #007162  #0f6f63  #00795d  #
 900 - #00684f  #005546  #0b5346  #005c40  #
*/

const typographyData = {
  body1: {
    font: "Barlow",
    weight: "Regular",
    size: 11.8,
    spacing: 0.4,
    lineHeight: 25,
    textTransform: 0,
  },
  body2: {
    font: "Barlow",
    weight: "Regular",
    size: 12.8,
    // spacing: 0.25,
    lineHeight: 20,
    textTransform: 0,
  },
  button: {
    font: "Barlow",
    weight: "Medium",
    size: 12.7,
    // spacing: 1.25,
    lineHeight: 16,
    textTransform: 1,
  },
  caption: {
    font: "Barlow",
    weight: "Regular",
    size: 10.9,
    // spacing: 0.4,
    lineHeight: 16,
    textTransform: 0,
  },
  h1: {
    font: "Barlow",
    weight: "Light",
    size: 88.2,
    spacing: -1.5,
    lineHeight: 0,
    textTransform: 0,
  },
  h2: {
    font: "Barlow",
    weight: "Regular",
    size: 54.8,
    spacing: -0.5,
    lineHeight: 0,
    textTransform: 0,
  },
  h3: {
    font: "Barlow",
    weight: "Bold",
    size: 43.9,
    spacing: null,
    lineHeight: 0,
    textTransform: 0,
  },
  h4: {
    font: "Barlow",
    weight: "Regular",
    size: 29.2,
    // spacing: 0.25,
    lineHeight: 0,
    textTransform: 0,
  },
  h5: {
    font: "Barlow",
    weight: "Regular",
    size: 21.0,
    spacing: null,
    lineHeight: 0,
    textTransform: 0,
  },
  h6: {
    font: "Barlow",
    weight: "Bold",
    size: 18.2,
    // spacing: 0.25,
    lineHeight: 0,
    textTransform: 0,
  },
  overline: {
    font: "Barlow",
    weight: "Medium",
    size: 10.9,
    // spacing: 2,
    lineHeight: 16,
    textTransform: 1,
  },
  subtitle1: {
    font: "Barlow",
    weight: "Regular",
    size: 14.6,
    // spacing: 0.15,
    lineHeight: 24,
    textTransform: 0,
  },
  subtitle2: {
    font: "Barlow",
    weight: "Medium",
    color: "#323C47",
    opacity: "0.67",
    size: 12.7,
    // spacing: 0.1,
    lineHeight: 24,
    textTransform: 0,
  },
};

export function pxToRem(px, unit = "rem") {
  return `${px / 16}${unit}`;
}

export function round(value) {
  return Math.round(value * 1e5) / 1e5;
}

export function getLineHeight(px) {
  // using baseline grid of 4dp
  return Math.ceil(px / 4) * 4;
}

export function getWeight(name) {
  let fontWeight;
  switch (name) {
    case "Hairline":
    case "Thin":
      fontWeight = 100;
      break;
    case "ExtraLight":
    case "UltraLight":
      fontWeight = 200;
      break;
    case "Light":
      fontWeight = 300;
      break;
    case "Normal":
    case "Regular":
    default:
      fontWeight = 400;
      break;
    case "Medium":
      fontWeight = 500;
      break;
    case "DemiBold":
    case "SemiBold":
      fontWeight = 600;
      break;
    case "Bold":
      fontWeight = 700;
      break;
    case "ExtraBold":
    case "UltraBold":
      fontWeight = 800;
      break;
    case "Black":
    case "Heavy":
      fontWeight = 900;
      break;
  }
  return fontWeight;
}

export function getTypographyVariants({ variants, map }) {
  const paramVariants =
    [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "body1",
      "body2",
      "subtitle1",
      "subtitle2",
      "button",
      "caption",
      "overline",
    ] || variants;

  const getStyleForVariant = (variant) => {
    const data = typographyData[variant];

    const fontFamily = data.font;
    const fontWeight = getWeight(data.weight);

    const processedData = {
      fontFamily,
      fontWeight,
      fontSize: pxToRem(data.size),
      letterSpacing: pxToRem(data.spacing),
    };
    if (data.opacity) processedData.opacity = data.opacity;
    if (data.color) processedData.color = data.color;
    if (data.lineHeight > 0)
      processedData.lineHeight = pxToRem(data.lineHeight);
    if (data.textTransform === 1) processedData.textTransform = "uppercase";
    else if (data.textTransform === 0) processedData.textTransform = "none";

    return processedData;
  };

  const styles = {};
  paramVariants.forEach((variant) => {
    let mappedVariant = variant;
    if (map && map[variant]) mappedVariant = map[variant];
    styles[mappedVariant] = getStyleForVariant(variant);
  });

  return styles;
}

const nextVariants = getTypographyVariants({});

const theme = createTheme({
  // shadows: ["none"],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1500,
    },
  },
  palette: {
    primary: teal,
    secondary: grey,
    status: {
      danger: red[600],
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 40,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: 12,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    MuiCardHeader: {
      root: {
        padding: 10,
      },
    },
    MuiCardContent: {
      root: {
        padding: 10,
      },
    },
    MuiTypography: {
      paragraph: {
        marginBottom: 0,
      },
    },
    MuiCardContent: {
      root: {
        padding: 5,
      },
    },
    MuiStepper: {
      root: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 14,
      },
    },
    MuiTextField: {
      root: {
        marginBottom: 5,
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
      },
    },
  },
  typography: {
    // Poppins + system font stack
    fontFamily: [
      "Barlow",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(", "),
    ...nextVariants,
  },
});

export default theme;
