import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    backgroundColor: palette.background.paper,

    height: spacing(10),

    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

const PaneHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        {title}
      </Typography>
    </div>
  );
};

export default PaneHeader;
