import React, { useCallback } from "react";
import { getIn } from "formik";

import { FormControl, FormHelperText, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { FormikTextField } from "@cargotic/webapp-component/component";

const FormikAutocomplete = ({
    className,
    form,
    name,
    label,
    handleChange,
    multiple,
    handleHomeEndKeys,
    id,
    size,
    options,
    getOptionLabel,
    defaultValue,
    renderTags,
    fullWidth,
    value,
    renderInput,
    ...props
}) => {
    const val = value || getIn(form.values, name);
    const error = getIn(form.errors, name);
    const isTouched = getIn(form.touched, name);

    const onChange = useCallback(
        (event, value) => {
            form.setFieldValue(name, value);
            if (typeof handleChange === "function") {
                handleChange(value);
            }
        },
        [form.handleChange, handleChange]
    );

    return (
        <FormControl fullWidth error={error} {...props}>
            <Autocomplete
                fullWidth={fullWidth || true}
                multiple={multiple}
                handleHomeEndKeys={handleHomeEndKeys}
                id={name}
                size={size || "small"}
                value={val}
                name={name}
                onChange={onChange}
                onBlur={form.handleBlur}
                options={options}
                defaultValue={defaultValue}
                getOptionLabel={getOptionLabel || ((option) => option?.label)}
                renderTags={renderTags}
                renderInput={
                    renderInput ||
                    ((params) => (
                        <FormikTextField
                            {...params}
                            form={form}
                            variant="outlined"
                            label={label}
                            name={name}
                        />
                    ))
                }
            />
        </FormControl>
    );
};

export default FormikAutocomplete;
