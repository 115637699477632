import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles"
import { Collapse, Link, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Warning from '@material-ui/icons/Warning';
import { Error, Launch } from '@material-ui/icons';
import { Link as RouterLink } from "react-router-dom";
import { formatDateTime, parseDate } from "../../../../packages/cargotic-webapp/utility/common";

import { formatCargoItem, formatCargoItemSum, CargoItemAction } from "../../../cargotic-core";
import { getCargoItemActionIcon } from "../../utility";
import { isStandartReasonNotDelivered } from "../../../cargotic-webapp/utility";

const useStyles = makeStyles(({ palette, spacing }) => ({
  icon: {
    color: palette.text.secondary
  },
  greenIcon: {
    color: "#009587"
  },
  warning: {
    color: "orange"
  },
  error: {
    color: "red"
  }
}));

export function CargoItemListItem({ item, displayIcons, expandableDetail, getPackageLabel }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const { action } = item;
  const Icon = getCargoItemActionIcon(action);

  const allPackagesDrivenThrough = useMemo(() =>
    item.packages?.every((pckg) => pckg.isDrivenThrough === true)
    , [item.packages])

  return (
    <Box display="flex" flexDirection="column" ml={3}>
      <Box onClick={expandableDetail && handleClick} display="flex" pb={1}>
        {displayIcons ? (
          <Box mr={1}>
            <Icon className={action === CargoItemAction.LOAD
              ? classes.greenIcon
              : classes.icon}
            />
          </Box>)
          : ""}

        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography color="textSecondary">
              {formatCargoItem(t, item)}
            </Typography>
            {item.incomingOrder ? (
              <Box ml={1}>
                <Typography><Link
                  component={RouterLink}
                  to={`/incoming-order/${item.incomingOrder.incomingOrderId}`}
                  target="_blank"
                  rel="noopener">
                  #{item.incomingOrder.indexNumber} (ref: {item.incomingOrder.externalReference})
                  <Tooltip title={t("webapp:cargo.editor.button.new-tab")}>
                    <Launch style={{ width: 12, height: 12 }} />
                  </Tooltip>
                </Link></Typography>
              </Box>
            ) : undefined}
            {item.incomingOrder?.reasonNotDelivered && action === CargoItemAction.UNLOAD ? (
              <>
                <Box ml={1}>
                  <Error className={classes.error} fontSize="small" />
                </Box>
                <Typography>{isStandartReasonNotDelivered(item.incomingOrder?.reasonNotDelivered) ? t(`webapp:incomingOrder.undeliveredReason.${item.incomingOrder?.reasonNotDelivered}`) : item.incomingOrder?.reasonNotDelivered}</Typography>
              </>
            ) : (!allPackagesDrivenThrough && (
              <Box ml={1}>
                <Warning className={classes.warning} fontSize="small" />
              </Box>
            ))}
          </Box>
          {item.incomingOrder?.codPaymentId && action === CargoItemAction.UNLOAD ? (
            <Box ml={1}>
              <Typography variant="body2">Zaplaceno {item.incomingOrder.codPaymentPrice} {item.incomingOrder.codPaymentCurrency}</Typography>
              <Typography variant="body2">Metoda {t(`webapp:incomingOrder.codPaymentMethod.${item.incomingOrder.codPaymentMethod}`)}, {formatDateTime(parseDate(item.incomingOrder.codPaymentCreatedAt))}</Typography>
            </Box>
          ) : undefined}
          {expandableDetail && <Box ml={4}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Box>}
        </Box>
      </Box>


      {expandableDetail && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box pb={1} pl={8}>
            {item?.packages?.map((pckg) => (
              <Box display="flex">
                <Box>
                  {!pckg.receivedDate ? (pckg.isDrivenThrough
                    ? <Check fontSize="small" className={classes.greenIcon} />
                    : <Clear fontSize="small" className={classes.icon} />
                  ): undefined}
                </Box>
                <Box>
                  {`#${getPackageLabel && getPackageLabel(pckg, item?.incomingOrder?.indexNumber)}`}
                </Box>
                <Box pl={3}>
                  {pckg.receivedDate && pckg.receivedBy ? `Přijato: ${formatDateTime(parseDate(pckg.receivedDate))}, ${pckg.receivedBy.name}` : undefined}
                </Box>
                <Box pl={3}>
                  {pckg.dispatchedDate && pckg.dispatchedBy ? `Vydáno: ${formatDateTime(parseDate(pckg.dispatchedDate))}, ${pckg.dispatchedBy.name}` : undefined}
                </Box>
              </Box>
            ))}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

CargoItemListItem.propTypes = {
  getPackageLabel: PropTypes.func.isRequired,
  displayIcons: PropTypes.bool,
  expandableDetail: PropTypes.bool,
}

CargoItemListItem.defaultProps = {
  expandableDetail: false,
  displayIcons: true
}

export default CargoItemListItem;
