import { getAlphabetLetterAt } from "../../cargotic-common";

const generateWaypointTitle = (t, index, isFirst, isLast) => {
  if (isFirst) {
    return t("core:waypoint.start");
  }

  if (isLast) {
    return t("core:waypoint.end");
  }

  const letter = getAlphabetLetterAt(index - 1);
  const name = `${t("core:waypoint.point")} ${letter}`;

  return name;
};

export default generateWaypointTitle;
