import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import AppRouter from "./AppRouter";

const useStyles = makeStyles(() => ({
  "@global": {
    "html, body, #app": {
      height: "100%",
      backgroundColor: "#EEEEEE"
    }
  }
}));

const App = () => {
  useStyles();

  return (
    <AppRouter />
  );
};

export default App;
