import React from "react";
import { Grow, Tooltip, IconButton } from "@material-ui/core";

import {
  DeleteOutlined,
  EditOutlined,
  FileCopyOutlined
} from "@material-ui/icons";

const BoardCardActions = ({
  t,
  history,
  id,
  hasCreateShipmentPermission,
  hasUpdateShipmentPermission,
  hasDeleteShipmentPermission,
  openDeleteDialog,
  hovered
}) =>
  <Grow in={hovered} timeout={500} >
    <span>
      {hasCreateShipmentPermission ?
        <Tooltip title={t("copy")}>
          <IconButton size="small" onClick={() => history.push(`/shipment/${id}/copy`)}>
            <FileCopyOutlined />
          </IconButton>
        </Tooltip>
        : null}
      {hasUpdateShipmentPermission ?
        <Tooltip title={t("edit")}>
          <IconButton size="small" onClick={() => history.push(`/shipment/${id}`)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
        : null}
      {hasDeleteShipmentPermission ?
        <Tooltip title={t("shipments.removeShipment")}>
          <IconButton size="small" onClick={() => openDeleteDialog(id)}>
            <DeleteOutlined />
          </IconButton>
        </Tooltip>
        : null}
    </span>
  </Grow>

export default BoardCardActions;