import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ValueError } from "@cargotic/common-deprecated";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";

import useRouter from "../../../hook/useRouter";

import RouterBreadcrumbs from "../../../../../cargotic-webapp-component/component/RouterBreadcrumbs";
import FileDropzone from "../../../../../../multiload/cargotic-webapp/component/FileDropzone";

import ChooseTemplateDialog from "./ChooseTemplateDialog";
import AddTemplateDialog from "./AddTemplateDialog";
import useBoltClient from "../../../hook/useBoltClient";
import { getTemplates, deleteTemplate } from "../../../../resource";

import {
  uploadCSV,
  getOrderAttributes,
  mapColumns,
  resolveCSV,
} from "../../../../resource";
import DeleteTemplateDialog from "./DeleteTemplateDialog";

const CSVImport = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const steps = [
    t("importCSV.steps.upload"),
    t("importCSV.steps.map"),
    t("importCSV.steps.finish"),
  ];
  const { isReady: isBoltClientReady, client: boltClient } = useBoltClient();

  const [hasDocumentFileDropzoneError, setHasDocumentFileDropzoneError] =
    useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [foundAttributes, setFoundAttributes] = useState(null);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [deleteTemplateDialogOpen, setDeleteTemplateDialogOpen] = useState(false);
  const [templateIdForDelete, setTemplateIdForDelete] = useState(null);
  const [addTemplateDialogOpen, setAddTemplateDialogOpen] = useState(false);
  const [compatibilityError, setIncompatiblityError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [routeError, setRouteError] = useState(null);
  const [placeError, setPlaceError] = useState(null);
  const [otherError, setOtherError] = useState(null);
  const [missingValues, setMissingValues] = useState(null);
  const [customerError, setCustomerError] = useState(null);
  const [indexError, setIndexError] = useState(null);

  const [cargoticAttr, setCargoticAttr] = useState([]);
  const [cargoticReqAttr, setCargoticReqAttr] = useState([]);

  const [duplicateRows, setDuplicateRows] = useState([]);
  const [partialMatches, setPartialMatches] = useState([]);
  const [isResolvingDuplicates, setIsResolvingDuplicates] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    const listener = ({ data }) => {
      const { content: { type, topic, ...res }, session } = JSON.parse(data);
      console.log(res);
      if (res.status === "COMPLETED") {
        setDuplicateRows(null);
        setPartialMatches(res.partialMatches);
        setIsResolvingDuplicates(false);
        setActiveStep(2);
      }
      if (res.status === "REJECTED") {
        if (res.indexError) setIndexError(res.row);
        if (res.routeError) setRouteError(res.row);
        if (res.placeError) setPlaceError(res.row);
        if (res.customerError) setCustomerError(res.row);
        if (res.duplicateRows) setDuplicateRows(res.duplicateRows);
        if (res.missingValues) setMissingValues(res.missingValues);
        if (res.trace) setOtherError(res.trace);
        setActiveStep(2);
        setProcessing(false);
      }
    };

    boltClient.addEventListener("message", listener);

    return () => {
      boltClient.removeEventListener("message", listener);
    };
  }, []);

  const remainingAttributes = useMemo(
    () =>
      cargoticAttr && foundAttributes
        ? cargoticAttr.filter(
          (atb) => !Object.values(foundAttributes).find((a) => a === atb)
        )
        : null,
    [cargoticAttr, foundAttributes]
  );

  const canContinue = useMemo(() => {
    switch (activeStep) {
      case 0:
        return !!foundAttributes;
      case 1:
        return !remainingAttributes?.find(
          (attr) => !!cargoticReqAttr.find((a) => a === attr)
        );
      default:
        return true;
    }
  }, [activeStep, foundAttributes, remainingAttributes, cargoticReqAttr]);

  const handleDocumentFilesSelect = async (files) => {
    setHasDocumentFileDropzoneError(false);

    files.forEach(async (file) => {
      try {
        // api call
        const res = await uploadCSV({ file });
        if (res) {
          const foundAttr = {};
          const isDuplicated = res.headers.some((item, idx) =>
            res.headers.indexOf(item) != idx);
          const isEmpty = res.headers.find(item => item === "");
          if (isDuplicated) {
            const duplicate = res.headers.reduce((acc, currentValue, index, array) => {
              if (array.indexOf(currentValue) != index && !acc.includes(currentValue)) acc.push(currentValue);
              return acc;
            }, []);

            console.error(duplicate);
            throw new ValueError("duplicated-attribute");
          }
          if (isEmpty) {
            throw new ValueError("empty-attribute");
          }
          res.headers.forEach((header) => {
            foundAttr[header] = null;
          });
          setFoundAttributes(foundAttr);
        }
      } catch (error) {
        console.log(error);
        if (error instanceof ValueError) {
          enqueueSnackbar(t(`importCSV.error.${error.message}`), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("importCSV.uploadError"), {
            variant: "error",
          });
        }
      }
    });
  };

  const handleSelectTemplate = (template) => {
    const parsed = JSON.parse(template.columns);
    let missing = false;
    Object.keys(parsed).forEach((csvAttr) => {
      if (!Object.keys(foundAttributes).find((k) => k === csvAttr)) {
        missing = true;
      }
    });
    if (missing) {
      setIncompatiblityError(true);
    } else {
      setFoundAttributes(parsed);
      setTemplateDialogOpen(false);
      setActiveStep(1);
    }
  };

  const handleDeleteTemplate = async () => {
    try {
      await deleteTemplate({ templateID: templateIdForDelete });
      setTemplateDialogOpen(false);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("importCSV.choose.error"), {
        variant: "error",
      });
    } finally {
      setDeleteTemplateDialogOpen(false);
    }
  }

  const handleMap = async () => {
    try {
      const res = await mapColumns(foundAttributes);
    } catch (error) {
      console.log(error);
      setProcessing(false);
      enqueueSnackbar(t("importCSV.mapError"), {
        variant: "error",
      });
    }
  };

  const handleResolve = async () => {
    setIsResolvingDuplicates(true);
    const resolvedDuplicates = [];
    duplicateRows.forEach((row) => {
      if (row.noImport === false) {
        resolvedDuplicates.push(row.externalReference);
      }
    });
    try {
      const res = await resolveCSV({ resolvedDuplicates });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t("importCSV.resolveError"), {
        variant: "error",
      });
      setIsResolvingDuplicates(false);
    }
  };

  const handleReset = async () => {
    setHasDocumentFileDropzoneError(false);
    setActiveStep(0);
    setFoundAttributes(null);
    setProcessing(false);
    setRouteError(null);
    setCustomerError(null);
    setIndexError(null);
    setMissingValues(null);
    setDuplicateRows([]);
  };

  useEffect(() => {
    if (!isBoltClientReady) {
      return;
    }

    const getAttr = async () => {
      try {
        const res = await getOrderAttributes();
        if (res) setCargoticAttr(res.attributes);
        if (res) setCargoticReqAttr(res.requiredAttributes);
      } catch (error) {
        console.log(error);
        enqueueSnackbar(t("importCSV.attrError"), {
          variant: "error",
        });
      }
    };
    getAttr();
  }, []);

  useEffect(() => {
    if (processing) handleMap();
  }, [processing, isBoltClientReady]);

  return (
    <Box padding="1rem">
      <RouterBreadcrumbs
        value={[
          { label: t("orders.title"), href: "/incoming-orders" },
          { label: t("importCSV.label") },
        ]}
      />
      <Box margin="1rem auto" maxWidth="50%">
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box margin="1rem auto" maxWidth="70%">
        {activeStep === 0 && (
          <Grid container>
            <Grid item xs={12} style={{ position: "relative" }}>
              {canContinue && (
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ backgroundColor: "rgba(193, 193, 193, 0.9)" }}
                >
                  {t("importCSV.fileUploaded")}
                </Box>
              )}
              <FileDropzone
                acceptFiles={[".csv"]}
                title={
                  hasDocumentFileDropzoneError
                    ? t("importCSV.fileTypeError")
                    : t("importCSV.upload")
                }
                description={[t("importCSV.about"), t("importCSV.supported")]}
                hasError={hasDocumentFileDropzoneError}
                onError={() => setHasDocumentFileDropzoneError(true)}
                onSelect={(files) => handleDocumentFilesSelect(files)}
              />
            </Grid>
          </Grid>
        )}
        {activeStep === 1 &&
          Object.keys(foundAttributes).map((attr) => (
            <>
              <Paper style={{ padding: "1rem" }} color="white">
                <Typography>{attr}</Typography>
              </Paper>
              <Box padding="1rem" maxWidth="60%">
                <FormControl fullWidth>
                  <InputLabel id={`${attr}-label`}>
                    {t("importCSV.map")}
                  </InputLabel>
                  <Select
                    labelId={`${attr}-label`}
                    value={foundAttributes[attr] || ""}
                    onChange={(e) =>
                      setFoundAttributes({
                        ...foundAttributes,
                        [attr]: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="" />
                    {foundAttributes[attr] && foundAttributes[attr] !== "" && (
                      <MenuItem
                        key={foundAttributes[attr]}
                        value={foundAttributes[attr]}
                      >
                        {cargoticReqAttr.find(
                          (a) => a === foundAttributes[attr]
                        ) ? (
                          <b>
                            {t(`importCSV.attributes.${foundAttributes[attr]}`)}{" "}
                            {t("importCSV.mandatory")}
                          </b>
                        ) : t(
                          `importCSV.attributes.${foundAttributes[attr]}`
                        ) ===
                          `importCSV.attributes.${foundAttributes[attr]}` ? (
                          `${foundAttributes[attr]} ${t("importCSV.price")}`
                        ) : (
                          t(`importCSV.attributes.${foundAttributes[attr]}`)
                        )}
                      </MenuItem>
                    )}
                    {remainingAttributes.map((att) => (
                      <MenuItem key={att} value={att}>
                        {cargoticReqAttr.find((a) => a === att) ? (
                          <b>
                            {t(`importCSV.attributes.${att}`)}{" "}
                            {t("importCSV.mandatory")}
                          </b>
                        ) : t(`importCSV.attributes.${att}`) ===
                          `importCSV.attributes.${att}` ? (
                          `${att} ${t("importCSV.price")}`
                        ) : (
                          t(`importCSV.attributes.${att}`)
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          ))}
        {activeStep === 2 && duplicateRows && duplicateRows.length > 0 && (
          <>
            <Typography variant="h5">
              {t("importCSV.duplicates.overview")} ({duplicateRows.length})
            </Typography>
            <Box marginTop="2rem">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={checkedAll}
                          onChange={() => {
                            const newRows = [];
                            duplicateRows.forEach((row) => {
                              newRows.push({
                                ...row,
                                noImport: checkedAll,
                              });
                            });
                            setDuplicateRows(newRows);
                            setCheckedAll(!checkedAll);
                          }}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {t("importCSV.duplicates.externalRef")}
                      </TableCell>
                      <TableCell align="right">
                        {t("importCSV.duplicates.address")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {duplicateRows.map((row, i) => (
                      <TableRow key={row.externalReference}>
                        <TableCell component="th" scope="row">
                          <Checkbox
                            checked={row.noImport === false}
                            onChange={() => {
                              const newRows = [...duplicateRows];
                              newRows[i] = {
                                ...newRows[i],
                                noImport: !row.noImport,
                              };
                              setDuplicateRows(newRows);
                            }}
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          {row.externalReference}
                        </TableCell>
                        <TableCell align="right">
                          {row.billingAddress}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
        {activeStep === 2 && (routeError || customerError || indexError || placeError || otherError) && (
          <Box
            marginTop="5rem"
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <b>
              {t(
                routeError
                  ? "importCSV.error.route"
                  : (customerError
                    ? "importCSV.error.customer"
                    : (
                      placeError ? "importCSV.error.place" : "importCSV.error.index"
                    )
                  )
              )}
            </b>
            <p>
              {`${t("importCSV.row")} ${routeError || customerError || indexError || placeError || JSON.stringify(otherError)
                }`}
            </p>
            <Button
              color="primary"
              variant="contained"
              onClick={handleReset}
              disabled={!canContinue}
              style={{ marginTop: "1rem", width: "200px" }}
            >
              {t("importCSV.uploadAgain")}
            </Button>
          </Box>
        )}
        {activeStep === 2 && partialMatches && partialMatches.length > 0 && (
          <p>
            {partialMatches.map((place) => (
              <p style={{ textAlign: 'left', marginBottom: 5 }}>
                <Typography><b>Vstup</b>: {place.input}</Typography>
                <Typography><b>Externí reference:</b>: {place.externalReference}</Typography>
                <Typography><b>Řádek</b>: {place.rowIndex}</Typography>
                <Typography><b>Rozpoznaná adresa:</b> {place.resolvedAddress.formatted_address}</Typography>
              </p>
            ))}
          </p>
        )}
        {activeStep === 2 && missingValues && missingValues.length > 0 && (
          <Box
            marginTop="5rem"
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <b>{t("importCSV.error.missingValues")}</b>
            {missingValues.map((value, i) => (
              <p key={i}>
                {`${t("importCSV.value")} ${value.missingValue.map(
                  (v) => v
                )} | ${t("importCSV.row")} ${value.rowIndex}`}
              </p>
            ))}
            <Button
              color="primary"
              variant="contained"
              onClick={handleReset}
              disabled={!canContinue}
              style={{ marginTop: "1rem", width: "200px" }}
            >
              {t("importCSV.uploadAgain")}
            </Button>
          </Box>
        )}
        {activeStep === 2 &&
          (!duplicateRows || duplicateRows.length < 1) &&
          (!missingValues || missingValues.length < 1) &&
          !routeError &&
          !customerError &&
          !placeError &&
          !otherError &&
          !indexError && (
            <Box marginTop="5rem" textAlign="center">
              <Typography variant="h5">{t("importCSV.success")}</Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push("/incoming-orders")}
                disabled={!canContinue}
                style={{ marginTop: "1rem" }}
              >
                {t("importCSV.finish")}
              </Button>
            </Box>
          )}
        <Box
          width="100%"
          display="flex"
          marginTop="1rem"
          justifyContent="flex-end"
        >
          {(activeStep === 0 || activeStep === 1) && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setTemplateDialogOpen(true)}
              style={{ margin: "0 0.5rem" }}
              disabled={activeStep === 0 ? !canContinue : false}
            >
              {t("importCSV.chooseTemplate")}
            </Button>
          )}
          {activeStep === 1 && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setAddTemplateDialogOpen(true)}
              style={{ margin: "0 0.5rem" }}
            >
              {t("importCSV.saveTemplate")}
            </Button>
          )}
          {(activeStep === 2 && duplicateRows && duplicateRows.length > 0
            ? true
            : activeStep < 2) && (
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  activeStep !== 2
                    ? activeStep === 1
                      ? setProcessing(true)
                      : setActiveStep(activeStep + 1)
                    : handleResolve()
                }
                disabled={!canContinue || processing}
                style={{ width: "150px" }}
              >
                {processing || isResolvingDuplicates ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  t("importCSV.continue")
                )}
              </Button>
            )}
        </Box>
        {templateDialogOpen && (
          <ChooseTemplateDialog
            isOpen={templateDialogOpen}
            onClose={() => setTemplateDialogOpen(false)}
            handleChooseTemplate={handleSelectTemplate}
            handleDeleteTemplate={(templateId) => {
              setTemplateIdForDelete(templateId);
              setDeleteTemplateDialogOpen(true);
            }}
            compatibilityError={compatibilityError}
          />
        )}
        <AddTemplateDialog
          isOpen={addTemplateDialogOpen}
          onClose={() => setAddTemplateDialogOpen(false)}
          templateData={foundAttributes}
        />
        <DeleteTemplateDialog
          open={deleteTemplateDialogOpen}
          handleClose={() => setDeleteTemplateDialogOpen(false)}
          handleSubmit={handleDeleteTemplate}
        />
      </Box>
    </Box>
  );
};

export default CSVImport;
