import Axios from "axios";

const EXCHANGE_RATES_API_URL = "https://api.exchangerate.host";

const Currency = {
  CZK: "CZK",
  EUR: "EUR"
};

const fetchExchangeRates = (baseCurrency = Currency.CZK) => Axios.get(`${EXCHANGE_RATES_API_URL}/latest?base=${baseCurrency}&source=ecb`)
  .then(({ data }) => data);

const convert = (sourceValue, sourceCurrency, targetCurrency, exchangeRates) => {
  const { base, rates } = exchangeRates;

  if (sourceCurrency === base) {
    return sourceValue * rates[targetCurrency];
  }

  if (targetCurrency === base) {
    return sourceValue / rates[sourceCurrency];
  }

  return (
    (sourceValue / rates[sourceCurrency]) * rates[targetCurrency]
  );
};

export {
  Currency,

  convert,
  fetchExchangeRates
};
