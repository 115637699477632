import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import useAuth from "../../hook/useAuth";
import useUserProfile from "../../hook/useUserProfile";
import Dashboard from "./Dashboard";
import NewsDialog from "../NewsDialog";
import { useApiClient } from "../../../../cargotic-webapp-component";

const DashboardContainer = ({ view, children }) => {
  const { user, signOut } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { loading: userProfileLoading, userProfile } = useUserProfile();
  const [news, setNews] = useState(null);
  const [isSeen, setIsSeen] = useState(true);

  const client = useApiClient();

  const fetchLatestNews = async (seen) => {
    try {
      const news = await client.news.getLatestNews({ seen });
      setNews(news);
    } catch (err) {
      console.log(err);

      enqueueSnackbar(t("overview.error.fetchNews"), {
        variant: "error"
      });
    }
  };

  const handleNewsIconClick = () => {
    setIsSeen(false)
    fetchLatestNews(true);
  }

  useEffect(() => {
    fetchLatestNews(false);
  }, []);

  const handleCloseNewsDialog = async () => {
    setNews(null);

    if (isSeen) {
      try {
        await client.news.postNewsSeen();
      } catch (err) {
        console.log(err);

        enqueueSnackbar(t("overview.error.postLastSeen"), {
          variant: "error"
        });
      }
    }
  }

  return (
    <div>
      {news ? <NewsDialog content={news.content} onClose={handleCloseNewsDialog} image={news.imageUrl} date={news.createdAt} /> : undefined}
      <Dashboard
        user={user}
        view={view}
        signOut={signOut}
        userProfileLoading={userProfileLoading}
        userProfile={userProfile}
        children={children}

        onNewsIconClick={handleNewsIconClick}
      />
    </div>
  );
};

export default DashboardContainer;
