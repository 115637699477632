import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, makeStyles } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import { localizeMonth } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(2),

    height: "100%",

    border: `1px solid ${palette.primary.light}`,

    color: palette.primary.light,

    "&:hover": {
      cursor: "pointer"
    },

    "& > section:last-child": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& svg": {
        fontSize: 48
      }
    }
  },
  disabled: {
    color: palette.action.disabled,
    borderColor: palette.action.disabled,

    "&:hover": {
      cursor: "auto"
    }
  }
}));

const VehicleExpensePlaceholderCard = ({
  className,
  month,
  onClick,
  isDisabled
}) => {
  const { i18n: { language } } = useTranslation();
  const classes = useStyles();

  const handleClick = event => {
    if (isDisabled) {
      return;
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      className={clsx(
        className,
        classes.root,
        {
          [classes.disabled]: isDisabled
        }
      )}
      onClick={handleClick}
    >
      <section>
        <Typography variant="h6">
          <b>
            {localizeMonth(month, language)}
          </b>
        </Typography>
      </section>
      <section>
        <AddIcon />
      </section>
    </div>
  );
};

export default VehicleExpensePlaceholderCard;
