import React from "react";

import { WaypointList } from "../../../cargotic-component";
import PaneContent from "../PaneContent";

const JourneySummaryContent = ({ waypoints }) => {
  return (
    <PaneContent>
      <WaypointList waypoints={waypoints} />
    </PaneContent>
  );
};

export default JourneySummaryContent;
