import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { MenuItem, Select, makeStyles, Typography } from "@material-ui/core";
import { AllInbox, LocalShipping } from "@material-ui/icons";

import {
  CargoItemTemplate,
  CargoItemTemplateSpecification
} from "../../../cargotic-core";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    textAlign: "center",

    "& > div > section:last-child": {
    }
  },
  large: {
    "& > section:first-child > svg": {
      color: palette.grey[500],

      fontSize: spacing(5)
    }
  },
  small: {
    display: "flex",

    "& > section:first-child": {
      padding: spacing(1),

      "& > svg": {
        color: palette.grey[500],

        fontSize: spacing(3)
      }
    },
    "& > section:last-child": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }
  }
}));

const CargoItemTemplateSelect = ({ className, name, value, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formatDimensions = ({ length, width, height }, lengthUnit) =>
    [length, width, height]
      .filter((dimension) => dimension !== undefined)
      .map((dimension) => `${dimension}`)
      .join(" × ") + ` ${lengthUnit.toLowerCase()}`;

  const getIcon = (specification) => {
    const Icon = !specification || !specification.fullTruck
      ? AllInbox
      : LocalShipping;

    return (
      <Icon className={classes.icon} />
    );
  };

  const getDescription = (template, specification) => (
    <>
      <Typography>
        {t(`core:cargo.item.template.${template}`)}
      </Typography>
      {
        specification === undefined
          ? null
          : (
            <Typography variant="subtitle2">
              {formatDimensions(specification, specification.lengthUnit)}
            </Typography>
          )
      }
    </>
  );

  const getTemplateElement = (template, variant) => {
    const specification = CargoItemTemplateSpecification[template];

    return (
      <div
        className={variant === "small" ? classes.small : classes.large}
      >
        <section>
          {getIcon(specification)}
        </section>
        <section>
          {getDescription(template, specification)}
        </section>
      </div>
    );
  };

  return (
    <Select
      className={clsx(classes.root, className)}
      name={name}
      variant="outlined"
      value={value}
      renderValue={
        (template) => getTemplateElement(template || undefined, "large")
      }
      onChange={onChange}
      displayEmpty
    >
      <MenuItem value="">
        {getTemplateElement(undefined, "small")}
      </MenuItem>
      {Object.keys(CargoItemTemplate).map((template) => (
        <MenuItem key={template} value={template}>
          {getTemplateElement(template, "small")}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CargoItemTemplateSelect;
