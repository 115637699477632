import React from "react";

import { makeStyles, Grid, Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    color: "black",
    fontSize: 18,
    width: "100%",
    textAlign: "left",
    fontWeight: "bold",
    marginTop: spacing(4)
  },
  basicInfoFlex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > div:nth-child(n+2)": {
      marginLeft: 100
    }
  },
  basicInfoFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  basicInfoBlockTitle: {
    width: "100%",
    textAlign: "left",
    color: "grey",
    fontWeight: "bold",
    fontSize: 14
  },
  basicInfoBlockInformation: {
    marginTop: spacing(1),
    width: "100%",
    textAlign: "left",
    color: "black",
    fontWeight: "500",
    fontSize: 13
  },
  basicInfoBlock: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "column",
    "& .basicInfoBlockTitle": {
      width: "100%",
      textAlign: "left",
      color: "grey",
      fontWeight: "bold",
      fontSize: 14
    },
    "& .basicInfoBlockInformation": {
      marginTop: spacing(1),
      width: "100%",
      textAlign: "left",
      color: "black",
      fontWeight: "500",
      fontSize: 13
    }
  },
  content: {
    padding: spacing(2),
    "& > section": {
      display: "flex",
      justifyContent: "space-between"
    }
  }
}));

const TrackStatuses = ({
  basicInfo: {
    createdAt,
    type
  },
  statuses
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const TrackOrderStateTranslations = {
    IN_SYSTEM: 'trackingOrder.states.inSystem',
    IN_SHIPMENT: 'trackingOrder.states.scheduledForDelivery',
    CROSS_DOCK: 'trackingOrder.states.xDock',
    NOT_DELIVERED: 'trackingOrder.states.notDelivered',
    IS_DELIVERING: 'trackingOrder.states.isDelivering',
    DELIVERED: 'trackingOrder.states.delivered'
  };

  // const formatDate = (d) => {
  //   const date = new Date(d);
  //   return `${
  //     date
  //       .getDate()
  //       .toString()
  //       .padStart(2, 0)
  //   }.${
  //     (date.getMonth() + 1)
  //       .toString()
  //       .padStart(2, 0)
  //   }.${
  //     date
  //       .getFullYear()
  //   } ${
  //     date
  //       .getHours()
  //       .toString()
  //       .padStart(2, 0)
  //   }:${
  //     date
  //       .getMinutes()
  //       .toString()
  //       .padStart(2, 0)
  //   }`;
  // };

  const dateAndTimeColumn = (
    <div className={classes.basicInfoFlexColumn}>
      <div className={classes.basicInfoBlock}>
        <div className={'basicInfoBlockTitle'}>
          {t('trackingOrder.base.dateAndTime')}
        </div>
        <div className={'basicInfoBlockInformation'}>
          {format(parseISO(createdAt), "dd.MM.yyyy HH:mm")}
        </div>
        {statuses.map(({ createdAt }) =>
          <div key={createdAt} className={'basicInfoBlockInformation'}>
            {format(parseISO(createdAt), "dd.MM.yyyy HH:mm")}
          </div>
        )}
      </div>
    </div>
  );

  const orderStates = (
    <div className={classes.basicInfoFlexColumn}>
      <div className={classes.basicInfoBlock}>
        <div className={'basicInfoBlockTitle'}>

        </div>
        <div className={'basicInfoBlockInformation'}>
          {t(TrackOrderStateTranslations[type])}
        </div>
        {statuses.map(({ type, reason }) =>
          <div key={type} className={'basicInfoBlockInformation'}>
            {t(TrackOrderStateTranslations[type])}
          </div>
        )}
      </div>
    </div>
  );

  const getReasonNotDelivered = (reason) => {
    switch (reason) {
      case "UNPAID":
        return t("webapp:incomingOrder.undeliveredReason.UNPAID");
      case "CANCELED":
        return t("webapp:incomingOrder.undeliveredReason.CANCELED");
      case "COMPLAINT":
        return t("webapp:incomingOrder.undeliveredReason.COMPLAINT");
      case "UNCATCHED":
        return t("webapp:incomingOrder.undeliveredReason.UNCATCHED");
      default:
        return t("webapp:incomingOrder.undeliveredReason.OTHER");
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <Card style={{ width: "100%" }}>
        <CardContent className={classes.content}>
          <div className={classes.title}>
            {t('trackingOrder.base.orderState')}
          </div>
          <div className={classes.basicInfoFlex}>
            <Grid container spacing={0}>
              <Grid item xs={6} className={classes.basicInfoBlock}>
                <Typography className={classes.basicInfoBlockTitle}>
                  {t('trackingOrder.base.dateAndTime')}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.basicInfoBlock}>
                <Typography className={classes.basicInfoBlockTitle}>
                  {t('trackingOrder.base.orderState')}
                </Typography>
              </Grid>
              {statuses.map(({ createdAt, type, reason }) =>
                <>
                  <Grid item xs={6}><Typography className={classes.basicInfoBlockInformation}>{format(parseISO(createdAt), "dd.MM.yyyy HH:mm")}</Typography></Grid>
                  <Grid item xs={6}><Typography className={classes.basicInfoBlockInformation}>{t(TrackOrderStateTranslations[type])}{type === 'NOT_DELIVERED' ? ` ${getReasonNotDelivered(reason)}` : ''}</Typography></Grid>
                </>
              )}
            </Grid>
          </div>
        </CardContent>
      </Card>
    </Grid >
  );
};

export default TrackStatuses;
