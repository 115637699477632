import React from "react";
import { useTranslation } from "react-i18next";

import {
  Card, CardContent, CardHeader, Typography, makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: "#29AEA0",
    color: "#FFF",
    padding: spacing(1)
  },
  content: {
    "& > section": {
      display: "flex",
      justifyContent: "space-between"
    },
    marginBottom: 47,
    minHeight: 251
  }
}));


const useStyles2 = makeStyles(({ palette, spacing }) => ({
  root: {
    margin: 0,
    padding: spacing(1)
  }
}));

const ShipmentSummary = ({ price, distance, duration }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes2 = useStyles2();
  const metrics = [
    ["webapp:incomingOrder.summary.label.distance", distance],
    ["webapp:incomingOrder.summary.label.duration", duration],
    ["invoices.price", price]
  ];

  return (
    <Card className={classes.root}>
      <CardHeader title={t("webapp:incomingOrder.summary.title")} classes={classes2} />
      <CardContent className={classes.content}>
        {
          metrics.map(([label, value]) => (
            <section key={label}>
              <Typography>
                {t(label)}
              </Typography>
              <Typography>
                <b>
                  {value}
                </b>
              </Typography>
            </section>
          ))
        }
      </CardContent>
    </Card>
  );
};

export default ShipmentSummary;
