class NoSuchElementError extends Error {

}

const findIndexOrThrow = (
  iterable,
  predicate,
  supplier = () => new NoSuchElementError()
) => {
  const index = iterable.findIndex(predicate);

  if (index === -1) {
    throw supplier();
  }

  return index;
};

const findOrThrow = (iterable, predicate, supplier = () => new NoSuchElementError()) => {
  const element = iterable.find(predicate);

  if (element === undefined) {
    throw supplier();
  }

  return element;
};

const areArraysEqual = (a, b) => a.length === b.length && a.every((v, i) => b.includes(v));

const replaceEmptyStringsByNull = object => Object.keys(object).forEach((key) => {
  if (object[key] === "") {
    object[key] = null;
  }
});

const getOrdering = (orderBy, directions) => orderBy.map((item, index) => [item, directions[index]]);

const getItemsOnPage = (items, page, rowsPerPage) => items.filter((item, index) => (index >= page * rowsPerPage
          && index < page * rowsPerPage + rowsPerPage && item.visible));

const getSelectedItemsOnPage = (items, page, rowsPerPage) => items.filter((item, index) => (index >= page * rowsPerPage
          && index < page * rowsPerPage + rowsPerPage
          && item.selected && item.visible));

const searchInItems = (index, items, text) => {
  if (text) {
    const results = index.search(text);
    return items.map((item) => ({ ...item, visible: results.includes(item.id) }));
  }
  return items.map((item) => ({ ...item, visible: true }));
};

const range = (minimum, maximum = undefined) => {
  const start = maximum !== undefined ? minimum : 0;
  const stop = maximum !== undefined ? maximum : minimum;
  const difference = stop - start;

  return Array(difference).fill(null).map((_, index) => start + index);
};

const getFirstNChars = (n, text) => text.substring(0, n);

const parseDateTime = dt => getFirstNChars(19, dt);

const formatNumericOrders = data => data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const calculatePercentageDiff = (current, previous) => {
  if (!current &&!previous) {
    return 0;
  }

  if (!previous) {
    return 100 * Math.sign(current);
  }

  return ((current - previous) / previous) * 100
}

export {
  NoSuchElementError,

  findIndexOrThrow,
  findOrThrow,
  getFirstNChars,
  parseDateTime,
  range,
  replaceEmptyStringsByNull,
  searchInItems,
  getOrdering,
  getItemsOnPage,
  getSelectedItemsOnPage,
  formatNumericOrders,
  calculatePercentageDiff,
  areArraysEqual
};
