import {
  CargoItemTemplate,
  CargoItemTemplateSpecification,
  LengthUnit,
  LengthUnitSpecification
} from "../model";

import convertUnit from "./convertUnit";

const formatCargoItem = (
  t,
  {
    quantity,
    template,
    description,
    width,
    height,
    length,
    volume,
    lengthUnit,
    weight,
    isStackable
  }
) => {
  const { fullTruck } = CargoItemTemplateSpecification[template] || {};
  const formattedTemplate = t(`core:cargo.item.template.${template}`);
  const stackability = t(`core:cargo.item.isStackable.${isStackable}`);
  const isWithStackability = template === undefined
    || !fullTruck;

  const builder = [];

  builder.push(`${quantity} ×`);

  if (weight !== undefined) {
    builder.push(` ${weight} kg`);
  }

  if (template === CargoItemTemplate.LDM) {
    if (length) {
      const baseLength = convertUnit(
        length,
        lengthUnit,
        LengthUnit.M,
        LengthUnitSpecification
      );

      builder.push(` ${baseLength} LDM`);
    } else {
      builder.push(` LDM`);
    }
  } else {
    const dimensions = [length, width, height]
      .filter(x => x)
      .join(" × ");

    if (template === undefined && description) {
      builder.push(` ${description}`);
    } else {
      builder.push(` ${formattedTemplate}`);
    }

    if (dimensions) {
      builder.push(
        ` ${dimensions} ${lengthUnit.toLowerCase()}`
      );
    }
  }

  if (volume) {
    builder.push(` ${volume} m³`);
  }

  if (isWithStackability && isStackable) {
    builder.push(`, ${stackability}`);
  }

  return builder.join("");
};

export default formatCargoItem;
