import React from "react";

import { Card, CardContent, CardHeader, Grid, makeStyles } from "@material-ui/core";
import logo from "../../../asset/image/logo.svg";

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
    content: {
        padding: spacing(2),
        "& > section": {
            display: "flex",
            justifyContent: "space-between"
        }
    }
}));

const AuthOneColumn = ({ classes, form }) => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={12} align="center">
                <a href="/"><img className={classes.logo} src={logo} alt="logo" /></a>
            </Grid>
            <Grid item xs={12} md={12}>
                <Card style={{ width: "100%" }}>
                    <CardContent className={classes.content}>
                        {form}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default AuthOneColumn;
