import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, makeStyles, Fab, Tooltip } from "@material-ui/core";
import { Delete, Edit, Build, Add, DeleteOutline } from "@material-ui/icons";

import DataTable from "../../../datatable";
import useRouter from "../../hook/useRouter";
import Page from "../../common/Page";
import { SelectColumnsButton } from "@cargotic/webapp-component";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& > :not(:first-child)": {
      marginTop: spacing(4)
    }
  },
  content: {
    flex: 1
  },
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  }
}));

const SettingsWarehousesOverview = ({
  className,
  isLoading,
  warehousesTableProps,
  hasCreateWarehousePermission,
  hasUpdateWarehousePermission,
  hasDeleteWarehousePermission,

  handleDeleteWarehouseClick,
  handleCreateWarehouseClick,
  handleUpdateWarehouseClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { history } = useRouter();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const warehousesHeader = [
    {
      name: "name",
      label: "webapp:common.title.name",
      isOrderable: true
    },
    {
      name: "address",
      label: "webapp:common.title.address",
      isOrderable: false
    }
  ];

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => handleUpdateWarehouseClick(id),
          icon: <Edit />,
          title: "webapp:settings.title.editWarehouse",
          disableFor: !hasUpdateWarehousePermission ? [-1] : undefined
        }
      ]
    },
  ];

  const {
    data,
    dataCount,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    selectedColumns,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = warehousesTableProps;

  const selectedItems = data.filter(({ selected }) => selected);

  return (
    <Page
      actions={(
        <>
          <Tooltip title={t("webapp:settings.title.createWarehouse")}>
            <span>
              <Fab
                color="primary"
                disabled={isLoading || !hasCreateWarehousePermission}
                onClick={handleCreateWarehouseClick}
              >
                <Add />
              </Fab>
            </span>
          </Tooltip>
          <Fab
            className={classes.danger}
            disabled={isLoading || selectedItems.length === 0 || !hasDeleteWarehousePermission}
            onClick={handleDeleteWarehouseClick}
          >
            <DeleteOutline />
          </Fab>
        </>
      )}
      tertiaryActions={(
        <SelectColumnsButton onClick={setIsOpenSelectColumnsDialog} />
      )}>
      <div className={clsx(className, classes.root)}>
        <DataTable
          headers={warehousesHeader}
          actions={tableActions}
          data={data}
          dataCount={dataCount}
          loading={loading}
          ordering={ordering}
          selectedColumns={selectedColumns}
          direction={direction}
          checkedAll={checkedAll}
          page={page}
          rowsPerPage={rowsPerPage}
          onSort={handleSort}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSelectedColumns={handleChangeSelectedColumns}
          isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
          setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        />
      </div>
    </Page>
  );
};

export default SettingsWarehousesOverview;
