import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationAlert } from "../../../cargotic-webapp-component";

const ContactDeleteConfirmationAlert = ({
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationAlert
      title={t("webapp:contact.title.delete")}
      content={t("webapp:contact.prompt.delete")}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default ContactDeleteConfirmationAlert;
