import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, makeStyles } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";

import { hexToRgba } from "../../../../cargotic-webapp-utility";
import theme from "../App/theme";
import { formatNumericOrders, calculatePercentageDiff } from "../../../utility/functional";
import { Periods } from "../../enums/enums";

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    [breakpoints.down("xs")]: {
      height: 320
    },
    [breakpoints.up("sm")]: {
      height: 390
    }
  },
  tooltip: {
    backgroundColor: "white",
    borderRadius: spacing(1),
    padding: spacing(1),
    fontSize: 14,
    fontWeight: "bold",
    boxShadow: "0px 5px 15px rgba(0,0,0,0.1)",
    marginBottom: spacing(1)
  },
  tooltipValue: {
    fontSize: 14,
    fontWeight: "bold",
    display: "inline"
  },
  negativeDifference: {
    color: palette.status.danger,
    marginLeft: spacing(1),
    fontSize: 14,
    fontWeight: "bold",
    display: "inline"
  },
  positiveDifference: {
    color: palette.primary.dark,
    marginLeft: spacing(1),
    fontSize: 14,
    fontWeight: "bold",
    display: "inline"
  },
  tooltipDates: {
    fontSize: 14
  }
}));

const { dark } = theme.palette.primary;
const SAMPLING = 5;

const getXFormat = (length) => {
  const n = Math.round(length / SAMPLING);
  let count = -1; // always show first date
  return (yFormat) => {
    count += 1;
    return (count % n !== 0) ? "" : yFormat;
  };
};

const addZeroPadding = date => ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2);

const StatisticsLineChart = ({ name, data, yLabel, axisYFormat, isMoneyFormat }) => {
  const classes = useStyles();

  const getNextPoint = (point) => {
    const points = data[0].data;
    let resDate;
    points.forEach(({ x: value }, index) => {
      if (point === value) {
        if (index === points.length - 1) {
          resDate = addZeroPadding(new Date());
        } else {
          //get next point and sub 1 day
          const [day, month] = points[index + 1].x.split('.');
          const nextDate = new Date(`${new Date().getFullYear()}-${month}-${day}`);
          resDate = addZeroPadding(new Date(nextDate.setDate(nextDate.getDate() - 1)));
        }
        return;
      }
    });
    return resDate;
  }

  const calculatePreviousDataIndex = (index) => index - data[0].data.length;
  

  const formatMoney = value => (
    (Math.ceil(value * 100) / 100).toLocaleString("cs")
  );
  
  const displayable = data.map(({ data }) => data.length)
    .reduce((x, y) => (x > y ? x : y), 0) > 1;

  const { t } = useTranslation();

  const tooltipElement = ({ point: { data: pointData } }) => (
    <div className={classes.tooltip}>
      {pointData.x + ((data[0].period === Periods.WEEK) ? ` - ` + getNextPoint(pointData.x) : '')}
      :
      {formatNumericOrders((pointData.y).toFixed())}
    </div>
  );

  const sliceTooltipElement = ({ slice: { points } }) => {
    const [p1, p2] = points;

    if (p2) {
      // p1 === previous
      // p2 === current
      const { data: data1 } = p1;
      const { data: data2 } = p2;

      const diff = calculatePercentageDiff(data2.y, data1.y);

      const previousTooltipDate = data[1].tooltipX[calculatePreviousDataIndex(p1.index)];
      const currentTooltipDate = data[0].tooltipX[p2.index];

      if (previousTooltipDate) {
        return (
          <div className={classes.tooltip}>
            <Typography className={classes.tooltipDates}>
              {`${previousTooltipDate} vs ${currentTooltipDate}`}
            </Typography>
            <div>
              <Typography className={classes.tooltipValue}>
                {`${isMoneyFormat ? formatMoney(data1.y) : data1.y.toFixed(0)} vs ${isMoneyFormat ? formatMoney(data2.y) : data2.y.toFixed(0)}`}
              </Typography>
              {diff
                ? (
                  diff < 0
                    ? (
                      <Typography className={classes.negativeDifference}>
                        {diff.toFixed(2) + "%"}
                      </Typography>
                    )
                    : (
                      <Typography className={classes.positiveDifference}>
                        {"+" + diff.toFixed(2) + "%"}
                      </Typography>
                    )
                )
                : ''}
            </div>
          </div>
        )
      }

      return (
        <div className={classes.tooltip}>
          <Typography className={classes.tooltipDates}>
            {`${currentTooltipDate}`}
          </Typography>
          <div>
            <Typography className={classes.tooltipValue}>
              {`${isMoneyFormat ? formatMoney(data2.y) : data2.y.toFixed(0)}`}
            </Typography>
          </div>
        </div>
      )

    }

    // p1 === current
    const { data: data1 } = p1;

    return (
      <div className={classes.tooltip}>
        <Typography className={classes.tooltipDates}>
          {`${data1.x}`}
        </Typography>
        <div>
          <Typography className={classes.tooltipValue}>
            {`${isMoneyFormat ? formatMoney(data1.y) : data1.y.toFixed(0)}`}
          </Typography>
        </div>
      </div>
    )

  }

  const resolveChartLine = ({ series, areaGenerator, lineGenerator, xScale, yScale }) => {
    const notNullData = series.map(({ id, data, color }) => ({ id, color, data: data.filter(d => d.data.y !== null) }));

    return notNullData.map(({ id, data, color }) => {
      if (id === "previousPeriod") {
        return (
          <path
            key={id}
            d={lineGenerator(
              data.map(d => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              }))
            )}
            fill="none"
            stroke={color}
            style={{
              strokeDasharray: '12, 6',
              strokeWidth: 1
            }}
          />
        )
      }


      return (
        <path
          d={areaGenerator(
            data.map(d => ({
              x: xScale(d.data.x),
              y: yScale(d.data.y),
            }))
          )}
          fill={color}
          fillOpacity={0.1}
          stroke={color}
          style={{
            strokeWidth: 1
          }}
        />
      )
    });
  };

  return (
    <div className={classes.root}>
      {displayable ? (
        <ResponsiveLine
          crosshairType="x"
          curve="monotoneX"
          enableCrosshair
          data={data}
          colors={[
            theme.palette.primary.main,
            hexToRgba(theme.palette.primary.main, 0.8)
          ]}
          enableGridX
          margin={{
            top: 10, right: 40, bottom: 30, left: 50
          }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear", min: "auto", max: "auto"
          }}
          pointSize={2}
          enableSlices="x"
          sliceTooltip={sliceTooltipElement}
          axisTop={null}
          lineWidth={1}
          axisRight={null}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLabel,
            legendOffset: -45,
            legendPosition: "middle",
            format: axisYFormat
          }}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickValues: 2,
            tickRotation: 0,
            legendPosition: "middle",
            format: getXFormat(data[0].data.length)
          }}
          layers={['grid', 'markers', 'areas', resolveChartLine, 'crosshair', 'slices', 'points', 'axes']}
        />
      ) : (
          <>
            <Typography variant="subtitle1">
              {t("statistics.fewData")}
            </Typography>
          </>
        )}
    </div>
  );
};

export default StatisticsLineChart;
