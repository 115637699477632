import clsx from "clsx";
import React from "react";

import { makeStyles } from "@material-ui/core";

import { Container } from "../../../cargotic-webapp-component";
import VehicleProfileBodyContent from "./VehicleProfileBodyContent";
import VehicleProfileBodyNavigation from "./VehicleProfileBodyNavigation";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& > :last-child": {
      flex: 1
    }
  }
}));

const VehicleProfileBody = ({
  className,
  activity,
  expirations,
  expirationPage,
  expirationQuery,
  expirationRowsPerPage,
  selectedExpiration,
  user,
  vehicle,
  view,
  isActivityLoading,
  isExpirationCreationDisabled,
  isExpirationDeleteConfirmationAlertOpen,
  isExpirationDeletionDisabled,
  isExpirationEditationDisabled,
  isExpirationEditorOpen,
  isExpirationsLoading,
  isExpirationTabDisabled,
  isIncomeCreationDisabled,
  isIncomeDeletionDisabled,
  isIncomeEditationDisabled,
  isExpenseTabDisabled,
  isIncomeTabDisabled,
  onActivityLoad,
  handleRegisterBreadcrumb,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onExpirationAdd,
  onExpirationDelete,
  onExpirationDeleteConfirmationAlertClose,
  onExpirationDeleteConfirmationAlertSubmit,
  onExpirationEdit,
  onExpirationEditorClose,
  onExpirationEditorSubmit,
  onExpirationPageChange,
  onExpirationQueryChange,
  onExpirationRowsPerPageChange,
  onViewChange
}) => {
  const classes = useStyles();

  return (
    <Container className={clsx(classes.root, className)}>
      <VehicleProfileBodyNavigation
        view={view}
        isExpenseTabDisabled={isExpenseTabDisabled}
        isIncomeTabDisabled={isIncomeTabDisabled}
        isExpirationTabDisabled={isExpirationTabDisabled}
        onViewChange={onViewChange}
      />
      <VehicleProfileBodyContent
        activity={activity}
        expirationPage={expirationPage}
        expirationQuery={expirationQuery}
        expirationRowsPerPage={expirationRowsPerPage}
        expirations={expirations}
        selectedExpiration={selectedExpiration}
        user={user}
        view={view}
        vehicle={vehicle}
        isActivityLoading={isActivityLoading}
        isExpirationCreationDisabled={isExpirationCreationDisabled}
        isExpirationDeleteConfirmationAlertOpen={
          isExpirationDeleteConfirmationAlertOpen
        }
        isExpirationDeletionDisabled={isExpirationDeletionDisabled}
        isExpirationEditationDisabled={isExpirationEditationDisabled}
        isExpirationEditorOpen={isExpirationEditorOpen}
        isExpirationsLoading={isExpirationsLoading}
        isIncomeCreationDisabled={isIncomeCreationDisabled}
        isIncomeDeletionDisabled={isIncomeDeletionDisabled}
        isIncomeEditationDisabled={isIncomeEditationDisabled}
        onActivityLoad={onActivityLoad}
        handleRegisterBreadcrumb={handleRegisterBreadcrumb}
        onCommentaryChange={onCommentaryChange}
        onCommentaryDelete={onCommentaryDelete}
        onCommentarySubmit={onCommentarySubmit}
        onExpirationAdd={onExpirationAdd}
        onExpirationDelete={onExpirationDelete}
        onExpirationDeleteConfirmationAlertClose={
          onExpirationDeleteConfirmationAlertClose
        }
        onExpirationDeleteConfirmationAlertSubmit={
          onExpirationDeleteConfirmationAlertSubmit
        }
        onExpirationEdit={onExpirationEdit}
        onExpirationEditorClose={onExpirationEditorClose}
        onExpirationEditorSubmit={onExpirationEditorSubmit}
        onExpirationPageChange={onExpirationPageChange}
        onExpirationQueryChange={onExpirationQueryChange}
        onExpirationRowsPerPageChange={onExpirationRowsPerPageChange}
      />
    </Container>
  );
};

export default VehicleProfileBody;
