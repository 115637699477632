import React from "react";

import ActivityType from "../../ActivityType";
import ActivityTimelineCommentaryItem from "./ActivityTimelineCommentaryItem";
import ActivityTimelineEventItem from "./ActivityTimelineEventItem";

const ActivityTimelineItem = ({
  userId,
  activity,
  isLast,
  onCommentaryChange,
  onCommentaryDelete
}) => {
  const { type } = activity;

  if (type === ActivityType.COMMENTARY) {
    const { commentary } = activity;
    const { id: commentaryId, author: { id: authorId } } = commentary;

    const handleChange = content => {
      if (onCommentaryChange) {
        onCommentaryChange(commentaryId, content);
      }
    };

    const handleDelete = () => {
      if (onCommentaryDelete) {
        onCommentaryDelete(commentaryId);
      }
    };

    return (
      <ActivityTimelineCommentaryItem
        commentary={commentary}
        isControlDisabled={userId !== authorId}
        onChange={handleChange}
        onDelete={handleDelete}
      />
    );
  }

  if (type === ActivityType.PROGRESS || type === ActivityType.UPDATE) {
    const {
      user,
      title,
      content,
      isAutoUpdated,
      createdAt
    } = activity;

    return (
      <ActivityTimelineEventItem
        variant={type === ActivityType.PROGRESS ? "progress" : "update"}
        user={user}
        title={title}
        content={content}
        createdAt={createdAt}
        isAutoUpdated={isAutoUpdated}
        isLast={isLast}
      />
    );
  }

  throw new Error(`Invalid activity type '${type}' passed!`);
};

export default ActivityTimelineItem;
