import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";
import { Typography, makeStyles } from "@material-ui/core";
import createSpacing from "@material-ui/core/styles/createSpacing";

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "& > div:last-child": {
      paddingLeft: spacing(2),
    },
    "& > div:not(:last-child)": {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  },
}));
export function Summary({ total, totalVAT, totalWithoutVAT, currency }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box pr={7} pl={8} className={classes.content}>
      <div>
        <Typography variant="subtitle1">
          {t("invoices.invoice.priceVat")}
        </Typography>
        <Typography variant="subtitle1">
          {totalVAT} {currency}
        </Typography>
      </div>
      <div>
        <Typography variant="subtitle1">
          {t("invoices.invoice.priceWithoutVat")}
        </Typography>
        <Typography variant="subtitle1">
          {totalWithoutVAT} {currency}
        </Typography>
      </div>
      <div>
        <Typography variant="h5">
          {t("invoices.invoice.priceVatIncluded")}
        </Typography>
        <Typography variant="h5">
          {total} {currency}
        </Typography>
      </div>
    </Box>
  );
}

Summary.propTypes = {
  currency: PropTypes.string.isRequired,
  total: PropTypes.number,
};

Summary.defaultProps = {
  total: 0,
};

export default Summary;
