import React, { useState, useMemo } from "react";

import {
  AddOutlined as OutlinedAddIcon,
  DeleteOutline as OutlinedDeleteIcon,
  KeyboardArrowRight as GoIcon,
  EmojiObjectsOutlined,
  Edit
} from "@material-ui/icons";

import {
  Fab,
  makeStyles,
  Typography,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import useAuth from "../../hook/useAuth";
import DataTable from "../../../datatable";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import SearchTextfield from "../../common/SearchTextfield";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDynamicDropdown from "../../../../cargotic-webapp-filter/component/FilterDynamicDropdown";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  }
}));

const Users = ({
  data,
  dataCount,
  selectedColumns,
  users,
  loading,
  search,
  ordering,
  direction,
  checkedAll,
  page,
  rowsPerPage,
  canInviteUser,
  canDeleteUser,
  handleUserDeleteDialogOpen,
  handleUserInviteDialogOpen,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectAll,
  handleSelect,
  handleSearch,
  clearFilter,
  filter,
  handleSelectUserRole,
  handleFilterSettingsOpen,
  defaultFilters,
  onEdit,
  handleChangeSelectedColumns,
  availableRoles,
  loadAvailableRoles,
  mode
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { history } = useRouter();
  const { hasPermission, user: { role, id: userId } } = useAuth();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;


  let usersHeader = [
    {
      name: "name",
      label: "users.name",
      isOrderable: true
    },
    {
      name: "email",
      label: "users.email",
      isOrderable: true
    },
    {
      name: "role",
      label: "users.role",
      isOrderable: true
    },
    {
      name: "lastSeenAt",
      label: "users.lastSeenAt",
      isOrderable: true
    }
  ];

  if (mode === 'users') {
    usersHeader.push({
      name: "monthlyRevenue",
      label: "statistics.revenue",
      isOrderable: true
    });
    usersHeader.push({
      name: "monthlyCommission",
      label: "statistics.commission",
      isOrderable: true
    });
  }

  let goDisable = [];
  let editDisable = [];

  for (let index = 0; index < users.length; index++) {
    const hasReadStatisticsPermission = hasPermission("resource.statistics.company.read") || userId === users[index].id;
    if (!hasReadStatisticsPermission) {
      goDisable.push(users[index].id);
    }
  }

  for (let index = 0; index < users.length; index++) {
    const hasUpdatePermission = hasPermission("resource.user.update") || userId === users[index].id;
    if (!hasUpdatePermission) {
      editDisable.push(users[index].id);
    }
  }

  const usersActions = useMemo(() => [
    {
      column: [
        {
          handleClick: (id) => onEdit(id),
          icon: <Edit />,
          disableFor: editDisable
        },
      ]
    },
    {
      column: [
        {
          handleClick: (id) => history.push(`/users/${id}`),
          icon: <GoIcon />,
          disableFor: goDisable
        }
      ]
    }
  ],
    [onEdit, history, editDisable, goDisable]);

  const isAnyFilterActive = () => {
    const { roles } = filter;
    return roles !== undefined;
  };

  return (
    <Page
      title={mode === 'users' ? t("menu.users") : ''}
      actions={mode === 'users' ? (
        <>
          <Fab
            color="primary"
            disabled={loading || !canInviteUser}
            onClick={handleUserInviteDialogOpen}
          >
            <OutlinedAddIcon />
          </Fab>
          <Fab
            className={classes.danger}
            disabled={loading || selected === 0 || !canDeleteUser}
            onClick={handleUserDeleteDialogOpen}
          >
            <OutlinedDeleteIcon />
          </Fab>
        </>
      ) : (
        <>
          <Fab
            color="primary"
            disabled={loading || !canInviteUser}
            onClick={handleUserInviteDialogOpen}
          >
            <OutlinedAddIcon />
          </Fab>
        </>)}
      tertiaryActions={mode === 'users' ? (
        <>
          <Tooltip title={(
            <>
              <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
              <Typography color="inherit">{t("proTableTip")}</Typography>
            </>
          )
          }
          >
            <IconButton>
              <EmojiObjectsOutlined />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
    >
      <FilterContainer
        searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("users.searchTooltip")} />}
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={[
          <FilterDynamicDropdown
            id="roles"
            key="roles"
            onChange={(value) => handleSelectUserRole(value.length === 0 ? undefined : value)}
            value={filter.roles ?? []}
            selectAll={(arr) => handleSelectUserRole(arr.map(item => item.id))}
            placeholderTitle={t("users.role")}
            suggest={loadAvailableRoles}
            source={availableRoles}
            onClear={() => handleSelectUserRole(undefined)}
            getTitle={(item) => item.name}
            getValue={(item) => item.id}
          />
        ]}
        onClear={clearFilter}
        onOpenSelectColumnsDialog={() => setIsOpenSelectColumnsDialog(true)}
      />
      <DataTable
        headers={usersHeader}
        actions={usersActions}
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        loading={loading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeSelectedColumns={handleChangeSelectedColumns}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
      />
    </Page>
  );
};

export default Users;
