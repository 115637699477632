import * as Yup from "yup";

export const getValidationSchema = (keys, t) =>
    Yup.object().shape(
        keys.reduce(
            (acc, key) => ({
                ...acc,
                [key]: Yup.array()
                    .of(Yup.string())
                    .min(1, t("settings.customSequencing.error.required"))
            }),
            {}
        )
    );
