import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import qs from "qs";

import { ConstructionOutlined } from "@material-ui/icons";

import {
    ShipmentInvoiceType,
    Currency
} from "@cargotic/webapp/component/enums/enums";
import {
    readCompanyBanking,
    readInvoices,
    getShipmentInvoice,
    getNextShipmentInvoiceNumber,
    createCompanyBankAccountSuggestQuery
} from "@cargotic/webapp/resource";
import useAuth from "@cargotic/webapp/component/hook/useAuth";

import { useApiClient } from "@cargotic/webapp-component";
import Loading from "@cargotic/webapp-component/component/Loading";

import InvoiceForm from "./InvoiceForm";

const TEMPORARY_DEFAULT_CURRENCY = Currency.CZK;

const Invoice = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const client = useApiClient();
    const {
        user: { id: userId, currency: userCurrency }
    } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [bankAccounts, setBankAccounts] = useState();
    const [invoiceNumber, handleSetInvoiceNumber] = useState(undefined);
    const [invoice, handleSetInvoice] = useState(undefined);
    const [currency, handleSetCurrency] = useState(userCurrency);

    const {
        type: invoiceType = ShipmentInvoiceType.ISSUED,
        id: updateInvoiceId,
        copy: copyInvoice
    } = useMemo(
        () =>
            qs.parse(history.location.search, {
                ignoreQueryPrefix: true
            }),
        [history]
    );

    const handleResolveAndSetInvoice = useCallback(
        (invoice, updateInvoiceId, copyInvoice) => {
            if (invoice) {
                const resolvedInvoiceData =
                    updateInvoiceId && copyInvoice
                        ? { ...invoice, id: undefined }
                        : invoice;

                handleSetInvoice(resolvedInvoiceData);
            }
        },
        [handleSetInvoice]
    );

    const fetchAccountsApiClient = useCallback(async () => {
        try {
            const banking = await client.bankAccount.getBankAccounts();
            setBankAccounts(
                banking.filter(
                    (bankAccount) =>
                        !bankAccount.isDeleted &&
                        bankAccount.currency ===
                            (currency || TEMPORARY_DEFAULT_CURRENCY)
                )
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t("settings.bankAccount.error.read"), {
                variant: "error"
            });
        }
    }, [client.bankAccount, setBankAccounts, enqueueSnackbar]);

    const fetchAccounts = useCallback(async (currency) => {
        try {
            const banking = await createCompanyBankAccountSuggestQuery();
            setBankAccounts(
                banking.filter(
                    (bankAccount) =>
                        !bankAccount.isDeleted &&
                        bankAccount.currency ===
                            (currency || TEMPORARY_DEFAULT_CURRENCY)
                )
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t("settings.bankAccount.error.read"), {
                variant: "error"
            });
        }
    }, []);

    const makeGenerateNextShipmentNumberRequest = useCallback(async () => {
        try {
            return getNextShipmentInvoiceNumber(invoiceType);
        } catch (e) {
            enqueueSnackbar(t("invoices.loadError"), { variant: "error" });
        }
    }, [getNextShipmentInvoiceNumber, invoiceType]);

    const makeGetInvoiceDetailRequest = useCallback(
        async (id) => {
            try {
                const invoice = await getShipmentInvoice(id);
                const invoiceCurrency = invoice?.currency;
                if (currency !== invoiceCurrency) {
                    handleSetCurrency(invoice?.currency);
                }
                return invoice;
            } catch (e) {
                console.error(e);
                enqueueSnackbar(t("invoices.loadError"), { variant: "error" });
            }
        },
        [getShipmentInvoice, handleSetCurrency]
    );

    useEffect(() => {
        const handleLoadData = async () => {
            const invoiceDetail =
                typeof updateInvoiceId === "undefined"
                    ? undefined
                    : await makeGetInvoiceDetailRequest(updateInvoiceId);
            const nextInvoiceId =
                !updateInvoiceId || (updateInvoiceId && copyInvoice)
                    ? await makeGenerateNextShipmentNumberRequest()
                    : undefined;

            if (invoiceDetail) {
                handleResolveAndSetInvoice(
                    invoiceDetail,
                    updateInvoiceId,
                    copyInvoice
                );
            }

            handleSetInvoiceNumber(nextInvoiceId || invoiceDetail?.indexNumber);
        };

        handleLoadData();
    }, [
        handleSetInvoiceNumber,
        handleResolveAndSetInvoice,
        updateInvoiceId,
        copyInvoice,
        makeGetInvoiceDetailRequest,
        makeGenerateNextShipmentNumberRequest
    ]);

    useEffect(() => {
        fetchAccounts(currency);
    }, [currency]);

    if (
        !bankAccounts ||
        (copyInvoice && !invoiceNumber) ||
        (updateInvoiceId && !invoice)
    ) {
        return <Loading />;
    }

    return (
        <InvoiceForm
            handleSetInvoiceNumber={handleSetInvoiceNumber}
            invoiceNumber={invoiceNumber}
            invoice={invoice}
            bankAccounts={bankAccounts}
            invoiceType={invoice?.type || invoiceType}
            currentUserId={userId}
            currency={currency}
            handleSetCurrency={(event) => handleSetCurrency(event.target.value)}
            submitButtonLabel={
                updateInvoiceId && !copyInvoice ? "save" : "create"
            }
            submitButtonProps={{
                enableOnDirty: updateInvoiceId ? false : true
            }}
        />
    );
}

export default Invoice;
