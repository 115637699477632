import React from "react";

import {
  IconButton,
  makeStyles,
  SwipeableDrawer
} from "@material-ui/core";
import {
  Close
} from "@material-ui/icons";

const SwipeableDialog = ({
  isOpen,
  onClose,
  onOpen = () => { },
  form,
  actions,
  width = "40%",
  closable = false
}) => {

  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    container: {
      padding: spacing(1),
      height: "100%"
    },
    rightAlignment: {
      position: "absolute",
      right: 0,
      top: 0
    },
    drawer: {
      [breakpoints.down("xs")]: {
        width: "100%"
      },
      [breakpoints.up("sm")]: {
        width
      }
    }
  }));

  const classes = useStyles();
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={onOpen}
      onClose={closable ? onClose : () => { }}
      classes={{ paper: classes.drawer }}
    >
      <div className={classes.container}>
        <div className={classes.rightAlignment}>
          {actions}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        {form}
      </div>
    </SwipeableDrawer>
  );
};

export default SwipeableDialog;
