import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Breadcrumbs,
  Link,
  Typography,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  anchor: {
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline"
    }
  },
  inverted: {
    color: palette.primary.contrastText
  }
}));

const RouterBreadcrumbs = ({ value, hasInvertedColor }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs className={hasInvertedColor ? classes.inverted : undefined}>
      {value.map(({ label, href }, index) => {
        const isLast = index === value.length - 1;

        if (isLast) {
          return (
            <Typography key={label} variant="subtitle1" color="inherit">
              <b>
                {label}
              </b>
            </Typography>
          );
        }

        return (
          <Typography
            key={label}
            className={href ? classes.anchor : undefined}
            variant="subtitle1"
            color="inherit"
            component={href ? RouterLink : undefined}
            to={href}
          >
            {label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default RouterBreadcrumbs;
