import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationAlert } from "../../../cargotic-webapp-component";

const VehicleExpirationDeleteConfirmationAlert = ({
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationAlert
      title={t("webapp:vehicle.title.delete-expiration")}
      content={t("webapp:vehicle.prompt.delete-expiration")}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default VehicleExpirationDeleteConfirmationAlert;
