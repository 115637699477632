import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import queryString from "query-string";

import { CircularProgress, makeStyles } from "@material-ui/core";

import useRouter from "../../../cargotic-webapp/component/hook/useRouter";
import useAuth from "../../../cargotic-webapp/component/hook/useAuth";
import { useApiClient } from "../../../cargotic-webapp-component";
import VehicleOverview from "../VehicleOverview";

const useStyles = makeStyles(() => ({
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const VehicleOverviewContainer = ({
  className,
  user,
  activity,
  vehicle: {
    model,
    manufacturer,
    id
  },
  isActivityLoading,
  onActivityLoad,
  handleRegisterBreadcrumb,
  onCommentaryChange,
  onCommentarySubmit,
  onCommentaryDelete
}) => {

  const client = useApiClient();
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();

  const [profitHighlights, setProfitHighlights] = useState({
    isLoading: true
  });
  const [travelsHighlights, setTravelsHighlights] = useState({
    isLoading: true
  });
  const [mileageHighlights, setMileageHighlights] = useState({
    isLoading: true
  });
  const [expensesHighlights, setExpensesHighlights] = useState({
    isLoading: true
  });
  const [revenueHighlights, setRevenueHighlights] = useState({
    isLoading: true
  });
  const [isLoading, setIsLoading] = useState(true);

  const loadProfitHighlights = async () => {
    setProfitHighlights({
      ...profitHighlights,
      isLoading: true
    })

    try {
      const loaded = await client.vehicle.getVehicleProfitHighlights({
        vehicleId: id,
        currency: profitHighlights.currency,
        period: profitHighlights.period
      })

      setProfitHighlights({
        ...profitHighlights,
        ...loaded,
        isLoading: false
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.loadHighlights"), {
        variant: "error"
      });
    }
  }

  const loadTravelsHighlights = async () => {
    setTravelsHighlights({
      ...travelsHighlights,
      isLoading: true
    })

    try {
      const loaded = await client.vehicle.getVehicleTravelsHighlights({
        vehicleId: id,
        period: travelsHighlights.period
      })

      setTravelsHighlights({
        ...travelsHighlights,
        ...loaded,
        isLoading: false
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.loadHighlights"), {
        variant: "error"
      });
    }
  }

  const loadMileageHighlights = async () => {
    setMileageHighlights({
      ...mileageHighlights,
      isLoading: true
    })

    try {
      const loaded = await client.vehicle.getVehicleMileageHighlights({
        vehicleId: id,
        period: mileageHighlights.period
      })

      setMileageHighlights({
        ...mileageHighlights,
        ...loaded,
        isLoading: false
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.loadHighlights"), {
        variant: "error"
      });
    }
  }

  const loadRevenueHighlights = async () => {
    setRevenueHighlights({
      ...revenueHighlights,
      isLoading: true
    })

    try {
      const loaded = await client.vehicle.getVehicleRevenueHighlights({
        vehicleId: id,
        currency: revenueHighlights.currency,
        period: revenueHighlights.period
      })

      setRevenueHighlights({
        ...revenueHighlights,
        ...loaded,
        isLoading: false
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.loadHighlights"), {
        variant: "error"
      });
    }
  }

  const loadExpensesHighlights = async () => {
    setExpensesHighlights({
      ...expensesHighlights,
      isLoading: true
    })

    try {
      const loaded = await client.vehicle.getVehicleExpensesHighlights({
        vehicleId: id,
        currency: expensesHighlights.currency,
        period: expensesHighlights.period
      })

      setExpensesHighlights({
        ...expensesHighlights,
        ...loaded,
        isLoading: false
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.loadHighlights"), {
        variant: "error"
      });
    }
  }

  /*const handleNewShipment = (id) => {
    const queryParameters = {};
    contacts.map((contact) => {
      if (contact.id == id) {
        if (contact.type === "CARRIER") {
          queryParameters.carrierId = id;
        } else if (contact.type === "CUSTOMER") {
          queryParameters.customerId = id;
        }
      }
    });

    const query = queryString.stringify(queryParameters);
    history.push(`/shipment?${query}`)
  };*/

  const onTravelsHeaderIconClick = () => {
    const queryParameters = {};

    queryParameters.filterVehicleId = id;
    const query = queryString.stringify(queryParameters);
    history.push(`/shipments?${query}`);
  }

  const handleProfitHighlightsChange = value => setProfitHighlights(value);
  const handleMileageHighlightsChange = value => setMileageHighlights(value);
  const handleTravelsHighlightsChange = value => setTravelsHighlights(value);
  const handleRevenueHighlightsChange = value => setRevenueHighlights(value);
  const handleExpensesHighlightsChange = value => setExpensesHighlights(value);

  useEffect(() => {
    onActivityLoad();
    handleRegisterBreadcrumb(t("webapp:common.title.overview"));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (expensesHighlights.currency === undefined
      || expensesHighlights.period === undefined) {
        return;
      }

    loadExpensesHighlights();
  }, [expensesHighlights.period, expensesHighlights.currency])

  useEffect(() => {
    if (profitHighlights.currency === undefined
      || profitHighlights.period === undefined) {
        return;
      }

    loadProfitHighlights();
  }, [profitHighlights.period, profitHighlights.currency])


  useEffect(() => {
    if (revenueHighlights.currency === undefined
      || revenueHighlights.period === undefined) {
        return;
      }

    loadRevenueHighlights();
  }, [revenueHighlights.period, revenueHighlights.currency])


  useEffect(() => {
    if (mileageHighlights.period === undefined) {
        return;
      }

    loadMileageHighlights();
  }, [mileageHighlights.period])


  useEffect(() => {
    if (travelsHighlights.period === undefined) {
        return;
      }

    loadTravelsHighlights();
  }, [travelsHighlights.period])


  if (isLoading || isActivityLoading) {
    return (
      <div className={clsx(className, classes.progress)}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <VehicleOverview
      className={className}
      user={user}
      profitHighlights={profitHighlights}
      expensesHighlights={expensesHighlights}
      travelsHighlights={travelsHighlights}
      mileageHighlights={mileageHighlights}
      revenueHighlights={revenueHighlights}
      onExpensesHighlightsChange={handleExpensesHighlightsChange}
      onProfitHighlightsChange={handleProfitHighlightsChange}
      onRevenueHighlightsChange={handleRevenueHighlightsChange}
      onTravelsHighlightsChange={handleTravelsHighlightsChange}
      onMileageHighlightsChange={handleMileageHighlightsChange}
      onProfitHighlightsChange={handleProfitHighlightsChange}
      activity={activity}
      onCommentaryChange={onCommentaryChange}
      onCommentaryDelete={onCommentaryDelete}
      onCommentarySubmit={onCommentarySubmit}
      onTravelsHeaderIconClick={onTravelsHeaderIconClick}
    />
  );
};

export default VehicleOverviewContainer;
