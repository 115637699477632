const suggestPlace = (placesAutocompleteService, request) =>
  new Promise((resolve, reject) => (
    placesAutocompleteService.getPlacePredictions(
      request,
      (predictions, status) => {
        if (status !== "OK") {
          reject(status);
          return;
        }

        resolve(predictions);
      }
    )
  ));

export default suggestPlace;
