import React from "react";

import { List, ListItem, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    right: 0,
    left: 0,

    zIndex: 100
  }
}));

const SearchAutosuggestTextFieldSuggestArea = ({
  suggestions,
  renderSuggestion,
  onMouseDown,
  onSuggestionSelect,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} onMouseDown={onMouseDown}>
      <List>
        {suggestions.map((suggestion, index) => {
          const key = index;
          const handleClick = () => {
            onSuggestionSelect(suggestion);
          };

          return (
            <ListItem key={key} onClick={handleClick} button>
              {renderSuggestion(suggestion)}
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default SearchAutosuggestTextFieldSuggestArea;
