import React, { useEffect, useRef, useState } from "react";

import { TextField, makeStyles } from "@material-ui/core";

import AutosuggestTextFieldSuggestionArea
  from "./AutosuggestTextFieldSuggestionArea";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  },
  suggestions: {
    position: "absolute",
    top: 50,
    left: 0,
    right: 0,

    zIndex: 100
  }
}));

const AutosuggestTextField = ({
  name,
  value,
  suggest,
  suggestionsComponent,
  getSuggestionText,
  emptySuggestionsLabel,
  onBlur,
  onChange,
  onFocus,
  InputProps,
  ...rest
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const suggestionAreaRef = useRef();
  const isFocused = useRef(false);
  const isSuggestionAreaClickedForBlur = useRef(false);
  const isSuggestionAreaClickedForChange = useRef(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionAreaOpen, setIsSuggestionAreaOpen] = useState(false);
  const [isSuggestionAreaLoading, setIsSuggestionAreaLoading] = useState(true);

  const loadSuggestions = async () => {
    const text = typeof value === "string" ? value : getSuggestionText(value);

    setIsSuggestionAreaLoading(true);
    setSuggestions(await suggest(text));
    setIsSuggestionAreaLoading(false);
  };

  const handleBlur = event => {
    if (isSuggestionAreaClickedForBlur.current) {
      isSuggestionAreaClickedForBlur.current = false;
      return;
    }

    setIsSuggestionAreaOpen(false);
    isFocused.current = false;

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleFocus = event => {
    isFocused.current = true;
    setIsSuggestionAreaOpen(true);
    loadSuggestions();

    if (onFocus) {
      onFocus(event);
    }
  };

  const handleSuggestionClick = (_, suggestion) => {
    if (onChange) {
      onChange({ target: { name, value: suggestion } });
    }
  };

  useEffect(() => {
    if (isSuggestionAreaClickedForChange.current) {
      setIsSuggestionAreaOpen(false);
      isSuggestionAreaClickedForChange.current = false;
    }

    if (isFocused.current) {
      loadSuggestions();
    }
  }, [value]);

  useEffect(() => {
    const { current: element } = suggestionAreaRef;

    if (!element) {
      return () => {};
    }

    const listener = () => {
      isSuggestionAreaClickedForBlur.current = true;
      isSuggestionAreaClickedForChange.current = true;
    };

    element.addEventListener("mousedown", listener);

    return () => {
      element.removeEventListener("mousedown", listener);
    };
  });

  return (
    <div className={classes.root}>
      <TextField
        {...rest}
        inputRef={inputRef}
        name={name}
        value={typeof value === "string" ? value : getSuggestionText(value)}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        autoComplete="off"
        InputProps={InputProps}
      />
      <AutosuggestTextFieldSuggestionArea
        ref={suggestionAreaRef}
        className={classes.suggestions}
        suggestions={suggestions}
        suggestionsComponent={suggestionsComponent}
        emptySuggestionsLabel={emptySuggestionsLabel}
        isLoading={isSuggestionAreaLoading}
        isOpen={isSuggestionAreaOpen}
        onSuggestionClick={handleSuggestionClick}
      />
    </div>
  );
};

export default AutosuggestTextField;
