import React from "react";
import { Paper } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import {
  FormikTextField
} from "../../../../cargotic-webapp-component";

const SettingsRoleEditorGeneral = ({ classes, form }) => {
  const { t } = useTranslation();

  return (
    <Paper className={classes.form}>
      <div>
        <FormikTextField
          required
          name="roleName"
          fullWidth
          form={form}
          label={t("webapp:common.title.name")}
        />
      </div>
      <div>
        <FormikTextField
          required
          form={form}
          name="description"
          label={t("webapp:common.title.description")}
          fullWidth
        />
      </div>
    </Paper>
  );
}

export default SettingsRoleEditorGeneral;
