import { Keyable } from "./type";

function hasProperty<T, K extends keyof T>(
  object: T,
  name: Keyable
): name is K {
  return Object.prototype.hasOwnProperty.call(object, name);
}

function fromEntries<K extends Keyable, V, O extends { [key in K]: V }>(
  entries: [K, V][]
): O {
  return entries
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {} as O);
}

export {
  hasProperty,
  fromEntries
};
