import React, { useState } from "react";

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { CompanyProfileSchema } from "../../../utility/validationSchema";
import PlacesAutocompleteTextField from "../../common/Input/PlacesAutocompleteTextField";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  error: {
    color: "red"
  }
}));

const CreateCompanyProfileDialog = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [address, setAddress] = useState({
    title: "",
    placeId: ""
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("company.create")}</DialogTitle>
      <Formik
        validationSchema={CompanyProfileSchema}
        initialValues={{
          companyName: "",
          ic: "",
          dic: "",
          url: "",
          address: ""

        }}
        onSubmit={(values, { setSubmitting }) => {
          const {
            ic, dic, companyName, url, email
          } = values;
          setSubmitting(false);
          handleSubmit(ic, dic, companyName, url, email, address.title, address.placeId);
        }}
      >
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          submitForm,
          errors,
          touched,
          values,
          setValues
        }) => (
          <>
            <DialogContent>
              <form className={classes.container}>
                <div>
                  <TextField
                    required
                    name="companyName"
                    label={t("contacts.companyName")}
                    error={errors.companyName && touched.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    fullWidth
                  />
                  {errors.companyName && touched.companyName && (
                    <Typography variant="subtitle2" className={classes.error}>
                      {t(errors.companyName)}
                    </Typography>
                  )}
                </div>
                <div>
                  <TextField
                    name="ic"
                    label="IČ"
                    error={errors.ic && touched.ic}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    value={values.ic}
                  />
                  {errors.ic && touched.ic && (
                    <Typography variant="subtitle2" className={classes.error}>
                      {t(errors.ic)}
                    </Typography>
                  )}
                </div>
                <div>
                  <TextField
                    name="dic"
                    label="DIČ"
                    required
                    value={values.dic}
                    fullWidth
                    error={errors.dic && touched.dic}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.dic && touched.dic && (
                    <Typography variant="subtitle2" className={classes.error}>
                      {t(errors.dic)}
                    </Typography>
                  )}
                </div>
                <div>
                  <TextField
                    type="url"
                    value={values.url}
                    name="url"
                    error={errors.url && touched.url}
                    label="Web"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.url && touched.url && (
                    <Typography variant="subtitle2" className={classes.error}>
                      {t(errors.url)}
                    </Typography>
                  )}
                </div>
                <PlacesAutocompleteTextField
                  required
                  name="address"
                  label={t("contacts.address")}
                  placeholder={t("contacts.address")}
                  value={values.address || ""}
                  suggestions={addressSuggestions}
                  error={touched.address && errors.address}
                  helperText={touched.address && t(errors.address) || " "}
                  onChange={value => setValues({ ...values, address: value })}
                  onBlur={handleBlur}
                  onSuggestionSelect={setAddress}
                  onSuggestionsChange={setAddressSuggestions}
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("abort")}
              </Button>
              <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                {t("create")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateCompanyProfileDialog;
