import React from "react";

import { ListItem } from "@material-ui/core";

import { AutosuggestSuggestionComponentProps } from "../Autosuggest";

interface SuggestionAreaListItemProps<T> {
  suggestion: T;

  suggestionComponent:
    React.ComponentType<AutosuggestSuggestionComponentProps<T>>;

  onClick?: (suggestion: T) => void;
}

function SuggestionAreaListItem<T>(
  {
    suggestion,
    suggestionComponent: SuggestionComponent,
    onClick
  }: SuggestionAreaListItemProps<T>
): React.ReactElement {
  const handleClick = (): void => {
    if (onClick) {
      onClick(suggestion);
    }
  };

  return (
    <ListItem button onClick={handleClick}>
      <SuggestionComponent suggestion={suggestion} />
    </ListItem>
  );
}

export default SuggestionAreaListItem;
