import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  CardActions,
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  Box
} from "@material-ui/core";

import { Place, Delete } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";

import { EditableTypography } from "../../../../packages/cargotic-common";
import { generateWaypointTitle } from "../../../cargotic-core";
import IconBox from "../IconBox";
import SearchAutosuggestTextField from "../SearchAutosuggestTextField";


const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    position: "relative",
    marginTop: spacing(4)
  },
  actions: {
    justifyContent: "flex-end"
  },
  box: {
    position: "absolute",

    left: spacing(1),
    top: spacing(-4)
  },
  activeCardBorder: {
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: palette.primary.main
  },
  unactiveCardBorder: {
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "transparent"
  },
  card: {
    overflow: "initial"
  },
  collapsed: {
    marginTop: spacing(0)
  },
  content: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1)
  },
  title: {
    paddingLeft: 100,
    marginBottom: 32,

    minHeight: spacing(4)
  }
}));

const WaypointBrowserItem = ({
  waypoint: {
    place: {
      googleId,
      alias,
      name,
      address: {
        formatted: formattedAddress
      },
      thumbnail
    },
    contact,
    phoneNumber,
    note,
    arriveAtFrom,
    arriveAtTo
  },
  errors,
  minimumArriveAt,
  index,
  lastLatLng,
  setLastLatLng,
  isExpanded,
  isFirst,
  isDeletable,
  isLast,
  suggestPlace,
  searchPlace,
  searchPlaceByCoordinates,
  isCargoEditorVisible,
  handlePickFromMap,
  onPlaceSearchFail,
  onChange,
  onError,
  onDelete,
  onExpand,
  isDraft
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const title = alias
    ?? name
    ?? generateWaypointTitle(t, index, isFirst, isLast);

  const handleChange = ({ target: { name, value } }) =>
    onChange(name, value);

  const handleMouseEnter = () => {
    if (!isExpanded) {
      onExpand();
    }
  };

  const haveError = (key) => Object.keys(errors).includes(key);

  return (
    <div className={classes.root}>
      <Box className={isExpanded ? classes.activeCardBorder : classes.unactiveCardBorder}>
        <IconBox thumbnail={thumbnail} className={classes.box}>
          <Place fontSize="large" />
        </IconBox>
        <Card className={classes.card} elevation={4}>
          <CardContent
            className={classes.content}
            onMouseEnter={handleMouseEnter}
          >
            <div className={classes.title}>
              <EditableTypography
                variant="h6"
                value={title}
                maxLength={128}
                isDisabled={googleId === undefined}
                onChange={({ target: { value } }) => onChange("alias", value)}
                onBlur={() => (
                  alias === "" ? onChange("alias", undefined) : undefined
                )}
              />
            </div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SearchAutosuggestTextField
                  required
                  lastLatLng={lastLatLng}
                  setLastLatLng={setLastLatLng}
                  isCargoEditorVisible={isCargoEditorVisible}
                  text={formattedAddress ?? ""}
                  value={googleId}
                  helperText={t(errors.place)}
                  error={haveError("place")}
                  label={t("webapp:journey.planner.address")}
                  variant="outlined"
                  renderSuggestion={({ description }) => (
                    <>
                      <ListItemIcon>
                        <Place />
                      </ListItemIcon>
                      <ListItemText primary={description} />
                    </>
                  )}
                  search={searchPlace}
                  searchByCoordinates={searchPlaceByCoordinates}
                  handlePickFromMap={handlePickFromMap}
                  suggest={suggestPlace}
                  fullWidth
                  onTextChange={(text) => onChange("formattedAddress", text)}
                  onValueChange={(value) => onChange("place", value)}
                  onSearchFail={onPlaceSearchFail}
                  data-cy="address"
                />
              </Grid>
            </Grid>
            <Collapse in={isExpanded}>
              <Grid className={classes.collapsed} container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    name="contact"
                    value={contact}
                    label={t("webapp:journey.planner.contact")}
                    variant="outlined"
                    error={haveError("contact")}
                    helperText={t(errors.contact)}
                    fullWidth
                    onChange={handleChange}
                    data-cy="contact"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="phoneNumber"
                    value={phoneNumber}
                    label={t("webapp:journey.planner.phoneNumber")}
                    variant="outlined"
                    error={haveError("phoneNumber")}
                    helperText={t(errors.phoneNumber)}
                    fullWidth
                    onChange={handleChange}
                    data-cy="phoneNumber"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="note"
                    value={note}
                    label={t("webapp:journey.planner.note")}
                    variant="outlined"
                    error={haveError("note")}
                    helperText={t(errors.note)}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <DateTimePicker
                    required={!isDraft}
                    allowKeyboardControl
                    allowSameDateSelection
                    value={arriveAtFrom === undefined ? null : arriveAtFrom}
                    label={t("webapp:journey.planner.arriveAtFrom")}
                    inputFormat="dd.MM.yyyy HH:mm"
                    ampm={false}
                    clearable
                    disableMaskedInput
                    onError={console.error}
                    renderInput={(props) => <TextField variant="outlined" {...props} helperText={t(errors.arriveAtFrom)} error={haveError("arriveAtFrom")} inputProps={{ ...props.inputProps, placeholder: "01.01.2020 15:00" }} />}
                    fullWidth
                    KeyboardButtonProps={{ size: "small" }}
                    onChange={(value) => {
                      if (value != null && value.getTime() === value.getTime()) {
                        onChange("arriveAtFrom", value);
                      } else {
                        onError("arriveAtFrom", "shipments.validation.dateValid");
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <DateTimePicker
                    required={!isDraft}
                    value={
                      arriveAtTo === undefined
                        ? arriveAtFrom === undefined
                          ? null
                          : arriveAtFrom
                        : arriveAtTo
                    }
                    label={t("webapp:journey.planner.arriveAtTo")}
                    ampm={false}
                    allowKeyboardControl
                    allowSameDateSelection
                    fullWidth
                    disableMaskedInput
                    clearable
                    onError={console.error}
                    inputFormat="dd.MM.yyyy HH:mm"
                    renderInput={(props) => <TextField variant="outlined" {...props} helperText={t(errors.arriveAtTo)} error={haveError("arriveAtTo")} inputProps={{ ...props.inputProps, placeholder: "01.01.2020 15:00" }} />}
                    KeyboardButtonProps={{ size: "small" }}
                    onChange={(value) => {
                      if (value != null && value.getTime() === value.getTime()) {
                        onChange("arriveAtTo", value);
                      } else {
                        onError("arriveAtTo", "shipments.validation.dateValid");
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </CardContent>
          <CardActions className={classes.actions}>
            <Tooltip title={t("webapp:waypoint.browser.button.delete")}>
              <span>
                <IconButton
                  size="small"
                  disabled={!isDeletable}
                  onClick={onDelete}
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </CardActions>
        </Card>
      </Box>
    </div >
  );
};

export default WaypointBrowserItem;
