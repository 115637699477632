import React from "react";

import { IconButton, InputBase, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";

interface SearchBarInputProps {
  placeholder?: string;
  value?: string | null;

  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "2px 16px"
  },
  button: {
    marginLeft: spacing(1)
  },
  input: {
    flex: 1
  }
}));

function SearchBarInput(
  {
    placeholder,
    value,
    onChange,
    onClick,
    onKeyPress
  }: SearchBarInputProps
): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <IconButton className={classes.button} onClick={onClick}>
        <Search />
      </IconButton>
    </div>
  );
}

export default SearchBarInput;
