import React, { useState } from "react";
import VehicleIncomeTable from "../VehicleIncomeTable";

const VehicleIncomeOverview = ({
  search,
  incomes,
  totalIncomes,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onEditClick,
  onDeleteClick,
  isIncomeEditationDisabled,
  isIncomeDeletionDisabled
}) => (
    <VehicleIncomeTable
      incomes={incomes}
      totalIncomes={totalIncomes}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      isIncomeEditationDisabled={isIncomeEditationDisabled}
      isIncomeDeletionDisabled={isIncomeDeletionDisabled}
    />
  );

export default VehicleIncomeOverview;
