import { appendQueryParameters, loadScript } from "../../cargotic-common";

const GOOGLE_MAPS_API_URL = "https://maps.googleapis.com/maps/api/js";

const loadGoogleMapsApi = async (key, libraries, language) => {
  const url = appendQueryParameters(
    GOOGLE_MAPS_API_URL,
    {
      key,
      language,
      libraries: libraries.join(",")
    }
  );

  await loadScript(url);

  return google.maps;
};

export default loadGoogleMapsApi;
