import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: "relative",

    paddingTop: 10,
    paddingRight: spacing(2),
    paddingLeft: spacing(2),

    overflowY: "auto",

    flex: 1,

    backgroundColor: palette.background.default
  }
}));

const PaneContent = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default PaneContent;
