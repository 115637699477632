import React, { useState, useEffect } from "react";

import {
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Paper,
  Divider,
  Grid,
  Button,
  Tooltip,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import { ShipmentType, ShipmentState } from "@cargotic/model-deprecated";
import {
  usePrevious,
  deserializeFloat,
  identity,
  pipe,
  ValueError,
} from "@cargotic/common-deprecated";

import { Currency, useExchangeRates } from "@cargotic/currency";
import { allowEmptyString, required, isObject } from "@cargotic/validate";
import sha256 from "crypto-js/sha256";

import {
  deleteShipmentDocument,
  useApiClient,
} from "@cargotic/api-client-deprecated";

import { Edit, AllInbox, Check, Close, CopyAll } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { createFormValidator, useForm } from "../../cargotic-webapp/form";

import {
  putIncomingOrderById,
  getIncomingOrderServices,
  postFile,
  deleteFile,
} from "../../cargotic-webapp/resource";

import ShipmentFormType from "../../cargotic-webapp/utility/ShipmentFormType";
import client from "../../cargotic-webapp/client";

import CargoItemList from "../../../multiload/cargotic-component/component/CargoItemList";

import { useApiClient as useNewApiClient } from "../../cargotic-webapp-component";
import useAuth from "../../cargotic-webapp/component/hook/useAuth";
import { generateUuid } from "../../../multiload/cargotic-common";

import ForwarderFormComponent from "./ForwarderFormComponent";
import CarrierFormComponent from "./CarrierFormComponent";
import OrderFormComponent from "./OrderFormComponent";
import WaypointsSection from "./WaypointsSection";
import config from "../../cargotic-webapp/config";

const { documents: { url: documentsURL } } = config;

const INITAL_PAYMENT_DUE_DAYS = "60";

const useStyles = makeStyles(({ spacing }) => ({
  tabContentContainer: {
    paddingTop: spacing(1),
  },
  subStatusText: {
    fontSize: 28,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: spacing(3),
    textTransform: "uppercase",
  },
  markPoint: {
    height: 8,
    width: 1,
    marginTop: -3,
  },
  containerMarginBottom: {
    marginBottom: spacing(2),
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
  gutterBottom: {
    marginBottom: spacing(2),
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  isDrivenThrough: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  isDrivenThroughIcon: {
    marginLeft: 5,
    marginRight: 15,
    width: 18,
    height: 18,
  },
  cardLabel: {
    borderRadius: 10,
    marginRight: 8,
    width: 50,
    height: 8,
  },
  cardLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  labelsColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  infoHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  contactTable: {
    width: "98%",
  },
  priceRow: {
    "& > :not(:first-child)": {
      paddingLeft: spacing(2),
    },
  },
  waypointAddress: {
    marginLeft: "4px",
  },
}));

const BasicInfo = ({
  entityType,

  shipment: propsShipment,
  incomingOrder,
  outcomingOrder,
  isDriveThroughLoading,
  setIsDriveThroughLoading,
  waypoints,
  cargos,
  nextShipmentNumber,
  errorAction,
  fetchActivity,

  setUpdatingWaypoint,
  setUpdateDriveThroughDialogOpen,

  updateShipmentsDriveThrough,
  onSave,
  onDraftSave,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const apiClient = useApiClient();
  const newApiClient = useNewApiClient();
  const { enqueueSnackbar } = useSnackbar();
  const { exchangeRates } = useExchangeRates();
  const {
    user: { token },
  } = useAuth();

  // Temporary shipment -> order solution
  const shipment = propsShipment || incomingOrder || outcomingOrder;
  const [hasDocumentFileDropzoneError, setHasDocumentFileDropzoneError] =
    useState(false);
  const [
    hasAdditionalDocumentFileDropzoneError,
    setHasAdditionalsDocumentFileDropzoneError,
  ] = useState(false);
  const [
    hasTermsDocumentFileDropzoneError,
    setHasTermsDocumentFileDropzoneError,
  ] = useState(false);
  const [openTooltipCopyToClipboard, setOpenTooltipCopyToClipboard] =
    useState(false);
  const [availableServices, setAvailableServices] = useState([]);
  const [availableOutcomingOrderServices, setAvailableOutcomingOrderServices] =
    useState([]);

  const validateDeleted = (x, message) => {
    if (typeof x === "object" && x?.isDeleted) {
      throw new ValueError(message);
    }

    return x;
  };

  const validateContact = pipe(
    (contact) => required(contact, t("webapp:shipment.form.error.contact")),
    (contact) => isObject(contact, t("webapp:shipment.form.error.contact")),
    (contact) =>
      validateDeleted(contact, t("webapp:shipment.form.error.deletedContact"))
  );

  const validateDraftContact = pipe(
    allowEmptyString((contact) =>
      isObject(contact, t("webapp:shipment.form.error.contact"))
    ),
    (contact) =>
      validateDeleted(contact, t("webapp:shipment.form.error.deletedContact"))
  );

  const validateEmployee = pipe(
    allowEmptyString((employee) =>
      isObject(employee, t("webapp:shipment.form.error.employee"))
    ),
    (employee) =>
      validateDeleted(employee, t("webapp:shipment.form.error.deletedEmployee"))
  );

  const isString = (x) => typeof x === "string";

  const validateDraftPrice = allowEmptyString((x) =>
    deserializeFloat(x, t("webapp:shipment.form.error.price"))
  );

  const validateDriver = pipe(
    (driver) => required(driver, t("webapp:shipment.form.error.driver")),
    (driver) => isObject(driver, t("webapp:shipment.form.error.driver")),
    (driver) =>
      validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  );

  const validateDraftDriver = pipe(
    allowEmptyString((driver) =>
      isObject(driver, t("webapp:shipment.form.error.driver"))
    ),
    (driver) =>
      validateDeleted(driver, t("webapp:shipment.form.error.deletedDriver"))
  );

  const validateVehicle = pipe(
    (vehicle) => required(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) => isObject(vehicle, t("webapp:shipment.form.error.vehicle")),
    (vehicle) =>
      validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  );

  const validateDraftVehicle = pipe(
    allowEmptyString((vehicle) =>
      isObject(vehicle, t("webapp:shipment.form.error.vehicle"))
    ),
    (vehicle) =>
      validateDeleted(vehicle, t("webapp:shipment.form.error.deletedVehicle"))
  );

  const validateTrailer = pipe(
    allowEmptyString((trailer) =>
      isObject(trailer, t("webapp:shipment.form.error.trailer"))
    ),
    (trailer) =>
      validateDeleted(trailer, t("webapp:shipment.form.error.deletedTrailer"))
  );

  const validatePrice = pipe(
    (x) => required(x, t("webapp:shipment.form.error.price")),
    (x) => deserializeFloat(x, t("webapp:shipment.form.error.price"))
  );

  const validateOptionalString = allowEmptyString(identity);

  const validateCarrierFormSubmitDraft = ({ values }) => {
    let errors = {};
    try {
      validateDraftVehicle(values.vehicle);
    } catch (err) {
      errors = { ...errors, vehicle: err };
    }
    try {
      validateDraftDriver(values.driver);
    } catch (err) {
      errors = { ...errors, driver: err };
    }
    try {
      validateTrailer(values.trailer);
    } catch (err) {
      errors = { ...errors, trailer: err };
    }

    return errors;
  };

  const validateOrderFormSubmitDraft = ({ values }) => {
    let errors = {};

    // validate shared values for both forms
    try {
      validateDraftContact(values.customerContact);
    } catch (err) {
      errors = { ...errors, customerContact: err };
    }
    try {
      validateEmployee(values.customerEmployee);
    } catch (err) {
      errors = { ...errors, customerEmployee: err };
    }
    try {
      validateDraftPrice(values.customerPrice);
    } catch (err) {
      errors = { ...errors, customerPrice: err };
    }
  };

  const validateForwarderFormSubmitDraft = ({ values }) => {
    let errors = {};

    // validate forwarder part
    try {
      validateDraftContact(values.carrierContact);
    } catch (err) {
      errors = { ...errors, carrierContact: err };
    }
    try {
      validateEmployee(values.carrierEmployee);
    } catch (err) {
      errors = { ...errors, carrierEmployee: err };
    }
    try {
      validateDraftPrice(values.carrierPrice);
    } catch (err) {
      errors = { ...errors, carrierPrice: err };
    }
  };

  const handleDocumentDelete = async (uuid, form) => {
    const { url, bucket, name } = form.values.documents.find(
      ({ uuid: other }) => other === uuid
    );

    if (url) {
      try {
        await deleteFile(bucket, name);
      } catch (error) {
        console.error(error);

        enqueueSnackbar(t("webapp:shipment.form.document.error.file.delete"), {
          variant: "error",
        });

        return;
      }
    }

    form.setValues((values) => ({
      ...values,
      documents: values.documents.filter(({ uuid: other }) => other !== uuid),
    }));
  };

  const handleDocumentFileDropzoneError = () =>
    setHasDocumentFileDropzoneError(true);

  const handleAdditionalDocumentFileDropzoneError = () =>
    setHasAdditionalDocumentFileDropzoneError(true);

  const handleTermsDocumentFileDropzoneError = () =>
    setHasTermsDocumentFileDropzoneError(true);

  const handleDocumentFilesSelect = (files, form, bucket) => {
    setHasDocumentFileDropzoneError(false);
    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true,
        bucket,
      };

      const updateDocument = (update) =>
        form.setValues((values) => ({
          ...values,
          documents: values.documents.map((other) => {
            if (other.uuid !== uuid) {
              return other;
            }

            return { ...other, ...update };
          }),
        }));

      form.setValues((values) => ({
        ...values,
        documents: [...values.documents, document],
      }));

      try {
        const { url, createdAt, id } = await postFile({
          bucket,
          file,
        });
        updateDocument({
          id,
          url,
          createdAt: new Date(createdAt),
          isUploading: false,
          bucket,
        });
      } catch (error) {
        console.error(error);
        updateDocument({
          isUploading: false,
          error: t(
            error?.response?.data?.error === "FileTooLargeError"
              ? "webapp:shipment.form.document.error.upload.size"
              : error?.response?.data?.error === "StorageLimitExceeded"
                ? "webapp:incomingOrder.form.document.error.upload.limit"
                : "webapp:shipment.form.document.error.upload.general"
          ),
        });
      }
    });
  };

  const onShipmentSave = async (updatedShipment) => {
    try {
      onSave(updatedShipment);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("shipment.update.error.general"), { variant: "error" });
    }
  };

  const forwarderForm = useForm({
    initialValues: {
      carrierContact: shipment.carrierContact ?? "",
      driverContact: shipment.driverContact,
      carrierPrice: shipment.carrierPrice ?? "",
      carrierPriceCurrency: shipment.carrierPriceCurrency ?? Currency.CZK,
      services: shipment.services,
      carrierEmployee: shipment.carrierEmployee ?? "",
      isCarrierPriceWithDph: shipment.isCarrierPriceWithDph,
      documents: shipment.documents
        ? shipment.documents.map((document) => ({
          ...document,
          createdAt: new Date(document.createdAt),
          uuid: generateUuid(),
        }))
        : [],
    },
    validate: createFormValidator({
      driverContact: validateOptionalString,
      carrierContact: validateContact,
      carrierPrice: validatePrice,
      carrierEmployee: validateEmployee,
    }),
    onChange: (
      {
        carrierPrice,
        carrierContact: newCarrierContact,
        carrierPriceCurrency: newCarrierPriceCurrency,
      },
      {
        carrierContact: oldCarrierContact,
        carrierPriceCurrency: oldCarrierPriceCurrency,
      }
    ) => {
      if (newCarrierPriceCurrency !== oldCarrierPriceCurrency) {
        const parsedCarrierPrice = parseFloat(carrierPrice);

        if (Number.isNaN(parsedCarrierPrice)) {
          return;
        }

        forwarderForm.setValue("carrierPrice", parsedCarrierPrice);
      }

      if (newCarrierContact !== oldCarrierContact) {
        forwarderForm.setValue("carrierContact", newCarrierContact);

        if (newCarrierContact === undefined || isString(newCarrierContact)) {
        }
      }
    },
    onSubmit: (values) => {
      console.log(values);
      onShipmentSave({
        ...values,
      });
    },
  });

  const orderForm = useForm({
    initialValues: {
      customerContact: shipment.customerContact ?? "",
      customerPrice: shipment.customerPrice ?? "",
      customerPriceCurrency: shipment.customerPriceCurrency ?? Currency.CZK,
      customerEmployee: shipment.customerEmployee ?? "",
      isCustomerPriceWithDph: shipment.isCustomerPriceWithDph,
      services: shipment.services,
      documents: shipment.documents
        ? shipment.documents.map((document) => ({
          ...document,
          createdAt: new Date(document.createdAt),
          uuid: generateUuid(),
        }))
        : [],
    },
    validate: createFormValidator({
      driverContact: validateOptionalString,
      customerContact: validateContact,
      customerEmployee: validateEmployee,
      customerPrice: validatePrice,
    }),
    onChange: (
      {
        customerPrice,
        customerContact: newCustomerContact,
        customerPriceCurrency: newCustomerPriceCurrency,
      },
      {
        customerContact: oldCustomerContact,
        customerPriceCurrency: oldCustomerPriceCurrency,
      }
    ) => {
      if (newCustomerPriceCurrency !== oldCustomerPriceCurrency) {
        const parsedCustomerPrice = parseFloat(customerPrice);

        if (Number.isNaN(parsedCustomerPrice)) {
          return;
        }

        orderForm.setValue("customerPrice", parsedCustomerPrice);
      }

      if (newCustomerContact !== oldCustomerContact) {
        orderForm.setValue("customerContact", newCustomerContact);
      }
    },
    onSubmit: async (values) => {
      const order = {
        ...shipment,
        ...values,
        customerPrice:
          values.customerPrice === "" ? undefined : values.customerPrice,
        customerPaymentDueDays:
          values.customerPaymentDueDays === ""
            ? undefined
            : values.customerPaymentDueDays,
        indexNumber: shipment.isDraft
          ? nextShipmentNumber
          : shipment.indexNumber,
        state: shipment.isDraft ? ShipmentState.READY : shipment.state,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id, bucket }) => ({ id, bucket })),
        isDraft: false,
        journey: undefined,
      };
      delete order.tableCells;
      await putIncomingOrderById(shipment.id, order);
      enqueueSnackbar(t("incomingOrders.update.success"), {
        variant: "success",
      });
    },
  });

  const carrierForm = useForm({
    initialValues: {
      driver: shipment.driver ?? "",
      vehicle: shipment.vehicle ?? "",
      trailer: shipment.trailer ?? "",
      type: ShipmentType.CARRIED,
      documents: shipment.documents
        ? shipment.documents.map((document) => ({
          ...document,
          createdAt: new Date(document.createdAt),
          uuid: generateUuid(),
        }))
        : [],
      driverContact: shipment.driverContact ?? "",
    },
    validate: createFormValidator({
      driver: validateDriver,
      vehicle: validateVehicle,
      trailer: validateTrailer,
    }),
    onSubmit: (values) => {
      onShipmentSave({
        ...shipment,
        ...values,
        indexNumber: shipment.isDraft
          ? nextShipmentNumber
          : shipment.indexNumber,
        // state: shipment.isDraft ? ShipmentState.READY : shipment.state,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id })),
        isDraft: false,
        journey: undefined,
      });
    },
  });

  const previousOrderValues = usePrevious(orderForm.values);
  const previousForwarderValues = usePrevious(forwarderForm.values);
  const previousCarrierValues = usePrevious(carrierForm.values);

  const handleVehicleChange = async () => {
    let { vehicle } = carrierForm.values;

    if (typeof vehicle === "string") {
      return;
    }

    if (
      typeof vehicle === "object" &&
      vehicle?.id !== previousCarrierValues?.vehicle?.id
    ) {
      vehicle = await client.vehicle.getVehicle({ vehicleId: vehicle.id });

      carrierForm.setValue("vehicle", vehicle);
    }

    if (vehicle?.defaultDriver) {
      carrierForm.setValue("driver", vehicle.defaultDriver);
    }

    if (vehicle?.defaultTrailer) {
      carrierForm.setValue("trailer", vehicle.defaultTrailer);
    }
  };

  const fetchContact = async (contactId) =>
    newApiClient.contact.getContact({ contactId });

  const resolveFullContact = async (form, type, id) => {
    const fetchedContact = await fetchContact(id);
    form.setValue(type, fetchedContact);

    if (fetchedContact.paymentDueDays) {
      type === "customerContact"
        ? form.setValue("customerPaymentDueDays", fetchedContact.paymentDueDays)
        : form.setValue("carrierpaymentDueDays", fetchedContact.paymentDueDays);
    } else {
      type === "customerContact"
        ? form.setValue("carrierPaymentDueDays", INITAL_PAYMENT_DUE_DAYS)
        : form.setValue("carrierPaymentDueDays", INITAL_PAYMENT_DUE_DAYS);
    }
  };

  useEffect(() => {
    getIncomingOrderServices().then((services) => {
      setAvailableServices(services.map(({ name, type }) => ({ name, type })));
    });
  }, []);

  useEffect(() => {
    const {
      values: { carrierContact },
    } = forwarderForm;
    const { carrierContact: oldCarrierContact } = previousCarrierValues ?? {};

    if (
      !carrierContact ||
      (oldCarrierContact && oldCarrierContact?.id !== carrierContact?.id)
    ) {
      forwarderForm.setValue("carrierEmployee", "");
    }

    // business address is only field that is different from Contact and ContactReference for sure
    if (carrierContact?.billingAddress || typeof carrierContact === "string") {
      return;
    }

    resolveFullContact(forwarderForm, "carrierContact", carrierContact.id);
  }, [forwarderForm.values.carrierContact]);

  useEffect(() => {
    const {
      values: { customerContact },
    } = orderForm;
    const { customerContact: oldCustomerContact } =
      previousForwarderValues ?? {};

    if (
      !customerContact ||
      (oldCustomerContact && oldCustomerContact?.id !== customerContact?.id)
    ) {
      orderForm.setValue("customerEmployee", "");
    }

    // business address is only field that is different from Contact and ContactReference for sure
    if (
      customerContact?.billingAddress ||
      typeof customerContact === "string"
    ) {
      return;
    }

    resolveFullContact(orderForm, "customerContact", customerContact.id);
  }, [orderForm.values.customerContact]);

  useEffect(() => {
    handleVehicleChange();
  }, [carrierForm.values.vehicle]);

  const onSubmitDraft = async (form) => {
    try {
      const { values } = form;

      const errs =
        entityType === "SHIPMENT"
          ? validateCarrierFormSubmitDraft(form)
          : entityType === "INCOMING_ORDER"
            ? validateOrderFormSubmitDraft(form)
            : validateForwarderFormSubmitDraft(form);

      if (Object.keys(errs).length !== 0) {
        form.setErrors(errs);
        return;
      }

      onDraftSave({
        ...shipment,
        ...form.values,
      });
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("shipment.update.error.general"), { variant: "error" });
    }
  };

  const saveShipment = () =>
    entityType === "SHIPMENT"
      ? carrierForm.handleSubmit()
      : entityType === "INCOMING_ORDER"
        ? orderForm.handleSubmit()
        : forwarderForm.handleSubmit();

  const getBasicInfoContent = () => (
    <form
      onSubmit={
        entityType === "SHIPMENT"
          ? carrierForm.handleSubmit
          : entityType === "INCOMING_ORDER"
            ? orderForm.handleSubmit
            : forwarderForm.handleSubmit
      }
    >
      <>
        {entityType === "SHIPMENT" ? (
          <CarrierFormComponent
            form={carrierForm}
            incomingOrder={!!entityType === "INCOMING_ORDER"}
            hasDocumentFileDropzoneError={hasDocumentFileDropzoneError}
            handleDocumentFileDropzoneError={handleDocumentFileDropzoneError}
            handleDocumentFilesSelect={handleDocumentFilesSelect}
            handleDocumentDelete={handleDocumentDelete}
          />
        ) : entityType === "INCOMING_ORDER" ||
          entityType === "WAREHOUSE_ORDER" ? (
          <OrderFormComponent
            form={orderForm}
            availableServices={availableServices}

            handleDocumentFilesSelect={handleDocumentFilesSelect}
            handleDocumentDelete={handleDocumentDelete}

            hasAdditionalDocumentFileDropzoneError={
              hasAdditionalDocumentFileDropzoneError
            }
            handleAdditionalDocumentFileDropzoneError={
              handleAdditionalDocumentFileDropzoneError
            }
            hasTermsDocumentFileDropzoneError={
              hasTermsDocumentFileDropzoneError
            }
            handleTermsDocumentFileDropzoneError={
              handleTermsDocumentFileDropzoneError
            }
          />
        ) : (
          <ForwarderFormComponent
            form={forwarderForm}
            availableServices={availableServices}

            handleDocumentFilesSelect={handleDocumentFilesSelect}
            handleDocumentDelete={handleDocumentDelete}

            hasAdditionalDocumentFileDropzoneError={
              hasAdditionalDocumentFileDropzoneError
            }
            handleAdditionalDocumentFileDropzoneError={
              handleAdditionalDocumentFileDropzoneError
            }
            hasTermsDocumentFileDropzoneError={
              hasTermsDocumentFileDropzoneError
            }
            handleTermsDocumentFileDropzoneError={
              handleTermsDocumentFileDropzoneError
            }
          />
        )}
      </>
      <div style={{ marginTop: 20 }}>
        {entityType === "SHIPMENT" && !token && (
          <Button
            variant="contained"
            color="primary"
            disabled={!shipment.isDraft}
            onClick={() =>
              onSubmitDraft(
                entityType === "SHIPMENT"
                  ? carrierForm
                  : entityType === "INCOMING_ORDER"
                    ? orderForm
                    : forwarderForm
                // formType === ShipmentFormType.CARRIER
                //   ? carrierForm
                //   : forwarderForm
              )
            }
            style={{ marginRight: 5 }}
          >
            {t("webapp:shipment.form.button.draft")}
          </Button>
        )}
        {propsShipment && !token && (
          <Button variant="contained" color="primary" type="submit">
            {t("webapp:common.title.save")}
          </Button>
        )}
      </div>
    </form>
  );

  return (
    <div className={classes.tabContentContainer}>
      <section className={classes.gutterBottom}>
        <div className={classes.infoHeader}>
          <div>
            <Typography className={classes.subtitle} gutterBottom>
              {t(
                entityType === "SHIPMENT"
                  ? "board.indexNumber"
                  : "outcomingOrders.indexNumber"
              )}
            </Typography>
            <Typography style={{ fontSize: 20, fontWeight: 300 }} gutterBottom>
              {!shipment.isDraft
                ? `#${shipment.indexNumber}`
                : t("board.draft")}
              {entityType === "OUTCOMING_ORDER" ? (
                <ClickAwayListener
                  onClickAway={() => setOpenTooltipCopyToClipboard(false)}
                >
                  <span>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setOpenTooltipCopyToClipboard(false)}
                      open={openTooltipCopyToClipboard}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Copied"
                    >
                      <IconButton
                        onClick={() => {
                          const hash = sha256(
                            `${shipment.companyId}-${shipment.id}`
                          ).toString();
                          navigator.clipboard.writeText(
                            `${documentsURL}/?token=${hash}`
                          );
                          setOpenTooltipCopyToClipboard(true);
                        }}
                      >
                        <CopyAll />
                      </IconButton>
                    </Tooltip>
                  </span>
                </ClickAwayListener>
              ) : (
                <></>
              )}
            </Typography>
            <Typography className={classes.subtitle} gutterBottom>
              {entityType === "SHIPMENT" || entityType === "INCOMING_ORDER"
                ? t("outcomingOrders.externalReference")
                : undefined}
            </Typography>
            <Typography style={{ fontSize: 13 }} gutterBottom>
              {shipment.externalReference}
            </Typography>
          </div>
        </div>
      </section>
      <Divider className={classes.divider} />
      <section className={classes.gutterBottom}>
        <Typography className={classes.subtitle} gutterBottom>
          {t("board.waypoints")}
        </Typography>
        <Grid container direction="column" spacing={1}>
          <WaypointsSection
            entityType={entityType}
            waypoints={waypoints}
            data={incomingOrder}
            fetchActivity={fetchActivity}
            isDriveThroughLoading={isDriveThroughLoading} // up??
            setIsDriveThroughLoading={setIsDriveThroughLoading}
            // setUpdatingWaypoint={setUpdatingWaypoint}
            // setUpdateDriveThroughDialogOpen={setUpdateDriveThroughDialogOpen}
            updateShipmentsDriveThrough={updateShipmentsDriveThrough}
            errorAction={errorAction}
          />
        </Grid>
      </section>
      <Divider className={classes.divider} />
      <section className={classes.gutterBottom}>
        <Typography className={classes.subtitle} gutterBottom>
          {t("shipments.cargo")}
        </Typography>
        {cargos.length <= 0 ? (
          <Typography variant="subtitle1">{t("board.noCargo")}</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="cargo-table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("shipments.description")}</TableCell>
                  <TableCell>{t("shipments.amount")}</TableCell>
                  <TableCell>{t("shipments.unit")}</TableCell>
                  <TableCell>{t("shipments.length")}</TableCell>
                  <TableCell>{t("shipments.width")}</TableCell>
                  <TableCell>{t("shipments.height")}</TableCell>
                  <TableCell>{t("shipments.weight")}</TableCell>
                  <TableCell>{t("shipments.volume")}</TableCell>
                  <TableCell>{t("shipments.totalWeight")}</TableCell>
                  <TableCell>{t("shipments.totalVolume")}</TableCell>
                  <TableCell>{t("shipments.cargoValue")}</TableCell>
                  <TableCell>
                    <Tooltip title={t("shipments.stackability")}>
                      <AllInbox />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cargos.map((cargo) => (
                  <TableRow key={cargo.id}>
                    <TableCell>
                      <Typography>{cargo.description}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{cargo.quantity}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {t(`core:cargo.item.template.${cargo.template}`)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.length
                          ? `${cargo.length} ${cargo.lengthUnit.toLowerCase()}`
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.width
                          ? `${cargo.width} ${cargo.lengthUnit.toLowerCase()}`
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.height
                          ? `${cargo.height} ${cargo.lengthUnit.toLowerCase()}`
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.weight ? `${cargo.weight} kg` : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.volume ? `${cargo.volume} cbm` : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.totalWeight ? `${cargo.totalWeight} kg` : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.totalVolume ? `${cargo.totalVolume} cbm` : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.cargoValue ? `${cargo.cargoValue} CZK` : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {cargo.isStackable ? (
                          <Tooltip title={t("shipments.stackable")}>
                            <Check />
                          </Tooltip>
                        ) : null}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </section>
      <Divider className={classes.divider} />
      <section className={classes.gutterBottom}>
        {getBasicInfoContent()}
      </section>
    </div>
  );
};

export default BasicInfo;
