import React from "react";

import {
  Box, Link, makeStyles, Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(({ spacing }) => ({
  info: {
    background: "#DADADA",
    color: "#000",
    padding: spacing(3),
    marginBottom: 41,
    marginTop: 47,
    minHeight: 224
  },
  title: {
    fontSize: 21,
    marginBottom: 33
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 14
  },
  value: {
    paddingLeft: 30,
    fontSize: 12
  },
  specialValue: {
    color: "#009588"
  }
}));

const RelatedInfo = ({ incomingOrder }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { outcomingOrders, shipment, invoices } = incomingOrder;
  return (
    <Box className={classes.info}>
      <Typography className={classes.title}>{t("menu.related")}</Typography>

      {outcomingOrders && outcomingOrders.map((item) => (
        <>
          <Typography className={classes.subtitle}>
            {t("outcomingOrders.outcomingOrder")}
          </Typography>
          <Typography className={`${classes.value}`}>
            <Link component={RouterLink} to={`/outcoming-order/${item.id}`}>
              #
              {item.indexNumber}
            </Link>
          </Typography>
        </>
      ))}

      {shipment && shipment.map((item) => (
        <>
          <Typography className={classes.subtitle}>
            {t("shipments.shipment")}
          </Typography>
          <Typography className={`${classes.value}`}>
            <Link component={RouterLink} to={`/shipment/${item.id}`}>
              #
              {item.indexNumber}
            </Link>
          </Typography>
        </>
      ))}
      {invoices && (
        <>
          <Typography className={classes.subtitle}>{t("settings.invoices")}</Typography>
          <Typography className={classes.value}>{t("settings.received")}</Typography>
          <Typography className={classes.value}>{t("settings.issued")}</Typography>
        </>
      )}
    </Box>
  );
};

export default RelatedInfo;
