import React, { useState } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  Button,
  Typography,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { getNameInitials } from "../../../utility/common";
import { ImageAvatar } from "../../common";
import SettingsReauthenticate from "../UserProfile/ReauthenticateDialog";
import useAuth from "../../hook/useAuth";
import { reauthenticate } from "../../../auth";
import { deepPurple, red } from "@material-ui/core/colors";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  card: {
    padding: 4
  },
  purpleAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  cardActions: {
    [breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center",
      "& > :not(:last-child)": {
        marginBottom: spacing(1)
      }
    },
    [breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  flexbox: {
    [breakpoints.down("xs")]: {
      display: "grid",
    },
    [breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  leftFlex: {
    [breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center"
    },
    [breakpoints.up("sm")]: {
      flex: 1
    }
  },
  rightFlex: {
    flex: 3
  },
  warning: {
    color: red[500]
  },
}));

const CompanyCard = ({
  companyProfile,

  handleUpdateClick,
  handleCreateClick,
  handleChangeAvatarImage,
  handleConditionsClick,
  handleDeleteCompany,
  
  hasUpdateCompanyProfilePermission,
  hasReadCompanyTermsPermission,
  hasDeleteCompanyProfilePermission
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    user: { email }
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [reauthenticateDeleteAccountDialogOpen, setReauthenticateDeleteAccountDialogOpen] = useState(false);

  const handleDeleteCompanyClick = (email, password) => {
    reauthenticate(email, password)
      .then(() => {
        setReauthenticateDeleteAccountDialogOpen(false);
        handleDeleteCompany();
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(t("settings.error.reauthenticating"), {
          variant: "error"
        });
      });
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={t("settings.companyProfile")} />
        <CardContent>
          <div className={classes.flexbox}>
            <div className={classes.leftFlex}>
              <input
                id="company-profile-avatar-file-input"
                style={{ display: "none" }}
                type="file"
                accept="image/png,image/jpeg"
                onChange={async ({ target: { files } }) => {
                  if (files.length !== 1) {
                    return;
                  }

                  const file = files[0];

                  if (file.type !== "image/png" && file.type !== "image/jpeg") {
                    enqueueSnackbar(t("settings.changeAvatarFileTypeError"), {
                      variant: "error"
                    });
                    return;
                  }

                  handleChangeAvatarImage(file);
                }}
              />
              <Button
                disabled={!hasUpdateCompanyProfilePermission}
                size="medium"
                color="secondary"
                onClick={(event) => {
                  event.preventDefault();
                  document.querySelector("#company-profile-avatar-file-input").click();
                }}
              >
                <ImageAvatar
                  classes={{
                    root: classes.purpleAvatar
                  }}
                  source={companyProfile.avatarUrl}
                  text={getNameInitials(`${companyProfile.companyName}`).toUpperCase()}
                />
              </Button>
            </div>
            <div className={classes.rightFlex}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    {companyProfile.companyName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">IČ</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    {companyProfile.ic}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">DIČ</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    {companyProfile.dic}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">{t("contacts.address")}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    {companyProfile.address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">{companyProfile.url ? "URL" : null}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    {companyProfile.url}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">{companyProfile.email ? t("email") : null}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    {companyProfile.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">{companyProfile.phoneNumber ? t("phone") : null}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    {companyProfile.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            disabled={!hasUpdateCompanyProfilePermission}
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={
              companyProfile.ic == null
                ? handleCreateClick
                : handleUpdateClick
            }
          >
            {companyProfile.ic == null ? t("new") : t("update")}
          </Button>
          <Button
            disabled={!hasUpdateCompanyProfilePermission && !hasReadCompanyTermsPermission}
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={handleConditionsClick}
          >
            {t("settings.setConditions")}
          </Button>
          <Tooltip title={t("settings.deleteCompanyTooltip")}>
            <span>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!hasDeleteCompanyProfilePermission}
                size="medium"
                onClick={() => setReauthenticateDeleteAccountDialogOpen(true)}
                className={classes.warning}
              >
                {t("settings.deleteCompany")}
              </Button>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
      <SettingsReauthenticate
        open={reauthenticateDeleteAccountDialogOpen}
        handleClose={() => setReauthenticateDeleteAccountDialogOpen(false)}
        handleSubmit={handleDeleteCompanyClick}
        email={email}
      />
    </>
  );
};

export default CompanyCard;
