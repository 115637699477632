import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormik, Formik } from "formik";
import { union, remove } from "lodash";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  makeStyles,
  IconButton,
  Table,
} from "@material-ui/core";

import { DateTimePicker } from "@material-ui/pickers";

import { Close } from "@material-ui/icons";

import { mapAndFormatWaypoints } from "../../../../cargotic-webapp/utility/waypoint";

import FormButtons from "@cargotic/webapp-component/component/FormButtons";
import WaypointCargoPackagesSelect from "./WaypointCargoPackagesSelect";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(3, 1, 1, 1),
    height: "100%",
  },
  rightAlignment: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  tableRow: {
    width: "100%",
    display: "table",

    "& td:first-child": {
      paddingLeft: spacing(0),
    },
  },
  tableRowWide: {
    "& td:first-child": {
      paddingLeft: spacing(5),
      paddingRight: spacing(1),
    },
  },
}));

export function DriveThroughDialog({
  title,
  waypoint,
  incomingOrder,
  shipment,
  initialValues,
  showDateInput,

  submitButtonLabel,
  submitButtonProps,

  onClose,
  onSubmit,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const formattedWaypoint = useMemo(() => {
    return mapAndFormatWaypoints([waypoint], true, false)[0];
  }, [waypoint]);

  const handleSubmit = useCallback(
    (values) => onSubmit(waypoint, values),
    [onSubmit, waypoint]
  );

  return (
    <Dialog open onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.rightAlignment}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>

        <DialogTitle>
          {formattedWaypoint?.placeAlias || formattedWaypoint?.placeName}
        </DialogTitle>

        <Formik
          initialValues={{
            packagesIds: initialValues?.packagesIds,
            drivenThroughAt: initialValues?.drivenThroughAt ?? new Date(),
          }}
          validateOnChange
          validate={({ drivenThroughAt }) => {
            if (drivenThroughAt instanceof Date) {
              if (drivenThroughAt > new Date()) {
                return {
                  drivenThroughAt: t(
                    "shipments.validation.drivenThroughAtFuture"
                  ),
                };
              } else if (
                drivenThroughAt.getTime() !== drivenThroughAt.getTime()
              ) {
                return {
                  drivenThroughAt: t(
                    "shipments.validation.drivenThroughAtValid"
                  ),
                };
              }
            } else {
              return {
                drivenThroughAt: t("shipments.validation.drivenThroughAtValid"),
              };
            }

            return {};
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            setValues,
            setFieldValue,
            setErrors,
            setFieldError,
            handleBlur,
            handleChange,
            submitForm,
            resetForm,
            touched,
            errors,
            isSubmitting,
            ...restFuncs
          }) => {
            return (
              <>
                <DialogContent>
                  <form>
                    {showDateInput && (
                      <DateTimePicker
                        value={values.drivenThroughAt}
                        allowKeyboardControl
                        ampm={false}
                        fullWidth
                        disableMaskedInput
                        inputFormat="dd.MM.yyyy HH:mm"
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            label={t("shipments.driveThroughAt")}
                            error={
                              errors.drivenThroughAt && touched.drivenThroughAt
                            }
                            helperText={
                              touched.drivenThroughAt && errors.drivenThroughAt
                            }
                            name="drivenThroughAt"
                            onBlur={handleBlur}
                          />
                        )}
                        onChange={(date) => {
                          setFieldValue("drivenThroughAt", date);
                        }}
                      />
                    )}

                    <Table>
                      <WaypointCargoPackagesSelect
                        waypoint={waypoint}
                        onSelectCargoItemPackages={(
                          selected,
                          cargoItemPackages
                        ) => {
                          let newValues = [...values.packagesIds];

                          if (selected) {
                            newValues = union(newValues, cargoItemPackages);
                          } else {
                            remove(newValues, (n) =>
                              cargoItemPackages.includes(n)
                            );
                          }

                          setFieldValue("packagesIds", newValues);
                        }}
                        tableRowClassName={classes.tableRow}
                        tableRowWideClassName={classes.tableRowWide}
                        selectedCargoItemPackages={values?.packagesIds}
                      />
                    </Table>
                  </form>
                </DialogContent>

                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    {t("shipments.cancel")}
                  </Button>
                  <Button
                    onClick={submitForm}
                    color="primary"
                    disabled={
                      isSubmitting ||
                      (showDateInput && !values.packagesIds?.length)
                    }
                  >
                    {t("shipments.driveThrough")}
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </div>
    </Dialog>
  );
}

DriveThroughDialog.propTypes = {};

export default DriveThroughDialog;
