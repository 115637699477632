import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import getTrendColor from "./getTrendColor";
import getTrendIcon from "./getTrendIcon";
import getTrendPercentage from "./getTrendPercentage";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "inline-flex",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    }
  }
}));

const Trend = ({ rateOfChange, description, isInverted }) => {
  const classes = useStyles();
  const color = getTrendColor(rateOfChange, isInverted);
  const Icon = getTrendIcon(rateOfChange);
  const percentage = getTrendPercentage(rateOfChange);

  return (
    <div className={classes.root}>
      <Icon style={{ color }} />
      <Typography style={{ color }} variant="subtitle2" component="p">
        {percentage}
      </Typography>
      <Typography variant="subtitle2" component="p">
        {description}
      </Typography>
    </div>
  );
};

export default Trend;
