import React from "react";
import {
  Dashboard,
  Add as AddIcon,
  Ballot as BallotIcon,
  Group as GroupIcon,
  DirectionsCar,
  ListAlt as ListAltIcon,
  Settings as SettingsIcon,
  ShowChart as ShowChartIcon,
  BubbleChart as BubbleChartIcon,
  NotListedLocation as NotListedLocationIcon,
  Help as HelpIcon,
  EmojiPeople as EmojiPeopleIcon,
  Place,
  Contacts as ContactsIcon,
  Feedback as FeedbackIcon,
  EditLocation,
  Message,
  LocationSearching,
  ViewWeek,
  ViewDay,
  Edit,
  Receipt as InvoicesIcon
} from "@material-ui/icons";

import Users from "../Users";
import Contacts from "../Contacts";
import Vehicles from "../Vehicles";
import Shipments from "../Shipments";
import IncomingOrders from "../IncomingOrders";
import OutcomingOrders from "../OutcomingOrders";
import Overview from "../Overview";
import Statistics from "../Statistics";
import Settings from "../Settings";
import Places from "../Places";
import Invoices from "../Invoices";
import Tracking from "../Tracking";
import DashboardView from "./DashboardView";
import Feedback from "../Feedback";
import TariffExpiration from "../TariffExpiration/TariffExpiration";
import TariffExpirationDispatcher from "../TariffExpiration/TariffExpirationDispatcher";
import { ShipmentView as NewShipment } from "../../../shipment";
import { IncomingOrder as NewIncomingOrder } from "../../../incomingOrder/component";
import { OutcomingOrderView as NewOutcomingOrder } from "../../../outcomingOrder/component";
import ShipmentBoard from "../ShipmentBoard";
import { VehicleProfileContainer } from "../../../../cargotic-webapp-vehicle";
import useShipmentConcept from "../../hook/useShipmentConcept";
import { Invoice } from "../../../invoice";
import useIncomingOrderConcept from "../../hook/useIncomingOrderConcept";
import useOutcomingOrderConcept from "../../hook/useOutcomingOrderConcept";
import WarehouseContainer from "../WarehouseDetail/WarehouseContainer";

const getUserElements = (hasPermission) => {
  const canCreateIncomingOrders = hasPermission("resource.incomingOrder.user.create");
  const canReadIncomingOrders = hasPermission("resource.incomingOrder.user.read") || hasPermission("resource.incomingOrder.company.read");
  const canCreateOutcomingOrders = hasPermission("resource.outcomingOrder.user.create");
  const canReadOutcomingOrders = hasPermission("resource.outcomingOrder.user.read") || hasPermission("resource.outcomingOrder.company.read");
  const canCreateShipment = hasPermission("resource.shipment.user.create");
  const canReadShipments = hasPermission("resource.shipment.user.read") || hasPermission("resource.shipment.company.read");
  const canReadWarehouses = hasPermission("resource.warehouse.read");
  const canReadInvoices = hasPermission("resource.invoice.read");
  const canCreateInvoice = hasPermission("resource.invoice.create");
  const canReadTracks = hasPermission("resource.track.read");
  const canReadStatistics = hasPermission("resource.statistics.user.read") || hasPermission("resource.statistics.company.read");
  const canReadUsers = hasPermission("resource.user.read");
  const canReadVehicles = hasPermission("resource.vehicle.read");
  const canReadPlaces = hasPermission("resource.place.read");
  const canReadContacts = hasPermission("resource.contact.user.read");

  const { shipment: shipmentConcept } = useShipmentConcept();
  const { incomingOrder: incomingOrderConcept } = useIncomingOrderConcept();
  const { outcomingOrder: outcomingOrderConcept } = useOutcomingOrderConcept();

  return [
    {
      view: DashboardView.OVERVIEW,
      path: "/",
      title: "menu.overview",
      icon: <Dashboard />,
      component: Overview
    },
    {
      isSubheader: true,
      title: "menu.subheader.orders",
      icon: <></>
    },
    (canReadShipments
      ? {
        view: DashboardView.SHIPMENT_BOARD,
        path: "/board",
        title: "menu.board",
        icon: <ViewWeek />,
        component: ShipmentBoard
      }
      : undefined),
    (canCreateIncomingOrders
      ? {
        view: DashboardView.INCOMING_ORDER,
        path: "/incoming-order",
        title: "menu.incomingOrder",
        icon: <AddIcon />,
        showAction: incomingOrderConcept,
        action: <Edit style={{ width: 18, height: 18 }} color="action" />,
        component: NewIncomingOrder
      }
      : undefined),
    (canCreateOutcomingOrders
      ? {
        view: DashboardView.OUTCOMING_ORDER,
        title: "menu.outcomingOrder",
        icon: <AddIcon />,
        showAction: outcomingOrderConcept,
        action: <Edit style={{ width: 18, height: 18 }} color="action" />,
        component: NewOutcomingOrder
      }
      : undefined),
    (canReadIncomingOrders
      ? {
        view: DashboardView.INCOMING_ORDERS,
        path: "/incoming-orders",
        title: "menu.incomingOrderOverview",
        icon: <ViewDay />,
        component: IncomingOrders
      }
      : undefined),
    {
      isSubheader: true,
      title: "menu.subheader.storing",
      icon: <></>
    },
    (canReadWarehouses
        ? {
          view: DashboardView.WAREHOUSES,
          path: "/warehouses",
          title: "menu.warehouses",
          icon: <ViewWeek />,
          component: WarehouseContainer
        } : undefined),
    {
      isSubheader: true,
      title: "menu.subheader.shipments",
      icon: <></>
    },
    (canCreateShipment || canCreateOutcomingOrders
      ? {
        view: DashboardView.SHIPMENT,
        path: "/shipment",
        title: "menu.shipment",
        icon: <AddIcon />,
        showAction: shipmentConcept,
        action: <Edit style={{ width: 18, height: 18 }} color="action" />,
        component: NewShipment
      }
      : undefined),
    (canReadOutcomingOrders
      ? {
        view: DashboardView.OUTCOMING_ORDERS,
        path: "/shipments",
        title: "menu.shipmentOverview",
        icon: <ViewDay />,
        component: OutcomingOrders
      }
      : undefined),
    (canReadTracks
      ? {
        view: DashboardView.TRACKING,
        path: "/tracking",
        title: "menu.onlineTracking",
        icon: <LocationSearching />,
        component: Tracking
      }
      : undefined),
    {
      isSubheader: true,
      title: "menu.subheader.analysis",
      icon: <></>
    },
    (canReadStatistics
      ? {
        view: DashboardView.STATISTICS,
        path: "/statistics",
        title: "menu.statistics",
        icon: <BubbleChartIcon />,
        component: Statistics
      }
      : undefined),
    {
      isSubheader: true,
      title: "menu.subheader.overview",
      icon: <></>
    },
    (canReadUsers
      ? {
        view: DashboardView.USERS,
        path: "/users",
        title: "menu.users",
        icon: <GroupIcon />,
        component: Users
      }
      : undefined),
    (canReadVehicles
      ? {
        view: DashboardView.VEHICLES,
        path: "/vehicles",
        title: "menu.vehicles",
        icon: <DirectionsCar />,
        component: Vehicles
      }
      : undefined),
    (canReadVehicles
      ? {
        view: DashboardView.VEHICLE_OVERVIEW,
        component: VehicleProfileContainer
      }
      : undefined),
    (canReadContacts
      ? {
        view: DashboardView.CONTACTS,
        path: "/contacts",
        title: "menu.contacts",
        icon: <ContactsIcon />,
        component: Contacts
      }
      : undefined),
    (canReadInvoices
      ? {
        view: DashboardView.INVOICES,
        path: "/invoices",
        title: "menu.invoices",
        icon: <InvoicesIcon />,
        component: Invoices
      }
      : undefined),
    (canReadPlaces
      ? {
        view: DashboardView.PLACES,
        path: "/places",
        title: "menu.places",
        icon: <EditLocation />,
        component: Places
      }
      : undefined),
    (canCreateInvoice
      ? {
        view: DashboardView.INVOICE,
        title: "menu.invoice",
        icon: <AddIcon />,
        action: <Edit style={{ width: 18, height: 18 }} color="action" />,
        component: Invoice
      }
      : undefined),
    {
      isSubheader: true,
      title: "menu.subheader.communication",
      icon: <></>
    },
    {
      view: DashboardView.SETTINGS,
      path: "/settings",
      title: "menu.settings",
      icon: <SettingsIcon />,
      component: Settings
    }, {
      view: DashboardView.FEEDBACK,
      path: "/feedback",
      title: "menu.feedback",
      icon: <FeedbackIcon />,
      component: Feedback
    }
  ]
    .filter(element => element);
};

export default getUserElements;
