import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import {
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  InsertEmoticon as InsertEmoticonIcon
} from "@material-ui/icons";

import { getDateFnsLocale } from "../../../cargotic-webapp-utility";
import UserAvatar from "../UserAvatar";
import UserLink from "../UserLink";
import ActivityTimelineCommentaryItemDeleteConfirmationAlert
  from "./ActivityTimelineCommentaryItemDeleteConfirmationAlert";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",

    padding: spacing(2),

    "& > section:last-child": {
      flex: 1,

      marginLeft: spacing(2),

      "& > div": {
        "&:first-child": {
          display: "flex",

          "& > :not(:first-child)": {
            marginLeft: spacing(1)
          }
        },

        "&:not(:first-child)": {
          marginTop: spacing(1)
        },

        "&:last-child": {
          display: "flex",
          justifyContent: "flex-end",

          "& > :not(:first-child)": {
            marginLeft: spacing(1)
          }
        }
      }
    }
  },
  content: {
    whiteSpace: "pre-wrap",
    wordWrap: "anywhere"
  }
}));

const ActivityTimelineCommentaryItem = ({
  commentary: {
    author,
    content,
    createdAt
  },
  isControlDisabled,
  isDisabled,
  onChange,
  onDelete
}) => {
  const { t, i18n: { language } } = useTranslation();
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [editedContent, setEditedContent] = useState(content);
  const dateFnsLocale = getDateFnsLocale(language);

  const handleCancelButtonClick = () => {
    setIsEditing(false);
    setEditedContent(content);
  };

  const handleDeleteAlertClose = () => (
    setIsDeleteAlertOpen(false)
  );

  const handleDeleteAlertSubmit = () => {
    setIsDeleteAlertOpen(false);

    if (onDelete) {
      onDelete();
    }
  };

  const handleDeleteButtonClick = () => (
    setIsDeleteAlertOpen(true)
  );

  const handleEditButtonClick = () => (
    setIsEditing(true)
  );

  const handleEditedContentChange = ({ target: { value } }) => (
    setEditedContent(value)
  );

  const handleSubmitButtonClick = () => {
    if (editedContent.trim() === "") {
      return;
    }

    setIsEditing(false);
    setEditedContent(content);

    if (editedContent === content) {
      return;
    }

    if (onChange) {
      onChange(editedContent);
    }
  };

  useEffect(() => {
    setEditedContent(content);
  }, [content]);

  return (
    <div>
      <Paper className={classes.root}>
        <section>
          <UserAvatar user={author} />
        </section>
        <section>
          <div>
            <UserLink user={author} />
            <Typography>
              {
                formatDistanceToNow(
                  createdAt,
                  { addSuffix: true, locale: dateFnsLocale }
                )
              }
            </Typography>
          </div>
          <div>
            {
              isEditing
                ? (
                  <TextField
                    variant="outlined"
                    value={editedContent}
                    onChange={handleEditedContentChange}
                    autoFocus
                    multiline
                    rows={3}
                    fullWidth
                  />
                ) : (
                  <Typography className={classes.content}>
                    {content}
                  </Typography>
                )
            }
          </div>
          <div>
            {
              isEditing
                ? (
                  <>
                    <Tooltip
                      key={0}
                      title={t("webapp:social.title.insertEmoji")}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <span>
                        <IconButton size="small" disabled>
                          <InsertEmoticonIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      key={1}
                      title={t("webapp:social.title.attachFile")}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <span>
                        <IconButton size="small" disabled>
                          <AttachFileIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Button color="primary" onClick={handleCancelButtonClick}>
                      {t("webapp:common.title.cancel")}
                    </Button>
                    <Button color="primary" onClick={handleSubmitButtonClick}>
                      {t("webapp:common.title.submit")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Tooltip
                      key={2}
                      title={t("webapp:social.title.editCommentary")}
                      disableFocusListener={isControlDisabled}
                      disableHoverListener={isControlDisabled}
                      disableTouchListener={isControlDisabled}
                    >
                      <span>
                        <IconButton
                          size="small"
                          disabled={isControlDisabled}
                          onClick={handleEditButtonClick}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      key={3}
                      title={t("webapp:social.title.deleteCommentary")}
                      disableFocusListener={isControlDisabled}
                      disableHoverListener={isControlDisabled}
                      disableTouchListener={isControlDisabled}
                    >
                      <span>
                        <IconButton
                          size="small"
                          disabled={isControlDisabled}
                          onClick={handleDeleteButtonClick}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )
            }
          </div>
        </section>
      </Paper>
      <ActivityTimelineCommentaryItemDeleteConfirmationAlert
        isOpen={isDeleteAlertOpen}
        onClose={handleDeleteAlertClose}
        onSubmit={handleDeleteAlertSubmit}
      />
    </div>
  );
};

export default ActivityTimelineCommentaryItem;
