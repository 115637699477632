import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  makeStyles
} from "@material-ui/core";
import { Close, Facebook, LinkedIn } from "@material-ui/icons";

import { formatDate } from "../../../utility/common";
import medium from "../../../asset/image/medium.svg";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: 0,
    padding: spacing(2)
  },
  actions: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex"
  },
  closeButton: {
    position: "absolute",
    right: spacing(1),
    top: spacing(1),
    color: palette.grey[500]
  },
  textContent: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    "& > ul": {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    },
    "& > p": {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    }
  },
  content: {
    padding: 0
  },
  socialIcon: {
    width: 25,
    height: 25,
    color: "rgba(0, 0, 0, 0.54)"
  }
}));

const NewsDialog = ({ content, onClose, image, date }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const imgStyles = makeStyles(({ breakpoints }) => ({
    image: {
      [breakpoints.only("xs")]: {
        height: 150
      },
      [breakpoints.up("sm")]: {
        height: 290
      },
      backgroundImage: `url(${image})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      padding: 0
    }
  }));

  const imgClasses = imgStyles();
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const obj = {
    __html: content
  };

  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography id="news-title">
          <Typography variant="h6">{t("newsDialog.title")}</Typography>
          <Typography variant="subtitle1">{formatDate(new Date(date))}</Typography>
          {handleClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <Close />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          {image ? <div className={imgClasses.image} /> : undefined}
          <div dangerouslySetInnerHTML={obj} className={classes.textContent} />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <div style={{ flex: 3 }}>
            <a href="https://facebook.com/cargotic" target="_blank"><Facebook className={classes.socialIcon} /></a>
            <a href="https://linked.in/company/cargotic" target="_blank"><LinkedIn className={classes.socialIcon} /></a>
            <a href="https://medium.com/@cargotic" target="_blank"><img src={medium} alt="Medium" className={classes.socialIcon} /></a>
          </div>
          <div>
            <Button onClick={handleClose} color="primary">OK</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewsDialog;
