import React, { useState } from "react";
import {
  Chip,
  Fab,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Tooltip,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Add,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ControlPoint,
  FileCopyOutlined,
  GetApp,
  Search,
  DeleteOutline,
  EmojiObjectsOutlined,
  Close,
  Edit,
  Payment,
  Email,
  PictureAsPdf,
  Print,
  AllInbox,
  IosShareOutlined
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { ShipmentType, ContactType } from "@cargotic/model";

import { addDays } from "date-fns";
import config from "../../../config";

import DataTable from "../../../datatable";
import ExportDialog from "./ExportDialog";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import SearchTextfield from "../../common/SearchTextfield";

import PayDialog from "../../../incomingOrder/component/PayDialog";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterTextDropdown from "../../../../cargotic-webapp-filter/component/FilterTextDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import VehiclesFilterDropdown from "../../../../cargotic-webapp-filter/component/VehiclesFilterDropdown";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import PlaceFilterDropdown from "../../../../cargotic-webapp-filter/component/PlaceFilterDropdown";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";
import { readCompanyOutcomingOrderHighlights } from "cargotic-webapp/resource";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    },
  },
  frame: {
    padding: 0
  }
}));

const Shipments = ({
  data,
  dataCount,
  selectedColumns,
  shipments,
  setShipments,
  onSelectAllOnPage,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  isShipmentsExportDialogOpen,
  setShipmentBoxOpen,
  isShipmentsExportProcessing,
  isShipmentsDeleteProcessing,
  isLabelsDownloadingLoading,
  isShipmentsExportDone,
  shipmentsExportLink,
  closeShipmentsExportDialog,
  exportShipments,
  exportShipmentsXlsx,
  exportCsvWithPackages,
  exportXlm,
  setBox,
  setCurrentBoxId,
  canCreateShipment,
  canUpdateShipment,
  canGeneratePdfShipment,
  canDeleteShipment,
  canReadCompanyShipment,
  additionalFilter: {
    type: additionalFilterType,
    lastWaypointDateFrom: additionalFilterLastDateFrom,
  },
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectAll,
  handleSelect,
  handleSelectLastMonth,
  handleDeleteRequest,
  handleCopyOutcomingOrder,
  filter,
  handleSelectShipmentState,
  handleSelectPlaces,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectCreators,
  handleSelectDrivers,
  loadAvailableUsers,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleSelectVehicles,
  loadAvailableVehicles,
  handleChangeSelectedColumns,
  loadAvailableStates,
  handleLoadAvailablePlaces,

  handleShipmentsImportRequest,
  handleShipmentsExportRequest,
  handleDeleteShipmentRequest,
  handlePrintShipmentsRequest
}) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const classes = useStyles();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;
  const selectedItems = data.filter(({ selected }) => selected);

  const shipmentsHeader = [
    {
      name: "indexNumber",
      label: "shipments.shipmentNumber",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "vehicle",
      label: "shipments.vehicle",
      isOrderable: false,
      width: "10%",
    },
    {
      name: "driver",
      label: "shipments.driver",
      isOrderable: false,
      width: "10%",
    },
    {
      name: "pickupAtStart",
      label: "shipments.date",
      isOrderable: true,
      width: "200px",
    },
    {
      name: "route",
      label: "shipments.route",
      isOrderable: false,
      width: "200px",
    },
    {
      name: "incomingOrders",
      label: "shipments.incomingOrderNumbers",
      isOrderable: false,
      width: "200px"
    },
    {
      name: "incomingOrderBox",
      label: "shipments.incomingOrderBox",
    },
    {
      name: "state",
      label: "board.state.title",
      width: "6%",
    },
    {
      name: "externalReference",
      label: "incomingOrders.externalReference"
    },
    {
      name: "createdBy",
      label: "incomingOrders.createdBy",
      width: "50px",
      isOrderable: false
    },
    {
      name: "incomingOrdersExternalReferences",
      label: "shipments.incomingOrdersExternalReferences",
      isOrderable: false,
      width: "100px"
    }
  ];

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => {
            setCurrentBoxId(id);
            setShipmentBoxOpen(true);
          },
          icon: <AllInbox />,
          title: "shipments.box.set",
          disableFor: !canUpdateShipment ? [-1] : undefined,
        },
      ],
    },
    {
      column: [
        {
          handleClick: (id) => history.push(`/shipment/${id}/copy`),
          icon: <FileCopyOutlined />,
          title: "copy",
          disableFor: !canCreateShipment ? [-1] : undefined,
        },
      ],
    },
    {
      column: [
        {
          handleClick: (id) => history.push(`/shipment/${id}`),
          icon: <Edit />,
          title: "outcomingOrders.edit",
          disableFor: !canUpdateShipment ? [-1] : undefined,
        },
      ],
    },
    {
      column: [
        {
          handleClick: handleCopyOutcomingOrder,
          icon: <IosShareOutlined />,
          title: "outcomingOrders.copy"
        }
      ]
    }
  ];

  const draftSource = [
    { title: t("shipments.draft"), value: "DRAFT" },
    { title: t("shipments.completed"), value: "COMPLETED" },
  ];

  const setDraft = (value) => {
    if (value.includes("DRAFT") && value.includes("COMPLETED")) {
      handleSelectIsDraft(null);
    } else if (value.includes("COMPLETED")) {
      handleSelectIsDraft(false);
    } else if (value.includes("DRAFT")) {
      handleSelectIsDraft(true);
    } else {
      handleSelectIsDraft(undefined);
    }
  };

  const isAnyFilterActive = () => {
    const {
      drivers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      vehicles,
      isDraft,
    } = filter;

    return [
      drivers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      vehicles,
      isDraft,
    ].some((item) => item !== undefined);
  };

  return (
    <Page
      className={classes.frame}
      rootStyle={{ padding: 0 }}
      actions={
        <>
          <Tooltip title={t("newShipment")}>
            <span>
              <Fab
                color="primary"
                disabled={loading || !canCreateShipment}
                onClick={() => history.push("/shipment")}
              >
                <Add />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("outcomingOrders.import.action")}>
            <span>
              <Fab
                color="primary"
                disabled={loading}
                onClick={handleShipmentsImportRequest}
              >
                <CloudUploadOutlined />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("outcomingOrders.export.action")}>
            <span>
              <Fab
                className={classes.danger}
                disabled={loading || selected === 0}
                onClick={handleShipmentsExportRequest}
              >
                <CloudDownloadOutlined />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("shipments.printPDF")}>
            <span>
              <Fab
                color="primary"
                disabled={loading}
                onClick={() => handlePrintShipmentsRequest(selectedItems)}
              >
                <PictureAsPdf />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("outcomingOrders.removeShipment")}>
            <span>
              <Fab
                className={classes.danger}
                disabled={loading || selected === 0 || !canDeleteShipment}
                onClick={handleDeleteShipmentRequest}
                data-cy="remove-shipment"
              >
                <DeleteOutline />
              </Fab>
            </span>
          </Tooltip>
        </>
      }>
      <FilterContainer
        searchField={
          <SearchTextfield
            handleSearch={handleSearch}
            value={search}
            fullWidth
            placeholder={t("shipments.searchTooltip")}
          />
        }
        showClearButton={isAnyFilterActive}
        onClear={handleDeselect}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={[
          <FilterDateRangeDropdown
            id="loadingDate"
            key="loadingDate"
            onChange={(value) => handleSelectLoadingsDateRange(value)}
            value={
              filter.loadingDate
                ? [filter.loadingDate.from, filter.loadingDate.to]
                : []
            }
            onClear={() => handleSelectLoadingsDateRange(undefined)}
            placeholderTitle={t("shipments.loadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="unloadingDate"
            key="unloadingDate"
            onChange={(value) => handleSelectUnloadingsDateRange(value)}
            value={
              filter.unloadingDate
                ? [filter.unloadingDate.from, filter.unloadingDate.to]
                : []
            }
            onClear={() => handleSelectUnloadingsDateRange(undefined)}
            placeholderTitle={t("shipments.unloadingsDateRange")}
          />,
          <UserFilterDropdown
            id="creators"
            key="creators"
            onChange={(value) =>
              handleSelectCreators(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectCreators(all)}
            value={filter.creators ?? []}
            placeholderTitle={t("shipments.creator")}
            search={(text) => loadAvailableUsers(text, "resource.shipment.user.create")}
            onClear={() => handleSelectCreators(undefined)}
            getTitle={(item) => item.name}
            disabled={!canReadCompanyShipment}
          />,
          <UserFilterDropdown
            id="drivers"
            key="drivers"
            onChange={(value) =>
              handleSelectDrivers(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectDrivers(all)}
            value={filter.drivers ?? []}
            placeholderTitle={t("shipments.driver")}
            search={(text) => loadAvailableUsers(text, "resource.user.role.permission.driver")}
            onClear={() => handleSelectDrivers(undefined)}
            getTitle={(item) => item.name}
          />,
          <FilterDateRangeDropdown
            id="createdAt"
            key="createdAt"
            onChange={(value) => handleSelectCreatedAtDateRange(value)}
            value={
              filter.createdAt
                ? [filter.createdAt.from, filter.createdAt.to]
                : []
            }
            onClear={() => handleSelectCreatedAtDateRange(undefined)}
            placeholderTitle={t("shipments.creationDate")}
          />,
          <FilterStaticDropdown
            id="cargo"
            key="cargo"
            placeholderTitle={t("shipments.cargo")}
            value={filter.cargo ?? []}
            onChange={(arr) =>
              handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
            }
            selectAll={() =>
              handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
            }
            onClear={() => handleSelectCargoTemplate(undefined)}
            source={AvailableCargoItemTemplates.map(({ label, value }) => ({ title: t(label), value }))}
          />,
          <FilterStaticDropdown
            id="isDraft"
            key="isDraft"
            placeholderTitle={t("shipments.draft")}
            value={
              filter.isDraft === undefined
                ? []
                : filter.isDraft === null
                  ? ["DRAFT", "COMPLETED"]
                  : filter.isDraft === true
                    ? ["DRAFT"]
                    : ["COMPLETED"]
            }
            onChange={(value) => setDraft(value)}
            selectAll={() => setDraft(draftSource.map((item) => item.value))}
            onClear={() => handleSelectIsDraft(undefined)}
            source={draftSource}
          />,
          <VehiclesFilterDropdown
            id="vehicles"
            key="vehicles"
            onChange={(value) =>
              handleSelectVehicles(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectVehicles(all)}
            value={filter.vehicles ?? []}
            placeholderTitle={t("shipments.vehicles")}
            search={(text) => loadAvailableVehicles(text)}
            onClear={() => handleSelectVehicles(undefined)}
          />,
          <StateFilterDropdown
            id="state"
            key="state"
            placeholderTitle={t("board.state.title")}
            value={filter.state ?? []}
            onChange={(arr) =>
              handleSelectShipmentState(arr.length === 0 ? undefined : arr)
            }
            selectAll={(all) =>
              handleSelectShipmentState(all)
            }
            onClear={() => handleSelectShipmentState(undefined)}
            search={(text) => loadAvailableStates(text)}
            getTitle={(item) => item.name}
          />,
          <PlaceFilterDropdown
            id="places"
            key="places"
            placeholderTitle={t("shipments.places")}
            selectAll={(all) => handleSelectPlaces(all)}
            search={(text) => handleLoadAvailablePlaces(text)}
            value={filter.places ?? []}
            onChange={(value) => handleSelectPlaces(value.length === 0 ? undefined : value)}
            onClear={() => handleSelectPlaces(undefined)}
          />
        ]}
      />
      <DataTable
        headers={shipmentsHeader}
        actions={tableActions}
        selectedColumns={selectedColumns}
        onSelectAllOnPage={onSelectAllOnPage}
        data={data}
        dataCount={dataCount}
        loading={loading || isShipmentsDeleteProcessing || isLabelsDownloadingLoading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        enableDetail
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        onChangeSelectedColumns={handleChangeSelectedColumns}
      />
      <ExportDialog
        infoText={t("shipments.export.info.text")}
        title={t("shipments.export.title")}
        closeButtonLabel={t("orders.export.close")}
        startButtonLabel={t("orders.export.start")}
        processingText={t("orders.export.processing.text")}
        isOpen={isShipmentsExportDialogOpen}
        isProcessing={isShipmentsExportProcessing}
        close={closeShipmentsExportDialog}
        exportCsv={exportShipments}
        exportXlsx={exportShipmentsXlsx}
        exportCsvWithPackages={exportCsvWithPackages}
        exportXlm={exportXlm}
      />
    </Page>
  );
};

export default Shipments;
