import React, { useEffect } from "react";

import {
  Button,
  MenuItem,
  makeStyles,
  TextField
} from "@material-ui/core";
import { Currency } from "../../../../../cargotic-currency/currency";
import { useFormik } from "formik2";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import useAuth from "../../../hook/useAuth";

import {
  DrawerDialog,
  FormikCheckbox,
  FormikSelect,
  FormikTextField,
  FormikDatePicker,
  CommaNumberFormat,
  FormikTextFieldNumber
} from "../../../../../cargotic-webapp-component";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
  root: {
    "p + p": {
      marginTop: "15px"
    }
  }
}));


const ExchangeRateEditor = ({
  initialValue = {},
  rate,
  isOpen,
  onSubmit,
  onClose
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user: { role, id: userId } } = useAuth();

  const { id } = initialValue;

  const initialDate = initialValue.createdAt || new Date();
  const initialSourceCurrency = initialValue.sourceCurrency || "EUR";
  const initialTargetCurrency= initialValue.targetCurrency || "CZK";
  const initialRate = initialValue.rate || "";


  const ExchangeRateSchema = Yup.object({
    date: Yup.date(t("webapp:common.validate.date"))
      .required(t("webapp:common.validate.date")),
    sourceCurrency: Yup.string()
      .max(5, t("webapp:common.validate.currency"))
      .required(t("webapp:common.validate.currency")),
    targetCurrency: Yup.string()
      .max(10, t("webapp:common.validate.currency"))
      .required(t("webapp:common.validate.currency")),
    rate: Yup.number(t("webapp:settings.error.rate")).required(t("webapp:settings.error.rate"))
  });

  const exchangeRateForm = useFormik({
    initialValues: {
      date: initialDate,
      sourceCurrency: initialSourceCurrency,
      targetCurrency: initialTargetCurrency,
      rate: initialRate
    },
    validationSchema: ExchangeRateSchema,
    onSubmit: values => {
      const date = values.date || undefined;
      const sourceCurrency = values.sourceCurrency || undefined;
      const targetCurrency = values.targetCurrency || undefined;
      const rate = values.rate || undefined;

      const exchangeRate = {
        id,
        date,
        sourceCurrency,
        targetCurrency,
        rate
      };

      if (onSubmit) {
        onSubmit(exchangeRate);
      }
    }
  });

  useEffect(() => {
    exchangeRateForm.resetForm({
      values: {
        date: initialDate,
        sourceCurrency: initialSourceCurrency,
        targetCurrency: initialTargetCurrency,
        rate: initialRate
      },
      errors: {}
    });
  }, [isOpen]);

  const handleSave = () => {
    try {
      exchangeRateForm.submitForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DrawerDialog
      title={t("webapp:settings.title.exchange-rate")}
      actions={(
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose}>
            {t("abort")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t("save")}
          </Button>
        </div>
      )}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        <div>
          <FormikDatePicker
            required
            name="date"
            fullWidth
            form={exchangeRateForm}
            label={t("webapp:common.title.date")}
          />
        </div>
        <div>
          <FormikSelect
            required
            name="sourceCurrency"
            fullWidth
            form={exchangeRateForm}
            label={t("webapp:settings.title.source-currency")}
          >
            <MenuItem value={Currency.CZK}>CZK</MenuItem>
            <MenuItem value={Currency.EUR}>EUR</MenuItem>
          </FormikSelect>
        </div>
        <div>
          <FormikSelect
            required
            name="targetCurrency"
            fullWidth
            form={exchangeRateForm}
            label={t("webapp:settings.title.target-currency")}
          >
            <MenuItem value={Currency.CZK}>CZK</MenuItem>
            <MenuItem value={Currency.EUR}>EUR</MenuItem>
          </FormikSelect>
        </div>
        <div>
          <FormikTextField
            required
            form={exchangeRateForm}
            name="rate"
            type="number"
            label={t("webapp:settings.title.rate")}
            fullWidth
          />
        </div>
      </div>
    </DrawerDialog >
  );
};

export default ExchangeRateEditor;
