import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    [breakpoints.down("xs")]: {
      width: "90%"
    },
    [breakpoints.up("sm")]: {
      minWidth: 280
    }
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column"
  }
 }));

const GpsCoordinatesDialog = ({
  isOpen,
  search,
  onClose,
  onSearchFail,
  onValueChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);

  const handleLatitudeChange = ({ target: { value } }) =>
    setLatitude(value);

  const handleLongitudeChange = ({ target: { value } }) =>
    setLongitude(value);

  const handleSearch = async () => {
    const result = await search(latitude, longitude);

    if (!result) {
      if (onSearchFail !== undefined) {
        onSearchFail();
      }
      else {
        enqueueSnackbar(t("webapp:journey.gps.error.search"), {
          variant: "error"
        });
      }
      return;
    }
    
    onValueChange(result);
    onClose();
  }

  return (
    <Dialog 
      classes={{
        paper: classes.paper
      }}
      open={isOpen} 
      onClose={onClose} 
    >
      <DialogTitle>
        {t("webapp:journey.gps.dialog.title")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          label={t("webapp:journey.gps.dialog.latitude")}
          type="number"
          value={latitude}
          onChange={handleLatitudeChange}
          fullWidth
        />
        <TextField
          label={t("webapp:journey.gps.dialog.longitude")}
          type="number"
          value={longitude}
          onChange={handleLongitudeChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("abort")}</Button>
        <Button autoFocus onClick={handleSearch} color="primary">
          {t("webapp:journey.gps.dialog.search")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GpsCoordinatesDialog;