import React from "react";

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, makeStyles
} from "@material-ui/core";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { SignInSchema } from "../../../utility/validationSchema";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  error: {
    color: "red"
  }
}));

const ReauthenticateDialog = ({
  open,
  handleClose,
  handleSubmit,
  email
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("settings.verifyIdentity")}</DialogTitle>
      <Formik
        validationSchema={SignInSchema}
        initialValues={{ email, password: "" }}
        onSubmit={(values, { setSubmitting }) => {
          const { password } = values;

          setSubmitting(false);
          handleSubmit(email, password);
        }}
      >
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          submitForm,
          errors,
          touched,
          values
        }) => (
            <>
              <DialogContent>
                <form className={classes.container}>
                  <div>
                    <TextField
                      name="email"
                      label={t("email")}
                      value={values.email}
                      error={errors.email && touched.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    {errors.email && touched.email && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.email)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <TextField
                      name="password"
                      label={t("password")}
                      type="password"
                      error={errors.password && touched.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    {errors.password && touched.password && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.password)}
                      </Typography>
                    )}
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("abort")}
                </Button>
                <Button onClick={submitForm} disabled={isSubmitting} color="primary">
                  {t("continue")}
                </Button>
              </DialogActions>
            </>
          )}
      </Formik>
    </Dialog>
  );
};

export default ReauthenticateDialog;
