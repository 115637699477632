import { ValueError } from "./error";
import { Optional } from "./type";

class NumberFormatError extends ValueError {

}

class NumberRangeError extends ValueError {

}

function deserializeFloat(value: string, message?: string): number {
  if (!/^[+-]?\d+(\.\d+)?$/.test(value)) {
    throw new NumberFormatError(message);
  }

  return parseFloat(value);
}

function deserializeInteger(
  value: string,
  base: number = 10,
  message?: string
): number {
  if (!/^[0-9]+$/.test(value)) {
    throw new NumberFormatError(message);
  }

  return parseInt(value, base);
}

function isBoundlessNumber(value: Optional<number>): boolean {
  return value === undefined || value === Infinity;
}

export {
  NumberFormatError,
  NumberRangeError,

  deserializeFloat,
  deserializeInteger,
  isBoundlessNumber
};
