import React from "react";

import Autosuggest from "react-autosuggest";
import deburr from "lodash/deburr";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  Paper, TextField, MenuItem, makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing(2)
  }
}));

const AutocompleteTextField = ({
  name,
  placeholder,
  label,
  sourceMethod,
  value,
  onChange,
  onBlur,
  suggestions,
  onSuggestionsChange,
  onSuggestionSelect,
  error,
  helperText,
  shownAttribute,
  required
}) => {
  const classes = useStyles();

  const renderInputComponent = (inputProps) => {
    const {
      classes, inputRef = () => {}, ref, ...other
    } = inputProps;

    return (
      <TextField
        fullWidth
        error={Boolean(error)}
        required={required}
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input
          }
        }}
        {...other}
      />
    );
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion[shownAttribute], query);
    const parts = parse(suggestion[shownAttribute], matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(part => (
            <span
              key={part.text}
              style={{ fontWeight: part.highlight ? 500 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  const getSuggestions = (val) => {
    const inputValue = deburr(val.trim()).toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : sourceMethod(val).then(r => r || []);
  };

  const getSuggestionValue = suggestion => suggestion[shownAttribute];

  const handleSuggestionsFetchRequested = async ({ val }) => {
    const suggestions = await getSuggestions(value);
    onSuggestionsChange(suggestions);
  };

  const handleSuggestionsClearRequested = () => {
    onSuggestionsChange([]);
  };

  const handleChange = (event, { newValue }) => {
    onChange(newValue);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected: (event, { suggestion }) => onSuggestionSelect(suggestion)
  };

  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        name,
        label,
        placeholder,
        value,
        required,
        onChange: handleChange,
        onBlur,
        error,
        helperText
      }}
      highlightFirstSuggestion
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion
      }}
      renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
    />
  );
};

export default AutocompleteTextField;
