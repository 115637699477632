import React from "react";
import { Route } from "react-router-dom";
import Placeholder from "../../common/Placeholder";

const TrackRoute = ({
  exact,
  path,
  component: Component
}) => (
  <Route
    exact={exact}
    path={path}
    component={() =>
      <Placeholder
        loading={false}
        render={() => {
          return (
            <Component />
          );
        }}
      />}
  />
);

export default TrackRoute;
