import React from "react";
import { useTranslation } from "react-i18next";

import ConfirmationAlert from "../ConfirmationAlert";

const ActivityTimelineCommentaryItemDeleteConfirmationAlert = ({
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationAlert
      title={t("webapp:social.title.deleteCommentary")}
      content={t("webapp:social.prompt.deleteCommentary")}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default ActivityTimelineCommentaryItemDeleteConfirmationAlert;
