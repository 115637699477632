import React, { useEffect } from "react";

import {
  Button,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { useFormik } from "formik2";

import * as Yup from "yup";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import useAuth from "../../hook/useAuth";

import {
  DrawerDialog,
  FormikSelect,
  FormikTextField,
  useApiClient
} from "../../../../cargotic-webapp-component";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
  root: {
    "p + p": {
      marginTop: "15px"
    }
  }
}));


const UserEditor = ({
  initialValue = {},
  user,
  availableRoles,
  isOpen,
  onSubmit,
  onClose
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user: { role, id: userId }, hasPermission } = useAuth();

  const hasUpdateRolePermission = hasPermission("resource.user.role.update");

  const { id } = initialValue;
  const initialFirstName = initialValue.firstName || "";
  const initialLastName = initialValue.lastName || "";
  const initialEmail = initialValue.email || "";
  const initialPhoneNumber = initialValue.phoneNumber || "";
  const initialRoleId = initialValue.roleId || "";
  const initialRoleName = initialValue.roleName || "";
  const initialUserSequence = initialValue.userSequence || "";


  const UserFormSchema = Yup.object({
    firstName: Yup.string()
      .max(128, t("webapp:common.validate.first-name"))
      .required(t("webapp:common.validate.first-name")),
    lastName: Yup.string()
      .max(128, t("webapp:common.validate.last-name"))
      .required(t("webapp:common.validate.last-name")),
    email: Yup.string()
      .max(128, t("webapp:common.validate.email"))
      .email(t("webapp:common.validate.email")),
    phoneNumber: Yup.string()
      .max(15, t("webapp:common.validate.phone-number")),
    role: Yup.string()
      .max(128, t("webapp:common.validate.role")),
    userSequence: Yup.number()
  });


  const userForm = useFormik({
    initialValues: {
      firstName: initialFirstName,
      lastName: initialLastName,
      email: initialEmail,
      phoneNumber: initialPhoneNumber,
      role: initialRoleId,
      userSequence: initialUserSequence
    },
    validationSchema: UserFormSchema,
    onSubmit: values => {
      const email = values.email || undefined;
      const phoneNumber = values.phoneNumber || undefined;
      const firstName = values.firstName || undefined;
      const lastName = values.lastName || undefined;
      const roleId = values.role || undefined;
      const userSequence = values.userSequence || undefined;

      const user = {
        id,
        email,
        firstName,
        lastName,
        phoneNumber,
        roleId,
        userSequence
      };

      if (onSubmit) {
        onSubmit(user);
      }
    }
  });

  useEffect(() => {
    userForm.resetForm({
      values: {
        email: initialEmail,
        firstName: initialFirstName,
        lastName: initialLastName,
        phoneNumber: initialPhoneNumber,
        role: initialRoleId,
        userSequence: initialUserSequence
      },
      errors: {}
    });
  }, [isOpen]);

  const createRolesMenuItems = () =>
    availableRoles.map(({ id, name }) => (
      <MenuItem value={id} key={id}>
        {name}
      </MenuItem>
    ));

  const handleSave = () => {
    try {
      userForm.submitForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DrawerDialog
      title={t("webapp:common.title.user")}
      actions={(
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose}>
            {t("abort")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t("save")}
          </Button>
        </div>
      )}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        <div>
          <FormikTextField
            form={userForm}
            required
            name="firstName"
            label={t("webapp:common.title.first-name")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            required
            name="lastName"
            form={userForm}
            label={t("webapp:common.title.last-name")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            required
            disabled
            name="email"
            form={userForm}
            label={t("webapp:common.title.email")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            name="phoneNumber"
            form={userForm}
            label={t("webapp:common.title.phone")}
            fullWidth
          />
        </div>
        <div>
          <FormikSelect
            required
            disabled={userId === id || !hasUpdateRolePermission}
            name="role"
            fullWidth
            form={userForm}
            label={t("webapp:common.title.role")}
          >
            {createRolesMenuItems()}
          </FormikSelect>
        </div>
        <div>
          <FormikTextField
            name="userSequence"
            form={userForm}
            type="number"
            label={t("webapp:common.title.userSequence")}
            fullWidth
          />
        </div>
      </div>
    </DrawerDialog >
  );
};

export default UserEditor;
