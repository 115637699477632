const JourneyPlannerState = ({
  cargoItemSeed,
  cargoItemLinkSeed,
  waypointSeed,
  waypoints,
  distance,
  duration,
  errors,
  selectedCargoItemId,
  selectedWaypointIndex,
  isPlaceSearchFailAlertOpen
}) => ({
  cargoItemSeed,
  cargoItemLinkSeed,
  waypointSeed,
  waypoints,
  distance,
  duration,
  errors,
  selectedCargoItemId,
  selectedWaypointIndex,
  isPlaceSearchFailAlertOpen
});

export default JourneyPlannerState;
