import React from "react";
import { Switch } from "react-router-dom";

import { AuthPolicy } from "../../../auth";
import TrackRoute from "../TrackRoute";
import Track from "../Track/Track";

const TrackingAppRouter = () => {

  return (

  <Switch>
    <TrackRoute
      exact
      path="/"
      policy={AuthPolicy.ANY}
      component={() => (
        <Track />
      )}
    />
  </Switch>
) };

export default TrackingAppRouter;
