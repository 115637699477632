import React, { useState, useEffect, useReducer } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  setDate,
  setMonth,
  getDate,
  getMonth,
  setYear,
  getYear,
  differenceInMilliseconds,
  addMilliseconds
} from "date-fns";
import { pipe } from "@cargotic/common-deprecated";

import {
  ShipmentType,
  Shipment as ShipmentT,
  ShipmentState
} from "@cargotic/model-deprecated";

import {
  fetchShipmentById,
  fetchNextShipmentIndexNumber,
  useApiClient
} from "@cargotic/api-client-deprecated";

import { useExchangeRates } from "@cargotic/currency";
import {
  CircularProgress,
  Slide,
  makeStyles
} from "@material-ui/core";
import queryString from "query-string";

import { useApiClient as useNewApiClient } from "../../../../cargotic-webapp-component";
import {
  storeDefaultUniLength,
  loadDefaultUnitLength,
  loadActiveFilters
} from "../../../storage";

import {
  putShipmentById,
  postShipment,
  postIncomingOrderFindJourneysByIds
} from "../../../resource";
import useRouter from "../../../component/hook/useRouter";
import useAuth from "../../../component/hook/useAuth";
import JourneyPlanner from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner";
import CargoticMapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import { Waypoint } from "../../../../../multiload/cargotic-core";
import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import ShipmentForm from "../ShipmentForm";
import { hydrateWaypoints, dehydrateWaypoints } from "../../../../../multiload/cargotic-webapp/utility";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";
import ShipmentConceptDialog from "../ShipmentConceptDialog";

import JourneyPlannerReducer
  from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerReducer";
import JourneyPlannerState from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerState";
import JourneyPlannerAction
  from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerAction";
import { date } from "yup";

const SHIPMENT_INDEX_NUMBER_MIN_LENGTH = 4;

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: "relative",
    height: "100%",
    overflow: "hidden"
  },
  form: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 200,
    overflow: "auto",
    backgroundColor: palette.background.default
  },
  loader: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  planner: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  shipmentForm: {
    paddingTop: spacing(2),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingBottom: spacing(2)
  }
}));

const getInitialCargoItemSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialCargoItemLinkSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ itemId }) => itemId)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialWaypointSeed = waypoints => {
  const seed = waypoints
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

function Shipment(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { match: { params: { id } }, history, location: { pathname, search } } = useRouter();

  const path = pathname.split("/");
  const copy = path.slice(-1)[0] === "copy";
  const edit = !isNaN(parseInt(path.slice(-1)[0]));
  const { vehicleId, trailerId } = queryString.parse(search);

  const { shipment: shipmentConcept, setShipment: setShipmentConcept } = useShipmentConcept();
  const [shipment, setShipment] = useState();
  const [initialJourney, setInitialJourney] = useState({
    cargoItemSeed: 0,
    cargoItemLinkSeed: 0,
    waypointSeed: 2,
    isPlaceSearchFailAlertOpen: false,
    selectedWaypointIndex: 0,
    waypoints: [Waypoint({ id: 0 }), Waypoint({ id: 1 })],
    distance: undefined,
    duration: undefined,
    errors: [{}, {}]
  });

  const [journeyState, dispatchJourney] = useReducer(
    JourneyPlannerReducer,
    JourneyPlannerState(initialJourney)
  );

  const [isPlannerOpen, setIsPlannerOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [drivers, setDrivers] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const { hasPermission } = useAuth();
  const [defaultUnitLength] = useState(() => loadDefaultUnitLength());
  const [conceptDialogOpen, setConceptDialogOpen] = useState(shipmentConcept && !shipmentConcept.fresh && (!id || copy));
  const [isJourneyChanged, setIsJourneyChanged] = useState(true);

  const apiClient = useApiClient();
  const newApiClient = useNewApiClient();

  const {
    isLoading: isExchangeRatesLoading,
    exchangeRates
  } = useExchangeRates();

  const {
    isLoading: isGoogleMapsApiLoading,
    api: googleMapsApi
  } = useGoogleMapsApi();

  const checkIndexNumber = (indexNumber) => /^[0-9,a-z,A-Z,-]*$/.test(indexNumber) && indexNumber.length >= SHIPMENT_INDEX_NUMBER_MIN_LENGTH;

  const handleBack = (): void => {
    setIsPlannerOpen(true);
  };

  const handleJourneyComplete = (): void => {
    setIsPlannerOpen(false);
  };

  const storeDefaultLenghUnitUndefinedTemplate = (unit) => storeDefaultUniLength(unit);

  const handleSave = async (
    newShipment: Omit<ShipmentT, "journey">,
    setIsSubmiting
  ): Promise<void> => {
    // create new shipment
    if (!newShipment.isDraft && !checkIndexNumber(newShipment.indexNumber)) {
      enqueueSnackbar(t("webapp:shipment.form.error.indexNumber"), {
        variant: "error"
      });
      return;
    }

    if (id === undefined || copy) {
      const state = newShipment.isDraft ? ShipmentState.QUEUE : newShipment.state;
      const shipment = {
        ...newShipment,
        state,
        journey: copy && journeyState === initialJourney
          ? {
            waypoints: dehydrateWaypoints(journeyState.waypoints),
            duration: journeyState.duration,
            distance: journeyState.distance
          }
          : journeyState,
        incomingOrderIds: shipmentConcept.incomingOrderIds
      };

      try {
        await postShipment(shipment);
        setShipmentConcept(null);

        enqueueSnackbar(t("shipment.create.success"), { variant: "success" });
        const params = loadActiveFilters("shipments");

        history.push(`/outcoming-orders?type=${ShipmentType.CARRIED}&${params}`);
      } catch (error) {
        console.error(error);

        const { response } = error;
        const data = response?.data || {};
        setIsSubmiting(false);

        const errorToMessageMapping = {
          "shipment/invalid-index-number-length": "shipment.create.error.invalidIndexNumberLength",
          "shipment/index-number-already-exists": "shipment.create.error.indexNumberAlreadyExists",
          "shipment/invalid-index-number": "webapp:shipment.form.error.indexNumber",
          "journey/no-point": "shipment.create.error.noPoint",
          "journey/no-journey": "shipment.create.error.noJourney",
          "journey/unsufficient-point-count": "shipment.create.error.unsufficientPointCount",
          "cargo/loaded-more-than-once": "shipment.create.error.loadedMoreThanOnce",
          "cargo/unloaded-before-loaded": "shipment.create.error.cargoUnloadedBeforeLoaded",
          "cargo/unloaded-more-than-once": "shipment.create.error.cargoUnloadedMoreThanOnce",
          "cargo/action-error": "shipment.create.error.cargoActionUnknown",
          "cargo/not-unloaded": "shipment.create.error.cargoNotUnloaded",
          "default": "shipment.create.error.general"
        };

        enqueueSnackbar(t(errorToMessageMapping[data.code] || errorToMessageMapping["default"]), { variant: "error" });
      }
    } else {
      const shipment = {
        ...newShipment,
        journey: isJourneyChanged
          ? journeyState
          : undefined,
        paidAt: undefined
      };

      try {
        await putShipmentById(id, shipment);
        enqueueSnackbar(t("shipment.update.success"), { variant: "success" });
        const params = loadActiveFilters("shipments");
        history.push(`/shipments?${params}`);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("shipment.update.error.general"), { variant: "error" });
      }
    }
  };

  const fetchShipmentData = async (): Promise<void> => {
    // new shipment
    if (id === undefined) {
      const indexNumber = await newApiClient.shipment.getNextShipmentNumber();

      let n = 0;
      let waypoints;
      if (shipmentConcept.mode === "FM") {
        const incomingOrderJourneys = await postIncomingOrderFindJourneysByIds(
          shipmentConcept.incomingOrderIds,
          undefined
        );
        if (typeof incomingOrderJourneys[0] === 'undefined') {
          setShipment({});
          setInitialJourney({});

          return;
        }

        const placeIds = incomingOrderJourneys.map(({
          journey: j
        }) => j.waypoints).flat().sort((a, b) => {
          const r = new Date(a.arriveAtFrom).getTime() - new Date(b.arriveAtFrom).getTime();
          if (a.cargo[0].action === "LOAD" && r === 0) {
            return -1;
          }
          return r;
        }).map((item) => ({ ...item, arriveAtFrom: item.arriveAtFrom ? new Date(item.arriveAtFrom) : undefined, arriveAtTo: item.arriveAtTo ? new Date(item.arriveAtTo) : undefined }));

        waypoints = placeIds.reduce((acc, waypoint) => {
          if (n) {
            const previousWaypoint = acc[n - 1];
            if (previousWaypoint.place.id === waypoint.place.id) {
              acc[n - 1] = {
                ...waypoint,
                cargo: [...waypoint.cargo, ...previousWaypoint.cargo],
                arriveAtFrom: waypoint.arriveAtFrom !== previousWaypoint.arriveAtFrom
                  ? waypoint.arriveAtFrom
                  : previousWaypoint.arriveAtFrom,
                arriveAtTo: waypoint.arriveAtTo !== previousWaypoint.arriveAtTo
                  ? waypoint.arriveAtTo
                  : previousWaypoint.arriveAtTo,
                contact: waypoint.contact === previousWaypoint.contact ? waypoint.contact : `${waypoint.contact}, ${previousWaypoint.contact}`,
                phoneNumber: waypoint.phoneNumber === "" || waypoint.phoneNumber === undefined ? undefined : (waypoint.phoneNumber === previousWaypoint.phoneNumber ? (waypoint.phoneNumber || "") : `${waypoint.phoneNumber || ""}, ${previousWaypoint.phoneNumber}`),
                note: waypoint.note === "" || waypoint.note === undefined ? undefined : (waypoint.note === previousWaypoint.note ? waypoint.note : `${waypoint.note}, ${previousWaypoint.note}`),
              }
              return acc;
            }
          }
          ++n;
          return [...acc, waypoint];
        }, []);

        const warehousePlace = shipmentConcept.warehousePlace;
        const allUnloadCargo = waypoints.filter((p) => p.cargo.every((c) => c.action === "UNLOAD")).map(({ id }) => id);
        const loadCargo = waypoints.filter((p) => p.cargo.every((c) => c.action === "LOAD")).map(({ cargo }) => cargo).flat().map((item) => ({ ...item, action: "UNLOAD" }));
        const filtered = waypoints.filter((p) => !allUnloadCargo.includes(p.id));
        const lastLoadWaypoint = filtered[filtered.length - 1];
        // .map((w) => ({ ...w, arriveAtFrom: new Date(), arriveAtTo: new Date() }));
        waypoints = [...filtered, {
          id: 1,
          place: warehousePlace,
          cargo: loadCargo,
          arriveAtFrom: new Date(lastLoadWaypoint.arriveAtFrom),
          arriveAtTo: new Date(lastLoadWaypoint.arriveAtTo)
        }]
      } else if (shipmentConcept.mode === "LH") {
        const incomingOrderJourneys = await postIncomingOrderFindJourneysByIds(
          shipmentConcept.warehouseOrderIds,
          "warehouse_order"
        );
        if (typeof incomingOrderJourneys[0] === 'undefined') {
          setShipment({});
          setInitialJourney({});

          return;
        }
        const placeIds = incomingOrderJourneys.map(({
          journey: j
        }) => j.waypoints).flat().sort((a, b) => {
          const r = new Date(a.arriveAtFrom).getTime() - new Date(b.arriveAtFrom).getTime();
          if (a.cargo[0].action === "LOAD" && r === 0) {
            return -1;
          }
          return r;
        }).map((item) => ({ ...item, arriveAtFrom: item.arriveAtFrom ? new Date(item.arriveAtFrom) : undefined, arriveAtTo: item.arriveAtTo ? new Date(item.arriveAtTo) : undefined }));
        const warehousePlace = shipmentConcept.warehousePlace;
        const loadCargo = placeIds.filter((p) => p.cargo.every((c) => c.action === "LOAD")).map(({ cargo }) => cargo).flat();

        waypoints = [{
          id: 1,
          place: warehousePlace,
          cargo: loadCargo,
          arriveAtFrom: new Date(),
          arriveAtTo: new Date()
        }, {
          id: 2,
          place: warehousePlace,
          cargo: loadCargo.map((item) => ({ ...item, action: "UNLOAD" })),
          arriveAtFrom: new Date(),
          arriveAtTo: new Date()
        }]

      } else {
        const incomingOrderJourneys = await postIncomingOrderFindJourneysByIds(
          shipmentConcept.incomingOrderIds,
          undefined
        );
        if (typeof incomingOrderJourneys[0] === 'undefined') {
          setShipment({});
          setInitialJourney({});

          return;
        }
        // set incoming order data to cargo items
        const placeIds = incomingOrderJourneys.map(({
          journey: j
        }) => j.waypoints).flat().sort((a, b) => {
          const r = new Date(a.arriveAtFrom) - new Date(b.arriveAtFrom);
          if (a.cargo[0].action === "LOAD" && r === 0) {
            return -1;
          }
          return r;
        }).map((item) => ({ ...item, arriveAtFrom: item.arriveAtFrom ? new Date(item.arriveAtFrom) : undefined, arriveAtTo: item.arriveAtTo ? new Date(item.arriveAtTo) : undefined }));

        waypoints = placeIds.reduce((acc, waypoint) => {
          if (n) {
            const previousWaypoint = acc[n - 1];
            if (previousWaypoint.place.id === waypoint.place.id) {
              acc[n - 1] = {
                ...previousWaypoint,
                cargo: [...waypoint.cargo, ...previousWaypoint.cargo],
                arriveAtFrom: waypoint.arriveAtFrom !== previousWaypoint.arriveAtFrom
                  ? waypoint.arriveAtFrom
                  : previousWaypoint.arriveAtFrom,
                arriveAtTo: waypoint.arriveAtTo !== previousWaypoint.arriveAtTo
                  ? previousWaypoint.arriveAtTo
                  : waypoint.arriveAtTo,
                contact: `${waypoint.contact}, ${previousWaypoint.contact}`,
                phoneNumber: waypoint.phoneNumber,
                note: `${waypoint.note}, ${previousWaypoint.note}`,
              }

              return acc;
            }
          }
          ++n;
          return [...acc, waypoint];
        }, []);

        // adjust datetime of waypoints relatively
        // const { arriveAtFrom: referenceDate } = waypoints[0] || {};
        // const newReferenceDate = pipe(
        //   (date) => setDate(date, getDate(new Date())),
        //   (date) => setMonth(date, getMonth(new Date())),
        //   (date) => setYear(date, getYear(new Date())),
        // )(new Date(referenceDate));

        // waypoints = waypoints.map((waypoint) => {
        //   const { arriveAtFrom, arriveAtTo } = waypoint;
        //   const differenceInMsFrom = differenceInMilliseconds(new Date(arriveAtFrom), new Date(referenceDate));
        //   const differenceInMsTo = arriveAtTo ? differenceInMilliseconds(new Date(arriveAtTo), new Date(arriveAtFrom)) : -1;
        //   const todaysFrom = addMilliseconds(newReferenceDate, differenceInMsFrom);
        //   const todaysTo = differenceInMsTo === -1 ? undefined : addMilliseconds(todaysFrom, differenceInMsTo);
        //   waypoint.arriveAtFrom = todaysFrom;
        //   waypoint.arriveAtTo = todaysTo;
        //   return waypoint;
        // });
      }

      let { vehicle, trailer, driver } = shipmentConcept;

      if (vehicleId) {
        vehicle = await newApiClient.vehicle.getVehicle({ vehicleId });
        driver = vehicle?.defaultDriver;
      }

      if (trailerId) {
        trailer = await newApiClient.vehicle.getVehicle({ vehicleId: trailerId });
      }

      const w = hydrateWaypoints(waypoints);

      const fetchedJourney = { waypoints: w, distance: 1, duration: 1 };
      const journeyData = {
        cargoItemSeed: getInitialCargoItemSeed(waypoints),
        cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
        waypointSeed: getInitialWaypointSeed(waypoints),
        waypoints: fetchedJourney.waypoints,
        errors: Array(fetchedJourney.waypoints.length).fill({}),
        distance: fetchedJourney.distance,
        duration: fetchedJourney.duration
      };

      setIsJourneyChanged(false);
      setInitialJourney(fetchedJourney);
      dispatchJourney({
        type: JourneyPlannerAction.CHANGE_JOURNEY,
        ...journeyData
      });

      const newShipment = {
        indexNumber,
        incomingOrders: shipmentConcept.incomingOrders,
        incomingOrderIds: shipmentConcept.incomingOrderIds,
        vehicle,
        driver,
        trailer,
        notes: "",

        documents: [],

        isDraft: true
      };

      setShipment(newShipment);
      setShipmentConcept({
        ...shipmentConcept,
        fresh: false,
        ...journeyData,
        ...newShipment
      });
    } else {
      if (copy) {
        const indexNumber = await fetchNextShipmentIndexNumber(apiClient);
        const { journey, ...newShipment } = await fetchShipmentById(apiClient, id);
        newShipment.indexNumber = indexNumber;
        newShipment.orderSerialNumber = indexNumber;
        newShipment.documents = [];
        newShipment.isDraft = true;
        newShipment.state = ShipmentState.QUEUE;
        newShipment.incomingOrders = [];

        // adjust datetime of waypoints relatively
        const referenceDate = journey.waypoints[0].arriveAtFrom;
        const newReferenceDate = pipe(
          (date) => setDate(date, getDate(new Date())),
          (date) => setMonth(date, getMonth(new Date())),
          (date) => setYear(date, getYear(new Date())),
        )(referenceDate);

        journey.waypoints = journey.waypoints.map((waypoint) => {
          const { arriveAtFrom, arriveAtTo } = waypoint;
          const differenceInMsFrom = differenceInMilliseconds(arriveAtFrom, referenceDate);
          const differenceInMsTo = arriveAtTo ? differenceInMilliseconds(arriveAtTo, arriveAtFrom) : -1;
          const todaysFrom = addMilliseconds(newReferenceDate, differenceInMsFrom);
          const todaysTo = differenceInMsTo === -1 ? undefined : addMilliseconds(todaysFrom, differenceInMsTo);
          waypoint.arriveAtFrom = todaysFrom;
          waypoint.arriveAtTo = todaysTo;
          return waypoint;
        });

        const waypoints = hydrateWaypoints(journey.waypoints);

        const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };
        const journeyData = {
          cargoItemSeed: getInitialCargoItemSeed(waypoints),
          cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
          waypointSeed: getInitialWaypointSeed(waypoints),
          waypoints: fetchedJourney.waypoints,
          errors: Array(fetchedJourney.waypoints.length).fill({}),
          distance: fetchedJourney.distance,
          duration: fetchedJourney.duration
        };

        setIsJourneyChanged(false);
        setShipment(newShipment);
        setInitialJourney(fetchedJourney);
        dispatchJourney({
          type: JourneyPlannerAction.CHANGE_JOURNEY,
          ...journeyData
        });
        setShipmentConcept({
          ...journeyData,
          ...newShipment
        });
      }
      if (edit) {
        const { journey, ...newShipment } = await fetchShipmentById(apiClient, id);
        newShipment.indexNumber = newShipment.indexNumber ? newShipment.indexNumber : await fetchNextShipmentIndexNumber(apiClient);
        newShipment.orderSerialNumber = newShipment.orderSerialNumber && !newShipment.isDraft
          ? newShipment.orderSerialNumber
          : newShipment.indexNumber;

        const waypoints = hydrateWaypoints(journey.waypoints);
        const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };

        setIsJourneyChanged(false);
        setShipment({
          ...newShipment
        });
        setInitialJourney(fetchedJourney);
        dispatchJourney({
          cargoItemSeed: getInitialCargoItemSeed(waypoints),
          cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
          waypointSeed: getInitialWaypointSeed(waypoints),
          type: JourneyPlannerAction.CHANGE_JOURNEY,
          waypoints: fetchedJourney.waypoints,
          errors: Array(fetchedJourney.waypoints.length).fill({}),
          distance: fetchedJourney.distance,
          duration: fetchedJourney.duration
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchShipmentData();
        setIsLoading(false);
      } catch (error) {
        console.error(error);

        enqueueSnackbar(t("shipment.load.error"), { variant: "error" });
      }
    })();
  }, []);

  useEffect(() => {
    if (JSON.stringify(journeyState.waypoints) !== JSON.stringify(initialJourney.waypoints)) {
      setIsJourneyChanged(true);
      onFormChange(journeyState);
    }
  }, [journeyState]);

  const onFormChange = (values) => {
    if ((id === undefined || copy) && !conceptDialogOpen) {
      const newShipmentConcept = { ...shipmentConcept, ...values };
      setShipmentConcept(newShipmentConcept);
      setShipment({ ...shipment, ...values });
    }
  };

  const content = (() => {
    if (isLoading || isExchangeRatesLoading || isGoogleMapsApiLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <>
        <JourneyPlanner
          className={classes.planner}
          googleMapsApi={googleMapsApi!}
          state={journeyState}
          dispatch={dispatchJourney}
          onComplete={handleJourneyComplete}
          mapTheme={CargoticMapTheme}
          defaultLengthUnit={defaultUnitLength}
          storeDefaultLengthUnit={storeDefaultLenghUnitUndefinedTemplate}
        />
        {!conceptDialogOpen
          ? (
            <Slide in={!isPlannerOpen} direction="left" unmountOnExit>
              <div className={classes.form}>
                <ShipmentForm
                  className={classes.shipmentForm}
                  apiClient={apiClient}
                  newApiClient={newApiClient}
                  exchangeRates={exchangeRates!}
                  // @ts-ignore
                  shipment={shipment}
                  journey={journeyState}
                  onBack={handleBack}
                  onSave={handleSave}
                  drivers={drivers}
                  vehicles={vehicles}
                  isUpdating={edit}
                  onFormChange={onFormChange}
                  isJourneyChanged={isJourneyChanged}
                />
              </div>
            </Slide>
          )
          : null}
        <ShipmentConceptDialog
          t={t}
          isOpen={conceptDialogOpen}
          onClose={async (saveConcept) => {
            if (saveConcept) {
              dispatchJourney({
                cargoItemSeed: shipmentConcept.cargoItemSeed,
                cargoItemLinkSeed: shipmentConcept.cargoItemLinkSeed,
                waypointSeed: shipmentConcept.waypointSeed,
                type: JourneyPlannerAction.CHANGE_JOURNEY,
                waypoints: shipmentConcept.waypoints,
                errors: shipmentConcept.errors,
                distance: shipmentConcept.distance,
                duration: shipmentConcept.duration
              });
              setShipment({
                ...shipment,
                ...shipmentConcept,
              });
            } else {
              setShipmentConcept(null);
            }
            setConceptDialogOpen(false);
          }}
        />
      </>
    );
  })();

  return (
    <>
      <div className={classes.root}>
        {content}
      </div>
    </>
  );
}

export default Shipment;
