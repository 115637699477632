import React from "react";
import { useTranslation } from "react-i18next";

import { blue, orange, green, brown } from "@material-ui/core/colors";
import { Tooltip, makeStyles } from "@material-ui/core";


const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    marginLeft: 5,
    marginRight: 5
  },
  cardLabel: {
    borderRadius: 10,
    marginRight: 8,
    width: 50,
    height: 6
  }
}));

const BoardCardLabels = ({ type, isDraft, paidAt }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const BoardCardLabel = ({ color, title }) => (
    <Tooltip title={title} >
      <span
        className={classes.cardLabel}
        style={{ backgroundColor: color }}
      />
    </Tooltip>
  );

  return (
    <div className={classes.root} >
      {isDraft
        ? <BoardCardLabel color={orange[400]} title={t("board.labels.draft")} />
        : null}
      {type === "FORWARDED"
        ? <BoardCardLabel color={blue[400]} title={t("board.labels.forwarded")} />
        : <BoardCardLabel color={brown[500]} title={t("board.labels.carried")} />
      }
      {paidAt
        ? <BoardCardLabel color={green[400]} title={t("board.labels.paid")} />
        : null}
    </div>
  );
}

export default BoardCardLabels;