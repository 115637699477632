import React from "react";
import PropTypes from 'prop-types'

import Box from "@material-ui/core/Box"

import CargoItemListItem from "./CargoItemListItem";

export function CargoItemList({ cargo, expandableDetail, ...restProps}) {
  return (
    <Box>
      {cargo.map((item, index) => {
        return (
          <CargoItemListItem 
            key={index} 
            item={item}
            expandableDetail={item.packages?.length === 0 ? false : expandableDetail}
            {...restProps}
          />
        );
      })}
    </Box>
  )
}


CargoItemList.propTypes = {}
CargoItemList.defaultProps = {}

export default CargoItemList;
