import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

const ConfirmationAlert = ({
  title,
  content,
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={onSubmit}>
          {t("webapp:common.title.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationAlert;
