import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import FormikTextField from "@cargotic/webapp-component/component/FormikTextField";
import FormikSelect from "@cargotic/webapp-component/component/FormikSelect";
import { InvoiceVatType } from "@cargotic/webapp/component/enums/enums";

import { renderTextField, renderSelect } from "../formHelpers";

const VAT_RATES = [0, 10, 15, 21];

const useStyles = makeStyles(({ spacing }) => ({
    removeButtonContainer: {
        minWidth: spacing(6),
        paddingLeft: spacing(1)
    }
}));

const getFieldName = (index, fieldName) => `items.${index}.${fieldName}`;

export function InvoiceItemForm({ index, form, onDelete }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleDelete = useCallback(() => {
        if (typeof onDelete === "function") {
            onDelete(index);
        }
    }, [index, onDelete]);

    const vatRateOptions = useMemo(
        () =>
            VAT_RATES.map((rate, idx) => (
                <MenuItem
                    key={`vatRate${idx}`}
                    value={rate}
                >{`${rate} %`}</MenuItem>
            )),
        []
    );

    const vatTypeOptions = useMemo(
        () =>
            Object.values(InvoiceVatType).map((vatTypeEnumItem) => (
                <MenuItem key={vatTypeEnumItem} value={vatTypeEnumItem}>
                    {t(`enum.InvoiceVatType.${vatTypeEnumItem}`)}
                </MenuItem>
            )),
        []
    );

    return (
        <Box display="flex" flexDirection="row">
            <Grid container spacing={1} key={`invoiceItem${index}`}>
                <Grid xs={1} item>
                    {renderTextField(
                        form,
                        getFieldName(index, "count"),
                        t("invoices.invoice.count"),
                        true
                    )}
                </Grid>
                <Grid xs={1} item>
                    {renderTextField(
                        form,
                        getFieldName(index, "unit"),
                        t("invoices.invoice.unit"),
                        false
                    )}
                </Grid>
                <Grid xs={1} item>
                    {renderTextField(
                        form,
                        getFieldName(index, "unitPrice"),
                        t("invoices.invoice.unitPrice"),
                        true
                    )}
                </Grid>
                <Grid xs={2} item>
                    {renderSelect(
                        form,
                        getFieldName(index, "dphInclusion"),
                        t("invoices.invoice.dphInclusion"),
                        [
                            <MenuItem key="vatExcluded" value={0}>
                                {t("invoices.invoice.vatExcluded")}
                            </MenuItem>,
                            <MenuItem key="vatIncluded" value={1}>
                                {t("invoices.invoice.vatIncluded")}
                            </MenuItem>
                        ]
                    )}
                </Grid>
                <Grid xs={1} item>
                    {renderSelect(
                        form,
                        getFieldName(index, "dph"),
                        t("invoices.invoice.dph"),
                        vatRateOptions
                    )}
                </Grid>
                <Grid xs={3} item>
                    {renderSelect(
                        form,
                        getFieldName(index, "dphType"),
                        t("invoices.invoice.dphType"),
                        vatTypeOptions
                    )}
                </Grid>
                <Grid xs={3} item>
                    {renderTextField(
                        form,
                        getFieldName(index, "description"),
                        t("invoices.invoice.description"),
                        false
                    )}
                </Grid>
            </Grid>

            <Box className={classes.removeButtonContainer}>
                {typeof onDelete === "function" && (
                    <Fab color="default" size="small" onClick={handleDelete}>
                        <DeleteIcon />
                    </Fab>
                )}
            </Box>
        </Box>
    );
}

InvoiceItemForm.propTypes = {
    index: PropTypes.number.isRequired,
    form: PropTypes.object.isRequired,
    onDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func.isRequired])
};

export default InvoiceItemForm;
