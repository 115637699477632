import clsx from "clsx";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core";

import WaypointBrowserButton from "./WaypointBrowserButton";
import WaypointBrowserConnector from "./WaypointBrowserConnector";
import WaypointBrowserItem from "./WaypointBrowserItem";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100%"
  }
}));

const WaypointBrowser = ({
  className,
  waypoints,
  errors,
  lastLatLng,
  setLastLatLng,
  selectedWaypointIndex,
  searchPlace,
  searchPlaceByCoordinates,
  isCargoEditorVisible,
  handlePickFromMap,
  suggestPlace,
  onPlaceSearchFail,
  onWaypointAdd,
  onWaypointChange,
  onWaypointError,
  onWaypointDelete,
  onWaypointSelect,
  onWaypointsReorder,
  isDraft
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const containerRef = useRef();

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    onWaypointsReorder(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="waypoints">
        {(provided, snapshot) => {
          const isDragging = snapshot.draggingFromThisWith !== null;

          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={
                clsx(
                  className,
                  classes.root,
                  {
                    [classes.highlight]: isDragging
                  }
                )
              }
            >
              {waypoints.map((waypoint, index) => {
                const { id } = waypoint;
                const isFirst = index === 0;
                const isLast = index === waypoints.length - 1;
                const isDeletable = waypoints.length > 2;
                const isExpanded = index === selectedWaypointIndex
                  && !isDragging;
                const previousWaypoint = waypoints[index - 1];
                const minimumArriveAt = previousWaypoint?.arriveAtTo
                  ?? previousWaypoint?.arriveAtFrom;

                return (
                  <Draggable key={id} draggableId={id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {
                          isFirst
                            ? null
                            : <WaypointBrowserConnector isHidden={isDragging} />
                        }
                        <WaypointBrowserItem
                          waypoint={waypoint}
                          errors={errors[index]}
                          minimumArriveAt={minimumArriveAt}
                          index={index}
                          lastLatLng={lastLatLng}
                          setLastLatLng={setLastLatLng}
                          isDeletable={isDeletable}
                          isFirst={isFirst}
                          isExpanded={isExpanded}
                          isLast={isLast}
                          searchPlace={searchPlace}
                          handlePickFromMap={handlePickFromMap}
                          isCargoEditorVisible={isCargoEditorVisible}
                          searchPlaceByCoordinates={searchPlaceByCoordinates}
                          suggestPlace={suggestPlace}
                          onPlaceSearchFail={onPlaceSearchFail}
                          onChange={(name, value) => (
                            onWaypointChange(index, name, value)
                          )}
                          onError={(name, value) => (
                            onWaypointError(index, name, value)
                          )}
                          onDelete={() => onWaypointDelete(index)}
                          onExpand={() => onWaypointSelect(index)}
                          isDraft={isDraft}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              <WaypointBrowserConnector isHidden={isDragging} size="small" />
              <WaypointBrowserButton
                isHidden={isDragging}
                onClick={onWaypointAdd}
              />
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default WaypointBrowser;
