import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationAlert } from "../../../cargotic-webapp-component";

const ContactPublicizeConfirmationAlert = ({
  isContactPrivate,
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationAlert
      title={
        isContactPrivate
          ? t("webapp:contact.title.make-public")
          : t("webapp:contact.title.make-private")
      }
      content={
        isContactPrivate
          ? t("webapp:contact.prompt.make-public")
          : t("webapp:contact.prompt.make-private")
      }
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default ContactPublicizeConfirmationAlert;
