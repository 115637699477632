import React from "react";
import { useTranslation } from "react-i18next";

import { ContactType } from "@cargotic/model";
import {
  IconButton,
  InputAdornment,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import { Warning as WarningIcon } from "@material-ui/icons";

import FormikDatePicker from "../FormikDatePicker";

const useStyles = makeStyles(({ palette }) => ({
  error: {
    color: palette.error.main
  },
  warning: {
    color: palette.warning.main
  }
}));

const FormikContactInsuranceExpirationDatePicker = ({
  form,
  name,
  ...props
}) => {
  const value = form.values[name];
  const now = new Date();
  const classes = useStyles();
  const { t } = useTranslation();

  const startAdornment = (() => {
    if (value > now) {
      return null;
    }

    if (value === null) {
      return (
        <InputAdornment position="start">
          <Tooltip title={t("webapp:contact.insurance.undefined")}>
            <IconButton className={classes.warning} size="small">
              <WarningIcon />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      );
    }

    return (
      <InputAdornment position="start">
        <Tooltip title={t("webapp:contact.insurance.expired")}>
          <IconButton className={classes.error} size="small">
            <WarningIcon />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  })();

  return (
    <FormikDatePicker
      {...props}
      name={name}
      form={form}
      InputProps={{
        startAdornment
      }}
    />
  );
};

export default FormikContactInsuranceExpirationDatePicker;
