import React from "react";
import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@material-ui/core";
import SettingsView from "./SettingsView";

const SettingsBodyNavigation = ({
  view,
  onViewChange,
  hasReadBankAccountPermission,
  hasReadRolePermission,
  hasReadWarehousePermission,
  hasUpdateCompanyProfilePermission,
  hasReadExchangeRatePermission
}) => {
  const { t } = useTranslation();

  const handleTabChange = (_, value) => onViewChange(value);
  
  return hasUpdateCompanyProfilePermission && (
    <Tabs
      indicatorColor="primary"
      value={view}
      onChange={handleTabChange}
    >
      {hasUpdateCompanyProfilePermission && <Tab
        label={t("webapp:settings.title.companyAndUser")}
        value={SettingsView.COMPANY_AND_USER}
        disabled={!hasUpdateCompanyProfilePermission}
      />}
      <Tab
        label={t("webapp:settings.title.tariffAndBilling")}
        value={SettingsView.TARIFF}
      />
      
      {hasReadBankAccountPermission && <Tab
        label={t("webapp:settings.title.bankAccounts")}
        value={SettingsView.BANK_ACCOUNTS}
        disabled={!hasReadBankAccountPermission}
      />}
      {hasReadExchangeRatePermission && <Tab
        label={t("webapp:settings.title.exchangeRates")}
        value={SettingsView.EXCHANGE_RATES}
        disabled={!hasReadExchangeRatePermission}
      />}
      {hasReadRolePermission && <Tab
        label={t("webapp:settings.title.roles")}
        value={SettingsView.ROLES}
        disabled={!hasReadRolePermission}
      />}
      {hasReadWarehousePermission && <Tab
          label={t("webapp:settings.title.warehouses")}
          value={SettingsView.WAREHOUSES}
          disabled={!hasReadWarehousePermission}
      />}
      {hasReadRolePermission && <Tab          
        label={t("webapp:settings.title.states")}
        value={SettingsView.STATES}
        // TODO
        disabled={!hasReadRolePermission}
      />}
    </Tabs>
  );
};

export default SettingsBodyNavigation;
