import React from "react";

import {
  TextField, Button, Typography, makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { ResetPasswordSchema } from "../../../utility/validationSchema";
import useAuth from "../../hook/useAuth";

const useStyles = makeStyles(({ spacing }) => ({
  bottomMargin: {
    marginBottom: spacing(1)
  }
}));

const AuthResetPasswordForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { resetPassword } = useAuth();

  const handleSubmit = ({ email }, { setSubmitting }) => resetPassword(email).then(() => setSubmitting(false));

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={ResetPasswordSchema}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                name="email"
                fullWidth
                label={t("email")}
                required
                value={values.email}
                error={errors.email && touched.email}
                helperText={touched.email && t(errors.email) || " "}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              className={classes.bottomMargin}
            >
              {t("resetPassword")}
            </Button>
            <div>
              <Typography variant="caption">
                {t("newToCargotic")}
&nbsp;
                <Link to="/sign-up">
                  {t("signUp")}
!
                </Link>
              </Typography>
              <Typography variant="caption" paragraph>
                {t("goBackTo")}
&nbsp;
                <Link to="/sign-in">{t("signIn")}</Link>
              </Typography>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export default AuthResetPasswordForm;
