import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, makeStyles } from "@material-ui/core";

import { isEmpty, formatDateRange } from "../../../cargotic-common";
import { generateWaypointTitle } from "../../../cargotic-core";
import CargoItemList from "../CargoItemList";

const useStyles = makeStyles(({ spacing }) => ({
  section: {
    display: "flex",

    "& > :first-child": {
      whiteSpace: "nowrap"
    },

    "& > :last-child": {
      marginLeft: spacing(1)
    }
  }
}));

const WaypointListItem = ({
  waypoint: {
    place: {
      address: {
        formatted: formattedAddress
      }
    },
    arriveAtFrom,
    arriveAtTo,
    cargo
  },
  index,
  isFirst,
  isLast
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const title = formattedAddress
    || generateWaypointTitle(t, index, isFirst, isLast);

  return (
    <div className={classes.root}>
      <section className={classes.section}>
        <Typography color="textSecondary">
          <b>
            {title}
          </b>
        </Typography>
      </section>
      {
        isEmpty(cargo)
          ? null
          : (
            <section>
              <CargoItemList cargo={cargo} />
            </section>
          )
      }
    </div>
  );
};

export default WaypointListItem;
