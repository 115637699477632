import queryString from "query-string";

const addUrlParam = (name, param) => {
  const params = new URLSearchParams(window.location.search);
  params.set(
    name, typeof param !== "string" ? JSON.stringify(param) : param
  );

  window.history.replaceState(null, null, "?"+params.toString())
}

const getTableUrlParams = (search) => {
  const {
    searchText,
    filter 
  } = queryString.parse(search);

  return {
    searchText: searchText || "",
    filter: filter ? JSON.parse(filter) : {}
  }
}

const getUrlWithParams = (name, param) => {
  const params = new URLSearchParams(window.location.search);
  params.set(
    name, typeof param !== "string" ? JSON.stringify(param) : param
  );
  return params.toString();
}

export {
  getTableUrlParams,
  addUrlParam,
  getUrlWithParams
}
