import clsx from "clsx";
import React, { useRef, useState, useEffect, useCallback } from "react";

import { makeStyles } from "@material-ui/core";

import { isEmpty } from "../../collection";
import { SimpleChangeEvent } from "../../dom";
import { Optional } from "../../type";

import SuggestionArea from "../SuggestionArea";
import AutosuggestSuggestionComponentProps
  from "./AutosuggestSuggestionComponentProps";


interface AutosuggestProps<T> {
  className?: string;
  name?: string;
  value?: T;
  suggest: (text: string) => Promise<T[]>;
  getSuggestionText: (suggestion: Optional<T>) => string;
  suggestionComponent:
    React.ComponentType<AutosuggestSuggestionComponentProps<T>>;
  // eslint-disable-next-line
  inputComponent: React.ComponentType<any>;
  InputProps: Object;
  onBlur?: React.FocusEventHandler;
  onChange?: (event: SimpleChangeEvent<Optional<T>>) => void;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: "relative"
  },
  suggestions: {
    position: "absolute",
    width: "100%",
    marginTop: spacing(-2),
    zIndex: 50
  }
}));

const debounce = (execute, delay) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => execute(...args), delay);
  };
};

function Autosuggest<T>(
  {
    className,
    name,
    value,
    suggest,
    getSuggestionText,
    suggestionComponent,
    inputComponent: InputComponent,
    onBlur,
    onChange,
    InputProps,
    ...other
  }: AutosuggestProps<T>
): React.ReactElement {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();
  const suggestionAreaRef = useRef<HTMLDivElement>();

  const isSuggestionAreaClicked = useRef<boolean>(false);

  const [suggestions, setSuggestions] = useState<T[]>([]);

  const debounceSuggest = useCallback(
    debounce(async (text) => {
      const newSuggestions = await suggest(text);

      if (document.activeElement !== inputRef.current) {
        return;
      }

      setSuggestions(newSuggestions);
    }, 1000), [suggest]
  );

  const handleChange = (newValue: Optional<T>): void => {
    if (onChange) {
      onChange({ target: { name: name ?? "", value: newValue } });
    }
  };

  const handleInputBlur = (event: React.FocusEvent): void => {
    if (isSuggestionAreaClicked.current) {
      isSuggestionAreaClicked.current = false;

      if (inputRef.current) {
        // inputRef.current.focus();
      }

      return;
    }

    setSuggestions([]);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleInputChange = async (
    {
      target: {
        value: newInputValue
      }
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): Promise<void> => {
    if (typeof value === "object") {
      handleChange("");
    }
    else {
      handleChange(newInputValue);
    }
  };

  const handleSuggestionClick = (suggestion: T): void => {
    setSuggestions([]);

    handleChange(suggestion);
  };

  const handleOnFocus = async () => {
    setSuggestions(await suggest(''));
  }

  useEffect(() => {
    const { current: element } = suggestionAreaRef;

    if (!element) {
      return () => {};
    }

    const listener = (): void => {
      isSuggestionAreaClicked.current = true;
    };

    element.addEventListener("mousedown", listener);

    return () => {
      element.removeEventListener("mousedown", listener);
    };
  });

  
  useEffect(() => {
    if (typeof value === "string") {
      debounceSuggest(value);
    }
  }, [value]);

  return (
    <div className={clsx(className, classes.root)}>
      <InputComponent
        {...other}
        name={name}
        inputRef={inputRef}
        value={typeof value === "string" ? value : getSuggestionText(value)}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        autoComplete="off"
        InputProps={InputProps}
      />
      <SuggestionArea
        ref={suggestionAreaRef}
        className={classes.suggestions}
        // @ts-ignore
        getSuggestionText={getSuggestionText}
        // @ts-ignore
        suggestionComponent={suggestionComponent}
        suggestions={suggestions}
        // @ts-ignore
        onSuggestionClick={handleSuggestionClick}
        dataCy="suggestions"
      />
    </div>
  );
}

export default Autosuggest;
export {
  AutosuggestProps
};
