const CargoItem = ({
  id,
  itemId,
  action,
  template,
  description,
  quantity,
  width,
  height,
  length,
  lengthUnit,
  volume,
  weight,
  totalVolume,
  totalWeight,
  isStackable
}) => ({
  id,
  itemId,
  action,
  template,
  description,
  quantity,
  width,
  height,
  length,
  lengthUnit,
  volume,
  weight,
  totalVolume,
  totalWeight,
  isStackable
});

export default CargoItem;
