import React, { useEffect, useState } from "react";

import UserProfileContext from "../../context/UserProfileContext";
import useAuth from "../../hook/useAuth";
import { readCustomerProfile } from "../../../resource";

const UserProfileProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});

  const { authenticated } = useAuth();

  useEffect(() => {
    if (authenticated) {
      readCustomerProfile()
        .then((userProfile) => {
          setUserProfile(userProfile);
          setLoading(false);
        });
    }
  }, [authenticated]);

  return (
    <UserProfileContext.Provider value={{
      loading,
      setLoading,
      userProfile,
      setUserProfile
    }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
