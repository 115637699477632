import { useContext } from "react";

import { ApiClient } from "../client";
import { ApiClientContext } from "../component";

function useApiClient(): ApiClient {
  const { client } = useContext(ApiClientContext);

  return client;
}

export default useApiClient;
