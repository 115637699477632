import React from "react";
import { Switch } from "react-router-dom";

import ContactProfileView
  from "../../../../cargotic-webapp-contact/ContactProfileView";

import SettingsView
  from "../Settings/SettingsView";

import RoleEditorView from "../Settings/RoleEditorView";

import VehicleProfileView
  from "../../../../cargotic-webapp-vehicle/VehicleProfileView";

import { AuthPolicy } from "../../../auth";
import ContactProfileContainerAdapter
  from "../../contact/ContactProfileContainerAdapter";

import { SettingsContainerAdapter } from "../../settings";
import RoleEditorContainer from "../Settings/RoleEditorContainer";
import { Roles } from "../../enums/enums";

import VehicleProfileContainerAdapter
  from "../../vehicle/VehicleProfileContainerAdapter";
import ContactVehicleProfileContainerAdapter
  from "../../vehicle/ContactVehicleProfileContainerAdapter";

import Auth from "../Auth";
import AuthRoute from "../AuthRoute";
import Dashboard from "../Dashboard";
import NotFound from "../NotFound";
import DashboardView from "../Dashboard/DashboardView";
import {WarehousesContainerAdapter} from "../../warehouses";
import WarehouseView from "../WarehouseDetail/WarehouseView";

const AppRouter = () => (
  <Switch>
    <AuthRoute
      exact
      path="/"
      policy={AuthPolicy.ANY}
      component={({ authenticated }) => (
        authenticated
          ? <Dashboard view={DashboardView.OVERVIEW} />
          : <Auth formType="SIGN_IN" />
      )}
    />
    <AuthRoute
      exact
      path="/action"
      policy={AuthPolicy.ANY}
      component={() => (
        <Auth formType="ACTION" />
      )}
    />
    <AuthRoute
      exact
      path="/contacts"
      handleExpiredTariff
      permission={["resource.contact.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.CONTACTS} />
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:id(\d+)"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.contact.user.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactProfileContainerAdapter
            view={ContactProfileView.CONTACT_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:id(\d+)/employees"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expenses.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactProfileContainerAdapter
            view={ContactProfileView.CONTACT_EMPLOYEE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:id(\d+)/vehicles"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactProfileContainerAdapter
            view={ContactProfileView.CONTACT_VEHICLES_OVERVIEW}

          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:contactId(\d+)/vehicles/:id(\d+)"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactVehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:contactId(\d+)/vehicles/:id(\d+)/expenses"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expenses.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactVehicleProfileContainerAdapter
             view={VehicleProfileView.VEHICLE_EXPENSE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:contactId(\d+)/vehicles/:id(\d+)/expirations"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expiration.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactVehicleProfileContainerAdapter
             view={VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:contactId(\d+)/vehicles/:id(\d+)/incomes"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.income.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactVehicleProfileContainerAdapter
             view={VehicleProfileView.VEHICLE_INCOME_OVERVIEW}
          />
        </Dashboard>
      )}
    />

    <AuthRoute
      exact
      path="/vehicles"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES} />
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)/expenses"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expenses.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_EXPENSE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)/expirations"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expiration.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)/incomes"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.income.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_INCOME_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/board"
      handleExpiredTariff
      permission={["resource.shipment.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT_BOARD} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipment/:id(\d+)?"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.shipment.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipment/:id(\d+)?/copy"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.shipment.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipment"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.shipment.user.create"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/incoming-order/:id(\d+)?"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.incomingOrder.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.INCOMING_ORDER} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/incoming-order/:id(\d+)?/copy"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.incomingOrder.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.INCOMING_ORDER} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/incoming-order"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.incomingOrder.user.create"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.INCOMING_ORDER} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/incoming-order/csv-import"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.incomingOrder.user.create"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.INCOMING_ORDERS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/outcoming-order/:id(\d+)?"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.outcomingOrder.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.OUTCOMING_ORDER} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/outcoming-order/:id(\d+)?/copy"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.outcomingOrder.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.OUTCOMING_ORDER} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/outcoming-order"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.outcomingOrder.user.create"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.OUTCOMING_ORDER} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/incoming-orders"
      permission={["resource.incomingOrder.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.INCOMING_ORDERS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/outcoming-orders"
      permission={["resource.outcomingOrder.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.OUTCOMING_ORDERS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipments"
      permission={["resource.shipment.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.OUTCOMING_ORDERS} />
      )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses"
        permission={["resource.warehouse.read"]}
        allPermissionsRequired
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.INCOMING_ORDERS}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/:warehouseId/incoming-orders"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.INCOMING_ORDERS}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/incoming-orders"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.INCOMING_ORDERS}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/order-history"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.ORDERS_HISTORY}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/:warehouseId/order-history"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.ORDERS_HISTORY}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/warehousemen"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.WAREHOUSEMEN}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/:warehouseId/warehousemen"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.WAREHOUSEMEN}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/shipments"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.INCOMING_SHIPMENTS}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/:warehouseId/shipments"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.INCOMING_SHIPMENTS}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/:warehouseId/shipments/incoming"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.INCOMING_SHIPMENTS}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/warehouses/:warehouseId/shipments/outcoming"
        policy={AuthPolicy.ENFORCE}
        component={() => (
            <Dashboard view={DashboardView.WAREHOUSES}>
              <WarehousesContainerAdapter
                  view={WarehouseView.OUTCOMING_SHIPMENTS}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/users/:id(\d+)?"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.user.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.USERS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/users"
      permission={["resource.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.USERS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/tracking"
      permission={["resource.track.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.TRACKING} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      permission={["resource.statistics.user.read", "resource.statistics.user.company"]}
      oneOfPermissionsRequired
      path="/statistics"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.STATISTICS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/places"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.PLACES} />
      )}
    />

    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/role/:id(\d+)?"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.role.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <RoleEditorContainer />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/role"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.role.create"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <RoleEditorContainer />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/invoice"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.invoice.create"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.INVOICE} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/invoices"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.invoice.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.INVOICES} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <SettingsContainerAdapter
            view={SettingsView.COMPANY_AND_USER}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/company-and-user"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <SettingsContainerAdapter
            view={SettingsView.COMPANY_AND_USER}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/tariff"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <SettingsContainerAdapter
            view={SettingsView.TARIFF}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/bank-accounts"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.bankAccount.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <SettingsContainerAdapter
            view={SettingsView.BANK_ACCOUNTS}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/exchange-rates"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <SettingsContainerAdapter
            view={SettingsView.EXCHANGE_RATES}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/roles"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.role.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <SettingsContainerAdapter
            view={SettingsView.ROLES}
          />
        </Dashboard>
      )}
    />
     <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/states"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.role.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
          <SettingsContainerAdapter
            view={SettingsView.STATES}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/settings/states/warehouse-order"
        policy={AuthPolicy.ENFORCE}
        permission={["resource.role.read"]}
        allPermissionsRequired
        component={() => (
          <Dashboard view={DashboardView.SETTINGS}>
              <SettingsContainerAdapter
              view={SettingsView.STATES}
            />
          </Dashboard>
        )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/states/incoming-order"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.role.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
            <SettingsContainerAdapter
            view={SettingsView.STATES}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings/states/shipment"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.role.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SETTINGS}>
            <SettingsContainerAdapter
            view={SettingsView.STATES}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
          exact
          handleExpiredTariff
          path="/settings/states/outcoming-order"
          policy={AuthPolicy.ENFORCE}
          permission={["resource.role.read"]}
          allPermissionsRequired
          component={() => (
            <Dashboard view={DashboardView.SETTINGS}>
                <SettingsContainerAdapter
                view={SettingsView.STATES}
              />
            </Dashboard>
          )}
    />
    <AuthRoute
        exact
        handleExpiredTariff
        path="/settings/warehouses"
        policy={AuthPolicy.ENFORCE}
        permission={["resource.warehouse.read"]}
        allPermissionsRequired
        component={() => (
            <Dashboard view={DashboardView.SETTINGS}>
              <SettingsContainerAdapter
                  view={SettingsView.WAREHOUSES}
              />
            </Dashboard>
        )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/feedback"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.FEEDBACK} />
      )}
    />
    <AuthRoute
      exact
      path="/reset-password"
      policy={AuthPolicy.FORBID}
      component={() => (
        <Auth formType="RESET_PASSWORD" />
      )}
    />
    <AuthRoute
      exact
      path="/sign-in"
      policy={AuthPolicy.FORBID}
      component={() => (
        <Auth formType="SIGN_IN" />
      )}
    />
    <AuthRoute
      exact
      path="/sign-up"
      policy={AuthPolicy.FORBID}
      component={() => (
        <Auth formType="SIGN_UP" />
      )}
    />
    <AuthRoute
      path=""
      policy={AuthPolicy.ANY}
      component={({ authenticated }) => (authenticated
        ? <Dashboard view={DashboardView.NOTFOUND} />
        : <NotFound />)
      }
    />
  </Switch>
);

export default AppRouter;
