import React from "react";
import { Switch } from "react-router-dom";

import { AuthPolicy } from "../../../auth";
import TrackRoute from "../TrackRoute";
import SubmitTransportationDocuments from "../SubmitTransportationDocuments";

const SubmitTransportationDocumentsAppRouter = () => (
  <Switch>
    <TrackRoute
      exact
      path="/"
      policy={AuthPolicy.ANY}
      component={() => <SubmitTransportationDocuments />}
    />
  </Switch>
);

export default SubmitTransportationDocumentsAppRouter;
