import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";

import { useFormik } from "formik";

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles
} from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Loading from "@cargotic/webapp-component/component/Loading";
import {
  readIndexNumberSequences,
  putIndexNumberSequences
} from "@cargotic/webapp/resource";

import CustomSequencingCardContent from "./CustomSequencingCardContent";

export function CustomSequencingSettingsCard({
  hasUpdateSequencingPermission
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [sequencingSettings, setSequencingSettings] = useState();

  const handleLoadSettings = useCallback(async () => {
    try {
      const data = await readIndexNumberSequences();
      setSequencingSettings(data);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t("settings.customSequencing.error.loadError"), {
        variant: "error"
      });
    }
  }, [enqueueSnackbar, setSequencingSettings, readIndexNumberSequences]);

  const handleSaveSettings = useCallback(
    async (values) => {
      try {
        await putIndexNumberSequences(values);
        enqueueSnackbar(t("settings.customSequencing.success"), {
          variant: "success"
        });
        await handleLoadSettings();
      } catch (e) {
        console.error(e);
        enqueueSnackbar(
          t("settings.customSequencing.error.saveError"),
          {
            variant: "error"
          }
        );
      }
    },
    [putIndexNumberSequences, handleLoadSettings, enqueueSnackbar]
  );

  useEffect(() => {
    handleLoadSettings();
  }, [handleLoadSettings]);

  return (
    <Card>
      <CardHeader title={t("settings.customSequencing.title")} />
      <CardContent>
        {!sequencingSettings ? (
          <Loading />
        ) : (
          <Box p={1} pb={0}>
            <CustomSequencingCardContent
              data={sequencingSettings}
              onFormSubmit={handleSaveSettings}
              hasUpdateSequencingPermission={hasUpdateSequencingPermission}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default CustomSequencingSettingsCard;
