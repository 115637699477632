import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

const VehicleDeleteDialog = ({
  open, selected, handleClose, handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("vehicles.delete")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("vehicles.messages.delete", { count: selected })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("abort")}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VehicleDeleteDialog;
