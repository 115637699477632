import DummyError from "./DummyError";
import sleep from "./sleep";

const unreliable = ({ errorRate, latency }) => execute => (
  async (...args) => {
    if (Math.random() <= errorRate) {
      throw new DummyError();
    }

    if (latency) {
      await sleep(latency);
    }

    return execute(...args);
  }
);

export default unreliable;
