import React, { useState, useEffect, useReducer } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  setDate,
  setMonth,
  getDate,
  getMonth,
  setYear,
  getYear,
  differenceInMilliseconds,
  addMilliseconds
} from "date-fns";
import { pipe } from "@cargotic/common-deprecated";

import {
  ShipmentType,
  Shipment as ShipmentT,
  ShipmentState
} from "@cargotic/model-deprecated";

import { Currency, useExchangeRates } from "@cargotic/currency";
import {
  CircularProgress,
  Slide,
  makeStyles
} from "@material-ui/core";
import queryString from "query-string";

import { useApiClient } from "../../../../cargotic-webapp-component";
import {
  storeDefaultUniLength,
  loadDefaultUnitLength
} from "../../../storage";

import {
  readUserTerms,
  readCompanyTerms,
  putOutcomingOrderById
} from "../../../resource";
import useRouter from "../../../component/hook/useRouter";
import useAuth from "../../../component/hook/useAuth";
import JourneyPlanner from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner";
import CargoticMapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import { Waypoint } from "../../../../../multiload/cargotic-core";
import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import OutcomingOrderForm from "../OutcomingOrderForm";
import { hydrateWaypoints, dehydrateWaypoints } from "../../../../../multiload/cargotic-webapp/utility";
import useOutcomingOrderConcept from "../../../component/hook/useOutcomingOrderConcept";
import ShipmentConceptDialog from "../ShipmentConceptDialog";

import JourneyPlannerReducer from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerReducer";
import JourneyPlannerState from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerState";
import JourneyPlannerAction from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerAction";

const SHIPMENT_INDEX_NUMBER_MIN_LENGTH = 4;

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: "relative",
    height: "100%",
    overflow: "hidden"
  },
  form: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 200,
    overflow: "auto",
    backgroundColor: palette.background.default
  },
  loader: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  planner: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  shipmentForm: {
    paddingTop: spacing(2),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingBottom: spacing(2)
  }
}));

const getInitialCargoItemSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialCargoItemLinkSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ itemId }) => itemId)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialWaypointSeed = waypoints => {
  const seed = waypoints
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

function OutcomingOrder(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { match: { params: { id } }, history, location: { pathname, search } } = useRouter();

  const path = pathname.split("/");
  const copy = path.slice(-1)[0] === "copy";
  const edit = !isNaN(parseInt(path.slice(-1)[0]));
  const {
    carrierId
  } = queryString.parse(search);

  const { outcomingOrder: shipmentConcept, setOutcomingOrder: setShipmentConcept } = useOutcomingOrderConcept();
  const [shipment, setShipment] = useState();
  const [initialJourney, setInitialJourney] = useState({
    cargoItemSeed: 0,
    cargoItemLinkSeed: 0,
    waypointSeed: 2,
    isPlaceSearchFailAlertOpen: false,
    selectedWaypointIndex: 0,
    waypoints: [Waypoint({ id: 0 }), Waypoint({ id: 1 })],
    distance: undefined,
    duration: undefined,
    errors: [{}, {}]
  });

  const [journeyState, dispatchJourney] = useReducer(
    JourneyPlannerReducer,
    JourneyPlannerState(initialJourney)
  );

  const [isPlannerOpen, setIsPlannerOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { hasPermission } = useAuth();
  const [defaultUnitLength] = useState(() => loadDefaultUnitLength());
  const [conceptDialogOpen, setConceptDialogOpen] = useState(shipmentConcept && !shipmentConcept.fresh && (!id || copy));
  const [isJourneyChanged, setIsJourneyChanged] = useState(true);
  const [availableTerms, setAvailableTerms] = useState([]);

  const apiClient = useApiClient();

  const {
    isLoading: isExchangeRatesLoading,
    exchangeRates
  } = useExchangeRates();

  const {
    isLoading: isGoogleMapsApiLoading,
    api: googleMapsApi
  } = useGoogleMapsApi();

  const checkIndexNumber = (indexNumber) => /^[0-9,a-z,A-Z,-]*$/.test(indexNumber) && indexNumber.length >= SHIPMENT_INDEX_NUMBER_MIN_LENGTH;

  const handleBack = (): void => {
    setIsPlannerOpen(true);
  };

  const handleJourneyComplete = (): void => {
    setIsPlannerOpen(false);
  };

  const storeDefaultLenghUnitUndefinedTemplate = (unit) => storeDefaultUniLength(unit);

  const handleSave = async (
    newShipment: Omit<ShipmentT, "journey">,
    setIsSubmiting
  ): Promise<void> => {
    // create new shipment
    if (!newShipment.isDraft && !checkIndexNumber(newShipment.indexNumber)) {
      enqueueSnackbar(t("webapp:outcomingOrder.form.error.indexNumber"), {
        variant: "error"
      });
      return;
    }

    const redirectPath = `/outcoming-orders?type=${ShipmentType.FORWARDED}`

    if (id === undefined || copy) {
      const state = newShipment.isDraft ? ShipmentState.QUEUE : newShipment.state;
      const shipment = {
        ...newShipment,
        state,
        journey: copy && journeyState === initialJourney
          ? { waypoints: dehydrateWaypoints(journeyState.waypoints), duration: journeyState.duration, distance: journeyState.distance }
          : journeyState,
        termsId: newShipment.isDraft ? (
          newShipment.terms ? newShipment.terms.termsId : undefined
        ) : newShipment.terms.termsId
        ,
        incomingOrderIds: shipmentConcept.incomingOrderIds
      };

      try {
        await apiClient.outcomingOrder.postOutcomingOrder({ outcomingOrder: shipment });
        setShipmentConcept(null);
        enqueueSnackbar(t("outcomingOrders.create.success"), { variant: "success" });
        history.push(redirectPath);

      } catch (error) {
        console.error(error);
        const { response } = error;
        const data = response?.data || {};
        setIsSubmiting(false);

        const errorToMessageMapping = {
          "shipment/invalid-index-number-length": "outcomingOrders.create.error.invalidIndexNumberLength",
          "shipment/index-number-already-exists": "outcomingOrders.create.error.indexNumberAlreadyExists",
          "shipment/invalid-index-number": "webapp:outcomingOrders.form.error.indexNumber",
          "journey/no-point": "outcomingOrders.create.error.noPoint",
          "journey/unsufficient-point-count": "outcomingOrders.create.error.unsufficientPointCount",
          "journey/no-journey": "outcomingOrders.create.error.noJourney",
          "cargo/loaded-more-than-once": "outcomingOrders.create.error.loadedMoreThanOnce",
          "cargo/unloaded-before-loaded": "outcomingOrders.create.error.cargoUnloadedBeforeLoaded",
          "cargo/unloaded-more-than-once": "outcomingOrders.create.error.cargoUnloadedMoreThanOnce",
          "cargo/action-error": "outcomingOrders.create.error.cargoActionUnknown",
          "cargo/not-unloaded": "outcomingOrders.create.error.cargoNotUnloaded",
          "default": "outcomingOrders.create.error.general",
        };
        enqueueSnackbar(t(errorToMessageMapping[data.code] || errorToMessageMapping["default"]), { variant: "error" });
      }

    } else {
      const shipment = {
        ...newShipment,
        journey: isJourneyChanged
          ? journeyState
          : undefined,
        paidAt: undefined
      };

      try {
        await putOutcomingOrderById(id, shipment);
        enqueueSnackbar(t("outcomingOrders.update.success"), { variant: "success" });
        history.push(redirectPath);

      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("outcomingOrders.update.error.general"), { variant: "error" });
      }
    }
  };

  const fetchContact = (id): Promise<any> => apiClient.contact.getContact({ contactId: id });

  const fetchShipmentData = async (): Promise<void> => {
    // new shipment
    if (id === undefined) {
      const indexNumber = await apiClient.outcomingOrder.getNextOutcomingOrderNumber();

      const incomingOrderJourneys = await apiClient.incomingOrder.postIncomingOrderFindJourneysByIds({
        incomingOrderIds: shipmentConcept.incomingOrderIds
      });

      let n = 0;
      const { journey } = incomingOrderJourneys[0];
      journey.waypoints = incomingOrderJourneys
        .reduce((a, i) => [...a, ...i.journey.waypoints], [])
        .sort((a, b) => a.arriveAtFrom - b.arriveAtFrom)
        .reduce((a, i) => {
          if (n) {
            const j = a[n - 1];

            if (j.place.googleId === i.place.googleId) {
              a[n - 1] = {
                ...j,
                cargo: [...i.cargo, ...j.cargo],
                arriveAtFrom: i.arriveAtFrom < j.arriveAtFrom ?
                  i.arriveAtFrom : j.arriveAtFrom,
                arriveAtTo: i.arriveAtTo < j.arriveAtTo ?
                  j.arriveAtTo : i.arriveAtTo,
                contact: `${i.contact}, ${j.contact}`,
                note: `${i.note}, ${j.note}`,
              }

              return a;
            }
          }

          ++n;
          return [...a, i];
        }, []);

      let carrier;

      if (carrierId) {
        carrier = await fetchContact(carrierId);
      }

      const termsArray = [];
      let loadedTerms;
      const userTerms = await readUserTerms();
      if (Object.keys(userTerms).length !== 0) {
        termsArray.push(userTerms);
        loadedTerms = userTerms;
      }
      const companyTerms = await readCompanyTerms();

      if (Object.keys(companyTerms).length !== 0) {
        termsArray.push(companyTerms);
        if (!loadedTerms) {
          loadedTerms = companyTerms;
        }
      }
      setAvailableTerms(termsArray);

      // adjust datetime of waypoints relatively
      const referenceDate = journey.waypoints[0].arriveAtFrom;
      const newReferenceDate = pipe(
        (date) => setDate(date, getDate(new Date())),
        (date) => setMonth(date, getMonth(new Date())),
        (date) => setYear(date, getYear(new Date())),
      )(referenceDate);

      journey.waypoints = journey.waypoints.map((waypoint) => {
        const { arriveAtFrom, arriveAtTo } = waypoint;
        const differenceInMsFrom = differenceInMilliseconds(arriveAtFrom, referenceDate);
        const differenceInMsTo = arriveAtTo ? differenceInMilliseconds(arriveAtTo, arriveAtFrom) : -1;
        const todaysFrom = addMilliseconds(newReferenceDate, differenceInMsFrom);
        const todaysTo = differenceInMsTo === -1 ? undefined : addMilliseconds(todaysFrom, differenceInMsTo);
        waypoint.arriveAtFrom = todaysFrom;
        waypoint.arriveAtTo = todaysTo;
        return waypoint;
      });

      const waypoints = hydrateWaypoints(journey.waypoints);

      const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };
      const journeyData = {
        cargoItemSeed: getInitialCargoItemSeed(waypoints),
        cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
        waypointSeed: getInitialWaypointSeed(waypoints),
        waypoints: fetchedJourney.waypoints,
        errors: Array(fetchedJourney.waypoints.length).fill({}),
        distance: fetchedJourney.distance,
        duration: fetchedJourney.duration
      };

      setIsJourneyChanged(false);
      setInitialJourney(fetchedJourney);
      dispatchJourney({
        type: JourneyPlannerAction.CHANGE_JOURNEY,
        ...journeyData
      });
      const newShipment = {
        indexNumber,
        incomingOrders: shipmentConcept.incomingOrders,
        carrierContact: carrier || shipmentConcept.carrierContact,

        driverContact: "",
        vehicleInfo: "",
        notes: "",

        terms: loadedTerms || {},

        carrierPrice: "",
        carrierPriceCurrency: Currency.CZK,
        carrierPaymentDueDays: "60",
        isCarrierPriceWithDph: false,

        commissionCurrency: Currency.CZK,

        services: [{ name: "Doprava", price: 0, priceCurrency: "CZK", isPriceWithDph: false }],
        documents: [],
        isDraft: true
      };

      setShipment(newShipment);
      setShipmentConcept({
        ...shipmentConcept,
        fresh: false,
        ...journeyData,
        ...newShipment
      });
    } else {
      if (copy) {
        const indexNumber = await apiClient.outcomingOrder.getNextOutcomingOrderNumber();
        const { journey, ...newShipment } = await apiClient.outcomingOrder.getOutcomingOrderById({ outcomingOrderId: id });
        newShipment.indexNumber = indexNumber;
        newShipment.documents = [];
        newShipment.isDraft = true;
        newShipment.state = ShipmentState.QUEUE;
        newShipment.incomingOrders = [];

        if (shipmentConcept && shipmentConcept.carrierContact) newShipment.carrierContact = shipmentConcept.carrierContact;

        const termsArray = [];
        let loadedTerms;
        const userTerms = await readUserTerms();
        if (Object.keys(userTerms).length !== 0) {
          termsArray.push(userTerms);
          loadedTerms = userTerms;
        }
        const companyTerms = await readCompanyTerms();

        if (Object.keys(companyTerms).length !== 0) {
          termsArray.push(companyTerms);
          if (!loadedTerms) {
            loadedTerms = companyTerms;
          }
        }
        setAvailableTerms(termsArray);

        // adjust datetime of waypoints relatively
        const referenceDate = journey.waypoints[0].arriveAtFrom;
        const newReferenceDate = pipe(
          (date) => setDate(date, getDate(new Date())),
          (date) => setMonth(date, getMonth(new Date())),
          (date) => setYear(date, getYear(new Date())),
        )(referenceDate);

        journey.waypoints = journey.waypoints.map((waypoint) => {
          const { arriveAtFrom, arriveAtTo } = waypoint;
          const differenceInMsFrom = differenceInMilliseconds(arriveAtFrom, referenceDate);
          const differenceInMsTo = arriveAtTo ? differenceInMilliseconds(arriveAtTo, arriveAtFrom) : -1;
          const todaysFrom = addMilliseconds(newReferenceDate, differenceInMsFrom);
          const todaysTo = differenceInMsTo === -1 ? undefined : addMilliseconds(todaysFrom, differenceInMsTo);
          waypoint.arriveAtFrom = todaysFrom;
          waypoint.arriveAtTo = todaysTo;
          return waypoint;
        });

        const waypoints = hydrateWaypoints(journey.waypoints);

        const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };
        const journeyData = {
          cargoItemSeed: getInitialCargoItemSeed(waypoints),
          cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
          waypointSeed: getInitialWaypointSeed(waypoints),
          waypoints: fetchedJourney.waypoints,
          errors: Array(fetchedJourney.waypoints.length).fill({}),
          distance: fetchedJourney.distance,
          duration: fetchedJourney.duration
        };

        setShipment({
          ...newShipment,
          orderSerialNumber: "",
          terms: loadedTerms || {}
        });
        setInitialJourney(fetchedJourney);
        dispatchJourney({
          type: JourneyPlannerAction.CHANGE_JOURNEY,
          ...journeyData
        });
        setShipmentConcept({
          ...journeyData,
          ...newShipment
        });
      }
      if (edit) {
        const { journey, ...newShipment } = await apiClient.outcomingOrder.getOutcomingOrderById({ outcomingOrderId: id });
        newShipment.indexNumber = newShipment.indexNumber
          ? newShipment.indexNumber
          : await apiClient.outcomingOrder.getNextOutcomingOrderNumber();

        newShipment.documents = newShipment.documents ? newShipment.documents.map(({ createdAt, ...doc }) => ({ createdAt: new Date(createdAt), ...doc })) : [];

        const termsArray = [];
        const userTerms = await readUserTerms();
        if (Object.keys(userTerms).length !== 0) {
          termsArray.push(userTerms);
        }
        const companyTerms = await readCompanyTerms();

        if (Object.keys(companyTerms).length !== 0) {
          termsArray.push(companyTerms);
        }
        setAvailableTerms(termsArray);

        const waypoints = hydrateWaypoints(journey.waypoints);
        const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };

        setIsJourneyChanged(false);
        setShipment({
          ...newShipment
        });
        setInitialJourney(fetchedJourney);
        dispatchJourney({
          cargoItemSeed: getInitialCargoItemSeed(waypoints),
          cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
          waypointSeed: getInitialWaypointSeed(waypoints),
          type: JourneyPlannerAction.CHANGE_JOURNEY,
          waypoints: fetchedJourney.waypoints,
          errors: Array(fetchedJourney.waypoints.length).fill({}),
          distance: fetchedJourney.distance,
          duration: fetchedJourney.duration
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchShipmentData();
        setIsLoading(false);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(t("outcomingOrders.load.error"), { variant: "error" });
      }
    })();
  }, []);

  useEffect(() => {
    if (JSON.stringify(journeyState.waypoints) !== JSON.stringify(initialJourney.waypoints)) {
      setIsJourneyChanged(true);
      onFormChange(journeyState);
    }
  }, [journeyState]);

  const onFormChange = (values) => {
    if ((id === undefined || copy) && !conceptDialogOpen) {
      const newShipmentConcept = { ...shipmentConcept, ...values, fresh: undefined };
      setShipmentConcept(newShipmentConcept);
      setShipment({ ...shipment, ...values });
    }
  };

  const content = (() => {
    if (isLoading || isExchangeRatesLoading || isGoogleMapsApiLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <>
        <JourneyPlanner
          className={classes.planner}
          googleMapsApi={googleMapsApi!}
          state={journeyState}
          dispatch={dispatchJourney}
          onComplete={handleJourneyComplete}
          mapTheme={CargoticMapTheme}
          defaultLengthUnit={defaultUnitLength}
          storeDefaultLengthUnit={storeDefaultLenghUnitUndefinedTemplate}
        />
        {!conceptDialogOpen
          ? (
            <Slide in={!isPlannerOpen} direction="left" unmountOnExit>
              <div className={classes.form}>
                <OutcomingOrderForm
                  className={classes.shipmentForm}
                  apiClient={apiClient}
                  exchangeRates={exchangeRates!}
                  // @ts-ignore
                  shipment={shipment}
                  journey={journeyState}
                  availableTerms={availableTerms}
                  onBack={handleBack}
                  onSave={handleSave}
                  isUpdating={edit}
                  onFormChange={onFormChange}
                  isJourneyChanged={isJourneyChanged}
                />
              </div>
            </Slide>
          )
          : null}
        <ShipmentConceptDialog
          t={t}
          isOpen={conceptDialogOpen}
          onClose={async (saveConcept) => {
            if (saveConcept) {
              dispatchJourney({
                cargoItemSeed: shipmentConcept.cargoItemSeed,
                cargoItemLinkSeed: shipmentConcept.cargoItemLinkSeed,
                waypointSeed: shipmentConcept.waypointSeed,
                type: JourneyPlannerAction.CHANGE_JOURNEY,
                waypoints: shipmentConcept.waypoints,
                errors: shipmentConcept.errors,
                distance: shipmentConcept.distance,
                duration: shipmentConcept.duration
              });
              const userTerms = await readUserTerms();
              let fetchedTerms;

              if (Object.keys(userTerms).length !== 0) {
                fetchedTerms = userTerms;
              } else {
                const terms = await readCompanyTerms();
                fetchedTerms = terms;
              }
              setShipment({
                ...shipment,
                ...shipmentConcept,
                terms: fetchedTerms || {}
              });
            } else {
              setShipmentConcept(null);
            }
            setConceptDialogOpen(false);
          }}
        />
      </>
    );
  })();

  return (
    <>
      <div className={classes.root}>
        {content}
      </div>
    </>
  );
}

export default OutcomingOrder;
