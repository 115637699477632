const EXAMPLE_DELIMITER = "";
const EM_DASH = "—";
const STATIC_CONFIG = {
    YEAR_2: new Date().getFullYear().toString().substr(-2),
    YEAR_4: new Date().getFullYear().toString(),
    USER_SEQUENCE_4: "4444",
    USER_SEQUENCE_2: "44",
    CUSTOMER_SEQUENCE_6: "666666",
    CUSTOMER_SEQUENCE_4: "6666",
    CUSTOMER_SEQUENCE_2: "66",
    DOCUMENT_SEQUENCE_6: "888888",
    DOCUMENT_SEQUENCE_4: "8888",
    DOCUMENT_SEQUENCE_2: "88",
    TRANSPORT_MODE_1: "S",
    MONTH_2: ("0" + (new Date().getMonth() + 1)).slice(-2),
    MONTH_1: new Date().getMonth() + 1
};

export const getExample = (sequenceItemCodes) => {
    return Array.isArray(sequenceItemCodes) && sequenceItemCodes.length > 0
        ? sequenceItemCodes
              ?.map((itemCode) => STATIC_CONFIG[itemCode])
              .join(EXAMPLE_DELIMITER)
        : EM_DASH;
};
