import React, { useMemo } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select, Tabs, Tab, Input
} from "@material-ui/core";
import { LinkField } from "../../common";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    [breakpoints.up("sm")]: {
      minWidth: 520
    }
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(4)
    }
  },
  progress: {
    marginTop: spacing(2)
  },
  formControl: {
    width: spacing(20),
    paddingTop: spacing(3)
  },
  selectTitle: {
    fontWeight: "600",
    marginBottom: spacing(1)
  },
  selectDepo: {
    fontWeight: "600",
    marginBottom: spacing(1),
    marginTop: spacing(1)
  }
}));

const ExportDialog = ({
  isOpen,
  isProcessing,
  title,
  startButtonLabel,
  closeButtonLabel,
  infoText,
  processingText,
  close,
  exportCsv,
  exportCsvWithPackages,
  exportXlsx,
  exportXlm,
  selectValue,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [currentSelectValue, setCurrentSelectValue] = useState();
  const [format, setFormat] = useState("csv");

  let content = null;

  if (isProcessing) {
    content = (
      <>
        <CircularProgress classes={{ root: classes.progress }} />
        <DialogContentText>{processingText}</DialogContentText>
      </>
    );
  }

  else {
    content = (
      <>
        <DialogContentText>{infoText}</DialogContentText>
        <FormControl className={classes.formControl}>
          <InputLabel>{t("language.title")}</InputLabel>
          <Select
            autoWidth
            defaultValue={language}
            onChange={(event) => {
              setLanguage(event.target.value);
            }}
          >
            <MenuItem value="cs-CZ">
              <ReactCountryFlag
                code="cz"
                svg
                styleProps={{
                  width: "24px",
                  height: "24px"
                }}
              />
            </MenuItem>
            <MenuItem value="en">
              <ReactCountryFlag
                code="gb"
                svg
                styleProps={{
                  width: "24px",
                  height: "24px"
                }}
              />
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>{t("format")}</InputLabel>
          <Select
            autoWidth
            defaultValue={'csv'}
            onChange={(event) => {
              setFormat(event.target.value);
              setCurrentSelectValue(event.target.value);
            }}
          >
            <MenuItem value="csv">
              CSV
            </MenuItem>
            <MenuItem value="csv-with-packages">
              CSV / s baliky
            </MenuItem>
            <MenuItem value="xlsx">
              XLSX
            </MenuItem>
            <MenuItem value="xml">
              XML
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }
  return (
    <Dialog classes={{ paper: classes.root }} open={isOpen} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isProcessing}
          onClick={() => {
            if (format === "csv") {
              exportCsv(language);
            } else if (format === "xlsx") {
              exportXlsx();
            } else if (format === 'xml') {
              exportXlm();
            } else if (format === 'csv-with-packages') {
              exportCsvWithPackages(language);
            }
          }}
        >
          {startButtonLabel}
        </Button>
        <Button color="primary" onClick={close}>
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
