import React, { useState, useEffect } from "react";

import {
  Button,
  TextField,
  makeStyles,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Place } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { PlaceSchema } from "../../../utility/validationSchema";
import SearchAutosuggestTextField from "../../../../../multiload/cargotic-webapp/component/SearchAutosuggestTextField";
import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import { normalizeGooglePlace } from "../../../../../multiload/cargotic-webapp/utility";
import {
  searchPlace,
  searchPlaceByCoordinates,
  suggestPlace,
  fetchGooglePlaceDetail,
  fetchPlaceByGooglePlaceId
} from "../../../places";


const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  abort: {
    background: palette.status.danger,
    marginRight: spacing(1),
    color: "white",
    "&:hover": {
      background: palette.status.danger,
      color: "white"
    }
  },
  actionButtonGroup: {
    justifyContent: "flex-end",
    flexDirection: "row",
    display: "flex",
    padding: spacing(2),
    marginTop: "auto",
    borderTop: "1px solid silver"
  },
}));

const PlaceCreateDialog = ({ open, handleClose, handleSearchFail, handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    api: googleMapsApi
  } = useGoogleMapsApi();

  const googlePlaceService = new google.maps.places.PlacesService(document.createElement('div'));
  const googleGeocoderService = new googleMapsApi.Geocoder();
  const googleAutocompleteService = new googleMapsApi.places.AutocompleteService();

  const [placesService, setPlacesService] = useState(googlePlaceService);
  const [geocoderService, setGeocoderService] = useState(googleGeocoderService);
  const [addressSuggested, setAddressSuggested] = useState(true);
  const [address, setAddress] = useState();

  const onPlaceChange = async (setValues, values, place) => {
    const normalizedPlace = await normalizeGooglePlace(placesService, place.place_id);

    setAddress(normalizedPlace);

    setAddressSuggested(true);
    setValues({ ...values, address: place.description });
  }

  const haveAddressError = (errors, touched) => errors.address && touched.address || !addressSuggested;

  return (
    <Formik
      validationSchema={PlaceSchema}
      initialValues={{
        alias: "",
        address: ""
      }}
      onSubmit={({ alias }, { setSubmitting }) => {
        handleSubmit(alias, address);
        setSubmitting(false);
      }}
    >
      {({
        handleBlur,
        handleChange,
        isSubmitting,
        submitForm,
        errors,
        values,
        touched,
        setValues
      }) => (
          <div className={classes.form}>
            <form className={classes.container}>
              <Typography variant="h4">{t("places.create")}</Typography>
              <TextField
                required
                name="alias"
                value={values.alias}
                error={errors.alias && touched.alias}
                helperText={touched.alias && t(errors.alias)}
                inputProps={{ maxLength: 128 }}
                label={t("places.alias")}
                onChange={handleChange}
                fullWidth
                onBlur={handleBlur}
              />
              <SearchAutosuggestTextField
                required
                fullWidth
                hideCargoPlaces
                name="address"
                text={values.address ?? ""}
                value={address}
                helperText={haveAddressError(errors, touched) && t(errors.address)}
                error={haveAddressError(errors, touched)}
                label={t("places.address")}
                renderSuggestion={({ description }) => (
                  <>
                    <ListItemIcon>
                      <Place />
                    </ListItemIcon>
                    <ListItemText primary={description} />
                  </>
                )}
                search={(text) => searchPlace(placesService, text)}
                searchByCoordinates={(latitude, longitude) =>
                  searchPlaceByCoordinates(geocoderService, placesService, latitude, longitude)}
                onSearchFail={handleSearchFail}
                suggest={(text) => suggestPlace(googleAutocompleteService, text)}
                onTextChange={(text) => {
                  setValues({ ...values, address: text });
                  setAddressSuggested(false);
                }}
                onValueChange={(place) => onPlaceChange(setValues, values, place)}
              />
            </form>
            <div className={classes.actionButtonGroup}>
              <Button onClick={handleClose} variant="contained" className={classes.abort}>
                {t("abort")}
              </Button>
              <Button onClick={submitForm} variant="contained" color="primary" disabled={isSubmitting}>
                {t("create")}
              </Button>
            </div>
          </div>
        )}
    </Formik>
  );
};

export default PlaceCreateDialog;
