import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import { cs, enUS } from "date-fns/locale";

import { VehicleIncomeMatchType } from "@cargotic/model";
import {
  makeStyles,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Link,
  Grow
} from "@material-ui/core";

import {
  CreditCard as CreditCardIcon,
  Delete,
  DoubleArrow,
  Edit,
  LocalShipping as LocalShippingIcon
} from "@material-ui/icons";

import { localizeMonth } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ spacing }) => ({
  opacityUp: {
    opacity: 1,
    transition: "opacity .25s ease-in-out"
  },
  opacityDown: {
    opacity: 0,
    transition: "opacity .25s ease-in-out"
  }
}));

const VehicleIncomeTableGroup = ({
  month,
  entries,
  onEditClick,
  onDeleteClick,
  isIncomeEditationDisabled,
  isIncomeDeletionDisabled
}) => {
  const classes = useStyles();
  const { t, i18n: { language } } = useTranslation();
  const [{ income: { accountedAt: firstAccountedAt } }] = entries;
  const formatedGroup = `${localizeMonth(firstAccountedAt.getMonth(), language)} ${firstAccountedAt.getFullYear()}`;

  const IncomeTableRow = ({ type, income }) => {
    const [hovered, setHovered] = useState(false);
    const {
      accountedAt,
      createdAt,
      description,
      value,
      currency,
      shipmentId,
      shipmentSerialNumber
    } = income;

    const locale = t("locale") === "cs" ? cs : enUS;
    const formatDate = (date) => format(
      date,
      'dd. MMMM yyyy',
      { locale }
    );

    return (
      <TableRow
        component={Paper}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <TableCell align="center">
          {
            type === VehicleIncomeMatchType.DIRECT_INCOME
              ? (
                <CreditCardIcon color="action" />
              ) : (
                <LocalShippingIcon color="action" />
              )
          }
        </TableCell>
        <TableCell align="center" colspan={2}>
          {
            type === VehicleIncomeMatchType.DIRECT_INCOME
              ? (
                <Typography>
                  {description}
                </Typography>
              ) : (
                <Link component={RouterLink} to={`/shipment/${shipmentId}`}>
                  {`${t("shipments.shipment")} #${shipmentSerialNumber}`}
                </Link>
              )
          }
        </TableCell>
        <TableCell align="center">{`${value} ${currency}`}</TableCell>
        <TableCell align="center">{formatDate(accountedAt)}</TableCell>
        <TableCell align="center">
          {formatDate(createdAt)}
        </TableCell>
        <TableCell align="center" padding="none" className={hovered ? classes.opacityUp : classes.opacityDown}>
          <IconButton
            size="small"
            onClick={() => onEditClick(income)}
            style={{ marginRight: "10%" }}
            disabled={type === VehicleIncomeMatchType.SHIPMENT_INCOME || isIncomeEditationDisabled}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => onDeleteClick(income)}
            disabled={type === VehicleIncomeMatchType.SHIPMENT_INCOME || isIncomeDeletionDisabled}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={8}>
          <Typography variant="subtitle1" style={{ fontWeight: 700, marginLeft: 20 }} >
            {formatedGroup}
          </Typography>
        </TableCell>
      </TableRow>
      {
        entries.map(({ income, type }) => (
          <IncomeTableRow type={type} income={income} />
        ))
      }
    </>
  );
};

export default VehicleIncomeTableGroup;
