import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const UserDeleteDialog = ({
  open,
  selected,
  handleClose,
  handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t("users.delete.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("users.delete.content", { selected })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("abort")}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t("remove")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
};


export default UserDeleteDialog;