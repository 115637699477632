import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import {
  AttachFile as AttachFileIcon,
  InsertEmoticon as InsertEmoticonIcon
} from "@material-ui/icons";

import UserAvatar from "../UserAvatar";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginLeft: spacing(2),

    "& > section:first-child": {
      display: "flex",

      "& > :last-child": {
        flex: 1,

        marginLeft: spacing(2)
      }
    },
    "& > section:last-child": {
      display: "flex",
      justifyContent: "flex-end",

      marginTop: spacing(1),

      "& > :not(:first-child)": {
        marginLeft: spacing(1)
      }
    }
  }
}));

const ActivityTimelineCommentaryInput = ({
  author,
  isDisabled,
  onSubmit
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [content, setContent] = useState("");

  const handleContentChange = ({ target: { value } }) => (
    setContent(value)
  );

  const handleSubmitButtonClick = () => {
    if (content.trim() === "") {
      return;
    }

    setContent("");

    if (onSubmit) {
      onSubmit(content);
    }
  };

  return (
    <div className={classes.root}>
      <section>
        <div>
          <UserAvatar user={author} />
        </div>
        <div>
          <TextField
            label={t("webapp:social.title.writeCommentary")}
            value={content}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            disabled={isDisabled}
            onChange={handleContentChange}
          />
        </div>
      </section>
      <section>
        <Tooltip
          title={t("webapp:social.title.insertEmoji")}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span>
            <IconButton size="small" disabled>
              <InsertEmoticonIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t("webapp:social.title.attachFile")}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span>
            <IconButton size="small" disabled>
              <AttachFileIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Button
          color="primary"
          variant="contained"
          disabled={isDisabled}
          onClick={handleSubmitButtonClick}
        >
          {t("webapp:common.title.submit")}
        </Button>
      </section>
    </div>
  );
};

export default ActivityTimelineCommentaryInput;
