
import React from "react";

import { Grid, Tooltip, Typography } from "@material-ui/core";

import {
  getFormattedPlaceName,
} from "../../../utility/waypoint";

const IncomingOrdersRouteContact = ({ incomingOrder }) => (
  <Grid key={incomingOrder.id} container>
    {incomingOrder.journey.waypoints.map((waypoint, index) => {
      const contact = waypoint.contact;
      return (
        <Grid key={index} item xs={12}>
          <Typography variant="body2">{contact}</Typography>
        </Grid>
      );
    })}
  </Grid>
);

export default IncomingOrdersRouteContact;