
import React from "react";

import { ShipmentState } from "@cargotic/model";
import { Select, MenuItem, IconButton, makeStyles } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing, transitions, palette }) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 8
  },
  cardIndexNumber: {
    fontFamily: "Barlow",
    fontSize: 13,
    fontWeight: 600
  }
}));

const BoardCardHeader = ({
  t,
  id,
  isLastState,
  state,
  indexNumber,
  onStateChange,
  moveToNextState,
  currentState,
  hasUpdateShipmentPermission
}) => {
  const classes = useStyles();
  return (
    <div className={classes.header} >
      <span className={classes.cardIndexNumber}>#{indexNumber}</span>
      {hasUpdateShipmentPermission ?
        <span
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => e.stopPropagation()} >
          <Select
            label={t("board.state.title")}
            onChange={onStateChange}
            disableUnderline
            variant="standard"
            value={currentState}
            style={{ marginRight: !isLastState ? 0 : 8 }}
          >
            <MenuItem value={ShipmentState.QUEUE}>{t("board.state.queue")}</MenuItem>
            <MenuItem value={ShipmentState.READY}>{t("board.state.ready")}</MenuItem>
            <MenuItem value={ShipmentState.IN_PROGRESS}>{t("board.state.inProgress")}</MenuItem>
            <MenuItem value={ShipmentState.DONE}>{t("board.state.done")}</MenuItem>
          </Select>
          {!isLastState ?
            <IconButton onClick={moveToNextState} style={{ padding: 5 }} >
              <NavigateNext />
            </IconButton>
            : null}
        </span>
        : null}
    </div>
  );
}

export default BoardCardHeader;