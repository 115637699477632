import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import UserSelectEditorSelect from "./UserSelectEditorSelect";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: spacing(50),
    height: spacing(60)
  }
}));

const UserSelectEditor = ({
  user: initialUser,
  isOpen,
  onClose,
  onSubmit,
  permissions
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [user, setUser] = useState(initialUser);

  const handleUserChange = value => setUser(value);

  const handleSubmitButtonClick = () => {
    console.log("user:", user)
    if (onSubmit) {
      onSubmit(user);
    }
  };

  useEffect(() => {
    setUser(initialUser);
  }, [isOpen]);

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {permissions.includes("resource.user.role.permission.driver") && <>{t("webapp:vehicle.title.assign-driver")}</>}
        {permissions.includes("resource.user.role.permission.warehouseman") && <>{t("warehouses.warehouseman.assign")}</>}
      </DialogTitle>
      <UserSelectEditorSelect value={user} permissions={permissions} onChange={handleUserChange} />
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmitButtonClick}>
          {permissions.includes("resource.user.role.permission.driver") && <>{t("webapp:common.title.save")}</>}
          {permissions.includes("resource.user.role.permission.warehouseman") && <> {t("assign")}</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSelectEditor;
