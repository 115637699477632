import { readCurrentExchangeRates } from "../cargotic-webapp/resource";

enum Currency {
  CZK = "CZK",
  EUR = "EUR"
}

interface ExchangeRateItem {
  id: number;
  rate: number;
  sourceCurrency: Currency;
  targetCurrency: Currency;
  createdAt: Date;
}

interface ExchangeRates extends Array<ExchangeRateItem>{}

async function fetchExchangeRates(
  baseCurrency: Currency
): Promise<ExchangeRates> {
  const data = await readCurrentExchangeRates(baseCurrency);
  return data;
}

function convertCurrency(
  value: number,
  sourceCurrency: Currency,
  targetCurrency: Currency,
  exchangeRates: ExchangeRates
): number {
  if (sourceCurrency === targetCurrency) {
    return value;
  }
  const { rate } = exchangeRates.find(({ sourceCurrency: source, targetCurrency: target }) => source === sourceCurrency && target === targetCurrency);

  return value * rate;
}

export {
  Currency,
  ExchangeRates,

  convertCurrency,
  fetchExchangeRates
};
