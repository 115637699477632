import React from "react";

import { makeStyles } from "@material-ui/core";

import { RouterBreadcrumbs } from "../../../cargotic-webapp-component";
import ContactSharing from "../ContactSharing";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  }
}));

const ContactProfileHeader = ({
  breadcrumbs,
  contact: {
    isPrivate,
    createdBy: {
      id: creatorId
    }
  },
  shares,
  isPublicizeConfirmationAlertOpen,
  isShareEditorOpen,
  onPublicization,
  onPublicizeConfirmationAlertClose,
  onPublicizeConfirmationAlertSubmit,
  onShareEditorClose,
  onShareEditorOpen,
  onShareEditorSubmit
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RouterBreadcrumbs value={breadcrumbs} />
      <ContactSharing
        shares={shares}
        contactCreatorId={creatorId}
        isContactPrivate={isPrivate}
        isPublicizeConfirmationAlertOpen={isPublicizeConfirmationAlertOpen}
        isShareEditorOpen={isShareEditorOpen}
        onPublicizeButtonClick={onPublicization}
        onPublicizeConfirmationAlertClose={onPublicizeConfirmationAlertClose}
        onPublicizeConfirmationAlertSubmit={onPublicizeConfirmationAlertSubmit}
        onShareEditorClose={onShareEditorClose}
        onShareEditorOpen={onShareEditorOpen}
        onShareEditorSubmit={onShareEditorSubmit}
      />
    </div>
  );
};

export default ContactProfileHeader;
