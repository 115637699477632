
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { TextField, Button, InputAdornment, IconButton, makeStyles } from "@material-ui/core";
import { Search, Clear, Add } from "@material-ui/icons";


const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    marginRight: 20
  },
  field: {
    marginBottom: 0,
    marginRight: 10,

    "& > .MuiOutlinedInput-root": {
      height: "48px"
    }
  }
}));

const VehicleIncomeHeader = ({ search, onSearchChange, onAddButtonClick, isIncomeCreationDisabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <TextField
        className={classes.field}
        variant="outlined"
        value={search}
        onChange={({ target: { value } }) => onSearchChange(value)}
        label={t("webapp:vehicle.title.search-income")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disabled={search === ""}
                onClick={() => onSearchChange("")}
              >
                {
                  search === ""
                    ? <Search color="action" />
                    : <Clear />
                }
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={isIncomeCreationDisabled}
          startIcon={(
            <Add />
          )}
          onClick={onAddButtonClick}
        >
          {t("webapp:vehicle.title.add-income")}
        </Button>
      </div>
    </div>
  );
};

export default VehicleIncomeHeader;
