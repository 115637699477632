import React, { useEffect, useState } from "react";
import useOutcomingOrderConcept from "../../../component/hook/useOutcomingOrderConcept";
import OutcomingOrder from "../OutcomingOrder";
import IncomingOrderMultiloading from "../IncomingOrderMultiloading";
import useRouter from "../../../component/hook/useRouter";

enum View {
    NONE,
    OUTCOMING_ORDER,
    OUTCOMING_ORDER_MULTILOADING
}

const OutcomingOrderView = () => {
  const { outcomingOrder } = useOutcomingOrderConcept();
  const { match: { params: { id } } } = useRouter();
  const [view, setView] = useState(View.NONE);

  useEffect(() => {
    if (!view) {
      setView(
        id || outcomingOrder
          ? View.OUTCOMING_ORDER
          : View.OUTCOMING_ORDER_MULTILOADING
      );
    }
  }, [view, outcomingOrder]);

  switch (view) {
  case View.OUTCOMING_ORDER:
    return <OutcomingOrder />;

  case View.OUTCOMING_ORDER_MULTILOADING:
    return <IncomingOrderMultiloading />;

  default:
    return null;
  }
};

export default OutcomingOrderView;
