import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import VehicleExpirationTableItem from "./VehicleExpirationTableItem";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > :not(:first-child)": {
      marginTop: spacing(2)
    }
  }
}))

const VehicleExpirationTableBody = ({
  className,
  expirations,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  onExpirationEditButtonClick,
  onExpirationDeleteButtonClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const now = new Date();

  return (
    <div className={clsx(classes.root, className)}>
      {expirations.map((expiration, index) => {
        const { id } = expiration;

        const handleDeleteButtonClick = event => {
          if (onExpirationDeleteButtonClick) {
            onExpirationDeleteButtonClick(event, index);
          }
        };

        const handleEditButtonClick = event => {
          if (onExpirationEditButtonClick) {
            onExpirationEditButtonClick(event, index);
          }
        };

        return (
          <VehicleExpirationTableItem
            key={id}
            expiration={expiration}
            isDeleteButtonDisabled={isDeleteButtonDisabled}
            isEditButtonDisabled={isEditButtonDisabled}
            onDeleteButtonClick={handleDeleteButtonClick}
            onEditButtonClick={handleEditButtonClick}
          />
        );
      })}
    </div>
  );
};

export default VehicleExpirationTableBody;
