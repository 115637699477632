import { ContactType, TimePeriod } from "@cargotic/model";
import { sleep } from "@cargotic/common";

import convertFileToBase64 from "./convertFileToBas64";
import unreliable from "./unreliable";

const createDummyClient = ({ errorRate = 0, latency = 0 } = {}) => {
  const client = {
    contact: {
      postContactAvatar: async ({ contactId, avatarFile }) => {
        const avatarUrl = await convertFileToBase64(avatarFile);

        return {
          avatarUrl
        };
      },
      getContactExistence: () => undefined,
      getContactPrefill: ({ ico, dic }) => ({
        dic: "asdf",
        name: "Tesla Inc.",
        businessAddress: "45500 Fremont Blvd Fremont"
      }),
      postContactShares: ({ contactId, shares }) => ({

      }),
      getContactShares: ({ contactId }) => ([

      ]),
      putContact: ({ contactId, contact }) => ({

      }),
      deleteContactEmployee: ({ contactId, employeeId }) => ({

      }),
      deleteContactCommentary: ({ contactId, commentaryId }) => ({

      }),
      getContact: ({ contactId }) => ({
        id: contactId,
        ico: "08492042",
        dic: "CZ08492042",
        email: "Press@tesla.com",
        phoneNumber: "603 716 991",
        type: ContactType.CARRIER,
        name: "Tesla Inc.",
        avatarUrl: "https://storage.googleapis.com/webdesignledger.pub.network/WDL/12f213e1-t1.jpg",
        businessAddress: "Rudolf-Diesel-Strasse 1454595 Prüm, Germany",
        billingAddress: "45500 Fremont Blvd Fremont",
        website: "tesla.com",
        billingContact: "Elon Musk",
        insuranceExpiresAt: new Date("2020-12-04"),
        paymentDueDays: 21,
        isPrivate: true,
        createdBy: {
          id: 22,
          name: "Jakub Dostál",
          avatarUrl: "https://i.scdn.co/image/ab67616d00001e0226c39e72973df4691d8dd637"
        },
        tags: [{"value":"Itálie","type":"GEOGRAPHICAL","geopoliticalType":"country","id":7},{"value":"Dopravce","type":"CONTACT_TYPE","geopoliticalType":null,"id":2},{"value":"Španělsko","type":"GEOGRAPHICAL","geopoliticalType":"country","id":16},{"value":"LKW","type":"VEHICLE_TYPE","geopoliticalType":null,"id":49}]
      }),
      getContactActivity: ({ contactId }) => ([
        { type: "CONTACT_CREATE", user: { id: 1, name: "Jakub Dostál", avatarUrl: "https://i.scdn.co/image/ab67616d0000b2731da538ded5176a399722e24a" }, createdAt: new Date() },
        { type: "CONTACT_COMMENTARY", commentary: { author: { id: 1, name: "Jakub Dostál", avatarUrl: "https://i.scdn.co/image/ab67616d0000b2731da538ded5176a399722e24a" }, content: "asdf", createdAt: new Date() } },
        { type: "CONTACT_EMPLOYEE_CREATE", user: { id: 1, name: "Jakub Dostál", avatarUrl: "https://i.scdn.co/image/ab67616d0000b2731da538ded5176a399722e24a" }, createdAt: new Date() },
        { type: "CONTACT_EMPLOYEE_DELETE", user: { id: 1, name: "Jakub Dostál", avatarUrl: "https://i.scdn.co/image/ab67616d0000b2731da538ded5176a399722e24a" }, createdAt: new Date() },
        { type: "CONTACT_EMPLOYEE_UPDATE", user: { id: 1, name: "Jakub Dostál", avatarUrl: "https://i.scdn.co/image/ab67616d0000b2731da538ded5176a399722e24a" }, createdAt: new Date() },
        { type: "CONTACT_UPDATE", user: { id: 1, name: "Jakub Dostál", avatarUrl: "https://i.scdn.co/image/ab67616d0000b2731da538ded5176a399722e24a" }, createdAt: new Date() }
      ]),
      postContactEmployee: ({ contactId, employee }) => ({

      }),
      putContactEmployee: ({ contactId, employeeId, employee }) => ({

      }),
      postContactEmployeeMatchQuery: async ({ contactId }) => ({
        total: 100,
        matches: [{
          id: 234,
          name: "Petr Vomáčka",
          phoneNumber: "603 716 991",
          email: "asdf@asdf.cz"
        },
        { id: 211, phoneNumber: "728 324 219" },
        { id: 2, email: "asdf@kekel.cz" }
        ]
      }),
      postContactCommentary: ({ contactId, commentary }) => ({
        id: Math.random(),
        createdAt: new Date()
      }),
      putContactCommentary: ({ contactId, commentaryId, commentary }) => ({
        id: commentaryId,
        createdAt: new Date()
      }),
      getContactShipmentsHighlight: async ({ contactId }) => {
        await sleep(500);

        return ({
          value: 3110,
          rateOfChange: 0.2
        });
      },
      getContactShipmentBalanceHighlight: async ({ contactId }) => {
        await sleep(500);

        return ({
          value: 2343,
          rateOfChange: 0.40
        });
      },
      getContactOrderBalanceHighlight: async ({ contactId }) => {
        await sleep(500);

        return ({
          value: 34124,
          rateOfChange: 0.32
        });
      }
    },
    user: {
      postUserMatchQuery: ({ match }) => ({
        total: 200,
        matches: [
          { id: 1, name: "Elon Musk", avatarUrl: "https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTY2MzU3Nzk2OTM2MjMwNTkx/elon_musk_royal_society.jpg" },
          { id: 2, name: "Gwynne Shotwell", avatarUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Gwynne_Shotwell_at_pre-launch_briefing_for_CRS-2_mission_%28KSC-2013-1704%29.jpg/800px-Gwynne_Shotwell_at_pre-launch_briefing_for_CRS-2_mission_%28KSC-2013-1704%29.jpg" },
          { id: 3, name: "Douglas Hurley" },
          { id: 4, name: "Elon Musk", avatarUrl: "https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTY2MzU3Nzk2OTM2MjMwNTkx/elon_musk_royal_society.jpg" },
          { id: 5, name: "Gwynne Shotwell", avatarUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Gwynne_Shotwell_at_pre-launch_briefing_for_CRS-2_mission_%28KSC-2013-1704%29.jpg/800px-Gwynne_Shotwell_at_pre-launch_briefing_for_CRS-2_mission_%28KSC-2013-1704%29.jpg" },
          { id: 6, name: "Douglas Hurley" }
        ]
      }),
      postUserSuggestQuery: ({ search, type }) => [
        { id: 1, name: "Elon Musk", avatarUrl: "https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTY2MzU3Nzk2OTM2MjMwNTkx/elon_musk_royal_society.jpg" },
        { id: 2, name: "Gwynne Shotwell", avatarUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Gwynne_Shotwell_at_pre-launch_briefing_for_CRS-2_mission_%28KSC-2013-1704%29.jpg/800px-Gwynne_Shotwell_at_pre-launch_briefing_for_CRS-2_mission_%28KSC-2013-1704%29.jpg" },
        { id: 3, name: "Douglas Hurley" }
      ]
    },
    vehicle: {
      deleteMonthlyVehicleExpenses: ({ vehicleId, year, month }) => ({ }),
      deleteVehicleCommentary: ({ vehicleId }) => ({ }),
      postVehicle: ({ vehicle }) => ({ ...vehicle, id: 12 }),
      postVehicleCommentary: ({ vehicleId, commentary: { content } }) => ({
        id: Math.round(Math.random() * 1000),
        createdAt: new Date()
      }),
      postVehicleAvatar: async ({ vehicleId, avatarFile }) => ({
        avatarUrl: await convertFileToBase64(avatarFile)
      }),
      putVehicleCommentary: ({ vehicleId, commentaryId, commentary }) => ({
        id: commentaryId,
        createdAt: new Date()
      }),
      getLatestVehicleExpirations: ({ vehicleId }) => ({
        FIRST_AID_KIT: {
          id: 122,
          expiresAt: new Date("2020-12-25T16:57:25.000Z")
        },
        VEHICLE_INSPECTION: {
          id: 123,
          expiresAt: new Date("2020-12-25T16:57:25.000Z")
        },
        VEHICLE_INSURANCE: {
          id: 124,
          expiresAt: new Date("2020-12-25T16:57:25.000Z")
        },
        VEHICLE_REGISTRATION_CERTIFICATE: {
          id: 125,
          expiresAt: new Date("2020-12-25T16:57:25.000Z")
        }
      }),
      getVehicle: ({ vehicleId }) => ({
        id: vehicleId,
        avatarUrl: "https://www.mojelektromobil.sk/wp-content/uploads/2017/10/tesla-semi-render-2.jpg",
        manufacturer: "Tesla",
        model: "Model S",
        vin: "WBAVB13506PT22180",
        licensePlate: "4GET 01L",
        trailerLicensePlate: "4GET 01L",
        manufacturedAt: new Date("2020-06-25"),
        defaultDriver: {
          id: 420,
          name: "Elon Musk",
          avatarUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Elon_Musk_2015.jpg/800px-Elon_Musk_2015.jpg"
        }
      }),
      getVehicleActivity: ({ vehicleId }) => [
        {"type":"VEHICLE_CREATE","createdAt": new Date("2020-06-19T11:31:47.000Z"),"user":{"id":22,"name":"Libor  Mrskal"}},
        {"type":"VEHICLE_UPDATE","createdAt": new Date("2020-06-19T16:48:45.000Z"),"user":{"id":22,"name":"Libor  Mrskal"},"properties":{"name":"hjk","vendor":"edwedwehjkkjwqeq","fuel":"gasoline","spz":"lwjededw","vin":"4864","cargoAreaLoad":460}},
        {"type":"VEHICLE_COMMENTARY", "commentary": { "createdAt": new Date("2020-06-22T13:50:15.000Z"), "id":4,"author":{"id":22,"name":"Jakub Dostál","avatarUrl":"http://localhost/api/v1/files/avatars/d292ae17eb638ce0282ec9e6a6505bafa1cc149f58fa07ef9aec04c6ec787a3c/b79d211f71b2142bfd59650169115cfa.jpeg"},"content":"qwerty" }},
        {"type":"VEHICLE_COMMENTARY", "commentary": { "createdAt": new Date("2020-06-25T14:05:15.000Z"), "id":6,"author":{"id":22,"name":"Jakub Dostál","avatarUrl":"http://localhost/api/v1/files/avatars/d292ae17eb638ce0282ec9e6a6505bafa1cc149f58fa07ef9aec04c6ec787a3c/b79d211f71b2142bfd59650169115cfa.jpeg"},"content":"qwerty" }},
        {"type":"VEHICLE_COMMENTARY", "commentary": { "createdAt": new Date("2020-06-25T16:57:25.000Z"), "id":5,"author":{"id":22,"name":"Jakub Dostál","avatarUrl":"http://localhost/api/v1/files/avatars/d292ae17eb638ce0282ec9e6a6505bafa1cc149f58fa07ef9aec04c6ec787a3c/b79d211f71b2142bfd59650169115cfa.jpeg"},"content":"qwe" }}
      ],
      getYearlyVehicleExpenses: ({ vehicleId, year }) => [
        {
          currency: "CZK",
          total: 325412,
          fixed: [
            { label: "Plat řidiče", value: 100 }
          ],
          variable: [
          ],
          rateOfChange: 1.2
        },
        {
          currency: "EUR",
          total: 20000,
          rateOfChange: -1.2,
          fixed: [],
          variable: []
        },
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ],
      getPreviousMonthlyVehicleExpenses: ({ vehicleId, year }) => null,
      postMonthlyVehicleExpenses: ({ vehicleId, year, month }) => ({ }),
      putMonthlyVehicleExpenses: ({ vehicleId, year, month }) => ({ }),
      putVehicle: ({ vehicleId, vehicl }) => ({ }),
      getVehicleHighlights: ({ vehicleId }) => ({
        travels: {
          value: 10,
          rateOfChange: 0.1,
          period: TimePeriod.CURRENT_MONTH
        },
        mileage: {
          value: 33240,
          rateOfChange: 0.5,
          period: TimePeriod.CURRENT_MONTH,
          unit: "KM"
        },
        expenses: {
          value: 233343,
          rateOfChange: -0.8,
          period: TimePeriod.CURRENT_MONTH,
          currency: "CZK"
        },
        profit: {
          value: 223,
          rateOfChange: 0.1,
          period: TimePeriod.CURRENT_MONTH,
          currency: "CZK"
        }
      }),
      postVehicleExpirationMatchQuery: ({ vehicleId, query }) => {
        const items = [
          {},
          {},
          {},
          {}
        ];

        return {
          total: 100,
          matches: [
            {
              id: 2,
              type: "FIRST_AID_KIT",
              expiresAt: new Date("04-12-30"),
              createdAt: new Date("04-12-29"),
              images: [
                {
                  id: 101,
                  name: "image.jpg",
                  url: "https://www.mwakilishi.com/sites/default/files/styles/large/public/2019-06/green-card.jpg?itok=hMmTVlad"
                }
              ]
            },
            {
              id: 2,
              type: "VEHICLE_INSPECTION",
              expiresAt: new Date("04-12-30"),
              createdAt: new Date("04-12-29"),
              images: [
                {
                  id: 102,
                  name: "image.jpg",
                  url: "https://i.etsystatic.com/10455839/r/il/966d28/1829559021/il_1140xN.1829559021_efxl.jpg"
                }
              ]
            }
          ]
        };
      },
      postVehicleExpirationImage: async ({ imageFile }) => ({
        name: imageFile.name,
        id: Math.random(),
        url: await convertFileToBase64(imageFile)
      }),
      postVehicleExpiration: () => ({

      }),
      putVehicleExpiration: () => ({

      }),
      deleteVehicleExpiration: () => ({

      })
    }
  };

  return Object.fromEntries(
    Object
      .entries(client)
      .map(([key, namespace]) => (
        [
          key,
          Object.fromEntries(
            Object
              .entries(namespace)
              .map(([name, callable]) => (
                [name, unreliable({ errorRate, latency })(callable)]
              ))
          )
        ]
      ))
  );
};

export default createDummyClient;
