const hexToRgba = (hex: string, alpha: number = 1) => {
  if (hex.length !== 4 && hex.length !== 7) {
    throw new Error("Invalid color hex value!");
  }

  const isShort = hex.length === 4;

  const red = parseInt(
    isShort
      ? hex.slice(1, 2).repeat(2)
      : hex.slice(1, 3),
    16
  );

  const green = parseInt(
    isShort
      ? hex.slice(2, 3).repeat(2)
      : hex.slice(3, 5),
    16
  );

  const blue = parseInt(
    isShort
      ? hex.slice(3, 4).repeat(2)
      : hex.slice(5, 7),
    16
  );

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export default hexToRgba;
