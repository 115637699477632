import React, { useEffect, useState } from "react";

import { Currency, ExchangeRates, fetchExchangeRates } from "../currency";
import ExchangeRatesContext from "./ExchangeRatesContext";

interface ExchangeRatesProviderProps {
  baseCurrency: Currency;

  children: React.ReactNode;
}

const FALLBACK_EXCHANGE_RATES = {
  rates: {
    CAD: 1,
    HKD: 1,
    ISK: 1,
    PHP: 1,
    DKK: 1,
    HUF: 1,
    CZK: 1,
    GBP: 1,
    RON: 1,
    SEK: 1,
    IDR: 1,
    INR: 1,
    BRL: 1,
    RUB: 1,
    HRK: 1,
    JPY: 1,
    THB: 1,
    CHF: 1,
    EUR: 1,
    MYR: 1,
    BGN: 1,
    TRY: 1,
    CNY: 1,
    NOK: 1,
    NZD: 1,
    ZAR: 1,
    USD: 1,
    MXN: 1,
    SGD: 1,
    AUD: 1,
    ILS: 1,
    KRW: 1,
    PLN: 1
  }
};

function ExchangeRatesProvider(
  { baseCurrency, children }: ExchangeRatesProviderProps
): React.ReactElement {
  const [exchangeRates, setExchangeRates] = useState<ExchangeRates>();
  const [isLoading, setIsLoading] = useState(true);

  const load = async (): Promise<void> => {
    let newExchangeRates;

    try {
      newExchangeRates = await fetchExchangeRates(baseCurrency);
    } catch (error) {
      console.log(error);

      newExchangeRates = {
        ...FALLBACK_EXCHANGE_RATES,
        base: baseCurrency
      };
    }

    setExchangeRates(newExchangeRates);
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [baseCurrency]);

  return (
    <ExchangeRatesContext.Provider value={{ isLoading, exchangeRates }}>
      {children}
    </ExchangeRatesContext.Provider>
  );
}

export default ExchangeRatesProvider;
