import { ApiClientProvider } from "@cargotic/api-client-deprecated";
import { Currency, ExchangeRatesProvider } from "@cargotic/currency";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { LocalizationProvider } from "@material-ui/pickers";
import { cs, enUS } from "date-fns/locale";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";

import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import Html5Backend from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Button from "@material-ui/core/Button";
import client, { axios } from "../../../client";
import i18n from "../../../i18n";
import config from "../../../config";

import AuthProvider from "../AuthProvider";
import UserProfileProvider from "../UserProfileProvider";
import ShipmentConceptProvider from "../ShipmentConceptProvider";
import IncomingOrderConceptProvider from "../IncomingOrderConceptProvider";
import BoltClientProvider from "../BoltClientProvider";

import App from "./App";
import TrackingApp from "./TrackingApp";
import SubmitTransportationDocumentsApp from "./SubmitTransportationDocumentsApp";
import store from "./store";
import theme from "./theme";
import { GoogleMapsApiProvider } from "../../../../../multiload/cargotic-map";
import { ApiClientProvider as ApiClientProvider2 } from "../../../../cargotic-webapp-component";
import IncomingOrderMultiselectSelectionProvider from "../IncomingOrderMultiselectSelectionProvider";
import OutcomingOrderConceptProvider from "../OutcomingOrderConceptProvider/OutcomingOrderConceptProvider";

const AppContainer = () => {
  const isTrackingLocation = window.location.href.includes("//tracking.");
  const isSubmitTransportationDocumentsLocation =
    window.location.href.includes("//documents.");
  const Content = () => {
    const { i18n } = useTranslation();

    const pickerLocale = isTrackingLocation
      ? cs
      : i18n.language === "en" || i18n.language === "en-US"
        ? enUS
        : cs;

    useEffect(() => {
      if (pickerLocale === cs) {
        axios.defaults.headers["Accept-Language"] = "cz";
      } else {
        axios.defaults.headers["Accept-Language"] = "en";
      }
    }, [pickerLocale]);

    if (isTrackingLocation) {
      return (
        <LocalizationProvider dateAdapter={DateFnsAdapter} locale={cs}>
          <TrackingApp />
        </LocalizationProvider>
      );
    }
    if (isSubmitTransportationDocumentsLocation) {
      return (
        <LocalizationProvider
          dateAdapter={DateFnsAdapter}
          locale={pickerLocale}
        >
          <SubmitTransportationDocumentsApp />
        </LocalizationProvider>
      );
    }
    return (
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={pickerLocale}>
        <App />
      </LocalizationProvider>
    );
  };

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  if (isTrackingLocation || isSubmitTransportationDocumentsLocation) {
    return (
      <ApiClientProvider2 client={client}>
        <ApiClientProvider client={{ baseUrl: config.api.urlV1 }}>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <BrowserRouter>
                <SnackbarProvider
                  maxSnack={10}
                  ref={notistackRef}
                  action={(key) => (
                    <Button onClick={onClickDismiss(key)}>
                      {i18next.t("snackbar.dismiss")}
                    </Button>
                  )}
                >
                  <Provider store={store}>
                    <DndProvider backend={Html5Backend}>
                      <CssBaseline />
                      <Content />
                    </DndProvider>
                  </Provider>
                </SnackbarProvider>
              </BrowserRouter>
            </I18nextProvider>
          </ThemeProvider>
        </ApiClientProvider>
      </ApiClientProvider2>
    );
  }
  return (
    <ApiClientProvider2 client={client}>
      <ApiClientProvider client={{ baseUrl: config.api.urlV1 }}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <SnackbarProvider
                maxSnack={10}
                ref={notistackRef}
                action={(key) => (
                  <Button onClick={onClickDismiss(key)}>
                    {i18next.t("snackbar.dismiss")}
                  </Button>
                )}
              >
                <AuthProvider>
                  <BoltClientProvider>
                    <UserProfileProvider>
                      <ShipmentConceptProvider>
                        <OutcomingOrderConceptProvider>
                          <IncomingOrderConceptProvider>
                            <IncomingOrderMultiselectSelectionProvider>
                              <Provider store={store}>
                                <GoogleMapsApiProvider
                                  apiKey={config.googleMaps.apiKey}
                                  libraries={["places"]}
                                >
                                  <ExchangeRatesProvider
                                    baseCurrency={Currency.CZK}
                                  >
                                    <DndProvider backend={Html5Backend}>
                                      <CssBaseline />
                                      <Content />
                                    </DndProvider>
                                  </ExchangeRatesProvider>
                                </GoogleMapsApiProvider>
                              </Provider>
                            </IncomingOrderMultiselectSelectionProvider>
                          </IncomingOrderConceptProvider>
                        </OutcomingOrderConceptProvider>
                      </ShipmentConceptProvider>
                    </UserProfileProvider>
                  </BoltClientProvider>
                </AuthProvider>
              </SnackbarProvider>
            </BrowserRouter>
          </I18nextProvider>
        </ThemeProvider>
      </ApiClientProvider>
    </ApiClientProvider2>
  );
};

export default AppContainer;
