import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, makeStyles, Fab, Tooltip } from "@material-ui/core";
import { Delete, Edit, DeleteOutline, Add } from "@material-ui/icons";

import ExchangeRateCard from "./ExchangeRateCard";
import DataTable from "../../../datatable";
import ExchangeRateMode from "./ExchangeRateMode";
import ExchangeRateEditor from "./ExchangeRateEditor";
import { SelectColumnsButton } from "@cargotic/webapp-component";

const useStyles = makeStyles(({ spacing, palette }) => ({

  root: {
    display: "flex",
    flexDirection: "column",

    "& > :not(:first-child)": {
      marginTop: spacing(4)
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      marginLeft: spacing(1)
    }
  },
  selectColumnsButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 0
  },
  content: {
    flex: 1
  },
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const SettingsCurrencyOverview = ({
  className,
  isLoading,

  exchangeRatesTableProps,
  exchangeRatesMode,

  handleSetExchangeRatesMode,
  handleCreateExchangeRateClick,
  handleUpdateExchangeRateClick,
  handleOpenDeleteExchangeRatesDialog,

  hasUpdateExchangeRatePermission,
  hasDeleteExchangeRatePermission,
  hasCreateExchangeRatePermission,
  hasUpdateExchangeRateModePermission,
  hasReadExchangeRatePermission
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const headers = [
    {
      name: "createdAt",
      label: "webapp:settings.title.createdAt",
      isOrderable: true
    },
    {
      name: "sourceCurrency",
      label: "webapp:settings.title.source-currency",
      isOrderable: true
    },
    {
      name: "rate",
      label: "webapp:settings.title.rate",
      isOrderable: true
    },
    {
      name: "targetCurrency",
      label: "webapp:settings.title.target-currency",
      isOrderable: true
    }
  ];

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => handleUpdateExchangeRateClick(id),
          icon: <Edit />,
          title: "webapp:settings.title.edit-exchange-rate",
          disableFor: !hasUpdateExchangeRatePermission ? [-1] : undefined
        }
      ]
    }
  ];

  const {
    data,
    dataCount,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    selectedColumns,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = exchangeRatesTableProps;

  const selectedItems = data.filter(({ selected }) => selected);


  if (isLoading) {
    return (
      <div className={clsx(className, classes.progress)}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <>
      <div className={clsx(className, classes.root)}>
        <ExchangeRateCard
          type={exchangeRatesMode}
          setType={handleSetExchangeRatesMode}
          hasUpdateExchangeRateModePermission={hasUpdateExchangeRateModePermission}
        />
        {hasReadExchangeRatePermission ? (
          <div>
            <div className={classes.selectColumnsButton}>
              <SelectColumnsButton onClick={setIsOpenSelectColumnsDialog} />
            </div>
            <div>
              <DataTable
                headers={headers}
                actions={exchangeRatesMode === ExchangeRateMode.AUTOMATIC ? [] : tableActions}
                data={data}
                dataCount={dataCount}
                loading={loading}
                ordering={ordering}
                selectedColumns={selectedColumns}
                direction={direction}
                checkedAll={checkedAll}
                page={page}
                rowsPerPage={rowsPerPage}
                onSort={handleSort}
                onSelect={handleSelect}
                onSelectAll={handleSelectAll}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangeSelectedColumns={handleChangeSelectedColumns}
                isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
                setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
              />
            </div>
          </div>) : undefined}
        {exchangeRatesMode === ExchangeRateMode.MANUAL ? (
          <div className={classes.actions}>
            <Tooltip title={t("webapp:settings.title.create-exchange-rate")}>
              <Fab
                color="primary"
                disabled={isLoading || !hasCreateExchangeRatePermission}
                onClick={handleCreateExchangeRateClick}
              >
                <Add />
              </Fab>
            </Tooltip>
            <Fab
              className={classes.danger}
              disabled={isLoading || selectedItems.length === 0 || !hasDeleteExchangeRatePermission}
              onClick={handleOpenDeleteExchangeRatesDialog}
            >
              <DeleteOutline />
            </Fab>
          </div>
        ) : undefined}
      </div>
    </>
  );
};

export default SettingsCurrencyOverview;
