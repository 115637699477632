import React, { useState, useEffect, useCallback } from "react";

import { Tabs, Tab, Fab } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { updateIndex } from "@cargotic/common-deprecated";

import { useTranslation } from "react-i18next";

import Page from "../cargotic-webapp/component/common/Page";
import useRouter from "../cargotic-webapp/component/hook/useRouter";
import Placeholder from "../cargotic-webapp/component/common/Placeholder";

import SwipeableDialog from "../cargotic-webapp/swipeableDialog";

import {
  mapAndFormatWaypoints,
  getWaypointsWithCargo,
} from "../cargotic-webapp/utility/waypoint";

import client from "../cargotic-webapp/client";

import { useApiClient as useNewApiClient } from "../cargotic-webapp-component";

import {
  getIncomingOrderActivity,
  getOutcomingOrderActivity,
  getWarehouseOrderActivity,
  getIncomingOrderById,
  getWarehouseOrderById,
  getOutcomingOrderById,
  readShipment
} from "../cargotic-webapp/resource";

import BasicInfo from "./BasicInfoTab";
import ActivityTab from "./ActivityTab";
import RouteAndCargo from "./RouteAndCargoTab";
import useAuth from "../cargotic-webapp/component/hook/useAuth";

const ENTITY_TYPES = {
  INCOMING_ORDER: "INCOMING_ORDER",
  OUTCOMING_ORDER: "OUTCOMING_ORDER",
  SHIPMENT: "SHIPMENT",
  WAREHOUSE_ORDER: "WAREHOUSE_ORDER",
}

const ERROR_ACTION = {
  SUB: 0,
  NOP: 0,
  ADD: 0,
};

export function ShipmentOverview({
  id,
  shipment: data,
  incomingOrder,
  outcomingOrder,
  warehouseOrderId,
  shipments,
  setShipments,
  onSave,
  onDraftSave,
  open,
  setOpen,
  type,
}) {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { user: { token } } = useAuth();
  const newApiClient = useNewApiClient();
  const directionsService = new google.maps.DirectionsService();

  const [isDriveThroughLoading, setIsDriveThroughLoading] = useState(false);
  const [nextShipmentNumber, setNextShipmentNumber] = useState();
  const [updateDriveThroughDialogOpen, setUpdateDriveThroughDialogOpen] =
    useState(false);
  const [updatingWaypoint, setUpdatingWaypoint] = useState({});

  const [currentTabValue, setCurrentTabValue] = useState(0);
  const [directions, setDirections] = useState([]);
  const [activity, setActivity] = useState([]);

  const [localShipments, setLocalShipments] = useState(shipments)

  // Temporary shipment -> order solution
  const [shipment, setShipment] = useState();

  // const shipmentWaypoints = useMemo(() => {
  //   const localShipmentIndex = localShipments.findIndex((localShipment) => localShipment.id === id)
  //   return localShipments[localShipmentIndex]?.journey?.waypoints
  // }, [localShipments, shipment])

  // const [formattedWaypoints, setFormattedWaypoints] = useState(
  //   mapAndFormatWaypoints(shipment.journey.waypoints, true, false)
  // );

  // const cargo = getWaypointsWithCargo(shipmentWaypoints);

  const fetchActivity = useCallback(async (id) => {
    const ACTIVITY_FETCHERS = {
      [ENTITY_TYPES.SHIPMENT]: (shipmentId) => newApiClient.shipment.getShipmentActivity({ shipmentId }),
      [ENTITY_TYPES.INCOMING_ORDER]: (incomingOrderId) => getIncomingOrderActivity({
        incomingOrderId,
      }),
      [ENTITY_TYPES.OUTCOMING_ORDER]: (outcomingOrderId) => getOutcomingOrderActivity({
        outcomingOrderId,
      }),
      [ENTITY_TYPES.WAREHOUSE_ORDER]: (warehouseOrderId) => getWarehouseOrderActivity({
        warehouseOrderId
      })
    }

    try {
      const activity = await ACTIVITY_FETCHERS[type](id);
      setActivity(activity);
    } catch (err) {
      console.error(err);
    }
  }, [
    newApiClient.shipment.getShipmentActivity,
    getIncomingOrderActivity,
    getOutcomingOrderActivity
  ])

  const fetchEntity = async (type, id) => {
    let entity;
    switch (type) {
      case "SHIPMENT":
        await fetchShipment(id);
        break;
      case "INCOMING_ORDER":
        await fetchIncomingOrder(id);
        break;
      case "WAREHOUSE_ORDER":
        await fetchWarehouseOrder(id);
        break;
      case "OUTCOMING_ORDER":
        await fetchOutcomingOrder(id);
        break;
      default:
        console.log('Err');
    }
  }

  const fetchIncomingOrder = async (id) => {
    const order = await getIncomingOrderById(id);
    setShipment(order);
  }

  const fetchShipment = async (id) => {
    const shipment = await readShipment(id);
    setShipment(shipment);
  }

  const fetchWarehouseOrder = async (id) => {
    const warehouseOrder = await getWarehouseOrderById(id);
    setShipment(warehouseOrder);
  }


  const fetchOutcomingOrder = async (id) => {
    const outcomingOrder = await getOutcomingOrderById(id);
    setShipment(outcomingOrder);
  }

  const loadNextShipmentNumber = useCallback(async () => {
    try {
      if (shipment.isDraft) {
        const number = await newApiClient.shipment.getNextShipmentNumber();
        setNextShipmentNumber(number);
      }
    } catch (e) {
      console.error(e)
    }
  }, [shipment, newApiClient.shipment.getNextShipmentNumber, setNextShipmentNumber])

  useEffect(() => {
    if (shipment) {
      loadNextShipmentNumber();
    }
  }, [shipment]);

  useEffect(() => {
    fetchEntity(type, id);
    fetchActivity(id);
  }, []);

  useEffect(() => {
    if (shipment) {
      directionsService.route(
        {
          waypoints: shipment.journey.waypoints.slice(1, -1).map((val) => ({
            location: new google.maps.LatLng(
              val.place.latitude,
              val.place.longitude
            ),
          })),
          origin: { placeId: shipment.journey.waypoints[0].place.googleId },
          destination: {
            placeId:
              shipment.journey.waypoints[shipment.journey.waypoints.length - 1]
                .place.googleId,
          },
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            setDirections(null);
          }
        }
      );
    }
  }, [shipment]);

  const handleChangeTab = useCallback((event, value) => setCurrentTabValue(value),
    [setCurrentTabValue]);

  const updateShipmentsDriveThrough = (
    waypointData,
    errorAction
  ) => {
    const {
      id,
      isDrivenThrough,
      drivenThroughAt,
      cargo,
    } = waypointData;
    // const formattedWaypointIndex = formattedWaypoints.findIndex((formattedWaypoint) => formattedWaypoint.id === id)
    // const newWaypoints = updateIndex(formattedWaypoints, formattedWaypointIndex, {
    //   id,
    //   action,
    //   index,
    //   formattedPlace,
    //   formattedDateTime,
    //   isDrivenThrough: Boolean(drivenThroughAt),
    //   drivenThroughAt,
    //   cargo,
    // });
    // setFormattedWaypoints(newWaypoints);
    setShipment({
      ...shipment,
      journey: {
        ...shipment.journey,
        waypoints: shipment.journey.waypoints.map((waypoint, i) => ({
          ...waypoint,
          cargo: waypoint.id === id
            ? cargo
            : shipment.journey.waypoints[i].cargo,
          isDrivenThrough:
            waypoint.id === id
              ? Boolean(drivenThroughAt)
              : shipment.journey.waypoints[i].isDrivenThrough,
          drivenThroughAt:
            waypoint.id === id
              ? drivenThroughAt
              : shipment.journey.waypoints[i].drivenThroughAt,
        }))
      }
    });

    const shipmentIndex = localShipments.findIndex(
      ({ id: shipmentId }) => shipmentId === shipment.id
    );

    const newShipments = updateIndex(localShipments, shipmentIndex, {
      ...shipment,
      journey: {
        ...shipment.journey,
        waypoints: shipment.journey.waypoints.map((waypoint, i) => ({
          ...waypoint,
          cargo: waypoint.id === id
            ? cargo
            : shipment.journey.waypoints[i].cargo,
          isDrivenThrough:
            waypoint.id === id
              ? Boolean(drivenThroughAt)
              : shipment.journey.waypoints[i].isDrivenThrough,
          drivenThroughAt:
            waypoint.id === id
              ? drivenThroughAt
              : shipment.journey.waypoints[i].drivenThroughAt,
        })),
      },
    });

    const waypointIndex = shipment.journey.waypoints.findIndex((waypoint) => waypoint.id === id);
    const { arriveAtFrom, arriveAtTo } = shipment.journey.waypoints[waypointIndex] || {}
    const currentDate = new Date();
    const warning =
      errorAction * (arriveAtFrom < currentDate && arriveAtTo > currentDate);
    const error = errorAction * ((arriveAtTo ?? arriveAtFrom) < currentDate); // wtf?
    setLocalShipments([...newShipments])
    setShipments([...newShipments])
    // setShipments({
    //   ...shipments,
    //   [shipment.state]: {
    //     total: shipments[shipment.state].total,
    //     totalWarnings: shipments[shipment.state].totalWarnings + warning,
    //     totalErrors: shipments[shipment.state].totalErrors + error,
    //     matches: newShipments,
    //   },
    // });
  };

  const getTabContent = (value) => {
    switch (value) {
      case 0:
        return (
          <BasicInfo
            entityType={type}
            shipment={shipment}
            incomingOrder={shipment}
            outcomingOrder={outcomingOrder}
            isDriveThroughLoading={isDriveThroughLoading} // up?
            setIsDriveThroughLoading={setIsDriveThroughLoading}
            waypoints={shipment.journey.waypoints}
            cargos={getWaypointsWithCargo(shipment.journey.waypoints)}
            nextShipmentNumber={nextShipmentNumber}
            errorAction={ERROR_ACTION}
            fetchActivity={fetchActivity}
            // setUpdataingWaypoint={setUpdatingWaypoint}
            // setUpdateDriveThroughDialogOpen={setUpdateDriveThroughDialogOpen}
            updateShipmentsDriveThrough={updateShipmentsDriveThrough}
            onSave={onSave}
            onDraftSave={onDraftSave}
          />
        );
      case 1:
        return (
          <ActivityTab
            entityType={type}
            data={shipment}
            activity={activity}
            setActivity={setActivity}
          />
        );
      case 2:
        return <RouteAndCargo shipment={shipment} directions={directions} />;
      default:
        throw new Error("Wrong tab value");
    }
  };

  const getRedirectUrl = useCallback((type) => {
    switch (type) {
      case ENTITY_TYPES.INCOMING_ORDER:
        return `/incoming-order/${incomingOrder.id}`;
      case ENTITY_TYPES.OUTCOMING_ORDER:
        return `/outcoming-order/${outcomingOrder.id}`;
      case ENTITY_TYPES.SHIPMENT:
        return `/shipment/${shipment.id}`;
      default:
        throw new Error("Bad entity type");
    }
  }, [type, incomingOrder, outcomingOrder, shipment])

  return (
    <>
      <SwipeableDialog
        isOpen={open}
        closable
        width="80%"
        onClose={() => {
          setOpen(false);
        }}
        form={
          <Page
            actions={
              !token ? <Fab
                color="primary"
                size="medium"
                onClick={() => history.push(getRedirectUrl(type))}
              >
                <Edit />
              </Fab> : undefined
            }
          >
            <Tabs
              value={currentTabValue}
              onChange={handleChangeTab}
              aria-label="tabs-detail-shipment"
              centered
            >
              <Tab value={0} label={t("board.basicInfo")} />
              <Tab value={1} label={t("board.activity")} />
              <Tab value={2} label={t("board.route")} />
            </Tabs>
            <Placeholder
              loading={!shipment}
              render={() => (
                <div>{getTabContent(currentTabValue)}</div>)} />
          </Page>
        }
      />
    </>
  );
};

ShipmentOverview.TYPES = ENTITY_TYPES;

export default ShipmentOverview;
