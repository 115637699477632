import { CargoItemAction } from "../../cargotic-core";

const dehydrateWaypoints = waypoints =>
  waypoints
    .map(waypoint => ({
      ...waypoint,
      place: {
        ...waypoint.place,
        thumbnail: undefined
      },
      cargo: waypoint.cargo
        .filter(({ action }) => action !== CargoItemAction.IDLE)
    }));

export default dehydrateWaypoints;
