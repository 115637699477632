import clsx from "clsx";
import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, makeStyles, Fab, Tooltip, IconButton } from "@material-ui/core";
import { Delete, Edit, Build, Add, DeleteOutline, Settings } from "@material-ui/icons";

import BankAccountEditor from "./BankAccountEditor";
import DataTable from "../../../datatable";
import BankAccountDeleteConfirmationAlert from "./BankAccountDeleteConfirmationAlert";
import Page from "../../common/Page";
import { SelectColumnsButton } from "@cargotic/webapp-component";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& > :not(:first-child)": {
      marginTop: spacing(4)
    }
  },
  content: {
    flex: 1
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  }
}));

const SettingsBankAccountOverview = ({
  className,

  isLoading,

  bankAccountsTableProps,
  hasUpdateBankAccountPermission,
  hasDeleteBankAccountPermission,
  hasCreateBankAccountPermission,

  handleOpenDeleteBankAccountDialog,
  handleOpenBankAccountEditor,

  handleCreateBankAccountClick,
  handleUpdateBankAcountClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const bankAccountsHeader = [
    {
      name: "currency",
      label: "webapp:settings.title.currency",
      isOrderable: true
    },
    {
      name: "bban",
      label: "webapp:settings.title.bban",
      isOrderable: true
    },
    {
      name: "iban",
      label: "webapp:settings.title.iban",
      isOrderable: true
    },
    {
      name: "isPrimary",
      label: "webapp:settings.title.isPrimary",
      isOrderable: false
    }
  ];

  const tableActions = [
    {
      column: [
        {
          handleClick: handleUpdateBankAcountClick,
          icon: <Edit />,
          title: "webapp:settings.title.editBankAccount",
          disableFor: !hasUpdateBankAccountPermission ? [-1] : undefined
        }
      ]
    },
  ];

  const {
    data,
    dataCount,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    selectedColumns,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = bankAccountsTableProps;

  const selectedItems = data.filter(({ selected }) => selected);

  if (isLoading) {
    return (
      <div className={clsx(className, classes.progress)}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <Page
      actions={(
        <>
          <Tooltip title={t("webapp:settings.title.create-bank-account")}>
            <span>
              <Fab
                color="primary"
                disabled={isLoading || !hasCreateBankAccountPermission}
                onClick={handleCreateBankAccountClick}
              >
                <Add />
              </Fab>
            </span>
          </Tooltip>
          <Fab
            className={classes.danger}
            disabled={isLoading || selectedItems.length === 0 || !hasDeleteBankAccountPermission}
            onClick={handleOpenDeleteBankAccountDialog}
          >
            <DeleteOutline />
          </Fab>
        </>
      )}
      tertiaryActions={(
        <SelectColumnsButton onClick={setIsOpenSelectColumnsDialog} />
      )}>
      <div className={clsx(className, classes.root)}>
        <DataTable
          headers={bankAccountsHeader}
          actions={tableActions}
          selectedColumns={selectedColumns}
          data={data}
          dataCount={dataCount}
          loading={loading}
          ordering={ordering}
          direction={direction}
          checkedAll={checkedAll}
          page={page}
          rowsPerPage={rowsPerPage}
          onSort={handleSort}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          onChangePage={handleChangePage}
          onChangeSelectedColumns={handleChangeSelectedColumns}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
          setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        />
      </div>
    </Page>
  );
};

export default SettingsBankAccountOverview;
