import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, Typography, makeStyles } from "@material-ui/core";

import VehicleExpirationTableBody from "./VehicleExpirationTableBody";
import VehicleExpirationTableFooter from "./VehicleExpirationTableFooter";

const useStyles = makeStyles(({ spacing }) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    marginTop: spacing(4)
  },
  footer: {
    marginTop: spacing(1)
  }
}));

const VehicleExpirationTable = ({
  className,
  expirations,
  page,
  rowsPerPage,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  isLoading,
  onExpirationDeleteButtonClick,
  onExpirationEditButtonClick,
  onPageChange,
  onRowsPerPageChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={clsx(classes.center, className)}>
        <CircularProgress />
      </div>
    );
  }

  const { matches, total } = expirations;

  if (matches.length === 0) {
    return (
      <div className={clsx(classes.center, className)}>
        <Typography color="textSecondary">
          {t("webapp:vehicle.title.empty-expirations")}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <VehicleExpirationTableBody
        className={classes.content}
        expirations={matches}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        isEditButtonDisabled={isEditButtonDisabled}
        onExpirationDeleteButtonClick={onExpirationDeleteButtonClick}
        onExpirationEditButtonClick={onExpirationEditButtonClick}
      />
      <VehicleExpirationTableFooter
        className={classes.footer}
        page={page}
        rowsPerPage={rowsPerPage}
        total={total}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </div>
  );
};

export default VehicleExpirationTable;
