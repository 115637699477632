import { createContext } from "react";

import { ApiClient } from "../../client";

interface ApiClientContextProps {
  client: ApiClient;
}

// @ts-ignore
const ApiClientContext = createContext<ApiClientContextProps>();

export default ApiClientContext;
