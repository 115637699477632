import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, makeStyles } from "@material-ui/core";
import { Add as AddIcon, Edit as EditIcon } from "@material-ui/icons";

import ChipTag from "../ChipTag/ChipTag";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",

    "& > *": {
      margin: spacing(0.5)
    }
  }
}));

const ChipTagGroup = ({ tags, limit, onEditButtonClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {tags.map(({ label, type, value }, index) => (
        <ChipTag key={index} label={label} type={type} value={value} />
      ))}
      <Chip
        color="primary"
        variant="outlined"
        label={(
          tags && tags.length
            ? t("webapp:contact.tags.edit")
            : t("webapp:contact.tags.add")
        )}
        deleteIcon={(
          tags && tags.length
            ? <EditIcon />
            : <AddIcon />
        )}
        onClick={onEditButtonClick}
        onDelete={onEditButtonClick}
        clickable
      />
    </div>
  );
};

export default ChipTagGroup;
