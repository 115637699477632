import React, { useState, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { UserRole as PermissionRoles } from "@cargotic/model";
import { Paper, Grid, Typography, Link } from "@material-ui/core";
import { ContactType } from "@cargotic/model";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";

import Page from "../../../component/common/Page";
import SelectOrderType from "./SelectOrderType";
import SelectCarrier from "./SelectCarrier";
import IncomingOrderTable from "./IncomingOrderTable";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import PlaceFilterDropdown from "../../../../cargotic-webapp-filter/component/PlaceFilterDropdown";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import SearchTextfield from "../../../component/common/SearchTextfield";
import useRouter from "../../../component/hook/useRouter";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

import IncomingOrdersDateTime from "../../../component/core/IncomingOrders/IncomingOrdersDateTime";
import IncomingOrdersRoute from "../../../component/core/IncomingOrders/IncomingOrdersRoute";
import IncomingOrderState from "../../../component/core/IncomingOrders/IncomingOrderState";

import { useApiClient } from "../../../../cargotic-webapp-component";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window";
import { storeFilters, loadFilters } from "../../../storage";

import DataTable from "../../../datatable";
import useTable from "../../../datatable/useTable";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import {
  suggestUsers,
  createPlacesQuery,
  updateIncomingOrderState,
  createStateSuggestQuery
} from "../../../resource";

import useAuth from "../../../component/hook/useAuth";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import useIncomingOrderMultiselectSelection from "../../../component/hook/useIncomingOrderMultiselectSelection";
import useOutcomingOrderConcept from "../../../component/hook/useOutcomingOrderConcept";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";
import IncomingOrdersOutcomingOrderReference from "../../../component/core/IncomingOrders/IncomingOrdersOutcomingOrderReference";
import { AvailableCargoItemTemplates } from "../../../../../multiload/cargotic-core";

const ordersHeader = [
  {
    name: "indexNumber",
    label: "incomingOrders.number",
    isOrderable: true,
    width: "10%",
  },
  {
    name: "customerContact",
    label: "incomingOrders.customer",
    isOrderable: false,
  },
  {
    name: "pickupAtStart",
    label: "incomingOrders.date",
    isOrderable: true,
    width: "10%",
  },
  {
    name: "route",
    label: "incomingOrders.route",
    isOrderable: false,
  },
  {
    name: "state",
    label: "incomingOrders.state",
    isOrderable: false,
    width: "6%",
  },
  {
    name: "outcomingOrder",
    label: "incomingOrders.outcomingOrderNumber",
    isOrderable: true,
  },
  {
    name: "externalReference",
    label: "incomingOrders.externalReference",
    isOrderable: false
  },
  {
    name: "customerPrice",
    label: "incomingOrders.customerPrice",
    isOrderable: false,
  },
  {
    name: "sumCargoValue",
    label: "incomingOrders.sumCargoValue",
    width: "15%",
    isOrderable: false,
  },
  {
    name: "cashOnDelivery",
    label: "incomingOrders.cashOnDelivery",
    width: "15%",
    isOrderable: false,
  },
];

const IncomingOrderMultiloading = () => {
  const { t } = useTranslation();
  const { hasPermission, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { history, location: { search: routerSearch } } = useRouter();

  let reloadDelay;

  const canReadCompanyIncomingOrder = hasPermission("resource.incomingOrder.company.read");

  const { incomingOrderSelection, setIncomingOrderSelection } = useIncomingOrderMultiselectSelection();
  const { setShipment } = useShipmentConcept();
  const { setOutcomingOrder } = useOutcomingOrderConcept();

  const defaultFilterValues = [
    "customers",
    "loadingDate",
    "unloadingDate",
    "creators",
    "state",
    "places",
  ];
  const availableFilters = [
    {
      label: t("contacts.customer"),
      value: "customers",
    },
    {
      label: t("incomingOrders.cargo"),
      value: "cargo",
    },
    {
      label: t("incomingOrders.loadingsDateRange"),
      value: "loadingDate",
    },
    {
      label: t("incomingOrders.unloadingsDateRange"),
      value: "unloadingDate",
    },
    {
      label: t("incomingOrders.creationDate"),
      value: "createdAt",
    },
    {
      label: t("incomingOrders.state"),
      value: "state",
    },
    {
      label: t("incomingOrders.customerPrice"),
      value: "customerPrice",
    },
    {
      label: t("incomingOrders.creator"),
      value: "creators",
    },
    {
      label: t("shipments.places"),
      value: "places"
    },
  ];

  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  const [filter, setFilter] = useState(initFilter);
  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);

  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [search, setSearch] = useState(initSearchText);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [allPagesSelected, setAllPagesSelected] = useState(false);

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);
  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
  const handleSelectUserRole = (roles) => setFilter({ ...filter, roles });

  const clearFilter = () => setFilter({});

  const isAnyFilterActive = () => {
    const {
      customerPrice,
      customers,
      creators,
      places,
      state,
      cargo,
      loadingDate,
      unloadingDate,
      createdAt,
    } = filter;

    return [
      customerPrice,
      customers,
      creators,
      places,
      state,
      cargo,
      loadingDate,
      unloadingDate,
      createdAt,
    ].some((item) => item !== undefined);
  };

  const [items, setItems] = useState([]);
  const [allIds, setAllIds] = useState([]);

  const client = useApiClient();

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  const onFilterSettingsSubmit = async (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("shipment-creation", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  useEffect(() => {
    const loadedFilters = loadFilters("shipment-creation");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    customers: filter.customers ? filter.customers.map(({ id }) => id) : undefined,
    state: filter.state ? filter.state.map(({ id }) => id) : undefined,
    places: filter.places ? filter.places.map(({ id }) => id) : undefined
  });

  const reloadItems = useCallback(async (offset, limit, ordering) => {
    const newFilter = transformFilter(filter);
    const state = await createStateSuggestQuery({ resources: ["incoming_order"] });
    const _items = await client.incomingOrder.postIncomingOrderMatchQuery({
      query: {
        match: { ...newFilter, search, isDraft: false },
        offset,
        limit,
        orderBy: ordering
      }
    });

    setAllIds(_items.allIds || []);

    if (_items.total === 0 && offset !== 0) {
      handleChangePage(
        undefined,
        0
      );
    }
    const orders = _items.matches.map((item) => {
      const isDisabled = item.isDisabled === 1;
      const selected = false;
      const id = item.id;
      const row = [
        {
          render: !item.isDraft ? (
            <Typography variant="body2"> #
              {item.indexNumber}
            </Typography>
          ) : (
            <Typography variant="body2">
              <i>{t("incomingOrders.draft")}</i>
            </Typography>
          ),
        },
        {
          render: (
            item.customerContact ? (
              <Typography variant="body2">
                <Link
                  component={RouterLink}
                  to={`/contacts/${item.customerContact.id}`}
                >
                  {item.customerContact.name ||
                    item.customerContact.email ||
                    item.customerContact.phoneNumber}
                </Link>
              </Typography>) : undefined
          ),
        },
        {
          render: <IncomingOrdersDateTime incomingOrder={item} />,
        },
        {
          render: <IncomingOrdersRoute incomingOrder={item} />,
        },
        {
          render: <IncomingOrderState incomingOrder={item} updateIncomingOrderState={updateIncomingOrderState} reload={reload} state={state} />,
        },
        {
          render: <IncomingOrdersOutcomingOrderReference incomingOrder={item} />,
        },
        {
          render: <Typography variant="body2">{item.externalReference}</Typography>
        },
        {
          render: (
            <Typography variant="body2">
              {item.customerPrice != null
                ? `${item.customerPrice.toFixed(0)} ${item.customerPriceCurrency
                }`
                : null}
            </Typography>
          ),
        },
        {
          render: <Typography variant="body2">{item.sumCargoValue}</Typography>
        },
        {
          render: (
            <Typography variant="body2">
              {item.cashOndeliveryValue != null
                ? item.cashOndeliveryValue.toFixed(0)
                : null}
            </Typography>
          )
        }
      ];

      return { id, row, selected, isDisabled };
    });

    setItems(_items);
    return { data: orders, totalCnt: _items.total };
  }, [filter, search]);

  const handleSelectAllOnPage = (bool) => {
    setAllPagesSelected(bool);
  };

  const {
    data,
    dataCount,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    selectedColumns,
    reloadData,
    reload,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangeSelectedColumns,
    handleChangePage,
    handleChangeRowsPerPage
  } = useTable(reloadItems, "shipment-creation");

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const handleSelectCreators = (creators) => setFilter({ ...filter, creators });
  const handleSelectCustomers = (customers) => setFilter({ ...filter, customers });
  const handleSelectLoadingsDateRange = (loadingDate) => setFilter({ ...filter, loadingDate });
  const handleSelectUnloadingsDateRange = (unloadingDate) => setFilter({ ...filter, unloadingDate });
  const handleSelectPlaces = (places) => setFilter({ ...filter, places });
  const handleSelectCreatedAtDateRange = (createdAt) => setFilter({ ...filter, createdAt });
  const handleSelectState = (state) => setFilter({ ...filter, state });

  const loadAvailableUsers = async (search, permissions) => {
    try {
      const matches = await suggestUsers({ permissions, search });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableContacts = async (type, search) => {
    try {
      const contacts = await client.contact.postContactSuggestQuery({
        query: {
          search, types: ["BOTH", type]
        }
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableStates = async (search) => {
    try {
      const states = await createStateSuggestQuery({ resources: ["incoming_order"] });
      return states;
    } catch (error) {
      enqueueSnackbar(t("settings.error.suggest-state"), {
        variant: "error",
      });
      return undefined;
    }
  };

  const handleLoadAvailablePlaces = async (search) => {
    try {
      const { places } = await createPlacesQuery({
        filter: { search, id: null }
      });
      return places;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  useEffect(() => {
    const toAdd = data.filter(item => item.selected).map(item => ({ ...item }));
    const ids = toAdd.map(item => item.id);
    const vals = ids.length > 0 ? items.matches.filter(({ id }) => ids.includes(id)) : [];
    setIncomingOrderSelection([...vals]);
  }, [data, checkedAll, items]);

  useEffect(() => {
    reload();
  }, [search]);

  const handleShipmentSelected = () => {
    if (!incomingOrderSelection.length) {
      enqueueSnackbar(t("incomingOrders.noSelection", { variant: "error" }));
    } else {
      setShipment({
        incomingOrderIds: allPagesSelected ? allIds : incomingOrderSelection.map(({ id }) => id),
        incomingOrders: incomingOrderSelection,
        fresh: true
      });
      setIncomingOrderSelection([]);
      history.push("shipment");
    }
  };

  const handleOutcomingOrderSelected = () => {
    if (!incomingOrderSelection.length) {
      enqueueSnackbar(t("incomingOrders.noSelection", { variant: "error" }));
    } else {
      setOutcomingOrder({
        incomingOrderIds: incomingOrderSelection.map(({ id }) => id),
        incomingOrders: incomingOrderSelection,
        fresh: true
      });
      setIncomingOrderSelection([]);
      history.push("outcoming-order");
    }
  };

  return (
    <>
      <Page>
        <FilterContainer
          searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("users.searchTooltip")} />}
          showClearButton={isAnyFilterActive}
          handleFilterSettingsOpen={handleFilterSettingsOpen}
          onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
          defaultFilters={defaultFilters}
          loading={loading}
          filters={[
            <ContactFilterDropdown
              id="customers"
              key="customers"
              onChange={(value) =>
                handleSelectCustomers(value.length === 0 ? undefined : value)
              }
              selectAll={(all) => handleSelectCustomers(all)}
              value={filter.customers ?? []}
              search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
              placeholderTitle={t("contacts.customer")}
              onClear={() => handleSelectCustomers(undefined)}
              getTitle={(item) => item.name}
            />,
            <FilterDateRangeDropdown
              id="loadingDate"
              key="loadingDate"
              onChange={(value) => handleSelectLoadingsDateRange(value)}
              value={
                filter.loadingDate
                  ? [filter.loadingDate.from, filter.loadingDate.to]
                  : []
              }
              onClear={() => handleSelectLoadingsDateRange(undefined)}
              placeholderTitle={t("incomingOrders.loadingsDateRange")}
            />,
            <FilterDateRangeDropdown
              id="unloadingDate"
              key="unloadingDate"
              onChange={(value) => handleSelectUnloadingsDateRange(value)}
              value={
                filter.unloadingDate
                  ? [filter.unloadingDate.from, filter.unloadingDate.to]
                  : []
              }
              onClear={() => handleSelectUnloadingsDateRange(undefined)}
              placeholderTitle={t("incomingOrders.unloadingsDateRange")}
            />,
            <FilterDateRangeDropdown
              id="createdAt"
              key="createdAt"
              onChange={(value) => handleSelectCreatedAtDateRange(value)}
              value={
                filter.createdAt
                  ? [filter.createdAt.from, filter.createdAt.to]
                  : []
              }
              onClear={() => handleSelectCreatedAtDateRange(undefined)}
              placeholderTitle={t("incomingOrders.creationDate")}
            />,
            <StateFilterDropdown
              id="state"
              key="state"
              placeholderTitle={t("board.state.title")}
              value={filter.state ?? []}
              onChange={(arr) =>
                handleSelectState(arr.length === 0 ? undefined : arr)
              }
              selectAll={(all) => handleSelectState(all)}
              onClear={() => handleSelectState(undefined)}
              search={(text) => loadAvailableStates(text)}

              getTitle={(item) => item.name}
            />,
            <FilterNumberRange
              id="customerPrice"
              key="customerPrice"
              onChange={(value) => handleSelectCustomerPrice(value)}
              value={filter.customerPrice}
              placeholderTitle={t("incomingOrders.customerPrice")}
              onClear={() => handleSelectCustomerPrice(undefined)}
            />,
            <UserFilterDropdown
              id="creators"
              key="creators"
              onChange={(value) =>
                handleSelectCreators(value.length === 0 ? undefined : value)
              }
              selectAll={(all) => handleSelectCreators(all)}
              value={filter.creators ?? []}
              placeholderTitle={t("incomingOrders.creator")}
              search={(text) => loadAvailableUsers(text)}
              onClear={() => handleSelectCreators(undefined)}
              getTitle={(item) => item.name}
              disabled={!canReadCompanyIncomingOrder}
            />,
            <FilterStaticDropdown
              id="cargo"
              key="cargo"
              placeholderTitle={t("incomingOrders.cargo")}
              value={filter.cargo ?? []}
              onChange={(arr) =>
                handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
              }
              selectAll={() =>
                handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
              }
              onClear={() => handleSelectCargoTemplate(undefined)}
              source={AvailableCargoItemTemplates.map(({ value, label }) => ({ value, title: t(label) }))}
            />,
            <PlaceFilterDropdown
              id="places"
              key="places"
              placeholderTitle={t("shipments.places")}
              selectAll={(all) => handleSelectPlaces(all)}
              search={(text) => handleLoadAvailablePlaces(text)}
              value={filter.places ?? []}
              onChange={(value) => handleSelectPlaces(value.length === 0 ? undefined : value)}
              onClear={() => handleSelectPlaces(undefined)}
            />
          ]}
          onClear={clearFilter}
        />

        <Paper>
          <DataTable
            headers={ordersHeader}
            data={data}
            dataCount={dataCount}
            loading={loading}
            ordering={ordering}
            selectedColumns={selectedColumns}
            direction={direction}
            checkedAll={checkedAll}
            page={page}
            onSelectAllOnPage={handleSelectAllOnPage}
            rowsPerPage={rowsPerPage}
            onSort={handleSort}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
            setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
            onChangeSelectedColumns={handleChangeSelectedColumns}
          />
        </Paper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SelectOrderType
            handleShipmentSelected={handleShipmentSelected}
            handleOutcomingOrderSelected={handleOutcomingOrderSelected}
          />
        </div>
      </Page>
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default IncomingOrderMultiloading;
