import React from "react";
import { Link } from "react-router-dom";

import { Typography, makeStyles } from "@material-ui/core";

import { buildUserUrl } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    "& a": {
      color: palette.text.primary,

      textDecoration: "none"
    }
  }
}));

const UserLink = ({ user: { name, id } }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      <b>
        <Link to={buildUserUrl(id)}>
          {name}
        </Link>
      </b>
    </Typography>
  );
};

export default UserLink;
