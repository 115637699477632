import clsx from "clsx";
import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: 2,
    marginLeft: 48,

    backgroundColor: palette.primary.main
  },
  hidden: {
    backgroundColor: "transparent"
  }
}));

const WaypointBrowserConnector = ({ size, isHidden }) => {
  const classes = useStyles();
  const height = size === "small"
    ? 32
    : 64;

  return (
    <div
      style={{ height }}
      className={clsx(classes.root, { [classes.hidden]: isHidden })}
    />
  );
};

export default WaypointBrowserConnector;
