import clsx from "clsx";
import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: "relative",

    width: 2,
    height: 48,
    marginLeft: 41,

    "&::before": {
      content: "\" \"",

      position: "absolute",
      top: -2,

      display: "block",
      width: 2,
      height: 2
    },

    "&::after": {
      content: "\" \"",

      position: "absolute",
      bottom: -2,

      display: "block",
      width: 2,
      height: 2
    }
  },
  extendedBottom: {
    "&::after": {
      bottom: -6,
      height: 6
    }
  },
  extendedTop: {
    "&::before": {
      top: -6,
      height: 6
    }
  },
  reducedBottom: {
    "&::after": {
      bottom: 0,
      height: 0
    }
  },
  reducedTop: {
    "&::before": {
      top: 0,
      height: 0
    }
  },
  active: {
    backgroundColor: palette.primary.light,

    "&::before": {
      backgroundColor: palette.primary.light
    },

    "&::after": {
      backgroundColor: palette.primary.light
    }
  },
  inactive: {
    backgroundColor: palette.action.disabled,

    "&::before": {
      backgroundColor: palette.action.disabled
    },

    "&::after": {
      backgroundColor: palette.action.disabled
    }
  }
}));

const ActivityTimelineItemConnector = ({
  hasExtendedBottom,
  hasExtendedTop,
  hasReducedBottom,
  hasReducedTop,
  isActive
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.extendedBottom]: hasExtendedBottom,
        [classes.extendedTop]: hasExtendedTop,
        [classes.active]: isActive,
        [classes.inactive]: !isActive,
        [classes.reducedBottom]: hasReducedBottom,
        [classes.reducedTop]: hasReducedTop
      })}
    />
  );
};

export default ActivityTimelineItemConnector;
