import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
} from "@material-ui/core";

import { postTemplate } from "../../../../resource";

const AddTemplateDialog = ({ isOpen, onClose, templateData }) => {
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const onSave = async () => {
    try {
      const res = await postTemplate({
        attributes: templateData,
        name: templateName,
      });
      if (res) {
        enqueueSnackbar(t("importCSV.add.success"), { variant: "success" });
        onClose();
      }
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("importCSV.add.error"), {
        variant: "error",
      });
    }
  };
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("importCSV.add.label")}</DialogTitle>
      <DialogContent>
        <Box p="1" minWidth={250} display="flex" flexDirection="column">
          {t("importCSV.add.description")}
          <TextField
            label="Název"
            value={templateName}
            inputProps={{
              maxLength: 20
            }}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("abort")}</Button>
        <Button color="primary" onClick={onSave}>
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTemplateDialog;
