import { useFormik } from "formik2";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, makeStyles } from "@material-ui/core";

import {
  DrawerDialog,
  FormikTextField
} from "../../../cargotic-webapp-component";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  }
}));

const ContactEmployeeEditor = ({
  initialValue = {},
  isOpen,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const schema = Yup.object({
    name: Yup.string()
      .max(64, t("webapp:common.validate.name")),
    email: Yup.string()
      .email(t("webapp:common.validate.email"))
      .max(128, t("webapp:common.validate.email")),
    phoneNumber: Yup.string()
      .max(32, t("webapp:common.validate.phone-number"))
  });

  const initialId = initialValue.id;
  const initialName = initialValue.name || "";
  const initialEmail = initialValue.email || "";
  const initialPhoneNumber = initialValue.phoneNumber || "";

  const form = useFormik({
    initialValues: {
      name: initialName,
      email: initialEmail,
      phoneNumber: initialPhoneNumber
    },
    validate: (values) => {
      const oneOfRequiredErrors = (
        values.email === "" &&
        values.name === "" &&
        values.phoneNumber === ""
      ) ? {
          email: t("webapp:common.validate.email-name-or-phone-required"),
          name: t("webapp:common.validate.email-name-or-phone-required"),
          phoneNumber: t("webapp:common.validate.email-name-or-phone-required")
        }
        : {};

      try {
        schema.validateSync(values);
      } catch (errors) {
        return { ...oneOfRequiredErrors, [errors.path]: errors.message };
      }
      return oneOfRequiredErrors;
    },
    onSubmit: ({ name, email, phoneNumber }) => {
      const employee = {
        id: initialId,
        contactId: initialValue.contactId,
        email: email || undefined,
        name: name || undefined,
        phoneNumber: phoneNumber || undefined
      };

      if (onSubmit) {
        onSubmit(employee);
      }
    }
  });

  const handleSubmitButtonClick = () => (
    form.submitForm()
  );

  useEffect(() => {
    form.resetForm({
      values: {
        name: initialName,
        email: initialEmail,
        phoneNumber: initialPhoneNumber
      },
      errors: {}
    });
  }, [isOpen]);

  return (
    <DrawerDialog
      title={t("webapp:common.title.employee")}
      isOpen={isOpen}
      onClose={onClose}
      actions={(
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose}>
            {t("webapp:common.title.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitButtonClick}
          >
            {t("webapp:common.title.continue")}
          </Button>
        </div>
      )}
    >
      <div>
        <FormikTextField
          form={form}
          name="name"
          label={t("webapp:common.title.name")}
          fullWidth
        />
        <FormikTextField
          form={form}
          name="email"
          label={t("webapp:common.title.email")}
          fullWidth
        />
        <FormikTextField
          form={form}
          name="phoneNumber"
          label={t("webapp:common.title.phone-number")}
          fullWidth
        />
      </div>
    </DrawerDialog>
  );
};

export default ContactEmployeeEditor;
