import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextField, IconButton, InputAdornment, makeStyles, Tooltip } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 0
  }
}));

const SearchTextfield = ({
  handleSearch,
  value: inputValue = "",
  fullWidth = false,
  placeholder
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(inputValue);
  const classes = useStyles();

  const withTooltip = (value, props) => (<Tooltip title={value === "" ? (placeholder ?? t("search")) : ""} placement="right" arrow>
    {props}
  </Tooltip>);


  const debounce = (execute, delay) => {
    let timeout;

    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => execute(...args), delay);
    };
  };

  const debounceSuggest = useCallback(
    debounce(async (text) => {
      await handleSearch(text.trim());
    }, 1000), [handleSearch]
  );

  useEffect(() => {
    if (typeof value === "string") {
      debounceSuggest(value);
    }
  }, [value]);

  const content = (
    <TextField
      placeholder={placeholder ?? t("search")}
      value={value}
      variant="outlined"
      fullWidth={fullWidth}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      classes={{
        root: classes.field
      }}
      InputProps={{
        style: {
          paddingRight: 0,
          marginBottom: 0
        },
        endAdornment: (
          <InputAdornment position="end">
            {value
              ? (
                <IconButton
                  onClick={() => {
                    handleSearch("");
                    setValue("");
                  }}
                >
                  <Close />
                </IconButton>
              )
              : (
                <IconButton>
                  <Search />
                </IconButton>
              )}
          </InputAdornment>
        )
      }}
    />);

  return withTooltip(value, content);
}

export default SearchTextfield;
