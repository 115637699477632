import React from "react";

import { TextField, OutlinedInputProps } from "@material-ui/core";

import { Form, FormValues } from "../../form";

interface FormTextFieldProps<T extends FormValues> {
  form: Form<T>;
  name: keyof T;
  variant?: "standard" | "outlined" | "filled";
  color?: "primary" | "secondary";
  rows?: number;
  label?: string;
  InputProps?: Partial<OutlinedInputProps>;
  isDisabled?: boolean;
  hasFullWidth?: boolean;
  required?: boolean,
  isMultiline?: boolean;
  dataCy?: string;
}

function FormTextField<T extends FormValues>(
  {
    form: {
      errors,
      touched,
      values,
      handleBlur,
      handleChange
    },
    name,
    rows,
    variant,
    color,
    label,
    InputProps,
    isDisabled,
    required,
    hasFullWidth,
    isMultiline,
    classes,
    dataCy
  }: FormTextFieldProps<T>
): React.ReactElement {
  const isTouched = touched[name];
  const error = isTouched ? errors[name] : undefined;
  const errorMessage = error?.message || undefined;
  const hasError = error !== undefined;
  const value = values[name];

  return (
    // @ts-ignore
    <TextField
      name={name as unknown as string}
      label={label}
      variant={variant}
      InputProps={InputProps}
      value={value}
      rows={rows}
      disabled={isDisabled}
      error={hasError}
      fullWidth={hasFullWidth}
      required={required}
      helperText={errorMessage ?? error?.name ?? " "}
      onBlur={handleBlur}
      onChange={handleChange}
      multiline={isMultiline}
      color={color}
      classes={classes}
      data-cy={dataCy}
    />
  );
}

export default FormTextField;
