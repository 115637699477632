import clsx from "clsx";
import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  default: {
    margin: "0 auto",
    width: 900,

    [breakpoints.up("lg")]: {
      width: 900
    },
    [breakpoints.up("xl")]: {
      width: 1280
    }
  },
  large: {
    "@media (min-width:0px)": {
      paddingRight: spacing(2),
      paddingLeft: spacing(2)
    },

    "@media (min-width:1920px)": {
      margin: "0 auto",
      width: 1640
    }
  }
}));

const Container = ({ className, size = "default", children }) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes[size])}>
      {children}
    </div>
  );
};

export default Container;
