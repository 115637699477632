import { Optional, ValueError } from "@cargotic/common-deprecated";

function required<T>(value: Optional<T>, message?: string): T {
  if (value === undefined) {
    throw new ValueError(message);
  }

  return value;
}

export {
  // eslint-disable-next-line
  required
};
