import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardContent, CardHeader, Grid, makeStyles, Typography } from "@material-ui/core";

import logo from "../../../asset/image/logo.svg";
import config from "../../../config";

const AuthSignUp = ({ classes, form, userCount }) => {
    const { t } = useTranslation();
    
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={12} align="center">
                <a href="/"><img className={classes.logo} src={logo} alt="logo" /></a>
            </Grid>
            <Grid item xs={12} md={12}>
                <Card style={{ width: "100%" }}>
                    <CardContent className={classes.content}>
                        {form}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default AuthSignUp;
