import React, { useCallback, useMemo, useState } from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TablePagination,
  useTheme,
  Typography,
  TableContainer,
} from "@material-ui/core";
import Placeholder from "../component/common/Placeholder";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import CoolerTablePagination from "../component/common/CoolerTablePagination";

import DataTableHead from "./DataTableHead";
import DataTableRow from "./DataTableRow";

import SelectColumnsDialog from "../component/core/SelectColumnsDialog";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  table: {
    [breakpoints.down("xs")]: {
      borderCollapse: "unset",
      borderSpacing: "5px",
    },
  },
  pagination: {
    [breakpoints.down("xs")]: {
      "& .MuiTablePagination-spacer": {
        flex: "0 0 0%",
      },
      "& .MuiTablePagination-selectRoot": {
        marginRight: 0,
      },
    },
  },
  currentPage: {
    textAlign: "center",
  },
}));

const DataTable = ({
  headers,
  actions,
  data,
  dataCount,
  selectedColumns: selectedColumnsData,
  additionalColumns = [],
  loading,
  ordering,
  direction,
  checkedAll,
  page,
  rowsPerPage,
  enableDetails,
  enableDetail,
  enableMultipleDetails,
  enableMenu,
  menuItems,
  selectOnlyOne,
  onSort,
  onSelect,
  onSelectAll,
  onSelectAllOnPage,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSelectedColumns,

  isOpenSelectColumnsDialog,
  setIsOpenSelectColumnsDialog,
  sum
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const tableMode = useMediaQuery(theme.breakpoints.up("sm"));

  const [detailed, setDetailed] = useState(-1);

  const selectedColumns = useMemo(
    () =>
      selectedColumnsData
        ? [...selectedColumnsData, ...additionalColumns]
        : headers.map((header) => header.name),
    [selectedColumnsData, additionalColumns, headers]
  );

  const selectedHeaders = useMemo(
    () =>
      selectedColumns
        ? selectedColumns
          .map((column) => headers.find((header) => header.name === column))
          .filter(Boolean)
        : headers,
    [headers, selectedColumns]
  );

  const rowsPerPageLabel = tableMode ? "rowsPerPage" : "";
  const displayedRowsLabel = tableMode
    ? ({ from, to, count }) => `${from}-${to} ${t("of")} ${count}`
    : ({ from, to, count }) => ``;

  const TPaper = tableMode ? Paper : "div";
  const TTable = tableMode ? Table : "div";
  const TTableProps = tableMode
    ? {
      className: classes.table,
    }
    : {};
  const TBody = tableMode ? TableBody : "div";

  const cellsFromRow = useCallback(
    (row) =>
      selectedColumns
        .map((column) => row[headers.findIndex(({ name }) => column === name)])
        .filter(Boolean),
    [selectedColumns, headers]
  );

  return (
    <Placeholder
      loading={loading}
      render={() => (
        <TPaper>
          <TableContainer>
            <TTable {...TTableProps}>
              <DataTableHead
                tableMode={tableMode}
                headers={selectedHeaders}
                actions={actions}
                ordering={ordering}
                direction={direction}
                checkedAll={checkedAll}
                enableDetails={enableDetails}
                selectOnlyOne={selectOnlyOne}
                onSort={onSort}
                onSelectAll={onSelectAll}
                onSelectAllOnPage={onSelectAllOnPage}
                setDialogOpen={setIsOpenSelectColumnsDialog}
              />
              <TBody>
                {data.map(
                  ({ id, row, selected, details, detail, disabled }, index) => (
                    <DataTableRow
                      key={id}
                      disabled={disabled}
                      tableMode={tableMode}
                      index={index}
                      id={id}
                      selected={selected}
                      enableDetails={enableDetails}
                      details={details}
                      detail={detail}
                      detailed={detailed}
                      setDetailed={setDetailed}
                      enableMultipleDetails={enableMultipleDetails}
                      enableMenu={enableMenu}
                      menuItems={menuItems}
                      cells={cellsFromRow(row)}
                      actions={actions}
                      enableDetail={enableDetail}
                      selectOnlyOne={selectOnlyOne}
                      onSelect={onSelect}
                    />
                  )
                )}
              </TBody>
            </TTable>
            {tableMode ? null : (
              <Typography variant="subtitle2" className={classes.currentPage}>
                {rowsPerPage * page + 1} - {rowsPerPage * page + data.length}{" "}
                {t("of")} {dataCount}
              </Typography>
            )}
            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={[5, 15, 25, 50, 100]}
              component="div"
              count={dataCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t(rowsPerPageLabel)}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              labelDisplayedRows={displayedRowsLabel}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              ActionsComponent={({ count, page, rowsPerPage, onChangePage }) => <CoolerTablePagination sum={sum} count={count} page={page} rowsPerPage={rowsPerPage} onChangePage={onChangePage} />}
            >
            </TablePagination>
            <SelectColumnsDialog
              selectedColumns={selectedColumnsData}
              additionalColumns={additionalColumns}
              onChangeSelectedColumns={onChangeSelectedColumns}
              open={isOpenSelectColumnsDialog}
              setDialogOpen={setIsOpenSelectColumnsDialog}
              headers={headers}
              showCloseButton
            />
          </TableContainer>
        </TPaper >
      )}
    />
  );
};

export default DataTable;
