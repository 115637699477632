import React from "react";

import { Avatar, CircularProgress } from "@material-ui/core";

const ImageAvatar = ({
  loading, source, text, classes = {}
}) => {
  const props = {
    className: classes.root
  };

  if (loading) {
    return (
      <Avatar {...props}>
        <CircularProgress />
      </Avatar>
    );
  }

  if (source) {
    return (
      <Avatar {...props} src={source} />
    );
  }

  return (
    <Avatar {...props}>
      {text}
    </Avatar>
  );
};

export default ImageAvatar;
