import React from "react";
import { useTranslation } from "react-i18next";

import { TablePagination } from "@material-ui/core";

const ContactEmployeeGridFooter = ({
  className,
  page,
  rowsPerPage,
  total,
  onPageChange,
  onRowsPerPageChange
}) => {
  const { t } = useTranslation();

  const handlePageChange = (event, value) => {
    if (onPageChange) {
      onPageChange(value);
    }
  };

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(parseInt(value, 10));
    }
  };

  return (
    <TablePagination
      className={className}
      component="div"
      count={total}
      page={page}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage={t("webapp:common.title.rows-per-page")}
      labelDisplayedRows={({ from, to, count }) => `${from} - ${to} ${t("of")} ${count}`}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    />
  );
};

export default ContactEmployeeGridFooter;
