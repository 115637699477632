import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  paper: {
    width: 400
  }
}));

const FilterSettings = ({
  availableFilters = [],
  initialFilters = [],
  isOpen,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filters, setFilters] = useState(initialFilters);
  const handleFiltersChange = (event, value) => setFilters(value);

  const handleSubmitButtonClick = () => {
    if (onSubmit) {
      onSubmit(filters.map(({ value }) => value));
    }
  };

  useEffect(() => {
    setFilters(initialFilters);
  }, [isOpen]);

  return (
    <Dialog
      width="lg"
      fullWidth
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>{t("filter.edit")}</DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          value={filters}
          noOptionsText={t("webapp:common.title.noTagOptions")}
          getOptionLabel={({ label }) => label}
          getOptionSelected={({ value: a }, { value: b }) => a === b}
          options={availableFilters}
          renderInput={params => (
            <TextField
              {...params}
              label={t("filter.label")}
            />
          )}
          onChange={handleFiltersChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmitButtonClick}>
          {t("webapp:common.title.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterSettings;
