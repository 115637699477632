import React from "react";

import FormikTextField from "@cargotic/webapp-component/component/FormikTextField";
import FormikSelect from "@cargotic/webapp-component/component/FormikSelect";

export const renderTextField = (
    form,
    fieldName,
    label,
    required = true,
    type = "text"
) => (
    <FormikTextField
        name={fieldName}
        form={form}
        label={label}
        fullWidth
        required={required}
        type={type}
        bypassIsTouched={true}
    />
);

export const renderSelect = (
    form,
    fieldName,
    label,
    options,
    required = true,
    handleChange
) => (
    <FormikSelect
        required={required}
        fullWidth
        form={form}
        name={fieldName}
        label={label}
        handleChange={handleChange}
    >
        {options}
    </FormikSelect>
);
