import React from "react";

import {
  Button, Dialog, Typography, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, makeStyles
} from "@material-ui/core";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { UserInviteSchema } from "../../../utility/validationSchema";
import useAuth from "../../hook/useAuth";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    "p + p": {
      marginTop: "15px"
    }
  },
  select: {
    width: "50%",
    maxWidth: "50%",
    marginTop: "15px"
  },
  error: {
    color: "red"
  }
}));


const UserInviteDialog = ({
  open,
  availableRoles,
  handleSubmit,
  handleClose
}) => {

  const { t } = useTranslation();
  const classes = useStyles();

  const [ firstRole ] = availableRoles;

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t("users.invite.title")}
        </DialogTitle>
        <Formik
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            roleId: firstRole ? firstRole.id : undefined,
            phoneNumber: ""
          }}
          onSubmit={handleSubmit}
          validationSchema={UserInviteSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            submitForm,
            touched,
            errors
          }) => (
            <>
              <DialogContent>
                <form className={classes.root}>
                  <TextField
                    required
                    name="firstName"
                    label={t("users.invite.firstName")}
                    error={errors.firstName && touched.firstName}
                    helperText={touched.firstName && t(errors.firstName)}
                    value={values.firstName}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                  />
                  <TextField
                    required
                    name="lastName"
                    label={t("users.invite.lastName")}
                    error={errors.lastName && touched.lastName}
                    helperText={touched.lastName && t(errors.lastName)}
                    value={values.lastName}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                  />
                  <TextField
                    required
                    name="email"
                    label={t("users.invite.email")}
                    error={errors.email && touched.email}
                    helperText={touched.email && t(errors.email)}
                    value={values.email}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                  />
                   <TextField
                    name="phoneNumber"
                    label={t("webapp:common.title.phone")}
                    inputProps={{
                      type: "tel"
                    }}
                    error={errors.phoneNumber && touched.phoneNumber}
                    helperText={touched.phoneNumber && t(errors.phoneNumber)}
                    value={values.phoneNumber}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                  />
                  <Select
                    required
                    variant="outlined"
                    name="roleId"
                    className={classes.select}
                    label={t("users.invite.role")}
                    value={values.roleId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {availableRoles.map(({ id, name }) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("abort")}
                </Button>
                <Button onClick={submitForm} color="primary">
                  {t("invite")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UserInviteDialog;
