import {
  endOfMonth,
  startOfMonth,
  parseISO,
  isBefore,
  differenceInDays,
  endOfToday,
} from "date-fns";
import React, { useCallback, useState, useEffect, useRef } from "react";
import queryString from "query-string";

import {
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  Icon,
  IconButton,
  Link,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { PayIconButton, HrefInNewTabIcon } from "@cargotic/webapp-component";
import useBoltClient from "../../hook/useBoltClient";

import {
  LocalShipping,
  FastForward,
  ArrowDownward,
  ArrowUpward,
  Info,
  SwapVert,
  Warning,
  Error,
  Cancel,
  CheckCircle,
  ModeComment,
  CallReceived,
  CallMade,
} from "@material-ui/icons";

import { ContactType, ShipmentType } from "@cargotic/model";
import { formatDate } from "@cargotic/common";

import ShipmentOverview from "@cargotic/webapp-shipment-overview";
import {
  updateShipmentById,
  useApiClient,
} from "@cargotic/api-client-deprecated";


import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useRouter from "../../hook/useRouter";

import Shipments from "./Shipments";
import useTable from "../../../datatable/useTable";
import { formatJourneyPointDateTime, isDue } from "../../../utility/common";
import {
  getActionFromWaypoint,
  getFormattedPlaceName,
  getAdvancedFormattedPlaceName,
} from "../../../utility/waypoint";

import useAuth from "../../hook/useAuth";
import UserAvatar from "../../../../cargotic-webapp-component/component/UserAvatar";

import { addUrlParam, getTableUrlParams, getUrlWithParams } from "../../../utility/window";

import { storeFilters, loadFilters, storeActiveFilters } from "../../../storage";

import {
  createShipmentsExport,
  createShipmentsQuery,
  deleteShipment,
  createShipmentInvoice,
  createCompanyBankAccountMatchQuery,
  suggestUsers,
  updateShipmentPaidAt,
  updateShipmentState,
  createStateSuggestQuery,
  printShipment,
  createPlacesQuery,
  deleteShipments,
  updateShipment,
  putShipmentBox,
  postExportShipmentsToCSV,
  postExportShipmentsToCSVWithPackages,
  postExportShipmentsToXLSX,
  postExportShipmentsToXLM,
  postExportShipmentToOutcomingOrder
} from "../../../resource";
import ShipmentsDeleteDialog from "./ShipmentsDeleteDialog";
import ShipmentsBoxDialog from "./ShipmentsBoxDialog";
import DetailedShipmentTableRow from "./DetailedShipmentTableRow";

import { useApiClient as useNewApiClient } from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";
import SettingsStateView from "../Settings/SettingsStateView";

import ShipmentState from './ShipmentState'

const useStyles = makeStyles(({ palette }) => ({
  journeyPointActionUpIcon: {
    fill: "#009688",
    height: 20,
    width: 20,
  },
  journeyPointActionDownIcon: {
    fill: palette.error.main,
    height: 20,
    width: 20,
  },
  carriedForwarderIcon: {
    fill: "rgba(0,0,0,0.56)",
  },
  inlineContent: {
    display: "inline-flex",
  },
  warning: {
    fill: "silver",
  },
  error: {
    fill: palette.error.main,
    color: palette.error.main,
  },
  success: {
    fill: "#009688",
  },
}));

const ShipmentsContainer = ({
  setPageLoading,
  setPageShipments,
  setPageSelected,
  deleteDialogOpen,
  setDeleteDialogOpen,

  handleShipmentsImportRequest,
  handleDeleteShipmentRequest,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const pathname = location.pathname.slice(1);

  const [shipments, setShipments] = useState([]);
  const [isLabelsDownloadingLoading, setIsLabelsDownloadingLoading] = useState(false);
  const [boxDialogOpen, setBoxDialogOpen] = useState(false);
  const {
    location: { search: routerSearch },
  } = useRouter();

  const { searchText: initSearchText, filter: initFilter } =
    getTableUrlParams(routerSearch);

  const { isReady: isBoltClientReady, client: boltClient } = useBoltClient();

  if (initFilter.lastWaypointDateFrom) {
    initFilter.lastWaypointDateFrom = new Date(initFilter.lastWaypointDateFrom);
    initFilter.lastWaypointDateTo = new Date(initFilter.lastWaypointDateTo);
  }

  const [additionalFilter, setAdditionalFilter] = useState(initFilter);
  const [isShipmentsExportDialogOpen, setIsShipmentsExportDialogOpen] =
    useState(false);
  const [isShipmentsExportProcessing, setIsShipmentsExportProcessing] =
    useState(false);
  const [isShipmentsDeleteProcessing, setIsShipmentsDeleteProcessing] = useState(false);
  const [isShipmentsExportDone, setIsShipmentsExportDone] = useState(false);
  const [shipmentsExportLink, setShipmentsExportLink] = useState(null);
  const [search, setSearch] = useState(initSearchText);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [allIds, setAllIds] = useState([]);
  const [allPagesSelected, setAllPagesSelected] = useState(false);
  const [currentBoxValue, setCurrentBoxValue] = useState('');
  const [currentBoxId, setCurrentBoxId] = useState(null);

  const handleDeselect = () => setAdditionalFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const onFilterSettingsSubmit = async (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("shipments", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const handleSelectAllOnPage = (bool) => {
    setAllPagesSelected(bool);
  };

  const defaultFilterValues = [
    "drivers",
    "vehicles",
    "loadingDate",
    "unloadingDate",
    "createdAt",
    "state",
  ];
  const availableFilters = [
    {
      label: t("shipments.driver"),
      value: "drivers",
    },
    {
      label: t("shipments.vehicles"),
      value: "vehicles",
    },
    {
      label: t("shipments.loadingsDateRange"),
      value: "loadingDate",
    },
    {
      label: t("shipments.unloadingsDateRange"),
      value: "unloadingDate",
    },
    {
      label: t("incomingOrders.state"),
      value: "state",
    },
    {
      label: t("shipments.creator"),
      value: "creators",
    },
    {
      label: t("shipments.creationDate"),
      value: "createdAt",
    },
    {
      label: t("shipments.cargo"),
      value: "cargo",
    },
    {
      label: t("shipments.draft"),
      value: "isDraft",
    },
  ];

  const classes = useStyles();

  const apiClient = useNewApiClient();
  const oldApiClient = useApiClient();

  let reloadDelay;
  let storeSearchDelay;

  const getShipmentTypeIcon = ({ type, isDraft }) => {
    if (isDraft) {
      return (
        <Tooltip title={t("shipments.draft")}>
          <Warning className={classes.carriedForwarderIcon} />
        </Tooltip>
      );
    }

    if (type === ShipmentType.CARRIED) {
      return (
        <Tooltip title={t("shipments.carried")}>
          <LocalShipping className={classes.carriedForwarderIcon} />
        </Tooltip>
      );
    }
    if (type === ShipmentType.FORWARDED) {
      return (
        <Tooltip title={t("shipments.forwarded")}>
          <FastForward className={classes.carriedForwarderIcon} />
        </Tooltip>
      );
    }

    return null;
  };

  const ShipmentsDateTime = ({ shipment }) => (
    <Grid key={shipment.id} container direction="column">
      {shipment.journey.waypoints.map((waypoint, index) => {
        const waypointAction = waypoint.cargo
          ? getActionFromWaypoint(waypoint.cargo)
          : [];

        const actionIcon = (waypointAction) => {
          if (
            waypointAction.includes("LOAD") &&
            waypointAction.includes("UNLOAD")
          ) {
            return (
              <Tooltip key={`${index}-both`} title={t("shipments.action.both")}>
                <SwapVert className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("LOAD")) {
            return (
              <Tooltip
                key={`${index}-load`}
                title={t("shipments.action.loading")}
              >
                <ArrowUpward className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("UNLOAD")) {
            return (
              <Tooltip
                key={`${index}-unload`}
                title={t("shipments.action.unloading")}
              >
                <ArrowDownward className={classes.journeyPointActionDownIcon} />
              </Tooltip>
            );
          }
          return (
            <Tooltip key={`${index}-none`} title={t("shipments.action.none")}>
              <Info className={classes.journeyPointActionDownIcon} />
            </Tooltip>
          );
        };

        return (
          <Grid
            key={`${index}-grid`}
            container
            item
            spacing={1}
            alignItems="center"
          >
            <Grid item>{actionIcon(waypointAction)}</Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {formatJourneyPointDateTime(
                  waypoint.arriveAtFrom,
                  waypoint.arriveAtTo
                )}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  const ShipmentIncomingOrders = ({ shipment }) => (
    <Grid container>
      <Typography variant="body2">
        {shipment.incomingOrders
          ? shipment.incomingOrders.map(({ id, indexNumber, isDeleted }) => (
            <Grid key={id} item xs={12}>
              {!isDeleted ? (
                <Link
                  component={RouterLink}
                  to={`/incoming-order/${id}`}
                  target="_blank"
                  rel="noopener"
                >
                  {` #${indexNumber}`}
                  <HrefInNewTabIcon />
                </Link>
              ) : (
                ` #${indexNumber}`
              )}
            </Grid>
          ))
          : "-"}
      </Typography>
    </Grid>
  );

  const IncomingOrdersExternalReferences = ({ shipment }) => (
    shipment.incomingOrders
      ? shipment.incomingOrders.map(({ id, externalReference, isDeleted }) =>
        !isDeleted ? (
          <Typography variant="body2">
            <Link
              component={RouterLink}
              to={`/incoming-order/${id}`}
              target="_blank"
              rel="noopener"
            >
              {`${externalReference}`}
              <HrefInNewTabIcon />
            </Link>
          </Typography>
        ) : (
          <Typography variant="body2">
            {externalReference}
          </Typography>
        )
      )
      : "-"
  );

  const IncomingOrdersBoxes = ({ shipment }) => (
    shipment.incomingOrders
      ? shipment.incomingOrders.map(({ id, box, isDeleted }) =>
        <Typography variant="body2">
          {box}
        </Typography>
      ) : "-");

  const ShipmentsRoute = ({ shipment }) => {
    return (
      <Grid key={shipment.id} container>
        {shipment.journey.waypoints.map((waypoint, index) => {
          const { components, formatted } = waypoint.place.address;
          const result = Array.isArray(components)
            ? getAdvancedFormattedPlaceName(components)
            : formatted;
          return (
            <Grid key={index} item xs={12}>
              <Typography variant="body2">{result}</Typography>
            </Grid>
          );
        })}
      </Grid>
    )
  };

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined,
    state: filter.state ? filter.state.map(({ id }) => id) : undefined,
    places: filter.places ? filter.places.map(({ id }) => id) : undefined
  });

  const reloadShipments = useCallback(
    async (offset, limit, ordering) => {
      const filter = transformFilter(additionalFilter);
      try {
        const state = await createStateSuggestQuery({ resources: ["shipment"] });
        const shipmentsResponse =
          await apiClient.shipment.postShipmentMatchQuery({
            query: {
              match: {
                search,
                ...filter,
              },
              orderBy: ordering,
              offset,
              limit,
            },
          });

        setAllIds(shipmentsResponse.allIds || []);
        if (shipmentsResponse.total === 0 && offset !== 0) {
          handleChangePage(undefined, 0);
        }

        const updatedShipments = shipmentsResponse.matches.map((shipment) => {
          shipment.isDisabled = shipment.isDisabled === 1;
          shipment.selected = false;

          shipment.tableCells = [
            {
              render: !shipment.isDraft ? (
                <Typography variant="body2">
                  {" "}
                  #{shipment.indexNumber}
                </Typography>
              ) : (
                <Typography variant="body2">
                  <i>{t("shipments.draft")}</i>
                </Typography>
              ),
            },
            {
              render: (
                <Typography variant="body2">
                  {shipment.vehicle ? (
                    <Link
                      component={RouterLink}
                      to={`/vehicles/${shipment.vehicle.id}`}
                      target="_blank"
                      rel="noopener"
                    >
                      {shipment.vehicle.manufacturer} {shipment.vehicle.model},
                      SPZ: {shipment.vehicle.licensePlate}
                      <HrefInNewTabIcon />
                    </Link>
                  ) : null}
                </Typography>
              ),
            },
            {
              render: shipment.driver ? (
                <Link
                  component={RouterLink}
                  to={`/users/${shipment.driver.id}`}
                >
                  {shipment.driver.name}
                </Link>
              ) : (
                <></>
              ),
            },
            {
              render: <ShipmentsDateTime shipment={shipment} />,
            },
            {
              render: <ShipmentsRoute shipment={shipment} />,
            },
            {
              render: <ShipmentIncomingOrders shipment={shipment} />,
            },
            {
              render: <IncomingOrdersBoxes shipment={shipment} />
            },
            {
              render: !!shipment.stateId && <ShipmentState shipment={shipment} state={state} />,
            },
            {
              render: <Typography variant="body2">{shipment.externalReference}</Typography>
            },
            {
              render: <UserAvatar size="large" user={{ ...shipment.createdBy, name: `${shipment.createdBy.firstName} ${shipment.createdBy.lastName}` }} />
            },
            {
              render: <IncomingOrdersExternalReferences shipment={shipment} />
            }
          ];
          return shipment;
        });


        setShipments(updatedShipments);

        const shipmentData = updatedShipments.reduce(
          (acc, value) => [
            ...acc,
            {
              type: value.type,
              id: value.id,
              row: value.tableCells,
              selected: false,
              detail: ({ setDetailed }) => (
                <ShipmentOverview
                  type={ShipmentOverview.TYPES.SHIPMENT}
                  shipment={value}
                  id={value.id}
                  shipments={updatedShipments}
                  setShipments={setShipments}
                  onSave={async (updatedShipment) => {
                    delete updatedShipment.tableCells;
                    await updateShipmentById(oldApiClient, updatedShipment.id, updatedShipment);
                    enqueueSnackbar(t("shipment.update.success"), { variant: "success" });
                    await reloadData();
                  }}
                  onDraftSave={async (updatedShipment) => {
                    delete updatedShipment.tableCells;
                    await updateShipmentById(oldApiClient, updatedShipment.id, updatedShipment);
                    enqueueSnackbar(t("shipment.update.success"), { variant: "success" });
                    await reloadData();
                  }}
                  open
                  setOpen={(val) => setDetailed(val)}
                />
              ),
            },
          ],
          []
        );

        return { data: shipmentData, totalCnt: shipmentsResponse.total };
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("shipments.error.get"), {
          variant: "error",
        });
      }
    },
    [search, additionalFilter]
  );

  const handleSelectLoadingsDateRange = (loadingDate) =>
    setAdditionalFilter({ ...additionalFilter, loadingDate });
  const handleSelectUnloadingsDateRange = (unloadingDate) =>
    setAdditionalFilter({ ...additionalFilter, unloadingDate });
  const handleSelectCreatedAtDateRange = (createdAt) =>
    setAdditionalFilter({ ...additionalFilter, createdAt });
  const handleSelectShipmentState = (state) =>
    setAdditionalFilter({ ...additionalFilter, state });
  const handleSelectVehicles = (vehicles) =>
    setAdditionalFilter({ ...additionalFilter, vehicles });
  const handleSelectCreators = (creators) =>
    setAdditionalFilter({ ...additionalFilter, creators });
  const handleSelectDrivers = (drivers) =>
    setAdditionalFilter({ ...additionalFilter, drivers });
  const handleSelectCargoTemplate = (cargo) =>
    setAdditionalFilter({ ...additionalFilter, cargo });
  const handleSelectIsDraft = (isDraft) =>
    setAdditionalFilter({ ...additionalFilter, isDraft });
  const handleSelectPlaces = (places) => setAdditionalFilter({ ...additionalFilter, places });

  const handleSelectLastMonth = () => {
    const now = new Date();

    setAdditionalFilter({
      lastWaypointDateFrom: startOfMonth(now),
      lastWaypointDateTo: endOfMonth(now),
    });
  };

  const loadAvailableUsers = async (search, permissions) => {
    try {
      const matches = await suggestUsers({ permissions, search });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search,
        },
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableStates = async (search) => {
    try {
      const states = await createStateSuggestQuery({ resources: ["shipment"] });
      return states;
    } catch (error) {
      enqueueSnackbar(t("settings.error.suggest-state"), {
        variant: "error",
      });
      return undefined;
    }
  };

  const handleLoadAvailablePlaces = async (search) => {
    try {
      const { places } = await createPlacesQuery({
        filter: { search, id: null }
      });
      return places;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  const loadInitVehicle = async (vehicleId) => {
    try {
      const vehicle = await apiClient.vehicle.getVehicle({
        vehicleId,
      });

      setAdditionalFilter({
        ...additionalFilter,
        vehicles: [vehicle],
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  useEffect(() => {
    if (!isBoltClientReady) {
      return;
    }

    const { filterVehicleId, filterContactId } =
      queryString.parse(routerSearch);

    if (filterVehicleId) {
      loadInitVehicle(filterVehicleId);
    }
  }, []);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reload,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData,
  } = useTable(reloadShipments, "shipments");

  useEffect(() => {
    setPageLoading(loading);
  }, [loading]);

  useEffect(() => {
    setPageShipments(shipments);
  }, [shipments]);

  useEffect(() => {
    const selected = loading
      ? null
      : data.filter(({ selected }) => selected).length;

    setPageSelected(selected);
  }, [loading, data]);

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  useEffect(() => {
    if (!isBoltClientReady) {
      return;
    }

    const loadedFilters = loadFilters("shipments");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const selectedShipments = data.filter(({ selected }) => selected);

  const handleDeleteSubmit = async () => {
    setDeleteDialogOpen(false);
    setIsShipmentsDeleteProcessing(true);

    try {
      const ids =
        allPagesSelected ?
          allIds :
          selectedShipments.map(({ id }) => id);
      await deleteShipments(ids);
      await reload();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t("orders.error.delete"), {
        variant: "error",
      });
    } finally {
      setIsShipmentsDeleteProcessing(false);
    }
  };

  const handleCopyOutcomingOrder = async (id) => {
    const result = await postExportShipmentToOutcomingOrder(id);
  }

  const handlePrintShipmentsRequest = async () => {
    try {
      setIsLabelsDownloadingLoading(true);
      const selectedItems =
        allPagesSelected ?
          allIds :
          selectedShipments.map(({ id }) => id);
      const isAnyConcept = shipments.filter(({ id, isDraft }) => selectedItems.includes(id) && isDraft).length > 0;
      if (isAnyConcept) {
        enqueueSnackbar('Chyba během generování rozvozových listů. Některá z přeprav je koncept.', {
          variant: "error",
          persist: true
        });
        setIsLabelsDownloadingLoading(false);
        return;
      }
      await printShipment(selectedItems);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t("shipments.error.printPDF"), {
        variant: "error",
      });
      setIsLabelsDownloadingLoading(false);
    }
  }

  const handleShipmentsExportRequest = () => {
    setIsShipmentsExportDialogOpen(true);
  };

  const closeShipmentsExportDialog = () => {
    setIsShipmentsExportDialogOpen(false);

    if (isShipmentsExportDone) {
      setIsShipmentsExportDone(false);
      setShipmentsExportLink(null);
    }
  };

  const exportXlm = async () => {
    try {
      const data = await postExportShipmentsToXLM(
        allPagesSelected ?
          allIds :
          selectedShipments.map(({ id }) => id)
      );
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      element.setAttribute(
        'download',
        `xml_shipment_orders_${new Date()
          .toLocaleDateString()
          .replaceAll(/\s/g, '')
          .replaceAll(/[\D]/g, '_')
        }.xml`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.log(error);
    } finally {
      setIsShipmentsExportProcessing(false);
    }
  };

  const setBox = async (value) => {
    setCurrentBoxValue(value);
  };

  const handleBoxSubmit = async () => {
    try {
      await putShipmentBox(currentBoxId, currentBoxValue);
      setBoxDialogOpen(false);
      enqueueSnackbar(t("shipments.box.success"), {
        variant: "success",
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("shipments.box.error"), {
        variant: "error",
      });
    }
  };


  const exportShipmentsXlsx = async () => {
    setIsShipmentsExportProcessing(true);

    try {
      const data = await postExportShipmentsToXLSX(
        allPagesSelected ?
          allIds :
          selectedShipments.map(({ id }) => id));
      const element = document.createElement('a');
      element.setAttribute('href', `data:application/octet-stream;base64,${data}`);
      element.setAttribute('download', 'shipments.xlsx');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setShipmentsExportLink(data);
      setIsShipmentsExportDone(true);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("shipments.export.error"), {
        variant: "error",
      });
    } finally {
      setIsShipmentsExportProcessing(false);
    }
  }

  const exportCsvWithPackages = async (language) => {
    setIsShipmentsExportProcessing(true);

    try {
      const data = await postExportShipmentsToCSVWithPackages(
        allPagesSelected ?
          allIds :
          selectedShipments.map(({ id }) => id));
      const element = document.createElement('a');
      element.setAttribute('href', `data:application/octet-stream;base64,${data}`);
      element.setAttribute('download', 'shipments-with-packages.csv');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setShipmentsExportLink(data);
      setIsShipmentsExportDone(true);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("shipments.export.error"), {
        variant: "error",
      });
    } finally {
      setIsShipmentsExportProcessing(false);
    }
  }

  const exportShipments = async (language) => {
    setIsShipmentsExportProcessing(true);

    try {
      const data = await postExportShipmentsToCSV(
        allPagesSelected ?
          allIds :
          selectedShipments.map(({ id }) => id));
      const element = document.createElement('a');
      element.setAttribute('href', `data:application/octet-stream;base64,${data}`);
      element.setAttribute('download', 'shipments.csv');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setShipmentsExportLink(data);
      setIsShipmentsExportDone(true);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("shipments.export.error"), {
        variant: "error",
      });
    } finally {
      setIsShipmentsExportProcessing(false);
    }
  };
  const handleDownloadLabelsFile = (data) => {
    if (typeof data.errors !== 'undefined') {
      console.error(data);
      data.errors.map((error) => {
        enqueueSnackbar(t(error), {
          variant: "error",
        });
      });
      setIsLabelsDownloadingLoading(false);
      return;
    }

    const element = document.createElement('a');
    element.setAttribute('href', 'data:application/pdf;base64,' + data);
    element.setAttribute('download', 'labels.pdf');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setIsLabelsDownloadingLoading(false);
  }

  useEffect(() => {
    const listener = ({ data }) => {
      const { type, content, errors } = JSON.parse(data);
      if (type === "SHIPMENT_LABEL") {
        handleDownloadLabelsFile(content);
      }
    };

    boltClient.addEventListener("message", listener);

    return () => {
      boltClient.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    addUrlParam("filter", additionalFilter);
    const f = getUrlWithParams("filter", additionalFilter);
    storeActiveFilters("shipments", f);
  }, [additionalFilter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250);
  }, [search]);

  const { hasPermission, user } = useAuth();
  const canUpdateShipment =
    hasPermission("resource.shipment.company.update") ||
    hasPermission("resource.shipment.user.update");
  const canCreateInvoice = hasPermission("resource.invoice.create");
  const canCreateShipment = hasPermission("resource.shipment.user.create");
  const canDeleteShipment =
    hasPermission("resource.shipment.user.delete") ||
    hasPermission("resource.shipment.company.delete");
  const canReadInvoice = hasPermission("resource.invoice.read");
  const canReadCompanyShipment = hasPermission(
    "resource.shipment.company.read"
  );
  //todo: permission for pdf
  const canGeneratePdfShipment = true;

  return (
    <>
      <Shipments
        data={data}
        onSelectAllOnPage={handleSelectAllOnPage}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        shipments={shipments}
        setShipments={setShipments}
        loading={loading}
        search={search}
        ordering={ordering}
        direction={direction}
        rowsPerPage={rowsPerPage}
        page={page}
        checkedAll={checkedAll}
        exportShipments={exportShipments}
        exportShipmentsXlsx={exportShipmentsXlsx}
        exportCsvWithPackages={exportCsvWithPackages}
        exportXlm={exportXlm}
        setBox={setBox}
        setShipmentBoxOpen={setBoxDialogOpen}
        setCurrentBoxId={setCurrentBoxId}
        canUpdateShipment={canUpdateShipment}
        canCreateInvoice={canCreateInvoice}
        canCreateShipment={canCreateShipment}
        canDeleteShipment={canDeleteShipment}
        canGeneratePdfShipment={canGeneratePdfShipment}
        canReadInvoice={canReadInvoice}
        canReadCompanyShipment={canReadCompanyShipment}
        isShipmentsExportDialogOpen={isShipmentsExportDialogOpen}
        isShipmentsExportProcessing={isShipmentsExportProcessing}
        isShipmentsExportDone={isShipmentsExportDone}
        isLabelsDownloadingLoading={isLabelsDownloadingLoading}
        shipmentsExportLink={shipmentsExportLink}
        isShipmentsDeleteProcessing={isShipmentsDeleteProcessing}
        additionalFilter={additionalFilter}
        closeShipmentsExportDialog={closeShipmentsExportDialog}
        handleSort={handleSort}
        handleChangePage={handleChangePage}
        handleShipmentsExportRequest={handleShipmentsExportRequest}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleSelectAll={handleSelectAll}
        handleSelect={handleSelect}
        handleDeleteRequest={() => setDeleteDialogOpen(true)}
        handleSelectLastMonth={handleSelectLastMonth}
        filter={additionalFilter}
        handleSelectShipmentState={handleSelectShipmentState}
        handleDeselect={handleDeselect}
        loadAvailableUsers={loadAvailableUsers}
        defaultFilters={defaultFilters}
        handleSelectLoadingsDateRange={handleSelectLoadingsDateRange}
        handleSelectUnloadingsDateRange={handleSelectUnloadingsDateRange}
        handleSelectCreatedAtDateRange={handleSelectCreatedAtDateRange}
        handleSelectCreators={handleSelectCreators}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        handleSelectDrivers={handleSelectDrivers}
        handleSelectCargoTemplate={handleSelectCargoTemplate}
        handleSelectIsDraft={handleSelectIsDraft}
        handleSelectPlaces={handleSelectPlaces}
        handleSelectVehicles={handleSelectVehicles}
        loadAvailableVehicles={loadAvailableVehicles}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        loadAvailableStates={loadAvailableStates}
        handleLoadAvailablePlaces={handleLoadAvailablePlaces}

        handleShipmentsImportRequest={handleShipmentsImportRequest}
        handleDeleteShipmentRequest={handleDeleteShipmentRequest}
        handlePrintShipmentsRequest={handlePrintShipmentsRequest}
        handleCopyOutcomingOrder={handleCopyOutcomingOrder}
      />
      <ShipmentsDeleteDialog
        open={deleteDialogOpen}
        allPagesSelected={allPagesSelected}
        allIdsLength={allIds.length}
        selected={selectedShipments.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
      <ShipmentsBoxDialog
        open={boxDialogOpen}
        setBox={setBox}
        handleClose={() => setBoxDialogOpen(false)}
        handleSubmit={handleBoxSubmit}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default ShipmentsContainer;
