import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ContactType } from "@cargotic/model";

import ContactProfileView
  from "../../../cargotic-webapp-contact/ContactProfileView";
import ContactProfileContainer
  from "../../../cargotic-webapp-contact/component/ContactProfileContainer";

const ContactProfileContainerAdapter = ({ view }) => {
  const history = useHistory();
  const params = useParams();

  const contactId = parseInt(params.id, 10);

  const handleViewChange = value => {
    switch (value) {
      case ContactProfileView.CONTACT_EMPLOYEE_OVERVIEW: {
        history.push(`/contacts/${contactId}/employees`);
        return;
      }

      case ContactProfileView.CONTACT_OVERVIEW: {
        history.push(`/contacts/${contactId}`);
        return;
      }

      case ContactProfileView.CONTACT_VEHICLES_OVERVIEW: {
        history.push(`/contacts/${contactId}/vehicles`);
        return;
      }

      default: {
        throw new Error(`Invalid contact profile view '${view}'`);
      }
    }
  };

  const handleContactsRedirect = () => history.push("/contacts");

  const handleShipmentRedirect = contactType => {
    switch (contactType) {
      case ContactType.BOTH: {
        return history.push("/shipment");
      }

      case ContactType.CARRIER: {
        return history.push(`/shipment?carrierId=${contactId}`);
      }

      case ContactType.CUSTOMER: {
        return history.push(`/shipment?customerId=${contactId}`);
      }

      default: {
        throw new Error(`Invalid contact type ${contactType}`);
      }
    }
  };

  return (
    <ContactProfileContainer
      contactId={contactId}
      view={view}
      onContactsReditect={handleContactsRedirect}
      onShipmentRedirect={handleShipmentRedirect}
      onViewChange={handleViewChange}
    />
  );
};

export default ContactProfileContainerAdapter;
