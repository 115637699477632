import React from "react";

import { DesktopDatePicker } from "@material-ui/pickers";
import { TextField } from "@material-ui/core";

const FormikDatePicker = ({
  form,
  name,
  format = "d.MM.yyyy",
  fullWidth,
  InputProps,
  placeholder,
  required,
  ...props
}) => {
  const value = form.values[name];
  const error = form.errors[name];
  const isTouched = form.touched[name];

  const getCleanDate = (value) => {
    if (!isNaN(value.getTime())) {
      const timezoneOffset = value.getTimezoneOffset() * 60000;
      const date = new Date(value.getTime() - timezoneOffset);
      return date;
    }
    return value;
  }

  const handleChange = newValue => (
    form.setValues({ ...form.values, [name]: getCleanDate(newValue) })
  );

  return (
    <DesktopDatePicker
      {...props}
      inputFormat={format}
      value={value}
      clearable
      autoOk
      disableMaskedInput
      allowSameDateSelection
      onError={console.error}
      allowKeyboardControl
      renderInput={(props) => (
        <TextField
          required={required}
          name={name}
          {...props}
          InputProps={{
            ...props.InputProps,
            ...InputProps
          }}
          value={props.inputProps.value}
          error={isTouched && error !== undefined}
          onBlur={form.handleBlur}
          helperText={isTouched && error !== undefined ? error : " "}
          inputProps={{
            ...props.inputProps,
            placeholder: placeholder ?? "1.1.2020"
          }}
          fullWidth={fullWidth}
          variant="standard"
        />)
      }
      onChange={handleChange}
      onAccept={handleChange}
    />
  );
};

export default FormikDatePicker;
