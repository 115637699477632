import React from "react";

import {
  Typography,
  makeStyles,
  Divider,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { ContactType, VehicleType } from "@cargotic/model";

import { Currency } from "@cargotic/currency";
import { useTranslation } from "react-i18next";
import {
  ContactFormAutosuggestTextField,
  EmployeeFormAutosuggestTextField,
} from "../../cargotic-webapp/contact";
import {
  FormCheckbox,
  FormSelect,
  FormTextField,
} from "../../cargotic-webapp/form";

import {
  useApiClient as useNewApiClient,
  FormikVehicleAutosuggestTextField,
  FormUserAutosuggestTextField,
} from "../../cargotic-webapp-component";

import { formatDateTime } from "../../../multiload/cargotic-common";

import IncomingOrderServicesInput from "../../cargotic-webapp/incomingOrder/component/IncomingOrderFormContent/IncomingOrderServicesInput";

import FileDropzone from "../../../multiload/cargotic-webapp/component/FileDropzone";
import FileUpload from "../../../multiload/cargotic-webapp/component/FileUpload";
import Placeholder from "../../cargotic-webapp/component/common/Placeholder";
import AttachmentPreview from "../../cargotic-webapp-component/component/AttachmentPreview";

const useStyles = makeStyles(({ spacing }) => ({
  tabContentContainer: {
    paddingTop: spacing(1),
  },
  subStatusText: {
    fontSize: 28,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: spacing(3),
    textTransform: "uppercase",
  },
  markPoint: {
    height: 8,
    width: 1,
    marginTop: -3,
  },
  containerMarginBottom: {
    marginBottom: spacing(2),
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
  caption: {
    fontSize: 11,
    fontWeight: 400,
    color: "rgba(0,0,0,0.6)",
  },
  gutterBottom: {
    marginBottom: spacing(2),
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  isDrivenThrough: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  isDrivenThroughIcon: {
    marginLeft: 5,
    marginRight: 15,
    width: 18,
    height: 18,
  },
  cardLabel: {
    borderRadius: 10,
    marginRight: 8,
    width: 50,
    height: 8,
  },
  cardLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  labelsColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  infoHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  contactTable: {
    width: "98%",
  },
  priceRow: {
    "& > :not(:first-child)": {
      paddingLeft: spacing(2),
    },
  },
  waypointAddress: {
    marginLeft: "4px",
  },
  preview: {
    backgroundSize: "contain",
    width: "100%",
    height: "auto"
  }
}));

const OrderFormComponent = ({
  form,
  availableServices,

  handleDocumentFilesSelect,
  handleDocumentDelete,

  hasTermsDocumentFileDropzoneError,
  handleTermsDocumentFileDropzoneError,

  hasAdditionalDocumentFileDropzoneError,
  handleAdditionalDocumentFileDropzoneError,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newApiClient = useNewApiClient();

  return (
    <>
      <section>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>
              {t("webapp:shipment.form.subtitle.customer")}
            </Typography>
            <Grid container className={classes.contactTable}>
              <Grid item xs={12}>
                <ContactFormAutosuggestTextField
                  form={form}
                  contactType={ContactType.CUSTOMER}
                  apiClient={newApiClient}
                  name="customerContact"
                  label={t("webapp:shipment.form.label.contact")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <EmployeeFormAutosuggestTextField
                  form={form}
                  apiClient={newApiClient}
                  contactId={form.values.customerContact?.id}
                  disabled={!form.values.customerContact?.id}
                  name="customerEmployee"
                  label={t("webapp:shipment.form.label.employee")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="subtitle2">
          {t("incomingOrders.services")}
        </Typography>
        <Placeholder
          render={() => (
            <IncomingOrderServicesInput
              form={form}
              name="services"
              availableServices={availableServices}
              isReadOnly
            />
          )}
          loading={!availableServices || !availableServices.length}
        />
      </section>
      <section>
        <Typography variant="subtitle2">
          {t("incomingOrders.totalPrice")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <FormTextField
              form={form}
              name="customerPrice"
              label={t("webapp:incomingOrder.form.label.price")}
              hasFullWidth
              isDisabled
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormSelect
              form={form}
              name="customerPriceCurrency"
              label={t("webapp:incomingOrder.form.label.currency")}
              hasFullWidth
            >
              {Object.values(Currency).map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormCheckbox
              form={form}
              name="isCustomerPriceWithDph"
              label={t("webapp:incomingOrder.form.label.isWithDph")}
              color="primary"
            />
          </Grid>
        </Grid>
      </section>
      <Divider className={classes.divider} />
      <section>
        <Typography className={classes.subtitle}>
          {t("webapp:shipment.form.subtitle.documents")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.caption}>
              {t("webapp:common.title.terms-documents")}
            </Typography>
            <FileDropzone
              accept={["application/pdf", "image/jpeg", "image/png"]}
              title={
                hasTermsDocumentFileDropzoneError
                  ? t("webapp:shipment.form.document.error.file.type")
                  : t("webapp:shipment.form.document.title.upload")
              }
              description={[
                t("webapp:shipment.form.document.description.about"),
                t("webapp:shipment.form.document.description.supported"),
                t("webapp:shipment.form.document.description.limit"),
              ]}
              hasError={hasTermsDocumentFileDropzoneError}
              onError={handleTermsDocumentFileDropzoneError}
              onSelect={(files) =>
                handleDocumentFilesSelect(
                  files,
                  form,
                  "cgt-staging-incoming-order-terms-documents"
                )
              }
            />
            {form.values.documents
              .filter(
                ({ bucket }) =>
                  bucket === "cgt-staging-incoming-order-terms-documents"
              )
              .map(
                ({ uuid, name, url, error, isUploading, createdAt }, index) => (
                  <React.Fragment key={uuid}>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                      <FileUpload
                        className={index === 0 ? classes.upload : undefined}
                        title={name}
                        description={
                          createdAt ? formatDateTime(createdAt) : undefined
                        }
                        url={url}
                        isUploading={isUploading}
                        error={error}
                        onDelete={() =>
                          handleDocumentDelete(
                            uuid,
                            form,
                            "cgt-staging-incoming-order-terms-documents"
                          )
                        }
                      />
                    </Grid>
                    <AttachmentPreview url={url} name={name}></AttachmentPreview>
                  </React.Fragment>
                )
              )}
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.caption}>
              {t("webapp:common.title.additional-documents")}
            </Typography>
            <FileDropzone
              accept={["application/pdf", "image/jpeg", "image/png"]}
              title={
                hasAdditionalDocumentFileDropzoneError
                  ? t("webapp:shipment.form.document.error.file.type")
                  : t("webapp:shipment.form.document.title.upload")
              }
              description={[
                t("webapp:shipment.form.document.description.about"),
                t("webapp:shipment.form.document.description.supported"),
                t("webapp:shipment.form.document.description.limit"),
              ]}
              hasError={hasAdditionalDocumentFileDropzoneError}
              onError={handleAdditionalDocumentFileDropzoneError}
              onSelect={(files) =>
                handleDocumentFilesSelect(
                  files,
                  form,
                  "cgt-staging-incoming-order-additional-documents"
                )
              }
            />

            {form.values.documents
              .filter(
                ({ bucket }) =>
                  bucket ===
                  "cgt-staging-incoming-order-additional-documents"
              )
              .map(
                ({ uuid, name, url, error, isUploading, createdAt }, index) => (
                  <React.Fragment key={uuid}>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                      <FileUpload
                        className={index === 0 ? classes.upload : undefined}
                        title={name}
                        description={
                          createdAt ? formatDateTime(createdAt) : undefined
                        }
                        url={url}
                        isUploading={isUploading}
                        error={error}
                        onDelete={() =>
                          handleDocumentDelete(
                            uuid,
                            form,
                            "cgt-staging-incoming-order-additional-documents"
                          )
                        }
                      />
                    </Grid>
                    <AttachmentPreview url={url} name={name}></AttachmentPreview>
                  </React.Fragment>
                )
              )}
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default OrderFormComponent;
