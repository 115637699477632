import React from "react";

import { Tooltip } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const HrefInNewTabIcon = () => {
    const { t } = useTranslation();

    return (
        <Tooltip title={t("webapp:cargo.editor.button.new-tab")}>
            <Launch style={{ width: 12, height: 12 }} />
        </Tooltip>);
};

export default HrefInNewTabIcon;