import React from "react";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";
import { TextField, Button } from "@material-ui/core";
import { SignUpUserSchema } from "../../../utility/validationSchema";

const SignUpUserForm = ({ initialValues, setFormUser, handleNext }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignUpUserSchema}
      onSubmit={({
        firstName, lastName, email, password, reenterPassword
      }) => {
        setFormUser({
          firstName, lastName, email, password, reenterPassword
        });
        return handleNext();
      }}
      validate={(values) => {
        const errors = {};

        if (values.password !== values.reenterPassword) {
          errors.reenterPassword = "auth.error.passwordMismatch";
        }

        return errors;
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched
      }) => (
        <form onSubmit={handleSubmit}>
          <section>
            <TextField
              name="firstName"
              label={t("firstName")}
              fullWidth
              helperText={touched.firstName && t(errors.firstName) || " "}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.firstName && touched.firstName}
              value={values.firstName}
              inputProps={{ maxLength: 20 }}
              required
            />
            <TextField
              name="lastName"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.lastName && t(errors.lastName) || " "}
              error={errors.lastName && touched.lastName}
              value={values.lastName}
              label={t("lastName")}
              inputProps={{ maxLength: 25 }}
              required
            />
            <TextField
              name="email"
              label={t("email")}
              fullWidth
              required
              helperText={touched.email && t(errors.email) || " "}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              value={values.email}
            />
            <TextField
              name="password"
              label={t("password")}
              type="password"
              fullWidth
              helperText={touched.password && t(errors.password) || " "}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password}
              value={values.password}
              required
            />
            <TextField
              name="reenterPassword"
              label={t("reenterPassword")}
              type="password"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.reenterPassword && t(errors.reenterPassword) || " "}
              error={errors.reenterPassword && touched.reenterPassword}
              value={values.reenterPassword}
              required
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              variant="contained"
            >
              {t("next")}
            </Button>
          </section>
        </form>
      )}
    </Formik>
  );
};

export default SignUpUserForm;
