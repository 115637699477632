import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

import React from "react";

const LinkField = ({ value, label }) => (
  <TextField
    value={value}
    variant="outlined"
    label={label}
    InputProps={{
      startAdornment: (
        <InputAdornment>
          <IconButton disabled>
            <LinkIcon />
          </IconButton>
        </InputAdornment>
      )
    }}
    fullWidth
  />
);

export default LinkField;
