import React, { useState } from "react";
import {
  Button, makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    padding: spacing(2),
    margin: spacing(1),
    fontWeight: 500,
  }
}));

const SelectOrderType = ({ handleShipmentSelected, handleOutcomingOrderSelected }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleShipmentSelected}
      >
        {t("shipments.carriedTo")}
      </Button>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={handleOutcomingOrderSelected}
      >
        {t("shipments.forwardedTo")}
      </Button>
    </>
  );
};

export default SelectOrderType;
