import React, { useEffect, useState } from "react";

import { Fab, makeStyles, Tooltip, } from "@material-ui/core";

import { ArrowDownward, ArrowUpward, CloudDownloadOutlined, Timer10 } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { ContactType } from "@cargotic/model";

import DataTable from "../../../datatable";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import SearchTextfield from "../../common/SearchTextfield";
import SendOrderDialog from "../../../incomingOrder/component/SendOrderDialog";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import { WarehouseOrdersModeEnum } from "../../enums/enums";
import ReceiveIncomingOrdersDialog from "./ReceiveIncomingOrdersDialog";
import DispatchIncomingOrdersDialog from "./DispatchIncomingOrdersDialog";
import { loadAvailableWarehouseStates } from "./utils/utils";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import ExportDialog from "../IncomingOrders/ExportDialog";
import useAuth from "../../hook/useAuth";
import { addUrlParam, getTableUrlParams } from "../../../utility/window";
import { reloadResources } from "i18next";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger,
    },
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)",
  },
}));

export const AssociatedWarehouseOrdersContext = React.createContext({})

const WarehouseOrders = ({
  data,
  mode,
  dataCount,
  selectedColumns,
  incomingOrders,
  setIncomingOrders,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  canCreateIncomingOrder,
  canUpdateIncomingOrder,
  canDeleteIncomingOrder,
  canReadCompanyIncomingOrder,
  additionalFilter: {
    type: additionalFilterType,
    lastWaypointDateFrom: additionalFilterLastDateFrom,
  },
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleWarehouseOrdersExportRequest,
  handleSelectAll,
  handleSelect,
  handleDeleteRequest,
  filter,
  handleSelectIncomingOrderState,
  handleSelectWarehouseOrderState,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectReceivedDateDateRange,
  handleSelectDispatchedDateDateRange,
  handleSelectDeliveryDateDateRange,
  handleSelectCreators,
  handleSelectReceivedBy,
  handleSelectDispatchedBy,
  loadAvailableUsers,
  handleSelectCustomer,
  handleSelectCustomerPrice,
  loadAvailableContacts,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleSendOrder,
  handleChangeSelectedColumns,
  handleDialogClosed,
  warehouseId,
  isWarehouseOrdersExportDone,
  isWarehouseOrdersExportDialogOpen,
  isWarehouseOrdersExportProcessing,
  warehouseOrdersExportLink,
  closeWarehouseOrdersExportDialog,
  exportWarehouseOrders,
  rinkaiDepo,
  setRinkaiDepo,
  exportOrdersRinkai,
  handleRinkaiDepoInputChange
}) => {
  const router = useRouter();
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, hasPermission } = useAuth();

  const {
    location: { search: routerSearch },
  } = useRouter();

  const { filter: initFilter } =
    getTableUrlParams(routerSearch);

  const [additionalFilter] = useState(initFilter);

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);

  const [selectValue, setSelectValue] = useState('rinkai');
  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
  };
  const canOperateRinkai = hasPermission("resource.rinkai.all");
  const EXPORT_TABS = [
    { id: 0, labelKey: "invoices.export.main", type: "export" },
    { id: 1, labelKey: "invoices.export.api", type: "api" }
  ];
  const [selectedExportTab, handleSetSelectedExportTab] = useState(EXPORT_TABS.find((tab) => tab.type === "export") || EXPORT_TABS[0]);

  const [warehouseDispatchDialogOpen, setWarehouseDispatchDialogOpen] = useState(false);
  const [warehouseReceiveDialogOpen, setWarehouseReceiveDialogOpen] = useState(false);

  const [associatedWarehouseOrders, setAssociatedWarehouseOrders] = useState({});

  const [loadingPayAction, setLoadingPayAction] = useState(false);

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));


  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const warehouseOrdersHeader = [
    {
      name: "indexNumber",
      label: "incomingOrders.number",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "customerContact",
      label: "incomingOrders.customer",
      isOrderable: false,
    },
    {
      name: "pickupAtStart",
      label: "incomingOrders.date",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "route",
      label: "incomingOrders.route",
      isOrderable: false,
    },
    {
      name: "externalReference",
      label: "incomingOrders.externalReference",
      isOrderable: false
    },
    {
      name: "customerPrice",
      label: "incomingOrders.customerPrice",
      isOrderable: false,
    },
    {
      name: "packages",
      label: "incomingOrders.warehousePackages",
      isOrderable: false,
    },
    {
      name: "receivedDate",
      label: "incomingOrders.warehouseReceivedDate",
      isOrderable: true,
    },
    {
      name: "receivedBy",
      label: "incomingOrders.warehouseReceivedBy",
      isOrderable: true,
    },
  ];

  if (mode === WarehouseOrdersModeEnum.INCOMING_ORDERS) {
    warehouseOrdersHeader.splice(
      4,
      0,
      {
        name: "warehouseState",
        label: "incomingOrders.warehouseState",
        isOrderable: true,
        width: "6%",
      }
    )
  }

  if (mode === WarehouseOrdersModeEnum.ORDERS_HISTORY) {
    warehouseOrdersHeader.push(
      {
        name: "dispatchedBy",
        label: "incomingOrders.warehouseDispatchedBy",
        isOrderable: true,
        width: "6%",
      },
      {
        name: "dispatchedDate",
        label: "incomingOrders.warehouseDispatchedDate",
        isOrderable: true,
        width: "6%",
      }
    )
  }

  const isAnyFilterActive = () => {
    const {
      types,
      drivers,
      carrierPrice,
      customerPrice,
      commission,
      carriers,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      issuedPaymentState,
      receivedPaymentState,
      vehicles,
      isDraft,
    } = filter;

    return [
      types,
      drivers,
      carrierPrice,
      customerPrice,
      commission,
      carriers,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      issuedPaymentState,
      receivedPaymentState,
      vehicles,
      isDraft,
    ].some((item) => item !== undefined);
  };

  const warehouseOrdersFilters = [
    <ContactFilterDropdown
      id="customers"
      key="customers"
      onChange={(value) =>
        handleSelectCustomer(value.length === 0 ? undefined : value)
      }
      selectAll={(all) => handleSelectCustomer(all)}
      value={filter.customers ?? []}
      search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
      placeholderTitle={t("contacts.customer")}
      onClear={() => handleSelectCustomer(undefined)}
      getTitle={(item) => item.name}
    />,
    <FilterDateRangeDropdown
      id="loadingDate"
      key="loadingDate"
      onChange={(value) => handleSelectLoadingsDateRange(value)}
      value={
        filter.loadingDate
          ? [filter.loadingDate.from, filter.loadingDate.to]
          : []
      }
      onClear={() => handleSelectLoadingsDateRange(undefined)}
      placeholderTitle={t("incomingOrders.loadingsDateRange")}
    />,
    <FilterDateRangeDropdown
      id="unloadingDate"
      key="unloadingDate"
      onChange={(value) => handleSelectUnloadingsDateRange(value)}
      value={
        filter.unloadingDate
          ? [filter.unloadingDate.from, filter.unloadingDate.to]
          : []
      }
      onClear={() => handleSelectUnloadingsDateRange(undefined)}
      placeholderTitle={t("incomingOrders.unloadingsDateRange")}
    />,
    <FilterDateRangeDropdown
      id="createdAt"
      key="createdAt"
      onChange={(value) => handleSelectCreatedAtDateRange(value)}
      value={
        filter.createdAt
          ? [filter.createdAt.from, filter.createdAt.to]
          : []
      }
      onClear={() => handleSelectCreatedAtDateRange(undefined)}
      placeholderTitle={t("incomingOrders.creationDate")}
    />,
    <FilterDateRangeDropdown
      id="receivedDate"
      key="receivedDate"
      onChange={(value) => handleSelectReceivedDateDateRange(value)}
      value={
        filter.receivedDate
          ? [filter.receivedDate.from, filter.receivedDate.to]
          : []
      }
      onClear={() => handleSelectReceivedDateDateRange(undefined)}
      placeholderTitle={t("incomingOrders.warehouseReceivedDate")}
    />,
    <FilterDateRangeDropdown
      id="deliveryDate"
      key="deliveryDate"
      onChange={(value) => handleSelectDeliveryDateDateRange(value)}
      value={
        filter.deliveryDate
          ? [filter.deliveryDate.from, filter.deliveryDate.to]
          : []
      }
      onClear={() => handleSelectDeliveryDateDateRange(undefined)}
      placeholderTitle={t("incomingOrders.warehouseDeliveryDate")}
    />,
    <FilterNumberRange
      id="customerPrice"
      key="customerPrice"
      onChange={(value) => handleSelectCustomerPrice(value)}
      value={filter.customerPrice}
      placeholderTitle={t("incomingOrders.customerPrice")}
      onClear={() => handleSelectCustomerPrice(undefined)}
    />,
    <UserFilterDropdown
      id="creators"
      key="creators"
      onChange={(value) =>
        handleSelectCreators(value.length === 0 ? undefined : value)
      }
      selectAll={(all) => handleSelectCreators(all)}
      value={filter.creators ?? []}
      placeholderTitle={t("incomingOrders.creator")}
      search={(text) => loadAvailableUsers(text)}
      onClear={() => handleSelectCreators(undefined)}
      getTitle={(item) => item.name}
    // disabled={!canReadCompanyIncomingOrder}
    />,
    <UserFilterDropdown
      id="receivedBy"
      key="receivedBy"
      onChange={(value) =>
        handleSelectReceivedBy(value.length === 0 ? undefined : value)
      }
      selectAll={(all) => handleSelectReceivedBy(all)}
      value={filter.receivedBy ?? []}
      placeholderTitle={t("incomingOrders.warehouseReceivedBy")}
      search={(text) => loadAvailableUsers(text)}
      onClear={() => handleSelectReceivedBy(undefined)}
      getTitle={(item) => item.name}
    // disabled={!canReadCompanyIncomingOrder}
    />,
    <FilterStaticDropdown
      id="cargo"
      key="cargo"
      placeholderTitle={t("incomingOrders.cargo")}
      value={filter.cargo ?? []}
      onChange={(arr) =>
        handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
      }
      selectAll={() =>
        handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
      }
      onClear={() => handleSelectCargoTemplate(undefined)}
      source={AvailableCargoItemTemplates.map(({ label, value }) => ({ title: t(label), value }))}
    />,
  ]

  if (mode === WarehouseOrdersModeEnum.INCOMING_ORDERS) {
    warehouseOrdersFilters.splice(
      4,
      0,
      <StateFilterDropdown
        id="warehouseOrderState"
        key="warehouseOrderState"
        placeholderTitle={t("board.warehouseState.title")}
        value={filter.state ?? []}
        onChange={(arr) =>
          handleSelectWarehouseOrderState(arr.length === 0 ? undefined : arr)
        }
        search={(text) => loadAvailableWarehouseStates(text)}
        selectAll={(all) =>
          handleSelectWarehouseOrderState(all)
        }
        onClear={() => handleSelectWarehouseOrderState(undefined)}
        getTitle={(item) => item.name}
      />,
    )
  }

  if (mode === WarehouseOrdersModeEnum.ORDERS_HISTORY) {
    warehouseOrdersFilters.push(
      <UserFilterDropdown
        id="dispatchedBy"
        key="dispatchedBy"
        onChange={(value) =>
          handleSelectDispatchedBy(value.length === 0 ? undefined : value)
        }
        selectAll={(all) => handleSelectReceivedBy(all)}
        value={filter.dispatchedBy ?? []}
        placeholderTitle={t("incomingOrders.warehouseDispatchedBy")}
        search={(text) => loadAvailableUsers(text)}
        onClear={() => handleSelectDispatchedBy(undefined)}
        getTitle={(item) => item.name}
      // disabled={!canReadCompanyIncomingOrder}
      />,
      <FilterDateRangeDropdown
        id="dispatchedDate"
        key="dispatchedDate"
        onChange={(value) => handleSelectDispatchedDateDateRange(value)}
        value={
          filter.dispatchedDate
            ? [filter.dispatchedDate.from, filter.dispatchedDate.to]
            : []
        }
        onClear={() => handleSelectDispatchedDateDateRange(undefined)}
        placeholderTitle={t("incomingOrders.warehouseDispatchedDate")}
      />,
    )
  }

  return (
    <Page
      title={ }
      actions={
        <>
          {mode === WarehouseOrdersModeEnum.INCOMING_ORDERS ? (
            <>
              <Tooltip title={t("incomingOrders.warehouseOrderReceive")}>
                <span>
                  <Fab
                    color="primary"
                    // disabled={loading || !canCreateIncomingOrder}
                    onClick={() => {
                      setWarehouseReceiveDialogOpen(true)
                    }}
                  >
                    <ArrowDownward />
                  </Fab>
                </span>
              </Tooltip>
              <Tooltip title={t("incomingOrders.warehouseOrderDispatch")}>
                <span>
                  <Fab
                    color="inherit"
                    // disabled={loading || !canCreateIncomingOrder}
                    onClick={() => {
                      setWarehouseDispatchDialogOpen(true)
                    }}
                  >
                    <ArrowUpward />
                  </Fab>
                </span>
              </Tooltip>
              <Tooltip title={t("incomingOrders.export.action")}>
                <span>
                  <Fab
                    color="primary"
                    disabled={loading || !selected}
                    onClick={handleWarehouseOrdersExportRequest}
                  >
                    <CloudDownloadOutlined />
                  </Fab>
                </span>
              </Tooltip>
            </>) : (
            <>
              <Tooltip title={t("incomingOrders.export.action")}>
                <span>
                  <Fab
                    color="primary"
                    disabled={loading || !selected}
                    onClick={handleWarehouseOrdersExportRequest}
                  >
                    <CloudDownloadOutlined />
                  </Fab>
                </span>
              </Tooltip>
            </>)
          }
        </>
      }
      tertiaryActions={ }
    >
      <FilterContainer
        searchField={
          <SearchTextfield
            handleSearch={handleSearch}
            value={search}
            fullWidth
            placeholder={t("incomingOrders.searchTooltip")}
          />
        }
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={warehouseOrdersFilters}
        onClear={handleDeselect}
      />
      <DataTable
        headers={warehouseOrdersHeader}
        actions={[]}
        selectedColumns={selectedColumns}
        data={data}
        dataCount={dataCount}
        loading={loading || loadingPayAction}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        enableDetail
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        onChangeSelectedColumns={handleChangeSelectedColumns}
      />
      <AssociatedWarehouseOrdersContext.Provider value={[associatedWarehouseOrders, setAssociatedWarehouseOrders]}>
        <ReceiveIncomingOrdersDialog
          open={warehouseReceiveDialogOpen}
          handleClose={() => {
            addUrlParam("filter", additionalFilter);
            setWarehouseReceiveDialogOpen(false)
          }}
          handleSubmit={handleDialogClosed}
          warehouseId={warehouseId}
        />
        <DispatchIncomingOrdersDialog
          open={warehouseDispatchDialogOpen}
          handleClose={() => {
            addUrlParam("filter", additionalFilter)
            setWarehouseDispatchDialogOpen(false)
          }}
          handleSubmit={handleDialogClosed}
          warehouseId={warehouseId}
        />
      </AssociatedWarehouseOrdersContext.Provider>
      <ExportDialog
        infoText={t("orders.export.info.text")}
        title={t("orders.export.title")}
        closeButtonLabel={t("orders.export.close")}
        downloadCSV={t("orders.export.downloadCSV")}
        startButtonLabel={t("orders.export.start")}
        processingText={t("orders.export.processing.text")}
        doneText={t("orders.export.done.text")}
        linkLabel={t("orders.export.done.link")}
        isDone={isWarehouseOrdersExportDone}
        isOpen={isWarehouseOrdersExportDialogOpen}
        isProcessing={isWarehouseOrdersExportProcessing}
        link={warehouseOrdersExportLink}
        close={closeWarehouseOrdersExportDialog}
        exportCsv={exportWarehouseOrders}
        tabs={EXPORT_TABS}
        selectValue={selectValue}
        handleSelectChange={handleSelectChange}
        canOperateRinkai={canOperateRinkai}
        setSelectedExportTab={handleSetSelectedExportTab}
        rinkaiDepo={rinkaiDepo}
        setRinkaiDepo={setRinkaiDepo}
        selectedExportTab={selectedExportTab}
        exportOrdersRinkai={exportOrdersRinkai}
        handleRinkaiDepoInputChange={handleRinkaiDepoInputChange}
      />
    </Page>
  );
};

export default WarehouseOrders;
