import React from "react";
import { useTranslation } from "react-i18next";

import {
  Divider,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  LocalShipping as LocalShippingIcon
} from "@material-ui/icons";

import { OutlinedIconButton } from "../../../cargotic-webapp-component";
import ContactProfileDetailContentHeaderAvatar
  from "./ContactProfileDetailContentHeaderAvatar";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    borderBottom: `1px solid ${palette.divider}`,

    textAlign: "center",

    padding: spacing(2),

    "& > :not(:first-child)": {
      marginTop: spacing(2)
    }
  },
  icons: {
    display: "flex",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    }
  }
}));

const ContactProfileDetailContentHeader = ({
  type,
  name,
  avatarUrl,
  isCreateShipmentButtonDisabled,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  onAvatarChange,
  onDeleteButtonClick,
  onEditButtonClick,
  onShipmentButtonClick
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <ContactProfileDetailContentHeaderAvatar
          avatarUrl={avatarUrl}
          name={name}
          onChange={onAvatarChange}
        />
      </div>
      <div>
        <Typography variant="h5">
          {name}
        </Typography>
        <Typography>
          {t(`webapp:contact.type.${type}`)}
        </Typography>
      </div>
      <div className={classes.icons}>
        <Tooltip
          title={t("webapp:common.title.create-shipment")}
          disableFocusListener={isCreateShipmentButtonDisabled}
          disableHoverListener={isCreateShipmentButtonDisabled}
          disableTouchListener={isCreateShipmentButtonDisabled}
        >
          <span>
            <OutlinedIconButton onClick={onShipmentButtonClick}>
              <LocalShippingIcon />
            </OutlinedIconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t("webapp:contact.title.edit")}
          disableFocusListener={isEditButtonDisabled}
          disableHoverListener={isEditButtonDisabled}
          disableTouchListener={isEditButtonDisabled}
        >
          <span>
            <OutlinedIconButton onClick={onEditButtonClick}>
              <EditIcon />
            </OutlinedIconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t("webapp:contact.title.delete")}
          disableFocusListener={isDeleteButtonDisabled}
          disableHoverListener={isDeleteButtonDisabled}
          disableTouchListener={isDeleteButtonDisabled}
        >
          <span>
            <OutlinedIconButton onClick={onDeleteButtonClick}>
              <DeleteIcon />
            </OutlinedIconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default ContactProfileDetailContentHeader;
