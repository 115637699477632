import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { range } from "@cargotic/common";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles
} from "@material-ui/core";

import MonthlyVehicleExpenseCard from "../MonthlyVehicleExpenseCard";
import MonthlyVehicleExpenseDeleteConfirmationAlert
  from "../MonthlyVehicleExpenseDeleteConfirmationAlert";

import MonthlyVehicleExpenseEditor from "../MonthlyVehicleExpenseEditor";
import MonthlyVehicleExpensePlaceholderCard
  from "../MonthlyVehicleExpensePlaceholderCard";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > section:not(:first-child)": {
      marginTop: spacing(2)
    },
    "& > section > div": {
      display: "flex",

      "& > div:first-child": {
        display: "flex",
        alignItems: "center",
        width: 200
      },

      "& > div:not(:first-child)": {
        marginTop: spacing(2)
      }
    }
  },
  card: {
    height: 135
  }
}));

const VehicleExpenseOverview = ({
  className,
  firstYear,
  lastYear,
  selectedYear,
  selectedMonth,
  yearlyExpenses,
  previousMonthlyExpenses,
  selectedMonthlyExpenses,
  isMonthlyExpenseCreationDisabled,
  isMonthlyExpenseDeletionDisabled,
  isMonthlyExpenseEditationDisabled,
  isMonthlyExpenseEditorOpen,
  isMonthlyExpenseDeleteAlertOpen,
  onMonthlyExpenseDeleteAlertClose,
  onMonthlyExpenseDeleteAlertOpen,
  onMonthlyExpenseDeleteAlertSubmit,
  onMonthlyExpenseEditorClose,
  onMonthlyExpenseEditorOpen,
  onMonthlyExpenseEditorSubmit,
  onSelectedMonthlyExpensesChange,
  onSelectedMonthChange,
  onSelectedYearChange
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getQuarterlyExpenses = quarter => (
    yearlyExpenses.slice(quarter * 3, (quarter * 3) + 3)
  );

  const handleSelectedYearChange = ({ target: { value } }) => {
    onSelectedYearChange(value);
  };

  const handleExpensePlaceholderCardClick = month => {
    const prefill = yearlyExpenses.slice(0, month)
      .reverse()
      .find(expenses => expenses)
        ?? previousMonthlyExpenses;

    onSelectedMonthChange(month);
    onSelectedMonthlyExpensesChange(prefill);
    onMonthlyExpenseEditorOpen();
  };

  const handleExpenseCardDeleteButtonClick = month => {
    onSelectedMonthChange(month);
    onMonthlyExpenseDeleteAlertOpen();
  };

  const handleExpenseCardEditButtonClick = month => {
    onSelectedMonthChange(month);
    onSelectedMonthlyExpensesChange(yearlyExpenses[month]);
    onMonthlyExpenseEditorOpen();
  };

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <section>
          <div>
            <div />
            <Grid container spacing={2}>
              <Grid item xs={8} />
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("webapp:common.title.year")}
                  </InputLabel>
                  <Select value={selectedYear} onChange={handleSelectedYearChange}>
                    {range(firstYear, lastYear + 1).map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </section>
        <section>
          {range(0, 4).map(quarter => (
            <div key={quarter}>
              <div>
                <Typography variant="h4">
                  {`Q${quarter + 1}`}
                </Typography>
              </div>
              <Grid key={quarter} container spacing={2}>
                {getQuarterlyExpenses(quarter).map((expenses, index) => {
                  const month = (quarter * 3) + index;

                  if (!expenses) {
                    return (
                      <Grid key={index} item xs={4}>
                        <MonthlyVehicleExpensePlaceholderCard
                          className={classes.card}
                          month={month}
                          onClick={() => (
                            handleExpensePlaceholderCardClick(month)
                          )}
                          isDisabled={isMonthlyExpenseCreationDisabled}
                        />
                      </Grid>
                    );
                  }

                  const { total, rateOfChange } = expenses;

                  return (
                    <Grid key={index} item xs={4}>
                      <MonthlyVehicleExpenseCard
                        className={classes.card}
                        month={month}
                        value={total}
                        rateOfChange={rateOfChange}
                        onDeleteButtonClick={() => (
                          handleExpenseCardDeleteButtonClick(month)
                        )}
                        onEditButtonClick={() => (
                          handleExpenseCardEditButtonClick(month)
                        )}
                        isDeleteButtonDisabled={isMonthlyExpenseDeletionDisabled}
                        isEditButtonDisabled={isMonthlyExpenseEditationDisabled}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ))}
        </section>
      </div>
      {
        selectedMonth !== undefined
          ? (
            <MonthlyVehicleExpenseDeleteConfirmationAlert
              month={selectedMonth}
              year={selectedYear}
              isOpen={isMonthlyExpenseDeleteAlertOpen}
              onClose={onMonthlyExpenseDeleteAlertClose}
              onSubmit={onMonthlyExpenseDeleteAlertSubmit}
            />
          ) : null
      }
      {
        selectedMonthlyExpenses
          ? (
            <MonthlyVehicleExpenseEditor
              month={selectedMonth}
              year={selectedYear}
              initialValue={selectedMonthlyExpenses}
              isOpen={isMonthlyExpenseEditorOpen}
              onClose={onMonthlyExpenseEditorClose}
              onSubmit={onMonthlyExpenseEditorSubmit}
            />
          ) : null
      }
    </>
  );
};

export default VehicleExpenseOverview;
