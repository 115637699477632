import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";

import { Currency } from "@cargotic/common";
import { ContactType, TimePeriod } from "@cargotic/model";
import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";

import {
  ShowChart as ShowChartIcon,
  LocalShipping as LocalShippingIcon,
  ArrowForward as ArrowForwardIcon
} from "@material-ui/icons";

import {
  StatisticCard,
  OutlinedIconButton
} from "../../../cargotic-webapp-component";
import ContactActivityTimeline from "../ContactActivityTimeline";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > :not(:first-child)": {
      marginTop: spacing(8)
    },
    "& > div > h5": {
      marginBottom: spacing(4)
    }
  },
  card: {
    height: spacing(26),

    flexGrow: 0,
    maxWidth: "100%",
    flexBasis: "100%",

    "@media (min-width: 1200px)": {
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%"
    },

    "@media (min-width: 1800px)": {
      flexGrow: 0,
      maxWidth: "33.333333%",
      flexBasis: "33.333333%"
    }
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const ContactOverview = ({
  className,
  activity,
  contact: {
    id,
    type,
    name
  },
  orderBalanceHighlight,
  shipmentBalanceHighlight,
  shipmentCommissionHighlight,
  shipmentsHighlight,
  user,
  isActivityLoading,
  isHighlightsLoading,
  onActivityLoad,
  onBreadcrumbsChange,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onOrderBalanceHighlightChange,
  onShipmentCommissionHighlightChange,
  onShipmentBalanceHighlightChange,
  onShipmentsHighlightChange,
  onShipmentsHeaderIconClick
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [orderBalanceDateRange, setOrderBalanceDateRange] = useState([]);
  const [shipmentBalanceDateRange, setShipmentBalanceDateRange] = useState([]);
  const [commissionDateRange, setCommissionDateRange] = useState([]);
  const [shipmentDateRange, setShipmentDateRange] = useState([]);

  const handleOrderBalanceHighlightPeriodChange = ({ target: { value } }) => {
    let range;
    if (value === "CUSTOM") {
      range = [new Date(), addDays(new Date(), 1)];
      setOrderBalanceDateRange(range)
    }
    else {
      range = undefined;
      setOrderBalanceDateRange([])
    }

    
    if (onOrderBalanceHighlightChange) {
      onOrderBalanceHighlightChange({
        ...orderBalanceHighlight,
        period: value,
        range
      });
    }
  };

  const handleShipmentCommissionHighlightPeriodChange = ({ target: { value } }) => {
    let range = undefined;
    if (value === "CUSTOM") {
      range = [new Date(), addDays(new Date(), 1)];
      setCommissionDateRange(range)
    }
    else {
      range = undefined;
      setCommissionDateRange([])
    }
    
    if (onShipmentCommissionHighlightChange) {
      onShipmentCommissionHighlightChange({
        ...shipmentCommissionHighlight,
        period: value,
        range
      });
    }
  };

  const handleOrderBalanceHighlightUnitChange = ({ target: { value } }) => {
    if (onOrderBalanceHighlightChange) {
      onOrderBalanceHighlightChange({
        ...orderBalanceHighlight,
        currency: value
      });
    }
  };

  const handleShipmentBalanceHighlightPeriodChange = ({
    target: { value }
  }) => {
    let range = undefined;
    if (value === "CUSTOM") {
      range = [new Date(), addDays(new Date(), 1)];
      setShipmentBalanceDateRange(range)
    }
    else {
      range = undefined;
      setShipmentBalanceDateRange([])
    }

    if (onShipmentBalanceHighlightChange) {
      onShipmentBalanceHighlightChange({
        ...shipmentBalanceHighlight,
        period: value,
        range
      });
    }
  };

  const handleShipmentBalanceHighlightUnitChange = ({ target: { value } }) => {
    if (onShipmentBalanceHighlightChange) {
      onShipmentBalanceHighlightChange({
        ...shipmentBalanceHighlight,
        currency: value
      });
    }
  };

  const handleShipmentCommissionHighlightUnitChange = ({ target: { value } }) => {
    if (onShipmentCommissionHighlightChange) {
      onShipmentCommissionHighlightChange({
        ...shipmentCommissionHighlight,
        currency: value
      });
    }
  };

  const handleShipmentsHighlightPeriodChange = ({ target: { value } }) => {
    let range = undefined;
    if (value === "CUSTOM") {
      range = [new Date(), addDays(new Date(), 1)];
      setShipmentDateRange(range)
    }
    else {
      range = undefined;
      setShipmentDateRange([])
    }

    if (onShipmentsHighlightChange) {
      onShipmentsHighlightChange({
        ...shipmentsHighlight,
        period: value,
        range
      });
    }
  };

  useEffect(() => {
    if (shipmentDateRange && shipmentDateRange[0] && shipmentDateRange[1] && onShipmentsHighlightChange) {
      onShipmentsHighlightChange({
        ...shipmentsHighlight,
        range: shipmentDateRange,
        notLoad: true
      })
    } 
  }, [shipmentDateRange]);

  useEffect(() => {
    if (orderBalanceDateRange && orderBalanceDateRange[0] && orderBalanceDateRange[1] && onShipmentsHighlightChange) {
      onOrderBalanceHighlightChange({
        ...orderBalanceHighlight,
        range: orderBalanceDateRange,
        notLoad: true
      })
    } 
  }, [orderBalanceDateRange]);

  useEffect(() => {
    if (shipmentBalanceDateRange && shipmentBalanceDateRange[0] && shipmentBalanceDateRange[1] && onShipmentsHighlightChange) {
      onShipmentBalanceHighlightChange({
        ...shipmentBalanceHighlight,
        range: shipmentBalanceDateRange,
        notLoad: true
      })
    } 
  }, [shipmentBalanceDateRange]);

  useEffect(() => {
    if (commissionDateRange && commissionDateRange[0] && commissionDateRange[1] && onShipmentsHighlightChange) {
      onShipmentCommissionHighlightChange({
        ...shipmentCommissionHighlight,
        range: commissionDateRange,
        notLoad: true
      })
    } 
  }, [commissionDateRange]);

  useEffect(() => {
    onActivityLoad();

    onOrderBalanceHighlightChange({
      ...orderBalanceHighlight,
      currency: Currency.CZK,
      period: TimePeriod.CURRENT_MONTH
    });

    onShipmentBalanceHighlightChange({
      ...shipmentBalanceHighlight,
      currency: Currency.CZK,
      period: TimePeriod.CURRENT_MONTH
    });

    onShipmentsHighlightChange({
      ...shipmentsHighlight,
      period: TimePeriod.CURRENT_MONTH
    });

    onShipmentCommissionHighlightChange({
      ...shipmentCommissionHighlight,
      currency: Currency.CZK,
      period: TimePeriod.CURRENT_MONTH
    })

    onBreadcrumbsChange([
      { label: t("webapp:common.title.contacts"), href: "/contacts" },
      { label: name, href: `/contacts/${id}` },
      { label: t("webapp:common.title.overview") }
    ]);
  }, []);

  if (isActivityLoading) {
    return (
      <div className={clsx(className, classes.progress)}>
        <CircularProgress />
      </div>
    );
  }

  const getTrendDescription = period => {
    if (
      period === TimePeriod.CURRENT_MONTH
      || period === TimePeriod.LAST_MONTH
    ) {
      return t("webapp:common.trend.previous-month");
    }

    if (
      period === TimePeriod.CURRENT_WEEK
      || period === TimePeriod.LAST_WEEK
    ) {
      return t("webapp:common.trend.previous-week");
    }

    if (period === TimePeriod.TODAY || period === TimePeriod.YESTERDAY) {
      return t("webapp:common.trend.previous-day");
    }

    return undefined;
  };

  return (
    <div className={clsx(className, classes.root)}>
      <div>
        <Grid container spacing={2}>
          <Grid item className={classes.card}>
            <StatisticCard
              title={t("webapp:common.title.shipments")}
              icon={LocalShippingIcon}
              units={[{ label: t("webapp:common.unit.dimensionless.total") }]}
              period={shipmentsHighlight.period}
              periods={
                Object.values(TimePeriod)
                  .map(value => ({
                    value,
                    label: t(`webapp:common.time-period.${value}`)
                  }))
              }
              value={Math.round(shipmentsHighlight.value)}
              range={shipmentDateRange}
              onRangeChange={setShipmentDateRange}
              rateOfChange={shipmentsHighlight.rateOfChange}
              isLoading={shipmentsHighlight.isLoading}
              onPeriodChange={handleShipmentsHighlightPeriodChange}
              headerAdornment={(
                <OutlinedIconButton
                  size="small"
                  onClick={onShipmentsHeaderIconClick}
                >
                  <ArrowForwardIcon />
                </OutlinedIconButton>
              )}
            />
          </Grid>
          {
            type !== ContactType.CARRIER
              ? (
                <>
                  <Grid item className={classes.card}>
                    <StatisticCard
                      title={t("webapp:common.title.order-balance")}
                      icon={ShowChartIcon}
                      period={orderBalanceHighlight.period}
                      periods={
                        Object.values(TimePeriod)
                          .map(value => ({
                            value,
                            label: t(`webapp:common.time-period.${value}`)
                          }))
                      }
                      unit={orderBalanceHighlight.currency}
                      units={
                        Object.values(Currency).map(value => ({ label: value, value }))
                      }
                      value={Math.round(orderBalanceHighlight.value)}
                      range={orderBalanceDateRange}
                      rateOfChange={orderBalanceHighlight.rateOfChange}
                      isLoading={orderBalanceHighlight.isLoading}
                      onRangeChange={setOrderBalanceDateRange}
                      onPeriodChange={handleOrderBalanceHighlightPeriodChange}
                      onUnitChange={handleOrderBalanceHighlightUnitChange}
                    />
                  </Grid>
                  <Grid item className={classes.card}>
                    <StatisticCard
                      title={t("webapp:common.title.shipment-commission")}
                      icon={ShowChartIcon}
                      period={shipmentCommissionHighlight.period}
                      periods={
                        Object.values(TimePeriod)
                          .map(value => ({
                            value,
                            label: t(`webapp:common.time-period.${value}`)
                          }))
                      }
                      unit={shipmentCommissionHighlight.currency}
                      units={
                        Object.values(Currency).map(value => ({ label: value, value }))
                      }
                      value={Math.round(shipmentCommissionHighlight.value)}
                      rateOfChange={shipmentCommissionHighlight.rateOfChange}
                      isLoading={shipmentCommissionHighlight.isLoading}
                      range={commissionDateRange}
                      onRangeChange={setCommissionDateRange}
                      onPeriodChange={handleShipmentCommissionHighlightPeriodChange}
                      onUnitChange={handleShipmentCommissionHighlightUnitChange}
                    />
                  </Grid>
                </>
              ) : undefined
          }
          {
            type !== ContactType.CUSTOMER
              ? (
                <Grid item className={classes.card}>
                  <StatisticCard
                    title={t("webapp:common.title.shipment-balance")}
                    icon={ShowChartIcon}
                    period={shipmentBalanceHighlight.period}
                    periods={
                      Object.values(TimePeriod)
                        .map(value => ({
                          value,
                          label: t(`webapp:common.time-period.${value}`)
                        }))
                    }
                    unit={shipmentBalanceHighlight.currency}
                    units={
                      Object.values(Currency).map(value => ({ label: value, value }))
                    }
                    value={Math.round(shipmentBalanceHighlight.value)}
                    rateOfChange={shipmentBalanceHighlight.rateOfChange}
                    isLoading={shipmentBalanceHighlight.isLoading}
                    isTrendInverted
                    range={shipmentBalanceDateRange}
                    onRangeChange={setShipmentBalanceDateRange}
                    onPeriodChange={handleShipmentBalanceHighlightPeriodChange}
                    onUnitChange={handleShipmentBalanceHighlightUnitChange}
                  />
                </Grid>
              ) : undefined
          }
        </Grid>
      </div>
      <div>
        <Typography variant="h5">
          <b>
            {t("webapp:common.title.activity")}
          </b>
        </Typography>
        <ContactActivityTimeline
          activity={activity}
          user={user}
          onCommentaryChange={onCommentaryChange}
          onCommentaryDelete={onCommentaryDelete}
          onCommentarySubmit={onCommentarySubmit}
        />
      </div>
    </div>
  );
};

export default ContactOverview;
