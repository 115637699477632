import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import TrackingAppRouter from "./TrackingAppRouter";

const useStyles = makeStyles(() => ({
  "@global": {
    "html, body, #app": {
      height: "100%",
      backgroundColor: "#EEEEEE"
    }
  }
}));

const TrackingApp = () => {
  useStyles();

  return (
    <TrackingAppRouter />
  );
};

export default TrackingApp;
