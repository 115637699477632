import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  progress: {
    position: "relative",

    width: 20,
    height: 20,

    border: `2px solid ${palette.primary.light}`,
    borderRadius: 20,

    backgroundColor: "transparent",

    "& > div": {
      position: "absolute",
      top: 2,
      left: 2,

      width: 12,
      height: 12,

      backgroundColor: palette.primary.light,

      borderRadius: 20
    }
  },
  update: {
    width: 12,
    height: 12,

    marginRight: 4,
    marginLeft: 4,

    borderRadius: 20,

    backgroundColor: palette.primary.light
  }
}));

const ActivityTimelineEventItemDot = ({ variant }) => {
  const classes = useStyles();

  if (variant === "progress") {
    return (
      <div className={classes.progress}>
        <div />
      </div>
    );
  }

  if (variant === "update") {
    return (
      <div className={classes.update} />
    );
  }

  throw new Error(`Unknown variant '${variant}' passed.`);
};

export default ActivityTimelineEventItemDot;
