import React from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const Placeholder = ({ loading, render, style }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.container} style={style} >
        <CircularProgress size={60} />
      </div>
    );
  }

  return render();
};

export default Placeholder;
