import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";

import { formatDate } from "@cargotic/common-deprecated/date";
import FormikDatePicker from "@cargotic/webapp-component/component/FormikDatePicker";

import { setInvoicePaidAt } from "@cargotic/webapp/resource";

const OUTPUT_DATE_FORMAT = "yyyy-MM-dd";

export function InvoicePaidDialog({ invoiceId, isOpen, onSuccess, onClose }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const invoicePaidAtForm = useFormik({
        initialValues: {
            paidAt: new Date()
        },
        validationSchema: Yup.object().shape({
            paidAt: Yup.string().required(
                t("invoices.invoice.validation.required")
            )
        }),
        onSubmit: async (values, actions) => {
            try {
                const { paidAt } = values || {};

                await setInvoicePaidAt(invoiceId, {
                    paidAt: formatDate(new Date(paidAt), OUTPUT_DATE_FORMAT)
                });

                if (typeof onClose === "function") {
                    onClose();
                }
                if (typeof onSuccess === "function") {
                    onSuccess();
                }
                enqueueSnackbar(t("invoices.changePaymentDateSuccess"), {
                    variant: "success"
                });
            } catch (error) {
                console.error(t("invoices.changePaymentDateError"), error);
                enqueueSnackbar(t("invoices.changePaymentDateError"), {
                    variant: "error"
                });
            }
        }
    });

    return (
        <Dialog open={isOpen}>
            <DialogTitle>{t("invoices.paidAt")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormikDatePicker
                        form={invoicePaidAtForm}
                        name="paidAt"
                        label={t("invoices.invoice.paymentDate")}
                        format="dd.MM.yyyy"
                        fullWidth
                        required
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("cancel")}</Button>
                <Button
                    onClick={() => {
                        return invoicePaidAtForm.submitForm();
                    }}
                    color="primary"
                >
                    {t("save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default InvoicePaidDialog;
