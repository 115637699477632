import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationAlert } from "../../../cargotic-webapp-component";
import { localizeMonth } from "../../../cargotic-webapp-utility";

const MonthlyVehicleExpenseDeleteConfirmationAlert = ({
  month,
  year,
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t, i18n: { language } } = useTranslation();

  return (
    <ConfirmationAlert
      title={t("webapp:vehicle.title.deleteExpenses")}
      content={t(
        "webapp:vehicle.prompt.deleteExpenses",
        { month: localizeMonth(month, language), year }
      )}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default MonthlyVehicleExpenseDeleteConfirmationAlert;
