import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, Typography, makeStyles } from "@material-ui/core";

import ContactEmployeeGridBody from "./ContactEmployeeGridBody";
import ContactEmployeeGridFooter from "./ContactEmployeeGridFooter";

const useStyles = makeStyles(({ spacing }) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  footer: {
    marginTop: spacing(1)
  }
}));

const ContactEmployeeGrid = ({
  className,
  employees,
  page,
  rowsPerPage,
  isLoading,
  onEmployeeDelete,
  onEmployeeEdit,
  onPageChange,
  onRowsPerPageChange
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={clsx(classes.center, className)}>
        <CircularProgress />
      </div>
    );
  }

  const { total, matches } = employees;

  if (total === 0) {
    return (
      <div className={clsx(classes.center, className)}>
        <Typography color="textSecondary">
          {t("webapp:contact.title.empty-employees")}
        </Typography>
      </div>
    );
  }

  return (
    <div className={className}>
      <ContactEmployeeGridBody
        employees={matches}
        onEmployeeDelete={onEmployeeDelete}
        onEmployeeEdit={onEmployeeEdit}
      />
      <ContactEmployeeGridFooter
        className={classes.footer}
        page={page}
        rowsPerPage={rowsPerPage}
        total={total}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </div>
  );
};

export default ContactEmployeeGrid;
