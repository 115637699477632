import React from "react";

import { Grid, Tooltip, Typography, makeStyles, Button } from "@material-ui/core";
import {
  SwapVert,
  ArrowUpward,
  ArrowDownward,
  Info
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { formatJourneyPointDateTime } from "../../../utility/common";

import {
  getActionFromWaypoint,
} from "../../../utility/waypoint";


const useStyles = makeStyles(({ palette }) => ({
  journeyPointActionUpIcon: {
    fill: "#009688",
    height: 20,
    width: 20,
  },
  journeyPointActionDownIcon: {
    fill: palette.error.main,
    height: 20,
    width: 20,
  },
}));

const IncomingOrdersDateTime = ({ incomingOrder, isEditable, handleClickEditDateTime }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid key={incomingOrder.id} container direction="column">
      {incomingOrder.journey.waypoints.map((waypoint, index) => {
        const waypointAction = waypoint.cargo
          ? getActionFromWaypoint(waypoint.cargo)
          : [];

        const actionIcon = (waypointAction) => {
          if (
            waypointAction.includes("LOAD") &&
            waypointAction.includes("UNLOAD")
          ) {
            return (
              <Tooltip
                key={`${index}-both`}
                title={t("incomingOrders.action.both")}
              >
                <SwapVert className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("LOAD")) {
            return (
              <Tooltip
                key={`${index}-load`}
                title={t("incomingOrders.action.loading")}
              >
                <ArrowUpward className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("UNLOAD")) {
            return (
              <Tooltip
                key={`${index}-unload`}
                title={t("incomingOrders.action.unloading")}
              >
                <ArrowDownward className={classes.journeyPointActionDownIcon} />
              </Tooltip>
            );
          }
          return (
            <Tooltip
              key={`${index}-none`}
              title={t("incomingOrders.action.none")}
            >
              <Info className={classes.journeyPointActionDownIcon} />
            </Tooltip>
          );
        };
        const formattedDateTime = formatJourneyPointDateTime(
          waypoint.arriveAtFrom,
          waypoint.arriveAtTo
        );

        return (
          <Grid
            key={`${index}-grid`}
            container
            item
            spacing={1}
            alignItems="center"
          >
            <Grid item>{actionIcon(waypointAction)}</Grid>
            <Grid item xs={8}>
              {isEditable ? <Button onClick={() => handleClickEditDateTime(incomingOrder, waypoint.id)}>{formattedDateTime}</Button> :
                <Typography variant="body2">
                  {formattedDateTime}
                </Typography>
              }
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default IncomingOrdersDateTime;