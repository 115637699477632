import React, { forwardRef } from "react";

import { Paper } from "@material-ui/core";

import { isEmpty } from "../../collection";
import { Nullable } from "../../type";
import { AutosuggestSuggestionComponentProps } from "../Autosuggest";
import SuggestionAreaList from "./SuggestionAreaList";

interface SuggestionAreaProps<T> {
  className?: string;

  getSuggestionText: (suggestion: T) => string;
  suggestions: T[];
  suggestionComponent:
    React.ComponentType<AutosuggestSuggestionComponentProps<T>>;
  dataCy?: string;

  onSuggestionClick?: (suggestion: T) => void;
}

function SuggestionArea<T>(
  {
    className,
    getSuggestionText,
    suggestions,
    suggestionComponent,
    dataCy,
    onSuggestionClick
  }: SuggestionAreaProps<T>,
  ref: React.Ref<HTMLDivElement | undefined>
): Nullable<React.ReactElement> {
  const handleSuggestionClick = (suggestion: T): void => {
    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }
  };

  if (isEmpty(suggestions)) {
    return null;
  }

  return (
    <Paper ref={ref} className={className} data-cy={dataCy}>
      <SuggestionAreaList
        getSuggestionText={getSuggestionText}
        suggestions={suggestions}
        suggestionComponent={suggestionComponent}
        onSuggestionClick={handleSuggestionClick}
      />
    </Paper>
  );
}

export default forwardRef(SuggestionArea);
