const Subject = {
  CARRIER: "carrier",
  CUSTOMER: "customer",
}

const Currency = {
  CZK: "CZK",
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
}

const DistanceUnit = {
  KM: "km",
  M: "m",
}

const DurationUnit = {
  MIN: "minute",
  H: "hour",
  DAY: "day",
  MONTH: "month",
}

const Roles = {
  // Prvni vyskyt roli
  CARRIER: "CARRIER",
  FORWARDER: "FORWARDER",
  DISPATCHER: "DISPATCHER",
  DRIVER: "DRIVER",
  OWNER: "OWNER",
  ACCOUNTANT: "ACCOUNTANT",
  MANAGER: "MANAGER",
}

const FirebaseErrorCodes = {
  DISABLED_USER: "auth/user-disabled",
  WRONG_PASSWORD: "auth/wrong-password",
  USER_NOT_FOUND: "auth/user-not-found",
  TOO_MANY_ATTEMPTS: "auth/too-many-requests"
}

const ServerErrorCodes = {
  DELETED_COMPANY: "DELETED_COMPANY",
}

const Periods = {
  WEEK: "week",
  DAY: "day",
  HOUR: "hour",
}

const AmountUnit = {
  KS: {
    name: "ks",
  },
  LDM: {
    name: "ldm",
  },
  EUR: {
    name: "eur",
    length: 120,
    width: 80,
  },
  US: {
    name: "us",
    length: 120,
    width: 100,
  },
  LKW: {
    name: "lkw",
    unit: "ldm",
    amount: 13.6,
  },
}

const AccountType = {
  FORWARDER: "Forwarder",
  CARRIER: "Carrier",
  COMBINATION: "Combination",
}

const TariffType = {
  CARRIER: "CARRIER",
  FORWARDER: "FORWARDER",
  LOGISTICIAN: "LOGISTICIAN",
  TRIAL: "TRIAL",
}

const BillingPeriod = {
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
}

const OrderingDirection = {
  ASC: "asc",
  DESC: "desc",
}

const InvoiceType = {
  RECEIVED: "RECEIVED",
  ISSUED: "ISSUED",
}

const PaymentMethod = {
  TRANSFER: "TRANSFER",
}

const InvoiceVatType = {
  TAXABLE_SUPPLY_CZ: "TAXABLE_SUPPLY_CZ",
  WITHOUT_DPH: "WITHOUT_DPH",
  SERVICES_EU: "SERVICES_EU",
  REVERSE_CHARGE: "REVERSE_CHARGE",
  CUSTOMER_INCLUDE: "CUSTOMER_INCLUDE",
  NO_TAX: "NO_TAX",
  FREE_FROM_DPH: "FREE_FROM_DPH",
}

const ShipmentInvoiceType = {
  RECEIVED: "RECEIVED",
  ISSUED: "ISSUED",
}

const WarehouseOrdersModeEnum = {
  INCOMING_ORDERS: "INCOMING_ORDERS",
  ORDERS_HISTORY: "ORDERS_HISTORY",
}

const WarehouseOrderStateEnum = {
  ACCEPTED: "ACCEPTED",
  PLANNED: "PLANNED",
  READY: "READY",
  DONE: "DONE",
}

const WarehouseOrderDialogModeEnum = {
  RECEIVE: "RECEIVE",
  DISPATCH: "DISPATCH",
}

export {
  Subject,
  Currency,
  DistanceUnit,
  DurationUnit,
  Roles,
  FirebaseErrorCodes,
  Periods,
  AmountUnit,
  AccountType,
  TariffType,
  BillingPeriod,
  OrderingDirection,
  ServerErrorCodes,
  InvoiceVatType,
  PaymentMethod,
  InvoiceType,
  ShipmentInvoiceType,
  WarehouseOrdersModeEnum,
  WarehouseOrderStateEnum,
  WarehouseOrderDialogModeEnum
}
