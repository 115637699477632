import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    height: 60,
    padding: spacing(3, 2.5),
  },
  icon: {
      fill: '#DADADA',
      marginRight: 22,
  },
  cardTitle: {
      fontSize: 18
  }
}));

const DragAndDropItem = ({title, innerRef, ...rest}) => {
  const classes = useStyles();

  return (
      <div ref={innerRef} {...rest}>
        <Paper className={classes.card} >
            <DragIndicatorIcon className={classes.icon} />
            <Typography className={classes.cardTitle}>{ title }</Typography>
        </Paper>
      </div>
  )
};

export default DragAndDropItem;
