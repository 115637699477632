import React from "react";

import WaypointListItem from "./WaypointListItem";

const WaypointList = ({ waypoints }) => (
  <div>
    {waypoints.map((waypoint, index) => {
      const key = index;
      const isFirst = index === 0;
      const isLast = index === waypoints.length - 1;

      return (
        <WaypointListItem
          key={key}
          waypoint={waypoint}
          isFirst={isFirst}
          isLast={isLast}
          index={index}
        />
      );
    })}
  </div>
);

export default WaypointList;
