import React, { useState } from "react";
import { Grid, Fab, Tooltip, Typography } from "@material-ui/core";

import { Edit, FileCopyOutlined, ArrowDownward, ArrowUpward, GetApp, Publish } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { ShipmentType } from "@cargotic/model-deprecated";

import { addDays } from "date-fns";
import config from "../../../config";

import DataTable from "../../../datatable";
import useRouter from "../../hook/useRouter";
import SearchTextfield from "../../common/SearchTextfield";

import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import VehiclesFilterDropdown from "../../../../cargotic-webapp-filter/component/VehiclesFilterDropdown";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import ExportDialog from "../Shipments/ExportDialog";
import Page from "../../common/Page";
import FirstMileIncomingOrdersDialog from "./FirstMileIncomingOrdersDialog";
import DispatchIncomingOrdersDialog from "@cargotic/webapp/component/core/WarehouseOrders/DispatchIncomingOrdersDialog";
import LinehallDialog from "./LinehallDialog";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";

import useShipmentConcept from "../../../component/hook/useShipmentConcept";

export const AssociatedWarehouseOrdersContext = React.createContext({})

const WarehouseShipments = ({
  selectedWarehouse,
  data,
  dataCount,
  selectedColumns,
  shipments,
  setShipments,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  isShipmentsExportDialogOpen,
  isShipmentsExportProcessing,
  isShipmentsExportDone,
  shipmentsExportLink,
  closeShipmentsExportDialog,
  exportShipments,
  canCreateShipment,
  canUpdateShipment,
  canDeleteShipment,
  canCreateInvoice,
  canReadCompanyShipment,
  canReadInvoice,
  additionalFilter: {
    type: additionalFilterType,
    lastWaypointDateFrom: additionalFilterLastDateFrom,
  },
  handlePayShipment,
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleShipmentsExportRequest,
  handleSelectAll,
  handleSelect,
  handleSelectCarried,
  handleSelectForwarded,
  handleSelectLastMonth,
  handleDeleteRequest,
  handleGenerateShipmentInvoice,
  filter,
  availableBankAccounts,
  handleSelectShipmentState,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectCreators,
  handleSelectDrivers,
  loadAvailableUsers,
  handleSelectCustomer,
  handleSelectCarrier,
  handleSelectCustomerPrice,
  handleSelectCarrierPrice,
  handleSelectCommission,
  loadAvailableContacts,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleSelectVehicles,
  loadAvailableVehicles,
  handleSelectIssuedPaymentState,
  handleSelectReceivedPaymentState,
  handleSendOrder,
  handleChangeSelectedColumns,
  loadAvailableStates
}) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);
  
    const { setShipment } = useShipmentConcept();

  const [warehouseReceiveDialogOpen, setWarehouseReceiveDialogOpen] = useState(false);
  const [warehouseDispatchDialogOpen, setWarehouseDispatchDialogOpen] = useState(false);
  const [associatedWarehouseOrders, setAssociatedWarehouseOrders] = useState({});

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const shipmentsHeader = [
    {
      name: "indexNumber",
      label: "shipments.shipmentNumber",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "vehicle",
      label: "shipments.vehicle",
      isOrderable: false,
      width: "10%",
    },
    {
      name: "driver",
      label: "shipments.driver",
      isOrderable: false,
      width: "10%",
    },
    {
      name: "pickupAtStart",
      label: "shipments.date",
      isOrderable: true,
      width: "200px",
    },
    {
      name: "route",
      label: "shipments.route",
      isOrderable: false,
      width: "200px",
    },
    {
      name: "incomingOrders",
      label: "shipments.incomingOrderNumbers",
      isOrderable: false,
    },
    {
      name: "state",
      label: "board.state.title",
      width: "6%",
    },
    {
      name: "createdBy",
      label: "incomingOrders.createdBy",
      width: "50px",
      isOrderable: false
    }
  ];

  const invoiceDisable = [];
  if (canReadInvoice) {
    for (let index = 0; index < shipments.length; index++) {
      if (!shipments[index].hasInvoice) {
        invoiceDisable.push(shipments[index].id);
      }
    }
  } else {
    invoiceDisable.push(-1);
  }

  const payDisable = [];
  if (canCreateInvoice) {
    for (let index = 0; index < shipments.length; index++) {
      if (shipments[index].isDraft) {
        payDisable.push(shipments[index].id);
      }
    }
  } else {
    payDisable.push(-1);
  }

  const createInvoiceDisable = [];
  if (canCreateInvoice) {
    shipments.forEach(({ isDraft, bankAccountId }, index) => {
      if (isDraft || !bankAccountId) {
        createInvoiceDisable.push(shipments[index].id);
      }
    });
  } else {
    createInvoiceDisable.push(-1);
  }

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => history.push(`/shipment/${id}/copy`),
          icon: <FileCopyOutlined />,
          title: "copy",
          disableFor: !canCreateShipment ? [-1] : undefined,
        },
      ],
    },
    {
      column: [
        {
          handleClick: (id) => history.push(`/shipment/${id}`),
          icon: <Edit />,
          title: "outcomingOrders.edit",
          disableFor: !canUpdateShipment ? [-1] : undefined,
        },
      ],
    },
  ];

  const shipmentTypeSource = [
    { title: t("statistics.forwarded"), value: ShipmentType.FORWARDED },
    { title: t("statistics.carried"), value: ShipmentType.CARRIED },
  ];
  const paymentSource = [
    { title: t("shipments.non-issued"), value: "NON_ISSUED" },
    { title: t("shipments.issued"), value: "ISSUED" },
    { title: t("shipments.paid"), value: "PAID" },
    { title: t("shipments.overdue-title"), value: "OVERDUE" },
  ];

  const draftSource = [
    { title: t("shipments.draft"), value: "DRAFT" },
    { title: t("shipments.completed"), value: "COMPLETED" },
  ];
  const setDraft = (value) => {
    if (value.includes("DRAFT") && value.includes("COMPLETED")) {
      handleSelectIsDraft(null);
    } else if (value.includes("COMPLETED")) {
      handleSelectIsDraft(false);
    } else if (value.includes("DRAFT")) {
      handleSelectIsDraft(true);
    } else {
      handleSelectIsDraft(undefined);
    }
  };

  const handleSubmitFirstMileDialog = (orderIds) => {
    setShipment({});
  }

  const isAnyFilterActive = () => {
    const {
      types,
      drivers,
      carrierPrice,
      customerPrice,
      commission,
      carriers,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      issuedPaymentState,
      receivedPaymentState,
      vehicles,
      isDraft,
    } = filter;

    return [
      types,
      drivers,
      carrierPrice,
      customerPrice,
      commission,
      carriers,
      customers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      issuedPaymentState,
      receivedPaymentState,
      vehicles,
      isDraft,
    ].some((item) => item !== undefined);
  };

  return (
    <Page actions={
      <>
        <Tooltip title={t("warehouses.shipments.toWarehouse")}>
          <span>
            <Fab
              color="primary"
              // disabled={loading || !canCreateIncomingOrder}
              onClick={() => {
                setWarehouseReceiveDialogOpen(true);
              }}
            >
              <Typography variant="h5">FM</Typography>
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title={t("warehouses.shipments.fromWarehouse")}>
          <span>
            <Fab
              color="inherit"
              // disabled={loading || !canCreateIncomingOrder}
              onClick={() => {
                setWarehouseDispatchDialogOpen(true);
              }}
            >
              <Typography variant="h5">LH</Typography>
            </Fab>
          </span>
        </Tooltip>
      </>
    }>
      <FilterContainer
        searchField={
          <SearchTextfield
            handleSearch={handleSearch}
            value={search}
            fullWidth
            placeholder={t("shipments.searchTooltip")}
          />
        }
        showClearButton={isAnyFilterActive}
        onClear={handleDeselect}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={[
          <FilterDateRangeDropdown
            id="loadingDate"
            key="loadingDate"
            onChange={(value) => handleSelectLoadingsDateRange(value)}
            value={
              filter.loadingDate
                ? [filter.loadingDate.from, filter.loadingDate.to]
                : []
            }
            onClear={() => handleSelectLoadingsDateRange(undefined)}
            placeholderTitle={t("shipments.loadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="unloadingDate"
            key="unloadingDate"
            onChange={(value) => handleSelectUnloadingsDateRange(value)}
            value={
              filter.unloadingDate
                ? [filter.unloadingDate.from, filter.unloadingDate.to]
                : []
            }
            onClear={() => handleSelectUnloadingsDateRange(undefined)}
            placeholderTitle={t("shipments.unloadingsDateRange")}
          />,
          <UserFilterDropdown
            id="creators"
            key="creators"
            onChange={(value) =>
              handleSelectCreators(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectCreators(all)}
            value={filter.creators ?? []}
            placeholderTitle={t("shipments.creator")}
            search={(text) => loadAvailableUsers(text)}
            onClear={() => handleSelectCreators(undefined)}
            getTitle={(item) => item.name}
            disabled={!canReadCompanyShipment}
          />,
          <UserFilterDropdown
            id="drivers"
            key="drivers"
            onChange={(value) =>
              handleSelectDrivers(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectDrivers(all)}
            value={filter.drivers ?? []}
            placeholderTitle={t("shipments.driver")}
            search={(text) => loadAvailableUsers(text, "resource.user.role.permission.driver")}
            onClear={() => handleSelectDrivers(undefined)}
            getTitle={(item) => item.name}
          />,
          <FilterDateRangeDropdown
            id="createdAt"
            key="createdAt"
            onChange={(value) => handleSelectCreatedAtDateRange(value)}
            value={
              filter.createdAt
                ? [filter.createdAt.from, filter.createdAt.to]
                : []
            }
            onClear={() => handleSelectCreatedAtDateRange(undefined)}
            placeholderTitle={t("shipments.creationDate")}
          />,
          <FilterStaticDropdown
            id="cargo"
            key="cargo"
            placeholderTitle={t("shipments.cargo")}
            value={filter.cargo ?? []}
            onChange={(arr) =>
              handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
            }
            selectAll={() =>
              handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
            }
            onClear={() => handleSelectCargoTemplate(undefined)}
            source={AvailableCargoItemTemplates.map(({ label, value }) => ({ value, title: t(label)}))}
          />,
          <FilterStaticDropdown
            id="isDraft"
            key="isDraft"
            placeholderTitle={t("shipments.draft")}
            value={
              filter.isDraft === undefined
                ? []
                : filter.isDraft === null
                  ? ["DRAFT", "COMPLETED"]
                  : filter.isDraft === true
                    ? ["DRAFT"]
                    : ["COMPLETED"]
            }
            onChange={(value) => setDraft(value)}
            selectAll={() => setDraft(draftSource.map((item) => item.value))}
            onClear={() => handleSelectIsDraft(undefined)}
            source={draftSource}
          />,
          <VehiclesFilterDropdown
            id="vehicles"
            key="vehicles"
            onChange={(value) =>
              handleSelectVehicles(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectVehicles(all)}
            value={filter.vehicles ?? []}
            placeholderTitle={t("shipments.vehicles")}
            search={(text) => loadAvailableVehicles(text)}
            onClear={() => handleSelectVehicles(undefined)}
          />,
          <StateFilterDropdown
            id="state"
            key="state"
            placeholderTitle={t("board.state.title")}
            value={filter.state ?? []}
            onChange={(arr) =>
              handleSelectShipmentState(arr.length === 0 ? undefined : arr)
            }
            selectAll={(all) =>
              handleSelectShipmentState(all)
            }
            onClear={() => handleSelectShipmentState(undefined)}
            search={(text) => loadAvailableStates(text)}
            getTitle={(item) => item.name}
          />
        ]}
      />
      <DataTable
        headers={shipmentsHeader}
        actions={tableActions}
        selectedColumns={selectedColumns}
        data={data}
        dataCount={dataCount}
        loading={loading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        enableDetail
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        onChangeSelectedColumns={handleChangeSelectedColumns}
      />
      <ExportDialog
        infoText={t("orders.export.info.text")}
        title={t("orders.export.title")}
        closeButtonLabel={t("orders.export.close")}
        downloadCSV={t("orders.export.downloadCSV")}
        startButtonLabel={t("orders.export.start")}
        processingText={t("orders.export.processing.text")}
        doneText={t("orders.export.done.text")}
        linkLabel={t("orders.export.done.link")}
        isDone={isShipmentsExportDone}
        isOpen={isShipmentsExportDialogOpen}
        isProcessing={isShipmentsExportProcessing}
        link={shipmentsExportLink}
        close={closeShipmentsExportDialog}
        exportCsv={exportShipments}
      />
      <FirstMileIncomingOrdersDialog
        open={warehouseReceiveDialogOpen}
        handleClose={() => {
          setWarehouseReceiveDialogOpen(false)
        }}
        handleSubmit={handleSubmitFirstMileDialog}
        warehouse={selectedWarehouse}
      />
      <LinehallDialog
        open={warehouseDispatchDialogOpen}
        handleClose={() => {
          setWarehouseDispatchDialogOpen(false)
        }}
        handleSubmit={() => null}
        warehouse={selectedWarehouse}
      />
    </Page>
  );
};

export default WarehouseShipments;
