import React from "react";

import {
  Box, Link, makeStyles, Typography, Tooltip
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(({ spacing }) => ({
  info: {
    background: "#DADADA",
    color: "#000",
    padding: spacing(3),
    marginBottom: 30,
    marginTop: 47,
    minHeight: 224
  },
  title: {
    fontSize: 21,
    marginBottom: 33
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 14,
    marginTop: 11
  },
  value: {
    paddingLeft: 30,
    fontSize: 12
  },
  specialValue: {
    color: "#009588"
  }
}));

const RelatedInfo = ({ shipment }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { incomingOrders, invoices } = shipment;

  return (
    <Box className={classes.info}>
      <Typography className={classes.title}>{t("menu.related")}</Typography>
      {incomingOrders && (
        <>
          <Typography className={classes.subtitle}>
            {t("incomingOrders.title")}
          </Typography>
          {incomingOrders.map(incomingOrder => (
            <Typography key={incomingOrder.id} className={`${classes.value}`}>
              <Link
              component={RouterLink}
              to={`/incoming-order/${incomingOrder.id}`}
              target="_blank"
              rel="noopener">
                #
                {incomingOrder.indexNumber} (ref: {incomingOrder.externalReference})
                <Tooltip title={t("webapp:cargo.editor.button.new-tab")}>
                  <Launch style={{ width: 12, height: 12 }} />
                </Tooltip>
              </Link>
            </Typography>

          ))}
        </>
      )}
      {invoices && (
        <>
          <Typography className={classes.subtitle}>{t("settings.invoices")}</Typography>
          <Typography className={classes.value}>{t("settings.received")}</Typography>
          <Typography className={classes.value}>{t("settings.issued")}</Typography>
        </>
      )}
    </Box>
  );
};

export default RelatedInfo;
