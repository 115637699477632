import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { cs, enUS } from "date-fns/locale";

import {
  makeStyles,
  Button
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DesktopDateRangePicker, DateRangeDelimiter, DateRange, LocalizationProvider } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(({ spacing }) => ({
  buttonRoot: {
    textTransform: "none",
    fontWeight: 600
  },
  buttonRootSelected: {
    background: "#D6D6D6",
    textTransform: "none",
    fontWeight: 600
  },
  text: {
    padding: "4px 6px"
  },
  icon: {
    width: "0.8em",
    height: "0.8em"
  },
  closeIcon: {
    width: "0.8em",
    height: "0.8em",
    cursor: "pointer",
    marginRight: 5
  },
  rootInput: {
    display: "flex",
    alignItems: "center",
    borderRadius: "40px"
  },
  buttonLabel: {
    padding: 5
  }
}));

const FilterDateRangeDropdown = ({
  source,
  placeholderTitle,
  onChange,
  value,
  disabled,
  onClear
}) => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const pickerLocale = i18n.language === "cs" ? cs : enUS;

  const anchorRef = useRef(null);
  const popperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      onChange({ from: dateRange[0], to: dateRange[1] });
    }
  }, [dateRange]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = (item, checked) => {
    if (checked) {
      if (!value.includes(item)) {
        onChange([...value, item]);
      }
    } else {
      onChange(value.filter(c => c !== item));
    }
  };

  const handleClearAndClose = () => {
    onClear();
    handleToggle();
  };

  return (
    <DesktopDateRangePicker
      value={value}
      disableMaskedInput
      calendars={1}
      disabled={disabled}
      onChange={(range) => {
        if (range[0] instanceof Date) {
          range[0] = new Date(range[0].getTime() - range[0].getTimezoneOffset() * 60000);
          range[0].setHours(0, 0, 0, 0);
        }
        if (range[1] instanceof Date) {
          range[1] = new Date(range[1].getTime() - range[1].getTimezoneOffset() * 60000);
          range[1].setHours(23, 59, 59, 999);
        }
        setDateRange(range);
      }}
      renderInput={(startProps, endProps) => {
        return (
          <div style={{ background: value.length ? "#D6D6D6" : "transparent" }} className={classes.rootInput} >
            <Button
              {...startProps.inputProps}
              classes={{
                root: value.length > 0 ? classes.buttonRootSelected : classes.buttonRoot,
                label: classes.buttonLabel,
                endIcon: classes.endIcon,
                text: classes.text
              }}
              ref={startProps.inputRef}
              inputProps={{ className: classes.datePickerInputBase }}
              endIcon={<ArrowDropDownIcon className={classes.icon} />}
            >
              {placeholderTitle}
            </Button>
            {value.length !== 0 ?
              <CloseIcon
                className={classes.closeIcon}
                color={disabled ? "disabled" : "action"}
                onClick={!disabled ? handleClearAndClose : () => { }}
              />
              : null
            }
          </div>
        );
      }}
    />
  );
};


export default FilterDateRangeDropdown;
