import { useFormik } from "formik2";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";

const MonthlyVehicleExpenseEditorExpenseAddDialog = ({
  expenses,
  expenseType,
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    label: Yup.string()
      .required(t("webapp:vehicle.validate.expenseLabelRequired"))
      .test(
        "is-unique",
        value => t("webapp:vehicle.validate.expenseLabelUnique"),
        value => !expenses[expenseType].find(
          ({ label }) => label === value.trim()
        )
      )
  });

  const form = useFormik({
    validationSchema,
    initialValues: {
      label: ""
    },
    onSubmit: ({ label }) => {
      onSubmit(label.trim());
    }
  });

  const handleSubmitButtonClick = () => form.submitForm();

  useEffect(() => {
    form.resetForm({
      values: {
        label: ""
      }
    });
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("webapp:vehicle.title.addExpense")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("webapp:vehicle.prompt.addExpense")}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          name="label"
          label={t("webapp:vehicle.title.expense")}
          value={form.values.label}
          margin="dense"
          error={form.touched.label && form.errors.label !== undefined}
          helperText={
            form.touched.label && form.errors.label ? form.errors.label : " "
          }
          inputProps={{ maxLength: 30 }}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmitButtonClick}>
          {t("webapp:common.title.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MonthlyVehicleExpenseEditorExpenseAddDialog;
