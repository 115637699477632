import React, { useMemo, useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import ExportDialog from "./ExportDialog";

import {
  Fab,
  makeStyles,
  IconButton,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Box,
  Grid,
} from "@material-ui/core";

import {
  DeleteOutline,
  EmojiObjectsOutlined,
  Add,
  CloudDownloadOutlined,
  ControlPoint,
  Edit as EditIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  SaveAlt,
  FileCopyOutlined, EditOutlined, ConstructionOutlined,
  PaymentOutlined
} from "@material-ui/icons";
import ReactCountryFlag from "react-country-flag";

import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import config from "../../../config";

import Page from "../../common/Page";
import DataTable from "../../../datatable";
import SearchTextfield from "../../common/SearchTextfield";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import FilterDynamicDropdown from "../../../../cargotic-webapp-filter/component/FilterDynamicDropdown";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import { useApiClient } from "@cargotic/webapp-component";
import { ShipmentInvoiceType, ContactType } from "@cargotic/model";
import { deleteInvoice } from '@cargotic/webapp/resource'

import useAuth from "@cargotic/webapp/component/hook/useAuth";

import { useDialog } from "@cargotic/webapp-component/hook"

import InvoicePaidDialog from "./InvoicePaidDialog";
import DeleteInvoiceConfirmationAlert from './DeleteInvoiceConfirmationAlert'
import InvoicesSpeedDial from './InvoicesSpeedDial'

const useStyles = makeStyles(({ palette, spacing }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  marginBottom: {
    marginBottom: spacing(2)
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)"
  }
}));

const DownloadInvoicePopoverContent = ({
  id
}) => (
  <React.Fragment>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.urlV1}/pdf/shipmentInvoice/${id}?language=en`} target="_blank" download>
          <ReactCountryFlag
            code="gb"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a href={`${config.api.url}/pdf/shipmentInvoice/${id}?language=cs`} target="_blank" download>
          <ReactCountryFlag
            code="cz"
            svg
            styleProps={{
              width: "32px",
              height: "32px"
            }}
          />
        </a>
      </Grid>
    </Grid>
  </React.Fragment>
);

const Invoices = ({
  tableRows,
  selectedTab,
  setSelectedTab,
  selectedColumns,
  availableTabs,
  dataCount,
  ordering,
  search,
  direction,
  checkedAll,
  page,
  rowsPerPage,
  loading,
  handleSort,
  loadAvailableCustomers,
  availableCustomers,
  filter,
  defaultFilters,
  handleReloadInvoices,
  handleSelect,
  handleSelectAll,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleSelectAuthor,
  handleSelectCustomer,
  handleSelectCreatedAtRange,
  handleSelectDuzpRange,
  handleSelectIssueDateRange,
  handleSelectDueDateRange,
  handleSelectStatus,
  handleSelectPaidAtDateRange,
  handleDeselectFilters,
  handleFilterSettingsOpen,
  handleChangeSelectedColumns,
  exportInvoicesPohoda,
  openInvoicesExportDialog,
  closeInvoicesExportDialog,
  isInvoicesExportDialogOpen,
  isInvoicesExportProcessing
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApiClient();
  const { enqueueSnackbar } = useSnackbar();
  const { hasPermission, user } = useAuth();

  const [
    isDeleteInvoiceConfirmationOpen,
    handleOpenDeleteInvoiceConfirmation,
    handleCloseDeleteInvoiceConfirmation,
    deleteInvoiceData
  ] = useDialog()

  const [
    isPaidAtDialogOpen,
    handleOpenPaidAtDialog,
    handleClosePaidAtDialog,
    paidAtDialogData
  ] = useDialog()

  const EXPORT_TABS = [
    { id: 0, labelKey: "invoices.export.main", type: "export" },
    { id: 1, labelKey: "invoices.export.api", type: "api" }
  ];

  const [selectedExportTab, handleSetSelectedExportTab] = useState(EXPORT_TABS.find((tab) => tab.type === "export") || EXPORT_TABS[0]);

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const selectedInvoiceIds = useMemo(() => tableRows.map(
    ({ selected, id }) => selected
      ? id
      : undefined
  ).filter(Boolean),
    [tableRows])

  const tableHeader = useMemo(() => [
    {
      name: "index_number",
      label: "invoices.invoiceNumber",
      isOrderable: true
    },
    {
      name: selectedTab?.type === ShipmentInvoiceType.RECEIVED ? "supplier_id" : "customer_id",
      label: selectedTab?.type === ShipmentInvoiceType.RECEIVED ? "invoices.invoice.supplier" : "invoices.invoice.customer",
      isOrderable: true
    },
    {
      name: "vs",
      label: "invoices.vs",
      isOrderable: true
    },
    {
      name: selectedTab?.type === ShipmentInvoiceType.RECEIVED ? "supplierContactSnapshot.ico" : "customerContactSnapshot.ico",
      label: "invoices.ic",
      isOrderable: true
    },
    {
      name: selectedTab?.type === ShipmentInvoiceType.RECEIVED ? "supplierContactSnapshot.dic" : "customerContactSnapshot.dic",
      label: "invoices.dic",
      isOrderable: true
    },
    {
      name: "release_date",
      label: "invoices.date",
      isOrderable: true
    },
    {
      name: "duzp",
      label: "invoices.duzp",
      isOrderable: true
    },
    {
      name: "duedate",
      label: "invoices.dueDate",
      isOrderable: true
    },
    {
      name: "price",
      label: "invoices.price",
      isOrderable: true
    },
    {
      name: "totalWithoutVAT",
      label: "invoices.totalWithoutVAT",
      isOrderable: false
    },
    {
      name: "totalVAT",
      label: "invoices.totalVAT",
      isOrderable: false
    },
    {
      name: "currency",
      label: "invoices.currency",
      isOrderable: false
    },
    {
      name: "paid_state",
      label: "invoices.payedState",
      isOrderable: false
    },
    {
      name: "paid_at",
      label: "invoices.invoice.paidAt",
      isOrderable: true
    }
  ], [selectedTab]);

  const shipmentInvoiceStatusSource = useMemo(() => [
    { value: true, title: t("invoices.invoice.paid") },
    { value: false, title: t("invoices.invoice.unpaid") },
  ], [t])

  const canExportInvoice = useMemo(() => hasPermission("resource.invoice.export.pohoda"), [hasPermission])
  const canDeleteInvoice = useMemo(() => hasPermission("resource.invoice.delete"), [hasPermission]);
  const canCreateInvoice = useMemo(() => hasPermission("resource.invoice.create"), [hasPermission]);
  const canUpdateInvoice = useMemo(() => hasPermission("resource.invoice.update"), [hasPermission]);
  const canPayInvoice = useMemo(() => hasPermission("resource.invoice.pay"), [hasPermission]);

  const handleClickCopyInvoice = useCallback((value) => history.push(`/invoice?id=${value}&copy=true`), [history])
  const handleClickUpdateInvoice = useCallback((value) => history.push(`/invoice?id=${value}`), [history])
  const handleClickDeleteInvoice = useCallback(async () => {
    try {
      const response = await deleteInvoice(deleteInvoiceData);
      handleCloseDeleteInvoiceConfirmation()

      handleReloadInvoices();
      enqueueSnackbar(t("invoices.deleteInvoice.success"), {
        variant: "success"
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("invoices.deleteInvoice.error"), {
        variant: "error"
      });
    }
  }, [
    enqueueSnackbar,
    deleteInvoice,
    deleteInvoiceData,
    handleCloseDeleteInvoiceConfirmation,
    handleReloadInvoices
  ])

  const handleClickDeleteSelectedInvoices = useCallback(() => {
    handleCloseDeleteInvoiceConfirmation();
    const requests = selectedInvoiceIds.map(deleteInvoice);

    return Promise.all(requests)
      .then(() => {
        handleReloadInvoices(true);
        enqueueSnackbar(t("invoices.deleteInvoice.successm"), {
          variant: "success"
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("invoices.deleteInvoice.errorm"), {
          variant: "error"
        });
      });
  }, [
    handleCloseDeleteInvoiceConfirmation,
    deleteInvoice,
    enqueueSnackbar,
    selectedInvoiceIds,
  ]);

  const tableActions = useMemo(() => [
    {
      column: [
        {
          handleClick: handleOpenPaidAtDialog,
          icon: <PaymentOutlined />,
          disableFor: [!canPayInvoice ? -1 : undefined],
          title: "invoices.updatePaidAt"
        }
      ]
    },
    {
      column: selectedTab?.type === ShipmentInvoiceType.ISSUED ? [
        {
          usePopover: true,
          PopoverContent: DownloadInvoicePopoverContent,
          icon: <SaveAlt />,
          title: "invoices.saveAlt"
        }
      ] : []
    },
    {
      column: [
        {
          disableFor: [!canCreateInvoice ? -1 : undefined],
          handleClick: handleClickCopyInvoice,
          icon: <FileCopyOutlined />,
          title: "invoices.fileCopyAlt"
        }
      ]
    },
    {
      column: [
        {
          disableFor: [!canUpdateInvoice ? -1 : undefined],
          handleClick: handleClickUpdateInvoice,
          icon: <EditOutlined />,
          title: "invoices.editAlt"
        }
      ]
    },
    {
      column: [
        {
          disableFor: [!canDeleteInvoice ? -1 : undefined],
          handleClick: handleOpenDeleteInvoiceConfirmation,
          icon: <DeleteOutline />,
          title: "invoices.deleteAlt"
        }
      ]
    }
  ], [selectedTab])


  const isAnyFilterActive = useCallback(() => Object.values(filter).some((filterValue) => !!filterValue), [filter]);

  const handleLoadAvailableUsers = useCallback(async (search) => {
    try {
      const { matches } = await client.user.postUserMatchQuery({
        query: {
          match: { search, limit: 100 }
        }
      });
      return matches;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, [client.user.postUserMatchQuery])

  const handleLoadAvailableContacts = useCallback(async (types, search) => {
    try {
      const matches = await client.contact.postContactSuggestQuery({
        query: {
          search,
          types
        }
      });
      return matches;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, [client.contact.postContactMatchQuery])

  return (
    <>
      <InvoicePaidDialog
        invoiceId={paidAtDialogData}
        onClose={handleClosePaidAtDialog}
        isOpen={isPaidAtDialogOpen}
        onSuccess={handleReloadInvoices}
      />

      <DeleteInvoiceConfirmationAlert
        selectedInvoices={selectedInvoiceIds}
        isOpen={isDeleteInvoiceConfirmationOpen}
        onClose={handleCloseDeleteInvoiceConfirmation}
        onSubmit={selectedInvoiceIds?.length > 0 ? handleClickDeleteSelectedInvoices : handleClickDeleteInvoice}
      />
      <Page
        title={t("invoices.agendaTitle")}
        secondaryActions={
          <Tabs
            value={selectedTab.id}
            onChange={(event, newlySelectedTabId) => {
              setSelectedTab(availableTabs.find((tab) => tab.id === newlySelectedTabId));
            }}
            indicatorColor="primary"
            textColor="secondary"
            aria-label="invoice type tabs"
          >
            {availableTabs.map((tab) => <Tab id={tab.id} key={tab.id} label={t(tab.labelKey)} />)}
          </Tabs>
        }
        actions={(
          <>
            <Tooltip title={t("invoices.createTooltipTitle")}>
              <InvoicesSpeedDial disabled={!canCreateInvoice} />
            </Tooltip>
            <Tooltip title={t("invoices.exportAction")}>
              <span>
                <Fab
                  color="primary"
                  disabled={selectedInvoiceIds.length === 0 || !canExportInvoice || loading}
                  onClick={() => { openInvoicesExportDialog() }}
                >
                  <CloudDownloadOutlined />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title={t("invoices.removeInvoice")}>
              <span>
                <Fab
                  className={classes.danger}
                  disabled={selectedInvoiceIds.length === 0 || !canDeleteInvoice}
                  onClick={(event) => handleOpenDeleteInvoiceConfirmation()}
                  data-cy="remove-invoice"
                >
                  <DeleteOutline />
                </Fab>
              </span>
            </Tooltip>
          </>
        )}
        tertiaryActions={(
          <>
            <Tooltip title={( // @TODO component?
              <>
                <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
                <Typography color="inherit">{t("proTableTip")}</Typography>
              </>
            )}
            >
              <IconButton>
                <EmojiObjectsOutlined />
              </IconButton>
            </Tooltip>
          </>
        )}
      >
        <FilterContainer
          loading={loading}
          searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("invoices.searchTooltip")} />}
          showClearButton={isAnyFilterActive}
          handleFilterSettingsOpen={handleFilterSettingsOpen}
          onClear={handleDeselectFilters}
          onOpenSelectColumnsDialog={() => setIsOpenSelectColumnsDialog(true)}
          defaultFilters={defaultFilters}
          filters={[
            <UserFilterDropdown
              id="author"
              key="author"
              onChange={(value) => handleSelectAuthor(value.length === 0 ? undefined : value)}
              search={(text) => handleLoadAvailableUsers(text)}
              selectAll={(all) => handleSelectAuthor(all)}
              onClear={() => handleSelectAuthor(undefined)}
              getTitle={(item) => item.name}
              value={filter.author ?? []}
              placeholderTitle={t("invoices.invoice.creator")}

            />,
            <ContactFilterDropdown
              id="customer"
              key="customer"
              onChange={(value) => handleSelectCustomer(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectCustomer(all)}
              value={filter.customer ?? []}
              search={(text) => handleLoadAvailableContacts([ContactType.CARRIER, ContactType.BOTH, ContactType.CUSTOMER], text)}
              placeholderTitle={t(selectedTab?.type === ShipmentInvoiceType.RECEIVED ? "invoices.invoice.supplier" : "invoices.invoice.customer")}
              onClear={() => handleSelectCustomer(undefined)}
              getTitle={(item) => item.name}
            />,
            <FilterDateRangeDropdown
              id="issueDate"
              key="issueDate"
              placeholderTitle={t("invoices.invoice.issueDate")}
              value={filter.issueDate ? [filter.issueDate.from, filter.issueDate.to] : []}
              onChange={(value) => handleSelectIssueDateRange(value)}
              onClear={() => handleSelectIssueDateRange(undefined)}
            />,
            <FilterDateRangeDropdown
              id="duzp"
              key="duzp"
              placeholderTitle={t("invoices.invoice.duzp")}
              value={filter.duzp ? [filter.duzp.from, filter.duzp.to] : []}
              onChange={(value) => handleSelectDuzpRange(value)}
              onClear={() => handleSelectDuzpRange(undefined)}
            />,
            <FilterDateRangeDropdown
              id="createdAt"
              key="createdAt"
              placeholderTitle={t("invoices.invoice.createdAt")}
              value={filter.createdAt ? [filter.createdAt.from, filter.createdAt.to] : []}
              onChange={(value) => handleSelectCreatedAtRange(value)}
              onClear={() => handleSelectCreatedAtRange(undefined)}
            />,
            <FilterDateRangeDropdown
              id="dueDate"
              key="dueDate"
              placeholderTitle={t("invoices.invoice.dueDate")}
              value={filter.dueDate ? [filter.dueDate.from, filter.dueDate.to] : []}
              onChange={(value) => handleSelectDueDateRange(value)}
              onClear={() => handleSelectDueDateRange(undefined)}
            />,
            <FilterStaticDropdown
              id="paid"
              key="paid"
              placeholderTitle={t("invoices.invoice.status")}
              value={filter.paid ?? []}
              onChange={(arr) => handleSelectStatus(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectStatus(shipmentInvoiceStatusSource.map(item => item.value))}
              onClear={() => handleSelectStatus(undefined)}
              source={shipmentInvoiceStatusSource}
            />,
            <FilterDateRangeDropdown
              id="paidAt"
              key="paidAt"
              placeholderTitle={t("invoices.invoice.paidAt")}
              value={filter.paidAt ? [filter.paidAt.from, filter.paidAt.to] : []}
              onChange={(value) => handleSelectPaidAtDateRange(value)}
              onClear={() => handleSelectPaidAtDateRange(undefined)}
            />,
          ]}
        />
        <DataTable
          headers={tableHeader}
          data={tableRows}
          dataCount={dataCount}
          actions={tableActions}
          loading={loading}
          ordering={ordering}
          direction={direction}
          selectedColumns={selectedColumns}
          checkedAll={checkedAll}
          page={page}
          rowsPerPage={rowsPerPage}
          onSort={handleSort}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSelectedColumns={handleChangeSelectedColumns}
          isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
          setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        />
        <ExportDialog
          infoText={t("invoices.export.info")}
          title={t("invoices.export.title")}
          closeButtonLabel={t("orders.export.close")}
          downloadCSV={t("orders.export.downloadCSV")}
          tabs={EXPORT_TABS}
          setSelectedExportTab={handleSetSelectedExportTab}
          selectedExportTab={selectedExportTab}
          startButtonLabel={t("orders.export.start")}
          processingText={t("orders.export.processing.text")}
          doneText={t("orders.export.done.text")}
          linkLabel={t("orders.export.done.link")}
          exportInvoicesPohoda={exportInvoicesPohoda}
          isOpen={isInvoicesExportDialogOpen}
          isProcessing={isInvoicesExportProcessing}
          close={closeInvoicesExportDialog}
        />
      </Page>
    </>
  );
};

export default Invoices;
