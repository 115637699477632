import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteShipmentDocument,
} from "@cargotic/api-client-deprecated";
import { Currency } from "@cargotic/currency";
import { ContactType } from "@cargotic/model-deprecated";
import { usePrevious } from "@cargotic/common-deprecated";
import {
  Fab,
  Grid,
  MenuItem,
  Typography,
  makeStyles,
  Button,
  Chip,
  Avatar,
  MenuItem,
  Menu,
} from "@material-ui/core";
import {
  AccountCircle,
  Edit as EditIcon,
  Warning,
  Work,
  Error,
  Done,
  DirectionsCar,
} from "@material-ui/icons";
import { red, yellow } from "@material-ui/core/colors";
import { VehicleType } from "@cargotic/model";
import useAuth from "../../../component/hook/useAuth";
import ContactEditor from "../../../../cargotic-webapp-contact/component/ContactEditor";
import EmployeeEditor from "../../../../cargotic-webapp-contact/component/ContactEmployeeEditor";
import SetConditionsDialog from "../../../component/core/CompanyProfile/SetConditionsDialog";

import PlaceSearchFailDialog from "../../../component/common/PlaceSearchFailDialog";

import { ErrorHandler } from "../../../../cargotic-webapp-error";

import {
  ContactFormAutosuggestTextField,
  EmployeeFormAutosuggestTextField,
} from "../../../contact";
import {
  FormikVehicleAutosuggestTextField,
  FormUserAutosuggestTextField,
  FormikVehicleAutosuggestTextField
} from "../../../../cargotic-webapp-component";
import VehicleEditor
  from "../../../../cargotic-webapp-vehicle/component/VehicleEditor";
import {
  FormSelect, FormTextField
} from "../../../form";
import {
  postFile,
  getIncomingOrderServices,
  deleteFile
} from "../../../resource";


import {
  generateUuid,
  formatDateTime,
} from "../../../../../multiload/cargotic-common";

import FileDropzone from "../../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload from "../../../../../multiload/cargotic-webapp/component/FileUpload";
import IncomingOrderServicesInput from "../../../incomingOrder/component/IncomingOrderFormContent/IncomingOrderServicesInput";
import Placeholder from "../../../component/common/Placeholder";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    "& > section": {
      "&:not(:first-child)": {
        marginTop: spacing(4),
      },
      "& > h6": {
        marginBottom: spacing(3),
      },
    },
  },
  fab: {
    display: "flex",
    justifyContent: "center",
  },
  upload: {
    marginTop: spacing(2),
  },
  warning: {
    color: palette.warning.light,
  },
}));

const ShipmentForwarderForm = ({
  className,
  form,
  onDocumentsChange,
  apiClient,
  availableTerms,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const language = i18n.language === "cs" ? "CZ" : "EN";
  const handleError = ErrorHandler(t, (message) =>
    enqueueSnackbar(message, { variant: "error" })
  );

  const [availableServices, setAvailableServices] = useState([]);

  const [
    hasAdditionalDocumentFileDropzoneError,
    setHasAdditionalDocumentFileDropzoneError,
  ] = useState(false);
  const [
    hasTermsDocumentFileDropzoneError,
    setHasTermsDocumentFileDropzoneError,
  ] = useState(false);

  const previousValues = usePrevious(form.values);

  const termsId = form.values.terms ? form.values.terms.termsId : undefined;
  const areTermsForCompany = form.values.terms
    ? form.values.terms.isCompany
    : undefined;
  const { terms } = form.values;
  const haveTermsMissingLanguage = !(terms?.cs?.content && terms?.en?.content);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [selectedVehicleType, setSelectedVehicleType] = useState();
  const [isVehicleEditorOpen, setIsVehicleEditorOpen] = useState(false);

  const [selectedContact, setSelectedContact] = useState();
  const [selectedContactType, setSelectedContactType] = useState();
  const [isContactEditorOpen, setIsContactEditorOpen] = useState(false);
  const [searchFailDialogOpen, setSearchFailDialogOpen] = useState(false);
  const [employeeEditorOpen, setEmployeeEditorOpen] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState({});
  const [conditionsDialogOpen, setConditionsDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);


  const handleDocumentDelete = async (uuid) => {
    const { url, bucket, name } = form.values.documents.find(
      ({ uuid: other }) => other === uuid
    );

    if (url) {
      try {
        await deleteFile(bucket, name);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(
          t("webapp:outcomingOrder.form.document.error.file.delete"),
          { variant: "error" }
        );

        return;
      }
    }

    form.setValues((values) => ({
      ...values,
      documents: values.documents.filter(({ uuid: other }) => other !== uuid),
    }));
  };

  useEffect(() => {
    getIncomingOrderServices().then((services) => {
      setAvailableServices(services.map(({ name, type }) => ({ name, type })));
    });
  }, []);

  useEffect(() => {
    onDocumentsChange();
  }, [form.values.documents]);

  const handleTermsDocumentFileDropzoneError = () =>
    setHasTermsDocumentFileDropzoneError(true);
  const handleAdditionalDocumentFileDropzoneError = () =>
    setHasAdditionalDocumentFileDropzoneError(true);

  const handleDocumentFilesSelect = (files, form, bucket) => {
    setHasTermsDocumentFileDropzoneError(false);
    setHasAdditionalDocumentFileDropzoneError(false);

    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true,
        bucket,
      };

      const updateDocument = (update) =>
        form.setValues((values) => ({
          ...values,
          documents: values.documents.map((other) => {
            if (other.uuid !== uuid) {
              return other;
            }

            return { ...other, ...update };
          }),
        }));

      form.setValues((values) => ({
        ...values,
        documents: [...values.documents, document],
      }));

      try {
        const { url, createdAt, id } = await postFile({
          bucket,
          file,
        });

        updateDocument({
          id,
          url,
          createdAt: new Date(createdAt),
          isUploading: false,
          bucket,
        });
      } catch (error) {
        console.log(error);

        let message = t(
          "webapp:outcomingOrder.form.document.error.upload.general"
        );

        if (error?.response?.data?.error === "FileTooLargeError") {
          message = t("webapp:outcomingOrder.form.document.error.upload.size");
        }

        if (error?.response?.data?.error === "StorageLimitExceeded") {
          message = t("webapp:incomingOrder.form.document.error.upload.limit");
        }

        updateDocument({
          isUploading: false,
          error: message,
        });
      }
    });
  };

  const handleEmployeeSubmit = async (employee) => {
    try {
      if (employee.id) {
        await apiClient.contact.putContactEmployee({
          contactId: employee.contactId,
          employeeId: employee.id,
          employee: {
            email: employee.email,
            name: employee.name,
            phoneNumber: employee.phoneNumber,
          },
        });
      } else {
        const { id } = await apiClient.contact.postContactEmployee({
          contactId: employee.contactId,
          employee: {
            name: employee.name,
            email: employee.email,
            phoneNumber: employee.phoneNumber,
          },
        });
        employee = { ...employee, id };
      }
      if (employee.contactId === form.values.customerContact.id) {
        form.setValue("customerEmployee", employee);
      } else {
        form.setValue("carrierEmployee", employee);
      }
      setEmployeeEditorOpen(false);
    } catch (err) {
      console.error(err);
      if (employee.id) {
        enqueueSnackbar(t("contacts.employees.error.update"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("contacts.employees.error.create"), {
          variant: "error",
        });
      }
    }
  };

  const disableCustomerContactButton = () => {
    const {
      values: { customerContact },
    } = form;

    if (typeof customerContact === "string" && customerContact !== "") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const disableCustomerEmployeeButton = () => {
    const {
      values: { customerContact },
    } = form;

    if (typeof customerContact === "string") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const disableCarrierEmployeeButton = () => {
    const {
      values: { carrierContact },
    } = form;

    if (typeof carrierContact === "string") {
      return true;
    }

    if (carrierContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const disableCarrierContactButton = () => {
    const {
      values: { carrierContact },
    } = form;

    if (typeof carrierContact === "string" && carrierContact !== "") {
      return true;
    }

    if (carrierContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const fetchContact = async (contactId) =>
    apiClient.contact.getContact({ contactId });

  const handleCarrierContactChange = async () => {
    const {
      values: { carrierContact },
    } = form;
    const { carrierContact: oldCarrier } = previousValues ?? {};

    if (
      !carrierContact ||
      (oldCarrier && oldCarrier.id !== carrierContact.id)
    ) {
      form.setValue("carrierEmployee", "");
    }

    if (typeof form.values.carrierContact === "string") {
      return;
    }

    if (carrierContact.paymentDueDays) {
      form.setValue("carrierPaymentDueDays", carrierContact.paymentDueDays);
    }

    if (carrierContact?.billingAddress) {
      return;
    }

    const contact = await fetchContact(carrierContact.id);

    if (contact.paymentDueDays) {
      form.setValue("carrierPaymentDueDays", contact.paymentDueDays);
    }

    form.setValue("carrierContact", contact);
  };

  useEffect(() => {
    handleCarrierContactChange();
  }, [form.values.carrierContact]);

  const handleContactEditorSubmit = async ({ id: contactId, ...contact }) => {
    setIsContactEditorOpen(false);

    try {
      let result;

      if (contactId) {
        result = await apiClient.contact.putContact({ contactId, contact });
      } else {
        result = await apiClient.contact.postContact({ contact });
      }

      if (selectedContactType === ContactType.CARRIER) {
        form.setValue("carrierContact", result);
      } else {
        form.setValue("customerContact", result);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleVehicleEditorSubmit = async (vehicle) => {
    setIsVehicleEditorOpen(false);

    const vehicleId =
      selectedVehicleType === VehicleType.CAR
        ? form.values.vehicle?.id
        : form.values.trailer?.id;

    try {
      let result = vehicleId
        ? await client.vehicle.putVehicle({ vehicleId, vehicle })
        : await client.vehicle.postVehicle({ vehicle });

      result = { ...vehicle, ...result };

      if (selectedVehicleType === VehicleType.CAR) {
        form.setValue("vehicle", result);
      } else {
        form.setValue("trailer", result);
      }
    } catch (error) {
      console.error(error);

      const {
        response: { data: errData },
      } = error;

      if (errData.type === "DuplicateEntityError") {
        enqueueSnackbar(t("vehicles.error.duplicate"), { variant: "error" });
      } else if (vehicleId) {
        enqueueSnackbar(t("vehicles.error.update"), { variant: "error" });
      } else {
        enqueueSnackbar(t("vehicles.error.create"), { variant: "error" });
      }
    }
  };

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <section data-cy="forwarder-form-carrier">
          <Typography variant="subtitle2">
            {t("webapp:outcomingOrder.form.subtitle.carrier")}
          </Typography>
          <Grid container spacing={2}>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCarrierContactButton()}
                onClick={() => {
                  setSelectedContact(
                    form.values.carrierContact || { type: ContactType.CARRIER }
                  );
                  setSelectedContactType(ContactType.CARRIER);
                  setIsContactEditorOpen(true);
                }}
              >
                {form.values.carrierContact ? <EditIcon /> : <AccountCircle />}
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <ContactFormAutosuggestTextField
                form={form}
                apiClient={apiClient}
                contactType={ContactType.CARRIER}
                name="carrierContact"
                label={t("webapp:outcomingOrder.form.label.contact")}
                fullWidth
                data-cy="carrier-contact"
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCarrierEmployeeButton()}
                onClick={() => {
                  setEmployeeToEdit({
                    ...form.values.carrierEmployee,
                    contactId: form.values.carrierContact.id,
                  });
                  setEmployeeEditorOpen(true);
                }}
              >
                {form.values.carrierEmployee ? <EditIcon /> : <Work />}
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <EmployeeFormAutosuggestTextField
                form={form}
                apiClient={apiClient}
                disabled={!form.values.carrierContact?.id}
                contactId={form.values.carrierContact?.id}
                name="carrierEmployee"
                label={t("webapp:outcomingOrder.form.label.employee")}
                fullWidth
              />
            </Grid>
            {form.values.carrierContact?.isSubcontractor ? (
              <>
                <Grid className={classes.fab} item xs={1}>
                  <Fab
                    size="medium"
                    color="primary"
                    onClick={() => {
                      setIsVehicleEditorOpen(true);
                      setSelectedVehicle(
                        typeof form.values.vehicle !== "string"
                          ? form.values.vehicle
                          : {}
                      );
                      setSelectedVehicleType(VehicleType.CAR);
                    }}
                  >
                    {typeof form.values.vehicle === "string" ? (
                      <DirectionsCar />
                    ) : (
                      <EditIcon />
                    )}
                  </Fab>
                </Grid>
                <Grid item xs={11}>
                  <FormikVehicleAutosuggestTextField
                    key="carrier-form-vehicle"
                    externalQueryParams={{
                      contactId: form.values.carrierContact?.id,
                    }}
                    form={form}
                    name="vehicle"
                    type={VehicleType.CAR}
                    label={t("webapp:shipment.form.label.vehicle")}
                    fullWidth
                  />
                </Grid>

                <Grid className={classes.fab} item xs={1}>
                  <Fab
                    size="medium"
                    color="primary"
                    onClick={() => {
                      setIsVehicleEditorOpen(true);
                      setSelectedVehicle(
                        typeof form.values.trailer !== "string"
                          ? form.values.trailer
                          : {}
                      );
                      setSelectedVehicleType(VehicleType.TRAILER);
                    }}
                  >
                    {typeof form.values.trailer === "string" ? (
                      <DirectionsCar />
                    ) : (
                      <EditIcon />
                    )}
                  </Fab>
                </Grid>
                <Grid item xs={11}>
                  <FormikVehicleAutosuggestTextField
                    key="carrier-form-trailer"
                    externalQueryParams={{
                      contactId: form.values.carrierContact?.id,
                    }}
                    form={form}
                    name="trailer"
                    type={VehicleType.TRAILER}
                    label={t("webapp:shipment.form.label.trailer")}
                    fullWidth
                  />
                </Grid>

                <Grid className={classes.fab} item xs={1}>
                  <Fab size="medium" color="primary" disabled>
                    <AccountCircle />
                  </Fab>
                </Grid>
                <Grid item xs={11}>
                  <FormUserAutosuggestTextField
                    key="carrier-form-driver"
                    externalQueryParams={{
                      contactId: form.values.carrierContact?.id,
                    }}
                    form={form}
                    permissions={["resource.user.role.permission.driver"]}
                    name="driver"
                    label={t("webapp:shipment.form.label.driver")}
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <FormTextField
                    form={form}
                    name="driverContact"
                    label={t("webapp:outcomingOrder.form.label.driverContact")}
                    hasFullWidth
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <FormTextField
                    form={form}
                    name="vehicleInfo"
                    label={t("webapp:outcomingOrder.form.label.vehicle")}
                    hasFullWidth
                  />
                </Grid>
              </>
            )}
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                form={form}
                name="orderSerialNumber"
                label={t("webapp:outcomingOrder.form.label.orderSerialNumber")}
                hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormTextField
                form={form}
                name="carrierPaymentDueDays"
                label={t("webapp:outcomingOrder.form.label.dueDays")}
                hasFullWidth
              />
            </Grid>
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("incomingOrders.services")}
          </Typography>
          <Placeholder
            render={() => (
              <IncomingOrderServicesInput
                form={form}
                name="services"
                availableServices={availableServices}
              />
            )}
            loading={!availableServices || !availableServices.length}
          />
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("incomingOrders.totalPrice")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <FormTextField
                form={form}
                name="carrierPrice"
                label={t("webapp:outcomingOrder.form.label.price")}
                hasFullWidth
                isDisabled
                dataCy="carrier-price"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelect
                form={form}
                name="carrierPriceCurrency"
                label={t("webapp:outcomingOrder.form.label.currency")}
                hasFullWidth
              >
                {Object.values(Currency).map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </FormSelect>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.terms")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              {termsId ? (
                haveTermsMissingLanguage ? (
                  <Chip
                    avatar={
                      <Avatar>
                        <Warning style={{ backgroundColor: yellow[700] }} />
                      </Avatar>
                    }
                    label={t("settings.incompleteConditions")}
                    style={{ backgroundColor: yellow[700], color: "#000" }}
                    onDelete={(e) =>
                      form.setValues((values) => ({
                        ...values,
                        terms: undefined,
                        termsId: undefined,
                      }))
                    }
                  />
                ) : (
                  <Chip
                    avatar={
                      <Avatar>
                        <Done />
                      </Avatar>
                    }
                    label={
                      areTermsForCompany
                        ? t("settings.defaultCompanyConditions")
                        : t("settings.defaultUserConditions")
                    }
                    color="primary"
                    onDelete={(e) =>
                      form.setValues((values) => ({
                        ...values,
                        terms: undefined,
                        termsId: undefined,
                      }))
                    }
                  />
                )
              ) : (
                <Chip
                  avatar={
                    <Avatar>
                      <Error
                        style={{ color: red[600], backgroundColor: "#fff" }}
                      />
                    </Avatar>
                  }
                  label={t("settings.undefinedConditions")}
                  style={{ backgroundColor: red[600], color: "#fff" }}
                />
              )}
            </Grid>
            <Grid item xs>
              {termsId ? (
                <Button onClick={() => setConditionsDialogOpen(true)}>
                  {t("show")}
                </Button>
              ) : (
                <>
                  <Button
                    aria-controls="terms-select"
                    aria-haspopup="true"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    {t("select")}
                  </Button>
                  <Menu
                    id="terms-select"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    {availableTerms.map((item) => (
                      <MenuItem
                        onClick={() => {
                          form.setValues((values) => ({
                            ...values,
                            terms: item,
                            termsId: item.termsId,
                          }));
                          setAnchorEl(null);
                        }}
                      >
                        {item.isCompany
                          ? t("webapp:shipment.form.subtitle.companyTerms")
                          : t("webapp:shipment.form.subtitle.userTerms")}

                      </MenuItem>
                    ))}
                  </Menu>
                  <Button href="/settings">{t("goToSettings")}</Button>
                </>
              )}
            </Grid>
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:outcomingOrder.form.subtitle.documents")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormTextField
                form={form}
                name="notes"
                label={t("webapp:outcomingOrder.form.label.notes")}
                variant="outlined"
                rows={6}
                isMultiline
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.caption}>
                  {t("webapp:common.title.terms-documents")}
                </Typography>
                <FileDropzone
                  accept={["application/pdf", "image/jpeg", "image/png"]}
                  title={
                    hasTermsDocumentFileDropzoneError
                      ? t("webapp:shipment.form.document.error.file.type")
                      : t("webapp:shipment.form.document.title.upload")
                  }
                  description={[
                    t("webapp:shipment.form.document.description.about"),
                    t("webapp:shipment.form.document.description.supported"),
                    t("webapp:shipment.form.document.description.limit"),
                  ]}
                  hasError={hasTermsDocumentFileDropzoneError}
                  onError={handleTermsDocumentFileDropzoneError}
                  onSelect={(files) =>
                    handleDocumentFilesSelect(
                      files,
                      form,
                      "cgt-staging-outcoming-order-terms-documents"
                    )
                  }
                />
                {form.values.documents
                  .filter(
                    ({ bucket }) =>
                      bucket === "cgt-staging-outcoming-order-terms-documents"
                  )
                  .map(
                    (
                      { uuid, name, url, error, isUploading, createdAt },
                      index
                    ) => (
                      <React.Fragment key={uuid}>
                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <FileUpload
                            className={index === 0 ? classes.upload : undefined}
                            title={name}
                            description={
                              createdAt ? formatDateTime(createdAt) : undefined
                            }
                            url={url}
                            isUploading={isUploading}
                            error={error}
                            onDelete={() =>
                              handleDocumentDelete(
                                uuid,
                                form,
                                "cgt-staging-outcoming-order-terms-documents"
                              )
                            }
                          />
                        </Grid>
                      </React.Fragment>
                    )
                  )}
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.caption}>
                  {t("webapp:common.title.additional-documents")}
                </Typography>
                <FileDropzone
                  accept={["application/pdf", "image/jpeg", "image/png"]}
                  title={
                    hasAdditionalDocumentFileDropzoneError
                      ? t("webapp:shipment.form.document.error.file.type")
                      : t("webapp:shipment.form.document.title.upload")
                  }
                  description={[
                    t("webapp:shipment.form.document.description.about"),
                    t("webapp:shipment.form.document.description.supported"),
                    t("webapp:shipment.form.document.description.limit"),
                  ]}
                  hasError={hasAdditionalDocumentFileDropzoneError}
                  onError={handleAdditionalDocumentFileDropzoneError}
                  onSelect={(files) =>
                    handleDocumentFilesSelect(
                      files,
                      form,
                      "cgt-staging-outcoming-order-additional-documents"
                    )
                  }
                />
                {form.values.documents
                  .filter(
                    ({ bucket }) =>
                      bucket ===
                      "cgt-staging-outcoming-order-additional-documents"
                  )
                  .map(
                    (
                      { uuid, name, url, error, isUploading, createdAt },
                      index
                    ) => (
                      <React.Fragment key={uuid}>
                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <FileUpload
                            className={index === 0 ? classes.upload : undefined}
                            title={name}
                            description={
                              createdAt ? formatDateTime(createdAt) : undefined
                            }
                            url={url}
                            isUploading={isUploading}
                            error={error}
                            onDelete={() =>
                              handleDocumentDelete(
                                uuid,
                                form,
                                "cgt-staging-outcoming-order-additional-documents"
                              )
                            }
                          />
                        </Grid>
                      </React.Fragment>
                    )
                  )}
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div >
      <VehicleEditor
        initialValue={selectedVehicle}
        isOpen={isVehicleEditorOpen}
        type={selectedVehicleType}
        onClose={() => setIsVehicleEditorOpen(false)}
        onSubmit={handleVehicleEditorSubmit}
      />
      <ContactEditor
        initialValue={selectedContact}
        isOpen={isContactEditorOpen}
        type={selectedContactType}
        onClose={() => setIsContactEditorOpen(false)}
        onSubmit={handleContactEditorSubmit}
      />
      <PlaceSearchFailDialog
        isOpen={searchFailDialogOpen}
        onClose={() => setSearchFailDialogOpen(false)}
      />
      <EmployeeEditor
        initialValue={employeeToEdit}
        isOpen={employeeEditorOpen}
        onClose={() => setEmployeeEditorOpen(false)}
        onSubmit={handleEmployeeSubmit}
      />
      <SetConditionsDialog
        isCompanyConditions={areTermsForCompany}
        isReadOnly
        open={conditionsDialogOpen}
        conditions={terms}
        handleClose={() => setConditionsDialogOpen(false)}
      />
    </>
  );

}

export default ShipmentForwarderForm;
