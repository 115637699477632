import Axios from "axios";

interface ApiClient {
  baseUrl: string;
}

enum HttpMethod {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT"
}

interface HttpResponse {
  // eslint-disable-next-line
  data: any;
  status: number;
}

function httpRequest(
  { baseUrl }: ApiClient,
  method: HttpMethod,
  url: string,
  parameters?: object,
  data?: object
): Promise<HttpResponse> {
  return Axios.request({
    method,
    url,
    data,
    baseURL: baseUrl,
    params: parameters,
    withCredentials: true
  });
}

function httpDelete(
  client: ApiClient, url: string, parameters?: object
): Promise<HttpResponse> {
  return httpRequest(client, HttpMethod.DELETE, url, parameters);
};

function httpGet(
  client: ApiClient, url: string, parameters?: object
): Promise<HttpResponse> {
  return httpRequest(client, HttpMethod.GET, url, parameters);
}

function httpPut(
  client: ApiClient, url: string, parameters?: object, data?: object
): Promise<HttpResponse> {
  return httpRequest(client, HttpMethod.PUT, url, parameters, data);
}

function httpPatch(
  client: ApiClient, url: string, parameters?: object, data?: object
): Promise<HttpResponse> {
  return httpRequest(client, HttpMethod.PATCH, url, parameters, data);
}

function httpPost(
  client: ApiClient, url: string, parameters?: object, data?: object
): Promise<HttpResponse> {
  return httpRequest(client, HttpMethod.POST, url, parameters, data);
}

export {
  ApiClient,

  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpRequest,
  httpPut
};
