import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles, TextField
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ShipmentsBoxDialog = ({
  open,
  handleClose,
  handleSubmit,
  setBox
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("shipments.box.set")}</DialogTitle>
      <DialogContent>
        <TextField
          onInput={(e) => setBox(e.target.value)}
          /*classes={{ root: classes.progress }}*/
          id="box"
          label={t("shipments.box.title")}
          variant="standard" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("abort")}
        </Button>
        <Button onClick={handleSubmit} color="primary" data-cy="set-shipment-box">
          {t("shipments.box.set")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShipmentsBoxDialog;
