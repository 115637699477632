import React from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "../../../cargotic-webapp-component";

const ContactDuplicateAlert = ({
  duplicate: {
    name: contactName,
    createdBy: {
      name: creatorName
    },
    isMine,
    isPrivate,
    isSharedWithMe
  },
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();
  const content = (() => {
    if (isMine) {
      return t("webapp:contact.duplicate.mine", { contactName });
    }

    if (!isPrivate) {
      return t("webapp:contact.duplicate.public", { contactName });
    }

    if (isSharedWithMe) {
      return t("webapp:contact.duplicate.shared", { contactName, creatorName });
    }

    return t("webapp:contact.duplicate.private", { contactName, creatorName });
  })();

  return (
    <Alert
      title={t("webapp:contact.title.duplicate")}
      content={content}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default ContactDuplicateAlert;
