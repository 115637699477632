import React from "react";

import {
  BusinessCenter as BusinessCenterIcon,
  Place as PlaceIcon,
  LocationCity as LocationCityIcon
} from "@material-ui/icons";

const AddressAutosuggestTextFieldPlaceSuggestionIcon = ({
  placeType,
  ...props
}) => {
  if (placeType === "establishment") {
    return (
      <LocationCityIcon {...props} />
    );
  }

  return (
    <PlaceIcon {...props} />
  );
};

export default AddressAutosuggestTextFieldPlaceSuggestionIcon;
