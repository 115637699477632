import React, { useEffect, useState, useRef, useCallback } from "react";
import { debounce } from "@cargotic/time";

import {
    getItemsOnPage,
    getOrdering,
    getSelectedItemsOnPage
} from "../utility/functional";
import {
    loadPagination,
    storePagination,
    loadPage,
    storePage
} from "../storage";
import useRouter from "../component/hook/useRouter";
import { OrderingDirection } from "../component/enums/enums";
import { useApiClient } from "../../cargotic-webapp-component";

function useTable(fetchData, tableName, onSelect = () => {}, onSelectAll = () => {}) {
  const client = useApiClient();
  const { location } = useRouter();
  const pathname = location.pathname.slice(1);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [ordering, setOrdering] = useState([]);
  const [direction, setDirection] = useState([OrderingDirection.DESC]);
  const [rowsPerPage, setRowsPerPage] = useState(loadPagination(pathname));
  const [page, setPage] = useState(loadPage(pathname));
  const [loading, setLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState(null);

  const firstPage = useRef(true);
  const firstRows = useRef(true);
  const firstDirection = useRef(true);

  const timeout = useRef();

  const loadSelectedColumns = async () => {
    try {
      const result = await client.selectedColumns.findSelectedColumns({ tableName });

      if (result) {
        return result.selectedColumns;
      }
    } catch (error) {
      console.log(error);
    }

    return null;
  };

  const reload = async () => {
    const _direction = direction.map(dir => dir.toUpperCase());
    const _data = await fetchData(
      page * rowsPerPage, rowsPerPage, getOrdering(ordering, _direction)
    );

    const _selectedColumns = await loadSelectedColumns();

    if (_data.data) {
      setData(_data.data);
    }
    if (_data.totalCnt) {
      setDataCount(_data.totalCnt);
    }

    setSelectedColumns(_selectedColumns);
    setCheckedAll(false);
    setLoading(false);
  };

  const reloadData = () => {
    setLoading(true);
    reload();
  };

  const reloadDataFromScratch = async () => {
    if (page != 0) {
      setPage(0);
    }
    else {
      reloadData();
    }
  };

  const handleSort = (primary, shiftKeyPressed) => {
    const index = ordering.indexOf(primary);
    let _ordering = ordering.slice(0);
    let _directions = direction.slice(0);

    if (index > -1) {
      _directions[index] = (_directions[index] === OrderingDirection.DESC) ? OrderingDirection.ASC : OrderingDirection.DESC;
      if (!shiftKeyPressed) {
        _directions = [_directions[index]];
        _ordering = [_ordering[index]];
      }
    } else {
      _ordering = shiftKeyPressed ? [..._ordering, primary] : [primary];
      _directions = shiftKeyPressed ? [..._directions, OrderingDirection.DESC] : [OrderingDirection.DESC];
    }

    setOrdering(_ordering);
    setDirection(_directions);
  };

  const handleSelect = (index, selected, onlyOne) => {
    if (onlyOne) {
      data.map((_data) => {
        _data.selected = false;
        return _data;
      });
    }
    setCheckedAll(selected
      && getSelectedItemsOnPage(data, page, rowsPerPage).length === getItemsOnPage(data, page, rowsPerPage).length - 1);
    let _data = data.slice(0);
    _data[index].selected = selected;
    setData(_data);
    onSelect(_data[index])
  };

  const handleSelectAll = (selected) => {
    console.log(data)
    data.map((_data) => {
      if (!_data.disabled) {
        _data.selected = selected;
      }
      return _data;
    });
    setData(data);
    setCheckedAll(selected);
    onSelectAll(data)
  };

  const handleChangePage = (_, newPage, _data = data) => {
    data.map((_data) => {
      _data.selected = false;
      return _data;
    });
    setCheckedAll(false);
    storePage(pathname, newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    data.map((_data) => {
      _data.selected = false;
      return _data;
    });
    const rpp = +event.target.value;
    setPage(0);
    setRowsPerPage(rpp);
    setCheckedAll(false);
    storePagination(pathname, rpp);
    storePage(pathname, 0);
  };

  useEffect(() => {
    if (firstRows.current) {
      firstRows.current = false;
      return;
    }

    setPage(0);
    storePage(pathname, 0);
    reloadData();
  }, [fetchData, rowsPerPage]);

  useEffect(() => {
    if (firstPage.current) {
      firstPage.current = false;
      return;
    }

    reloadData();
  }, [page]);

  useEffect(() => {
    if (firstDirection.current) {
      firstDirection.current = false;
      return;
    }

    reload();
  }, [direction]);

  useEffect(() => {
    reloadData();
  }, []);


  const handleChangeSelectedColumns = (newSelectedColumns) => {
    if (newSelectedColumns) {
      const apiCall = selectedColumns
        ? client.selectedColumns.updateSelectedColumns
        : client.selectedColumns.createSelectedColumns;

      apiCall({
        tableName,
        selectedColumns: { selectedColumns: newSelectedColumns }
      });
    } else {
      client.selectedColumns.deleteSelectedColumns(client, tableName);
    }

    setSelectedColumns(newSelectedColumns);
  };

  return {
    data,
    dataCount,
    loading,
    selectedColumns,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reload,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData
  };
}

export default useTable;
