import React, { useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik2";
import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";

import useAuth from "../../../hook/useAuth";
import useRouter from "../../../hook/useRouter";
import { addUrlParam } from "../../../../utility/window";
import SettingsRoleEditor from "../SettingsRoleEditor";

import {
  readCompanyPermissions,
  createUserRole,
  updateCompanyRole,
  readCompanyRoleById
} from "../../../../resource";
import RoleEditorView from "../RoleEditorView";


const RoleEditorContainer = () => {
  const { t, i18n } = useTranslation();
  const { user, signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const language = i18n.language === "cs" ? "CZ" : "EN";
  const { location: { search } } = useRouter();
  const { view: tabView } = queryString.parse(search);

  const history = useHistory();
  const params = useParams();

  const roleId = parseInt(params.id, 10);

  const [isRoleLoading, setIsRoleLoading] = useState(true);
  const [isCompanyPermissionsLoading, setIsCompanyPermissionsLoading] = useState(true);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [role, setRole] = useState();
  const [view, setView] = useState(tabView ?? "GENERAL");

  const edit = role && role.id;
  const initialRoleName = role ? role.name : "";
  const initialRoleDescription = role ? role.description : "";
  const initialRolePermissions = role ? role.permissions.map(({ id }) => id) : [];

  useEffect(() => {
    addUrlParam("view", view);
  }, [view]);

  const handleViewChange = value =>
    setView(value);

  const handleRoleEditorSubmit = (data) => {
    const { id, ...rest } = data;
    if (id) {
      handleUpdateUserRole(data);
    }
    else {
      handleCreateUserRole(data);
    }
    history.push(`/settings/roles`);
  }

  const handleUpdateUserRole = async ({ id, name, description, permissionsIds }) => {
    try {
      await updateCompanyRole(id, { name, description, permissionsIds });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error"
      });
    }
  }

  const handleCreateUserRole = async ({ name, description, permissionsIds }) => {
    try {
      await createUserRole({ name, description, permissionsId: permissionsIds });
    } catch (error) {
      console.error(error);

      enqueueSnackbar(error, {
        variant: "error"
      });
    }
  }

  const loadAvailableCompanyPermissions = async () => {
    setIsCompanyPermissionsLoading(true);

    try {
      const permissions = await readCompanyPermissions();
      setAvailablePermissions(permissions);
      setIsCompanyPermissionsLoading(false);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("webapp:role.error.load"), {
        variant: "error"
      });
    }

  }

  const loadRole = async () => {
    setIsRoleLoading(true);

    try {
      const role = await readCompanyRoleById(roleId);
      setRole(role);
      setIsRoleLoading(false);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("webapp:role.error.load"), {
        variant: "error"
      });
    }

  }

  useEffect(() => {
    loadAvailableCompanyPermissions();
    if (roleId) {
      loadRole();
    } else {
      setIsRoleLoading(false);
    }
  }, []);

  const breadcrumbs = [
    { label: t("webapp:settings.title.settings"), href: "/settings" },
    { label: t("webapp:common.title.role"), href: `/settings/roles` },
    edit ? { label: role ? role.name : "", href: `/settings/role/${role.id}` } : { label: t("create"), href: `/settings/role/` }
  ];

  const GeneralInfoSchema = Yup.object({
    roleName: Yup.string()
      .max(20, t("webapp:common.validate.name"))
      .required(t("webapp:common.validate.name")),
    description: Yup.string()
      .max(256, t("webapp:common.validate.description"))
      .required(t("webapp:common.validate.description"))
  });

  const generalForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      roleName: initialRoleName,
      description: initialRoleDescription,
      permissions: initialRolePermissions
    },
    validationSchema: GeneralInfoSchema,
    onSubmit: values => {
      const roleName = values.roleName || undefined;
      const description = values.description || undefined;
      const permissions = values.permissions || undefined;

      const roleForm = {
        id: role ? role.id : undefined,
        name: roleName,
        description,
        permissionsIds: permissions
      };

      if (handleRoleEditorSubmit) {
        handleRoleEditorSubmit(roleForm);
      }
    }
  });

  return (
    <SettingsRoleEditor
      isRoleLoading={isRoleLoading}
      isCompanyPermissionsLoading={isCompanyPermissionsLoading}
      breadcrumbs={breadcrumbs}
      form={generalForm}
      availablePermissions={availablePermissions}
      view={view}
      onViewChange={handleViewChange}
    />
  );
};

export default RoleEditorContainer;
