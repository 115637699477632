import React from "react";
import { Typography } from "@material-ui/core";

const TrackingCardInfo = ({title, value}) => (
  <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
    <Typography varian="subtitle2" style={{ fontWeight: "bold" }} >{title}</Typography>
    <Typography varian="subtitle2">{value}</Typography>
  </span>
);

export default TrackingCardInfo;