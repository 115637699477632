import React, {useState} from "react";
import {dispatchCargoItemPackages} from "../../../resource";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {flatten, intersection, remove, union} from "lodash"
import DispatchIncomingOrdersContainer from "./DispatchIncomingOrdersContainer";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(({palette}) => ({
	dialogContent: {
		display: "flex",
		flexDirection: "column",
	},
	dialogPaper: {
		height: '80vh',
		minWidth: '80vw',
	},
	abort: {
		background: palette.status.danger,
		color: "white",
		"&:hover": {
			background: palette.status.danger,
			color: "white"
		}
	},
}));

export const CargooPackagesContext = React.createContext({})

const DispatchIncomingOrdersDialog = ({
																				mode,
																				open,
																				handleClose,
																				handleSubmit,
																				warehouseId
																			}) => {
	const {t} = useTranslation();
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const [cargoItemPackagesToUpdate, setCargoItemPackagesToUpdate] = useState([]);
	const [warehouseOrders, setWarehouseOrders] = useState([]);

	const handleCargoItemPackagesRequest = async (warehouseOrders) => {
		const data = warehouseOrders
				.map(warehouseOrder => {
					const incomingOrderCargoItemPackages = flatten(warehouseOrder.journey.waypoints[0].cargo.map(cargoItem => cargoItem.packages.map(p => p.packageId)));
					const cargoItemsReceivedOnCurrentWarehouse = cargoItemPackagesToUpdate.filter(p => !!warehouseOrder.warehouseOrder.cargoItemPackages.find(cip => cip.id === p)?.receivedDate)
					return {
						id: warehouseOrder.id,
						cargoItemPackages: intersection(incomingOrderCargoItemPackages, cargoItemsReceivedOnCurrentWarehouse)
					};
				})
				.filter(d => d.cargoItemPackages.length > 0);

		try {
			await dispatchCargoItemPackages({
				warehouseOrders: data,
				warehouseId: warehouseId
			});
			enqueueSnackbar(t("incomingOrders.dispatchIncomingOrders.success.post"), {
				variant: "success",
			});
			handleSubmit();
			handleClose();
			setCargoItemPackagesToUpdate([])
		} catch (err) {
			console.log(err);
			enqueueSnackbar(t("incomingOrders.dispatchIncomingOrders.error.post"), {
				variant: "error",
			});
		}
	};

	const handleSelectCargoItemPackages = (selected, cargoItemPackages, warehouseOrders) => {
		const receivedCargoItemPackages =
				flatten(warehouseOrders.map(warehouseOrder => warehouseOrder.warehouseOrder.cargoItemPackages.filter(cip => cip.receivedDate).map(cip => cip.id)));
		setCargoItemPackagesToUpdate((state) => {
			if (selected) {
				state = union(state, intersection(cargoItemPackages, receivedCargoItemPackages))
			} else {
				remove(state, (n) => cargoItemPackages.includes(n))
			}
			return [...state];
		})
	}

	return (
			<Dialog
					open={open}
					onClose={handleClose}
					disableEnforceFocus
					classes={{paper: classes.dialogPaper}}
			>
				<DialogTitle>
					<>{t("incomingOrders.dispatchFromWarehouse")}</>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<CargooPackagesContext.Provider value={cargoItemPackagesToUpdate}>
						<DispatchIncomingOrdersContainer
								onWarehouseOrdersReload={setWarehouseOrders}
								onSelectCargoItemPackages={handleSelectCargoItemPackages}
								handleReloadWarehouseOrders={(w) => setWarehouseOrders(w)}
								warehouseId={warehouseId}
						/>
					</CargooPackagesContext.Provider>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="contained" className={classes.abort}>
						{t("incomingOrders.cancel")}
					</Button>
					<Button onClick={() => {
						handleCargoItemPackagesRequest(warehouseOrders).then(() => {
						})
					}}
									variant="contained"
									color="primary">
						<>{t("incomingOrders.dispatchFromWarehouse")}</>
					</Button>
				</DialogActions>
			</Dialog>
	);
};

export default DispatchIncomingOrdersDialog;