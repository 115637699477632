import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  makeStyles,
  Chip
} from "@material-ui/core";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { replaceEmptyStringsByNull } from "../../../utility/functional";

import { CompanyProfileSchema } from "../../../utility/validationSchema";
import PlacesAutocompleteTextField from "../../common/Input/PlacesAutocompleteTextField";

import { parsePhoneNumberFromString } from "libphonenumber-js";

const DEFAULT_COUNTRY_CODE = "CZ";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  error: {
    color: "red"
  }
}));

const UpdateCompanyProfileDialog = ({
  open,
  companyProfile: {
    ic,
    dic,
    url,
    companyName,
    email,
    address,
    placeId,
    responsiblePerson,
    bankAccount,
    tags,
    phoneNumber
  },
  handleClose,
  handleSubmit,
  optionTags
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [addressState, setAddressState] = useState({
    title: address,
    placeId
  });
  const [tagValue, setTagValue] = useState([]);

  useEffect(() => {
    setTagValue(tags);
  }, [tags]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("settings.updateCompanyProfile")}</DialogTitle>
      <Formik
        validationSchema={CompanyProfileSchema}
        initialValues={{
          ic, dic, url, companyName, email, address, responsiblePerson, bankAccount, phoneNumber
        }}
        onSubmit={(values, { setSubmitting }) => {
          replaceEmptyStringsByNull(values);
          setSubmitting(false);
          handleSubmit({
            ic: values.ic,
            dic: values.dic,
            url: values.url,
            companyName: values.companyName,
            email: values.email,
            address: values.address,
            placeId: addressState.placeId,
            responsiblePerson: values.responsiblePerson,
            bankAccount: values.bankAccount,
            tags: tagValue,
            phoneNumber: values.phoneNumber
              ? parsePhoneNumberFromString(values.phoneNumber, DEFAULT_COUNTRY_CODE).number
              : undefined
          });
        }}
      >
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          submitForm,
          errors,
          touched,
          values,
          setValues
        }) => (
            <>
              <DialogContent>
                <form className={classes.container}>
                  <div>
                    <TextField
                      name="companyName"
                      label={t("contacts.companyName")}
                      value={values.companyName}
                      error={errors.companyName && touched.companyName}
                      onChange={handleChange}
                      required
                      fullWidth
                      onBlur={handleBlur}
                    />
                    {errors.companyName && touched.companyName && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.companyName)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <TextField
                      name="ic"
                      label="IČ"
                      fullWidth
                      value={values.ic ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.ic && touched.ic}
                    />
                    {errors.ic && touched.ic && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.ic)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <TextField
                      name="dic"
                      required
                      label="DIČ"
                      value={values.dic ?? ""}
                      fullWidth
                      error={errors.dic && touched.dic}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.dic && touched.dic && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.dic)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <TextField
                      name="url"
                      type="url"
                      label="Web"
                      value={values.url ?? ""}
                      error={errors.url && touched.url}
                      helperText={touched.url && t(errors.url)}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <PlacesAutocompleteTextField
                    name="address"
                    label={t("contacts.address")}
                    placeholder={t("contacts.address")}
                    value={values.address ?? ""}
                    suggestions={addressSuggestions}
                    error={touched.address && errors.address}
                    helperText={touched.address && t(errors.address)}
                    onChange={value => setValues({ ...values, address: value })}
                    onBlur={handleBlur}
                    onSuggestionSelect={setAddressState}
                    onSuggestionsChange={setAddressSuggestions}
                    required
                  />
                  <div>
                    <TextField
                      name="email"
                      label="Email"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email ?? ""}
                      error={errors.email && touched.email}
                    />
                    {errors.email && touched.email && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.email)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <TextField
                      name="phoneNumber"
                      label={t("company.phone")}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber ?? ""}
                      error={errors.phoneNumber && touched.phoneNumber}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.phoneNumber)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <TextField
                      name="responsiblePerson"
                      label={t("contacts.responsiblePerson")}
                      onChange={handleChange}
                      fullWidth
                      required
                      onBlur={handleBlur}
                      value={values.responsiblePerson ?? ""}
                      error={errors.responsiblePerson && touched.responsiblePerson}
                    />
                    {errors.responsiblePerson && touched.responsiblePerson && (
                      <Typography variant="subtitle2" className={classes.error}>
                        {t(errors.responsiblePerson)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <Autocomplete
                      multiple
                      id="contact-tags"
                      options={optionTags}
                      value={tagValue}
                      filterSelectedOptions
                      noOptionsText={t("contact.noTag")}
                      // groupBy={(item) => item.type}
                      getOptionSelected={(
                        option,
                        value,
                      ) => value.value === option.value}
                      onChange={(event, newValue) => {
                        event.preventDefault();
                        return setTagValue(newValue);
                      }}
                      getOptionLabel={(option) => (option.geopoliticalType !== null ? `${option.value} / ${option.localizedGeopoliticalType}` : `${option.value} / ${option.localizedType}`)}
                      renderTags={(value, getTagProps) => (
                        value.map(({
                          value
                        }, index) => (
                            <Chip key={index} label={value} {...getTagProps({ index })} />
                          )))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={t("contact.tags")}
                          placeholder="Tag"
                        />
                      )}
                    />
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("abort")}
                </Button>
                <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                  {t("update")}
                </Button>
              </DialogActions>
            </>
          )}
      </Formik>
    </Dialog>
  );
};

export default UpdateCompanyProfileDialog;
