import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import { useGoogleMapsApi } from "../../../../multiload/cargotic-map";
import { suggestPlace } from "../../../cargotic-webapp-google-maps";
import AutosuggestTextField from "../AutosuggestTextField";
import AddressAutosuggestTextFieldPlaceSuggestionIcon
  from "./AddressAutosuggestTextFieldPlaceSuggestionIcon";

const UserAutosuggestTextField = ({ placeType, ...rest }) => {
  const { api: googleMapsApi } = useGoogleMapsApi();
  const [placesAutocompleteService] = useState(
    () => new googleMapsApi.places.AutocompleteService()
  );

  const { t } = useTranslation();

  return (
    <AutosuggestTextField
      {...rest}
      emptySuggestionsLabel={t("webapp:common.title.no-place-found")}
      getSuggestionText={description => description}
      suggest={async search => {
        if (search === "") {
          return [];
        }

        try {
          return await suggestPlace(
            placesAutocompleteService,
            {
              input: search,
              types: placeType ? [placeType] : undefined
            }
          );
        } catch (error) {
          if (error === "ZERO_RESULTS") {
            return [];
          }

          throw error;
        }
      }}
      suggestionsComponent={({ suggestions, onSuggestionClick }) => (
        <List>
          {suggestions.map(suggestion => {
            const { place_id: placeId, types, description } = suggestion;

            const handleClick = event => onSuggestionClick(event, description);

            return (
              <ListItem key={placeId} button onClick={handleClick}>
                <ListItemIcon>
                  <AddressAutosuggestTextFieldPlaceSuggestionIcon
                    placeType={placeType}
                  />
                </ListItemIcon>
                <ListItemText primary={description} />
              </ListItem>
            );
          })}
        </List>
      )}
    />
  );
};

export default UserAutosuggestTextField;
