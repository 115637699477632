import React, { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { createStateSuggestQuery } from "../../../resource";

const StatusesDialog = ({ isOpen, handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();
  const [availableStatuses, setAvailableStatuses] = useState();
  useEffect(() => {
    const fetchStatuses = async () => {
      const statuses = await createStateSuggestQuery({
        resources: ["incoming_order"],
      });
      setAvailableStatuses(statuses);
      setValue(statuses[0].id);
    };
    fetchStatuses();
  }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t("webapp:incomingOrder.setStatuses")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("webapp:incomingOrder.setStatusesSubtitle")}
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          {!availableStatuses ? (
            <CircularProgress />
          ) : (
            <Select
              id="status"
              value={value || availableStatuses[0]}
              onChange={({ target: { value } }) => setValue(value)}
            >
              {availableStatuses.map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("abort")}
        </Button>
        <Button
          onClick={() => handleSubmit(value)}
          color="primary"
          data-cy="set-undelivered-reason-confirm"
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusesDialog;
