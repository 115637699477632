import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    minWidth: 520
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(4)
    }
  },
  progress: {
    marginTop: spacing(2)
  }
}));

const InvoiceDialog = ({
  id,
  title,
  content,
  generateButtonLabel,
  closeButtonLabel,
  handleGenerateShipmentInvoice,
  isOpen,
  handleClose
}) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.root }} open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleGenerateShipmentInvoice(id)}>
          {generateButtonLabel}
        </Button>
        <Button color="primary" onClick={handleClose}>
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDialog;
