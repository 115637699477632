import React from "react";

import AddressAutosuggestTextField from "../AddressAutosuggestTextField";

const FormikAddressAutosuggestTextField = ({ form, name, ...rest }) => {
  const value = form.values[name];
  const error = form.errors[name];
  const isTouched = form.touched[name];

  return (
    <AddressAutosuggestTextField
      {...rest}
      name={name}
      value={value}
      error={isTouched && error !== undefined}
      helperText={isTouched && error !== undefined ? error : " "}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
    />
  );
};

export default FormikAddressAutosuggestTextField;
