import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, Tooltip } from "@material-ui/core";
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon
} from "@material-ui/icons";

const ContactSharingPublicizeButton = ({
  isContactPrivate,
  isDisabled,
  onClick
}) => {
  const { t } = useTranslation();
  const [isMouseOver, setIsMouseOver] = useState(false);

  const Icon = (() => {
    if (
      (isContactPrivate && isMouseOver)
      || (!isContactPrivate && !isMouseOver)
    ) {
      return LockOpenIcon;
    }

    return LockIcon;
  })();

  const handleMouseEnter = () => (
    setIsMouseOver(true)
  );

  const handleMouseLeave = () => (
    setIsMouseOver(false)
  );

  return (
    <Tooltip
      title={
        isContactPrivate
          ? t("webapp:contact.title.make-public")
          : t("webapp:contact.title.make-private")
      }
      disableFocusListener={isDisabled}
      disableHoverListener={isDisabled}
      disableTouchListener={isDisabled}
    >
      <span>
        <IconButton
          size="small"
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Icon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ContactSharingPublicizeButton;
