import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { ChangePasswordSchema } from "../../../utility/validationSchema";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  error: {
    color: "red"
  }
}));

const ChangePasswordDialog = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("settings.changePassword")}</DialogTitle>
      <Formik
        initialValues={{ newPassword: "" }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { newPassword } = values;

          setSubmitting(false);
          handleSubmit(newPassword);
        }}
      >
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          submitForm,
          values,
          errors,
          touched
        }) => (
          <>
            <DialogContent>
              <form className={classes.container}>
                <div>
                  <TextField
                    name="newPassword"
                    label={t("newPassword")}
                    value={values.newPassword}
                    type="password"
                    error={errors.newPassword && touched.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.newPassword && t(errors.newPassword)}
                    required
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("abort")}
              </Button>
              <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                {t("update")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ChangePasswordDialog;
