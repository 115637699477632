import { useSnackbar } from "notistack";
import React, { useEffect, useState, useCallback} from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import { Currency, replaceIndex } from "@cargotic/common";
import { ContactActivityType, TimePeriod } from "@cargotic/model";

import { createSubcontractorEmployee } from "@cargotic/webapp/resource";
import { useDialog } from "@cargotic/webapp-component/hook"
import useRouter from "../../../cargotic-webapp/component/hook/useRouter";
import useAuth from "../../../cargotic-webapp/component/hook/useAuth";
import useUserProfile
  from "../../../cargotic-webapp/component/hook/useUserProfile";

import { useApiClient } from "../../../cargotic-webapp-component";
import ContactProfile from "../ContactProfile";

const ContactProfileContainer = ({
  contactId,
  view,
  onContactsReditect,
  onShipmentRedirect,
  onViewChange
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();
  const profile = useUserProfile();
  const session = useAuth();

  const { history } = useRouter();

  const [activity, setActivity] = useState();
  const [availableTags, setAvailableTags] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [contact, setContact] = useState();
  const [employees, setEmployees] = useState();
  const [orderBalanceHighlight, setOrderBalanceHighlight] = useState({
    isLoading: true
  });

  const [shipmentBalanceHighlight, setShipmentBalanceHighlight] = useState({
    isLoading: true
  });

  const [shipmentsHighlight, setShipmentsHighlight] = useState({
    isLoading: true
  });

  const [shipmentCommissionHighlight, setShipmentCommissionHighlight] = useState({
    isLoading: true
  })

  const [employeeQuery, setEmployeeQuery] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [shares, setShares] = useState();
  const [isActivityLoading, setIsActivityLoading] = useState(true);
  const [isAvailableTagsLoading, setIsAvailableTagsLoading] = useState(true);
  const [isContactLoading, setIsContactLoading] = useState(true);
  const [isContactEditorOpen, setIsContactEditorOpen] = useState(false);
  const [
    isDeleteConfirmationAlertOpen,
    setIsDeleteConfirmationAlertOpen
  ] = useState(false);

  const [isPublicizeAlertOpen, setIsPublicizeAlertOpen] = useState(false);
  const [isEmployeesLoading, setIsEmployeesLoading] = useState(true);
  const [isEmployeeEditorOpen, setIsEmployeeEditorOpen] = useState(false);
  const [
    isSubcontractorEmployeeEditorOpen,
    handleOpenSubcontractorEmployeeEditor,
    handleCloseSubcontractorEmployee
  ] = useDialog()
  const [
    isEmployeeDeleteConfirmationAlertOpen,
    setIsEmployeeDeleteConfirmationAlertOpen
  ] = useState(false);

  const [isHighlightsLoading, setIsHighlightsLoading] = useState(true);
  const [isShareEditorOpen, setIsShareEditorOpen] = useState(false);
  const [isTagEditorOpen, setIsTagEditorOpen] = useState(false);

  const user = {
    id: session.user.id,
    name: `${session.user.firstName} ${session.user.lastName}`,
    avatarUrl: profile.userProfile.avatarUrl
  };

  const employeePage = employeeQuery?.page;
  const employeeRowsPerPage = employeeQuery?.rowsPerPage;

  const handleShipmentRedirect = () => onShipmentRedirect(contact.type);

  const handleAvatarChange = async avatarFile => {
    try {
      const { avatarUrl } = await client.contact.postContactAvatar({
        contactId,
        avatarFile
      });

      setContact(current => ({ ...current, avatarUrl }));
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.avatarUpload"), {
        variant: "error"
      });
    }
  };

  const loadAvailableTags = async () => {
    setIsAvailableTagsLoading(true);

    try {
      const loadedTags = await client.contact.getContactTags();

      setAvailableTags(loadedTags);
      setIsAvailableTagsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.loadTags"), {
        variant: "error"
      });
    }
  };

  const transformDateRange = ([ from, to ]) => ({
    from,
    to
  })

  const loadContact = async () => {
    setIsContactLoading(true);

    try {
      const loadedContact = await client.contact.getContact({ contactId });

      if (loadedContact.isPrivate) {
        const loadedShares = await client.contact.getContactShares({
          contactId
        });

        setShares(loadedShares);
      }

      setContact(loadedContact);
      setIsContactLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.load"), {
        variant: "error"
      });
    }
  };

  const loadEmployees = async () => {
    setIsEmployeesLoading(true);
    try {
      const loaded = await client.contact.postContactEmployeeMatchQuery({
        contactId,
        query: {
          ...employeeQuery,
          limit: employeeRowsPerPage,
          offset: employeePage * employeeRowsPerPage,
          page: undefined,
          rowsPerPage: undefined
        }
      });

      setEmployees(loaded);
      setIsEmployeesLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.load-employees"), {
        variant: "error"
      });
    }
  };

  const loadOrderBalanceHighlight = async () => {
    if (!orderBalanceHighlight.notLoad) {
      setOrderBalanceHighlight({
        ...orderBalanceHighlight,
        isLoading: true
      });
    }

    try {
      const loaded = await client.contact.getContactOrderBalanceHighlight({
        contactId,
        currency: orderBalanceHighlight.currency,
        period: orderBalanceHighlight.period,
        range: orderBalanceHighlight.range ? transformDateRange(orderBalanceHighlight.range) : undefined
      });

      setOrderBalanceHighlight({
        ...orderBalanceHighlight,
        ...loaded,
        isLoading: false,
        notLoad: false
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.load-order-balance-highlight"), {
        variant: "error"
      });
    }
  };

  const loadShipmentCommissionHighlight = async () => {
    if (!shipmentCommissionHighlight.notLoad) {
      setShipmentCommissionHighlight({
        ...shipmentCommissionHighlight,
        isLoading: true
      })
    }

    try {
      const loaded = await client.contact.getContactShipmentCommissionHighlight({
        contactId,
        currency: shipmentCommissionHighlight.currency,
        period: shipmentCommissionHighlight.period,
        range: shipmentCommissionHighlight.range ? transformDateRange(shipmentCommissionHighlight.range) : undefined
      })

      setShipmentCommissionHighlight({
        ...shipmentCommissionHighlight,
        ...loaded,
        isLoading: false,
        notLoad: false
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(
        t("webapp:contact.error.load-shipment-commission-highlight"),
        { variant: "error" }
      );
    }
  }

  const loadShipmentBalanceHighlight = async () => {
    if (!shipmentBalanceHighlight.notLoad){
      setShipmentBalanceHighlight({
        ...shipmentBalanceHighlight,
        isLoading: true
      });
    }

    try {
      const loaded = await client.contact.getContactShipmentBalanceHighlight({
        contactId,
        currency: shipmentBalanceHighlight.currency,
        period: shipmentBalanceHighlight.period,
        range: shipmentBalanceHighlight.range ? transformDateRange(shipmentBalanceHighlight.range) : undefined
      });

      setShipmentBalanceHighlight({
        ...shipmentBalanceHighlight,
        ...loaded,
        isLoading: false,
        notLoad: false
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(
        t("webapp:contact.error.load-shipment-balance-highlight"),
        { variant: "error" }
      );
    }
  };

  const loadShipmentsHighlight = async () => {
    if (!shipmentsHighlight.notLoad) {
      setShipmentsHighlight({
        ...shipmentsHighlight,
        isLoading: true
      });
    }

    try {
      const loaded = await client.contact.getContactShipmentsHighlight({
        contactId,
        currency: shipmentsHighlight.currency,
        period: shipmentsHighlight.period,
        range: shipmentsHighlight.range ? transformDateRange(shipmentsHighlight.range) : undefined
      });

      setShipmentsHighlight({
        ...shipmentsHighlight,
        ...loaded,
        isLoading: false,
        notLoad: false
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(
        t("webapp:contact.error.load-shipments-highlight"),
        { variant: "error" }
      );
    }
  };

  const handleActivityLoad = async () => {
    setIsActivityLoading(true);

    try {
      const loaded = await client.contact.getContactActivity({ contactId });

      setActivity(loaded);
      setIsActivityLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.load-activity"), {
        variant: "error"
      });
    }
  };

  const handleEdit = () => setIsContactEditorOpen(true);

  const handleContactEditorClose = () => setIsContactEditorOpen(false);

  const handleContactEditorSubmit = async value => {
    setIsContactEditorOpen(false);

    try {
      const newContact = await client.contact.putContact({
        contactId,
        contact: {
          ...value,
          tags: contact.tags
        }
      });

      setContact(newContact);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.update"), {
        variant: "error"
      });
    }

    if (activity) {
      setActivity(current => [
        ...current,
        {
          type: ContactActivityType.CONTACT_UPDATE,
          user,
          properties: {},
          createdAt: new Date()
        }
      ]);
    }
  };

  const handleCommentaryChange = async (commentaryId, content) => {
    try {
      const { createdAt } = await client.contact.putContactCommentary({
        contactId,
        commentaryId,
        commentary: {
          content
        }
      });

      setActivity(current => {
        const index = current
          .findIndex((other) => other.commentary?.id === commentaryId);

        const updated = replaceIndex(current, index, {
          type: ContactActivityType.CONTACT_COMMENTARY,
          commentary: {
            content,
            createdAt,
            id: commentaryId,
            author: user
          }
        });

        return updated;
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.update-commentary"), {
        variant: "error"
      });
    }
  };

  const handleCommentaryDelete = async commentaryId => {
    try {
      await client.contact.deleteContactCommentary({
        contactId,
        commentaryId
      });

      setActivity(current => (
        current.filter((other) => other.commentary?.id !== commentaryId)
      ));
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.delete-commentary"), {
        variant: "error"
      });
    }
  };

  const handleCommentarySubmit = async content => {
    try {
      const { id, createdAt } = await client.contact.postContactCommentary({
        contactId,
        commentary: { content }
      });

      setActivity(current => ([
        ...current,
        {
          type: ContactActivityType.CONTACT_COMMENTARY,
          commentary: {
            id,
            content,
            createdAt,
            author: user
          }
        }
      ]));
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.post-commentary"), {
        variant: "error"
      });
    }
  };

  const handleDelete = () => setIsDeleteConfirmationAlertOpen(true);

  const handleDeleteConfirmationAlertClose = () => (
    setIsDeleteConfirmationAlertOpen(false)
  );

  const handleDeleteConfirmationAlertSubmit = async () => {
    setIsDeleteConfirmationAlertOpen(false);

    try {
      await client.contact.deleteContact({ contactId });

      onContactsReditect();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.delete"), {
        variant: "error"
      });
    }
  };

  const handleEmployeeAdd = () => {
    setSelectedEmployee({});
    setIsEmployeeEditorOpen(true);
  };

  const handleSubcontractorEmployeeAdd = () => {
    setSelectedEmployee({});
    handleOpenSubcontractorEmployeeEditor();
  };

  const handleEmployeeDeleteConfirmationAlertClose = () => (
    setIsEmployeeDeleteConfirmationAlertOpen(false)
  );

  const handleEmployeeDeleteConfirmationAlertSubmit = async () => {
    setIsEmployeeDeleteConfirmationAlertOpen(false);

    const { id: employeeId } = selectedEmployee;

    try {
      await client.contact.deleteContactEmployee({ contactId, employeeId });

      setEmployeeQuery({ ...employeeQuery, page: 0 });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.delete-employee"), {
        variant: "error"
      });
    }
  };

  const handleEmployeeDelete = index => {
    setSelectedEmployee(employees.matches[index]);
    setIsEmployeeDeleteConfirmationAlertOpen(true);
  };

  const handleEmployeeEdit = index => {
    setSelectedEmployee(employees.matches[index]);
    setIsEmployeeEditorOpen(true);
  };

  const handleEmployeeEditorClose = () => setIsEmployeeEditorOpen(false);

  const handleEmployeeEditorSubmit = async ({ id: employeeId, ...employee }) => {
    setIsEmployeeEditorOpen(false);

    try {
      if (employeeId) {
        await client.contact.putContactEmployee({
          contactId,
          employeeId,
          employee
        });
      } else {
        await client.contact.postContactEmployee({ contactId, employee });
      }

      await setEmployeeQuery({ ...employeeQuery, page: 0 });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.employee-update"), {
        variant: "error"
      });
    }
  };

  const handleEmployeeQueryChange = value => setEmployeeQuery(value);

  const handleBreadcrumbsChange = value => setBreadcrumbs(value);

  const handleOrderBalanceHighlightChange = value => (
    setOrderBalanceHighlight(value)
  );

  const handleShipmentCommissionHighlightChange = value => (
    setShipmentCommissionHighlight(value)
  );

  const handlePublicization = () => setIsPublicizeAlertOpen(true);

  const handlePublicizeConfirmationAlertClose = () => (
    setIsPublicizeAlertOpen(false)
  );

  const handlePublicizeConfirmationAlertSubmit = async () => {
    setIsPublicizeAlertOpen(false);

    try {
      const updated = {
        ...contact,
        isPrivate: !contact.isPrivate
      };

      await client.contact.putContact({
        contactId,
        contact: updated
      });

      if (contact.isPrivate) {
        setContact(updated);
        setShares(undefined);
      } else {
        setShares([]);
        setContact(updated);
      }
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.publicize"), {
        variant: "error"
      });
    }

    if (activity) {
      setActivity(current => [
        ...current,
        {
          type: ContactActivityType.CONTACT_UPDATE,
          user,
          properties: {},
          createdAt: new Date()
        }
      ]);
    }
  };

  const handleShareEditorClose = () => setIsShareEditorOpen(false);

  const handleShareEditorOpen = () => setIsShareEditorOpen(true);

  const handleShareEditorSubmit = async value => {
    setIsShareEditorOpen(false);

    try {
      await client.contact.postContactShares({
        contactId,
        shares: value.map(({ id }) => ({ id }))
      });

      setShares(value);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.share"), {
        variant: "error"
      });
    }
  };

  const handleShipmentBalanceHighlightChange = value => (
    setShipmentBalanceHighlight(value)
  );

  const handleShipmentsHighlightChange = value => setShipmentsHighlight(value);

  const handleTagEdit = () => setIsTagEditorOpen(true);

  const handleTagEditorClose = () => setIsTagEditorOpen(false);

  const handleTagEditorSubmit = async tags => {
    setIsTagEditorOpen(false);

    try {
      const result = await client.contact.putContact({
        contactId,
        contact: {
          ...contact,
          tags
        }
      });

      setContact(result);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.tagUpdate"), {
        variant: "error"
      });
    }
  };

  const handleShipmentsHeaderIconClick = () => {
    const queryParameters = {};

    queryParameters.filterContactId = contactId;
    const query = queryString.stringify(queryParameters);
    history.push(`/shipments?${query}`);
  }

  const handleSubcontractorEmployeeSubmit = useCallback((id, values) => {
    const makeRequest = async () => {
      try {
        await createSubcontractorEmployee(id, values);
        enqueueSnackbar(t("webapp:contact.user.created"), {
          variant: "success"
        });
        handleCloseSubcontractorEmployee();
        loadEmployees();
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t("webapp:contact.error.employeeUpdate"), {
          variant: "error"
        });
      }
    }

    makeRequest();
  }, [createSubcontractorEmployee, loadEmployees])

  useEffect(() => {
    loadAvailableTags();
    loadContact();
  }, []);

  useEffect(() => {
    if (employeeQuery === undefined) {
      return;
    }

    loadEmployees();
  }, [employeeQuery]);

  useEffect(() => {
    if (
      orderBalanceHighlight.currency === undefined
      || orderBalanceHighlight.period === undefined
    ) {
      return;
    }

    loadOrderBalanceHighlight();
  }, [orderBalanceHighlight.currency, orderBalanceHighlight.period, orderBalanceHighlight.range]);

  useEffect(() => {
    if (
      shipmentCommissionHighlight.currency === undefined
      || shipmentCommissionHighlight.period === undefined
    ) {
      return;
    }

    loadShipmentCommissionHighlight();
  }, [shipmentCommissionHighlight.currency, shipmentCommissionHighlight.period, shipmentCommissionHighlight.range]);

  useEffect(() => {
    if (
      shipmentBalanceHighlight.currency === undefined
      || shipmentBalanceHighlight.period === undefined
    ) {
      return;
    }

    loadShipmentBalanceHighlight();
  }, [shipmentBalanceHighlight.currency, shipmentBalanceHighlight.period, shipmentBalanceHighlight.range]);

  useEffect(() => {
    if (shipmentsHighlight.period === undefined) {
      return;
    }

    loadShipmentsHighlight();
  }, [shipmentsHighlight.period, shipmentsHighlight.range]);

  return (
    <ContactProfile
      activity={activity}
      availableTags={availableTags}
      breadcrumbs={breadcrumbs}
      contact={contact}
      employeeQuery={employeeQuery}
      employees={employees}
      orderBalanceHighlight={orderBalanceHighlight}
      shipmentCommissionHighlight={shipmentCommissionHighlight}
      selectedEmployee={selectedEmployee}
      shipmentBalanceHighlight={shipmentBalanceHighlight}
      shipmentsHighlight={shipmentsHighlight}
      shares={shares}
      user={user}
      view={view}
      isActivityLoading={isActivityLoading}
      isAvailableTagsLoading={isAvailableTagsLoading}
      isContactLoading={isContactLoading}
      isContactEditorOpen={isContactEditorOpen}
      isDeleteConfirmationAlertOpen={isDeleteConfirmationAlertOpen}
      isEmployeeDeleteConfirmationAlertOpen={
        isEmployeeDeleteConfirmationAlertOpen
      }
      isEmployeeEditorOpen={isEmployeeEditorOpen}
      isSubcontractorEmployeeEditorOpen={isSubcontractorEmployeeEditorOpen}
      isEmployeesLoading={isEmployeesLoading}
      isHighlightsLoading={isHighlightsLoading}
      isPublicizeConfirmationAlertOpen={isPublicizeAlertOpen}
      isShareEditorOpen={isShareEditorOpen}
      isTagEditorOpen={isTagEditorOpen}
      onActivityLoad={handleActivityLoad}
      onAvatarChange={handleAvatarChange}
      onBreadcrumbsChange={handleBreadcrumbsChange}
      onContactEditorClose={handleContactEditorClose}
      onContactEditorSubmit={handleContactEditorSubmit}
      onCommentaryChange={handleCommentaryChange}
      onCommentaryDelete={handleCommentaryDelete}
      onCommentarySubmit={handleCommentarySubmit}
      onDelete={handleDelete}
      onDeleteConfirmationAlertClose={handleDeleteConfirmationAlertClose}
      onDeleteConfirmationAlertSubmit={handleDeleteConfirmationAlertSubmit}
      onEdit={handleEdit}
      onEmployeeAdd={handleEmployeeAdd}
      onSubcontractorEmployeeSubmit={handleSubcontractorEmployeeSubmit}
      onSubcontractorEmployeeAdd={handleSubcontractorEmployeeAdd}
      onSubcontractorEmployeeClose={handleCloseSubcontractorEmployee}
      onEmployeeDelete={handleEmployeeDelete}
      onEmployeeDeleteConfirmationAlertClose={
        handleEmployeeDeleteConfirmationAlertClose
      }
      onEmployeeDeleteConfirmationAlertSubmit={
        handleEmployeeDeleteConfirmationAlertSubmit
      }
      onEmployeeEdit={handleEmployeeEdit}
      onEmployeeEditorClose={handleEmployeeEditorClose}
      onEmployeeEditorSubmit={handleEmployeeEditorSubmit}
      onEmployeeQueryChange={handleEmployeeQueryChange}
      onPublicization={handlePublicization}
      onOrderBalanceHighlightChange={handleOrderBalanceHighlightChange}
      onPublicizeConfirmationAlertClose={handlePublicizeConfirmationAlertClose}
      onPublicizeConfirmationAlertSubmit={
        handlePublicizeConfirmationAlertSubmit
      }
      onShareEditorClose={handleShareEditorClose}
      onShareEditorOpen={handleShareEditorOpen}
      onShareEditorSubmit={handleShareEditorSubmit}
      onShipmentBalanceHighlightChange={handleShipmentBalanceHighlightChange}
      onShipmentCommissionHighlightChange={handleShipmentCommissionHighlightChange}
      onShipmentRedirect={handleShipmentRedirect}
      onShipmentsHighlightChange={handleShipmentsHighlightChange}
      onTagEdit={handleTagEdit}
      onTagEditorClose={handleTagEditorClose}
      onTagEditorSubmit={handleTagEditorSubmit}
      onViewChange={onViewChange}
      onShipmentsHeaderIconClick={handleShipmentsHeaderIconClick}
    />
  );
};

export default ContactProfileContainer;
