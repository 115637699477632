import React from "react";
import clsx from "clsx";

import {
  Card, CardContent, CardActionArea, makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  selected: {
    borderColor: "blue",
    border: 5,
    height: "100%"
  },
  CardContent: {
    padding: 10
  },
  disabled: {
    background: "silver"
  }
}));

const ClickableCard = ({ children, click, chosen = false, cardStyle }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.selected, cardStyle)}>
      <CardActionArea onClick={click} className={classes.selected} disabled={chosen}>
        <CardContent className={clsx(classes.CardContent, classes.selected)}>
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ClickableCard;
