import React, { useState } from "react";
import {
  Grid, Button, Typography, CardActions, CardHeader, CardContent, Card, makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import differenceInDays from "date-fns/differenceInDays";
import { useSnackbar } from "notistack";

import { formatDate } from "../../../utility/common";
// import SetTariffDialog from "./SetTariffDialog";
import useAuth from "../../hook/useAuth";


const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  card: {
    padding: 4
  },
  cardActions: {
    [breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center",
      "& > :not(:last-child)": {
        marginBottom: spacing(1)
      }
    },
    [breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
}));


const SubscriptionCard = ({ hasUpdateCompanyProfilePermission, subscriptionInfo }) => {
  const { t } = useTranslation();
  const [configureTariff, setConfigureTariff] = useState(false);
  const { user: { subscription } } = useAuth();
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={t("webapp:settings.title.subscription")} />
        <CardContent>
          <Grid container spacing={1}>
            <Grid container item>
              <Grid item xs>
                <Typography variant="subtitle2">{t("settings.expirationDate")}</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2">
                  {`${formatDate(new Date(subscriptionInfo.expiresAt))} / ${t("remainsDays", { days: differenceInDays(new Date(subscriptionInfo.expiresAt), new Date()) })}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs>
                <Typography variant="subtitle2">{t("webapp:settings.title.module")}</Typography>
              </Grid>
              <Grid item xs>
              <Typography variant="subtitle2">
                {t("webapp:settings.title.basic-module")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SubscriptionCard;
