import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  CircularProgress,
  Grid,
  Typography,
  Select,
  Tooltip,
  TextField,
  FormControlLabel,
  Fab,
  ButtonBase,
  Slider
} from "@material-ui/core";
import { ShipmentType, ShipmentState, ContactType } from "@cargotic/model-deprecated";

import { red, blue } from "@material-ui/core/colors";
import {
  Add,
  Warning,
  Error,
  Timelapse,
  ChevronLeft,
  ChevronRight
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { red, blue } from "@material-ui/core/colors";
import clsx from "clsx";
import { startOfWeek, endOfWeek } from "date-fns";
import { cs, enUS } from "date-fns/locale";

import Placeholder from "../../common/Placeholder";
import { formatDateWithoutYear } from "../../../utility/common";
import Page from "../../common/Page";
import SearchTextfield from "../../common/SearchTextfield";
import useRouter from "../../hook/useRouter";
import PrimarySwitch from "../../common/PrimarySwitch";
import WeekPicker from "../../common/WeekPicker";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterTextDropdown from "../../../../cargotic-webapp-filter/component/FilterTextDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import VehiclesFilterDropdown from "../../../../cargotic-webapp-filter/component/VehiclesFilterDropdown";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";

import FilterWeekPicker from "../../../../cargotic-webapp-filter/component/FilterWeekPicker";

import {
  storeHiddenColumns,
  loadHiddenColumns,
  storeBoardCardSize,
  loadBoardCardSize
} from "../../../storage";
import BoardList from "./BoardList";
import useAuth from "../../hook/useAuth";
import client from "../../../client";

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    [breakpoints.down("xs")]: {
      paddingTop: spacing(3),
      paddingRight: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2)
    },
    [breakpoints.up("sm")]: {
      paddingTop: spacing(3),
      paddingRight: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2)
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  rootGrid: {
    height: "100%",
    flexWrap: "noWrap",
    marginBottom: 15
  },
  headerTitle: {
    fontWeight: 800,
    fontSize: 16
  },
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  boardColumn: {
    minWidth: 300
  },
  boarListHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 5,
    marginRight: 10,
    marginBottom: 7,
    alignItems: "center"
  },
  flexRowAround: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  actions: {
    position: "fixed",
    bottom: spacing(3),
    right: spacing(3),
    "& > :not(:last-child)": {
      marginRight: spacing(1)
    }
  },
  hiddenColumn: {
    cursor: "pointer",
    marginTop: 10,
    marginLeft: 8,
    marginRight: 4
  },
  slider: {
    width: "40%",
    maxWidth: 200,
    display: "flex",
    flexDirection: "column",
    marginLeft: 30,
    marginRight: 20
  },
  spinner: {
    position: "absolute",
    left: "calc(50% - 30px)",
    top: "calc(50% - 30px)"
  }
}));

const ShipmentBoard = ({
  shipments,
  loading,
  loadingDetails,
  search,
  loadAdditionalShipments,
  onClick,
  hasCreateShipmentPermission,
  hasUpdateShipmentPermission,
  hasDeleteShipmentPermission,
  hasCompanyReadPermission,
  openDeleteDialog,
  handleSearch,
  handleChangeState,
  handleChangePeriod,
  totalShipmentWarnings,
  hasShipmentError,
  filter,
  handleFilterSettingsOpen,
  defaultFilters,
  handleDeselect,

  loadAvailableContacts,
  loadAvailableUsers,
  loadAvailableStates,

  handleSelectShipmentType,
  handleSelectDrivers,
  handleSelectCargoTemplate,
  handleSelectCreators,
  handleSelectCarriers,
  handleSelectCustomers,
  handleSelectCommission,
  handleSelectCarrierPrice,
  handleSelectCustomerPrice,
  handleSelectPayment,
  handleSelectCargoType,
  handleSelectCreatedAt,
  handleSelectLoadingDate,
  handleSelectUnloadingDate,
  handleSelectIsDraft,
  handleSelectVehicles,
  loadAvailableVehicles,
  handleSelectIssuedPaymentState,
  handleSelectReceivedPaymentState,
  updateShipmentsSameState,
  handleSelectCurrentStatusState,
  state
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const pickerLocale = i18n.language === "cs" || i18n.language === "cs-CZ"
    ? cs
    : enUS;

  const { history } = useRouter();
  
  const stateLabels = {}
  const states = state.map(e => e.name)
  state.forEach(e => (stateLabels[e.name] = e.name))

  const queueCardCount = shipments.QUEUE ? shipments.QUEUE.length : 0;
  const readyCardsCount = shipments.READY ? shipments.READY.length : 0;
  const inProgressCardsCount = shipments.IN_PROGRESS ? shipments.IN_PROGRESS.length : 0;
  const doneCardsCount = shipments.DONE ? shipments.DONE.length : 0;

  const [hiddenColumns, setHiddenColumns] = useState(JSON.parse(loadHiddenColumns()));
  const [cardSize, setCardSize] = useState(loadBoardCardSize());
  const [columnFlexBasis, setColumnFlexBasis] = useState(100 / states.length);

  useEffect(() => {
    setColumnFlexBasis(100 / (states.length - hiddenColumns.length));
    storeHiddenColumns(JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  const findLongestState = () =>
    states.reduce((acc, state) => (
      (shipments[state].total > shipments[acc].total && !hiddenColumns.includes(state))
        ? state
        : acc
    ), states[0]);

  const isAnyFilterActive = () => {
    const { types, loadingDate, createdAt, carrierPrice, customerPrice, commission, carriers, drivers, customers, creators, cargo } = filter;
    return [types, loadingDate, createdAt, carrierPrice, customerPrice, commission, carriers, drivers, customers, creators, cargo].some(item => item !== undefined);
  };

  const shipmentTypeSource = [
    { title: t("statistics.forwarded"), value: ShipmentType.FORWARDED },
    { title: t("statistics.carried"), value: ShipmentType.CARRIED }
  ];

  const paymentSource = [
    { title: t("shipments.non-issued"), value: "NON_ISSUED" },
    { title: t("shipments.issued"), value: "ISSUED" },
    { title: t("shipments.paid"), value: "PAID" },
    { title: t("shipments.overdue-title"), value: "OVERDUE" }
  ];

  const draftSource = [{ title: t("shipments.draft"), value: "DRAFT" }, { title: t("shipments.completed"), value: "COMPLETED" }];

  const computeStateFlexBasis = (state) => {
    switch (hiddenColumns.length) {
      case 0:
        return 25;
      case 1:
        return findLongestState() === state ? 50 : 25;
      case 2:
        return 50;
      case 3:
        return 100;
    }
    return 0;
  };

  const boardSizes = [
    {
      value: 0,
      label: "S"
    }, {
      value: 1,
      label: "M"
    }, {
      value: 2,
      label: "L"
    }, {
      value: 3,
      label: "XL"
    }
  ];

  const setDraft = (value) => {
    if (value.includes("DRAFT") && value.includes("COMPLETED")) {
      handleSelectIsDraft(null);
    } else if (value.includes("COMPLETED")) {
      handleSelectIsDraft(false);
    } else if (value.includes("DRAFT")) {
      handleSelectIsDraft(true);
    } else {
      handleSelectIsDraft(undefined);
    }
  };

  const updateShipmentDriveThrough = async (shipmentId, waypointId, state) => {
    let newShipment = shipments[state].matches.find(({ id }) => id === shipmentId);
    const newWaypoints = newShipment.journey.waypoints.map((waypoint) =>
      waypoint.id === waypointId
        ? {
          ...waypoint,
          isDrivenThrough: true,
          drivenThroughAt: new Date(),
          hasError: false,
          hasWarning: false
        }
        : waypoint);
    newShipment.journey.waypoints = newWaypoints;

    const data = await client.shipment.postShipmentWaypointDriveThrough({
      shipmentId,
      waypointId
    });

    updateShipmentsSameState({
      destination: {
        state: state,
        total: shipments[state].total,
        errors: shipments[state].totalErrors,
        warnings: shipments[state].totalWarnings,
        matches: shipments[state].matches
      }
    });
  };

  const getRoundStateActions = (actions) => {
    const total = actions < 10
      ? actions
      : actions - actions % 10;
    return `${total}+`;
  }

  const getBoardStateWarnings = (state) => {
    const totalWarnings = state?.totalWarnings
    return state?.matches?.length === state?.total
      ? totalWarnings
      : getRoundStateActions(totalWarnings)
  }

  const getBoardStateErrors = (state) => {
    const totalErrors = state?.totalErrors;
    return state?.matches?.length === state?.total
      ? totalErrors
      : getRoundStateActions(totalErrors)
  }

  return (
    <>
      <div className={classes.root}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }} >
          <Typography variant="h5" style={{ fontSize: 20, fontWeight: "bold" }}>{t("menu.board")}</Typography>
          <div className={classes.slider} >
            <Slider
              marks={boardSizes}
              defaultValue={cardSize}
              step={1}
              min={0}
              max={3}
              onChangeCommitted={(_, value) => {
                storeBoardCardSize(value);
                setCardSize(value);
              }}
            />
          </div>
        </div>
        <FilterContainer
          searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("shipments.searchTooltip")} />}
          showClearButton={isAnyFilterActive}
          handleFilterSettingsOpen={handleFilterSettingsOpen}
          defaultFilters={defaultFilters}
          loading={loading}
          filters={[
            <FilterStaticDropdown
              id="types"
              key="types"
              placeholderTitle={t("statistics.shipmentType")}
              value={filter.types ?? []}
              onChange={(arr) => handleSelectShipmentType(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectShipmentType(shipmentTypeSource.map(item => item.value))}
              onClear={() => handleSelectShipmentType(undefined)}
              source={shipmentTypeSource}
            />,
            <FilterDateRangeDropdown
              id="loadingDate"
              key="loadingDate"
              onChange={(value) => handleSelectLoadingDate(value)}
              value={filter.loadingDate ? [filter.loadingDate.from, filter.loadingDate.to] : []}
              onClear={() => handleSelectLoadingDate(undefined)}
              placeholderTitle={t("shipments.loadingsDateRange")}
            />,
            <FilterDateRangeDropdown
              id="unloadingDate"
              key="unloadingDate"
              onChange={(value) => handleSelectUnloadingDate(value)}
              value={filter.unloadingDate ? [filter.unloadingDate.from, filter.unloadingDate.to] : []}
              onClear={() => handleSelectUnloadingDate(undefined)}
              placeholderTitle={t("shipments.unloadingsDateRange")}
            />,
            <UserFilterDropdown
              id="creators"
              key="creators"
              onChange={(value) => handleSelectCreators(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectCreators(all)}
              value={filter.creators ?? []}
              placeholderTitle={t("shipments.creator")}
              search={(text) => loadAvailableUsers(text)}
              onClear={() => handleSelectCreators(undefined)}
              getTitle={(item) => item.name}
              disabled={!hasCompanyReadPermission}
            />,
            <FilterDateRangeDropdown
              id="createdAt"
              key="createdAt"
              onChange={(value) => handleSelectCreatedAt(value)}
              value={filter.createdAt ? [filter.createdAt.from, filter.createdAt.to] : []}
              onClear={() => handleSelectCreatedAt(undefined)}
              placeholderTitle={t("shipments.creationDate")}
            />,
            <ContactFilterDropdown
              id="customers"
              key="customers"
              onChange={(value) => handleSelectCustomers(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectCustomers(all)}
              value={filter.customers ?? []}
              search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
              placeholderTitle={t("contacts.customer")}
              onClear={() => handleSelectCustomers(undefined)}
              getTitle={(item) => item.name}
            />,
            <ContactFilterDropdown
              id="carriers"
              key="carriers"
              onChange={(value) => handleSelectCarriers(value.length === 0 ? undefined : value)}
              value={filter.carriers ?? []}
              selectAll={(all) => handleSelectCarriers(all)}
              placeholderTitle={t("contacts.carrier")}
              onClear={() => handleSelectCarriers(undefined)}
              search={(text) => loadAvailableContacts(ContactType.CARRIER, text)}
              getTitle={(item) => item.name}
            />,
            <UserFilterDropdown
              id="drivers"
              key="drivers"
              onChange={(value) => handleSelectDrivers(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectDrivers(all)}
              value={filter.drivers ?? []}
              placeholderTitle={t("shipments.driver")}
              search={(text) => loadAvailableUsers(text, ["resource.user.role.permission.driver"])}
              onClear={() => handleSelectDrivers(undefined)}
              getTitle={(item) => item.name}
            />,
            <FilterNumberRange
              id="customerPrice"
              key="customerPrice"
              onChange={(value) => handleSelectCustomerPrice(value === "" ? undefined : value)}
              value={filter.customerPrice}
              placeholderTitle={t("shipments.customerPrice")}
              onClear={() => handleSelectCustomerPrice(undefined)}
            />,
            <FilterNumberRange
              id="carrierPrice"
              key="carrierPrice"
              onChange={(value) => handleSelectCarrierPrice(value === "" ? undefined : value)}
              value={filter.carrierPrice}
              placeholderTitle={t("shipments.carrierPrice")}
              onClear={() => handleSelectCarrierPrice(undefined)}
            />,
            <FilterNumberRange
              id="commission"
              key="commission"
              onChange={(value) => handleSelectCommission(value === "" ? undefined : value)}
              value={filter.commission}
              placeholderTitle={t("shipments.commission")}
              onClear={() => handleSelectCommission(undefined)}
            />,
            <FilterStaticDropdown
              id="cargo"
              key="cargo"
              placeholderTitle={t("shipments.cargo")}
              value={filter.cargo ?? []}
              onChange={(arr) => handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectCargoTemplate(shipmentTypeSource.map(item => item.value))}
              onClear={() => handleSelectCargoTemplate(undefined)}
              source={AvailableCargoItemTemplates.map(({ label, value }) => ({ title: t(label), value }))}
            />,
            <FilterStaticDropdown
              id="isDraft"
              key="isDraft"
              placeholderTitle={t("shipments.draft")}
              value={filter.isDraft === undefined ? [] : (filter.isDraft === null ? ["DRAFT", "COMPLETED"] : (filter.isDraft === true ? ["DRAFT"] : ["COMPLETED"]))}
              onChange={(value) => setDraft(value)}
              selectAll={() => setDraft(draftSource.map(item => item.value))}
              onClear={() => handleSelectIsDraft(undefined)}
              source={draftSource}
            />,
            <VehiclesFilterDropdown
              id="vehicles"
              key="vehicles"
              onChange={(value) => handleSelectVehicles(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectVehicles(all)}
              value={filter.vehicles ?? []}
              placeholderTitle={t("shipments.vehicles")}
              search={(text) => loadAvailableVehicles(text)}
              onClear={() => handleSelectVehicles(undefined)}
            />,
            <FilterStaticDropdown
              id="issuedPaymentState"
              key="issuedPaymentState"
              placeholderTitle={t("shipments.issuedPaymentState")}
              value={filter.issuedPaymentState ?? []}
              onChange={(arr) => handleSelectIssuedPaymentState(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectIssuedPaymentState(paymentSource.map(item => item.value))}
              onClear={() => handleSelectIssuedPaymentState(undefined)}
              source={paymentSource}
            />,
            <FilterStaticDropdown
              id="receivedPaymentState"
              key="receivedPaymentState"
              placeholderTitle={t("shipments.receivedPaymentState")}
              value={filter.receivedPaymentState ?? []}
              onChange={(arr) => handleSelectReceivedPaymentState(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectReceivedPaymentState(paymentSource.map(item => item.value))}
              onClear={() => handleSelectReceivedPaymentState(undefined)}
              source={paymentSource}
            />,
            <StateFilterDropdown
            id="currentStatusState"
            key="currentStatusState"
            placeholderTitle={t("board.state.title")}
            value={filter.currentStatusState ?? []}
            onChange={(arr) => handleSelectCurrentStatusState(arr.length === 0 ? undefined : arr)}
            selectAll={(all) => handleSelectCurrentStatusState(all)}
            onClear={() => handleSelectCurrentStatusState(undefined)}
            search={(text) => loadAvailableStates(text)}
            getTitle={(item) => item.name}
           />
          ]}
          onClear={handleDeselect}
        />
        <>
          <Placeholder
            loading={loading}
            render={() => (
              <Grid container direction="row" className={classes.rootGrid} >
                {states.map((state) => {
                  const flexBasis = computeStateFlexBasis(state);
                  return (
                    !hiddenColumns.includes(state) ?
                      <>
                        <div key={state} className={classes.boardColumn} style={{
                          flexBasis: `${flexBasis}%`,
                          maxWidth: `${flexBasis}%`,
                          paddingLeft: 5,
                          paddingRight: 3 + (flexBasis / 5),
                          borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                        }}>
                          <Grid item xs style={{ minWidth: 300 }}>
                            <div className={classes.boarListHeader}>
                              <ButtonBase onClick={() => setHiddenColumns([...hiddenColumns, state])} >
                                <span style={{ display: "flex" }}>
                                  <ChevronLeft />
                                  <Typography className={classes.headerTitle}>
                                    {stateLabels[state]} ({shipments[state] ? shipments[state].total : 0})
                                  </Typography>
                                </span>
                              </ButtonBase>
                              <span className={classes.flexRowAround}>
                                {shipments[state]?.totalWarnings ?
                                  <span className={classes.flexRowAround} style={{ marginRight: 15 }} >
                                    <Typography variant="subtitle1" style={{ marginRight: 5 }} >
                                      {shipments[state] ? getBoardStateWarnings(shipments[state]) : 0}
                                    </Typography>
                                    <Timelapse style={{ color: blue[600] }} />
                                  </span>
                                  : null}
                                {shipments[state]?.totalErrors ?
                                  <span className={classes.flexRowAround} style={{ marginRight: 15 }} >
                                    <Typography variant="subtitle1" style={{ marginRight: 5 }} >
                                      {shipments[state] ? getBoardStateErrors(shipments[state]) : 0}
                                    </Typography>
                                    <Error style={{ color: red[700] }} />
                                  </span>
                                  : null}
                              </span>
                            </div>
                          </Grid>
                          <BoardList
                            key={`boardList${state}`}
                            shipments={shipments[state].matches}
                            totalShipments={shipments[state].total}
                            loadAdditionalShipments={loadAdditionalShipments}
                            updateShipmentDriveThrough={(...args) => updateShipmentDriveThrough(...args, state)}
                            totalColumns={computeStateFlexBasis(state) / 25}
                            state={state}
                            cardSize={cardSize}
                            onClick={onClick}
                            handleChangeState={handleChangeState}
                            totalShipmentWarnings={totalShipmentWarnings}
                            hasShipmentError={hasShipmentError}
                            hasCreateShipmentPermission={hasCreateShipmentPermission}
                            hasUpdateShipmentPermission={hasUpdateShipmentPermission}
                            hasDeleteShipmentPermission={hasDeleteShipmentPermission}
                            openDeleteDialog={(id) => openDeleteDialog({ id, state })}
                          />
                        </div>
                      </>
                      :
                      <div
                        onClick={() => setHiddenColumns(hiddenColumns.filter((hiddenState) => hiddenState !== state))}
                        style={{ cursor: "pointer", marginLeft: 8, marginRight: 4 }}>
                        <span style={{ display: "flex" }}>
                          <ChevronRight />
                        </span>
                        <div style={{ display: "flex", writingMode: "vertical-rl", marginTop: "10px" }}>
                          <Typography className={classes.headerTitle}>
                            {stateLabels[state]}
                          </Typography>
                          <Typography className={classes.headerTitle}>
                            ({shipments[state] ? shipments[state].total : 0})
                          </Typography>
                        </div>
                      </div>
                  )
                }
                )}
              </Grid>
            )}
          />
          {loadingDetails ? (
            <CircularProgress
              className={classes.spinner}
              size={60}
            />
          ) : null}
          <div className={classes.actions}>
            <Tooltip title={t("newShipment")}>
              <span>
                <Fab
                  color="primary"
                  disabled={loading || !hasCreateShipmentPermission}
                  onClick={() => history.push("/shipment")}
                >
                  <Add />
                </Fab>
              </span>
            </Tooltip>
          </div>
        </>
      </div>
    </>
  );
};

export default ShipmentBoard;
