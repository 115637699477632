import React, { useCallback, useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { Typography, Chip, Button, Avatar, Tooltip, makeStyles } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { getContactSharingUsers, setContactSharingUsers } from "@cargotic/api-client";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import {
  loadMatchFilter,
  storeMatchFilter,
  storeSearchText,
  loadSearchText,
  storeFilters,
  loadFilters
} from "../../../storage";

import Contacts from "./Contacts";
import ContactsDeleteDialog from "./ContactsDeleteDialog";
import ContactsSharingDialog from "./ContactsSharingDialog";
import SwipeableDialog from "../../../swipeableDialog";
import PlaceSearchFailDialog from "../../common/PlaceSearchFailDialog";
import AvatarGroup from "../../../avatarGroup";
import { useApiClient } from "../../../../cargotic-webapp-component";
import ContactEditor from "../../../../cargotic-webapp-contact/component/ContactEditor";

import UserAvatar from "../../../../cargotic-webapp-component/component/UserAvatar";

import {
  createContact,
  deleteContact,
  updateContact,
  createContactsQuery,
  readAvailableTags,
  updateEmployee,
  createEmployee,
  deleteEmployee
} from "../../../resource";
import useRouter from "../../hook/useRouter";
import useAuth from "../../hook/useAuth";
import useTable from "../../../datatable/useTable";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window"

const toDisplayable = ({
  id,
  name,
  ic,
  dic,
  email,
  phoneNumber,
  web,
  notes,
  type,
  formattedAddress,
  placeId
}) => ({
  id,
  name,
  ic,
  dic,
  email,
  phoneNumber,
  web,
  notes,
  type,
  formattedAddress,
  placeId,
  selected: false
});

const useStyles = makeStyles(({ palette, spacing }) => ({
  contactName: {
    display: "flex",
    alignItems: "center",

    "& > :not(:first-child)": {
      marginLeft: spacing(1),
      fontSize: 19
    }
  }
}));

const RENDERABLE_TAG_COUNT = 3;

const ContactsContainer = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const client = useApiClient();

  const pathname = location.pathname.slice(1);

  const { history, location: { search: routerSearch } } = useRouter();
  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  const defaultFilterValues = ["types", "isPrivate", "sharedWith", "sharedBy", "createdAt", "isSubcontractor"];
  const availableFilters = [
    {
      label: t("contacts.contactType"),
      value: "types"
    }, {
      label: t("contacts.visibility"),
      value: "isPrivate"
    },
    {
      label: t("contacts.sharedWith"),
      value: "sharedWith"
    },
    {
      label: t("contacts.sharedBy"),
      value: "sharedBy"
    }, {
      label: t("contacts.createdAtRange"),
      value: "createdAt"
    },
    {
      label: t("contacts.createdBy"),
      value: "creators"
    },
    {
      label: t("contacts.tags"),
      value: "tags"
    },
    {
      label: t("contact.subcontractor"),
      value: "isSubcontractor"
    }
    // {
    //   label: t("contacts.headquarters"),
    //   value: "headquarters"
    // }
  ];

  const [filter, setFilter] = useState(initFilter);
  const [search, setSearch] = useState(initSearchText);

  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchFailDialogOpen, setSearchFailDialogOpen] = useState(false);
  const [optionTags, setOptionTags] = useState([]);
  const [sharingDialogOpen, setSharingDialogOpen] = useState(false);
  const [sharingUsers, setSharingUsers] = useState([]);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const selectedContacts = contacts.filter(({ selected }) => selected);
  const language = i18n.language === "cs" ? "CZ" : "EN";
  let reloadDelay;
  let storeSearchDelay;

  const fetchTags = async () => {
    const tgs = await readAvailableTags(t, language);
    setOptionTags(tgs);
  };


  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    sharedWith: filter.sharedWith ? filter.sharedWith.map(({ id }) => id) : undefined,
    sharedBy: filter.sharedBy ? filter.sharedBy.map(({ id }) => id) : undefined
  });

  const reloadContacts = useCallback(async (offset, limit, ordering) => {
    try {
      const transformedFilter = transformFilter(filter);

      let contactData = [];
      let promise = client.contact.postContactMatchQuery({
        query: {
          match: { search, ...transformedFilter },
          orderBy: ordering,
          offset,
          limit
        }
      });

      let _contacts = await promise;

      if (_contacts.total === 0 && offset !== 0) {
        handleChangePage(
          undefined,
          0
        )
      }

      _contacts.matches.map((contact) => {
        contact.items = contact.items;
        contact.isDisabled = contact.isDisabled === 1;
        contact.selected = false;

        let typeText = "";
        if (contact.type === "CARRIER") {
          typeText = t("contacts.carrier");
        } else if (contact.type === "CUSTOMER") {
          typeText = t("contacts.customer");
        } else {
          typeText = t("contacts.both");
        }

        let tableCells = [];
        tableCells.push({
          render: (
            <div className={classes.contactName}>
              <Typography variant="body2"> {contact.name} </Typography>
              {contact.isPrivate
                ? (
                  <Tooltip title={t("contacts.private")}>
                    <LockIcon color="action" />
                  </Tooltip>
                )
                : null}
            </div>
          )
        });
        tableCells.push({
          render: <Typography variant="body2"> {contact.billingAddress} </Typography>
        });
        tableCells.push({
          render:
            <UserAvatar
              user={contact.createdBy}
            />
        });
        tableCells.push({
          render: <Typography variant="body2"> {typeText} </Typography>
        });
        if (contact.tags) {
          const { tags } = contact;

          const renderableTags = tags.slice(0, RENDERABLE_TAG_COUNT);
          const restTagsLength = tags.length - renderableTags.length;

          tableCells.push({
            render:
              <div style={{ display: "flex", flexDirection: "row" }}>
                {
                  renderableTags.sort((a, b) => {
                    const firstElem = a.type.toUpperCase();
                    const secondElem = b.type.toUpperCase();
                    if (firstElem < secondElem) {
                      return -1;
                    }
                    if (firstElem < secondElem) {
                      return 1;
                    }
                    return 0;
                  }).map((tag, index) => (
                    <Chip key={index} label={tag.label} style={{ margin: 1 }} />
                  ))
                }
                {restTagsLength ? <Typography style={{ marginLeft: 4, marginTop: 4, fontSize: 17 }}>+{restTagsLength}</Typography> : undefined}
              </div>
          });
        }
        else {
          tableCells.push({
            render: null
          });
        }

        contactData.push({ id: contact.id, row: tableCells, selected: false, disabled: user.id !== contact.createdBy.id && contact.isPrivate && !canUpdateCompanyContact });
        return contact;
      });

      setContacts(_contacts.matches);
      return { data: contactData, totalCnt: _contacts.total };
    }
    catch (err) {
      console.error(err);
      enqueueSnackbar(t("contacts.error.get"), {
        variant: "error"
      });
    }
  }, [search, filter]);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = useTable(reloadContacts, "contacts");

  const handleCreateSubmit = (contact) => {
    setIsEditorOpen(false);

    return createContact(contact)
      .then(() => {
        reloadData();
      })
      .catch((error) => {
        console.error(error);
        const { response } = error;

        let message = t("contacts.error.create");
        if (response.data.code === "DUPLICATE_ENTRY") {
          message = t("contacts.error.alreadyExists");
        }
        enqueueSnackbar(message, {
          variant: "error"
        });
      });
  };

  const handleDeleteSubmit = () => {
    setDeleteDialogOpen(false);

    const ids = selectedContacts.map(({ id }) => id);
    const requests = ids.map(deleteContact);

    return Promise.all(requests)
      .then(() => {
        reloadDataFromScratch();
      })
      .catch((error) => {
        console.log(error);

        enqueueSnackbar(t("contacts.error.delete"), {
          variant: "error"
        });
      });
  };

  const replaceContact = (index, contact) => setContacts(Object.assign([], contacts, { [index]: contact }));

  const handleSelectContactType = (types) => setFilter({ ...filter, types });

  const handleSelectIsSubcontractor = (isSubcontractor) => setFilter({ ...filter, isSubcontractor });

  const handleSelectCreatedAtRange = (createdAt) => setFilter({ ...filter, createdAt });

  const handleSelectVisibility = (isPrivate) => setFilter({ ...filter, isPrivate });

  const handleSelectCreators = (creatorIds) => setFilter({ ...filter, creators: creatorIds });

  const handleSelectTags = (tagIds) => setFilter({ ...filter, tags: tagIds });

  const handleSelectContactSharedBy = (userIds) => setFilter({ ...filter, sharedBy: userIds });

  const handleSelectContactSharedWith = (userIds) => setFilter({ ...filter, sharedWith: userIds });

  const handleSelectHeadquarters = (text) => setFilter({ ...filter, headquarters: text });

  const handleSelectCreatedAt = (createdAt) => setFilter({ ...filter, createdAt });

  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
    setSharingUsers([]);
  };

  const handleUpdateSubmit = (contact) => {
    setUpdateDialogOpen(false);
    return updateContact(contact)
      .then(() => {
        reloadData();
      })
      .catch((error) => {
        let message = "contacts.error.update";
        if (error.response.data.error === "contact.duplicateIcDic") {
          message = "contacts.error.duplicateIcDic";
        }

        enqueueSnackbar(t(message), {
          variant: "error"
        });
      });
  };

  const handleDeselectFilters = () => setFilter({});

  const handleFilterSettingsClose = () => {
    setIsFilterSettingsOpen(false);
  };

  const handleFilterSettingsOpen = () => {
    setIsFilterSettingsOpen(true);
  }

  const onFilterSettingsSubmit = (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("contacts", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const handleNewShipment = (id) => {
    const queryParameters = {};
    contacts.map((contact) => {
      if (contact.id == id) {
        if (contact.type === "CARRIER") {
          queryParameters.carrierId = id;
        } else if (contact.type === "CUSTOMER") {
          queryParameters.customerId = id;
        }
      }
    });

    const query = queryString.stringify(queryParameters);
    history.push(`/shipment?${query}`)
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const handleOpenDeleteDialog = () => {
    // Synchronize selected items from data table with contacts
    for (let index = 0; index < contacts.length; index++) {
      contacts[index].selected = data[index].selected;
    }

    const isAssigned = selectedContacts.filter(({ isAssigned }) => isAssigned === 1).length;

    if (isAssigned) {
      enqueueSnackbar(
        t("contacts.error.assignedToShipment"),
        { variant: "error" }
      );
    } else {
      setDeleteDialogOpen(true);
    }
  };

  const handleSharingSubmit = async (users, contactId) => {
    const resultSharing = await setContactSharingUsers(apiClient, contactId, users);
    setSharingDialogOpen(false);
    setSharingUsers(users);
  };

  const handleContactEditorSubmit = async ({ id: contactId, ...contact }) => {
    setIsEditorOpen(false);

    try {
      if (contactId) {
        await client.contact.putContact({ contactId, contact });
        await reloadData();
      } else {
        const { id } = await client.contact.postContact({ contact });
        history.push(`/contacts/${id}`);
      }
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("contacts.error.create"), { variant: "error" });
    }
  };

  const expandFilters = (values, fullValues) => values.map(item => fullValues.find(i => i.value === item));

  useEffect(() => {
    addUrlParam("filter", filter);
  }, [filter])

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250)
  }, [search])

  useEffect(() => {
    const loadedFilters = loadFilters("contacts");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const { hasPermission } = useAuth();
  const canCreateContact = hasPermission("resource.contact.user.create");
  const canDeleteContact = hasPermission("resource.contact.user.delete");
  const canUpdateContact = hasPermission("resource.contact.user.update");
  const canCreateShipment = hasPermission("resource.shipment.user.create");
  const canUpdateCompanyContact = hasPermission("resource.contact.company.update");

  return (
    <>
      <Contacts
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        ordering={ordering}
        search={search}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        canCreateContact={canCreateContact}
        canDeleteContact={canDeleteContact}
        canUpdateContact={canUpdateContact}
        canCreateShipment={canCreateShipment}
        filter={filter}
        handleDeselectFilters={handleDeselectFilters}
        handleSort={handleSort}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleCreateRequest={() => {
          setSelectedContact({});
          setIsEditorOpen(true);
        }}
        handleUpdateRequest={index => {
          const contact = contacts
            .filter(item => index === item.id)[0];

          setSelectedContact(contact);
          setIsEditorOpen(true);
        }}
        handleDeleteRequest={handleOpenDeleteDialog}
        handleViewMoreRequest={async (id) => history.push(`/contacts/${id}`)}
        handleNewShipment={handleNewShipment}
        handleSelectContactType={handleSelectContactType}
        handleSelectIsSubcontractor={handleSelectIsSubcontractor}
        handleSelectVisibility={handleSelectVisibility}
        handleSelectCreators={handleSelectCreators}
        handleSelectContactSharedWith={handleSelectContactSharedWith}
        handleSelectContactSharedBy={handleSelectContactSharedBy}
        handleSelectTags={handleSelectTags}
        handleSelectCreatedAtRange={handleSelectCreatedAtRange}
        handleSelectHeadquarters={handleSelectHeadquarters}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        defaultFilters={defaultFilters}
      />
      <ContactEditor
        initialValue={selectedContact}
        isOpen={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        onSubmit={handleContactEditorSubmit}
      />
      <ContactsDeleteDialog
        open={deleteDialogOpen}
        selected={selectedContacts.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default ContactsContainer;
