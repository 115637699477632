import {
  format,
  addMinutes,
  addSeconds,
  subMinutes,
  setHours,
  setMinutes,
  getHours,
  getMinutes,
  differenceInMinutes,
  parseISO,
  differenceInDays,
  isSameDay,
  formatDistance,
  eachWeekOfInterval,
  startOfWeek,
  addWeeks,
  formatRelative,
  isBefore,
} from "date-fns";

const parseDuration = (s) =>
  formatDistance(new Date(), addSeconds(new Date(), s), {
    includeSeconds: false,
  });

const getNameInitials = (name) =>
  name
    .split(" ")
    .map(([firstLetter]) => firstLetter)
    .join("");

const formatDateTime = (date) => format(date, "d.M.yyyy H:mm");

const parseDate = (date) => parseISO(date);

const formatDate = (date) => format(date, "d.M.yyyy");

const formatDateWithoutYear = (date) => format(date, "d.M.");

const formatDateTimeWithoutYear = (date) => format(date, "d.M. H:mm");

const formatHours = (date) => format(date, "HH");

const formatDateTimeRelatively = (date, locale) =>
  formatRelative(date, new Date(), { locale });

const getCleanDate = (value) => {
  if (!isNaN(value.getTime())) {
    const timezoneOffset = value.getTimezoneOffset() * 60000;
    const date = new Date(value.getTime() - timezoneOffset);
    return date;
  }
  return value;
};

const transformOrderDatesToTodays = (
  departureDateTimeFrom,
  departureDateTimeTo,
  deliveryDateTimeFrom,
  deliveryDateTimeTo
) => {
  let newDepartureFromDate = new Date();
  newDepartureFromDate = setHours(
    newDepartureFromDate,
    getHours(departureDateTimeFrom)
  );
  newDepartureFromDate = setMinutes(
    newDepartureFromDate,
    getMinutes(departureDateTimeFrom)
  );
  const loadingDifference = differenceInMinutes(
    departureDateTimeTo,
    departureDateTimeFrom
  );
  const newDepartureToDate = addMinutes(
    newDepartureFromDate,
    loadingDifference
  );
  const unloadingFromDifference = differenceInMinutes(
    deliveryDateTimeFrom,
    departureDateTimeFrom
  );
  const newDeliveryFromDate = addMinutes(
    newDepartureFromDate,
    unloadingFromDifference
  );
  const unloadingDifference = differenceInMinutes(
    deliveryDateTimeTo,
    deliveryDateTimeFrom
  );
  const newDeliveryToDate = addMinutes(
    newDeliveryFromDate,
    unloadingDifference
  );
  return {
    departureFrom: newDepartureFromDate,
    departureTo: newDepartureToDate,
    deliveryFrom: newDeliveryFromDate,
    deliveryTo: newDeliveryToDate,
  };
};

/**
 * Method takes DateTime from and to (strings) and format it
 * Input: 11.11.2020 15:00, 11.11.2020 17:00 -> expected output: 11.11.2020 15:00-17:00
 * Input: 11.11.2020 15:00, 12.11.2020 11:00 -> expected output: 11.11.2020 15:00-12.11.2020 11:00
 * @name formatJourneyPointDateTime
 * @param {String} dateTimeFrom
 * @param {String?} dateTimeTo
 * @returns {String}
 */
const formatJourneyPointDateTime = (
  dateTimeFrom,
  dateTimeTo,
  withoutYear = false
) => {
  if (!dateTimeFrom && !dateTimeTo) return "";
  // fixed datetime
  if (!dateTimeTo) {
    return withoutYear
      ? formatDateTimeWithoutYear(dateTimeFrom)
      : formatDateTime(dateTimeFrom);
  }
  // datetime range in same day
  if (isSameDay(dateTimeFrom, dateTimeTo)) {
    const result = withoutYear
      ? formatDateTimeWithoutYear(dateTimeFrom)
      : formatDateTime(dateTimeFrom);
    const hours = `0${getHours(dateTimeTo)}`.slice(-2);
    const minutes = `0${getMinutes(dateTimeTo)}`.slice(-2);
    return `${result} - ${hours}:${minutes}`;
  }
  // datetime range in several days
  return `${withoutYear
    ? formatDateTimeWithoutYear(dateTimeFrom)
    : formatDateTime(dateTimeFrom)
    } - ${withoutYear
      ? formatDateTimeWithoutYear(dateTimeTo)
      : formatDateTime(dateTimeTo)
    }`;
};

const deserializeQuery = (query) =>
  query
    .split("&")
    .map((value) => value.split("="))
    .map(([key, value]) => [key, decodeURIComponent(value)])
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key]: value,
      }),
      {}
    );

class ID {
  constructor(start) {
    this.num = start || 0;
  }

  to4DigitString() {
    return `00000000${this.num}`.slice(-8);
  }

  current() {
    return this.to4DigitString(this.num);
  }

  next() {
    return this.to4DigitString(++this.num);
  }
}

const formatTimezone = (date) => {
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1
    ? subMinutes(date, offset)
    : addMinutes(date, Math.abs(offset));
};

const isEmpty = (iterable) => iterable.length === 0;

const sortTable = (a, b, primary, secondary, _direction) => {
  if (_direction === asc) {
    [a, b] = [b, a];
  }
  return a[primary] > b[primary]
    ? -1
    : b[primary] > a[primary]
      ? 1
      : a[secondary] > b[secondary]
        ? -1
        : 1;
};

const formatPlaceFromComponents = (components) =>
  components
    .filter(
      ({ types }) =>
        types.includes("sublocality") ||
        types.includes("locality") ||
        types.includes("postal_code") ||
        types.includes("country")
    )
    .sort(({ types: typesA }, { types: typesB }) => {
      if (typesA.includes("country")) {
        return 1;
      }
      if (typesB.includes("country")) {
        return -1;
      }
      return 0;
    })
    .map(({ shortName, longName, types }) =>
      types.includes("country") ? shortName : longName
    )
    .join(", ");

const getWeekRangesSinceDateTillToday = (referenceDate) => {
  const weeks = eachWeekOfInterval(
    {
      start: startOfWeek(referenceDate, {
        weekStartsOn: 1,
      }),
      end: addWeeks(
        startOfWeek(new Date(), {
          weekStartsOn: 1,
        }),
        1
      ),
    },
    {
      weekStartsOn: 1,
    }
  );
  return weeks
    .map((val, index) =>
      index !== weeks.length - 1 ? [val, weeks[index + 1]] : undefined
    )
    .slice(0, -1)
    .sort((a, b) => {
      if (a[0] > b[0]) {
        return -1;
      }
      if (a[0] < b[0]) {
        return 1;
      }
      return 0;
    });
};

const isDue = (dateString) =>
  dateString && isBefore(parseISO(dateString), new Date());

const getOverdueDays = (dateString) =>
  dateString ? differenceInDays(new Date(), parseISO(dateString)) : undefined;

const intersect = (a, b) => a.filter(Set.prototype.has, new Set(b));

export {
  getNameInitials,
  formatDate,
  formatDateTime,
  formatTimezone,
  formatHours,
  ID,
  getCleanDate,
  deserializeQuery,
  isEmpty,
  sortTable,
  formatDateWithoutYear,
  transformOrderDatesToTodays,
  parseDate,
  formatJourneyPointDateTime,
  formatDateTimeWithoutYear,
  parseDuration,
  formatPlaceFromComponents,
  getWeekRangesSinceDateTillToday,
  formatDateTimeRelatively,
  isDue,
  getOverdueDays,
  intersect,
};
