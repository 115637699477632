import { makeStyles } from "@material-ui/core";

import React from "react";

import { Container } from "../../../cargotic-webapp-component";
import VehicleProfileHeaderContent from "./VehicleProfileHeaderContent";
import VehicleProfileHeaderToolbar from "./VehicleProfileHeaderToolbar";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    height: 480,

    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingTop: spacing(1)
  }
}));

const VehicleProfileHeader = ({
  breadcrumbs,
  latestExpirations,
  vehicle,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  isExpirationCreateButtonDisabled,
  isShipmentCreateButtonDisabled,
  onAvatarChange,
  onDeleteButtonClick,
  onEditButtonClick,
  onExpirationAdd,
  onShipmentCreateButtonClick,
  onVehicleTrailerEditClick,
  onVehicleDriverEditClick
}) => {
  const classes = useStyles();
  const { avatarUrl: backgroundUrl } = vehicle;
  const background = backgroundUrl
    ? `
      linear-gradient(rgba(0, 0, 0, 0), #eee),
      url('${backgroundUrl}') center center / cover no-repeat
    `
    : undefined;

  return (
    <header className={classes.root} style={{ background }}>
      <div>
        <VehicleProfileHeaderToolbar
          breadcrumbs={breadcrumbs}
          hasInvertedColor={backgroundUrl !== undefined}
          onAvatarChange={onAvatarChange}
        />
      </div>
      <Container>
        <VehicleProfileHeaderContent
          latestExpirations={latestExpirations}
          vehicle={vehicle}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
          isExpirationCreateButtonDisabled={isExpirationCreateButtonDisabled}
          isEditButtonDisabled={isEditButtonDisabled}
          isShipmentCreateButtonDisabled={isShipmentCreateButtonDisabled}
          onDeleteButtonClick={onDeleteButtonClick}
          onEditButtonClick={onEditButtonClick}
          onExpirationAdd={onExpirationAdd}
          onShipmentCreateButtonClick={onShipmentCreateButtonClick}
          onVehicleTrailerEditClick={onVehicleTrailerEditClick}
          onVehicleDriverEditClick={onVehicleDriverEditClick}
        />
      </Container>
    </header>
  );
};

export default VehicleProfileHeader;
