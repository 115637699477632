class DummyError extends Error {
  constructor() {
    super(`
      Oops! A random cat has rushed through the room and knocked down all of
      the networking hardware and your connection was interrupted abruptly.
    `);
  }
}

export default DummyError;
