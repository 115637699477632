import { fromEntries } from "./object";
import { Keyable } from "./type";

function pop<T>(array: T[]): T[] {
  return array.slice(0, -1);
}

function push<T>(array: T[], element: T): T[] {
  return [...array, element];
}

function removeIndex<T>(array: T[], index: number): T[] {
  return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
}

function removeProperty<T, K extends keyof T>(
  object: T,
  name: K
): Omit<T, K> {
  return fromEntries(
    Object.entries(object)
      .filter(([other, _]) => other !== name)
  );
}

function swapIndices<T>(
  array: T[],
  sourceIndex: number,
  destinationIndex: number
): T[] {
  const firstIndex = sourceIndex > destinationIndex
    ? destinationIndex : sourceIndex;

  const secondIndex = sourceIndex > destinationIndex
    ? sourceIndex : destinationIndex;

  const result = [
    ...array.slice(0, firstIndex),
    array[secondIndex],
    ...array.slice(firstIndex + 1, secondIndex),
    array[firstIndex],
    ...array.slice(secondIndex + 1, array.length)
  ];

  return result;
}

function updateIndex<T>(array: T[], index: number, element: T): T[] {
  return [
    ...array.slice(0, index),
    element,
    ...array.slice(index + 1, array.length)
  ];
}

function updateProperty<K extends Keyable, V, T extends { [key in K]: V }>(
  object: T,
  name: K,
  value: V
): T {
  return {
    ...object,
    [name]: value
  };
}

export {
  push,
  pop,
  removeIndex,
  removeProperty,
  swapIndices,
  updateIndex,
  updateProperty
};
