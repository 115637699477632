import {useApiClient} from "../../../../../cargotic-webapp-component";
import React from "react";
import {createStateSuggestQuery} from "../../../../resource";

const loadAvailableUsers = async (search, roles = undefined) => {
	const apiClient = useApiClient();
	try {
		const {matches} = await apiClient.user.postUserMatchQuery({
			query: {
				match: {search, roles},
				limit: 15,
			},
		});
		return matches;
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

const loadAvailableContacts = async (type, search) => {
	const apiClient = useApiClient();
	try {
		const contacts = await apiClient.contact.postContactSuggestQuery({
			query: {
				search,
				types: ["BOTH", type],
			},
		});
		return contacts;
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

const loadAvailableVehicles = async (search) => {
	const apiClient = useApiClient();
	try {
		const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
			query: {
				search,
			},
		});
		return vehicles;
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

const transformFilter = (filter) => ({
	...filter,
	creators: filter.creators ? filter.creators.map(({id}) => id) : undefined,
	drivers: filter.drivers ? filter.drivers.map(({id}) => id) : undefined,
	carriers: filter.carriers ? filter.carriers.map(({id}) => id) : undefined,
	customers: filter.customers
			? filter.customers.map(({id}) => id)
			: undefined,
	vehicles: filter.vehicles ? filter.vehicles.map(({id}) => id) : undefined,
});

const getReceiversNames = (cargoItems) => {
	let receiverNames = [];
	cargoItems.map(cargoItem => {
		if (!receiverNames.some(receiverName => receiverName === cargoItem.receivedBy.name)) {
			receiverNames.push(cargoItem.receivedBy.name)
		}
	})
	return receiverNames;
}

const loadAvailableWarehouseStates = async (search) => {
	try {
		const states = await createStateSuggestQuery({ resources: ["warehouse_order"] });
		return states;
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

const loadAvailableStates = async (search) => {
	try {
		const states = await createStateSuggestQuery({ resources: ["incoming_order"] });
		return states;
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

export {loadAvailableWarehouseStates}