import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "@cargotic/time";

import {
  makeStyles,
  Typography,
  MenuList,
  Grow,
  Menu,
  TextField,
  ListItemText,
  Popper,
  InputAdornment,
  ClickAwayListener,
  FormControl,
  IconButton,
  Select,
  InputLabel,
  Button,
  CircularProgress,
  Scrollbar,
  Input,
  MenuItem,
  Divider,
  ListItem,
  Checkbox,
  ListItemSecondaryAction,
  Paper
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import { areArraysEqual } from "../../../cargotic-webapp/utility/functional";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: spacing(50),
    height: spacing(60)
  },
  icon: {
    width: "0.8em",
    height: "0.8em"
  },
  endIcon: {
    marginLeft: 0
  },
  buttonRoot: {
    textTransform: "none",
    fontWeight: 600
  },
  buttonRootSelected: {
    background: "#D6D6D6",
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#D6D6D6"
    }
  },
  text: {
    padding: "4px 6px"
  },
  field: {
    padding: spacing(1)
  },
  adorned: {
    paddingRight: 0,
  },
  gutters: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },
  itemTitle: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },
  progressIndicator: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    padding: spacing(1)
  }
}));

const FilterDynamicSuggestionDropdown = ({
  id,
  placeholderTitle,
  onChange,
  value,
  search,
  selectAll,
  getTitle,
  onClear,
  renderItem,
  disabled
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [source, setSource] = useState([]);
  const [selectAllChosen, setSelectAllChosen] = useState(undefined);
  const [selectedItems, setSelectedItems] = useState([]);

  const isSelectedAll = areArraysEqual(value, source);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const stopPropagation = e => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    if (!open) {
      handleSuggest(searchText);
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = (item, checked) => {
    if (checked) {
      if (!value.includes(item)) {
        onChange([...value, item]);
      }
    } else {
      onChange(value.filter(c => c !== item));
    }
  };

  const handleSuggest = useCallback(debounce(1000)(async () => setSource(await search(searchText))));
  const handleSelectItem = useCallback(debounce(100)((item, checked) => handleClick(item, checked)));

  useEffect(() => {
    if (open) {
      handleSuggest(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    const load = async () => {
      if (open) {
        setLoading(true);
        setSource(await search(searchText));
        setLoading(false);
      }
    }
    load();
  }, [open]);

  const handleClearAndClose = () => {
    onClear();
    handleToggle();
  };

  return (
    <>
      <Button
        ref={anchorRef}
        classes={{ root: value.length > 0 ? classes.buttonRootSelected : classes.buttonRoot, endIcon: classes.endIcon, text: classes.text }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={disabled}
        disableRipple
        endIcon={
          <div>
            <IconButton onClick={null} size="small">
              <ArrowDropDownIcon size="small" className={classes.icon} />
            </IconButton>
            {value.length > 0 ?
              (<IconButton onClick={handleClearAndClose} size="small">
                <CloseIcon size="small" className={classes.icon} />
              </IconButton>) : null}
          </div>
        }
      >
        {placeholderTitle} {value.length > 0 ? `(${value.length})` : null}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex: 1301}}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper style={{ maxHeight: 400, overflow: "auto" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <TextField
                    id="search"
                    fullWidth
                    value={searchText}
                    className={classes.field}
                    label={t("search")}
                    onChange={({ target: { value } }) => setSearchText(value)}
                    onKeyDown={stopPropagation}
                    variant="outlined"
                    InputProps={{
                      classes: { adornedEnd: classes.adorned },
                      endAdornment:
                        searchText === "" ? (<InputAdornment position="end"><IconButton><SearchIcon /></IconButton></InputAdornment>) : (<InputAdornment position="end">
                          <IconButton onClick={() => setSearchText("")}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>)
                    }}
                  />
                  <ListItem button key="selectAll" onClick={() => onChange({ target: { checked: !checked } })}>
                    <ListItemText primary={t("selectAll")} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        color="primary"
                        checked={selectAllChosen || isSelectedAll}
                        edge="end"
                        onChange={({ target: { checked } }) => {
                          if (checked) {
                            selectAll(source);
                            setSelectAllChosen(true);
                          } else if (checked === false) {
                            onClear();
                            setSelectAllChosen(false);
                          }
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  {value.map((item) => renderItem(item, value.map(({ id }) => id).includes(item.id), ({ target: { checked } }) => handleSelectItem(item, checked)))}
                  {value.length > 0 ? <Divider /> : null}
                  {loading ? <div className={classes.progressIndicator}><CircularProgress /></div> : source.filter(({ id }) => !value.find((i) => i.id === id)).map((item) => renderItem(item, value.map(({ id }) => id).includes(item.id), ({ target: { checked } }) => handleSelectItem(item, checked)))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default FilterDynamicSuggestionDropdown;
