import { ApiClient, httpDelete, httpPost } from "./client";

interface DocumentUploadResult {
  id: number,
  url: string,
  createdAt: Date
}

const deserializeDocument = ({ id, url, createdAt }) => ({
  id,
  url,
  createdAt: new Date(createdAt)
}) as DocumentUploadResult;

const uploadShipmentDocument = async (client: ApiClient, document: File) => {
  const form = new FormData();

  form.append("document", document);

  const { data } = await httpPost(
    client,
    "files/shipment-documents",
    undefined,
    form
  );

  return deserializeDocument(data);
};

const deleteShipmentDocument = async (client: ApiClient, url: string) => {
  const [name, salt] = new URL(url).pathname.split("/").reverse();

  return httpDelete(client, `files/shipment-documents/${salt}/${name}`);
};

export {
  deleteShipmentDocument,
  uploadShipmentDocument
};
