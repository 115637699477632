import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "& svg": {
      color: palette.text.secondary,
      fontSize: 64,

      marginRight: spacing(2)
    }
  }
}));

const StatisticCardBody = ({ value, icon: Icon }) => {
  const classes = useStyles();
  const { i18n: { language } } = useTranslation();

  return (
    <section className={classes.root}>
      {
        Icon
          ? <Icon />
          : null
      }
      <Typography variant="h2" component="p">
        <b>
          {value.toLocaleString(language)}
        </b>
      </Typography>
    </section>
  );
};

export default StatisticCardBody;
