const fetchGooglePlaceDetail = async (placesService, placeId, fields) =>
  new Promise((resolve, reject) => (
    placesService.getDetails(
      { placeId, fields },
      (detail, status) => {
        if (status !== "OK") {
          reject(status);
          return;
        }

        resolve(detail);
      }
    )
  ));

export default fetchGooglePlaceDetail;
