import { 
  suggestGooglePlace,
  searchGooglePlace,
  searchGooglePlaceByCoordinates
} from "./../../multiload/cargotic-map";

const deserializeGeocodeData = data => ({
  title: data.formatted_address,
  placeId: data.place_id,
  latitude: data.geometry.location.lat(),
  longitude: data.geometry.location.lng()
});

const deserializePlaceData = data => ({
  title: data.description,
  placeId: data.place_id
});

const suggestPlace = async (googleAutocompleteService, text) => {
  try {
    return await suggestGooglePlace(googleAutocompleteService, text);
  } catch (error) {
    if (error === "ZERO_RESULTS") {
      return [];
    }

    throw error;
  }
};

const fetchGooglePlaceDetail = async (
  placesService,
  placeId,
  fields
) => {
  return new Promise((resolve, reject) => (
    placesService.getDetails(
      { placeId, fields },
      (detail, status) => {
        if (status !== "OK") {
          reject(status);
          return;
        }

        resolve(detail);
      }
    )
  ));
}

const fetchPlaceByGooglePlaceId = async (placesService, googlePlaceId) => {
  const response = await fetchGooglePlaceDetail(
    placesService,
    googlePlaceId,
    [
      "address_component",
      "formatted_address",
      "geometry.location",
      "name",
    ]
  );

  const {
    address_components: components,
    formatted_address: formatted,
    name,
    photos
  } = response;

  const address = {
    formatted: formatted,
    components: components
      .map(({ short_name: shortName, long_name: longName, types }) => ({
        shortName,
        longName,
        types
      }))
  };

  const location = response?.geometry?.location;
  const latitude = location.lat();
  const longitude = location.lng();
  const image = photos ? photos[0].getUrl({}) : undefined;

  return {
    place_id: googlePlaceId,
    description: address.formatted,
    address,
    name,
    latitude,
    longitude,
    image
  };
}

const searchPlace = async (placesService, text) => {
  try {
    const [result] = await searchGooglePlace(
      placesService,
      text,
      [
        "place_id"
      ]
    );

    const place = await fetchPlaceByGooglePlaceId(placesService, result?.place_id);
    return place;
  } catch (error) {
    if (error === "ZERO_RESULTS") {
      return undefined;
    }

    throw error;
  }
};

const searchPlaceByCoordinates = async (geocoderService, placesService, latitude, longitude) => {
  try {
    var latlng = {lat: parseFloat(latitude), lng: parseFloat(longitude)};
    const [result] = await searchGooglePlaceByCoordinates(
      geocoderService,
      latlng
    );

    const place = await fetchPlaceByGooglePlaceId(placesService, result?.place_id);
    return place;
  } catch (error) {
    if (error === "ZERO_RESULTS") {
      return undefined;
    }

    throw error;
  }
}

const getPlacesSuggestions = value => new Promise((resolve, reject) => {
  new google.maps.places.AutocompleteService().getQueryPredictions({
    input: value
  }, (predictions, status) => {
    if (status === "OK") {
      resolve(predictions.map(deserializePlaceData));
      return;
    }

    reject(status);
  });
});

const fetchPlaceId = placeId => new Promise((resolve, reject) => {
  new google.maps.Geocoder().geocode({
    placeId
  }, (results, status) => {
    if (status === "OK" && results[0]) {
      resolve(results[0].geometry.location);
      return;
    }

    reject(status);
  });
});

const fetchPlaceIdByAddress = address => new Promise((resolve, reject) => {
  new google.maps.Geocoder().geocode({
    address
  }, (results, status) => {
    if (status === "OK" && results[0]) {
      resolve(results[0].place_id);
      return;
    }

    reject(status);
  });
});

const fetchGeocode = (latitude, longitude) => new Promise((resolve, reject) => {
  new google.maps.Geocoder().geocode({
    location: {
      lat: latitude,
      lng: longitude
    }
  }, (results, status) => {
    if (status === "OK") {
      resolve(results.map(deserializeGeocodeData));
      return;
    }

    reject(status);
  });
});

export {
  searchPlace,
  searchPlaceByCoordinates,
  suggestPlace,
  fetchGooglePlaceDetail,
  fetchPlaceByGooglePlaceId,
  getPlacesSuggestions,
  fetchPlaceId,
  fetchGeocode,
  fetchPlaceIdByAddress
};
