import React from "react";

import {
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  CardActionArea
} from "@material-ui/core";

import { invariant } from "../../../cargotic-webapp-utility";
import StatisticCardBody from "./StatisticCardBody";
import StatisticCardFooter from "./StatisticCardFooter";
import StatisticCardHeader from "./StatisticCardHeader";

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",

    "&:last-child": {
      padding: spacing(2)
    }
  },
  progress: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const StatisticCard = ({
  className,
  headerAdornment,
  title,
  value,
  icon,
  rateOfChange,
  trendDescription,
  periods,
  period,
  units,
  unit,
  range,
  onRangeChange,
  isLoading,
  isTrendInverted,
  onPeriodChange,
  onUnitChange,
  onClick
}) => {
  invariant(
    periods && periods.length > 0,
    "You have to specify at least one period."
  );

  invariant(
    units && units.length > 0,
    "You have to specify at least one unit."
  );

  const classes = useStyles();
  const Container = onClick ? CardActionArea : "div";
  
  return (
    <Container onClick={onClick}>
      <Card className={className}>
        <CardContent className={classes.content}>
          {
            isLoading
              ? (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <StatisticCardHeader
                    adornment={headerAdornment}
                    title={title}
                    period={period}
                    periods={periods}
                    range={range}
                    onRangeChange={onRangeChange}
                    onPeriodChange={onPeriodChange}
                  />
                  <StatisticCardBody
                    icon={icon}
                    value={value}
                  />
                  <StatisticCardFooter
                    rateOfChange={rateOfChange}
                    trendDescription={trendDescription}
                    unit={unit}
                    units={units}
                    isTrendInverted={isTrendInverted}
                    onUnitChange={onUnitChange}
                  />
                </>
              )
          }
        </CardContent>
      </Card>
    </Container>
  );
};

export default StatisticCard;
