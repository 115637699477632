import { firstElement, lastElement } from "../../cargotic-common";

const calculateRoute = async (directionsService, placeIds, travelMode) => {
  const originId = firstElement(placeIds);
  const destinationId = lastElement(placeIds);
  const waypointIds = placeIds.slice(1, -1);

  const origin = { placeId: originId };
  const destination = { placeId: destinationId };
  const waypoints = waypointIds
    .map(placeId => ({ location: { placeId } }));

  return new Promise((resolve, reject) => {
    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode
      },
      (directions, status) => {
        if (status === "ZERO_RESULTS") {
          resolve({
            directions: undefined,
            distance: undefined,
            duration: undefined
          });

          return;
        }

        if (status !== "OK") {
          reject(status);

          return;
        }

        const { routes } = directions;
        const [{ legs }] = routes;

        const distance = legs
          .reduce(
            (accumulator, { distance: { value } }) => accumulator + value,
            0
          );

        const duration = legs
          .reduce(
            (accumulator, { duration: { value } }) => accumulator + value,
            0
          );

        resolve({
          directions,
          distance,
          duration
        });
      }
    );
  });
};

export default calculateRoute;
