const searchGooglePlace = (placesService, text, fields) =>
  new Promise((resolve, reject) => (
    placesService.findPlaceFromQuery(
      { query: text, fields },
      (places, status) => {
        if (status !== "OK") {
          reject(status);
          return;
        }

        resolve(places);
      }
    )
  ));

export default searchGooglePlace;
