import React from "react";
import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@material-ui/core";
import RoleEditorView from "./RoleEditorView";

const SettingsRoleEditorNavigation = ({ view, onViewChange }) => {
  const { t } = useTranslation();

  const handleTabChange = (_, value) => onViewChange(value);

  return (
    <Tabs
      indicatorColor="primary"
      value={view}
      onChange={handleTabChange}
    >
      <Tab
        label={t("webapp:settings.title.general")}
        value={RoleEditorView.GENERAL}
      />
      <Tab
        label={t("webapp:settings.title.permissions")}
        value={RoleEditorView.PERMISSIONS}
      />
    </Tabs>
  );
};

export default SettingsRoleEditorNavigation;
