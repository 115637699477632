import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import ContactEmployeeCard from "../ContactEmployeeCard";

const useStyles = makeStyles(() => ({
  card: {
    height: 280
  }
}));

const ContactEmployeeGridBody = ({
  employees,
  onEmployeeDelete,
  onEmployeeEdit
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {employees.map((employee, index) => {
        const { id } = employee;

        const handleDeleteButtonClick = () => {
          if (onEmployeeDelete) {
            onEmployeeDelete(index);
          }
        };

        const handleEditButtonClick = () => {
          if (onEmployeeEdit) {
            onEmployeeEdit(index);
          }
        };

        return (
          <Grid item key={id} xs={4}>
            <ContactEmployeeCard
              className={classes.card}
              employee={employee}
              onDeleteButtonClick={handleDeleteButtonClick}
              onEditButtonClick={handleEditButtonClick}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ContactEmployeeGridBody;
