import React, {useMemo} from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select, Tab, Tabs
} from "@material-ui/core";
import { LinkField } from "../../common";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles(({breakpoints, spacing }) => ({
  root: {
    [breakpoints.up("sm")]: {
      minWidth: 520
    }
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(4)
    }
  },
  progress: {
    marginTop: spacing(2)
  },
  formControl: {
    width: spacing(20),
    paddingTop: spacing(3)
  },
  selectTitle: {
    fontWeight: "600",
    marginBottom: spacing(1)
  }
}));

const ExportDialog = ({
  isOpen,
  isProcessing,
  isDone,
  title,
  startButtonLabel,
  closeButtonLabel,
  infoText,
  processingText,
  close,
  tabs,
  setSelectedExportTab,
  selectedExportTab,
  exportInvoicesPohoda
                      }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  let content = null;

  const exportTabContent = useMemo(() => {
    if (selectedExportTab.type === "export") {
      return <>
        <FormControl className={classes.formControl}>
          <div className={classes.selectTitle}>{t("invoices.export.selectTitle")}</div>
          <Select defaultValue={"pohoda"}>
            <MenuItem value="pohoda">Pohoda XML</MenuItem>
          </Select>
        </FormControl>
      </>;
    } else {
      return <></>;
    }
  }, [selectedExportTab]);

  if (isProcessing) {
    content = (
      <>
        <CircularProgress classes={{ root: classes.progress }} />
        <DialogContentText>{processingText}</DialogContentText>
      </>
    );
  }  else {
    content = (
      <>
        <DialogContentText>{infoText}</DialogContentText>
        <FormControl>
          <Tabs
            value={selectedExportTab.id}
            onChange={(event, newlySelectedTabId) => {
              setSelectedExportTab(tabs.find((tab) => tab.id === newlySelectedTabId));
            }}
            indicatorColor="primary"
            textColor="secondary"
            aria-label="invoice type tabs"
          >
            {tabs.map(tab => <Tab id={tab.id} key={tab.id} label={t(tab.labelKey)} />)}
          </Tabs>
          {exportTabContent}
        </FormControl>
      </>
    );
  }

  return (
    <Dialog classes={{ paper: classes.root }} open={isOpen} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isProcessing || isDone}
          onClick={() => {
            exportInvoicesPohoda();
          }}
        >
          {startButtonLabel}
        </Button>
        <Button color="primary" onClick={close}>
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
