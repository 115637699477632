import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  InputAdornment,
  Tooltip,
  IconButton,
  makeStyles
} from "@material-ui/core";

import { Error } from "@material-ui/icons";

import { getNameInitials } from "../../../cargotic-webapp-utility";
import { useApiClient } from "../../hook";
import AutosuggestTextField from "../AutosuggestTextField";
import { suggestUsers } from "../../../cargotic-webapp/resource";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    color: palette.status.danger
  }
}));

const UserAutosuggestTextField = ({ roleId, permissions, externalQueryParams, ...rest }) => {
  const client = useApiClient();
  const { t } = useTranslation();
  const classes = useStyles();

  const deletedAdornment = () => ({
    endAdornment: (
      <InputAdornment position="start">
        <Tooltip title={t("webapp:shipment.form.error.deletedDriver")}>
          <IconButton className={classes.danger} size="small">
            <Error />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  })

  const resolveInputProps = () => {
    const { value } = rest;

    if (value?.isDeleted) {
      return deletedAdornment();
    }

    return {};
  }


  return (
    <AutosuggestTextField
      {...rest}
      emptySuggestionsLabel={t("webapp:common.title.noUserFound")}
      getSuggestionText={(suggestion) => suggestion ? suggestion.name : ''}
      InputProps={resolveInputProps()}
      suggest={search => suggestUsers({ roleId, permissions, search, ...externalQueryParams})}
      suggestionsComponent={({ suggestions, onSuggestionClick }) => (
        <List>
          {suggestions.map(suggestion => {
            const { id, name, avatarUrl, email } = suggestion;

            const handleClick = event => onSuggestionClick(event, suggestion);

            return (
              <ListItem key={id} button onClick={handleClick}>
                <ListItemAvatar>
                  <Avatar
                    alt={name}
                    src={avatarUrl}
                  >
                    {getNameInitials(name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={email} />
              </ListItem>
            );
          })}
        </List>
      )}
    />
  );
};

export default UserAutosuggestTextField;
