import clsx from "clsx";
import React from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";

import { Container } from "../../../cargotic-webapp-component";
import ContactDeleteConfirmationAlert from "../ContactDeleteConfirmationAlert";
import ContactEditor from "../ContactEditor";
import ContactTagEditor from "../ContactTagEditor";
import ContactProfileBody from "./ContactProfileBody";
import ContactProfileHeader from "./ContactProfileHeader";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    minHeight: "100%",
    flexDirection: "column"
  },
  body: {
    flex: 1
  },
  progress: {
    alignItems: "center",
    justifyContent: "center"
  }
}));

const ContactProfile = ({
  activity,
  availableTags,
  breadcrumbs,
  contact,
  employeeQuery,
  employees,
  orderBalanceHighlight,
  selectedEmployee,
  shares,
  shipmentBalanceHighlight,
  shipmentsHighlight,
  shipmentCommissionHighlight,
  user,
  view,
  isActivityLoading,
  isAvailableTagsLoading,
  isContactLoading,
  isContactEditorOpen,
  isDeleteConfirmationAlertOpen,
  isEmployeeDeleteConfirmationAlertOpen,
  isEmployeeEditorOpen,
  isSubcontractorEmployeeEditorOpen,
  isEmployeesLoading,
  isHighlightsLoading,
  isPublicizeConfirmationAlertOpen,
  isShareEditorOpen,
  isTagEditorOpen,
  onActivityLoad,
  onAvatarChange,
  onBreadcrumbsChange,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onContactEditorClose,
  onContactEditorSubmit,
  onDelete,
  onDeleteConfirmationAlertClose,
  onDeleteConfirmationAlertSubmit,
  onEdit,
  onEmployeeAdd,
  onSubcontractorEmployeeSubmit,
  onSubcontractorEmployeeAdd,
  onSubcontractorEmployeeClose,
  onEmployeeDelete,
  onEmployeeDeleteConfirmationAlertClose,
  onEmployeeDeleteConfirmationAlertSubmit,
  onEmployeeEdit,
  onEmployeeEditorClose,
  onEmployeeEditorSubmit,
  onEmployeeQueryChange,
  onPublicization,
  onPublicizeConfirmationAlertClose,
  onPublicizeConfirmationAlertSubmit,
  onOrderBalanceHighlightChange,
  onShareEditorClose,
  onShareEditorOpen,
  onShareEditorSubmit,
  onShipmentBalanceHighlightChange,
  onShipmentCommissionHighlightChange,
  onShipmentRedirect,
  onShipmentsHighlightChange,
  onTagEdit,
  onTagEditorClose,
  onTagEditorSubmit,
  onViewChange,
  onShipmentsHeaderIconClick
}) => {
  const classes = useStyles();

  if (isAvailableTagsLoading || isContactLoading) {
    return (
      <div className={clsx(classes.root, classes.progress)}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Container size="large" className={classes.root}>
        <ContactProfileHeader
          breadcrumbs={breadcrumbs}
          contact={contact}
          shares={shares}
          isPublicizeConfirmationAlertOpen={isPublicizeConfirmationAlertOpen}
          isShareEditorOpen={isShareEditorOpen}
          onPublicization={onPublicization}
          onPublicizeConfirmationAlertClose={onPublicizeConfirmationAlertClose}
          onPublicizeConfirmationAlertSubmit={onPublicizeConfirmationAlertSubmit}
          onShareEditorClose={onShareEditorClose}
          onShareEditorOpen={onShareEditorOpen}
          onShareEditorSubmit={onShareEditorSubmit}
        />
        <ContactProfileBody
          className={classes.body}
          activity={activity}
          contact={contact}
          employeeQuery={employeeQuery}
          employees={employees}
          orderBalanceHighlight={orderBalanceHighlight}
          selectedEmployee={selectedEmployee}
          shipmentBalanceHighlight={shipmentBalanceHighlight}
          shipmentCommissionHighlight={shipmentCommissionHighlight}
          shipmentsHighlight={shipmentsHighlight}
          user={user}
          view={view}
          isActivityLoading={isActivityLoading}
          isEmployeeDeleteConfirmationAlertOpen={
            isEmployeeDeleteConfirmationAlertOpen
          }
          isEmployeeEditorOpen={isEmployeeEditorOpen}
          isSubcontractorEmployeeEditorOpen={isSubcontractorEmployeeEditorOpen}
          isEmployeesLoading={isEmployeesLoading}
          isHighlightsLoading={isHighlightsLoading}
          onAvatarChange={onAvatarChange}
          onActivityLoad={onActivityLoad}
          onBreadcrumbsChange={onBreadcrumbsChange}
          onCommentaryChange={onCommentaryChange}
          onCommentaryDelete={onCommentaryDelete}
          onCommentarySubmit={onCommentarySubmit}
          onDelete={onDelete}
          onEdit={onEdit}
          onEmployeeAdd={onEmployeeAdd}
          onSubcontractorEmployeeSubmit={onSubcontractorEmployeeSubmit}
          onSubcontractorEmployeeAdd={onSubcontractorEmployeeAdd}
          onSubcontractorEmployeeClose={onSubcontractorEmployeeClose}
          onEmployeeDelete={onEmployeeDelete}
          onEmployeeDeleteConfirmationAlertClose={
            onEmployeeDeleteConfirmationAlertClose
          }
          onEmployeeDeleteConfirmationAlertSubmit={
            onEmployeeDeleteConfirmationAlertSubmit
          }
          onEmployeeEdit={onEmployeeEdit}
          onEmployeeEditorClose={onEmployeeEditorClose}
          onEmployeeEditorSubmit={onEmployeeEditorSubmit}
          onEmployeeQueryChange={onEmployeeQueryChange}
          onOrderBalanceHighlightChange={onOrderBalanceHighlightChange}
          onShipmentBalanceHighlightChange={onShipmentBalanceHighlightChange}
          onShipmentCommissionHighlightChange={onShipmentCommissionHighlightChange}
          onShipmentRedirect={onShipmentRedirect}
          onShipmentsHighlightChange={onShipmentsHighlightChange}
          onTagEdit={onTagEdit}
          onViewChange={onViewChange}
          onShipmentsHeaderIconClick={onShipmentsHeaderIconClick}
        />
      </Container>
      <ContactDeleteConfirmationAlert
        isOpen={isDeleteConfirmationAlertOpen}
        onClose={onDeleteConfirmationAlertClose}
        onSubmit={onDeleteConfirmationAlertSubmit}
      />
      <ContactEditor
        initialValue={contact}
        isOpen={isContactEditorOpen}
        onClose={onContactEditorClose}
        onSubmit={onContactEditorSubmit}
      />
      <ContactTagEditor
        availableTags={availableTags}
        initialTags={contact.tags}
        isOpen={isTagEditorOpen}
        onClose={onTagEditorClose}
        onSubmit={onTagEditorSubmit}
      />
    </>
  );
};

export default ContactProfile;
