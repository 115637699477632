import React, { useState } from "react";
import {
  Fab,
  Grid,
  makeStyles,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { useExchangeRates } from "@cargotic/currency";

import {
  Add,
  CloudDownloadOutlined,
  FileCopyOutlined,
  GetApp,
  DeleteOutline,
  Edit,
  Email,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

import { ShipmentType, ContactType } from "@cargotic/model-deprecated";

import { addDays } from "date-fns";
import config from "../../../config";

import DataTable from "../../../datatable";
import ExportDialog from "./ExportDialog";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import InvoiceDialog from "./InvoiceDialog";
import SearchTextfield from "../../common/SearchTextfield";
import SendOrderDialog from "../../../incomingOrder/component/SendOrderDialog";

import PayDialog from "../../../incomingOrder/component/PayDialog";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterTextDropdown from "../../../../cargotic-webapp-filter/component/FilterTextDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import VehiclesFilterDropdown from "../../../../cargotic-webapp-filter/component/VehiclesFilterDropdown";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";


const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    },
  },
  frame: {
    padding: 0
  }
}));

const DownloadOrderPopoverContent = ({ id }) => (
  <React.Fragment>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a
          href={`${config.api.urlV1}/pdf/outcoming-order/${id}?language=en`}
          target="_blank"
          download
        >
          <ReactCountryFlag
            code="gb"
            svg
            styleProps={{
              width: "32px",
              height: "32px",
            }}
          />
        </a>
      </Grid>
    </Grid>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a
          href={`${config.api.urlV1}/pdf/outcoming-order/${id}?language=cs`}
          target="_blank"
          download
        >
          <ReactCountryFlag
            code="cz"
            svg
            styleProps={{
              width: "32px",
              height: "32px",
            }}
          />
        </a>
      </Grid>
    </Grid>
  </React.Fragment>
);

const SendOrderPopoverContent = ({ id }) => (
  <React.Fragment>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a
          href={`${config.api.urlV1}/pdf/shipment/${id}?language=en`}
          target="_blank"
          download
        >
          <ReactCountryFlag
            code="gb"
            svg
            styleProps={{
              width: "32px",
              height: "32px",
            }}
          />
        </a>
      </Grid>
    </Grid>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a
          href={`${config.api.urlV1}/pdf/shipment/${id}?language=cs`}
          target="_blank"
          download
        >
          <ReactCountryFlag
            code="cz"
            svg
            styleProps={{
              width: "32px",
              height: "32px",
            }}
          />
        </a>
      </Grid>
    </Grid>
  </React.Fragment>
);

const DownloadInvoicePopoverContent = ({ id }) => (
  <React.Fragment>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a
          href={`${config.api.urlV1}/pdf/shipmentInvoice/${id}?language=en`}
          target="_blank"
          download
        >
          <ReactCountryFlag
            code="gb"
            svg
            styleProps={{
              width: "32px",
              height: "32px",
            }}
          />
        </a>
      </Grid>
    </Grid>
    <Grid container style={{ margin: 5 }}>
      <Grid item>
        <a
          href={`${config.api.urlV1}/pdf/shipmentInvoice/${id}?language=cs`}
          target="_blank"
          download
        >
          <ReactCountryFlag
            code="cz"
            svg
            styleProps={{
              width: "32px",
              height: "32px",
            }}
          />
        </a>
      </Grid>
    </Grid>
  </React.Fragment>
);

const OutcomingOrders = ({
  data,
  dataCount,
  selectedColumns,
  shipments,
  setShipments,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  isShipmentsExportDialogOpen,
  isShipmentsExportProcessing,
  isShipmentsExportDone,
  shipmentsExportLink,
  closeShipmentsExportDialog,
  exportShipments,
  canCreateShipment,
  canUpdateShipment,
  canDeleteShipment,
  canCreateInvoice,
  canReadCompanyShipment,
  canDeleteOutcomingOrder,
  canReadInvoice,
  additionalFilter: {
    type: additionalFilterType,
    lastWaypointDateFrom: additionalFilterLastDateFrom,
  },
  handlePayShipment,
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleShipmentsExportRequest,
  handleSelectAll,
  handleSelect,
  handleSelectCarried,
  handleSelectForwarded,
  handleSelectLastMonth,
  handleDeleteRequest,
  handleGenerateShipmentInvoice,
  filter,
  availableBankAccounts,
  handleSelectShipmentState,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectCreators,
  handleSelectDrivers,
  loadAvailableUsers,
  handleSelectCustomer,
  handleSelectCarrier,
  handleSelectCustomerPrice,
  handleSelectCarrierPrice,
  handleSelectCommission,
  loadAvailableContacts,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleSelectVehicles,
  loadAvailableVehicles,
  handleSelectIssuedPaymentState,
  handleSelectReceivedPaymentState,
  handleSendOrder,
  handleSelectCurrentStatusState,
  handleChangeSelectedColumns,
  canCreateOrderPdf,
  loadAvailableStates,

  handleDeleteOutcomingOrderRequest,
  handleOutcomingOrdersExportRequest
}) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    isLoading: isExchangeRatesLoading,
    exchangeRates
  } = useExchangeRates();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] =
    useState(false);
  const [
    showMissingIssuedInvoiceNumberModal,
    setShowMissingIssuedInvoiceNumberModal,
  ] = useState(false);
  const [invoiceDialogShipmentId, setInvoiceDialogShipmentId] = useState(0);
  const [
    invoiceDialogIssuedInvoiceNumber,
    setInvoiceDialogIssuedInvoiceNumber,
  ] = useState();

  const [payShipmentDialogOpen, setPayShipmentDialogOpen] = useState(false);
  const [payShipmentDialogData, setPayShipmentDialogData] = useState({});

  const [sendOrderDialogOpen, setSendOrderDialogOpen] = useState(false);
  const [sendOrderDialogData, setSendOrderDialogData] = useState({});

  const [loadingPayAction, setLoadingPayAction] = useState(false);

  const handleGenerateInvoiceAndRedirect = async (id) => {
    const w = window.open("", "_blank");
    await handleGenerateShipmentInvoice(id);
    w.location.href = `${config.api.urlV1}/pdf/shipmentInvoice/${id}?language=cs`;
  };

  const handleGenerateInvoiceClick = (id) => {
    const [{ issuedInvoiceNumber, hasInvoice }] = shipments.filter(
      ({ id: shipmentId, hasInvoice }) => shipmentId === id
    );

    if (hasInvoice) {
      setInvoiceDialogShipmentId(id);
      setInvoiceDialogIssuedInvoiceNumber(issuedInvoiceNumber);
      setShowGenerateInvoiceModal(true);
    } else if (!issuedInvoiceNumber) {
      setInvoiceDialogShipmentId(id);
      setShowMissingIssuedInvoiceNumberModal(true);
    } else {
      handleGenerateInvoiceAndRedirect(id);
    }
  };

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  const handleSendOrderClick = (id) => {
    const [{ carrierContact, carrierEmployee, indexNumber }] =
      shipments.filter(({ id: orderId }) => id === orderId);

    setSendOrderDialogData({
      id,
      indexNumber,
      email:
        carrierEmployee && carrierEmployee.email
          ? carrierEmployee.email
          : carrierContact.email,
      message: "",
      isSendCopyToMeChecked: false,
    });

    setSendOrderDialogOpen(true);
  };

  const handlePayShipmentClick = (id) => {
    const [
      {
        issuedInvoicePaidAt,
        issuedInvoiceNumber,
        issuedInvoiceDueDate,
        receivedInvoicePaidAt,
        receivedInvoiceDueDate,
        receivedInvoiceNumber,
        bankAccountId,
        taxableSupplyDate,
        invoiceNote,
        issueDate,
        journey: { waypoints },
        customerPaymentDueDays,
      },
    ] = shipments.filter(({ id: shipmentId }) => id === shipmentId);

    const lastUnloading = waypoints[waypoints.length - 1];
    const lastUnloadingDateTime =
      lastUnloading.arriveAtTo ?? lastUnloading.arriveAtFrom;

    setPayShipmentDialogData({
      id,
      issuedInvoicePaidAt,
      issuedInvoiceNumber,
      issuedInvoiceDueDate:
        issuedInvoiceDueDate ?? addDays(new Date(), customerPaymentDueDays),
      receivedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      bankAccountId,
      taxableSupplyDate: taxableSupplyDate ?? lastUnloadingDateTime,
      invoiceNote,
      issueDate: issueDate ?? new Date(),
    });
    setPayShipmentDialogOpen(true);
  };

  const handleSendOrderSubmit = async ({
    email,
    message,
    language,
    isSendCopyToMeChecked,
  }) => {
    setSendOrderDialogOpen(false);
    await handleSendOrder(sendOrderDialogData.id, {
      email,
      message,
      language,
      isSendCopyToMeChecked,
    });
  };

  const handlePayShipmentSubmit = async ({
    issuedInvoiceNumber,
    issuedInvoiceDueDate,
    issuedInvoicePaidAt,
    receivedInvoiceDueDate,
    receivedInvoiceNumber,
    receivedInvoicePaidAt,
    bankAccountId,
    taxableSupplyDate,
    invoiceNote,
    issueDate,
  }) => {
    setLoadingPayAction(true);
    setPayShipmentDialogOpen(false);
    await handlePayShipment(payShipmentDialogData.id, {
      issuedInvoiceNumber,
      issuedInvoiceDueDate,
      issuedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      receivedInvoicePaidAt,
      bankAccountId,
      taxableSupplyDate,
      invoiceNote,
      issueDate,
    });
    const updatedShipments = shipments.map((shipment) =>
      shipment.id === payShipmentDialogData.id
        ? {
          ...shipment,
          issuedInvoiceNumber,
          issuedInvoiceDueDate,
          issuedInvoicePaidAt,
          receivedInvoiceDueDate,
          receivedInvoiceNumber,
          receivedInvoicePaidAt,
          bankAccountId,
          taxableSupplyDate,
          invoiceNote,
          issueDate,
        }
        : shipment
    );
    setShipments(updatedShipments);

    setPayShipmentDialogData({
      id: payShipmentDialogData.id,
      issuedInvoicePaidAt,
      issuedInvoiceNumber,
      issuedInvoiceDueDate,
      receivedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      bankAccountId,
      taxableSupplyDate,
      invoiceNote,
      issueDate,
    });
    setLoadingPayAction(false);
  };

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const shipmentsHeader = [
    {
      name: "indexNumber",
      label: "outcomingOrders.indexNumber",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "carrierContact",
      label: "outcomingOrders.carrier",
      isOrderable: false,
      width: "10%",
    },
    {
      name: "pickupAtStart",
      label: "outcomingOrders.date",
      isOrderable: true,
      width: "200px",
    },
    {
      name: "route",
      label: "outcomingOrders.route",
      isOrderable: false,
      width: "10%",
    },
    {
      name: "incomingOrders",
      label: "outcomingOrders.incomingOrderNumbers",
      isOrderable: false,
      width: "100px"
    },
    {
      name: "incomingOrdersExternalReferences",
      label: "outcomingOrders.incomingOrderExternalReference",
      isOrderable: false,
      width: "100px"
    },
    {
      name: "carrierPrice",
      label: "outcomingOrders.carrierPrice",
      isOrderable: false,
    },
    {
      name: "commissionNativePrice",
      label: "outcomingOrders.commission",
      isOrderable: false,
    },
    {
      name: "state",
      label: "board.state.title",
      isOrderable: false,
      width: "6%",
    },
    {
      name: "createdBy",
      label: "incomingOrders.createdBy",
      width: "50px",
      isOrderable: false
    }
  ];

  // const invoiceDisable = [];
  // if (canReadInvoice) {
  //   for (let index = 0; index < shipments.length; index++) {
  //     if (!shipments[index].hasInvoice) {
  //       invoiceDisable.push(shipments[index].id);
  //     }
  //   }
  // } else {
  //   invoiceDisable.push(-1);
  // }

  // const payDisable = [];
  // if (canCreateInvoice) {
  //   for (let index = 0; index < shipments.length; index++) {
  //     if (shipments[index].isDraft) {
  //       payDisable.push(shipments[index].id);
  //     }
  //   }
  // } else {
  //   payDisable.push(-1);
  // }

  const shipmentPdfDisable = [];
  if (canCreateOrderPdf) {
    shipments.forEach(({ type, isDraft }, index) => {
      if (type === ShipmentType.CARRIED || isDraft) {
        shipmentPdfDisable.push(shipments[index].id);
      }
    });
  } else {
    shipmentPdfDisable.push(-1);
  }

  // const createInvoiceDisable = [];
  // if (canCreateInvoice) {
  //   shipments.forEach(({ isDraft, bankAccountId }, index) => {
  //     if (isDraft || !bankAccountId) {
  //       createInvoiceDisable.push(shipments[index].id);
  //     }
  //   });
  // } else {
  //   createInvoiceDisable.push(-1);
  // }

  const tableActions = [
    {
      column: [
        {
          handleClick: (id) => history.push(`/outcoming-order/${id}/copy`),
          icon: <FileCopyOutlined />,
          title: "copy",
          disableFor: !canCreateShipment ? [-1] : undefined,
        },
      ],
    },
    {
      column: [
        {
          icon: <GetApp />,
          title: "downloadOrder",
          usePopover: true,
          PopoverContent: DownloadOrderPopoverContent,
          disableFor: shipmentPdfDisable,
        },
      ],
    },
    {
      column: [
        {
          icon: <Email />,
          title: "sendOrder",
          handleClick: handleSendOrderClick,
          disableFor: shipmentPdfDisable,
        },
      ]
    },
    {
      column: [
        {
          handleClick: (id) => history.push(`/outcoming-order/${id}`),
          icon: <Edit />,
          title: "outcomingOrders.edit",
          disableFor: !canUpdateShipment ? [-1] : undefined,
        },
      ],
    },
  ];

  const draftSource = [
    { title: t("outcomingOrders.draft"), value: "DRAFT" },
    { title: t("outcomingOrders.completed"), value: "COMPLETED" },
  ];
  const setDraft = (value) => {
    if (value.includes("DRAFT") && value.includes("COMPLETED")) {
      handleSelectIsDraft(null);
    } else if (value.includes("COMPLETED")) {
      handleSelectIsDraft(false);
    } else if (value.includes("DRAFT")) {
      handleSelectIsDraft(true);
    } else {
      handleSelectIsDraft(undefined);
    }
  };

  const isAnyFilterActive = () => {
    const {
      types,
      drivers,
      carrierPrice,
      commission,
      carriers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      vehicles,
      isDraft,
    } = filter;

    return [
      drivers,
      carrierPrice,
      commission,
      carriers,
      creators,
      cargo,
      loadingDate,
      createdAt,
      vehicles,
      isDraft,
    ].some((item) => item !== undefined);
  };

  const sum = shipments.reduce((acc, val) => {
    const rate = val.carrierPriceCurrency ? exchangeRates.find((exchangeRate) => exchangeRate.sourceCurrency === val.carrierPriceCurrency).rate : 1;
    return acc + (val.carrierPrice * rate || 0);
  }, 0);

  return (
    <Page
      className={classes.frame}
      actions={
        <>
          <Tooltip title={t("outcomingOrders.newOutcomingOrder")}>
            <span>
              <Fab
                color="primary"
                disabled={loading || !canCreateShipment}
                onClick={() => history.push("/shipment")}
              >
                <Add />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("outcomingOrders.export.action")}>
            <span>
              <Fab
                color="primary"
                disabled={loading || selected === 0}
                onClick={handleOutcomingOrdersExportRequest}
              >
                <CloudDownloadOutlined />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title={t("outcomingOrders.removeOutcomingOrder")}>
            <span>
              <Fab
                className={classes.danger}
                disabled={loading || selected === 0 || !canDeleteOutcomingOrder}
                onClick={handleDeleteOutcomingOrderRequest}
                data-cy="remove-outcoming-orders"
              >
                <DeleteOutline />
              </Fab>
            </span>
          </Tooltip>
        </>
      }>
      <FilterContainer
        searchField={
          <SearchTextfield
            handleSearch={handleSearch}
            value={search}
            fullWidth
            placeholder={t("outcomingOrders.searchTooltip")}
          />
        }
        showClearButton={isAnyFilterActive}
        onClear={handleDeselect}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={[
          <StateFilterDropdown
            id="state"
            key="state"
            placeholderTitle={t("board.state.title")}
            value={filter.state ?? []}
            onChange={(arr) =>
              handleSelectShipmentState(arr.length === 0 ? undefined : arr)
            }
            selectAll={(all) => handleSelectShipmentState(all)}
            onClear={() => handleSelectShipmentState(undefined)}
            search={(text) => loadAvailableStates(text)}

            getTitle={(item) => item.name}
          />,
          <FilterDateRangeDropdown
            id="loadingDate"
            key="loadingDate"
            onChange={(value) => handleSelectLoadingsDateRange(value)}
            value={
              filter.loadingDate
                ? [filter.loadingDate.from, filter.loadingDate.to]
                : []
            }
            onClear={() => handleSelectLoadingsDateRange(undefined)}
            placeholderTitle={t("outcomingOrders.loadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="unloadingDate"
            key="unloadingDate"
            onChange={(value) => handleSelectUnloadingsDateRange(value)}
            value={
              filter.unloadingDate
                ? [filter.unloadingDate.from, filter.unloadingDate.to]
                : []
            }
            onClear={() => handleSelectUnloadingsDateRange(undefined)}
            placeholderTitle={t("outcomingOrders.unloadingsDateRange")}
          />,
          <UserFilterDropdown
            id="creators"
            key="creators"
            onChange={(value) =>
              handleSelectCreators(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectCreators(all)}
            value={filter.creators ?? []}
            placeholderTitle={t("outcomingOrders.creator")}
            search={(text) => loadAvailableUsers(text)}
            onClear={() => handleSelectCreators(undefined)}
            getTitle={(item) => item.name}
            disabled={!canReadCompanyShipment}
          />,
          <FilterDateRangeDropdown
            id="createdAt"
            key="createdAt"
            onChange={(value) => handleSelectCreatedAtDateRange(value)}
            value={
              filter.createdAt
                ? [filter.createdAt.from, filter.createdAt.to]
                : []
            }
            onClear={() => handleSelectCreatedAtDateRange(undefined)}
            placeholderTitle={t("outcomingOrders.creationDate")}
          />,
          <ContactFilterDropdown
            id="carriers"
            key="carriers"
            onChange={(value) =>
              handleSelectCarrier(value.length === 0 ? undefined : value)
            }
            value={filter.carriers ?? []}
            selectAll={(all) => handleSelectCarrier(all)}
            placeholderTitle={t("contacts.carrier")}
            onClear={() => handleSelectCarrier(undefined)}
            search={(text) => loadAvailableContacts(ContactType.CARRIER, text)}
            getTitle={(item) => item.name}
          />,
          <FilterNumberRange
            id="carrierPrice"
            key="carrierPrice"
            onChange={(value) => handleSelectCarrierPrice(value)}
            value={filter.carrierPrice}
            placeholderTitle={t("outcomingOrders.carrierPrice")}
            onClear={() => handleSelectCarrierPrice(undefined)}
          />,
          <FilterStaticDropdown
            id="cargo"
            key="cargo"
            placeholderTitle={t("outcomingOrders.cargo")}
            value={filter.cargo ?? []}
            onChange={(arr) =>
              handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
            }
            selectAll={() =>
              handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
            }
            onClear={() => handleSelectCargoTemplate(undefined)}
            source={AvailableCargoItemTemplates.map(({ label, value }) => ({ title: t(label), value }))}
          />,
          <FilterStaticDropdown
            id="isDraft"
            key="isDraft"
            placeholderTitle={t("outcomingOrders.draft")}
            value={
              filter.isDraft === undefined
                ? []
                : filter.isDraft === null
                  ? ["DRAFT", "COMPLETED"]
                  : filter.isDraft === true
                    ? ["DRAFT"]
                    : ["COMPLETED"]
            }
            onChange={(value) => setDraft(value)}
            selectAll={() => setDraft(draftSource.map((item) => item.value))}
            onClear={() => handleSelectIsDraft(undefined)}
            source={draftSource}
          />,
        ]}
      />
      <DataTable
        headers={shipmentsHeader}
        actions={tableActions}
        selectedColumns={selectedColumns}
        data={data}
        dataCount={dataCount}
        loading={loading || loadingPayAction}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        enableDetail
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        onChangeSelectedColumns={handleChangeSelectedColumns}
        sum={sum}
      />
      <InvoiceDialog
        id={invoiceDialogShipmentId}
        title={t("orders.invoice.title")}
        content={t("orders.invoice.message")}
        generateButtonLabel={t("orders.invoice.generate")}
        closeButtonLabel={t("orders.invoice.close")}
        isOpen={showGenerateInvoiceModal}
        handleGenerateShipmentInvoice={() => {
          if (invoiceDialogIssuedInvoiceNumber) {
            handleGenerateInvoiceAndRedirect(invoiceDialogShipmentId);
          } else {
            setShowMissingIssuedInvoiceNumberModal(true);
          }

          setShowGenerateInvoiceModal(false);
        }}
        handleClose={() => setShowGenerateInvoiceModal(false)}
      />
      <InvoiceDialog
        id={`missingIssuedInvoiceNumber${invoiceDialogShipmentId}`}
        // title={t("orders.invoice.title")}
        // content={t("orders.invoice.message")}
        title={t("orders.invoice.missingIssuedInvoiceNumberTitle")}
        content={t("orders.invoice.missingIssuedInvoiceNumberMessage")}
        generateButtonLabel={t("orders.invoice.generate")}
        closeButtonLabel={t("orders.invoice.close")}
        isOpen={showMissingIssuedInvoiceNumberModal}
        handleGenerateShipmentInvoice={() => {
          handleGenerateInvoiceAndRedirect(invoiceDialogShipmentId);
          setShowMissingIssuedInvoiceNumberModal(false);
        }}
        handleClose={() => setShowMissingIssuedInvoiceNumberModal(false)}
      />
      <ExportDialog
        infoText={t("orders.export.info.text")}
        title={t("orders.export.title")}
        closeButtonLabel={t("orders.export.close")}
        downloadCSV={t("orders.export.downloadCSV")}
        startButtonLabel={t("orders.export.start")}
        processingText={t("orders.export.processing.text")}
        doneText={t("orders.export.done.text")}
        linkLabel={t("orders.export.done.link")}
        isDone={isShipmentsExportDone}
        isOpen={isShipmentsExportDialogOpen}
        isProcessing={isShipmentsExportProcessing}
        link={shipmentsExportLink}
        close={closeShipmentsExportDialog}
        exportCsv={exportShipments}
      />
      <PayDialog
        issuedInvoiceDueDate={payShipmentDialogData.issuedInvoiceDueDate}
        issuedInvoicePaidAt={payShipmentDialogData.issuedInvoicePaidAt}
        issuedInvoiceNumber={payShipmentDialogData.issuedInvoiceNumber}
        receivedInvoiceDueDate={payShipmentDialogData.receivedInvoiceDueDate}
        receivedInvoiceNumber={payShipmentDialogData.receivedInvoiceNumber}
        receivedInvoicePaidAt={payShipmentDialogData.receivedInvoicePaidAt}
        bankAccountValue={payShipmentDialogData.bankAccountId}
        bankAccounts={availableBankAccounts}
        invoiceNote={payShipmentDialogData.invoiceNote}
        taxableSupplyDate={payShipmentDialogData.taxableSupplyDate}
        issueDate={payShipmentDialogData.issueDate}
        isOpen={payShipmentDialogOpen}
        handleSubmit={handlePayShipmentSubmit}
        handleClose={() => setPayShipmentDialogOpen(false)}
        disableReceivedInvoice={
          shipments.find(({ id }) => id === payShipmentDialogData.id)
            ? shipments.find(({ id }) => id === payShipmentDialogData.id)
              .type === ShipmentType.CARRIED
            : false
        }
      />
      <SendOrderDialog
        email={sendOrderDialogData.email}
        message={t("webapp:outcomingOrder.send-order.label.default-text", {
          number: sendOrderDialogData.indexNumber,
        })}
        language="cs"
        shipmentId={sendOrderDialogData.id}
        isOpen={sendOrderDialogOpen}
        handleSubmit={handleSendOrderSubmit}
        handleClose={() => setSendOrderDialogOpen(false)}
      />
    </Page>
  );
};

export default OutcomingOrders;
