
import React from "react";
import BoardCardWaypointIcon from "./BoardCardWaypointIcon";
import { Tooltip, makeStyles, IconButton, CircularProgress } from "@material-ui/core";
import { Check, Warning, Error, Timelapse } from "@material-ui/icons";
import { teal, red, blue } from "@material-ui/core/colors";
import { getDrivenThroughAtText } from "../../../../utility/waypoint";


const useStyles = makeStyles(({ spacing, transitions, palette }) => ({
  cardPlace: {
    fontFamily: "Barlow",
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "1rem"
  },
  cardTime: {
    fontFamily: "Barlow",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "1rem"
  },
  waypointRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "space-between",
    alignItems: "center",
    alignContent: "center"
  },
  placeRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    margin: 8
  },
  placeRowInfo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 5
  }
}));

const BoardCardWaypointRow = ({
  t,
  id,
  action,
  formattedPlace,
  formattedDateTime,
  isDrivenThrough,
  drivenThroughAt,
  updateShipmentDriveThrough,
  driveThroughLoading,
  inProgress,
  haveError
}) => {
  const classes = useStyles();

  const WaypointIconButton = ({ icon }) => (
    <IconButton
      onClick={updateShipmentDriveThrough}
      style={{ padding: 0, margin: 0 }}
      disabled={driveThroughLoading !== -1} >
      {icon}
    </IconButton>
  );

  return (
    <span className={classes.waypointRow} >
      <span className={classes.placeRow} >
        <BoardCardWaypointIcon t={t} action={action} />
        <span className={classes.placeRowInfo} >
          <span className={classes.cardPlace}>
            {formattedPlace}
          </span>
          <span className={classes.cardTime}>
            {formattedDateTime}
          </span>
        </span>
      </span >
      <span onClick={(e) => {
        e.stopPropagation();
      }} >
        {driveThroughLoading === id
          ? <CircularProgress size={20} style={{ marginRight: 5 }} />
          : <>
            {isDrivenThrough
              ? (
                <Tooltip title={getDrivenThroughAtText(t, action, drivenThroughAt)}>
                  <Check style={{ marginRight: 5, color: teal[700], width: 20, height: 20 }} />
                </Tooltip>
              )
              : null}
            {inProgress && !isDrivenThrough
              ? (
                <WaypointIconButton icon={
                  <Tooltip title={t("board.inProgress")}>
                    <Timelapse style={{ marginRight: 5, color: blue[600], width: 20, height: 20 }} />
                  </Tooltip>
                } />
              )
              : null}
            {haveError && !isDrivenThrough
              ? (
                <WaypointIconButton icon={
                  <Tooltip title={t("board.missedLoading")}>
                    <Error style={{ marginRight: 5, color: red[700], width: 20, height: 20 }} />
                  </Tooltip>
                } />
              )
              : null}
          </>}
      </span>
    </span>
  );
}

export default BoardCardWaypointRow;