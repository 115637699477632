import clsx from "clsx";
import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: "absolute",
    bottom: 0,
    right: 0,

    padding: spacing(1),

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    }
  }
}));

const PaneActions = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  );
};

export default PaneActions;
