import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  paper: {
    width: 400
  }
}));

const ContactTagEditor = ({
  availableTags = [],
  initialTags = [],
  isOpen,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tags, setTags] = useState(initialTags);

  const handleTagsChange = (event, value) => setTags(value);

  const handleSubmitButtonClick = () => {
    if (onSubmit) {
      onSubmit(tags);
    }
  };

  useEffect(() => {
    setTags(initialTags);
  }, [isOpen]);

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>{t("webapp:contact.tags.edit")}</DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          value={tags}
          noOptionsText={t("webapp:common.title.noTagOptions")}
          groupBy={({ type }) => t(`webapp:contact.tag.type.${type}`)}
          getOptionLabel={({ label }) => label}
          getOptionSelected={({ id }, { id: other }) => id === other}
          options={availableTags}
          renderInput={params => (
            <TextField
              {...params}
              label={t("webapp:common.title.tags")}
            />
          )}
          onChange={handleTagsChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmitButtonClick}>
          {t("webapp:common.title.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactTagEditor;
