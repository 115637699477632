import clsx from "clsx";
import React, { useState } from "react";

import {
  IconButton,
  InputBase,
  Typography,
  makeStyles,
  useTheme
} from "@material-ui/core";
import { ThemeStyle } from "@material-ui/core/styles/createTypography";
import { Edit } from "@material-ui/icons";

interface EditableTypographyProps {
  className?: string;
  name?: string;
  value?: string;
  variant?: ThemeStyle;
  maxLength?: number;
  isDisabled?: boolean;
  dataCy?: string;

  onBlur?: React.FocusEventHandler;
  onChange?: React.ChangeEventHandler;
}

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      verticalAlign: "middle"
    }
  },
  button: {
    marginLeft: 6
  },
  input: {
    padding: 0
  },
  typography: {
    display: "inline",
    wordWrap: "break-word"
  }
}));

function EditableTypography(
  {
    className,
    name,
    value,
    variant = "body1",
    maxLength,
    isDisabled,
    dataCy,
    onBlur,
    onChange
  }: EditableTypographyProps
): React.ReactElement {
  const [isEditable, setIsEditable] = useState(false);
  const classes = useStyles();
  const { typography } = useTheme();

  const handleEditButtonClick = (): void => {
    setIsEditable(true);
  };

  const handleInputBlur = (event: React.FocusEvent): void => {
    setIsEditable(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.preventDefault();
      (event.target as HTMLTextAreaElement | HTMLInputElement).blur();
    }
  };

  const content = isEditable ? (
    <InputBase
      className={classes.input}
      style={typography[variant]}
      name={name}
      value={value}
      onBlur={handleInputBlur}
      onChange={onChange}
      onKeyDown={handleInputKeyDown}
      inputProps={{ maxLength }}
      autoFocus
      fullWidth
      multiline
    />
  ) : (
    <>
      <Typography className={classes.typography} variant={variant} data-cy={dataCy}>
        {value}
      </Typography>
      <IconButton
        className={classes.button}
        size="small"
        onClick={handleEditButtonClick}
        disabled={isDisabled}
      >
        <Edit fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div className={clsx(classes.root, className)}>
      {content}
    </div>
  );
}

export default EditableTypography;
