import React from "react";

import VehicleAutosuggestTextField from "../VehicleAutosuggestTextField";

const FormikVehicleAutosuggestTextField = ({
  form,
  name,
  type,
  ...rest 
}) => {
  const value = form.values[name];
  const error = form.errors[name];
  const isTouched = form.touched ? form.touched[name] : form.touched[name];
  const hasError = isTouched && error;
  const errorMessage = (error?.message) ? error.message : error;

  return (
    <VehicleAutosuggestTextField
      {...rest}
      name={name}
      value={value}
      type={type}
      error={hasError}
      helperText={hasError ? errorMessage : " "}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
    />
  );
};

export default FormikVehicleAutosuggestTextField;
