const searchGooglePlaceByCoordinates = (geocoderService, latlng) =>
  new Promise((resolve, reject) => (
    geocoderService.geocode(
      { location: latlng },
      (places, status) => {
        if (status !== "OK") {
          reject(status);
          return;
        }

        resolve(places);
      }
    )
  ));

export default searchGooglePlaceByCoordinates;
