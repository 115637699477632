import React from "react";

import {
  Typography, Grid, Button, makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
  page: {
    marginTop: spacing(20)
  },
  title: {
    fontWeight: 1000
  },
  notFound: {
    fontWeight: 700,
    marginTop: 5
  },
  button: {
    marginTop: 10
  }
}));

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.page}>
      <Grid container direction="row">
        <Grid item xs={2} />
        <Grid item xs={8} style={{ marginTop: 15 }}>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item>
              <Typography className={classes.title} variant="h1">404</Typography>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.notFound} variant="h4">
                    {t("pageNotFound")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" className={classes.button} component={Link} to="/">
                    {t("backHome")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

export default NotFound;
