import Place from "./Place";

const Waypoint = ({
  id,
  place = Place({}),
  contact = "",
  note = "",
  phoneNumber = "",
  arriveAtFrom = new Date(),
  arriveAtTo,
  cargo = []
}) => ({
  id,
  place,
  contact,
  note,
  phoneNumber,
  arriveAtFrom,
  arriveAtTo,
  cargo
});

export default Waypoint;
