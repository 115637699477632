import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    padding: spacing(2)
  },
  paper: {
    maxWidth: "initial"
  }
}));

const ImageViewer = ({ image: { url, name }, isOpen, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const containerRef = useRef();

  const maxWidth = window.innerWidth - 64;
  const maxHeight = window.innerHeight - 64 - 128;

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const content = new Image();
    content.src = url;
    content.onload = () => {
      const container = containerRef.current;

      if (content.width > maxWidth || content.height > maxHeight) {
        if (content.width / maxWidth > content.height / maxHeight) {
          content.width = maxWidth;
          content.height = parseInt(
            content.height / (content.width / maxWidth),
            10
          );
        } else {
          content.width = parseInt(
            content.width / (content.height / maxHeight),
            10
          );

          content.height = maxHeight;
        }
      }

      container.innerHTML = "";
      container.appendChild(content);
    };
  }, [isOpen]);

  return (
    <Dialog classes={{ paper: classes.paper }} open={isOpen} onClose={onClose}>
      <div className={classes.header}>
        <Typography variant="h5">
          {name}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <div ref={containerRef} />
      </div>
    </Dialog>
  );
};

export default ImageViewer;
