import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, ListSubheader, Paper, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { compare } from "@cargotic/common";

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const SettingsRoleEditorPermissions = ({ form, permissions }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const selectedAll = compare(permissions.map(({ id }) => id), form.values.permissions);

  const [checkedAll, setCheckedAll] = useState(selectedAll);

  const handleChangeCheckbox = (e) => {
    const { checked, name: id } = e.target;
    const parsedId = parseInt(id);
    if (checked) {
      form.setFieldValue("permissions", [...form.values.permissions, parsedId]);
    } else {
      form.setFieldValue(
        "permissions",
        form.values.permissions.filter((v) => v !== parsedId)
      );
    }
  }

  const handleChangeAll = (e) => {
    const { checked, name: id } = e.target;
    if (checked) {
      form.setFieldValue("permissions", permissions.map(({ id }) => id));
      setCheckedAll(true);
    } else {
      console.log(checked, permissions);
      form.setFieldValue(
        "permissions",
        []
      );
      setCheckedAll(false);
    }
  }

  const groupPermissionsByResource = (permissions) => (
    permissions.reduce((r, a) => {
      const shorterCode = a.code.split(".")[1];
      r[shorterCode] = r[shorterCode] || [];
      r[shorterCode].push(a);
      return r;
    }, Object.create(null)));

  useEffect(() => {
    groupPermissionsByResource(permissions);
  }, [permissions]);

  return (
    <div>
      <List dense className={classes.list} component={Paper}>
        <ListItem key="selectAll" button>
          <ListItemText primary={t(`selectAll`)} />
          <ListItemSecondaryAction>
            <FormControlLabel
              name={`selectAll`}
              checked={checkedAll}
              onChange={handleChangeAll}
              control={(
                <Checkbox color="primary" />
              )}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {
          Object.entries(groupPermissionsByResource(permissions)).map(([k, v], index) => (
            <>
              {index !== 0 ? <Divider /> : null}
              <ListSubheader>{t(`webapp:permission.${k}.title`)}</ListSubheader>
              {v.map((item) => (
                <ListItem key={item.name} button>
                  <ListItemText primary={t(`webapp:permission.${item.localization_title}`)} secondary={t(`webapp:permission.${item.localization_title}-subtitle`)} />
                  <ListItemSecondaryAction>
                    <FormControlLabel
                      name={item.id}
                      checked={form.values.permissions.includes(item.id)}
                      onChange={handleChangeCheckbox}
                      control={(
                        <Checkbox color="primary" />
                      )}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </>
          ))}
      </List>
    </div>
  );
}

export default SettingsRoleEditorPermissions;
