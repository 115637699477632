import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";

import FormButtons from "@cargotic/webapp-component/component/FormButtons";

import SequencingSettingsAutocomplete from "./SequencingSettingsAutocomplete";
import { getValidationSchema } from "./validationSchema";

export function CustomSequencingCardContent({
  data = {},
  onFormSubmit,
  hasUpdateSequencingPermission
}) {
  const { t } = useTranslation();
  const { availableItems, sequences } = data;

  const sequencesKeys = useMemo(() => Object.keys(sequences), [sequences]);
  const validationSchema = useMemo(
    () => getValidationSchema(sequencesKeys, t),
    [sequencesKeys, t]
  );

  const sequencingForm = useFormik({
    initialValues: sequencesKeys?.reduce((acc, sectionKey) => {
      return {
        ...acc,
        [sectionKey]: sequences[sectionKey]?.map((v) => v.code)
      };
    }, {}),
    validationSchema,
    onSubmit: async (values, actions) => {
      onFormSubmit(values);
    }
  });

  return (
    <>
      {sequencesKeys.map((sectionKey) => {
        const sectionItems = sequences?.[sectionKey] || [];

        const sectionSpecificAvailableItems = Object.values(
          availableItems
        ).filter((item) =>
          (item?.availableFor || []).includes(sectionKey)
        );

        return (
          <Box key={sectionKey} pb={3}>
            <SequencingSettingsAutocomplete
              form={sequencingForm}
              name={sectionKey}
              availableItems={sectionSpecificAvailableItems}
              defaultItems={sectionItems}
            />
          </Box>
        );
      })}
      <FormButtons
        form={sequencingForm}
        submitButtonProps={{
          disabled: !sequencingForm.dirty || !sequencingForm.isValid || !hasUpdateSequencingPermission,
          variant: "outlined",
          color: "default"
        }}
        onSubmit={() => {
          try {
            sequencingForm.submitForm();
          } catch (error) {
            console.error(
              t("settings.customSequencing.error.saveError"),
              error
            );
          }
        }}
      />
    </>
  );
}

CustomSequencingCardContent.propTypes = {
  data: PropTypes.shape({
    sequences: PropTypes.object,
    availableItems: PropTypes.object
  })
};

CustomSequencingCardContent.defaultProps = {
  data: { sequences: {}, availableItems: {} }
};

export default CustomSequencingCardContent;
