import { ContactSnapshot, ContactType, Employee } from "@cargotic/model";

async function suggestContact(
  client: any,
  type: ContactType | undefined,
  text: string
): Promise<ContactSnapshot[]> {
  const contacts = await client.contact.postContactSuggestQuery({
    query: {
      types: [type, ContactType.BOTH],
      search: text
    }
  });

  return contacts;
}

async function suggestEmployee(
  client: any,
  text: string,
  contactId?: number
): Promise<Employee[]> {
  const employees = await client.contact.postContactEmployeeSuggestQuery({
    query: {
      search: text
    },
    contactId
  });

  return employees;
}

export {
  // eslint-disable-next-line
  suggestContact,
  suggestEmployee
};
