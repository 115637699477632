
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  TextField, Button, Checkbox, FormControlLabel, makeStyles, FormControl, FormHelperText, Typography
} from "@material-ui/core";
import config from "../../../config";

import { SignUpCompanySchema } from "../../../utility/validationSchema";

const useStyles = makeStyles(({ spacing }) => ({
  bigPadding: {
    padding: spacing(3)
  },
  marginBottom: {
    marginBottom: spacing(3)
  }
}));

const SignUpCompanyForm = ({
  initialValues, setFormCompany, handleSubmit, handleBack
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (initialValues.companyName !== "") {
      handleSubmit();
    }
  }, [initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignUpCompanySchema}
      onSubmit={({
        companyName, ic, dic, gdpr, isSubscriber
      }) => setFormCompany({
        companyName, ic, dic, gdpr, isSubscriber
      })}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched
      }) => (
          <>
            <form onSubmit={handleSubmit}>
              <section style={{ marginBottom: 4 }}>
                <TextField
                  name="companyName"
                  label={t("companyName")}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.companyName && t(errors.companyName) || " "}
                  error={errors.companyName && touched.companyName}
                  value={values.companyName}
                  required
                />
                <TextField
                  name="ic"
                  label={t("ic")}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.ic && t(errors.ic) || " "}
                  error={errors.ic && touched.ic}
                  value={values.ic}
                  required
                />
                <TextField
                  name="dic"
                  label={t("dic")}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.dic && t(errors.dic) || " "}
                  error={errors.dic && touched.dic}
                  value={values.dic}
                  required
                />
                <div>
                  <FormControlLabel
                    style={{ marginBottom: 0 }}
                    control={(
                      <Checkbox
                        name="gdpr"
                        checked={values.gdpr}
                        onChange={handleChange}
                      />
                    )}
                    label={<Typography>{t("gdprAgreement")} <a href={`${config.landingPage.url}/document/GDPR.pdf`} target="_blank">{t("gdprDocument")}</a></Typography>}
                    required
                    className={classes.marginBottom}
                  />
                  <FormHelperText error={errors.gdpr}>{touched.gdpr && t(errors.gdpr) || " "}</FormHelperText>
                </div>
                <div>
                  <FormControlLabel
                    style={{ marginBottom: 0 }}
                    control={(
                      <Checkbox
                        name="isSubscriber"
                        checked={values.isSubscriber}
                        onChange={handleChange}
                      />
                    )}
                    label={<Typography>{t("newsletterAgreement")}</Typography>}
                    required
                    className={classes.marginBottom}
                  />
                  <FormHelperText error={errors.isSubscriber}>{touched.isSubscriber && t(errors.isSubscriber) || " "}</FormHelperText>
                </div>
              </section>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {t("next")}
              </Button>
            </form>
          </>
        )}
    </Formik>
  );
};

export default SignUpCompanyForm;
