import { useCallback, useState } from "react"

export default ({ onOpen, onClose } = {}) => {
  const [[isOpen, data], setOpen] = useState([false])

  const handleOpen = useCallback(
    (dialogData) => {
      setOpen([true, dialogData])
      if (typeof onOpen === "function") {
        onOpen()
      }
    },
    [onOpen, setOpen]
  )

  const handleClose = useCallback(
    (result) => {
      setOpen([false])
      if (typeof onClose === "function") {
        onClose(result)
      }
    },
    [onClose, setOpen]
  )

  return [isOpen, handleOpen, handleClose, data]
}
