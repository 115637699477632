import React from "react";

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const DeleteCompanyDialog = ({
  open,
  selected,
  handleClose,
  handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("settings.deleteCompany")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("settings.confirmDeleteCompany")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("no")}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCompanyDialog;
