import React, { useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import {
  differenceInDays,
  startOfToday,
  endOfToday,
  addDays,
  startOfDay,
  endOfDay
} from "date-fns";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import { Poll as PollIcon, Person as PersonIcon, TrendingUp as TrendingUpIcon } from "@material-ui/icons";
import { cs, enUS } from "date-fns/locale";

import { formatDateTimeRelatively } from "../../../utility/common";
import {
  readCompanyHighlights,
  readCompanyIncomingOrderHighlights,
  readCompanyOutcomingOrderHighlights,
  readCompanyShipmentHighlights,
  readuserShipmentHighlights,
  readCompanyRevenueHighlights,
  readUserRevenueHighlights,
  readUserHighlights,
  readUserOutcomingOrderHighlights,
  readNews,
  readExchangeRate
} from "../../../resource";
import useRouter from "../../hook/useRouter";
import useAuth from "../../hook/useAuth";
import { resendVerificationMail } from "../../../auth";
import Overview from "./Overview";
import OverviewCard from "./OverviewCard";
import ClickableCard from "../../common/ClickableCard";
import TariffExpiration from "../TariffExpiration/TariffExpiration";
import TariffExpirationDispatcher from "../TariffExpiration/TariffExpirationDispatcher";
import { calculatePercentageDiff, formatNumericOrders } from "../../../utility/functional";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  gridItem: {
    [breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  card: {
    height: spacing(21),
    [breakpoints.down("xs")]: {
      width: "100%"
    },
    [breakpoints.up("sm")]: {
      width: spacing(35)
    }
  },
  content: {
    marginBottom: spacing(1),
    marginTop: spacing(1)
  }
}));

const OverviewContainer = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;

  const {
    user: {
      emailVerified, subscription
    },
    hasPermission
  } = useAuth();

  const now = new Date();
  const subscriptionExpirationDays = differenceInDays(subscription.expiresAt, now);

  const [showVerifiedEmailSnackbar, setShowVerifiedEmailSnackbar] = useState(!emailVerified);
  const [showTariffSnackbar, setShowTariffSnackbar] = useState(subscriptionExpirationDays <= 14);
  const [highlights, setHighlights] = useState({
    allUsers: 0,
    revenueForTimeframe: 0,
    allShipments: 0,
    allIncomingOrders: 0,
    allOutcomingOrders: 0,
    incomingOrdersForTimeframe: 0,
    outcomingOrdersForTimeframe: 0,
    shipmentsForTimeframe: 0,
    commissionForLastDay: 0
  });
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingNews, setLoadingNews] = useState(true);
  const [loadingShipments, setLoadingShipments] = useState(false);
  const [loadingRevenue, setLoadingRevenue] = useState(false);
  const [loadingCommission, setLoadingCommission] = useState(false);
  const [loadingIncomingOrders, setLoadingIncomingOrders] = useState(false);
  const [loadingOutcomingOrders, setLoadingOutcomingOrders] = useState(false);
  const [loadingExchange, setLoadingExchange] = useState(true);

  const [exchangeRate, setExchangeRate] = useState({});

  const sendVerificationMail = () => resendVerificationMail()
    .then(() => {
      console.info("Resent verification email...");
      enqueueSnackbar(t("overview.verificationMailResent"), { variant: "success" });
    })
    .catch((error) => {
      console.error(error);

      if (error.response.data.message.includes("TOO_MANY_ATTEMPTS_TRY_LATER")) {
        enqueueSnackbar(
          t("overview.error.verificationTooSoon"),
          { variant: "error" }
        );
      }
      else {
        enqueueSnackbar(
          t("overview.error.verificationMail"),
          { variant: "error" }
        );
      }
    });

  const canReadCompanyHighlights = hasPermission("resource.statistics.company.read");
  const canReadUserHighlights = hasPermission("resource.statistics.user.read");

  const fetchShipments = async (period) => {
    try {
      setLoadingShipments(true);
      let shipments;
      let previousShipments;
      if (canReadCompanyHighlights) {
        shipments = period.start
          ? await readCompanyShipmentHighlights(period.start.toISOString(), period.end.toISOString(), period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readCompanyShipmentHighlights();

      } else if (canReadUserHighlights) {
        shipments = period.start
          ? await readUserShipmentHighlights(period.start.toISOString(), period.end.toISOString(), period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readUserShipmentHighlights();

      }
      const { shipments: total,shipmentsPreviousPeriod: totalPrevious, allShipments } = shipments;
      const shipmentsDiff = totalPrevious !== undefined ? calculatePercentageDiff(total, totalPrevious)?.toFixed(2) : undefined;

      setHighlights((_highlights) => ({ ..._highlights, shipmentsForTimeframe: total, shipmentsDiff, allShipments }));
      setLoadingShipments(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoadingShipments(false);
      enqueueSnackbar(
        t("overview.error.fetchData"),
        { variant: "error" }
      );
    }
  };

  const fetchRevenue = async (period) => {
    try {
      setLoadingRevenue(true);
      let revenue;
      let previousRevenue;
      if (canReadCompanyHighlights) {
        revenue = period.start
          ? await readCompanyRevenueHighlights(period.start.toISOString(), period.end.toISOString(), period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readCompanyRevenueHighlights();
      }
      else if (canReadUserHighlights) {
        revenue = period.start
          ? await readUserRevenueHighlights(period.start.toISOString(), period.end.toISOString(), period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readUserRevenueHighlights();
      }
      const { revenue: total, revenuePreviousPeriod: totalPrevious, allRevenue  } = revenue;

      const revenueDiff = totalPrevious !== undefined ? calculatePercentageDiff(total, totalPrevious)?.toFixed(2) : undefined;
      setHighlights((_highlights) => ({ ..._highlights, revenueForTimeframe: total, revenueDiff, allRevenue }));
      setLoadingRevenue(false);
    } catch (error) {
      console.error(error);
      setLoadingRevenue(false);
      enqueueSnackbar(
        t("overview.error.fetchData"),
        { variant: "error" }
      );
    }
  };
  
  const fetchOutcomingOrders = async (period) => {
    try {
      setLoadingOutcomingOrders(true);
      let outcomingOrders;
      if (canReadCompanyHighlights) {
        outcomingOrders = period.start
          ? await readCompanyOutcomingOrderHighlights(period.start.toISOString(), period.end.toISOString(), period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readCompanyOutcomingOrderHighlights();
      }
      else if (canReadUserHighlights) {
        outcomingOrders = period.start
          ? await readUserOutcomingOrderHighlights(period.start.toISOString(), period.end.toISOString(),  period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readUserOutcomingOrderHighlights();
      }
      const { outcomingOrders: total, allOutcomingOrders, outcomingOrdersPreviousPeriod } = outcomingOrders;
      const outcomingOrdersDiff = outcomingOrdersPreviousPeriod !== undefined ? calculatePercentageDiff(total, outcomingOrdersPreviousPeriod)?.toFixed(2) : undefined;
      setHighlights((_highlights) => ({ ..._highlights, allOutcomingOrders, outcomingOrdersForTimeframe: total, outcomingOrdersDiff }));
      setLoadingOutcomingOrders(false);
      setLoading(false);

    }
    catch (error) {
      console.error(error);
      setLoadingIncomingOrders(false);
      enqueueSnackbar(
        t("overview.error.fetchData"),
        { variant: "error" }
      );
    }
  }

  const fetchIncomingOrders = async (period) => {
    try {
      setLoadingIncomingOrders(true);
      let incomingOrders;
      if (canReadCompanyHighlights) {
        incomingOrders = period.start
          ? await readCompanyIncomingOrderHighlights(period.start.toISOString(), period.end.toISOString(), period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readCompanyIncomingOrderHighlights();
      }
      else if (canReadUserHighlights) {
        incomingOrders = period.start
          ? await readUserIncomingOrderHighlights(period.start.toISOString(), period.end.toISOString(),  period.startPrevious.toISOString(), period.endPrevious.toISOString())
          : await readUserIncomingOrderHighlights();
      }
      const { incomingOrders: total, allIncomingOrders, incomingOrdersPreviousPeriod } = incomingOrders;
      const incomingOrdersDiff = incomingOrdersPreviousPeriod !== undefined ? calculatePercentageDiff(total, incomingOrdersPreviousPeriod)?.toFixed(2) : undefined;
      setHighlights((_highlights) => ({ ..._highlights, allIncomingOrders, incomingOrdersForTimeframe: total, incomingOrdersDiff }));
      setLoadingIncomingOrders(false);
      setLoading(false);

    }
    catch (error) {
      console.error(error);
      setLoadingIncomingOrders(false);
      enqueueSnackbar(
        t("overview.error.fetchData"),
        { variant: "error" }
      );
    }
  }

  const fetchCommission = async (period) => {
    try {
      setLoadingCommission(true);
      let commission;
      let previousCommission;
      if (canReadCompanyHighlights) {
        commission = period.start
          ? await readCompanyHighlights(period.start.toISOString(), period.end.toISOString())
          : await readCompanyHighlights();

        if (period.startPrevious) {
          previousCommission = await readCompanyHighlights(period.startPrevious.toISOString(), period.endPrevious.toISOString());
        }
      }
      else if (canReadUserHighlights) {
        commission = period.start
          ? await readUserHighlights(period.start.toISOString(), period.end.toISOString())
          : await readUserHighlights();

        if (period.startPrevious) {
          previousCommission = await readUserHighlights(period.startPrevious.toISOString(), period.endPrevious.toISOString());
        }
      }

      const { commissionForTimeframe } = commission;
      let commissionDiff;
      if (previousCommission) {
        const { commissionForTimeframe: previousCommissionForTimeframe } = previousCommission;
        commissionDiff = previousCommissionForTimeframe !== undefined ? calculatePercentageDiff(commissionForTimeframe, previousCommissionForTimeframe)?.toFixed(2) : undefined;
      }
      setHighlights((_highlights) => ({ ..._highlights, commissionForTimeframe }));
      setLoadingCommission(false);
    } catch (error) {
      console.error(error);
      setLoadingCommission(false);
      enqueueSnackbar(
        t("overview.error.fetchData"),
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    fetchOutcomingOrders({start: startOfDay(new Date()), end: endOfDay(new Date()), startPrevious: startOfDay(addDays(new Date(), -1)), endPrevious: endOfDay(addDays(new Date(), -1)) });
    fetchRevenue({start: startOfDay(new Date()), end: endOfDay(new Date()), startPrevious: startOfDay(addDays(new Date(), -1)), endPrevious: endOfDay(addDays(new Date(), -1)) });
    fetchIncomingOrders({start: startOfDay(new Date()), end: endOfDay(new Date()), startPrevious: startOfDay(addDays(new Date(), -1)), endPrevious: endOfDay(addDays(new Date(), -1)) });
    fetchShipments({start: startOfDay(new Date()), end: endOfDay(new Date()), startPrevious: startOfDay(addDays(new Date(), -1)), endPrevious: endOfDay(addDays(new Date(), -1)) });
  }, []);

  useEffect(() => {
    readExchangeRate({ sourceCurrency: "EUR", targetCurrency: "CZK" })
      .then(({ rate, createdAt }) => {
        setExchangeRate({ rate: rate.toFixed(2), createdAt });
        setLoadingExchange(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingExchange(false);

        enqueueSnackbar(
          t("overview.error.fetchExchange"),
          { variant: "error" }
        );
      });
  }, []);

  const getContent = () => (
    <Grid container item spacing={3} justify="center" className={classes.content} >
      {canReadCompanyHighlights
        ? (
          <Grid item className={classes.gridItem}>
            <ClickableCard cardStyle={classes.card} click={() => history.push("/users")}>
              <OverviewCard
                loading={loading}
                CardIcon={PersonIcon}
                title={t("overview.users")}
                subtitle={t("active")}
                value={highlights.allUsers}
                bottomCaption={t("of") + " " + highlights.allUsers}
              />
            </ClickableCard>
          </Grid>
        ) : null}
      {canReadUserHighlights || canReadCompanyHighlights ? (
        <>
          <Grid item className={classes.gridItem}>
            <ClickableCard cardStyle={classes.card} click={() => history.push("/shipments")}>
              <OverviewCard
                loading={loading || loadingShipments}
                CardIcon={TrendingUpIcon}
                title={t("overview.shipments")}
                subtitle={t("newPerLastDay")}
                value={formatNumericOrders(highlights.shipmentsForTimeframe)}
                difference={highlights.shipmentsDiff}
                bottomCaption={t("of") + " " + highlights.allShipments}
                enableTimeSelect
                fetchValue={fetchShipments}
              />
            </ClickableCard>
          </Grid>
          <Grid item className={classes.gridItem}>
            <ClickableCard cardStyle={classes.card} click={() => history.push("/statistics")}>
              <OverviewCard
                loading={loading || loadingRevenue}
                CardIcon={PollIcon}
                title={t("overview.revenue")}
                subtitle={t("perLastDay")}
                value={formatNumericOrders(Number.parseFloat(highlights.revenueForTimeframe).toFixed(0))}
                difference={highlights.revenueDiff}
                bottomCaption="CZK"
                enableTimeSelect
                fetchValue={fetchRevenue}
              />
            </ClickableCard>
          </Grid>
          {/* <Grid item className={classes.gridItem}>
            <ClickableCard cardStyle={classes.card} click={() => history.push("/statistics")}>
              <OverviewCard
                loading={loading || loadingCommission}
                CardIcon={PollIcon}
                title={t("overview.commission")}
                subtitle={t("perLastDay")}
                value={formatNumericOrders(Number.parseFloat(highlights.commissionForTimeframe).toFixed(0))}
                difference={highlights.commissionDiff}
                bottomCaption="CZK"
                enableTimeSelect
                fetchValue={fetchCommission}
              />
            </ClickableCard>
          </Grid> */}
          <Grid item className={classes.gridItem}>
            <ClickableCard cardStyle={classes.card}>
              <OverviewCard
                loading={loading || loadingExchange}
                CardIcon={TrendingUpIcon}
                title={t("overview.exchangeRate")}
                subtitle={exchangeRate.createdAt ? formatDateTimeRelatively(new Date(exchangeRate.createdAt), locale) : null}
                value={exchangeRate.rate}
                bottomCaption="CZK"
                enableTimeSelect={false}
              />
            </ClickableCard>
          </Grid>
          <Grid item className={classes.gridItem}>
            <ClickableCard cardStyle={classes.card} click={() => history.push("/incoming-order")}>
              <OverviewCard
                loading={loading || loadingIncomingOrders}
                CardIcon={TrendingUpIcon}
                title={t("overview.incomingOrders")}
                subtitle={t("newPerLastDay")}
                value={formatNumericOrders(highlights.incomingOrdersForTimeframe)}
                difference={highlights.incomingOrdersDiff}
                bottomCaption={t("of") + " " + highlights.allIncomingOrders}
                enableTimeSelect
                fetchValue={fetchIncomingOrders}
              />
            </ClickableCard>
          </Grid>
          <Grid item className={classes.gridItem}>
            <ClickableCard cardStyle={classes.card} click={() => history.push("/incoming-order")}>
              <OverviewCard
                loading={loading || loadingOutcomingOrders}
                CardIcon={TrendingUpIcon}
                title={t("overview.outcomingOrders")}
                subtitle={t("newPerLastDay")}
                value={formatNumericOrders(highlights.outcomingOrdersForTimeframe)}
                difference={highlights.outcomingOrdersDiff}
                bottomCaption={t("of") + " " + highlights.allOutcomingOrders}
                enableTimeSelect
                fetchValue={fetchOutcomingOrders}
              />
            </ClickableCard>
          </Grid>
        </>
      ) : null}
    </Grid>
  );

  if (subscription.isExpired) {
    if (hasPermission("resource.subscription.update")) {
      return (
        <TariffExpiration />
      );
    }

    return (
      <TariffExpirationDispatcher />
    );
  }

  return (
    <Overview
      isTrialSubscription={subscription.isTrial}
      subscriptionExpirationDays={subscriptionExpirationDays}
      showTariffSnackbar={showTariffSnackbar}
      showVerifiedEmailSnackbar={showVerifiedEmailSnackbar}
      sendVerificationMail={sendVerificationMail}
      setShowVerifiedEmailSnackbar={setShowVerifiedEmailSnackbar}
      setShowTariffSnackbar={setShowTariffSnackbar}
      content={getContent()}
      news={newsList}
      loadingNews={loadingNews}
    />
  );
};

export default OverviewContainer;
