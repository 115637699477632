import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

export function Loading({ size }) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <CircularProgress size={size} />
        </Box>
    );
}

Loading.propTypes = {
    size: PropTypes.number
};

Loading.defaultProps = {
    size: 60
};

export default Loading;
