import React from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragAndDropItem from "./DragAndDropItem";

const DragAndDrop = ({
  items,
  setItems,
  droppableId = "dragAndDrop",
  paddingLeft
}) => {
  const { t } = useTranslation();

  const handleOnDragEnd = ({ source, destination }) => {
    // if the card is outside of draggable area
    if (!destination) return;

    const newItems = [...items];

    const [item] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, item);

    setItems(newItems);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="dragAndDrop">
        {(provided) => (
          <div
            {...provided.droppabableProps}
            ref={provided.innerRef}
            style={{
              padding: 10,
              paddingLeft
            }}
          >
            {items.map(({ name, label }, index) => (
              <Draggable
                key={name}
                draggableId={name}
                index={index}
              >
                {(provided) => (
                  <DragAndDropItem
                    title={t(label)}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    innerRef={provided.innerRef}
                  />
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDrop;
