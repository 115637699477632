import React from "react";
import { useTranslation } from "react-i18next";

import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    padding: spacing(1)
  }
}));

const ContactProfileDetailActions = ({ isExpanded, onExpandButtonClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button color="primary" onClick={onExpandButtonClick}>
        {
          isExpanded
            ? t("webapp:common.title.less")
            : t("webapp:common.title.more")
        }
      </Button>
    </div>
  );
};

export default ContactProfileDetailActions;
