import React from "react";

import {
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { SignInSchema } from "../../../utility/validationSchema";
import { loadSignedUser, storeSignedUser } from "../../../storage";
import useAuth from "../../hook/useAuth";
import useShipmentConcept from "../../hook//useShipmentConcept";

const SignInForm = () => {
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const { setShipment: setShipmentConcept } = useShipmentConcept();

  const handleSubmit = ({ email, password, remember }, { setSubmitting }) =>
    signIn(email, password, remember).then(async (id) => {
      const signedUserId = loadSignedUser();
      const parsedSignedUserId = signedUserId ? parseInt(signedUserId) : undefined;
      if (parsedSignedUserId !== id && id) {
        setShipmentConcept(null);
        localStorage.clear();
        storeSignedUser(id);
      }
      setSubmitting(false);
    });

  return (
    <Formik
      initialValues={{
        remember: false,
        email: "",
        password: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={SignInSchema}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched
      }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div>
                <TextField
                  name="email"
                  label={t("email")}
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  required
                  error={touched.email && errors.email}
                  helperText={touched.email && t(errors.email) || " "}
                  fullWidth
                />
                <TextField
                  name="password"
                  label={t("password")}
                  type="password"
                  required
                  error={touched.password && errors.password}
                  value={values.password}
                  helperText={touched.password && t(errors.password) || " "}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {t("signIn")}
              </Button>
              <div>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="remember"
                      checked={values.remember}
                      onChange={handleChange}
                    />
                  )}
                  label={t("rememberMe")}
                />
                <div>
                  <Typography variant="caption">
                    {t("newToCargotic")}
                  &nbsp;
                  <Link to="/sign-up">
                      {t("signUp")}
                    !
                  </Link>
                  </Typography>
                  <Typography variant="caption" paragraph>
                    {t("forgottenPassword")}
                  ?&nbsp;
                  <Link to="/reset-password">{t("resetPassword")}</Link>
                  </Typography>
                </div>
              </div>
            </form>
          </>
        )}
    </Formik>
  );
};

export default SignInForm;
