import React from "react";
import { useTranslation } from "react-i18next";

import { VehicleExpirationType } from "@cargotic/model";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  InputAdornment,
  IconButton,
  TextField,
  makeStyles
} from "@material-ui/core";

import {
  Add as AddIcon,
  Beenhere as BeenhereIcon,
  Build as BuildIcon,
  Clear as ClearIcon,
  DriveEta as DriveEtaIcon,
  LocalHospital as LocalHospitalIcon,
  Money as MoneyIcon,
  Search as SearchIcon
} from "@material-ui/icons";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import VehicleExpirationIcon from "../VehicleExpirationIcon";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  controls: {
    "& > :not(:first-child)": {
      marginLeft: spacing(2)
    }
  },
  field: {
    marginBottom: 0,

    "& > .MuiOutlinedInput-root": {
      height: "48px"
    }
  }
}));

const VehicleExpirationOverviewSwitchboard = ({
  match: {
    type,
    search,
    isExpired
  } = {},
  isAddButtonDisabled,
  onAddButtonClick,
  onTypeChange,
  onSearchChange,
  onIsExpiredChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTypeChange = (_, value) => onTypeChange(value ?? undefined);

  const handleIsExpiredChange = ({ target: { checked } }) => (
    onIsExpiredChange(checked ? false : undefined)
  );

  const handleSearchTextFieldChange = ({ target: { value } }) => onSearchChange(value);

  const handleSearchTextFieldClear = () => onSearchChange("");

  return (
    <div className={classes.root}>
      <FormGroup className={classes.controls} row>
        <ToggleButtonGroup
          value={type ?? null}
          exclusive
          onChange={handleTypeChange}
        >
          {Object.values(VehicleExpirationType).map(value => (
            <ToggleButton key={value} value={value}>
              <Tooltip title={t(`webapp:vehicle.expiration.${value}`)}>
                <VehicleExpirationIcon type={value} />
              </Tooltip>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div>
          <TextField
            className={classes.field}
            variant="outlined"
            value={search}
            onChange={handleSearchTextFieldChange}
            label={t("webapp:vehicle.title.search-expiration")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    disabled={search === ""}
                    onClick={handleSearchTextFieldClear}
                  >
                    {
                      search === "" || !search
                        ? (
                          <SearchIcon color="action" />
                        ) : (
                          <ClearIcon />
                        )
                    }
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </div>
        <FormControlLabel
          label={t("webapp:vehicle.title.only-active")}
          control={(
            <Switch
              color="primary"
              checked={isExpired === false}
              onChange={handleIsExpiredChange}
            />
          )}
        />
      </FormGroup>
      <div>
        <Button
          variant="contained"
          color="primary"
          startIcon={(
            <AddIcon />
          )}
          disabled={isAddButtonDisabled}
          onClick={onAddButtonClick}
        >
          {t("webapp:vehicle.title.add-expiration")}
        </Button>
      </div>
    </div>
  );
};

export default VehicleExpirationOverviewSwitchboard;
